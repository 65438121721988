import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class CategorieDemandeService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/categorie-demandes"
       return axios.get(url).then(res => res.data) 
   }

   get(idStructure, niveau) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/categorie-demandes?idStructure="+idStructure+"&niveau="+niveau
    return axios.get(url).then(res => res.data) 
   }
   

   create(newCategorieDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/categorie-demandes"
       return axios.post(url,newCategorieDemande)
                   .then(res => res.data);
   }
   
   update(idCategorieDemande ,newCategorieDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/categorie-demandes/"
       return axios.put(url+idCategorieDemande, newCategorieDemande)
                   .then(res => res.data);
   }

   delete(idCategorieDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/categorie-demandes/"
       return axios.patch(url+idCategorieDemande)
                   .then(res => res.data)
   }


}