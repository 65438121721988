import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SignataireService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
   getAll(idEtablissement) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire?idEtablissement="+idEtablissement
       return axios.get(url).then(res => res.data) 
   }

   getSignataireEtablissement(idEtablissement, idTypeSignataire) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire?idEtablissement="+idEtablissement+"&idTypeSignataire="+idTypeSignataire
    console.log(url)
    return axios.get(url).then(res => res.data) 
    }


    getSignataireIesr(idIesr, idTypeSignataire) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire?idIesr="+idIesr+"&idTypeSignataire="+idTypeSignataire
        console.log(url)
        return axios.get(url).then(res => res.data) 
        }
   
   create(signataire) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire"
       return axios.post(url,signataire)
                   .then(res => res.data);
   }

   update(idSignataire, signataire) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire/"+idSignataire

    console.log(url, signataire)
    return axios.put(url,signataire)
                .then(res => res.data);
}


}