import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ECHoraireService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getAll() {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire"
        return axios.get(url).then(res => res.data)
    }

    getAllEcHoraireDepartement(idDepartement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire?idDepartement="
        return axios.get(url+idDepartement).then(res => res.data)
    }

    getEcHoraireDepartementParcoursAnnee(idAnneeAcademique, idParcoursFiliere, idSemestre){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire?idAnneeAcademique="+idAnneeAcademique+"&idParcoursFiliere="+idParcoursFiliere+"&idSemestre="+idSemestre
        return axios.get(url).then(res => res.data)
    }
   
    getEcHoraireDepartementAnnee(idDepartement, idAnneeAcademique){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire?idDepartement="+idDepartement+"&idAnneeAcademique="+idAnneeAcademique
        return axios.get(url).then(res => res.data)
    }

    getEcHoraireDepartementParcours(idDepartement, idParcours){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire?idDepartement="+idDepartement+"&idParcours="+idParcours
        return axios.get(url).then(res => res.data)
    }


   create(newECHoraire) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire"
       return axios.post(url,newECHoraire)
       .then(res => res.data);
   }
   
   update(idECHoraire ,newECHoraire) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire/"
       return axios.put(url+idECHoraire, newECHoraire)
       .then(res => res.data);
   }

   delete(idECHoraire) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif-horaire/"
       return axios.patch(url+idECHoraire)
       .then(res => res.data)
   }



   getListeEnseignantEcAnnee(idAnneeAcademique, idParcoursFiliere, idSemestre) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/report-affectation-ec?idAnneeAcademique="+idAnneeAcademique+"&idParcoursFiliere="+idParcoursFiliere+"&idSemestre="+idSemestre
    return axios.get(url)
    .then(res => res.data)

    }

 


}