import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ParamNoteService {
     constructor(){
         this.adresse = new AdresseIP()
     }
    getAll() {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/param-note"
        return axios.get(url).then(res => res.data) 
    }
}