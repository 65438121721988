import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InfrastructureServices } from '../../service/InfrastructureServices';


export default function EtatReservation(){

    const [listeProgrammation, setListeProgrammation] = useState(null);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);


    const notify = new Notify();
    const spinner = new Spinner();
    const infrastructureServices = new InfrastructureServices();


    useEffect(() => {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)

        setLoading(true)

        infrastructureServices.getlisteSuiviReservation(token.code_departement).then(data => {
                
                setListeProgrammation(data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
                setLoading(false)
            })

        }, []
    );

    return (
            <div className="datatable-crud-demo">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <div className="card">
                    {loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable value={listeProgrammation} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                        dataKey="id" paginator rows={10} 
                        globalFilter={globalFilter}
                        header="Liste des demandes de reservation d'infrastructure en attente d'examen du departement d'accueil">
                        <Column field="libelleInfrastructure" header="Infrastructure" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="dateProgrammation" header="Date de la reservation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="heureDebutSeanceProg" header="Heure debut reservation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="heureFinSeanceProg" header="Heure fin reservation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="libelleDepartementEtab" header="Departement demandeur" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    </DataTable>
                    }
                </div>

            </div>
        )

    }