import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../components/Notify'
import { Spinner } from '../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { ParcoursService } from '../service/ParcoursService'
import { SemestreService } from '../service/SemestreService'
import { NiveauService } from '../service/NiveauService'
import { Dropdown } from 'primereact/dropdown'


export class ParametrerFraisPaiement extends Component {

    constructor(props){

        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.ModelParcours = new ParcoursService()
        this.ModelSemestre = new SemestreService()
        this.ModelNiveau = new NiveauService()

        this.state = {
            token : null,
            idDepartement : 0,
            parcoursList: [],
            niveauList: [],
            zonePaiementList: [],
            statutEtudiantList: [],
            fraisPaiementList: [],
            typeFraisList: [],
            loading : false,
            globalFilter: null,
            viewParametragePaiement: false,
            allowSave: false,
            curentParcours: null,
            curentNiveau: null,
            curentZonePaiement: null,
            curentStatutEtudiant: null,
            curentTypeFrais: null,
            deleteParametreFraisDialog : false,
            submitted: false,
            montant: '',
            fraisList: [],
            nombreTranche: '',
            manyTranche: false,
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            messageTranche: '',
            enableAjoutTranche: true,
            listeTranche: [],
            showTranche: false,
            detailsFrais: [],
            idDeletion: null
        }

    }

    componentDidMount(){
        
        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)

        const departement = localStorage.getItem('departement')
        const depart = JSON.parse(departement)

        this.setState({loading : true})

        this.setState({
            token : token,
            idDepartement : token.code_departement,
            currentDepartement: depart
        })

        this.ModelParcours.getListeFraisPaiement(token.code_departement).then(data => {

            //console.log(data)
            this.setState({
                fraisPaiementList: data,
                loading: false
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec("Echec de chargement des frais d'inscription par parcours !!")
        })
        

        this.ModelParcours.getListeParcoursDepartementByIdDepartement(token.code_departement).then(data => {
            this.setState({
                parcoursList: data
            })
        }).catch(error => {
            console.log(error)
        })
        
        this.ModelParcours.getZonePaiementList().then(data => {

            this.setState({
                zonePaiementList: data
            })
        }).catch(error => {
            console.log(error)
        })
        
        this.ModelParcours.getStatutEtudiantList().then(data => {
            this.setState({
                statutEtudiantList: data
            })
        }).catch(error => {
            console.log(error)
        })

        this.ModelParcours.getListeTypeFrais().then(data => {
            console.log(data)
            this.setState({
                typeFraisList: data
            })
        }).catch(error => {
            console.log(error)
        })

        this.setState({
            loading: false
        })
    }

    
    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState({
            viewParametragePaiement: true
        })
    }

    hideDialog = () => {

        this.setState({
            viewParametragePaiement: false,
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            manyTranche: false,
            fraisList: [],
            messageTranche: "",
            enableAjoutTranche: true,
            curentParcours: null,
            curentStatutEtudiant: null,
            curentTypeFrais: null,
            montant: "",
            curentNiveau: null,
            curentZonePaiement: null,
            nombreTranche: '',
            submitted: false
        })
    }

    onParcoursChange = (event) => {

        //On met à jour la valeur du parcours selectionné
        let parcours = event.target.value
        this.setState({
            curentParcours: event.target.value,
            fraisList: [],
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            enableAjoutTranche: true
        })

        let niveau = []
        //On va selectionner les semestres du parcours pour les disponibiliser dans la selection
        this.ModelSemestre.get(parseInt(parcours.idParcours)).then(data => {


            let semestre = data
	
	        this.ModelNiveau.getAll().then(response=>{
		
                response.map(niv => {
                    semestre.map(se => {
                        if(niv.idNiveau === se.idNiveau)
                            niveau.push(niv)
                    })
                })

		        let collectionIdNiveau = []
                niveau.map(nive => {
                    collectionIdNiveau.push(nive.idNiveau)
                })

		        let listeNiveau = []
                niveau.map( (nivo, index) => {
                    for(let i=index+1;i<collectionIdNiveau.length; i++){
                        if(nivo.idNiveau === collectionIdNiveau[i]){
                            listeNiveau.push(nivo)
                        }
                        
                    }
                }) 

		        this.setState({niveauList : listeNiveau})

	        })

        }).catch(error => {
            console.log(error)
        })
    }



    onTrancheInputChange = (event, fieldName) => {

        let tranche = this.state.tranche
        tranche[`${fieldName}`] = event.target.value
        
        this.setState({
            tranche: tranche,
            messageTranche: "",
            enableAjoutTranche: true
        })
    }



    onNiveauChange = (event) => {
        
        let niveau = event.target.value
        this.setState({
            curentNiveau: niveau,
            fraisList: [],
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            enableAjoutTranche: true
        })
    }

    deleteFraisPaiement = (tranche) => {
        //console.log(tranche)
    }

    onTypeFraisChange = (event) => {
        this.setState({
            curentTypeFrais: event.target.value,
            fraisList: [],
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            enableAjoutTranche: true
        })
    }

    onZonePaiementChange = (event) => {
        
        this.setState({
            curentZonePaiement: event.target.value,
            fraisList: [],
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            enableAjoutTranche: true
        })
    }

    onStatutChange = (event) => {
        this.setState({
            curentStatutEtudiant: event.target.value,
            fraisList: [],
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            enableAjoutTranche: true
        })
    }

    onMontantChange = (event) => {
        this.setState({
            montant: event.target.value,
            fraisList: [],
            tranche: {
                ordreTranche: 1,
                montantTranche: ""
            },
            enableAjoutTranche: true
        })
    }


    onNombreTrancheChange = (event) => {

        if(!this.state.curentStatutEtudiant || !this.state.curentZonePaiement || !this.state.curentTypeFrais || !this.state.montant || parseInt(this.state.montant)<1000){
            this.setState({
                submitted: true
            })
        }else{
            this.setState({
                nombreTranche: event.target.value,
                manyTranche: (!isNaN(event.target.value) && event.target.value>1)?true:false,
                fraisList: [],
                tranche: {
                    ordreTranche: 1,
                    montantTranche: ""
                },
                enableAjoutTranche: true
            })
        }
        
    }

    saveParametrageFrais = () => {
        this.setState({
            submitted: true
        })

        let valide = true
        let frais = this.state.fraisList

        if(this.state.curentStatutEtudiant && this.state.curentZonePaiement && this.state.curentTypeFrais && this.state.montant){

            if(parseInt(this.state.nombreTranche)>1){

                //Les questions de montant étant déjà verifiée avant d'arriver à là
                if(parseInt(this.state.nombreTranche) !==frais.length){
                    valide = false
                    this.setState({
                        messageTranche: "Le nombre de tranche ne concorde pas à ce renseigné"
                    })
                }
            }else{
                frais.push({
                    ordreTranche: 1,
                    montantTranche: this.state.montant
                })
            }
        
        }else{
            valide = false
            this.setState({
                messageTranche: "Veuillez renseigner toutes les informations necessaires"
            })
        }

        if(valide){
            
            let objectPaiement = {
                fraisPaiementDto: {
                    montant: this.state.montant,
                    idParcours: this.state.curentParcours.idParcours,
                    idNiveau: this.state.curentNiveau.idNiveau,
                    idZonePaiement: this.state.curentZonePaiement.idZonePaiement,
                    idTypeFraisPaiement: this.state.curentTypeFrais.idTypeFraisPaiement,
                    idStatutEtudiant: this.state.curentStatutEtudiant.idStatutEtudiant,
                },
                listeTranchePaiement: frais
            }

            this.ModelParcours.enregistrerFraisPaiement(objectPaiement).then(data => {
                this.notify.success()

                this.setState({
                    loading: true,
                    viewParametragePaiement: false,
                    tranche: {
                        ordreTranche: 1,
                        montantTranche: ""
                    },
                    manyTranche: false,
                    fraisList: [],
                    messageTranche: "",
                    enableAjoutTranche: true,
                    curentParcours: null,
                    curentStatutEtudiant: null,
                    curentTypeFrais: null,
                    montant: "",
                    curentNiveau: null,
                    curentZonePaiement: null,
                    nombreTranche: '',
                    submitted: false
                })


                this.ModelParcours.getListeFraisPaiement(this.state.idDepartement).then(data => {
                    this.setState({
                        fraisPaiementList: data,
                        loading: false
                    })
        
                }).catch(error => {
                    console.log(error)

                    this.setState({loading: false})
                })


            }).catch(error => {
                //this.notify.echec("Echec de l'enregistrement du frais")
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
            //console.log(objectPaiement)
        }
    }




    /*
    listeExamenPage = (rowData) => {

        let idSession = rowData.idSession
        this.props.history.push('/lmd/parametres/examen/'+idSession)
    }
    */

    ajouterTranche = () => {

        let nombreTranche = this.state.nombreTranche
        let tranche = this.state.tranche
        let montant = this.state.montant
        let fraisList = this.state.fraisList

        let montantTotal = parseInt(tranche.montantTranche)
        fraisList.map(element => {
            montantTotal = parseInt(montantTotal)+parseInt(element.montantTranche)
        })

        if(parseInt(nombreTranche) > parseInt(tranche.ordreTranche) ){
            if(montantTotal<montant){
                fraisList.push(tranche)
                let ordreTranche = parseInt(tranche.ordreTranche) +1
                    this.setState({
                        tranche:{
                            ordreTranche: ordreTranche,
                            montantTranche: (parseInt(ordreTranche)===parseInt(nombreTranche))?(parseInt(montant)-parseInt(montantTotal)):'',
                        },
                        fraisList: fraisList,
                        messageTranche: ""
                    })
            }else{
                this.setState({
                    messageTranche: "Le total des tranches atteint déjà le montant total alors que le nombre de tranche renseigné n'est pas atteint !!!"
                })
            }
        }else{
            //Nous somme à la dernière tranche
            if(parseInt(montantTotal) === parseInt(montant) ){
                fraisList.push(tranche)
                    this.setState({
                        tranche:{
                            ordreTranche: parseInt(tranche.ordreTranche),
                            montantTranche: tranche.montantTranche
                        },
                        fraisList: fraisList,
                        messageTranche: "",
                        enableAjoutTranche: false
                    })
            }else{
                this.setState({
                    messageTranche: "La somme des tranches est différent du montant total des frais, le montant à renseigné a été positionné, veuillez valider !!!",
                    tranche:{
                        ordreTranche: parseInt(tranche.ordreTranche),
                        montantTranche: parseInt(montant)-( parseInt(montantTotal) - parseInt(tranche.montantTranche))
                    },
                })
            }
        } 

    }



    supprimerLesTranche = () => {

        if(this.state.fraisList.length>0){
            let supprime = window.confirm("Voulez-vous vraiment supprimer les tranches déjà paramétré? Si oui vous allez devoir réparametrer les tranches !")
            
            if(supprime){
                this.setState({
                    fraisList: [],
                    tranche:{
                        ordreTranche: 1,
                        montantTranche: ''
                    },
                    enableAjoutTranche: true
                })
            }
        }
    }


    confirmDeleteSession = (frais) => {
        this.setState({
            deleteParametreFraisDialog: true,
            idDeletion: frais.idFraisPaiement
        })
    }




    hideDeleteParametrageFraisDialog = () => {
        this.setState(
            { 
                deleteParametreFraisDialog: false,
                idDeletion: null
            }
        );
    }

    deleteParametreFraisInscription = (idParametrageFrais) => {
        //console.log(idParametrageFrais)


        this.ModelParcours.deleteFraisPaiement(idParametrageFrais).then(data => {
            this.notify.success()
            this.setState({
                loading: true,
                deleteParametreFraisDialog: false,
                idDeletion: null
            })

            this.ModelParcours.getListeFraisPaiement(this.state.idDepartement).then(data => {
                this.setState({
                    fraisPaiementList: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.setState({loading: false})
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec("Echec de la suppression !!!")
            this.setState({ 
                    deleteParametreFraisDialog: false,
                    idDeletion: null
                });
        })

        
    }


    viewListeTranche = (frais) => {

        this.setState({
            showTranche: true,
            listeTranche: frais.listeTranchePaiement,
            detailsFrais: [{
                parcours: frais.parcours.libelleParcours,
                niveau: frais.niveau.libelleNiveau,
                zone: frais.zonePaiement.libelleZonePaiement,
                statutEtudiant: (frais.statutEtudiant)? frais.statutEtudiant.libelleStatutEtudiant:"",
                typeFrais: frais.typeFraisPaiement.libelleTypeFraisPaiement,
                montant: frais.montant
            }]
        })
        //console.log(this.state.detailsFrais)
    }

    hideListeTranche = () => {

        //console.log(this.state.detailsFrais)
        this.setState({
            showTranche: false,
            listeTranche: [],
            detailsFrais: []
        })
    }

    onTypeSessionChange = (e) => {

        let curentSession = this.state.curentSession
        curentSession.typeSession = e.target.value
        this.setState({
            curentSession
        })
    }
    
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-danger" style={{marginRight: '.5em'}} title='Supprimer' onClick={ () => {this.confirmDeleteSession(rowData)} } />
                <Button type="button" icon="pi pi-list" className="p-button-success" style={{marginRight: '.5em'}} title='Liste des tranches' onClick={ () => {this.viewListeTranche(rowData)} } />
            </React.Fragment>
        );
    }

    
    render() {

        if(this.state.redirect) {
            //window.location.reload();
        }
        
        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const deleteParametrageFraisDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteParametrageFraisDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteParametreFraisInscription(this.state.idDeletion)} />
            </React.Fragment>
        );

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const parametrageFooterDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveParametrageFrais} />
            </React.Fragment>
        );

        const trancheFooterDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeTranche} />
            </React.Fragment>
        );
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.fraisPaiementList} selectionMode="single" header="Liste des frais d'inscription et frais de formation" paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="parcours.libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher par parcours"/>
                                <Column field="niveau.libelleNiveau" header="Niveau" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un niveau"/>
                                <Column field="zonePaiement.libelleZonePaiement" header="Zone concernée" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher par une zone"/>
                                <Column field="statutEtudiant.libelleStatutEtudiant" header="Statut étudiant concerné"  sortable={true} filter={true} filterPlaceholder="Rechercher par tatut étudiant concerné"/>
                                <Column field="typeFraisPaiement.libelleTypeFraisPaiement" header="Type de frais"  sortable={true} filter={true} filterPlaceholder="Rechercher par type frais"/>
                                <Column field="montant" header="Montant des frais"  sortable={true} filter={true} filterPlaceholder="Rechercher par montant"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>





                <Dialog visible={this.state.showTranche} style={{ width: '75%' }} header="" modal className="p-fluid" footer={trancheFooterDialogFooter} onHide={this.hideListeTranche}>
                    
                    <DataTable  ref={(el) => this.dt = el} value={this.state.detailsFrais} selectionMode="single" header="Détails du frais" paginator={true} rows={10}
                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="parcours" header="Parcours concerné" filterMatchMode="contains"  sortable={false} filter={false} />
                        <Column field="niveau" header="Niveau concerné" filterMatchMode="contains"  sortable={false} filter={false} />
                        <Column field="zone" header="Zone concernée" filterMatchMode="contains"  sortable={false} filter={false} />
                        <Column field="typeFrais" header="Type de frais" filterMatchMode="contains"  sortable={false} filter={false} />
                        <Column field="statutEtudiant" header="Statut étudiant" filterMatchMode="contains"  sortable={false} filter={false} />
                        <Column field="montant" header="Montant total" filterMatchMode="contains"  sortable={false} filter={false} />
                    </DataTable>
                    <hr/>
                    
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeTranche} selectionMode="single" header="Liste des tranches du frais" paginator={true} rows={10}
                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="ordreTranche" header="Ordre de la tranche" filterMatchMode="contains"  sortable={false} filter={false} />
                        <Column field="montantTranche" header="Montant de la tranche" filterMatchMode="contains"  sortable={false} filter={false} />
                    </DataTable>
                </Dialog>

                <Dialog visible={this.state.viewParametragePaiement} style={{ width: '95%' }} header="Informations concernant les frais de formation" modal className="p-fluid" footer={parametrageFooterDialogFooter} onHide={this.hideDialog}>
                    <hr/>

                    <div className="p-grid">

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le parcours</label>
                                <Dropdown optionLabel="libelleParcours" optionValue={this.state.curentParcours} value={this.state.curentParcours}  options={this.state.parcoursList} onChange={(e) => {this.onParcoursChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder=""/>
                                {this.state.submitted && !this.state.curentParcours && <small className="p-invalid" style={{color : "red"}}>Selectionner le parcours</small>}
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le niveau</label>
                                <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau"  options={this.state.niveauList} value={this.state.curentNiveau} onChange={(e) => {this.onNiveauChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder=""/>
                                {this.state.submitted && !this.state.curentNiveau && <small className="p-invalid" style={{color : "red"}}>Selectionner un niveau</small>}
                            </div>
                        </div>

                    </div>

                    <div className="p-grid">
                        
                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner la zone de paiement</label>
                                <Dropdown optionLabel="libelleZonePaiement" optionValue={this.state.curentZonePaiement} value={this.state.curentZonePaiement}  options={this.state.zonePaiementList} onChange={(e) => {this.onZonePaiementChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder=""/>
                                {this.state.submitted && !this.state.curentZonePaiement && <small className="p-invalid" style={{color : "red"}}>Selectionner la zone de paiement.</small>}
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le statut étudiant concerné</label>
                                <Dropdown optionLabel="libelleStatutEtudiant" optionValue={this.state.curentStatutEtudiant}  options={this.state.statutEtudiantList} value={this.state.curentStatutEtudiant} onChange={(e) => {this.onStatutChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder=""/>
                                {this.state.submitted && !this.state.curentStatutEtudiant && <small className="p-invalid" style={{color : "red"}}>Selectionner un statut étudiant.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="typeFrais">Selectionner le type de frais</label>
                                <Dropdown optionLabel="libelleTypeFraisPaiement" optionValue={this.state.curentTypeFrais} value={this.state.curentTypeFrais}  options={this.state.typeFraisList} onChange={(e) => {this.onTypeFraisChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder=""/>
                                {this.state.submitted && !this.state.curentTypeFrais && <small className="p-invalid" style={{color : "red"}}>Selectionner le type de paiement.</small>}
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="montantTotal">Entrer le montant</label>
                                <input type='number'  value={this.state.montant} onChange={(e) => this.onMontantChange(e)}  placeholder=''  className='p-inputtext p-component'/>
                                {((this.state.submitted && !this.state.montant) || parseInt(this.state.montant)<500)  && <small className="p-invalid" style={{color : "red"}}>Entrer un montant supérieur à 500</small>}
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nombreTranche">Entrer le nombre de tranche</label>
                                <input type='number'  value={this.state.nombreTranche} onChange={(e) => this.onNombreTrancheChange(e)}  placeholder=''  className='p-inputtext p-component'/>
                                {this.state.submitted && !this.state.nombreTranche && <small className="p-invalid" style={{color : "red"}}>Renseigner l'ensemble des valeurs puis entrer le nombre de tranche</small>}
                            </div>
                        </div>

                    </div>
                
                
                    {this.state.manyTranche && 
                        <React.Fragment>
                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'><hr/></div>
                            </div>

                            <div className='row' style={{padding: '5px'}}>
                                <div className='p-col-12 p-md-12 p-lg-6'>
                                    <div className="card">

                                        {this.state.enableAjoutTranche?
                                            (
                                                <React.Fragment>
                                                    <div className="card-header">
                                                        Ajout des tranches de paiement
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="p-grid">
                                                            <div className='p-col-6 p-md-6 p-lg-6'>
                                                                <div className="p-field">
                                                                    <label htmlFor="nombreTranche">Ordre tranche</label>
                                                                    <input type='number'  value={this.state.tranche.ordreTranche} placeholder=''  className='p-inputtext p-component' readOnly/>
                                                                </div>
                                                            </div>
                                                            <div className='p-col-6 p-md-6 p-lg-6'>
                                                                <div className="p-field">
                                                                    <label htmlFor="montantTranche">Montant de la tranche</label>
                                                                    <input type='number'  value={this.state.tranche.montantTranche} onChange={(e) => this.onTrancheInputChange(e, 'montantTranche')}  placeholder=''  className='p-inputtext p-component'/>
                                                                    {this.state.messageTranche && <small className="p-invalid" style={{color : "red"}}>{this.state.messageTranche}</small>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="card-footer">
                                                        <Button label="Ajouter" icon="pi pi-plus" className='p-button p-component p-button-text p-button-text-icon-left' onClick={this.ajouterTranche} />
                                                    </div>
                                                </React.Fragment>
                                            ):(
                                                <React.Fragment>
                                                    <div className="card-header">
                                                        Ajout des tranches de paiement
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="p-grid">
                                                            <div className='p-col-6 p-md-6 p-lg-6'>
                                                                <div className="p-field">
                                                                    <label htmlFor="nombreTranche">Ordre tranche</label>
                                                                    <input type='number'  value={this.state.tranche.ordreTranche} placeholder=''  className='p-inputtext p-component' readOnly/>
                                                                </div>
                                                            </div>
                                                            <div className='p-col-6 p-md-6 p-lg-6'>
                                                                <div className="p-field">
                                                                    <label htmlFor="montantTranche">Montant de la tranche</label>
                                                                    <input type='number'  value={this.state.tranche.montantTranche} onChange={(e) => this.onTrancheInputChange(e, 'montantTranche')}  placeholder=''  className='p-inputtext p-component' readOnly/>
                                                                    {this.state.messageTranche && <small className="p-invalid" style={{color : "red"}}>{this.state.messageTranche}</small>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="card-footer">
                                                        <Button label="Ajouter" icon="pi pi-plus" className='p-button p-component p-button-text p-button-text-icon-left' onClick={this.ajouterTranche} disabled/>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>
                                </div>

                                <div className='p-col-12 p-md-12 p-lg-6'>
                                    <DataTable  ref={(el) => this.dt = el} value={this.state.fraisList} selectionMode="single" header="Liste des tranches du frais" paginator={true} rows={10}
                                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                        <Column field="ordreTranche" header="Ordre de la tranche" filterMatchMode="contains"  sortable={false} filter={false} />
                                        <Column field="montantTranche" header="Montant de la tranche" filterMatchMode="contains"  sortable={false} filter={false} />
                                    </DataTable>
                                    <Button label="Supprimer les tranches" icon="pi pi-trash" className='p-button-danger p-button p-component p-button-text p-button-text-icon-left danger' onClick={this.supprimerLesTranche} />
                                </div>
                            </div>
                        </React.Fragment>
                        
                    }

                </Dialog>

                <Dialog visible={this.state.deleteParametreFraisDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteParametrageFraisDialogFooter} onHide={this.hideDeleteParametrageFraisDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.idDeletion && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

            </div> 
        )

}

}

export default ParametrerFraisPaiement
