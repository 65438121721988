import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../../components/Notify'
import { Spinner } from '../../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { SpecialiteService } from '../../../service/SpecialiteService'
import { ParcoursService } from '../../../service/ParcoursService'
import { Dropdown } from 'primereact/dropdown'
import { SemestreService } from '../../../service/SemestreService'
import { PickList } from 'primereact/picklist';


export class Parcours extends Component {
    constructor(props){
        super(props);

        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelSpecialite = new SpecialiteService();
        this.ModelParcours = new ParcoursService();
        this.ModelSemestre = new SemestreService ();

        this.state = {
            curentParcours: {
                idParcours: '',
                libelleParcours: '',
                specialite: {},
                semestreEntrant: {},
                semestreSortant: {}
            },
            specialiteList: [],
            parcoursList: [],
            listeSemestre : [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateParcours: false,
            allowSave: false,
            viewDeleteParcoursDialog: false,
            deletionId: null,
            messageNiveau: null,
            viewAddParcoursEnfant: false,
            listeParcoursCandidat: [],
            listeParcoursChoix: [],
            allowAddChild: false,
            editParcoursDialog: false,
            viewSelectSpecialiteDialog: false,
            viewSelectSemestreDialog: false,
            typeSemestreChoix: null
        }
    }

    
    componentDidMount(){
        //Temoin de chargement
        this.setState({loading : true})
        this.ModelParcours.getAll().then(data => {

            this.setState({
                parcoursList: data
            })
            this.setState({loading : false})
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        //On recupère la liste des domaine
        this.ModelSpecialite.getAllSansObjet().then(data => {
            this.setState({
                specialiteList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        this.ModelSemestre.getAll().then(data => {
                this.setState({
                    listeSemestre: data
                })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
        
    }

    editParcours = (rowData) => {

        this.setState({
            curentParcours: rowData,
            editParcoursDialog: true
        })
        
    }

    ajoutEnfant = (rowData) => {

        //Liste des parcours enfant
        let listeIdParcoursChoisie = []
        this.ModelParcours.getParcoursEnfant(parseInt(rowData.idParcours)).then(data => {

            this.setState({
                listeParcoursChoix: data,
                allowAddChild: this.testChildValid(data)
            })

            //On constitue la liste des ID parcours choisis
            data.map(parcours => {
                listeIdParcoursChoisie.push(parcours.idParcours)
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        
        //Liste des parcours candidats
        this.ModelParcours.getParcoursCandidat(parseInt(rowData.idSemestreSortant)).then(data => {

            let listeParcoursDisponible = []

            //On extrait la liste des parcours qui sont déjà dans les debouchés du parcours
            listeParcoursDisponible = data.filter(parcours => {
                return (listeIdParcoursChoisie.indexOf(parcours.idParcours)===-1)
            })

            this.setState({
                listeParcoursCandidat: listeParcoursDisponible
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        this.setState({
            viewAddParcoursEnfant: true,
            curentParcours: rowData
        })
    }

    confirmDeleteParcours = (rowData) => {
        
        this.setState({
            viewDeleteParcoursDialog: true,
            deletionId: rowData.idParcours
        })
    }

    hideDeleteParcoursDialog = () => {
        this.setState({
            viewDeleteParcoursDialog: false
        })
    }

    deleteParcours = (idDeletion) => {
        this.ModelParcours.delete(parseInt(idDeletion)).then(data => {
            this.notify.success()


            this.setState({
                loading: true
            })
            this.ModelParcours.getAll().then(data => {
                this.setState({
                    parcoursList: data
                })
                this.setState({loading : false})
                }
            )
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({loading : false})
            })

            this.setState({
                deletionId: null,
                viewDeleteParcoursDialog: false,
                redirect: true
            })
            
        }).catch(error => {
            
            this.notify.echec(error.response.data.message)
            console.log(error)
            
            this.setState({
                viewDeleteParcoursDialog: false,
                deletionId: null
            })

        })
    }

    hideDialog = () => {
        
        this.setState({
            curentParcours: {
                idParcours: '',
                libelleParcours: '',
                specialite: {},
                semestreEntrant: {},
                semestreSortant: {}
            },
            viewCreateParcours: false
        })
    }

    hideSelectSpecialiteDialog = () => {
        this.setState({
            viewSelectSpecialiteDialog: false
        })
    }

    hideSelectSemestreDialog = () => {
        this.setState({
            viewSelectSemestreDialog: false
        })
    }



    hideEditDialog = () => {
        
        this.setState({
            curentParcours:  {
                idParcours: '',
                libelleParcours: '',
                specialite: {},
                semestreEntrant: {},
                semestreSortant: {}
            },
            editParcoursDialog: false
        })
    }

    hideSousParcoursDialog = () => {
        this.setState({
            viewAddParcoursEnfant: false,
            listeParcoursCandidat: [],
            listeParcoursChoix: []
        })
    }



    saveParcours = () => {
        
        let newParcours = {
            idParcours: parseInt(this.state.curentParcours.idParcours),
            idSpecialite: parseInt(this.state.curentParcours.specialite.idSpecialite),
            libelleParcours: this.state.curentParcours.libelleParcours,
            idSemestreEntrant: this.state.curentParcours.semestreEntrant.idSemestre,
            idSemestreSortant: this.state.curentParcours.semestreSortant.idSemestre
        }
        
        //On peut proceder à la mise à jour ou à l'ajout de la spécialité
        if(newParcours.idParcours){

            this.ModelParcours.update(newParcours.idParcours, newParcours).then(data => {
                this.notify.success()
                this.setState({
                    viewCreateParcours: false,
                    redirect: true,
                    editParcoursDialog: false
                })
                this.ModelParcours.getAll().then(data => {
                    this.setState({
                        parcoursList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }else{

            this.ModelParcours.create(newParcours).then(data => {
                this.notify.success()
                this.setState({ 
                    redirect: true,
                    viewCreateParcours: false,
                    editParcoursDialog: false
                })
                this.ModelParcours.getAll().then(data => {
                    this.setState({
                        parcoursList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }
        

    }


    saveEditParcours = () => {


        this.ModelParcours.update(this.state.curentParcours.idParcours, this.state.curentParcours).then(data => {
            this.notify.success()
            this.setState({
                viewCreateParcours: false,
                redirect: true,
                editParcoursDialog: false
            })
            this.ModelParcours.getAll().then(data => {
                this.setState({
                    parcoursList: data,
                    loading : false
                })
            })
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({loading : false})
            })
        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)

        })

    
    }


    //Gestion de l'ouverture du formulaire
    openNew = () => {

        this.setState(
            {
                viewCreateParcours: true
            }
        )
    }

    editerParcours = (parcours) => {

        //console.log(parcours)
        this.setState({
            curentParcours: parcours,
            editParcoursDialog: true,
            allowSave: this.authorisationSaveEdit(parcours)
        })
    }

    onInputChange = (event, field) => {

        let curentParcours = this.state.curentParcours
        curentParcours[`${field}`] = event.target.value
        
        if(field==="semestreEntrant" || field==="semestreSortant"){

            if(curentParcours.semestreEntrant && curentParcours.semestreSortant){
                
                let ordreEntre = curentParcours.semestreEntrant.ordreSemestre
                let ordreSortie = curentParcours.semestreSortant.ordreSemestre

                if(ordreEntre>=ordreSortie)
                {
                    this.setState({
                        messageNiveau: "Le niveau de sortie doit être supérieur au niveau d'entré !!!"
                    })
                }else{
                    this.setState({
                        messageNiveau: null
                    })
                }
                 
            }

           
        }

        if(this.state.editParcoursDialog){
            this.setState({
                curentParcours: curentParcours,
                allowSave: this.authorisationSaveEdit(curentParcours)
            })
        }else{
            this.setState({
                curentParcours: curentParcours,
                allowSave: this.authorisationSave(curentParcours)
            })
        }
        
 
    }


    selectionSpecialite = (specialite) => {
        let parcours = this.state.curentParcours

        parcours["specialite"] = specialite
        this.setState({
            curentParcours: parcours,
            viewSelectSpecialiteDialog: false
        })
    }


    selectionSemestre = (semestre) => {

        let parcours = this.state.curentParcours

        if(this.state.typeSemestreChoix && this.state.typeSemestreChoix === "entree"){
            parcours["semestreEntrant"] = semestre
        }else{
            parcours["semestreSortant"] = semestre
        }

        if(parcours.semestreEntrant && parcours.semestreSortant){
                
            let ordreEntre = parcours.semestreEntrant.ordreSemestre
            let ordreSortie = parcours.semestreSortant.ordreSemestre

            if(ordreEntre>=ordreSortie)
            {
                this.setState({
                    messageNiveau: "Le niveau de sortie doit être supérieur au niveau d'entré !!!"
                })
            }else{
                this.setState({
                    messageNiveau: null
                })
            }
             
        }

        this.setState({
            curentParcours: parcours,
            viewSelectSemestreDialog: false
        })
    }


    openSelectionSemestreEntree = () => {
        this.setState({
            typeSemestreChoix: "entree",
            viewSelectSemestreDialog: true
        })
    }


    openSelectionSemestreSortie = () => {
        this.setState({
            typeSemestreChoix: "sortie",
            viewSelectSemestreDialog: true
        })
    }


    //typeSemestreChoix



    authorisationSave = (parcours) => {

        return (
                parcours.libelleParcours
                &&
                (parcours.libelleParcours.trim()).length>=2
                && 
                parcours.specialite
                && 
                parcours.semestreEntrant
                && 
                parcours.semestreSortant
                &&
                parcours.semestreEntrant.ordreSemestre<=parcours.semestreSortant.ordreSemestre
            )? true:false
    }


    authorisationSaveEdit = (parcours) => {

        return (
                parcours.libelleParcours
                &&
                (parcours.libelleParcours.trim()).length>=2
                && 
                parcours.specialite
                &&
                parcours.semestreEntrant
                && 
                parcours.semestreSortant
                &&
                parcours.semestreEntrant.ordreSemestre<=parcours.semestreSortant.ordreSemestre
            )? true:false
    }
                  
    //Fonction renvoyant le grade du semestre



    onParcoursSelect = (e) => {
        this.setState({
            listeParcoursCandidat: e.source,
            listeParcoursChoix: e.target,
            allowAddChild: this.testChildValid(e.target)
        })
    }

    testChildValid = (listeEnfant) => {
        return listeEnfant.length>=0
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-success" onClick={() => this.editerParcours(rowData)} title='Edition du parcours'/>
                <Button type="button" icon="pi pi-sitemap" className="p-button-warning" onClick={() => this.ajoutEnfant(rowData)} title='Parcours associés'/>
                {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteParcours(rowData)} title='Suppression'/>*/}
            </React.Fragment>
        );
    }


    actionSelectSpecialiteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={() => this.selectionSpecialite(rowData)} title='Sélectionner la specialité'/>
            </React.Fragment>
        );
    }

    actionSelectSemestreBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={() => this.selectionSemestre(rowData)} title='Sélectionner le semestre'/>
            </React.Fragment>
        );
    }

    itemTemplate = (item) => {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleParcours} ( de {item.libelleSemestreEntrant} à {item.libelleSemestreSortant})</h6>
                </div>
            </div>
        );
    }


    saveSousParcours = () => {


        this.ModelParcours.saveParcoursShield(parseInt(this.state.curentParcours.idParcours), this.state.listeParcoursChoix).then(data => {
            this.notify.success()
            this.setState({
                viewAddParcoursEnfant: false
            })
        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
    }

    render() {
        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

       const deleteParcoursDialogFooter = (
        <React.Fragment>
            <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteParcoursDialog} />
            <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteParcours(this.state.deletionId) } />
        </React.Fragment>
        )

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveParcours} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        
        const parcoursDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );


        const selectSpecialiteDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSpecialiteDialog} />
            </React.Fragment>
        )

        const selectSemestreDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSemestreDialog} />
            </React.Fragment>
        )


        const editParcoursDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideEditDialog} />
                {this.state.allowSave ? 
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveEditParcours} />:
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />}
            </React.Fragment>
        );

        let allowSaveSousParcours = (this.state.allowAddChild)?
        (<Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.saveSousParcours} />):
        (<Button label="Valider" icon="pi pi-check" className="p-button-text" disabled />);
        
        const sousParcoursDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSousParcoursDialog} />
                {allowSaveSousParcours}
            </React.Fragment>
        );


   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable ref={(el) => this.dt = el} value={this.state.parcoursList} selectionMode="single" header={`Liste des parcours (${this.state.parcoursList.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleParcours" header="Parcours"  sortable={true} filter={true} filterPlaceholder="rechercher parcours"/>
                                <Column field="libelleSpecialite" header="Specialité"  sortable={true} filter={true} filterPlaceholder="rechercher spécialité"/>
                                <Column field="libelleSemestreEntrant" header="Niveau d'entré"  sortable={true} filter={true} filterPlaceholder="rechercher niveau d'entré"/>
                                <Column field="libelleSemestreSortant" header="Niveau de sortie"  sortable={true} filter={true} filterPlaceholder="rechercher niveau de sortie"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                <Dialog visible={this.state.viewAddParcoursEnfant} style={{ width: '90%', height: 'auto' }} header='Définition hiérarchie' modal className="p-fluid" footer={sousParcoursDialogFooter} onHide={this.hideSousParcoursDialog}>
                    <hr/>
                    <h6>Détails du parcours concerné</h6>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-8">
                            <label>Parcours</label>
                            <input type="text" className="p-inputtext p-component"  readOnly="readOnly" value={this.state.curentParcours.libelleParcours}/>
                        </div>
                        <div className="p-field p-col-2">
                            <label>Semestre d'entrée</label>
                            <input type="text" className="p-inputtext p-component"  readOnly="readOnly" value={this.state.curentParcours.libelleSemestreEntrant? this.state.curentParcours.libelleSemestreEntrant:""}/>
                        </div>
                        <div className="p-field p-col-2">
                            <label>Semestre de sortie</label>
                            <input type="text" className="p-inputtext p-component"  readOnly="readOnly" value={this.state.curentParcours.libelleSemestreSortant? this.state.curentParcours.libelleSemestreSortant:""}/>
                        </div>
                    </div>

                    <hr/>
                    <h6>Choisir les parcours disponibles en option apres validation du parcours</h6>
                    <hr/>
                            
                    <div className="p-field" style={{marginTop : 30}}>
                        <PickList source={this.state.listeParcoursCandidat} target={this.state.listeParcoursChoix} itemTemplate={this.itemTemplate}
                            sourceHeader="Parcours candidats" targetHeader="Liste des parcours en option" 
                            sourceStyle={{ height: '300px', width : '400px' }} targetStyle={{ width : '400px' ,height: '300px' }}
                            onChange={this.onParcoursSelect}>
                        </PickList>
                    </div>
                </Dialog>
                
                <Dialog visible={this.state.viewCreateParcours} style={{ width: '90%', height: 'auto' }} header="Informations du parcours" modal className="p-fluid" footer={parcoursDialogFooter} onHide={this.hideDialog}>
                    
                    <div className="p-field">
                        <label htmlFor="libelleParcours">Parcours de la formation</label>
                        <InputText id="libelleParcours" value={this.state.curentParcours.libelleParcours}  onChange={(e) => this.onInputChange(e, 'libelleParcours')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Renseigner le parcours"/>
                        {(!this.state.curentParcours.libelleParcours || this.state.curentParcours.libelleParcours.length<2) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit compter au moins 2 caractères.</small>}
                    </div>

                    <hr/>
                    <div className="p-field">
                        <label htmlFor="libelleSpecialite">Specialité de la formation</label>

                        <div className="p-grid p-lg-12">
                            <div className="p-field p-col-11">
                                <Dropdown optionLabel="libelleSpecialite" optionValue="idSpecialite" value={this.state.curentParcours.specialite} options={this.state.specialiteList} onChange={(e) => {this.onInputChange(e, 'specialite')}} required  className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner la spécialité"/>
                                {!this.state.curentParcours.specialite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                            <div className="p-field p-col-1">
                                <Button type="button" icon="pi pi-search" className="p-button-info"  onClick={() => { this.setState({viewSelectSpecialiteDialog: true})}} />
                            </div>
                        </div>

                    </div>
                    <hr />

                    <div className="p-field">
                        <label htmlFor="libelleNiveau1">Niveau d'entré</label>

                        <div className="p-grid p-lg-12">
                            <div className="p-field p-col-11">
                                <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.curentParcours.semestreEntrant} options={this.state.listeSemestre} onChange={(e) => {this.onInputChange(e, 'semestreEntrant')}} required  className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le semestre d'entrée"/>
                                {!this.state.curentParcours.semestreEntrant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                            <div className="p-field p-col-1">
                                <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.openSelectionSemestreEntree} />
                            </div>
                        </div>
                        
                    </div>
                    <hr />


                    <div className="p-field">
                        <label htmlFor="libelleNiveau">Niveau de sortie</label>
                        <div className="p-grid p-lg-12">
                            <div className="p-field p-col-11">
                                <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.curentParcours.semestreSortant} options={this.state.listeSemestre} onChange={(e) => {this.onInputChange(e, 'semestreSortant')}} required  className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le niveau de sortie"/>
                                {!this.state.curentParcours.semestreSortant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                {this.state.messageNiveau && <small className="p-invalid" style={{color : "red"}}>Le niveau de sortie doit être superieur au niveau d'entrée.</small>}
                            </div>
                            <div className="p-field p-col-1">
                                <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.openSelectionSemestreSortie} />
                            </div>
                        </div>
                    </div>

                </Dialog>




                <Dialog visible={this.state.viewDeleteParcoursDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteParcoursDialogFooter} onHide={this.hideDeleteParcoursDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>


                <Dialog visible={this.state.editParcoursDialog} style={{ width: '75%', height: 'auto' }} header="Edition du parcours" modal className="p-fluid" footer={editParcoursDialogFooter} onHide={this.hideEditDialog}>
                   <hr/>

                    <div className='p-grid p-lg-12'>
                        
                            <div className='p-col-6 p-md-6 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="libelleParcours">Parcours de la formation</label>
                                    <div className="p-grid p-lg-12">
                                        <div className="p-field p-col-12">
                                            <InputText id="libelleParcours"  value={this.state.curentParcours.libelleParcours}  onChange={(e) => this.onInputChange(e, 'libelleParcours')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Renseigner le parcours"/>
                                            {(!this.state.curentParcours.libelleParcours || this.state.curentParcours.libelleParcours.length<2) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit compter au moins 2 caractères.</small>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='p-col-6 p-md-6 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="libelleSpecialite">Specialité de la formation</label>


                                    <div className="p-grid p-lg-12">
                                        <div className="p-field p-col-11">
                                            <Dropdown optionLabel="libelleSpecialite" optionValue={this.state.curentParcours.specialite} value={this.state.curentParcours.specialite} options={this.state.specialiteList} onChange={(e) => {this.onInputChange(e, 'specialite')}} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner la spécialité"/>
                                            {!this.state.curentParcours.specialite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>
                                        <div className="p-field p-col-1">
                                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={() => { this.setState({viewSelectSpecialiteDialog: true})}} />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        
                    </div>

                    <div className='p-grid p-lg-12'>
                        
                            <div className='p-col-6 p-md-6 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="libelleNiveau1">Niveau d'entré</label>
                                    <div className="p-grid p-lg-12">
                                        <div className="p-field p-col-11">
                                            <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.curentParcours.semestreEntrant} options={this.state.listeSemestre} onChange={(e) => {this.onInputChange(e, 'semestreEntrant')}} required  className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le semestre d'entrée"/>
                                            {!this.state.curentParcours.semestreEntrant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>
                                        <div className="p-field p-col-1">
                                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.openSelectionSemestreEntree}/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='p-col-6 p-md-6 p-lg-6'>
                                <div className="p-field">
                                    <label htmlFor="libelleNiveau">Niveau de sortie</label>

                                    <div className="p-grid p-lg-12">
                                        <div className="p-field p-col-11">
                                            <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.curentParcours.semestreSortant} options={this.state.listeSemestre} onChange={(e) => {this.onInputChange(e, 'semestreSortant')}} required  className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le niveau de sortie"/>
                                            {!this.state.curentParcours.semestreSortant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                            {this.state.messageNiveau && <small className="p-invalid" style={{color : "red"}}>Le niveau de sortie doit être superieur au niveau d'entrée.</small>}
                                        </div>
                                        <div className="p-field p-col-1">
                                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.openSelectionSemestreSortie}/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                    </div>

                </Dialog>


                <Dialog visible={this.state.viewSelectSpecialiteDialog} style={{ width: '75%', height: 'auto' }} header="Sélection de la spécialité" modal className="p-fluid" footer={selectSpecialiteDialogFooter} onHide={this.hideSelectSpecialiteDialog}>
                    <DataTable ref={(el) => this.dt = el} value={this.state.specialiteList} header="Liste des spécialités disponibles" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="libelleSpecialite" header="Spécialités" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher une specialité"/>
                        <Column header={actionHeader} body={this.actionSelectSpecialiteBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                </Dialog>



                <Dialog visible={this.state.viewSelectSemestreDialog} style={{ width: '75%', height: 'auto' }} header="Sélection du semestre" modal className="p-fluid" footer={selectSemestreDialogFooter} onHide={this.hideSelectSemestreDialog}>
                    <DataTable ref={(el) => this.dt = el} value={this.state.listeSemestre} header="Liste des semestres disponibles" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="libelleSemestre" header="Semestre"  sortable={true} filter={true} filterMatchMode="contains"  filterPlaceholder="rechercher un semestre"/>
                        <Column header={actionHeader} body={this.actionSelectSemestreBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                </Dialog>


            </div> 
        )

}

}

export default Parcours
