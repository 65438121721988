import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { ExamenService } from '../../service/ExamenService'
import { ECService } from '../../service/ECService'
import { TypeActiviteService } from '../../service/TypeActiviteService'
import { SessionExamenService } from '../../service/SessionExamenService'
import { ParamNoteService } from '../../service/ParamNoteService'
import { ParcoursUEService } from '../../service/ParcoursUEService'
import { Dropdown } from 'primereact/dropdown'

export class Examen extends Component {

    //Model de l'objet Examen
    emptyExamen = {
        idExamen: null,
        libelleExamen: null,
        dureeExamen: null,
        intituleExamen: null,
        dateExamen: null,
        heureExamen: null,
        observationExamen: null,
        elementConstitutif: null,
        paramNote: null,
        typeActivite: null,
        session: null
    }
    

    constructor(props){

        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.ModelExamen = new ExamenService()
        this.ModelEConstitutif = new ECService()
        this.ModelSession = new SessionExamenService()
        this.ModelTypeActivite = new TypeActiviteService()
        this.ModelParamNote = new ParamNoteService()
        this.ModelParcoursUE = new ParcoursUEService()

        this.state = {
            curentExamen: {},
            curentSession: null,
            paramNoteList: [],
            typeActiviteList: [],
            examenList: [],
            ueList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateExamenDialog: false,
            allowSave: false,
            curentUE: null,
            elementConstitutifList: [],
            viewDeleteExamenDialog: false,
            deletionId: null,
            spin: false
        }

        //On recupère l'id de la session
        const idSession =  props.match.params.idSession
        this.ModelSession.getSessionById(parseInt(idSession)).then(data => {
            let curentExamen = this.emptyExamen
            console.log(data)
            let idSemestre = data.data[0].anneeSemestre.idSemestre
            let idParcours = data.data[0].parcoursFiliere.parcours.idParcours

            this.ModelParcoursUE.get(idParcours, idSemestre).then(data => {

                this.setState({
                    ueList: data
                })
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })

            curentExamen.session = data.data[0]

            this.setState({
                curentSession: data.data[0],
                curentExamen: curentExamen
            })


            //Temoin de chargement
            this.setState({loading : true})
            this.ModelExamen.examenBySession(idSession).then(data => {
                this.setState({
                    examenList: data
                })
                this.setState({loading : false})
                }
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })

        }).catch(error =>{
            console.log(error)
            this.notify.echec(error.response.data.message)
        })  
    }

    componentDidMount(){

        //On recupère la liste des type activite
        this.ModelTypeActivite.getAll().then(data => {
            this.setState({
                typeActiviteList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        //On recupère la liste des param notes
        this.ModelParamNote.getAll().then(data => {
            this.setState({
                paramNoteList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        //On recupère la liste des param notes
        this.ModelParamNote.getAll().then(data => {
            this.setState({
                paramNoteList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    
    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                curentExamen: this.state.curentExamen,
                viewCreateExamenDialog: true
            }
        )
    } 

    onUEChange = (e) => {

        this.setState({
            curentUE: e.target.value,
            elementConstitutifList: e.target.value.uniteEnseignement.listeElementConstitutif
        })
    }

    onSelectChange = (e, field) => {

        let curentExamen = this.state.curentExamen
        curentExamen[`${field}`] = e.target.value
        this.setState(
            {
                curentExamen,
                allowSave: this.testValidation(curentExamen)
            }
        )
    }

    hideDialog = () => {

        let emptyExamen = {
            idExamen: null,
            libelleExamen: null,
            dureeExamen: null,
            intituleExamen: null,
            dateExamen: null,
            heureExamen: null,
            observationExamen: null,
            elementConstitutif: null,
            paramNote: null,
            typeActivite: null,
            session: this.state.curentSession
        }

        this.setState({
            curentExamen: emptyExamen,
            viewCreateExamenDialog: false
        })
    }


    testValidation = (examen) =>{
        return (
                    examen.libelleExamen
                    && examen.dureeExamen
                    && examen.intituleExamen
                    && examen.dateExamen
                    && examen.heureExamen
                    && examen.elementConstitutif
                    && examen.typeActivite
                    && examen.session
        ) ? true:false
    }
    
    saveExamen = () => {

        let examen = this.state.curentExamen
        let session = this.state.curentSession

        //console.log(examen);
        this.setState({
            spin: true
        })

        console.log('Creation examen')

        this.ModelExamen.create(examen).then(data => {

            let emptyExamen = {
                idExamen: null,
                libelleExamen: null,
                dureeExamen: null,
                intituleExamen: null,
                dateExamen: null,
                heureExamen: null,
                observationExamen: null,
                elementConstitutif: null,
                paramNote: null,
                typeActivite: null,
                session: session
            }
    
            this.setState({
                curentExamen: emptyExamen,
                viewCreateExamenDialog: false,
                spin: false
            })

            //On recharge la liste des examen de la session
            this.setState({loading : true})
            this.ModelExamen.examenBySession(parseInt(session.idSession)).then(data => {
                this.setState({
                    examenList: data
                })
                this.setState({loading : false})
                }
            )
            .catch(error => { 
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({loading : false})
            })
            this.notify.success()

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({
                viewCreateExamenDialog: false,
                spin: false
            })
        })
    }

    listeNoteExamen = (dataRow) => {

        let idExamen = dataRow.idExamen
        this.props.history.push('/lmd/parametres/notation/'+idExamen)
    }

    deleteExamen = (rowData) => {
        this.setState({
            deletionId: rowData.idExamen,
            viewDeleteExamenDialog: true
        })
    }

    confirmDeleteExamen = (idExamen) => {

        //Suppression de l'examen
        let session = this.state.curentSession
        this.ModelExamen.delete(parseInt(idExamen)).then(data => {

                //On recupère de nouveau la liste des examen pour la mise à jour de la liste
                this.ModelExamen.examenBySession(parseInt(session.idSession)).then(data => {
                    this.setState({
                        examenList: data,
                        viewDeleteExamenDialog: false,
                        deletionId: null
                    })
                    }
                )
                .catch(error => {
                    console.log(error)
                })
                this.notify.success()
            }
        )
        .catch(error => { 
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({
                viewDeleteExamenDialog: false,
                deletionId: null
            })
        })
    }

    hideDeleteExamenDialog = () => {
        this.setState({
            deletionId: null,
            viewDeleteExamenDialog: false
        })
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-list" className="p-button-warning" style={{marginRight: '.5em'}} title="Liste des notes" onClick={ () => this.listeNoteExamen(rowData) } />
                <Button type="button" icon="pi pi-trash" className="p-button-danger" style={{marginRight: '.5em'}} title="Supprimer l'évaluation" onClick={ () => this.deleteExamen(rowData) } />
            </React.Fragment>
        );
    }

    
    render() {

        //console.log(this.state.paramNoteList)
        if(this.state.redirect) {
            window.location.reload();
        }

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const deleteExamenFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteExamenDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.confirmDeleteExamen(this.state.deletionId) } />
            </React.Fragment>
        );

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon={this.state.spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-text" onClick={this.saveExamen} disabled={this.state.spin?true:false} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const sessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} disabled={this.state.spin?true:false} />
                {allowSave}
            </React.Fragment>
        );

        let titreDatatable = this.state.curentSession? `Liste des examens de la session ${this.state.curentSession.libelleSession}`: ''
   
        return (


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                                <div className="p-grid">
                                <div className="p-col-6" ></div>
                                <div className="p-col-6" >
                                    {this.spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.examenList} selectionMode="single" header={titreDatatable} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif"  sortable={true} filter={true} filterPlaceholder="intitulé de l'élement constitutif"/>
                                <Column field="libelleExamen" header="Libellé examen"  sortable={true} filter={true} filterPlaceholder="rechercher libellé"/>
                                <Column field="dureeExamen" header="Durée examen"  sortable={true} filter={true} filterPlaceholder="durée examen"/>
                                <Column field="dateExamen" header="Date examen"  sortable={true} filter={true} filterPlaceholder="date examen"/>
                                <Column field="heureExamen" header="Heure examen"  sortable={true} filter={true} filterPlaceholder="heure examen"/>
                                <Column field="observationExamen" header="Observation"  sortable={true} filter={true} filterPlaceholder="observation"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>
                
                <Dialog visible={this.state.viewCreateExamenDialog} style={{ width: '80%' }} header="Informations de la session" modal className="p-fluid" footer={sessionDialogFooter} onHide={this.hideDialog}>
                    
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleUniteEnseignement">Selectionner l'unité d'enseignement</label>
                                <Dropdown optionLabel="libelleUniteEnseignement" optionValue="idUniteEnseignement" value={this.state.curentUE} options={this.state.ueList} onChange={(e) => {this.onUEChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'unité d'enseignement"/>
                                {!this.state.curentUE && <small className="p-invalid" style={{color : "red"}}>Selectionner l'unité d'enseignement.</small>}
                            </div>
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleElementConstitutif">Selectionner l'élement constitutif</label>
                                <Dropdown optionLabel="libelleElementConstitutif" optionValue="idElementConstitutif" value={this.state.curentExamen.elementConstitutif}  options={this.state.elementConstitutifList} onChange={(e) => {this.onSelectChange(e, 'elementConstitutif')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'élement constitutif"/>
                                {!this.state.curentExamen.elementConstitutif && <small className="p-invalid" style={{color : "red"}}>Selectionner l'élement constitutif.</small>}
                            </div>
                        </div>

                    </div>
                
                    
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleTypeActivite">Selectionner le type d'activité</label>
                                <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={this.state.curentExamen.typeActivite} options={this.state.typeActiviteList} onChange={(e) => {this.onSelectChange(e, 'typeActivite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le type d'activite"/>
                                {!this.state.curentExamen.typeActivite && <small className="p-invalid" style={{color : "red"}}>Selectionner le type d'activite.</small>}
                            </div>
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="valParam">Selectionner le taux (facultatif)</label>
                                <Dropdown optionLabel="valParam" optionValue="idParamNote" value={this.state.curentExamen.paramNote}  options={this.state.paramNoteList} onChange={(e) => {this.onSelectChange(e, 'paramNote')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le taux"/>
                            </div>
                        </div>

                    </div>


                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <label htmlFor="libelleExamen">Libellé Examen</label>
                            <InputText id="libelleExamen" value={this.state.curentExamen.libelleExamen}  onChange={(e) => this.onSelectChange(e, 'libelleExamen')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Entrer le libelle de l'examen"/>
                            {!this.state.curentExamen.libelleExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dureeExamen">Entrer la durée de l'examen</label>
                            <InputText id="dureeExamen" value={this.state.curentExamen.dureeExamen}  onChange={(e) => this.onSelectChange(e, 'dureeExamen')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Entrer la durée de l'examen"/>
                            {!this.state.curentExamen.dureeExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="intituleExamen">Entrer l'intitulé de l'examen</label>
                            <InputText id="intituleExamen" value={this.state.curentExamen.intituleExamen}  onChange={(e) => this.onSelectChange(e, 'intituleExamen')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Entrer l'intitulé de l'examen'"/>
                            {!this.state.curentExamen.intituleExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateExamen">Entrer la date de l'examen</label>
                            <input type="date" id="dateExamen" value={this.state.curentExamen.dateExamen}  onChange={(e) => this.onSelectChange(e, 'dateExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer la date de l'examen"/>
                            {!this.state.curentExamen.dateExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="heureExamen">Entrer l'heure de l'examen</label>
                            <input type="time" id="heureExamen" value={this.state.curentExamen.heureExamen}  onChange={(e) => this.onSelectChange(e, 'heureExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer l'heure de l'examen"/>
                            {!this.state.curentExamen.heureExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <label htmlFor="observationExamen">Observation</label>
                            <textarea id="observationExamen" value={this.state.curentExamen.observationExamen} onChange={(e) => this.onSelectChange(e, 'observationExamen')}  placeholder="Description" className='p-inputtext p-component' />
                        </div>
                    </div>

                </Dialog>


                <Dialog visible={this.state.viewDeleteExamenDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteExamenFooter} onHide={this.hideDeleteExamenDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                
            </div> 
        )

    }

}
export default Examen
