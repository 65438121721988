import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { ECService } from '../../service/ECService';
import { UEService } from '../../service/UEService';
import { Dropdown } from 'primereact/dropdown';

export class Econstitutif extends Component {

    constructor(props){
        super(props);

        //Model de l'objet ElementConstitutif
        let emptyEC = {
            idElementConstitutif: '',
            uniteEnseignement: null,
            libelleElementConstitutif: '',
            codeElementConstitutif: '',
            creditElementConstitutif: '',
            nbHeureElementConstitutif: '',
            nbHeureTD: '',
            nbHeureTP: '',
            nbHeureCours: '',
            nbHeureTravauxPersonnel: '',
            ordreElementConstitutif: ''
        }

        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelEConstitutif = new ECService();
        this.ModelUEnseignement = new UEService();

        this.state = {
            curentEC: emptyEC,
            UEList: [],
            ECList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateEC: false,
            allowSave: false,
            viewDeleteECDialog: false,
            deletionId: null,
            errorMessage: null
        }
    }

    componentDidMount(){
        //Temoin de chargement
        this.setState({loading : true})

        this.ModelEConstitutif.getAll().then(data => {
            this.setState({
                ECList: data
            })
            this.setState({loading : false})
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        //On recupère la liste des domaine
        //this.ModelUEnseignement.getAllSansObjet().then(data => {
        this.ModelUEnseignement.getAll().then(data => {
                this.setState({
                    UEList: data
                })
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
    }
    
    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                viewCreateEC: true
            }
        )
    }

    hideDialog = () => {

        //Model de l'objet ElementConstitutif
        let emptyEC = {
            idElementConstitutif: '',
            uniteEnseignement: null,
            libelleElementConstitutif: '',
            codeElementConstitutif: '',
            creditElementConstitutif: '',
            nbHeureElementConstitutif: '',
            nbHeureTD: '',
            nbHeureTP: '',
            nbHeureCours: '',
            nbHeureTravauxPersonnel: '',
            ordreElementConstitutif: ''
        }

        this.setState({
            curentEC: emptyEC,
            viewCreateEC: false
        })
    }

    onInputChange = (event, field) => {

        let curentEC = this.state.curentEC

        curentEC[`${field}`] = event.target.value

        let allowSave = this.testAllowSave(curentEC)

        this.setState({
            curentEC: curentEC,
            allowSave: allowSave
        })
 
    }

    saveElementConstitutif = () => {

        let nbHeures=0;

        if(!isNaN(this.state.curentEC.nbHeureCours)){
            nbHeures = nbHeures + parseInt(this.state.curentEC.nbHeureCours)
        }

        if(!isNaN(this.state.curentEC.nbHeureTD)){
            nbHeures = nbHeures + parseInt(this.state.curentEC.nbHeureTD)
        }

        if(!isNaN(this.state.curentEC.nbHeureTP)){
            nbHeures = nbHeures + parseInt(this.state.curentEC.nbHeureTP)
        }

        if(!isNaN(this.state.curentEC.nbHeureTravauxPersonnel)){
            nbHeures = nbHeures + parseInt(this.state.curentEC.nbHeureTravauxPersonnel)
        }
        
        let newEC = {
            idElementConstitutif: parseInt(this.state.curentEC.idElementConstitutif),
            idUniteEnseignement: parseInt(this.state.curentEC.uniteEnseignement.idUniteEnseignement),
            libelleElementConstitutif: this.state.curentEC.libelleElementConstitutif,
            codeElementConstitutif: this.state.curentEC.codeElementConstitutif,
            creditElementConstitutif: parseInt(this.state.curentEC.creditElementConstitutif),
            nbHeureElementConstitutif: nbHeures,
            nbHeureTD: parseInt(this.state.curentEC.nbHeureTD),
            nbHeureTP: parseInt(this.state.curentEC.nbHeureTP),
            nbHeureCours: parseInt(this.state.curentEC.nbHeureCours),
            nbHeureTravauxPersonnel: parseInt(this.state.curentEC.nbHeureTravauxPersonnel),
            ordreElementConstitutif: parseInt(this.state.curentEC.ordreElementConstitutif)
        }

        
        //On peut proceder à la mise à jour ou à l'ajout de l'element constitutif
        if(newEC.idElementConstitutif){

            this.ModelEConstitutif.update(newEC.idElementConstitutif, newEC).then(data => {
                this.notify.success()
                this.setState({
                    viewCreateEC: false,
                    redirect: true
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }else{

            this.ModelEConstitutif.create(newEC).then(data => {
                this.notify.success()
                this.setState({
                    redirect: true,
                    viewCreateEC: false
                }
                )
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }

    }

    editElementConstitutif = (rowData) => {
        
        let allowSave = this.testAllowSave(rowData);

        this.setState({
            curentEC: rowData,
            viewCreateEC: true,
            allowSave: allowSave
        })
        
    }

    confirmDeleteElementConstitutif = (rowData) => {
        this.setState(
            {
                deletionId: rowData.idElementConstitutif,
                viewDeleteECDialog: true
            }
        )
    }

    deleteElementConstitutif = (idElementConstitutif) => {

        this.ModelEConstitutif.delete(parseInt(idElementConstitutif)).then(data =>{
            this.notify.success()
            this.setState({
                viewDeleteECDialog: false,
                redirect: true,
                deletionId: null
            })
        }).catch(error=>{
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
    }

    hideDeleteElementConstitutif = () => {
        this.setState(
            {
                viewDeleteMentionDialog: false,
                deletionId: null
            }
        )
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editElementConstitutif(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteElementConstitutif(rowData)} />
            </React.Fragment>
        );
    }


    testAllowSave = (curentEC) => {
        return (
            curentEC.uniteEnseignement
            && 
            (curentEC.libelleElementConstitutif.trim()).length>=5
            &&
            curentEC.libelleElementConstitutif
            &&
            (curentEC.codeElementConstitutif.trim()).length>=2
            &&
            curentEC.codeElementConstitutif
            &&
            !isNaN(curentEC.creditElementConstitutif)
            &&
            curentEC.creditElementConstitutif!==null
            &&
            !isNaN(curentEC.nbHeureCours)
            &&
            curentEC.nbHeureCours!==null
            &&
            !isNaN(curentEC.nbHeureTD)
            &&
            curentEC.nbHeureTD !== null
            &&
            !isNaN(curentEC.nbHeureTP)
            &&
            curentEC.nbHeureTP!==null
            &&
            !isNaN(curentEC.nbHeureTravauxPersonnel)
            &&
            curentEC.nbHeureTravauxPersonnel!==null
            &&
            !isNaN(curentEC.ordreElementConstitutif)
            &&
            curentEC.ordreElementConstitutif!==null

            )? true:false
    }

    
    render() {

        if(this.state.redirect) {
            //window.location.reload();
        }

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

       const deleteECDialogFooter = (
        <React.Fragment>
            <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementConstitutif} />
            <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteElementConstitutif(this.state.deletionId) } />
        </React.Fragment>
        )

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveElementConstitutif} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const elementConstitutifDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.ECList} selectionMode="single" header="Liste des element constitutif" paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleUniteEnseignement" header="Libellé unité d'enseignement"  sortable={true} filter={true} filterPlaceholder="rechercher unité enseignement"/>
                                <Column field="codeElementConstitutif" header="Code élement constitutif"  sortable={true} filter={true} filterPlaceholder="rechercher élement constitutif"/>
                                <Column field="libelleElementConstitutif" header="Libelle élement constitutif"  sortable={true} filter={true} filterPlaceholder="rechercher élement constitutif"/>
                                <Column field="creditElementConstitutif" header="Crédit élement constitutif"  sortable={true} filter={true} filterPlaceholder="crédit élement constitutif"/>
                                <Column field="nbHeureCours" header="Nombre d'heures de cours"  sortable={true} filter={true} filterPlaceholder="rechercher nombre d'heures cours"/>
                                <Column field="nbHeureTD" header="Nombre d'heures de travaux dirrigés"  sortable={true} filter={true} filterPlaceholder="rechercher nombre d'heures travaux dirrigés"/>
                                <Column field="nbHeureTP" header="Nombre d'heures de travaux pratiques"  sortable={true} filter={true} filterPlaceholder="rechercher nombre d'heures travaux pratiques"/>
                                <Column field="nbHeureTravauxPersonnel" header="Nombre d'heures de travaux personnels"  sortable={true} filter={true} filterPlaceholder="rechercher nombre d'heures travaux personnels"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                
                <Dialog visible={this.state.viewCreateEC} style={{ width: '450px' }} header="Informations de l'élement constitutif" modal className="p-fluid" footer={elementConstitutifDialogFooter} onHide={this.hideDialog}>
                   
                    <div className="p-field">
                        <label htmlFor="libelleUniteEnseignement">Unité d'enseignement</label>
                        <Dropdown optionLabel="libelleUniteEnseignement" optionValue="idUniteEnseignement" value={this.state.curentEC.uniteEnseignement} options={this.state.UEList} onChange={(e) => {this.onInputChange(e, 'uniteEnseignement')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner une unité d'enseignement"/>
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="codeElementConstitutif">Code élement constitutif</label>
                        <InputText id="codeElementConstitutif" value={this.state.curentEC.codeElementConstitutif}  onChange={(e) => this.onInputChange(e, 'codeElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Code élement constitutif"/>
                        {this.state.submitted && !this.state.curentEC.codeElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="libelleElementConstitutif">Libellé élement constitutif</label>
                        <InputText id="libelleElementConstitutif" value={this.state.curentEC.libelleElementConstitutif}  onChange={(e) => this.onInputChange(e, 'libelleElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Libellé élement constitutif"/>
                        {this.state.submitted && !this.state.curentEC.libelleElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="creditElementConstitutif">Crédit élement constitutif (Valeur numérique)</label>
                        <InputText id="creditElementConstitutif" value={this.state.curentEC.creditElementConstitutif}  onChange={(e) => this.onInputChange(e, 'creditElementConstitutif')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   placeholder="Crédit élement constitutif"/>
                        {this.state.submitted && !this.state.curentEC.creditElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="nbHeureCours">Nombre d'heures de cours magistral (Valeur numérique)</label>
                        <InputText id="nbHeureCours" value={this.state.curentEC.nbHeureCours}  onChange={(e) => this.onInputChange(e, 'nbHeureCours')} autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   placeholder="Nombre d'heures de cours magistral"/>
                        {this.state.submitted && !this.state.curentEC.nbHeureCours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="nbHeureTD">Nombre d'heures de travaux dirrigés (Valeur numérique)</label>
                        <InputText id="nbHeureTD" value={this.state.curentEC.nbHeureTD}  onChange={(e) => this.onInputChange(e, 'nbHeureTD')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   placeholder="Nombre d'heures de travaux dirrigés"/>
                        {this.state.submitted && !this.state.curentEC.nbHeureTD && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="nbHeureTP">Nombre d'heures de travaux pratiques (Valeur numérique)</label>
                        <InputText id="nbHeureTP" value={this.state.curentEC.nbHeureTP}  onChange={(e) => this.onInputChange(e, 'nbHeureTP')} autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   placeholder="Nombre d'heures de travaux pratiques"/>
                        {this.state.submitted && !this.state.curentEC.nbHeureTD && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>

                    <hr/>
                    <div className="p-field">
                        <label htmlFor="nbHeureTravauxPersonnel">Nombre d'heures de travaux personnels (Valeur numérique)</label>
                        <InputText id="nbHeureTravauxPersonnel" value={this.state.curentEC.nbHeureTravauxPersonnel}  onChange={(e) => this.onInputChange(e, 'nbHeureTravauxPersonnel')} autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   placeholder="Nombre d'heures de travaux personnel"/>
                        {this.state.submitted && !this.state.curentEC.nbHeureCours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>

                    <hr/>
                    <div className="p-field">
                        <label htmlFor="ordreElementConstitutif">Ordre de l'élement constitutif</label>
                        <InputText id="ordreElementConstitutif" value={this.state.curentEC.ordreElementConstitutif}  onChange={(e) => this.onInputChange(e, 'ordreElementConstitutif')} autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   placeholder="Ordre de l'élement constitutif"/>
                        {this.state.submitted && !this.state.curentEC.ordreElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.viewDeleteECDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteECDialogFooter} onHide={this.hideDeleteElementConstitutif}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                
            </div> 
        )

}

}

export default Econstitutif
