import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { NiveauService } from '../../service/NiveauService';
import { CycleService } from '../../service/CycleService';




export class Niveau extends Component {

    emptyNiveau = {

        idNiveau: null,
        cycle: null,
        libelleNiveau: "",
        codeNiveau : "",
        ordreNiveau: ""
    }

    constructor() {
        super();
        this.state = {
            listeNiveau : [],
            listeCycle : [],
            niveauDialog: false,
            deleteNiveauDialog: false,
            niveau: this.emptyNiveau,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }

        this.cycleService = new CycleService();
        this.niveauService = new NiveauService();
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveNiveau = this.saveNiveau.bind(this);
        this.editNiveau = this.editNiveau.bind(this);
        this.confirmDeleteNiveau = this.confirmDeleteNiveau.bind(this);
        this.deleteNiveau = this.deleteNiveau.bind(this);
        this.onLibelleNiveauChange = this.onLibelleNiveauChange.bind(this);
        this.onCodeNiveauChange = this.onCodeNiveauChange.bind(this);
        this.hideDeleteNiveauDialog = this.hideDeleteNiveauDialog.bind(this);
    }
    componentDidMount() {
        
        /*const listeNiveau = [
            {
                idNiveau : 1,
                libelleNiveau : "Licence 1",
                codeNiveau : "L1",
                cycle: {idCycle : 1, libelleCycle : "Licence"}
            },
            {
                idNiveau : 2,
                libelleNiveau : "Licence 2",
                codeNiveau : "L2",
                cycle: {idCycle : 1, libelleCycle : "Licence"}
            },
            {
                idNiveau : 3,
                libelleNiveau : "Licence 3",
                codeNiveau : "L3",
                cycle: {idCycle : 1, libelleCycle : "Licence"}
            },
        ]
        const listeCycle = [
            {
                idCycle : 1,
                libelleCycle : "Licence"
            },
            {
                idCycle : 2,
                libelleCycle : "Master"
            },
            {
                idCycle : 3,
                libelleCycle : "Doctorat"
            },
        ]
        this.setState({listeNiveau: listeNiveau, loading : false})
        this.setState({listeCycle: listeCycle})*/
        this.setState({loading : true})
        this.niveauService.getAll().then(data => 
            this.setState({listeNiveau: data, loading : false})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })

        this.cycleService.getAll().then(data => {
            //console.log(data)
            this.setState({listeCycle: data})
        })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)})
        
    }
    
    openNew() {
        this.setState({
            niveau: this.emptyNiveau,
            submitted: false,
            niveauDialog: true
        });
    }
    hideDialog() {
        this.setState({
            submitted: false,
            niveauDialog: false
        });
    }
    hideDeleteNiveauDialog() {
        this.setState({ deleteNiveauDialog: false });
    }
    saveNiveau() {
        let state = { submitted: true };

        if (this.state.niveau.cycle && this.state.niveau.libelleNiveau.trim() && this.state.niveau.ordreNiveau) {

            const newNiveau = {
                idNiveau : parseInt(this.state.niveau.idNiveau) ,
                idCycle : parseInt(this.state.niveau.cycle.idCycle) ,
                libelleNiveau : this.state.niveau.libelleNiveau ,
                codeNiveau : this.state.niveau.codeNiveau,
                ordreNiveau: this.state.niveau.ordreNiveau
            }
            if (this.state.niveau.idNiveau) {
                this.niveauService.update(parseInt(this.state.niveau.idNiveau
                    ), newNiveau)
                                .then(data => {
                                    this.notify.success()
                                    this.setState({redirect : true})
                                    this.setState({loading : true})
                                    this.niveauService.getAll().then(data => 
                                        this.setState({listeNiveau: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)
                                            console.log(error)
                                            this.setState({loading : false})
                                        })

                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.niveauService.create(newNiveau).then(data => {
                    this.notify.success()
                    this.setState({redirect : true})
                    this.setState({loading : true})
                    this.niveauService.getAll().then(data => 
                        this.setState({listeNiveau: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                        })
                    })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                niveauDialog: false,
                niveau: this.emptyNiveau
            };
        }

        this.setState(state);
    }
    editNiveau(niveau) {
        /*this.loadBatiment(lit.idCite)
        this.loadNiveau(lit.idBatiment)
        this.loadCouloir(lit.idNiveau)
        this.loadChambre(lit.idCouloir)*/
        //console.log(niveau)
        this.setState({
            niveau,
            niveauDialog: true
        });
    }
    confirmDeleteNiveau(niveau) {
        this.setState({
            niveau,
            deleteNiveauDialog: true
        });
    }
    deleteNiveau(idNiveau) {
        this.niveauService.delete(parseInt(idNiveau)).then(data => {
            this.notify.success()
            this.setState({
                deleteNiveauDialog: false,
                niveau: this.emptyNiveau,
                redirect : true
            });
            this.setState({loading : true})
            this.niveauService.getAll().then(data =>
                this.setState({listeNiveau: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })
            })
    }
    
    onLibelleNiveauChange(e, libelleNiveau) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${libelleNiveau}`] = val;

        this.setState({niveau });
    }
    onCodeNiveauChange(e, codeNiveau) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${codeNiveau}`] = val;

        this.setState({ niveau });
    }

    onOrdreNiveauChange(e, ordreNiveau){

        let val = (e.target && e.target.value) || '';

        val = (!isNaN(val))? val:''

        let niveau = this.state.niveau;
        niveau[`${ordreNiveau}`] = val;

        this.setState({niveau });
    }

    onIdCycleChange(e, cycle) {
        const val = (e.target && e.target.value) || '';
        let niveau = this.state.niveau;
        niveau[`${cycle}`] = val;

        this.setState({ niveau });
    }
    
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editNiveau(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteNiveau(rowData)} />
            </React.Fragment>
        );
    }
    
    render() {
        
        if(this.state.redirect) {
            //window.location.reload();
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const niveauDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveNiveau} />
            </React.Fragment>
        );
        const deleteNiveauDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteNiveauDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteNiveau(this.state.niveau.idNiveau) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeNiveau} selectionMode="single" header={`Liste des niveaux (${this.state.listeNiveau.length})`} paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                
                            <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher niveau"/>
                            <Column field="codeNiveau" header="Code niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code niveau"/>
                            <Column field="libelleCycle" header="Cycle" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cycle"/>
                            <Column field="ordreNiveau" header="Ordre niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher ordre"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.niveauDialog} style={{ width: '450px' }} header="Informations niveau" modal className="p-fluid" footer={niveauDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleCycle">Cycle</label>
                        <Dropdown optionLabel="libelleCycle" optionValue="idCycle" value={this.state.niveau.cycle} options={this.state.listeCycle} onChange={(e) => {this.onIdCycleChange(e, 'cycle')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un cycle"/>
                        {this.state.submitted && !this.state.niveau.cycle && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="libelleNiveau">Niveau</label>
                        <InputText id="libelleNiveau" value={this.state.niveau.libelleNiveau}  onChange={(e) => this.onLibelleNiveauChange(e, 'libelleNiveau')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        {this.state.submitted && !this.state.niveau.libelleNiveau && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="codeNiveau">Code niveau</label>
                        <InputText id="codeNiveau" value={this.state.niveau.codeNiveau}  onChange={(e) => this.onCodeNiveauChange(e, 'codeNiveau')}   />
                    </div>
                        <hr />
                    <div className="p-field">
                        <label htmlFor="ordreNiveau">Ordre du niveau</label>
                        <InputText id="ordreNiveau" value={this.state.niveau.ordreNiveau || ""}  onChange={(e) => this.onOrdreNiveauChange(e, 'ordreNiveau')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.niveau.ordreNiveau && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et de type numérique.</small>}
                    </div>
                </Dialog>


                <Dialog visible={this.state.deleteNiveauDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteNiveauDialogFooter} onHide={this.hideDeleteNiveauDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.niveau && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Niveau
