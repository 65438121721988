import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { CurriculaService } from '../../service/CurriculaService'
import { ParcoursService } from '../../service/ParcoursService'
import { PickList } from 'primereact/picklist';

export class Curricula extends Component {

    //Model de l'objet curricula
    emptyCurricula = {
        idCurriculat: null,
        referenceCurriculat: null,
        anneeDebut: null,
        anneeFin: null
    }

    constructor(props){
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelCurricula = new CurriculaService();
        this.ModelParcours = new ParcoursService();

        this.state = {
            curentCurricula: this.emptyCurricula,
            listeCurricula: [],
            listeParcours: [],
            listeParcoursChoix: [],
            listeSousParcours: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateCurriculaDialog: false,
            allowSave: false,
            viewEditCurriculaDialog: false,
            viewParcoursDialog: false
        }
    }

    componentDidMount(){

        this.setState({loading : true})

        this.ModelCurricula.getAll().then(data => {
            this.setState({
                listeCurricula: data,
                loading: false
            })
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        //On recupère la liste des filieres
        this.ModelParcours.getAll().then(data => {
            this.setState({
                listeParcours: data
            })
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
    }

    editCurricula = (rowData) => {
        
        this.setState({
            curentCurricula: rowData,
            viewEditCurriculaDialog: true,
            allowSave: this.authorisationSave(rowData)
        })
    }

    hideDialog = () => {
        this.setState({
            curentCurricula: this.emptyCurricula,
            viewCreateCurriculaDialog: false,
            viewEditCurriculaDialog: false
        })
    }


    listeParcoursCuricula = (rowData) => {

        console.log(rowData.parcours)
        this.setState({
            viewParcoursDialog: true,
            curentCurricula: rowData,
            listeSousParcours: rowData.parcours
        })
    }

    saveCurricula = () => {

        let newCurricula = {
            idCurriculat: parseInt(this.state.curentCurricula.idCurriculat),
            referenceCurriculat: this.state.curentCurricula.referenceCurriculat,
            parcours: this.state.listeParcoursChoix
        }
        console.log(newCurricula)
        //On peut proceder à la mise à jour ou à l'ajout du curricula
        if(newCurricula.idCurriculat){

            this.ModelCurricula.update(parseInt(newCurricula.idCurriculat), newCurricula).then(data => {
                this.notify.success()
                this.setState({
                    viewCreateCurriculaDialog: false,
                    viewEditCurriculaDialog: false,
                    redirect: true
                })
                this.ModelCurricula.getAll().then(data => {
                    this.setState({
                        listeCurricula: data,
                        loading: false
                    })
                    }
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                }) 
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({
                    viewCreateCurriculaDialog: false,
                    viewEditCurriculaDialog: false
                })
            })

        }else{

            this.ModelCurricula.create(newCurricula).then(data => {
                this.notify.success()
                this.setState({ 
                    redirect: true,
                    viewCreateCurriculaDialog: false, 
                    curentCurricula: this.emptyCurricula
                })
                this.ModelCurricula.getAll().then(data => {
                    this.setState({
                        listeCurricula: data,
                        loading: false
                    })
                    }
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({
                    viewCreateCurriculaDialog: false,
                    viewEditCurriculaDialog: false
                })
            })
        }
    }

    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                viewCreateCurriculaDialog: true,
                curentCurricula: this.emptyCurricula
            }
        )
    }

    onInputChange = (event, field) => {

        let curentCurricula = this.state.curentCurricula
        curentCurricula[`${field}`] = event.target.value
        
        this.setState({
            curentCurricula,
            allowSave: this.authorisationSave(curentCurricula)
        })
    }

    authorisationSave = (curentCurricula) => {

        return (
                curentCurricula.referenceCurriculat
                && 
                (curentCurricula.referenceCurriculat.trim()).length>=3
            )? true:false
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCurricula(rowData)} style={{marginRight: '.5em'}} title='Edition'/>
                <Button icon="pi pi-list" className="p-button-warning" onClick={() => this.listeParcoursCuricula(rowData)} title='Liste des parcours du curricula'/>
            </React.Fragment>
        );
    }


    itemTemplate = (item) => {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleParcours}</h6>
                </div>
            </div>
        );
    }

    onSelectionParcours = (event) => {
        this.setState({
            listeParcours: event.source,
            listeParcoursChoix: event.target
        })
    }

    hideParcoursDialog = () => {
        this.setState({
            viewParcoursDialog: false
        })
    }


    render() {

        console.log(this.state.listeCurricula)
        if(this.state.redirect) {
            //window.location.reload();
        }

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCurricula} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        
        const parcoursDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );


        const sousParcoursDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideParcoursDialog} />
            </React.Fragment>
        );
   
        let titreCurriculat = (this.state.curentCurricula)? this.state.curentCurricula.referenceCurriculat: ''
        return (
                <div className="p-grid">

                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                    {/*<Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />*/}
                            </div>
                        </div>
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeCurricula} selectionMode="single" header={`Liste des curriculats (${this.state.listeCurricula.length})`} paginator={true} rows={10} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="referenceCurriculat" header="Reférence curricula"  sortable={true} filter={true} filterPlaceholder="rechercher reference"/>
                                <Column field="anneeDebut" header="Date de création"  sortable={true} filter={true} filterPlaceholder="rechercher date création"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                <Dialog visible={this.state.viewCreateCurriculaDialog} style={{ width: '850px' }} header="Création d'un curriculat" modal className="p-fluid" footer={parcoursDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="referenceCuriculat">Réference du curriculat</label>
                        <InputText id="referenceCuriculat" value={this.state.curentCurricula.referenceCurriculat}  onChange={(e) => this.onInputChange(e, 'referenceCurriculat')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {!this.state.curentCurricula.referenceCurriculat || (this.state.curentCurricula.referenceCurriculat.trim()).length<3 && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 3 caractères.</small>}
                    </div>
                    <hr/>
                    Sélection des parcours concernés par le curricula
                    <hr />
                    <div className="p-field" style={{marginTop : 30}}>
                        <PickList source={this.state.listeParcours} target={this.state.listeParcoursChoix} itemTemplate={this.itemTemplate}
                            sourceHeader="Liste des parcours" targetHeader="Parcours concernés par le curriculat"
                            sourceStyle={{ height: '250px', width : '250px' }} targetStyle={{ width : '250px' ,height: '250px' }}
                            onChange={this.onSelectionParcours}>
                        </PickList>
                    </div>
                </Dialog>

                <Dialog visible={this.state.viewEditCurriculaDialog} style={{ width: '450px' }} header="Edition d'un curriculat" modal className="p-fluid" footer={parcoursDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="referenceCuriculat">Reference du curriculat</label>
                        <InputText id="referenceCuriculat" value={this.state.curentCurricula.referenceCurriculat}  onChange={(e) => this.onInputChange(e, 'referenceCurriculat')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {!this.state.curentCurricula.referenceCurriculat || (this.state.curentCurricula.referenceCurriculat.trim()).length<3 && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 3 caractères.</small>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.viewParcoursDialog} style={{ width: '85%' }}  modal className="p-fluid" footer={sousParcoursDialogFooter} onHide={this.hideParcoursDialog}>
                    <div className="p-field">
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSousParcours} selectionMode="none" header={`Liste des parcours concernés par le curriculat : ${titreCurriculat}`}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleParcours" header="Libellé parcours"  sortable={true} filter={false}/>
                        </DataTable>
                    </div>
                </Dialog>


            </div>
            
        )
    }
}
export default Curricula