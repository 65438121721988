import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeActiviteService {
     constructor(){
         this.adresse = new AdresseIP()
     }
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-activite"
        return axios.get(url).then(res => res.data) 
    }
    
    create(newTypeActivite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-activite"
        return axios.post(url,newTypeActivite)
                    .then(res => res.data);
    }
    update(idTypeActivite ,newTypeActivite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-activite/"
        return axios.put(url+idTypeActivite, newTypeActivite)
                    .then(res => res.data);
    }


    delete(idTypeActivite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-activite/"
        return axios.patch(url+idTypeActivite)
    }
    
}