import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';



export default class TypeStructureService{
    constructor(){
        this.adresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso";
    }


    getAll(){
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/types-structures`).then(response=>response.data);
    }

    getTypeStructure(idTypeStructure,code){
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/structure-rattachement?code=${code}&idTypeStructure=${idTypeStructure}`).then(response=>response.data);
    }

} 