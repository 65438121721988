import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../components/Notify';
import { Spinner } from '../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InputText } from 'primereact/inputtext';
import PhoneInput from "react-phone-number-input";
import { SignataireService } from '../service/SignataireService';
import { EnseignantService} from '../service/EnseignantService'
import { DepartementService} from '../service/DepartementService'

export class SignataireCarteEtudiant extends Component {



    constructor() {
        super();

        this.rfPhoto = React.createRef();

        this.state = {
            listeEnseignant : [],
            signataire: [],
            curentSignataire: {
                idEtablissement: null,
                autoriteSignataire: '',
                titreSignataire: '',
                nomPhotoSignature: '',
                extensionPhotoSignature: '',
                nomSignataire: '',
                prenomSignataire: '',
                telephoneSignataire: '',
                distinctionHonorifique: '',
                libelleEtablissement: '',
                libelleIesr: null,
                idIesr: null,
                enseignant: null,
                typeSignataire: {
                    idTypeSignataire: 2,
                    libelleTypeSignataire: 'Attestation inscription'
                }
            },
            listeEtablissement: [],
            curentEtablissement: null,
            viewCreateSignataire: false,
            submitted: false,
            idEtablissement: null,
            viewSelectEnseignantDialog: false,
            typeSignataire: {
                idTypeSignataire: 2,
                libelleTypeSignataire: 'Attestation inscription'
            }
        }

        this.signataireService = new SignataireService();
        this.enseignantService = new EnseignantService()
        this.departementService = new DepartementService()


        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }

    componentDidMount() {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({
            idIesr: token.code_iesr
        })


        this.signataireService.getSignataireIesr(token.code_iesr, 2).then(data => {

            let actuSignataire = null
            if(data.idSignataire){
                actuSignataire = data
            }

            this.setState({
                curentSignataire: actuSignataire
            })
            this.setState({signataire: data?[data]:[], loading : false})

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

    }
    
    openNew = () => {

        console.log(this.state.curentSignataire)
        let tempSignature = null

        if(!this.state.curentSignataire){
            tempSignature = {
                idEtablissement: null,
                autoriteSignataire: '',
                titreSignataire: '',
                nomPhotoSignature: '',
                extensionPhotoSignature: '',
                nomSignataire: '',
                prenomSignataire: '',
                telephoneSignataire: '',
                distinctionHonorifique: '',
                libelleEtablissement: '',
                libelleIesr: null,
                idIesr: null,
                enseignant: null,
                typeSignataire: {
                    idTypeSignataire: 2,
                    libelleTypeSignataire: 'Attestation inscription'
                }
            }
        }else{
            tempSignature = this.state.curentSignataire
        }

        this.setState({
            submitted: false,
            viewCreateSignataire: true,
            curentSignataire: tempSignature
        });
        
    }

    hideDialog = () => {
        this.setState({
            submitted: false,
            viewCreateSignataire: false
        });
    }


    onSignataireEdite = (event, fieldName) => {

        const val = (event.target && event.target.value) || '';
        let curentSignataire = this.state.curentSignataire
        curentSignataire[`${fieldName}`] = val

        console.log(event.target.value)

        this.setState({
            curentSignataire
        })

    }


    chargerPhoto = (event) => {

        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);

                let curentSignataire = this.state.curentSignataire
                curentSignataire['nomPhotoSignature'] = fichier
                curentSignataire['extensionPhotoSignature'] =extension
                this.setState({
                    curentSignataire: curentSignataire,
                    errorFilePhoto: "",
                    errorFile: ""
                });
            };
            reader.readAsBinaryString(file);

        } else {

            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement une photo valide !";
            } else if (file.size > 5457280) {
                errorFile = "La photo est très lourde !";
            }

            this.setState({
                errorFilePhoto: errorFile,
            });
        }
    };



    saveSignataire = () => {

        this.setState({submitted: true})

        let curentSignataire = this.state.curentSignataire
        curentSignataire['typeSignataire'] = {
            idTypeSignataire: 2,
            libelleTypeSignataire: 'Attestation inscription'
        }

        console.log(curentSignataire)

        
        //On s'assure que toutes les informtaions obligatoires ont ete renseignes!
        if(curentSignataire.autoriteSignataire && curentSignataire.titreSignataire && curentSignataire.nomPhotoSignature  && curentSignataire.nomSignataire && curentSignataire.prenomSignataire && curentSignataire.telephoneSignataire && ((!curentSignataire.distinctionHonorifique || (curentSignataire.distinctionHonorifique!=="" && curentSignataire.distinctionHonorifique.length>5) )) ){

            curentSignataire['idIesr'] = this.state.idIesr
            if(curentSignataire.idSignataire){
                //Il s'agit d'une edition
                this.signataireService.update(curentSignataire.idSignataire, curentSignataire).then(data => {

                    this.setState({
                        loading: true,
                        submitted: false,
                        viewCreateSignataire: false
                    })
                    this.notify.success()

                    this.signataireService.getSignataireIesr(this.state.idIesr, 2).then(data => {

                        let actuSignataire = null
                        if(data.idSignataire){
                            actuSignataire = data
                        }
            
                        this.setState({
                            curentSignataire: actuSignataire
                        })
                        this.setState({signataire: data?[data]:[], loading : false})
            
                    }).catch(error => {
                        this.notify.echec(error.response.data.message)
                        console.log(error)
                        this.setState({loading : false})
                    })

                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })


            }else{
                
                //Il s'agit d'un premier enregistrement
                this.signataireService.create(curentSignataire).then(data => {

                    this.setState({
                        loading: true,
                        submitted: false,
                        viewCreateSignataire: false
                    })
                    this.notify.success()

                    this.signataireService.getSignataireIesr(this.state.idIesr, 2).then(data => {

                        let actuSignataire = null
                        if(data.idSignataire){
                            actuSignataire = data
                        }
            
                        this.setState({
                            curentSignataire: actuSignataire
                        })
                        this.setState({signataire: data?[data]:[], loading : false})
            
                    }).catch(error => {
                        this.notify.echec(error.response.data.message)
                        console.log(error)
                        this.setState({loading : false})
                    })

                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })

            }
        }
        
    }


    onPhoneChange = (value, fieldName) => {
        let curentSignataire = this.state.curentSignataire;
        curentSignataire[`${fieldName}`] = value;
        this.setState({
            curentSignataire
        });
    };


    selectionSignataireEnseignant = (enseignant) => {
        let curentSignataire = this.state.curentSignataire
        curentSignataire['enseignant'] = enseignant

        this.setState({
            curentSignataire: curentSignataire,
            viewSelectEnseignantDialog: false
        })
     }


    
    hideSelectSignataireDialog = () => {
        this.setState({
            viewSelectEnseignantDialog: false
        })
    }


    actionSelectSignataireBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={(e) => this.selectionSignataireEnseignant(rowData)} title="Sélectionner l'enseignant"/>
            </React.Fragment>
        );
    }

    
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editNiveau(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteNiveau(rowData)} />
            </React.Fragment>
        );
    }

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" title="Editer signataire" onClick={() => this.openNew()} style={{marginRight: '.5em'}}/>
            </React.Fragment>
        )
    }
    
    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const signataireSelectFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSignataire} />
            </React.Fragment>
        );

        const selectSignataireDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSignataireDialog} />
            </React.Fragment>
        )

        
        


        return (

            
            <div className="p-grid">


                                

                <div className="p-col-12">
                    
    
                    <div className="card card-w-title datatable-demo">


                  
                    
                    <div className="p-grid p-col-12">
                            <div className="p-field">
                                        {/*
                                        <Dropdown
                                            optionLabel="nom"
                                            optionValue="idEtablissement"
                                            value={this.state.curentEtablissement}
                                            options={this.state.listeEtablissement}
                                            onChange={(e) => {
                                                this.onEtablissementChange(e);
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner l'établissement"
                                        />
                                        this.state.submitted &&
                                            !this.state.iesr && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )*/}
                                        </div>
                                    </div>


                        {this.state.signataire.length===0 &&
                            <div className="p-grid">
                                <div className="p-col-2">
                                    <Button label="Ajouter / Changer signataire" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                                </div>
                            </div>
                        }




                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />

                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.signataire} selectionMode="single" header="Signataire des cates d'étudiants" paginator={true} rows={10}responsive={true}>
                            <Column field="enseignant.matriculeEnseignant" header="Matricule du signataire" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher matricule"/>
                            <Column field="enseignant.nomEnseignant" header="Nom signataire" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par nom"/>
                            <Column field="enseignant.prenomEnseignant" header="Prénom signataire" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par prénom"/>
                            <Column field="enseignant.grade.libelleGrade" header="Grade de l'enseignant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par grade"/>
                            <Column field="titreSignataire" header="Distinction" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="Distinction signataire"/>
                            <Column field="autoriteSignataire" header="Fonction" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="Fonction signataire"/>
                            <Column header={actionHeader} body={this.actionBodyTemplateSession} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>




                <Dialog visible={this.state.viewCreateSignataire} style={{ width: '75%' }} header="Sélection du signataire" modal className="p-fluid" footer={signataireSelectFooter} onHide={this.hideDialog}>
                    
                    {this.state.curentSignataire && 
                    <>
                        <div className="p-grid p-lg-12">
                            
                            <div className="p-field p-col-4">
                                <div className="p-field">
                                    <label htmlFor="titreSignataire"><span style={{color:'red'}}>*</span> Titre signataire</label>
                                    <InputText id="titreSignataire" value={this.state.curentSignataire.titreSignataire}  onChange={(e) => this.onSignataireEdite(e, 'titreSignataire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentSignataire.titreSignataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className="p-field p-col-4">
                                <div className="p-field">
                                    <label htmlFor="autoriteSignataire"><span style={{color:'red'}}>*</span> Fonction du signataire</label>
                                    <InputText id="autoriteSignataire" value={this.state.curentSignataire.autoriteSignataire}  onChange={(e) => this.onSignataireEdite(e, 'autoriteSignataire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentSignataire.autoriteSignataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>
                            
                            <div className="p-field p-col-4">
                                <div className="p-field">
                                    <label htmlFor="distinctionHonorifique">Distinction honorifique</label>
                                    <InputText id="distinctionHonorifique" value={this.state.curentSignataire.distinctionHonorifique}  onChange={(e) => this.onSignataireEdite(e, 'distinctionHonorifique')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.curentSignataire.distinctionHonorifique && this.state.curentSignataire.distinctionHonorifique<5 && <small className="p-invalid" style={{color : "red"}}>Distinction tres courte.</small>}
                                </div>
                            </div>
                        </div>


                        <div className="p-grid p-lg-12">
                            
                            <div className="p-field p-col-4">
                                <div className="p-field">
                                    <label htmlFor="nomSignataire"><span style={{color:'red'}}>*</span> Nom signataire</label>
                                    <InputText id="nomSignataire" value={this.state.curentSignataire.nomSignataire}  onChange={(e) => this.onSignataireEdite(e, 'nomSignataire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentSignataire.nomSignataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>

                            <div className="p-field p-col-4">
                                <div className="p-field">
                                    <label htmlFor="prenomSignataire"><span style={{color:'red'}}>*</span> Prénom signataire</label>
                                    <InputText id="prenomSignataire" value={this.state.curentSignataire.prenomSignataire}  onChange={(e) => this.onSignataireEdite(e, 'prenomSignataire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                    {this.state.submitted && !this.state.curentSignataire.prenomSignataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                            </div>
                            
                            <div className="p-field p-col-4">
                                <div className="p-field">
                                    <label htmlFor="distinctionHonorifique"><span style={{color:'red'}}>*</span> Numéro de téléphone</label>
                                    <PhoneInput id="telephoneSignataire" value={ this.state.curentSignataire.telephoneSignataire} defaultCountry="BF" limitMaxLength="8" onChange={(value) => this.onPhoneChange( value, "telephoneSignataire" ) } />
                                    {this.state.submitted && (!this.state.curentSignataire.telephoneSignataire || this.state.curentSignataire.telephoneSignataire.length<8) && <small className="p-invalid" style={{color : "red"}}>Renseigner un numéro valide.</small>}
                                </div>
                            </div>
                        </div>


                        <hr />
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>Chargement du cachet et signature</h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Cachet et signature en format image
                                    </div>


                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="photo">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Cachet et signature
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="photo"
                                                        name="photo"
                                                        accept="image/*"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerPhoto(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="apercuPhoto">
                                                        Aperçu du cachet et signature
                                                    </label>
                                                    <div className="card">
                                                        {this.state.curentSignataire.nomPhotoSignature && this.state.curentSignataire.extensionPhotoSignature && (
                                                            <img
                                                                className="card-img-top"
                                                                src={`data:image/${this.state.curentSignataire.extensionPhotoSignature};base64, ${this.state.curentSignataire.nomPhotoSignature}`}
                                                                alt="Cadre visualisation"
                                                                ref={
                                                                    this.rfPhoto
                                                                }
                                                                style={{
                                                                    width: "207px"
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.curentSignataire.nomPhotoSignature ||
                                                        this.state
                                                            .errorFilePhoto) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {this.state
                                                                    .errorFilePhoto?
                                                                this.state
                                                                    .errorFilePhoto: "Charger l'image du cachet et de la signature"
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </Dialog>



                <Dialog visible={this.state.viewSelectEnseignantDialog} style={{ width: '75%', height: 'auto' }} header="Sélection le signataire" modal className="p-fluid" footer={selectSignataireDialogFooter} onHide={this.hideSelectSignataireDialog}>
                    <DataTable ref={(el) => this.dt = el} value={this.state.listeEnseignant} header="Liste des enseignants" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="nomPrenom" header="Enseignant" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher un enseignant"/>
                        <Column field="grade.libelleGrade" header="Grade enseignant" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher un grade"/>
                        <Column header={actionHeader} body={this.actionSelectSignataireBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                </Dialog>

            </div> 
        )
    }
}

export default SignataireCarteEtudiant
