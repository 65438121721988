
import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeSessionService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-sessions"
       return axios.get(url).then(res => res.data) 
   }
   

   create(newTypeSession) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-sessions"
       return axios.post(url,newTypeSession)
                   .then(res => res.data);
   }
   
   update(idTypeSession ,newTypeSession) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-sessions/"
       return axios.put(url+idTypeSession, newTypeSession)
                   .then(res => res.data);
   }

   delete(idTypeSession) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-sessions/"
       return axios.patch(url+idTypeSession)
                   .then(res => res.data)
   }


}