import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP"


export default class NominationService {
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/enseignants-responsabilites"
    }


   /**
    * 
    * @returns  la liste compte des nominations
    * c'est a dire la responsabilité et l'enseignant
    * ainsi que la date de nomination
    */
   
    getAll(){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    } 


    /**
     * 
     * @param {*} nomination 
     * @returns undefined comme valeur de retour
     * enregistre les informations de la nouvelle nomination dans la base de données
     */
    create(nomination){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,nomination);
    }

    /**
     * 
     * @param {*} nomination 
     * @returns undefined
     * mise à jour des informations d'une nomination
     */
    update(nomination){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${nomination.idEnseignantResponsabilite}`,nomination);
    }

    createNominationVacataire(nominationVacataire){
        const url = `${this.addresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/nomination-upload`
        return axios.post(url, nominationVacataire)
                    .then(res => res.data);
    }

    getAllNominationVacataire (){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/nomination`).then(response=>response.data);
    } 
    
    deleteNominationVacataire(idNomination){
        return  axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/nomination/${idNomination}`);
    }
    getFichier(idNomination){
        return  axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/nomination-upload/${idNomination}`).then(response=>response.data);
    }
}