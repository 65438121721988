import React, { useState, useEffect, useRef } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";

import { AnneeService } from "../../lmd/service/AnneeService";
import { DashboardService } from "../service/DashboardService";
import { IesrService } from "../service/IesrService";
import { ParcoursFiliereService } from "../../lmd/service/ParcoursFiliereService";
import { Spinner } from "../../components/Spinner";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { SemestreService } from "../service/SemestreService";
import { NiveauService } from "../service/NiveauService";

const DashboardEnseignant = () => {
    let emptyStatDelib = {
        idDepartement: null,
        idAnnee: null,
        idSemestre: null,
        listeParcours: [
            {
                idParcours: null,
                libelleParcours: "",
                statutDelibearition: true,
            },
        ],
    };

    const chartDataCombo = {
        labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
        ],
        datasets: [
            {
                type: "line",
                label: "Dataset 1",
                borderColor: "#42A5F5",
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                data: [50, 25, 12, 48, 56, 76, 42],
            },
            {
                type: "bar",
                label: "Dataset 2",
                backgroundColor: "#66BB6A",
                data: [21, 84, 24, 75, 37, 65, 34],
                borderColor: "white",
                borderWidth: 2,
            },
            {
                type: "bar",
                label: "Dataset 3",
                backgroundColor: "#FFA726",
                data: [41, 52, 24, 74, 23, 21, 32],
            },
        ],
    };

    const lightOptionsCombo = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
            y: {
                ticks: {
                    color: "#495057",
                },
                grid: {
                    color: "#ebedef",
                },
            },
        },
    };

    let items = ["Nombre de garçon", "Nombre de fille"];
    let colorData = ["#66BB6A", "#FFA726"];
    let donnees = [1592, 1490];
    const chartDataPie = {
        labels: items,
        datasets: [
            {
                data: donnees,
                backgroundColor: colorData,
            },
        ],
    };

    const lightOptionsPie = {
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
    };

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y1: {
                    type: "linear",
                    display: true,
                    position: "right",
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: "#ebedef",
                    },
                },
            },
        };

        return {
            basicOptions,
            multiAxisOptions,
        };
    };

    const { basicOptions, multiAxisOptions } = getLightTheme();

    const goToSession = () => {
        //props.history.pushState("/lmd/session");
        window.location.hash("/lmd/session");
    };

    const tokenDecoded = localStorage.getItem("tokenDecoded");
    const token = JSON.parse(tokenDecoded);
    const campusDGESup = localStorage.getItem("campusDGESup");
    const campusVpEip = localStorage.getItem("campusVpEip");
    const campusDepartement = localStorage.getItem("campusDepartement");
    const isDGESup = parseInt(JSON.parse(campusDGESup));
    const isVp = parseInt(JSON.parse(campusVpEip));
    const isDepartement = parseInt(JSON.parse(campusDepartement));

    const [listeAnnee, setListeAnnee] = useState([]);
    const [curentAnnee, setCurentAnnee] = useState({});
    const [annee, setAnnee] = useState([]);

    const [etab, setEtab] = useState();
    const [dept, setDept] = useState();
    const [listeParcoursDepartement, setListeParcoursDepartement] = useState(
        []
    );
    const [parcours, setParcours] = useState();
    const [listeNiveau, setListeNiveau] = useState([]);
    const [listeSemestre, setListeSemestre] = useState([]);
    const [niveau, setNiveau] = useState();
    const [listeEtudiantParcoursNiveau, setListeEtudiantParcoursNiveau] =
        useState([]);

    const [pieDataUniv, setPieDataUniv] = useState();
    const [loadingUniv, setLoadingUniv] = useState(false);
    const [dialogParcours, setDialogParcours] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const anneeService = new AnneeService();
    const iesrService = new IesrService();
    const cohorteServices = new CohorteServices();
    const parcourService = new ParcoursFiliereService();
    const semestreService = new SemestreService();
    const niveauService = new NiveauService();
    const dashboardService = new DashboardService();

    const spinner = new Spinner();

    const onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === "annee") {
            setAnnee(val);
            if (dept) {
                parcourService
                    .getListeParcoursDepartement(dept.idDepartementEtab)
                    .then((data) => {
                        //console.log(data)
                        setListeParcoursDepartement(data);
                    });
            }
        } else if (field === "etab") {
            setEtab(val);
        } else if (field === "parcours") {
            setParcours(val);
            let niveau = [];
            semestreService.get(val.idParcours).then((data) => {
                let semestre = data;
                setListeSemestre(data);
                niveauService.getAll().then((response) => {
                    response.map((niv) => {
                        semestre.map((se) => {
                            if (niv.idNiveau === se.idNiveau) niveau.push(niv);
                        });
                    });
                    let collectionIdNiveau = [];
                    niveau.map((nive) => {
                        collectionIdNiveau.push(nive.idNiveau);
                    });
                    let listeNiveauParcours = [];
                    niveau.map((nivo, index) => {
                        for (
                            let i = index + 1;
                            i < collectionIdNiveau.length;
                            i++
                        ) {
                            if (nivo.idNiveau === collectionIdNiveau[i]) {
                                listeNiveauParcours.push(nivo);
                            }
                        }
                    });
                    setListeNiveau(listeNiveauParcours);
                });
            });
        } else if (field === "niveau") {
            setNiveau(val);
        }
    };

    useEffect(() => {
        anneeService
            .getAll()
            .then((data) => {
                setListeAnnee(data);
                //console.log(data)
                let nbElementAnnee = data.length;
                setCurentAnnee(data[nbElementAnnee - 1]);
            })
            .catch((error) => console.log(error));
    }, []);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
    });

    const onCurentAnneeChange = (event) => {
        setCurentAnnee(event.target.value);
    };

    const listeEtudiantParcours = () => {
        const idAnnee = annee.idAnneeAcademique;
        const idDepartement = dept.idDepartementEtab;
        const idParcours = parcours.idParcours;

        dashboardService
            .getListeEtudiantParcours(idDepartement, idParcours, idAnnee)
            .then((data) => {
                setListeEtudiantParcoursNiveau(data);
            });
    };

    const listeParcoursDialogFooter = (
        <React.Fragment>
            <Button
                label="Quitter"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        console.log("quitter !");
        setSubmitted(false);
        setDialogParcours(false);
    };

    return (
        <div className="card">
            <div className="p-grid">
                <div className="p-col-12">
                    <hr />
                    <h5>Année académique </h5>
                    {
                        <Dropdown
                            optionLabel="libelleAnnee"
                            value={curentAnnee}
                            optionValue={curentAnnee.libelleAnnee}
                            onChange={(e) => {
                                onCurentAnneeChange(e);
                            }}
                            options={listeAnnee}
                            placeholder="Choisir une année académique"
                            className="p-col-12"
                        />
                    }
                    <hr />
                </div>
            </div>

            <div className="p-grid">
                <div className="p-col-6">
                    <h5>Buttons d'action</h5>
                    <div className="p-grid">
                        <div>
                            <Button
                                onClick={(e) =>
                                    (window.location.hash = "/lmd/import-note")
                                }
                                style={{ marginLeft: 10 }}
                                label="Import de notes"
                            />
                            <Button
                                onClick={(e) =>
                                    (window.location.hash = "/lmd/saisie-note")
                                }
                                style={{ marginLeft: 10 }}
                                label="Saisie de notes"
                            />
                            <Button
                                onClick={(e) =>
                                    (window.location.hash =
                                        "/lmd/validation-note")
                                }
                                style={{ marginLeft: 10 }}
                                label="Validation de note"
                            />
                        </div>
                    </div>
                </div>

                <div className="p-col-6">
                    <h5>Nombre d'étudiants par parcours et genre </h5>
                    <div className="p-grid" style={{ marginTop: 10 }}>
                        <DataTable
                            header="Nombre d'etudiants par parcours et genre"
                            paginator={true}
                            rows={5}
                            filterMatchMode="contains"
                            responsive={true}
                        >
                            <Column field="" header="Parcours" />
                            <Column field="" header="Genre" />
                            <Column field="" header="Nombre d'etudiants" />
                        </DataTable>
                    </div>
                </div>
            </div>

            <div className="p-grid">
                <div className="p-col-6">
                    <h5>Nombre d'étudiants par semestre, parcours et genre</h5>
                    <div className="p-grid" style={{ marginTop: 10 }}>
                        <Dropdown
                            style={{ marginLeft: 10 }}
                            optionLabel=""
                            optionValue=""
                            placeholder="Choisir un semestre"
                        />
                        <Button style={{ marginLeft: 10 }} label="Lister" />
                    </div>
                    <br />
                    <div className="p-grid" style={{ marginRight: 5 }}>
                        <DataTable
                            header="Nombre d'etudiants par parcours"
                            paginator={true}
                            rows={5}
                            filterMatchMode="contains"
                            responsive={true}
                        >
                            <Column field="" header="Parcours" />
                            <Column field="" header="Genre" />
                            <Column field="" header="Nombre d'etudiants" />
                        </DataTable>
                    </div>
                </div>

                <div className="p-col-6">
                    <hr />
                    <h5>Nombre d'étudiants par genre</h5>
                    <div className="p-grid">
                        <DataTable
                            header="Nombre d'etudiants par parcours"
                            paginator={true}
                            rows={5}
                            filterMatchMode="contains"
                            responsive={true}
                        >
                            <Column field="" header="Genre" />
                            <Column field="" header="Nombre d'etudiants" />
                        </DataTable>
                    </div>
                </div>
            </div>

            <div className="p-grid">
                <div className="p-col-6">
                    <div className="p-grid" style={{ marginTop: 20 }}>
                        <h5>Proportion d'étudiants par genre</h5>
                        {
                            <Chart
                                className="p-col-12"
                                type="pie"
                                data={chartDataPie}
                                options={lightOptionsPie}
                            />
                        }
                    </div>
                </div>
            </div>

            <Dialog
                visible={dialogParcours}
                style={{ width: "50%" }}
                header="Liste Parcours"
                modal
                className="p-fluid"
                footer={listeParcoursDialogFooter}
                onHide={hideDialog}
            >
                <div className="p-grid">
                    <div className="p-col-3">
                        <Dropdown
                            optionLabel="libelleAnnee"
                            optionValue={annee.value}
                            value={annee}
                            options={listeAnnee}
                            onChange={(e) => {
                                onChampsChange(e, "annee");
                            }}
                            placeholder="Choisir une année"
                        />
                    </div>
                    <div className="p-col-3">
                        <Dropdown
                            optionLabel="libelleParcours"
                            optionValue={parcours}
                            value={parcours}
                            options={listeParcoursDepartement}
                            onChange={(e) => {
                                onChampsChange(e, "parcours");
                            }}
                            placeholder="Choisir un parcours"
                        />
                    </div>
                    {/*<div className='p-col-3'>
                        <Dropdown optionLabel="libelleNiveau" optionValue={niveau} value={niveau} options={listeNiveau} onChange={(e) => {onChampsChange(e, "niveau")}}  placeholder="Choisir un niveau"/>
                    </div>*/}
                    <div className="p-col-3">
                        <Button
                            style={{ marginLeft: 10 }}
                            label="Lister"
                            onClick={listeEtudiantParcours}
                        />
                    </div>
                </div>
                <div className="p-grid" style={{ marginTop: 10 }}>
                    <DataTable
                        value={listeEtudiantParcoursNiveau}
                        header="Nombre d'etudiants par parcours et par niveau"
                        paginator={true}
                        rows={5}
                        filterMatchMode="contains"
                        responsive={true}
                    >
                        {/*<Column field="libelleParcours" header="Parcours"/>*/}
                        {<Column field="libelleNiveau" header="Niveau" />}
                        <Column
                            field="effectifEtudiant"
                            header="Nombre d'etudiants"
                        />
                    </DataTable>
                </div>
            </Dialog>
        </div>
    );
};

export default DashboardEnseignant;
