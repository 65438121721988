import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Notify } from '../../components/Notify';
import { ToastContainer} from 'react-toastify';
import { Spinner } from '../../components/Spinner';
import { InscriptionService } from '../service/InscriptionService'
import md5 from 'md5';
import { AdresseIP } from '../../components/AdresseIP'

export class InscriptionAdministrative extends Component {

    constructor(props){
        super(props)
        this.adresse = new AdresseIP()

        this.notify = new Notify()
        this.spinner = new Spinner()
        this.ModelInscriptionService = new InscriptionService()
        this.idFranchises = React.createRef()
        this.idAccordPaiement = React.createRef()

        this.state = {
            etudiant: {},
            listeOption: [],
            globalFilter: null,
            payDialog: false,
            loading: false,
            inscriptionDialog: false,
            submitted: false,
            operateur: "",
            franchises: true,
            sessionChoix: {},
            idEtudiant: null,
            suivantMoov: false,
            suivantCoris: false,
            conditionAccepte: false,
            objetPaiement: {
                numero: "",
                codeSecret: ""
            },
            montantInscription: "",
            messageErreur: "",
            dejaInscrit: false,
            detailsInscriptionAdministratif: {},
            nationaliteDialog : false,
            selectNationaliteDialog : false,
            nationalite : {},
            nationalites : [],
            paiementInscription: null
        }
    }


    componentDidMount(){

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)
        let idEtudiant = null;


        //console.log(token)
        //On recupere le login qui est l'INE et l'on recupere l'identifiant de l'etudiant à travers
        //console.log(token.preferred_username.toUpperCase())
        this.ModelInscriptionService.getEtudiantByIne(token.preferred_username.toUpperCase()).then(data => {
            console.log("etudiant",data)                    

            //Gestion de la nationalité
            if(!data.nationalite){
                this.ModelInscriptionService.getNationaliteEtudiant().then(data =>{
                    this.setState({
                        nationaliteDialog :true,
                        nationalites : data
                    })
                    
                })
            }

            this.setState({
                idEtudiant: data.idEtudiant
            })
            idEtudiant = data.idEtudiant;

            this.setState({loading: true})
            this.ModelInscriptionService.testerTransactionAttenteLigdiCash(idEtudiant).then(responseData =>{

                    let existeSession = false
                    this.ModelInscriptionService.testerExistanceSessionInscriptionAdministrative(idEtudiant).then(data => {

                        if(data.length>0){
                            existeSession = true
                        }
                        this.setState({
                            listeOption: data,
                            etudiant: data,
                            loading: false
                        })
                        
                    }).catch(error => {
                        console.log(error)
                        this.setState({
                            loading: false
                        })
                    })

                    if(!existeSession){
                        this.ModelInscriptionService.getInformationInscriptionAdministratif(idEtudiant).then(detailsInscription => {
                            
                            console.log(detailsInscription)

                            let listePaiement = detailsInscription.paiements

                            if(listePaiement.length>0){
                                let curentPaiementInscription = listePaiement.filter(paiement => {
                                    return paiement.idTypeFraisPaiement===1
                                })

                                if(curentPaiementInscription.length>0){
                                    this.setState(
                                        {
                                            paiementInscription: curentPaiementInscription[0]
                                        }
                                    )
                                }
                                console.log(curentPaiementInscription[0])
                            }
                            

                           if(detailsInscription && detailsInscription.idInscriptionAdministrative){
                                this.setState({
                                    dejaInscrit: true,
                                    detailsInscriptionAdministratif: detailsInscription
                                })
                            }


                        }).catch(error => {
                            console.log(error)
                            this.notify.echec(error.response.data.message)
                        })
                    }

                    

                   


            }).catch(error => {
                this.setState({
                    loading: false
                })
                console.log(error)
                this.notify.echec(error.response.data.message)
            })


        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })



    }

    // viewNationaliteDialog = () => {
        
        
    //     this.setState({
    //         nationaliteDialog :true
    //     })


    //     //Gestion de la nationalité
        
    //     this.ModelInscriptionService.getNationaliteEtudiant().then(data =>{
    //         this.setState({
    //             nationaliteDialog :true,
    //             nationalites : data
    //         })
            
    //     })
        
    // }


    onInputChange = (event, fieldName) => {

        let objetPaiement = this.state.objetPaiement
        objetPaiement[`${fieldName}`] = event.target.value
        
        this.setState({
            objetPaiement: objetPaiement
        })
    }

    onNationaliteChange = (e) => {

        const val = (e.target && e.target.value) || "";
        this.setState({
            nationalite:val
        })
    }


    optionInscription = (sessionInscription) => {

            this.ModelInscriptionService.getInformationEtudiant(this.state.idEtudiant).then(data => {
                this.setState({
                    etudiant: data[0]
                })

            }).catch(error => {
                console.log(error)
            })
            

            this.ModelInscriptionService.getMontantInscription(sessionInscription.idParcours, sessionInscription.idNiveau, this.state.idEtudiant).then(montant => {


            //console.log(montant)
            this.setState({loading: true})
            this.ModelInscriptionService.testerExistanceSessionInscriptionAdministrative(this.state.idEtudiant).then(data => {

                //console.log(data)
                this.setState({
                    listeOption: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    loading: false
                })
            })


            this.setState({
                inscriptionDialog: true,
                sessionChoix: sessionInscription,
                montantInscription: montant
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec("Problème lié à l'information du montant paiement !!!")
        })
        
    }

    hideDialogNationalite = () => {

        
        //let quitter = true
        // this.setState({
        //     nationaliteDialog:true
        // })
        
        if(!this.state.nationalite) {
             window.confirm("Veuillez renseigner votre nationalité avant de quitter pour poursuivre votre inscription")
        }
        // if(this.state.suivantCoris || this.state.suivantMoov){
        //     quitter = window.confirm("Veuillez renseigner votre nationalité avant de quitter pour poursuivre votre inscription")
        // }
    }


     

    hideDialog = () => {

        let quitter = true

        if(this.state.suivantCoris || this.state.suivantMoov){
            quitter = window.confirm("Le processus de paiement est en cours, si vous quittez, vous risquez de recommencer le processus. Voulez vous vraiment quitter? !")
        }

        if(quitter){

            this.setState({
                inscriptionDialog: false,
                operateur: "",
                franchises: true,
                sessionChoix: {},
                suivantMoov: false,
                suivantCoris: false,
                conditionAccepte: false,
                objetPaiement: {
                    numero: "",
                    codeSecret: ""
                },
                messageErreur: ""
            })
        }
        
    }

    inscriptionAdministratif = (operateur) => {

        //console.log(this.state.sessionChoix)
        let inscriptionObject = {
            idEtudiant: this.state.etudiant.idEtudiant,
            idSessionInscription: this.state.sessionChoix.idSessionInscription,
            operateur: operateur
        }

        //console.log(inscriptionObject)
        this.ModelInscriptionService.InscriptionAdministrative(inscriptionObject).then(data => {
            this.notify.success()

            this.setState({
                loading: true
            })

             /*
            this.ModelInscriptionService.testerExistanceSessionInscription(this.state.idEtudiant).then(data => {
                this.setState({
                    listeOption: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    loading: false
                })
            })
            */

            this.setState({
                inscriptionDialog: false,
                operateur: "",
                franchises: false,
                sessionChoix: {}
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    inscriptionParOrange = () =>{

        //console.log(this.state.sessionChoix)
        let inscriptionObject = {
            idEtudiant: this.state.etudiant.idEtudiant,
            idSessionInscription: this.state.sessionChoix.idSessionInscription,
            operateur: "OM",
            numero: this.state.objetPaiement.numero,
            code: this.state.objetPaiement.codeSecret
        }
        
        this.ModelInscriptionService.paiementInscriptionAdministrative(inscriptionObject).then(data => {

            if(parseInt(data.status)===200){
                this.notify.success()
                this.setState({
                    inscriptionDialog: false,
                    operateur: "",
                    franchises: false,
                    sessionChoix: {},
                    listeOption: []
                })

            }else{
                this.setState({
                    messageErreur: data.message
                })
            }


        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    inscriptionEtape1Moov = () => {

        console.log("Moov etape 1")

        //console.log(this.state.sessionChoix)
        let inscriptionObject = {
            idEtudiant: this.state.etudiant.idEtudiant,
            idSessionInscription: this.state.sessionChoix.idSessionInscription,
            operateur: "moov",
            numero: this.state.objetPaiement.numero,
            code: this.state.objetPaiement.codeSecret
        }
        
        this.ModelInscriptionService.paiementInscriptionAdministrative(inscriptionObject).then(data => {
             /*
            let objetPaiement = this.state.objetPaiement
            objetPaiement['codeSecret'] = ""
            this.setState({
                suivantMoov: true,
                franchises: false,
                objetPaiement: objetPaiement
            })

            if(parseInt(data.status)===200){
                this.notify.success()
                this.setState({
                    inscriptionDialog: false,
                    operateur: "",
                    franchises: false,
                    sessionChoix: {},
                    listeOption: []
                })

            }else{
                this.setState({
                    messageErreur: data.message
                })
            }
            */
            
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    
        
    }

    inscriptionEtape2Moov = () => {
        console.log("Moov etape 2")
        //Traitement du paiement
    }

    inscriptionEtape1Coris = () => {
        console.log("Coris etape 1")

        let objetPaiement = this.state.objetPaiement
        objetPaiement['codeSecret'] = ""

        //Supposition que c'est bon pour gestion interface
        this.setState({
            suivantCoris: true,
            franchises: true,
            objetPaiement: objetPaiement
        })
    }

    inscriptionEtape2Coris = () => {
        console.log("Coris etape 2")
        //Traitement du paiement
    }

    onOperateurSelect = (operateur) => {

        let franchises = (operateur==="orange" || operateur==="moov")?true:false
        this.setState({
            operateur: operateur,
            franchises: franchises,
            objetPaiement: {
                numero: "",
                codeSecret: ""
            }
        })
    }


    telechargerAttestation = () => {

        let requestAttestation = {
            reportFormat: "PDF",
            reportType: "QUITTANCE",
            idEtudiant: this.state.idEtudiant,
            idPaiement: this.state.paiementInscription.idPaiement
        };

        console.log(requestAttestation)

        this.ModelInscriptionService.getAttestationInscriptionPedagogique(
            requestAttestation
        )
            .then((response) => {
                let blob = new Blob([response], { type: "application/pdf" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                let fileName = "Quittance_de_paiement";
                link.download = fileName;
                link.click();
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    saveNationalite = () => {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)
        let idEtudiant = null;

        this.ModelInscriptionService.getEtudiantByIne(token.preferred_username.toUpperCase()).then(data => {
            //console.log(data)

            this.setState({
                idEtudiant: data.idEtudiant
            })
            idEtudiant = data.idEtudiant
        

        //const idEtudiant = this.state.etudiant
        const idNationalite = this.state.nationalite.idNationalite
        

        console.log(idEtudiant,idNationalite)

        this.ModelInscriptionService.saveNationaliteEtudiant(idEtudiant,idNationalite).then(res => {
            
            this.setState({
                nationaliteDialog:false
                
            })
            this.notify.success()

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
        })

        
    }

    choixNationalite = () => {
        this.setState({
            selectNationaliteDialog: true
        })
    }

    hideSelectNationalite = () => {
        this.setState({
            selectNationaliteDialog: false
        })
    }

    choixNationaliteAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-check-circle" className="p-button-success" onClick={() => this.onNationaliteChangeByDatatable(rowData)} />
            </React.Fragment>
        );
    }

    onNationaliteChangeByDatatable = (nationalite) => {

        this.setState({           
            nationalite : nationalite,
            selectNationaliteDialog: false,
        });

        this.ModelInscriptionService.getNationaliteEtudiant().then(data =>{            
            this.setState({
                nationalites : data,               
                loading: false
            })
        }).catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message)
            this.setState({
                loading: false
            })
        })
    }


    inscriptionParLigdicash = () =>{
        let curentDate = Date()
        let callback_url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/ligdi-cash/confirm-transaction"


        this.ModelInscriptionService.getParametresLigdicash(this.state.sessionChoix.idSessionInscription).then(responseParametreLigdicah => {

            let structureData = {
                commande: {

                    invoice: {
                        items: [
                            {
                                name: "Paiement des frais d'inscription",
                                description: "Il s'agit de payer les frais d'inscription, etape préalable à l'inscription pédagogique",
                                quantity: 1,
                                unit_price: this.state.montantInscription,
                                total_price: this.state.montantInscription
                            }
                        ],
                        total_amount: this.state.montantInscription,
                        devise: "XOF",
                        description: "Il s'agit de payer les frais d'inscription, etape préalable à l'inscription pédagogique",
                        customer: "",
                        customer_firstname: this.state.etudiant.prenom,
                        customer_lastname: this.state.etudiant.nom,
                        customer_email: this.state.etudiant.email
                    },

                    store: {
                        name: "Plateforme integrée de l'enseignement superieur",
                        website_url: "https://services.campusfaso.bf"
                    },
    
                    actions: {
                        cancel_url: "https://services.campusfaso.bf/#/lmd/inscription-administrative",
                        return_url: "https://services.campusfaso.bf/#/lmd/inscription-administrative",
                        callback_url: callback_url
                    },
    
                    custom_data: {
                        transaction_id: md5(this.state.etudiant.idEtudiant+this.state.sessionChoix.idSessionInscription+curentDate),
                        idEtudiant: this.state.etudiant.idEtudiant,
                        idSessionInscription: this.state.sessionChoix.idSessionInscription
                    }   
                }
            }

            let headerData = {
                headers: {
                    Apikey: responseParametreLigdicah.apiKey,
                    Authorization: responseParametreLigdicah.apiAuthorisation,
                    Accept: "application/json",
                    'Content-Type': "application/json"
                } 
            }

            console.log(structureData)
            console.log(headerData)

            this.ModelInscriptionService.initierPaiementLigdicash(structureData, headerData).then(data => {
            //console.log(data)
            if(data.response_code==="00"){
                let dtoInitiationPaiement = {
                    token: data.token,
                    transactionId: md5(this.state.etudiant.idEtudiant+this.state.sessionChoix.idSessionInscription+curentDate),
                    idSessionInscription: this.state.sessionChoix.idSessionInscription,
                    idEtudiant: this.state.etudiant.idEtudiant,
                    urlPaiement: data.response_text
                }
                this.ModelInscriptionService.initierPaiementLigdicashBackend(dtoInitiationPaiement).then(donnee => {
                    window.location.replace(data.response_text);
                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })
            }else{
                this.setState(
                    {
                        messageErreur: data.message
                    }
                )
              this.notify.echec(data.message)
            }
    
          }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
          })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }



    confirmerConditionPaiement = () => {
        this.setState({
            conditionAccepte: (this.idAccordPaiement.current.checked && this.idFranchises.current.checked)
        })
    }

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check" className="p-button-success" style={{marginRight: '.5em'}} title="S'inscrire"  onClick = { () => this.optionInscription(rowData)}/>
            </React.Fragment>
        )
    }


    render() {

        let actionHeader = <Button className="enteteDatatable" type="button" icon="pi pi-cog"/>;

        const nationaliteFooter = (
            <React.Fragment>
                {/* <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogNationalite} /> */}
            
                <Button label="Enregistrer" icon="pi pi-money-bill" className="p-button-text" onClick={this.saveNationalite} required/>
            </React.Fragment>
        )

        const footerSelectNationalite = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectNationalite} />
            </React.Fragment>
        )
        
        const inscriptionFooter = (

            <React.Fragment>

                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />

                {/* debut ajout pour ligdicash */}
                <Button label="Payer mes frais" icon="pi pi-money-bill" className="p-button-text" onClick={this.inscriptionParLigdicash} disabled={!this.state.conditionAccepte}/>
                {/* Fin ajout pour ligdicash */}

                {/* Gestion par operateur abandonné pour la gestion par ligdicash */}
                {/*
                {(this.state.operateur==="orange" && this.state.conditionAccepte) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionParOrange} />
                    )
                } 

                {(this.state.operateur==="orange" && !this.state.conditionAccepte) &&      
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionParOrange} disabled />
                    )
                }

                {(this.state.operateur==="moov" && !this.state.suivantMoov && this.state.conditionAccepte) &&
                    <Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape1Moov} />
                }

                {(this.state.operateur==="moov" && !this.state.suivantMoov && !this.state.conditionAccepte) &&
                    <Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape1Moov} disabled />
                }

                {(this.state.suivantMoov) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Moov} />
                    )
                }

                {this.state.operateur==="coris" && !this.state.suivantCoris && 
                    <Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape1Coris} />
                }

                {(this.state.suivantCoris && this.state.conditionAccepte) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Coris} />
                    )
                }

                {(this.state.suivantCoris && !this.state.conditionAccepte) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Coris} disabled/>
                    )
                }
                */}

            </React.Fragment>

        );

        return (

            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                    {<ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />}

                        {this.state.loading ? 
                            <div className="p-grid">
                                <div className="p-col-6" ></div>
                                <div className="p-col-6" >
                                    {this.spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                        :
                            (this.state.listeOption.length>0) ?
                            <div>
                                <DataTable ref={(el) => this.dt = el} value={this.state.listeOption} selectionMode="single" header="Liste des sessions d'inscription concordant avec votre profile" paginator={true} rows={10} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.listeOption} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                    <Column field="libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                                    <Column field="libelleAnneeAcademique" header="Année académique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une année"/>
                                    <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un niveau"/>
                                    <Column field="dateOuvertureFormated" header="Date ouverture"  sortable={true} filter={true} filterPlaceholder="date ouverture"/>
                                    <Column field="dateFermetureFormated" header="Date fermeture"  sortable={true} filter={true} filterPlaceholder="date fermeture"/>
                                    <Column field="etatSession" header="Etat de la session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un état"/>
                                    <Column header={actionHeader} body={this.actionBodyTemplateSession} style={{textAlign:'center', width: '8em'}}/>
                                </DataTable>

                                <div>
                                <Button 
                                    label="Modifier la nationalité"  
                                    className="p-button-text" 
                                    onClick={this.viewNationaliteDialog} 
                                    required
                                />
                                </div>
                            </div>
                            
                                
                            :
                            <>
                            
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                <div>
                                    <h3>Aucune session d'inscription disponible pour votre profile</h3>
                                    {/*<button onClick={ () => {this.optionInscription()}}>Tester</button>*/}
                                </div>
                            </div>


                            {(this.state.dejaInscrit && this.state.detailsInscriptionAdministratif && this.state.detailsInscriptionAdministratif.idInscriptionAdministrative) && (
                                <div className="row">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <div className="p-col-12 p-md-12 p-lg-12">
                                            <h2>
                                                Détails sur l'inscription administrative
                                            </h2>
                                            <table
                                                className="table table-hover table-bordered table-striped"
                                                style={{ padding: "0px" }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Université</th>
                                                        <th>UFR</th>
                                                        <th>Parcours</th>
                                                        {/*<th>Niveau</th>*/}
                                                        <th>Année académique</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {this.state.detailsInscriptionAdministratif.libelleIsr}
                                                        </td>
                                                        <td>{this.state.detailsInscriptionAdministratif.libelleEtablessement}</td>
                                                        <td>
                                                            {this.state.detailsInscriptionAdministratif.parcours.libelleParcours}
                                                        </td>
                                                        {/*<td>{this.state.detailsInscriptionAdministratif.niveau.libelleNiveau}</td>*/}
                                                        <td>
                                                            {this.state.detailsInscriptionAdministratif.libelleAnneeAcademique}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>



                                    <div className="p-col-12 p-md-12 p-lg-6"  style={{ paddingTop: "6px" }}>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4>Mes informations</h4>
                                            </div>
                                            <div
                                                className="card-body"
                                                style={{ padding: "0px" }}
                                            >
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-12 p-lg-12">
                                                        <table
                                                            className="table table-hover table-bordered table-striped"
                                                            style={{
                                                                padding: "0px",
                                                            }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">
                                                                        INE
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            this.state.detailsInscriptionAdministratif.etudiant.ine
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        Nom
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            this.state.detailsInscriptionAdministratif.etudiant
                                                                                .nom
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        Prenom
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            this.state.detailsInscriptionAdministratif.etudiant
                                                                                .prenom
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        Date de
                                                                        naissance
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            this.state.detailsInscriptionAdministratif.etudiant
                                                                                .dateNaiss
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">
                                                                        Lieu de
                                                                        naissance
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            this.state.detailsInscriptionAdministratif.etudiant
                                                                                .lieuNaissance
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {this.state.paiementInscription &&
                                                        <div className="p-col-6 p-md-6 p-lg-6">
                                                            <div className="p-field">
                                                                <Button
                                                                    label="Télecharger la quittance de paiement"
                                                                    icon="pi pi-file-pdf"
                                                                    className="p-button-info p-mr-2"
                                                                    onClick={
                                                                        this
                                                                            .telechargerAttestation
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            



                            </>

                        }
                    </div>
                </div>


                
                <React.Fragment>
                    <Dialog 
                        visible={this.state.nationaliteDialog} 
                        style={{ width: '70%', height :'30%' }} 
                        header="Validation de la nationalité" 
                        modal className="p-fluid" 
                        footer={nationaliteFooter} 
                        onHide={this.hideDialogNationalite} 
                        >
                        <div className="p-grid p-lg-12 dialog-content">
                            <div className='p-lg-10'>
                                <Dropdown
                                    optionLabel="nationalite"
                                    value={this.state.nationalite}
                                    options={this.state.nationalites} 
                                    onChange={(e) => {this.onNationaliteChange(e)}}
                                    
                                    required
                                    className={classNames({
                                        "p-invalid": "this.state.submitted",
                                    })}
                                    placeholder="Selectionner votre nationalité"
                                />
                            </div>

                            <div className="p-field p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.choixNationalite}/>
                            </div>
                            
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.selectNationaliteDialog} style={{ width: '40%' }} header="Sélection de la nationalité" modal className="p-fluid" footer={footerSelectNationalite} onHide={this.hideSelectNationalite}>
                        <DataTable  ref={(el) => this.dt = el} value={this.state.nationalites} header="Liste des nationalités" paginator={true} rows={10} filterMatchMode="contains" responsive={true} >
                            <Column field="nationalite" header="Nationalite" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher une nationalité"/>
                            <Column header={actionHeader} body={this.choixNationaliteAction} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                    </Dialog>

                </React.Fragment>


                {/* Construction interface */}
                <Dialog visible={this.state.inscriptionDialog} style={{ width: '98%' }} header="Détails inscription administrative" modal className="p-fluid" footer={inscriptionFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="row">

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <h6>Identité étudiant</h6>
                                <hr/>
                            </div>

                            <div className='p-col-12 p-md-12 p-lg-12'>

                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="nom">Nom</label>
                                            <InputText id="nom" value={this.state.etudiant.nom} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="prenoms">Prénom(s)</label>
                                            <InputText id="prenoms" value={this.state.etudiant.prenom} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            

                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="ine">INE</label>
                                            <InputText id="ine" value={this.state.etudiant.ine} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="dateNaissance">Date de naissance</label>
                                            <InputText id="dateNaissance" value={this.state.etudiant.dateNaissance} readOnly/>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="nationalite">Nationalite</label>
                                            <InputText id="nationalite" value={this.state.etudiant.nomPays} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="telephone">Téléphone</label>
                                            <InputText id="telephone" value={this.state.etudiant.telephone} readOnly/>
                                        </div>   
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <h6>Informations cursus</h6>
                                <hr/>
                            </div>

                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="universite">Université</label>
                                            <InputText id="universite" value={this.state.sessionChoix.libelleIesr} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="ufr">UFR/Institut</label>
                                            <InputText id="ufr" value={this.state.sessionChoix.libelleDepartement} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            

                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="parcours">Parcours</label>
                                            <InputText id="parcours" value={this.state.sessionChoix.libelleParcours} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="niveau">Niveau</label>
                                            <InputText id="niveau" value={this.state.sessionChoix.libelleNiveau} readOnly/>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="anneeAcademique">Année académique</label>
                                            <InputText id="anneeAcademique" value={this.state.sessionChoix.libelleAnneeAcademique} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="frais">Frais d'inscription</label>
                                            <InputText id="frais" value={this.state.montantInscription} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <h6>Règlement des frais d'inscription</h6>
                                <hr/>
                            </div>

                            <div className='p-col-12 p-md-12 p-lg-12'>

                                {/*(!this.state.suivantCoris && !this.state.suivantMoov) &&
                                    (
                                        <div className="p-grid">
                                            <div className='p-col-12 p-md-12 p-lg-12'>
                                                <div className="p-field">
                                                    <label htmlFor="operateur">Sélection de l'opérateur</label>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="operateur" id="orange" value="orange" onClick = { () => this.onOperateurSelect("orange")}/>
                                                        <label className="form-check-label" for="orange">Orange Money</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="operateur" id="moov" value="moov" onClick = { () => this.onOperateurSelect("moov")}/>
                                                        <label className="form-check-label" for="moov">Moov Money</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="operateur" id="coris" value="coris" onClick = { () => this.onOperateurSelect("coris")}/>
                                                        <label className="form-check-label" for="coris">Coris Money</label>
                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>
                                        </div>
                                    )
                                */}



                                {/*(this.state.operateur==="orange" && !this.state.suivantCoris && !this.state.suivantMoov)?
                                
                                    <div className="p-grid">
                                        {
                                            this.state.messageErreur && <div className='p-col-12 p-md-12 p-lg-12'>
                                                <div class="alert alert-danger">{this.state.messageErreur}</div>
                                            </div>
                                        }
                                        
                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">
                                                                                
                                                <div id="OM" className="text-center" style={{display: 'block'}}>
                                                    <span className="fa fa-bullhorn"></span> <strong>Instructions</strong> <br/>
                                                    Saisissez la syntaxe suivante à partir de votre téléphone ayant un compte Orange Money <br/>
                                                    <strong style={{color: 'red', fontSize: '17px'}}>*144*4*6*{this.state.montantInscription}#</strong>. <br/>
                                                    Vous allez recevoir un code OTP (6 chiffres) par SMS.
                                                    Saisissez le numéro de télephone ayant servi à la génération du code, ainsi que le code OTP reçu dans les champs
                                                    indiqués ci-dessous puis validez pour effectuer le paiement.
                                                </div>

                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-6 p-md-12 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="compteOrange">Numéro de compte (Orange Money)</label>
                                                        <input id="compteOrange" type="number" value={this.state.objetPaiement.numero} placeholder="Saisir le numero de compte Orange Money" onChange={ (e) => this.onInputChange(e, 'numero') } className='p-inputtext p-component' />
                                                    </div>
                                                </div>
                                                <div className='p-col-6 p-md-6 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="codeOTP">Code OTP</label>
                                                        <InputText id="codeOTP" value={this.state.objetPaiement.codeSecret} placeholder="Saisir le code OTP" onChange={(e) => {this.onInputChange(e, 'codeSecret')}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    (this.state.operateur==="coris" && !this.state.suivantCoris && !this.state.suivantMoov)?
                                    
                                    <div className="p-grid">

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">

                                                <div className="text-center" style={{display: 'block'}}>
                                                    <span className="fa fa-bullhorn"></span> 
                                                    <strong>Instructions</strong><br/>
                                                    <strong style={{color: 'red', fontSize: '17px'}}>
                                                        Pour procéder au versement de votre scolarité, 
                                                        renseignez votre numéro de téléphone Coris Money ainsi que 
                                                        votre code secret puis validez. <br/>
                                                        Le montant à payer est : {this.state.montantInscription} FCFA
                                                    </strong>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-12 p-md-12 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="compteCoris">Numéro de compte (Coris Money)</label>
                                                        <InputText id="compteCoris" type="number" value={this.state.objetPaiement.numero} placeholder="Saisir le numero de compte Coris Money" className='p-inputtext p-component' onChange={ (e) => this.onInputChange(e, 'numero')}/>
                                                    </div>
                                                </div>
                                                <div className='p-col-6 p-md-6 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="codeCoris">Code sécret</label>
                                                        <InputText type="password" id="codeCoris" value={this.state.objetPaiement.codeSecret} placeholder="Saisir le code sécret" onChange={(e) => this.onInputChange(e, 'codeSecret')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :

                                    <div className="p-grid">

                                        {(this.state.operateur==="moov"  && !this.state.suivantCoris && !this.state.suivantMoov) 
                                            && 
                                        <React.Fragment>
                                            
                                            <div className='p-col-12 p-md-12 p-lg-6'>
                                                <div class="alert alert-warning">
                                                    <div className="text-center">
                                                        <span className="fa fa-bullhorn"></span> 
                                                        <strong>Instructions</strong><br/>
                                                        Pour payer votre scolarité, renseigner le numero Moov Money avec lequel vous voulez effectuer le paiement. 
                                                        Le montant à payer est :<br/> <strong style={{color: 'red', fontSize: '17px' }}>{this.state.montantInscription} FCFA</strong>. <br/>
                                                        Vous allez recevoir un SMS vous indiquant la procedure à suivre.<br/>
                                                        Une fois le paiement effectué, il faudra valider le paiement
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-12 p-lg-6'>
                                                <div className="p-grid">
                                                    <div className='p-col-12 p-md-12 p-lg-12'>
                                                        <div className="p-field">
                                                            <label htmlFor="compteMoov">Numéro de compte (Moov Money)</label>
                                                            <InputText id="compteMoov" type="number" value={this.state.objetPaiement.numero} placeholder="Saisir le numero de compte Moov Money" onChange={(e) => this.onInputChange(e, 'numero') }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>}
                                    </div>
                                */}


                                    
                                        
                                {/*(this.state.suivantMoov) 
                                    && 
                                    <div className="p-grid">
                                    
                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">
                                                <div className="text-center">
                                                    <span className="fa fa-bullhorn"></span> 
                                                    <strong>Instructions Moov Money</strong><br/>
                                                    Veuillez suivre l'instruction reçu par SMS sur le numéro <br/><strong style={{color: 'red', fontSize: '17px' }}>{this.state.objetPaiement.numero}</strong><br/> puis cliquez sur le button <strong style={{color: 'red', fontSize: '17px' }}>« Valider le paiement Moov »</strong> pour valider le paiement sur Campusfaso.
                                                </div>
                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-12 p-md-12 p-lg-12'>
                                                    <div className="p-field">
                                                        <Button label="Valider le paiement Moov" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Moov} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                */}


                                {/*(this.state.suivantCoris) && 
                                    <div className="p-grid">
                                    
                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">
                                                <div className="text-center">
                                                    <span className="fa fa-bullhorn"></span> 
                                                    <strong>Instructions Coris Money</strong><br/>
                                                    Pour terminer l’opération de versement de votre scolarité, renseignez le code de confirmation que vous avez reçu par SMS sur votre numero <br/><strong style={{color: 'red', fontSize: '17px' }}>{this.state.objetPaiement.numero}</strong><br/> acceptez les conditions de paiement en cochant les cases à cocher puis validez.
                                                </div>
                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-12 p-md-12 p-lg-12'>
                                                    <div className="p-field">
                                                        <label htmlFor="codeConfirmation">Code secret de la transaction</label>
                                                        <InputText id="codeConfirmation" value={this.state.objetPaiement.codeSecret} placeholder="Saisir le code sécret reçu par SMS" onChange = {(e) => this.onInputChange(e, 'codeSecret')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                */}







                                {/* Nouveau ajout pour ligdicash */}
                                <div className="p-grid">
                                    {
                                        this.state.messageErreur && <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div class="alert alert-danger">{this.state.messageErreur}</div>
                                        </div>
                                    }
                                    
                                    <div className='p-col-12 p-md-12 p-lg-12'>
                                        <div class="alert alert-warning">
                                                                            
                                            <div id="OM" className="text-center" style={{display: 'block'}}>
                                                <span className="fa fa-bullhorn"></span> <strong>Instructions</strong> <br/>
                                                Acceptez les franchises universitaires puis, cliquez sur le bouton « payer mes frais » 
                                                <br/>
                                                <strong style={{color: 'red', fontSize: '17px'}}> Montant: {this.state.montantInscription} FCFA</strong> <br/>
                                                Vous allez être redirrigé vers la plateforme de paiement.
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                {/* Nouveau ajout pour ligdicash */}
                            


                                {this.state.franchises && 
                                    <div className='p-col-12 p-md-12 p-lg-12'>
                                        
                                        <div className="p-field">
                                            <label htmlFor="operateur">Franchises universitaire</label>
                                            <hr/>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="confirmation" id="confirmation" onClick={this.confirmerConditionPaiement} ref={this.idFranchises}/>
                                                <label className="form-check-label" for="confirmation">
                                                    J'accepte et je certifie par ce paiement que mes informations sont correctes et me conformer aux franchises universitaires de mon établissement d'accueil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="validation" id="validation" onClick={this.confirmerConditionPaiement} ref={this.idAccordPaiement}/>
                                                <label class="form-check-label" for="validation">
                                                    Le paiement des frais d’inscription constitue une preuve de la validation de mon programme d’étude et ne sont pas remboursables
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                </Dialog>

            </div>
        )
    }
}

export default InscriptionAdministrative