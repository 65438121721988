import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeMembreJuryService {

    constructor(){
        this.adresse = new AdresseIP()
    }

   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-membre"
       return axios.get(url).then(res => res.data) 
   }
   
   create(newTypeMembre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-membre"
       return axios.post(url,newTypeMembre)
                   .then(res => res.data);
   }

   update(idTypeMembre ,newTypeMembre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-membre/"
       return axios.put(url+idTypeMembre, newTypeMembre)
                   .then(res => res.data);
   }

   delete(idTypeMembre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-membre/"
       return axios.patch(url+idTypeMembre)
                   .then(res => res.data)
   }

}