import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { InscriptionService } from "../service/InscriptionService";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Infrastructures from "../../volumeHoraire/components/parametres/Infrastructures";
import { RadioButton } from 'primereact/radiobutton';

export class InscriptionPedagogique extends Component {
    constructor(props) {
        super(props);

        this.notify = new Notify();
        this.spinner = new Spinner();
        this.ModelInscriptionService = new InscriptionService();
        this.conformite = React.createRef();
        this.franchise = React.createRef();
        this.rfPhoto = React.createRef();
        this.rfDocumentDiplome = React.createRef();
        this.rfDocumentExtrait = React.createRef();
        this.rfDocumentCessation = React.createRef();

        this.state = {
            etudiant: {},
            listeOption: [],
            globalFilter: null,
            payDialog: false,
            loading: true,
            inscriptionDialog: false,
            submitted: false,
            operateur: "",
            franchises: false,
            sessionChoix: {},
            idEtudiant: null,
            listeECSemestre1: [],
            listeECSemestre2: [],
            listeECOptionnel1: [],
            listeECOptionnel2: [],
            listeECChoisi1: [],
            listeECChoisi2: [],
            nombreECChoix1: null,
            nombreECChoix2: null,

            nombreUEChoix1: null,
            nombreUEChoix2: null,
            listeUEChoisi1: [],
            listeUEChoisi2: [],
            viewDetailsUEOptionnel: [],
            
            listeSelection: null,
            existeOption: true,
            existeOption1: true,
            existeOption2: true,
            franchisesOk: false,
            informationEtudiant: {},
            extension: null,
            errorFile: "",
            titreFenetre: "",
            valideMail: true,
            dejaInscrit: false,
            loadingDetailInscription: true,
            existeUEOptionS1: false,
            existeUEOptionS2: false,
            listeUEOptionS1: [],
            listeUEOptionS2: [],
            selectionUe1: null,
            listeEcUeOptionnel1: [],
            listeEcUeOptionnel2: [],
            isEnsStudent: null,
            ensInformationCompleted: null,
            ensCompletionData: {
                paysNaissance: "",
                situationFamiliale: "",
                nomPere: "",
                professionPere: "",
                nomMere: "",
                professionMere: "",
                diplome: "",
                paysProvenanceDiplome: "",
                mention: "",
                anciennete: "",
                nationalite: "",
                titreUniversitaire: "",
                diplomeProfessionel: "",
                numeroCompteBancaire: ""
            },
        listePays: [],
        documentDiplome: null,
        documentExtrait: null,
        photo: null,
        extensionPhoto : null,
        documentCessation: null,
        typeDate: null,
        anneeNaissance: null,
        documentReleveBancaire: null,
        listeECUEOptionnelChoisi: [],
        viewEcOfUEChoiDialog: false,
        sessionPedagogique : null,

        };
    }




    componentDidMount() {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);

        let isStagiaire = false;
        let informationCompleted = false;
        let idEtudiant = null
        
        this.ModelInscriptionService.getInformationEtudiantByIne(token.preferred_username.toUpperCase()).then(data => {

            isStagiaire = (data.idTypeStagiaire!==null)
            informationCompleted = (data.detailStagiaire!==null)
            idEtudiant = data.idEtudiant

            this.setState({
                etudiant: data,
                informationEtudiant: data,
                idEtudiant: data.idEtudiant,
                isEnsStudent: isStagiaire,
                ensInformationCompleted: informationCompleted,
            })

            //console.log(isStagiaire)
            if(!isStagiaire){

                this.ModelInscriptionService.testerExistanceSessionInscriptionPedagogique(
                    idEtudiant
                )
                    .then((data) => {
                        if (data) {
                            console.log(data)
                            let inscription = [data];

                            console.log(inscription)
                            this.setState({
                                listeOption: inscription,
                                loading: false,
                                loadingDetailInscription: false
                            });
    
                        } else {
    
                            //Recuperation des informations de l'inscription
                            this.getInformationInscription(idEtudiant)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        
                        this.setState({
                            loading: false,
                            loadingDetailInscription: false
                        });
                    });
    
    
            }else if(isStagiaire && !informationCompleted){
                
                this.ModelInscriptionService.getSessionInscriptionEns(idEtudiant).then(data => {
                    console.log(data)
                    
                    this.setState({
                        listeOption: [data],
                        loadingDetailInscription: false,
                        loading: false
                    })

                }).catch(error => {

                    console.log(error);
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loadingDetailInscription: false,
                        loading: false
                    })
                })


            }else{

                console.log('first')
                this.getInformationInscription(idEtudiant)
            }
            
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }


    getInformationInscription = (idEtudiant) => {
        //On fait appel aux informations concernant une eventuelle inscription déjà faite. Puis jouer sur la variable dejaInscrit
        this.ModelInscriptionService.testerInscriptionPedagogiqueEtudiant(
            idEtudiant
        )
        .then((data) => {
            console.log(data)

            this.setState({
                idInscriptionPedagogique : data.idInscriptionPedagogique,
                dejaInscrit: true,
            });
            let ecSemestre1 =
                data.listeEcObligatoirePremierSemestre;
            if (
                data.listeEcOptionnelPremierSemestre.length > 0
            ) {
                ecSemestre1 = ecSemestre1.concat(
                    data.listeEcOptionnelPremierSemestre
                );
            }

            let ecSemestre2 =
                data.listeEcObligatoireDeuxiemeSemestre;
            if (
                data.listeEcOptionnelDeuxiemeSemestre.length > 0
            ) {
                ecSemestre2 = ecSemestre2.concat(
                    data.listeEcOptionnelDeuxiemeSemestre
                );
            }

            this.setState({
                
                listeECSemestre1: ecSemestre1,
                listeECSemestre2: ecSemestre2,
                photo: data.photo,
                extension: data.extensionPhoto,
                listeOption: [],
                loading: false,
                dejaInscrit: true,
                loadingDetailInscription: false,
                universite: data.nomIesr,
                ufr: data.nomDepartement,
                parcours: data.libelleParcours,
                niveau: data.libelleNiveau,
                anneeAcademique: data.libelleAnnee,
            });
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                loadingDetailInscription: false,
            });
        });

    }

    optionInscription = (sessionInscription) => {
        
        if(this.state.isEnsStudent){


            this.ModelInscriptionService.getListePays().then(data => {

                console.log(data)
                this.setState({
                    listePays: data
                })
                
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })

            console.log(this.state.etudiant)



            
            this.ModelInscriptionService.getLIsteDocument(this.state.etudiant.idTypeStagiaire).then(data => {
                console.log(data)
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
            


            this.setState({
                inscriptionEnsDialog: true
            })




        }else{
            this.ModelInscriptionService.getInformationInscriptionPedagogique(
                this.state.idEtudiant,
                sessionInscription.idSessionInscription
            )
                .then((data) => {
                    console.log(data);
                    console.log(data.listeUeOptionnelDeuxiemeSemestre)
    
                    this.setState({
                        listeECSemestre1: data.listeEcObligatoirePremierSemestre,
                        listeECSemestre2: data.listeEcObligatoireDeuxiemeSemestre,
                        listeECOptionnel1: data.listeEcOptionnelPremierSemestre,
                        listeECOptionnel2: data.listeEcOptionnelDeuxiemeSemestre,
                        existeOption:
                            data.listeEcOptionnelPremierSemestre.length > 0 ||
                            data.listeEcOptionnelDeuxiemeSemestre.length > 0,
                        existeOption1:
                            data.listeEcOptionnelPremierSemestre.length > 0,
                        existeOption2:
                            data.listeEcOptionnelDeuxiemeSemestre.length > 0,
                        inscriptionDialog: true,
                        sessionChoix: sessionInscription,
                        nombreECChoix1: data.nbEcOptionnelChoixPremierSemestre,
                        nombreECChoix2: data.nbEcOptionnelChoixDeuxiemeSemestre,
                        titreFenetre:
                            "Détails inscription pédagogique | " +
                            sessionInscription.libelleParcours +
                            " - " +
                            sessionInscription.libelleNiveau +
                            " - " +
                            sessionInscription.libelleAnneeAcademique +
                            " ( " +
                            sessionInscription.libelleIesr +
                            " )",
                        existeUEOptionS1:
                            data.listeUeOptionnelPremierSemestre.length > 0,
                        existeUEOptionS2:
                            data.listeUeOptionnelDeuxiemeSemestre.length > 0,
                        listeUEOptionS1: data.listeUeOptionnelPremierSemestre,
                        listeUEOptionS2: data.listeUeOptionnelDeuxiemeSemestre,
                        nombreUEChoix1:  data.nbUeOptionnelChoixPremierSemestre,
                        nombreUEChoix2: data.nbUeOptionnelChoixDeuxiemeSemestre
                    });



                    console.log({
                        listeECSemestre1: data.listeEcObligatoirePremierSemestre,
                        listeECSemestre2: data.listeEcObligatoireDeuxiemeSemestre,
                        listeECOptionnel1: data.listeEcOptionnelPremierSemestre,
                        listeECOptionnel2: data.listeEcOptionnelDeuxiemeSemestre,
                        existeOption:
                            data.listeEcOptionnelPremierSemestre.length > 0 ||
                            data.listeEcOptionnelDeuxiemeSemestre.length > 0,
                        existeOption1:
                            data.listeEcOptionnelPremierSemestre.length > 0,
                        existeOption2:
                            data.listeEcOptionnelDeuxiemeSemestre.length > 0,
                        inscriptionDialog: true,
                        sessionChoix: sessionInscription,
                        nombreECChoix1: data.nbEcOptionnelChoixPremierSemestre,
                        nombreECChoix2: data.nbEcOptionnelChoixDeuxiemeSemestre,
                        titreFenetre:
                            "Détails inscription pédagogique | " +
                            sessionInscription.libelleParcours +
                            " - " +
                            sessionInscription.libelleNiveau +
                            " - " +
                            sessionInscription.libelleAnneeAcademique +
                            " ( " +
                            sessionInscription.libelleIesr +
                            " )",
                        existeUEOptionS1:
                            data.listeUeOptionnelPremierSemestre.length > 0,
                        existeUEOptionS2:
                            data.listeUeOptionnelDeuxiemeSemestre.length > 0,
                        listeUEOptionS1: data.listeUeOptionnelPremierSemestre,
                        listeUEOptionS2: data.listeUeOptionnelDeuxiemeSemestre,
                        nombreUEChoix1: data.nbUeOptionnelChoixPremierSemestre,
                        nombreUEChoix2: data.nbUeOptionnelChoixDeuxiemeSemestre 
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        }
        
    };

    hideDialog = () => {
        this.setState({
            inscriptionDialog: false,
            operateur: "",
            franchises: false,
            sessionChoix: {},
            nombreECChoix1: 0,
            nombreECChoix2: 0,
            nombreUEChoix1: 0,
            nombreUEChoix2: 0,
            listeECOptionnel1: [],
            listeECOptionnel2: [],
            listeECSemestre1: [],
            listeECSemestre2: [],
            existeOption: false,
            existeOption1: false,
            existeOption2: false,
            photo: null,
            extension: null,
            existeUEOptionS1: false,
            existeUEOptionS2: false,
            listeUEOptionS1: [],
            listeUEOptionS2: [],
            listeEcUeOptionnel1: [],
            listeEcUeOptionnel2: [],
            listeUEChoisi1: [],
            listeUEChoisi2: []
        });
    };




    hideEnsDialog = () => {
        this.setState({
            inscriptionEnsDialog: false,
            operateur: "",
            franchises: false,
            photo: null,
            extension: null,
            typeDate: null,
            anneeNaissance: null,
            ensCompletionData: {
                paysNaissance: "",
                situationFamiliale: "",
                nomPere: "",
                professionPere: "",
                nomMere: "",
                professionMere: "",
                diplome: "",
                paysProvenanceDiplome: "",
                mention: "",
                anciennete: "",
                nationalite: "",
                titreUniversitaire: "",
                diplomeProfessionel: "",
                numeroCompteBancaire: ""
            },
            errorFileCessation: null,
            errorFileDiplome: null,
            errorFileExtrait: null,
            errorFilePhoto: null,
            errorFileReleveBanque: null
        });
    };

    selectionChoixEC1 = (ecChoix) => {
        let listeECChoisi1 = this.state.listeECChoisi1;
        if (listeECChoisi1.length < this.state.nombreECChoix1) {
            let listeIdECChoix = listeECChoisi1.map((element) => {
                return element.idElementConstitutif;
            });

            if (listeIdECChoix.indexOf(ecChoix.idElementConstitutif) !== -1) {
                alert("Vous avez déjà choisi cet Element constitutif ");
            } else {
                listeECChoisi1.push(ecChoix);
            }

            this.setState({
                listeECChoisi1: listeECChoisi1,
            });
        } else {
            alert("Vous avez déjà atteint le nombre de choix réquis");
        }
    };

    selectionUEOptionS1 = (data) => {
        let listeUEChoi1 = this.state.listeUEChoisi1
        let nbUEOptionObligatoire = this.state.nombreUEChoix1

        if(listeUEChoi1.length<nbUEOptionObligatoire){

            let listeIdUEChoix = listeUEChoi1.map(ue => {
                return ue.idUniteEnseignement
            })

            if(listeIdUEChoix.indexOf(data.idUniteEnseignement === -1)){
                listeUEChoi1.push(data)
                this.setState({
                    listeUEChoi1: listeUEChoi1,
                });
            }else{
                alert('Figure déjà dans vos choix !!!')
            }
        }else{
            alert('Vous avez atteint le nombre de choix attendu !!!')
        }
    };


    viewECOfSelectedUE = (data) => {
        this.setState({
            listeEcUeOptionnel1: data.listeElementConstitutif,
        });
    };

    selectionUEOptionS2 = (data) => {
        let listeUEChoi2 = this.state.listeUEChoisi2
        let nbUEOptionObligatoire = this.state.nombreUEChoix2

        if(listeUEChoi2.length<nbUEOptionObligatoire){

            let listeIdUEChoix = listeUEChoi2.map(ue => {
                return ue.idUniteEnseignement
            })

            if(listeIdUEChoix.indexOf(data.idUniteEnseignement === -1)){
                listeUEChoi2.push(data)
                this.setState({
                    listeUEChoi2: listeUEChoi2,
                });
            }else{
                alert('Figure déjà dans vos choix !!!')
            }
        }else{
            alert('Vous avez atteint le nombre de choix attendu !!!')
        }
    };

    selectionChoixEC2 = (ecChoix) => {
        let listeECChoisi2 = this.state.listeECChoisi2;
        if (listeECChoisi2.length < this.state.nombreECChoix2) {
            let listeIdECChoix = listeECChoisi2.map((element) => {
                return element.idElementConstitutif;
            });

            if (listeIdECChoix.indexOf(ecChoix.idElementConstitutif) !== -1) {
                alert("Vous avez déjà choisi cet' Element constitutif ");
            } else {
                listeECChoisi2.push(ecChoix);
            }

            this.setState({
                listeECChoisi2: listeECChoisi2,
            });
        } else {
            alert("Vous avez déjà atteint le nombre de choix réquis");
        }
    };

    annulationChoixEC1 = (ecChoix) => {
        let listeECChoisi1 = this.state.listeECChoisi1;

        let listeNewECChoisi1 = listeECChoisi1.filter((element) => {
            return (
                element.idElementConstitutif !== ecChoix.idElementConstitutif
            );
        });

        this.setState({
            listeECChoisi1: listeNewECChoisi1,
        });
    };

    annulationChoixEC2 = (ecChoix) => {
        let listeECChoisi2 = this.state.listeECChoisi2;

        let listeNewECChoisi2 = listeECChoisi2.filter((element) => {
            return (
                element.idElementConstitutif !== ecChoix.idElementConstitutif
            );
        });

        this.setState({
            listeECChoisi2: listeNewECChoisi2,
        });
    };

    inscriptionPedagogiqueEtudiant = () => {
        this.setState({
            submitted: true,
        });

        let valideEtudiant =
            this.state.valideMail &&
            this.state.informationEtudiant.telephone &&
            this.state.informationEtudiant.contactPersonnePrevenir &&
            this.state.informationEtudiant.personnePrevenir &&
            this.state.informationEtudiant.personnePrevenir.length >= 4
                ? true
                : false;

        let valideChoixEC =
            (!this.state.existeOption1 ||
                this.state.listeECChoisi1.length ===
                    this.state.nombreECChoix1) &&
            (!this.state.existeOption2 ||
                this.state.listeECChoisi2.length === this.state.nombreECChoix2)
                ? true
                : false;

        let valideChoixUEOptionnel =
            (!this.state.existeUEOptionS1 ||
                (this.state.existeUEOptionS1 &&
                    this.state.listeUEChoisi1.length === this.state.nombreUEChoix1)) &&
            (!this.state.existeUEOptionS2 ||
                (this.state.existeUEOptionS2 &&
                    this.state.listeUEChoisi2.length === this.state.nombreUEChoix2))
                ? true
                : false;

        let validitePhoto =
            (this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 99 &&
                this.rfPhoto.current.naturalHeight === 128) ||
            (this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 138 &&
                this.rfPhoto.current.naturalHeight === 177) ||
            (this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 207 &&
                this.rfPhoto.current.naturalHeight === 266)
                ? true
                : false;

        if (
            valideEtudiant &&
            valideChoixEC &&
            validitePhoto &&
            valideChoixUEOptionnel
        ) {
            /*Preparation de l'objet pour envoi*/
            let listeECInscriptionOblibatoire =
                this.state.listeECSemestre1.concat(this.state.listeECSemestre2);

            if (this.state.existeOption1) {
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        this.state.listeECChoisi1
                    );
            }

            if (this.state.existeOption2) {
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        this.state.listeECChoisi2
                    );
            }

            if (this.state.existeUEOptionS1) {
                let listeUEChoiOption = this.state.listeUEChoisi1
                let listeEcUeOptionnel1 = []
                listeUEChoiOption.map(curentUE =>{
                    curentUE.listeElementConstitutif.map(ec => {
                        listeEcUeOptionnel1.push(ec)
                    })
                })
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        listeEcUeOptionnel1
                    );
            }

            if (this.state.existeUEOptionS2) {
                let listeEcUeOptionnel2 = []
                let listeUEChoiOption = this.state.listeUEChoisi2

                console.log(listeUEChoiOption)

                listeUEChoiOption.map(curentUE => {
                    curentUE.listeElementConstitutif.map(ec => {
                        listeEcUeOptionnel2.push(ec)
                    })
                    
                })

                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        listeEcUeOptionnel2
                    );
            }

            let objetReinscription = {
                idSessionInscription:
                    this.state.sessionChoix.idSessionInscription,
                etudiant: this.state.informationEtudiant,
                photo: this.state.photo,
                extensionPhoto: this.state.extension,
                listeElementConstitutif: listeECInscriptionOblibatoire,
            };

            console.log(objetReinscription)

            this.setState({
                weetResult: true
            })
            
            this.ModelInscriptionService.inscrireEtudiant(objetReinscription)
                .then((data) => {
                    this.notify.success();

                    this.setState({ loading: true });
                    this.ModelInscriptionService.testerExistanceSessionInscriptionPedagogique(
                        this.state.idEtudiant
                    )
                        .then((data) => {
                            //console.log(data)

                            if (data) {
                                let inscription = [data];
                                this.setState({
                                    listeOption: inscription,
                                    loading: false,
                                });
                            } else {
                                //On fait appel aux informations concernant l'inscription déjà faite. Puis jouer sur la variable dejaInscrit
                                this.ModelInscriptionService.testerInscriptionPedagogiqueEtudiant(
                                    this.state.idEtudiant
                                )
                                    .then((data) => {
                                        //console.log(data)
                                        this.setState({
                                            dejaInscrit: true,
                                            listeOption: [],
                                        });

                                        let ecSemestre1 =
                                            data.listeEcObligatoirePremierSemestre;
                                        if (
                                            data.listeEcOptionnelPremierSemestre
                                                .length > 0
                                        ) {
                                            ecSemestre1 = ecSemestre1.concat(
                                                data.listeEcOptionnelPremierSemestre
                                            );
                                        }

                                        let ecSemestre2 =
                                            data.listeEcObligatoireDeuxiemeSemestre;
                                        if (
                                            data
                                                .listeEcOptionnelDeuxiemeSemestre
                                                .length > 0
                                        ) {
                                            ecSemestre2 = ecSemestre2.concat(
                                                data.listeEcOptionnelDeuxiemeSemestre
                                            );
                                        }

                                        this.setState({
                                            listeECSemestre1: ecSemestre1,
                                            listeECSemestre2: ecSemestre2,
                                            photo: data.photo,
                                            extension: data.extensionPhoto,
                                            listeOption: [],
                                            loading: false,
                                            dejaInscrit: true,
                                            universite: data.nomIesr,
                                            ufr: data.nomDepartement,
                                            parcours: data.libelleParcours,
                                            niveau: data.libelleNiveau,
                                            anneeAcademique: data.libelleAnnee,
                                        });
                                    })
                                    .catch((error) => {
                                        //Erreur lors de la recupération de l'inscription pédagogique
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            //Erreur lors de l'inscription
                            console.log(error);
                            this.setState({
                                loading: false,
                            });
                        });

                    this.setState({
                        inscriptionDialog: false,
                        operateur: "",
                        franchises: false,
                        sessionChoix: {},
                        nombreECChoix1: 0,
                        nombreECChoix2: 0,
                        nombreUEChoix1: 0,
                        nombreUEChoix2: 0,
                        listeECOptionnel1: [],
                        listeECOptionnel2: [],
                        listeECSemestre1: [],
                        listeECSemestre2: [],
                        existeOption: false,
                        existeOption1: false,
                        existeOption2: false,
                        photo: null,
                        extension: null,
                        existeUEOptionS1: false,
                        existeUEOptionS2: false,
                        listeUEOptionS1: [],
                        listeUEOptionS2: [],
                        listeEcUeOptionnel1: [],
                        listeEcUeOptionnel2: [],
                        listeUEChoisi1: [],
                        listeUEChoisi2: [],
                        listeECUEOptionnelChoisi: [],
                        weetResult: false
                    });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);

                    this.setState({
                        weetResult: false
                    })
                });
                

            //console.log(objetReinscription)
        } else {
            if (!validitePhoto && this.state.photo) {
                this.setState({
                    errorFile:
                        "La taille de la photo ne correspond à aucune des tailles au choix: (99px*128px); (138px*177px); (207px*266px))",
                });
            } else if (!this.state.photo) {
                this.setState({
                    errorFile: "Charger obligatoirement une photo valide !",
                });
            }
        }
    };


    inscriptionEnsEtudiant = () => {
        this.setState({
            submitted: true
        });

        let dataInscription = this.state.informationEtudiant;
        let detailsDto = this.state.ensCompletionData

        let valideEtudiant =
            this.state.valideMail &&
            this.state.informationEtudiant.telephone &&
            this.state.informationEtudiant.contactPersonnePrevenir &&
            this.state.informationEtudiant.personnePrevenir &&
            this.state.informationEtudiant.personnePrevenir.length >= 4
                ? true
                : false;

        console.log("Validation Etud :",valideEtudiant)  
             

        let validitePhoto =
            (
                this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 99 &&
                this.rfPhoto.current.naturalHeight === 128) ||
            (
                this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 138 &&
                this.rfPhoto.current.naturalHeight === 177) ||
            (
                this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 207 &&
                this.rfPhoto.current.naturalHeight === 266
            )
                ? true
                : false;
                
            if(!validitePhoto){
                this.setState({
                    errorFilePhoto: 'Photo d\'identité invalide, bien vouloir charger une photo valide aux bonnes dimenssions'
                })
            }

        console.log("Validation Photo :",validitePhoto)

        let validationInformationComplementaire = (
            this.state.ensCompletionData.paysNaissanceDto && 
            this.state.ensCompletionData.nomPere && 
            this.state.ensCompletionData.nomPere!=="" &&
            this.state.ensCompletionData.nomPere.length>=4 && 
            this.state.ensCompletionData.nomMere &&
            this.state.ensCompletionData.nomMere!=="" &&
            this.state.ensCompletionData.nomMere.length>=4 &&
            this.state.ensCompletionData.diplome && 
            this.state.ensCompletionData.diplome!=="" && 
            this.state.ensCompletionData.diplome.length>=4 && 
            this.state.ensCompletionData.paysProvenanceDiplomeDto && 
            this.state.ensCompletionData.mention && 
            this.state.ensCompletionData.mention!=="" && 
            this.state.ensCompletionData.mention.length>=4 &&
            this.state.ensCompletionData.nationalite && 
            this.state.ensCompletionData.nationalite!=="" && 
            this.state.ensCompletionData.nationalite.length>=4 /*&&
            this.state.ensCompletionData.diplomeProfessionel &&
            this.state.ensCompletionData.diplomeProfessionel!=="" &&
            this.state.ensCompletionData.diplomeProfessionel.length>=4*/
        )

        //console.log(validationInformationComplementaire)

       
        let valideDocument = (
            (
                this.state.etudiant.idTypeStagiaire===1 &&
                this.state.documentDiplome &&
                this.state.documentDiplome!=="" &&
                this.state.documentExtrait &&
                this.state.documentExtrait!==""
            ) ||
            (
                this.state.etudiant.idTypeStagiaire===2 &&
                this.state.documentDiplome &&
                this.state.documentDiplome!=="" &&
                this.state.documentExtrait &&
                this.state.documentExtrait!=="" &&
                this.state.documentCessation &&
                this.state.documentCessation!==""
            )
        )

        if(!this.state.documentDiplome || this.state.documentDiplome===""){
            this.setState({
                errorFileDiplome: 'Charger obligatoirement le diplome'
            })
        }

        if(!this.state.documentExtrait || this.state.documentExtrait===""){
            this.setState({
                errorFileExtrait: 'Charger obligatoirement l\'extrait'
            })
        }

        /*
        if(!this.state.documentReleveBancaire || this.state.documentReleveBancaire===""){
            this.setState({
                errorFileReleveBanque: 'Charger obligatoirement le relevé d\'identité bancaire'
            })
        }
        */
        

        if(this.state.etudiant.idTypeStagiaire===2 && (!this.state.documentCessation || this.state.documentCessation==="")){
            this.setState({
                errorFileExtrait: 'Charger obligatoirement l\'acte de sessation'
            })
        }


        let validiteDateNaissance = (
            this.state.typeDate==="datePrecis" 
            && this.state.informationEtudiant.dateNaissance 
            && this.state.informationEtudiant.dateNaissance!==null
        ) || (
            this.state.typeDate!=="datePrecis" 
            && this.state.anneeNaissance
            && this.state.anneeNaissance!==""
        )

        if(validiteDateNaissance && this.state.typeDate!=="datePrecis"){
            let dateNaissance = "";

            if(this.state.typeDate!=="neVers"){
                dateNaissance = "Né(e) vers "+this.state.anneeNaissance
            }else{
                dateNaissance = "Né(e) en "+this.state.anneeNaissance
            }

            dataInscription['neVers'] = dateNaissance 
            dataInscription['dateNaissance'] = this.state.anneeNaissance+'-12-31'
        }else{
            dataInscription['neVers'] = null
        }

        
        
        if(valideEtudiant && validitePhoto && validationInformationComplementaire && valideDocument && validiteDateNaissance){
            console.log("verifié !")
            this.setState({
                weetResult: true
            })

            detailsDto['paysNaissance']= detailsDto.paysNaissanceDto.libellePays
            detailsDto['paysProvenanceDiplome'] = detailsDto.paysProvenanceDiplomeDto.libellePays

            

            let listeDocument = []

            if(this.state.etudiant.idTypeStagiaire===1){
                listeDocument = [
                    {
                        codeDocument: 'diplome',
                        file: this.state.documentDiplome,
                        fileName: 'Diplôme ayant servi au dépôt du concours',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: "pdf"
                        }
                    }
                    ,
                    {
                        codeDocument: 'extrait',
                        file: this.state.documentExtrait,
                        fileName: 'Extrait de naissance',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: "pdf"
                        }
                    }
                    ,
                    {
                        codeDocument: 'photo',
                        file: this.state.photo,
                        fileName: 'Photo d\'identité',
                        typeDocument: {
                            idDocument: null,
                            //libelleDocument: "png"
                            libelleDocument: this.state.extensionPhoto
                        }
                    }
                ]
            }else{


                listeDocument = [
                    {
                        codeDocument: 'cessation',
                        file: this.state.documentCessation,
                        fileName: 'Copie de la Cessation de service',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: "pdf"
                        }
                    },
                    {
                        codeDocument: 'diplome',
                        file: this.state.documentDiplome,
                        fileName: 'Diplôme ayant servi au dépôt du concours',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: "pdf"
                        }
                    },
                    {
                        codeDocument: 'extrait',
                        file: this.state.documentExtrait,
                        fileName: 'Extrait de naissance',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: "pdf"
                        }
                    }
                    ,
                    {
                        codeDocument: 'photo',
                        file: this.state.photo,
                        fileName: 'Photo d\'identité',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: this.state.extensionPhoto
                        }
                    }
                ]
            }

            

            

            if(this.state.documentReleveBancaire && this.state.documentReleveBancaire!==""){
                listeDocument.push(
                    {
                        codeDocument: 'releveBancaire',
                        file: this.state.documentReleveBancaire,
                        fileName: 'Relevé de compte bancaire',
                        typeDocument: {
                            idDocument: null,
                            libelleDocument: "pdf",
                        }
                    }
                )
            }
            

            dataInscription['documentStagiaireDto'] = listeDocument
            dataInscription["detailStagiaire"] = detailsDto

            console.log(dataInscription)
            

            
            
            this.ModelInscriptionService.completionDataEns(dataInscription, this.state.informationEtudiant.idEtudiant).then(data => {
                console.log(data)
                this.notify.success()
                this.setState({
                    submitted: false,
                    weetResult: false
                })

                this.hideEnsDialog()
                this.getInformationInscription(this.state.idEtudiant)
            }).catch(error => {
                console.log(error)
                this.setState({
                    weetResult: false
                })
                this.notify.echec(error.response.data.message)
            })
            

        }else{
            console.log("non verifié !")
            this.setState({
                weetResult: false
            })
        }
    };


    onInputChange = (event, fieldName) => {
        let informationEtudiant = this.state.informationEtudiant;
        informationEtudiant[`${fieldName}`] = event.target.value;

        this.setState({
            informationEtudiant: informationEtudiant,
        });
    };


    onAnneeNaissanceChange = (event) => {
        this.setState({
            anneeNaissance: event.target.value
        })
    }


    onEnsInputChange = (event, fieldName) => {
        let informationEns = this.state.ensCompletionData;
        informationEns[`${fieldName}`] = event.target.value;

        console.log(informationEns)

        this.setState({
            ensCompletionData: informationEns,
            //ensCompletionData.paysNaissanceDto.libellePays
        });
    };


    onSituationFamilialChange = (changeValue) => {
        if(changeValue==="VEUF" && (this.state.informationEtudiant.sexe.toUpperCase()==="F" || this.state.informationEtudiant.sexe.toUpperCase()==="FEMININ") ){
            changeValue = "VEUVE"
        }

        let informationStagiaire = this.state.ensCompletionData
        informationStagiaire['situationFamiliale'] = changeValue

        this.setState({
            ensCompletionData: informationStagiaire
        })

    }

    onTypeDateSelect = (changeValue) => {
        this.setState({
            typeDate: changeValue
        })
    }

    onPhoneChange = (value, fieldName) => {
        let informationEtudiant = this.state.informationEtudiant;
        informationEtudiant[`${fieldName}`] = value;
        this.setState({
            informationEtudiant: informationEtudiant,
        });
    };



    onMailChange = (event, fieldName) => {
        let informationEtudiant = this.state.informationEtudiant;
        informationEtudiant[`${fieldName}`] = event.target.value;

        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        //console.log(regEmail.test(event.target.value))
        this.setState({
            informationEtudiant: informationEtudiant,
            valideMail: regEmail.test(event.target.value),
        });
    };

    chargerPhoto = (event) => {
        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);
                this.setState({
                    photo: fichier,
                    extensionPhoto: extension,
                    errorFilePhoto: "",
                    errorFile: ""
                });
            };
            reader.readAsBinaryString(file);

            //console.log(this.rfPhoto.current)
        } else {
            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement une photo valide !";
            } else if (file.size > 5457280) {
                errorFile = "La photo est très lourde !";
            }

            this.setState({
                errorFilePhoto: errorFile,
            });
        }
    };




    chargerDocumentDiplome = (event) => {
        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);

                console.log(fichier)
                this.setState({
                    documentDiplome: fichier,
                    extension: extension,
                    errorFileDiplome: "",
                });
            };
            reader.readAsBinaryString(file);

            //console.log(this.rfPhoto.current)
        } else {
            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement un document valide !";
            } else if (file.size > 5457280) {
                errorFile = "Document de grande taille !";
            }

            this.setState({
                errorFileDiplome: errorFile,
            });
        }
    };


    chargerDocumentExtrait = (event) => {
        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);

                console.log(fichier)
                this.setState({
                    documentExtrait: fichier,
                    extension: extension,
                    errorFileExtrait: "",
                });
            };
            reader.readAsBinaryString(file);

            //console.log(this.rfPhoto.current)
        } else {
            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement un document valide !";
            } else if (file.size > 5457280) {
                errorFile = "Document de grande taille !";
            }

            this.setState({
                errorFileExtrait: errorFile,
            });
        }
    };



    chargerDocumentReleveBancaire = (event) => {
        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);

                console.log(fichier)
                this.setState({
                    documentReleveBancaire: fichier,
                    extension: extension,
                    errorFileReleveBanque: "",
                });
            };
            reader.readAsBinaryString(file);

            //console.log(this.rfPhoto.current)
        } else {
            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement un document valide !";
            } else if (file.size > 5457280) {
                errorFile = "Document de grande taille !";
            }

            this.setState({
                errorFileReleveBanque: errorFile,
            });
        }
    };




    chargerDocumentCessation = (event) => {
        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);

                console.log(fichier)
                this.setState({
                    documentCessation: fichier,
                    extension: extension,
                    errorFileCessation: "",
                });
            };
            reader.readAsBinaryString(file);

            //console.log(this.rfPhoto.current)
        } else {
            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement un document valide !";
            } else if (file.size > 5457280) {
                errorFile = "Document de grande taille !";
            }

            this.setState({
                errorFileCessation: errorFile,
            });
        }
    };

    telechargerAttestation = () => {
        
        if(this.state.etudiant.idTypeStagiaire!==null){
            let requestAttestationEns = {
                reportFormat: "PDF",
                reportType: "ATTESTATION_STAGIAIRE",
                idInscriptionPedagogique: this.state.idInscriptionPedagogique,
            };
            console.log(requestAttestationEns)
            
            this.ModelInscriptionService.getAttestationEtudiantEns(requestAttestationEns).then(data => {
                console.log(data)

                let blob = new Blob([data], { type: "application/pdf" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = "Attestation_inscription_ENS";
                    link.download = fileName;
                    link.click();

            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })


        }else{
            let requestAttestation = {
                reportFormat: "PDF",
                reportType: "ATTESTATION_INSCRIPTION",
                idEtudiant: this.state.etudiant.idEtudiant,
            };

            this.ModelInscriptionService.getAttestationInscriptionPedagogique(
                requestAttestation
            )
                .then((response) => {
                    let blob = new Blob([response], { type: "application/pdf" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = "Attestation_inscription_pédagogique";
                    link.download = fileName;
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });

        }
        

        
    };



    telechargerCarteEtudiant = () => {
        
        if(this.state.etudiant.idTypeStagiaire!==null){
            //console.log("ENS")
            const requestCarte = {
                reportFormat: "PDF",
                reportType: "CARTE_STAGIAIRE",
                idEtudiant: this.state.etudiant.idEtudiant,
            };

            //console.log(requestCarte)

            this.ModelInscriptionService.getCarteStagiareEns(
                requestCarte
            )
                .then((response) => {
                    let blob = new Blob([response], { type: "application/pdf" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = "Carte_etudiant";
                    link.download = fileName;
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        }

        else{
            let requestAttestation = {
                reportFormat: "PDF",
                reportType: "CARTE_ETUDIANT",
                idEtudiant: this.state.etudiant.idEtudiant,
            };

            console.log(requestAttestation)

            this.ModelInscriptionService.getAttestationInscriptionPedagogique(
                requestAttestation
            )
                .then((response) => {
                    let blob = new Blob([response], { type: "application/pdf" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = "Carte_etudiant";
                    link.download = fileName;
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        }

    }

    confirmerFranchise = () => {
        this.setState({
            franchisesOk:
                this.conformite.current.checked &&
                this.franchise.current.checked
                    ? true
                    : false,
        });
    };

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="S'inscrire"
                    onClick={() => this.optionInscription(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyECOption1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'EC"
                    onClick={() => this.selectionChoixEC1(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyUEOption1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.selectionUEOptionS1(rowData)}
                />
            </React.Fragment>
        );
    };



    viewECUEOptionnel = (ueChoisi) => {

        this.setState({
            listeECUEOptionnelChoisi: ueChoisi.listeElementConstitutif,
            viewEcOfUEChoiDialog: true
        })
    }

    hideDateilsEcUeDialog = () => {
        this.setState({
            listeECUEOptionnelChoisi: [],
            viewEcOfUEChoiDialog: false
        })
    }

    retirerUeOptionnel1 = (ueChoisi) => {
        let listeUEOptionChoisi = this.state.listeUEChoisi1
        let newListeUE = listeUEOptionChoisi.filter(dataUe => {
            return dataUe.idUniteEnseignement!== ueChoisi.idUniteEnseignement
        })
        this.setState({
            listeUEChoisi1: newListeUE
        })
    }

    retirerUeOptionnel2 = (ueChoisi) => {

        let listeUEOptionChoisi = this.state.listeUEChoisi2
        let newListeUE = listeUEOptionChoisi.filter(dataUe => {
            return dataUe.idUniteEnseignement!== ueChoisi.idUniteEnseignement
        })
        this.setState({
            listeUEChoisi2: newListeUE
        })
    }

    actionBodyUEOptionChoi1 = (ueChoisi) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-eye"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.viewECUEOptionnel(ueChoisi)}
                />

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.retirerUeOptionnel1(ueChoisi)}
                />
            </React.Fragment>
        );
    }


    actionBodyUEOptionChoi2 = (ueChoisi) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-eye"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.viewECUEOptionnel(ueChoisi)}
                />

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.retirerUeOptionnel2(ueChoisi)}
                />
            </React.Fragment>
        );
    }

    actionBodyUEOptionChoisi1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.viewECUEOptionnel(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyUEOption2 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.selectionUEOptionS2(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyECOption2 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'EC"
                    onClick={() => this.selectionChoixEC2(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyAnnulerChoix1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Annuler le choix"
                    onClick={() => this.annulationChoixEC1(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyAnnulerChoix2 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Annuler le choix"
                    onClick={() => this.annulationChoixEC2(rowData)}
                />
            </React.Fragment>
        );
    };

    render() {
        let actionHeader = (
            <Button
                className="enteteDatatable"
                type="button"
                icon="pi pi-cog"
            />
        );

        const inscriptionFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    disabled={this.state.weetResult}
                    onClick={this.hideDialog}
                />
                {this.state.franchisesOk ? (
                    <Button
                        label="Valider"
                        icon={this.state.weetResult?"pi pi-spin pi-spinner":"pi pi-check"}
                        className="p-button-text"
                        disabled={this.state.weetResult}
                        onClick={this.inscriptionPedagogiqueEtudiant}
                    />
                ) : (
                    <Button
                        label="Valider"
                        icon="pi pi-check"
                        className="p-button-text"
                        disabled
                        onClick={this.inscriptionPedagogiqueEtudiant}
                    />
                )}
            </React.Fragment>
        );






        const inscriptionEnsFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideEnsDialog}
                    disabled={this.state.weetResult}
                />


                {this.state.franchisesOk ? (
                    <Button
                        label="Valider"
                        icon={this.state.weetResult?"pi pi-spin pi-spinner":"pi pi-check"}
                        className="p-button-text"
                        disabled={this.state.weetResult}
                        onClick={this.inscriptionEnsEtudiant}
                    />
                ) : (
                    <Button
                        label="Valider"
                        icon="pi pi-check"
                        className="p-button-text"
                        disabled
                        onClick={this.inscriptionEnsEtudiant}
                    />
                )}
            </React.Fragment>
        );


        const detailsEcUeFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDateilsEcUeDialog}
                />
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        }

                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) :  this.state.listeOption.length > 0 ?(
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeOption}
                                selectionMode="single"
                                header="Liste des sessions d'inscription pédagogique concordant avec votre profile"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilter}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.listeOption}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un parcours"
                                />
                                <Column
                                    field="libelleAnneeAcademique"
                                    header="Année académique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une année"
                                />
                                <Column
                                    field="libelleNiveau"
                                    header="Niveau"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher un niveau"
                                />
                                <Column
                                    field="dateOuvertureFormated"
                                    header="Date ouverture"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="date ouverture"
                                />
                                <Column
                                    field="dateFermetureFormated"
                                    header="Date fermeture"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="date fermeture"
                                />
                                <Column
                                    field="etatSession"
                                    header="Etat de la session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un état"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateSession}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        ) : this.state.dejaInscrit ? (
                            <div className="row">
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <h2>
                                            Détails sur l'inscription
                                            pédagogique
                                        </h2>
                                        <table
                                            className="table table-hover table-bordered table-striped"
                                            style={{ padding: "0px" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Université</th>
                                                    <th>UFR</th>
                                                    <th>Parcours</th>
                                                    {/*<th>Niveau</th>*/}
                                                    <th>Année académique</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {this.state.universite}
                                                    </td>
                                                    <td>{this.state.ufr}</td>
                                                    <td>
                                                        {this.state.parcours}
                                                    </td>
                                                    {/*<td>{this.state.niveau}</td>*/}
                                                    <td>
                                                        {
                                                            this.state
                                                                .anneeAcademique
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="p-col-12 p-md-12 p-lg-6">
                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.listeECSemestre1}
                                        selectionMode="single"
                                        header={`Liste des éléments constitutifs du premier semestre ( ${this.state.listeECSemestre1.length} ECs) `}
                                        paginator={true}
                                        rows={5}
                                        globalFilter={this.state.globalFilter}
                                        filterMatchMode="contains"
                                        responsive={true}
                                        selection={this.state.listeSelection}
                                        onSelectionChange={(event) =>
                                            this.setState({
                                                dataTableSelection1:
                                                    event.value,
                                            })
                                        }
                                    >
                                        <Column
                                            field="codeElementConstitutif"
                                            header="Code EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Code EC"
                                        />
                                        <Column
                                            field="libelleElementConstitutif"
                                            header="Libellé EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="libellé EC"
                                        />
                                        <Column
                                            field="creditElementConstitutif"
                                            header="crédit EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Crédit EC"
                                        />
                                    </DataTable>
                                    <br />

                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.listeECSemestre2}
                                        selectionMode="single"
                                        header={`Liste des éléments constitutifs du deuxième semestre ( ${this.state.listeECSemestre2.length} ECs) `}
                                        paginator={true}
                                        rows={5}
                                        globalFilter={this.state.globalFilter}
                                        filterMatchMode="contains"
                                        responsive={true}
                                        selection={this.state.listeSelection}
                                        onSelectionChange={(event) =>
                                            this.setState({
                                                dataTableSelection1:
                                                    event.value,
                                            })
                                        }
                                    >
                                        <Column
                                            field="codeElementConstitutif"
                                            header="Code EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Code EC"
                                        />
                                        <Column
                                            field="libelleElementConstitutif"
                                            header="Libellé EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="libellé EC"
                                        />
                                        <Column
                                            field="creditElementConstitutif"
                                            header="crédit EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Crédit EC"
                                        />
                                    </DataTable>
                                </div>

                                <div
                                    className="p-col-12 p-md-12 p-lg-6"
                                    style={{ paddingTop: "6px" }}
                                >
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Mes informations</h4>
                                        </div>
                                        <div
                                            className="card-body"
                                            style={{ padding: "0px" }}
                                        >
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-12 p-lg-12">
                                                    <table
                                                        className="table table-hover table-bordered table-striped"
                                                        style={{
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">
                                                                    INE
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .ine
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Nom
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .nom
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Prenom
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .prenom
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Date de
                                                                    naissance
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .dateNaiss
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Lieu de
                                                                    naissance
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .lieuNaissance
                                                                    }

                                                                    {console.log(this
                                                                            .state
                                                                            .etudiant)}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">
                                                                    Photo
                                                                    d'identité
                                                                </th>
                                                                <td>
                                                                    {this.state
                                                                        .photo && (
                                                                        <img
                                                                            className="card-img-top"
                                                                            src={`data:image/${this.state.extension};base64, ${this.state.photo}`}
                                                                            alt="Zone de visualisation"
                                                                            ref={
                                                                                this
                                                                                    .rfPhoto
                                                                            }
                                                                            style={{
                                                                                width: "207px",
                                                                                height: "266px",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="p-col-6 p-md-6 p-lg-6">
                                                    <div className="p-field">
                                                        <Button
                                                            label="Télecharger l'attestation d'inscription"
                                                            icon="pi pi-file-pdf"
                                                            className="p-button-info p-mr-2"
                                                            onClick={
                                                                this
                                                                    .telechargerAttestation
                                                            }
                                                        />
                                                    </div>
                                                </div>


                                                <div className="p-col-6 p-md-6 p-lg-6">
                                                    <div className="p-field">
                                                        <Button
                                                            label="Télecharger la carte d'étudiant"
                                                            icon="pi pi-file-pdf"
                                                            className="p-button-info p-mr-2"
                                                            onClick={
                                                                this
                                                                    .telechargerCarteEtudiant
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : this.state.loadingDetailInscription ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-12">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="alert alert-success d-flex align-items-center"
                                role="alert"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                    viewBox="0 0 16 16"
                                    role="img"
                                    aria-label="Warning:"
                                >
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div>
                                    <h3>
                                        Aucune session d'inscription disponible
                                        pour votre profile
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Construction interface */}
                <Dialog
                    visible={this.state.inscriptionDialog}
                    style={{ width: "98%" }}
                    header={this.state.titreFenetre}
                    modal
                    className="p-fluid"
                    footer={inscriptionFooter}
                    onHide={this.hideDialog}
                >
                    <hr />
                    <div className="row">
                        {/* Les EC obligatoires du premier semestre */}
                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        EC obligatoires du premier semestre ({" "}
                                        {this.state.listeECSemestre1.length} EC
                                        )
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            ref={(el) => (this.dt = el)}
                                            value={this.state.listeECSemestre1}
                                            selectionMode="single"
                                            header="EC obligatoires Semestre 1"
                                            paginator={true}
                                            rows={5}
                                            globalFilter={
                                                this.state.globalFilter
                                            }
                                            filterMatchMode="contains"
                                            responsive={true}
                                            selection={
                                                this.state.listeSelection
                                            }
                                            onSelectionChange={(event) =>
                                                this.setState({
                                                    dataTableSelection1:
                                                        event.value,
                                                })
                                            }
                                        >
                                            <Column
                                                field="codeElementConstitutif"
                                                header="Code EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Code EC"
                                            />
                                            <Column
                                                field="libelleElementConstitutif"
                                                header="Libellé EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="libelleEC"
                                            />
                                            <Column
                                                field="creditElementConstitutif"
                                                header="crédit EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Crédit EC"
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Fin EC obligatoires du premier semestre */}

                        {/* Les EC obligatoires du deuxième semestre */}
                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        EC obligatoires du deuxième semestre ({" "}
                                        {this.state.listeECSemestre2.length} EC
                                        )
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            ref={(el) => (this.dt = el)}
                                            value={this.state.listeECSemestre2}
                                            selectionMode="single"
                                            header="EC obligatoires Semestre 2"
                                            paginator={true}
                                            rows={5}
                                            globalFilter={
                                                this.state.globalFilter
                                            }
                                            filterMatchMode="contains"
                                            responsive={true}
                                            selection={
                                                this.state.listeSelection
                                            }
                                            onSelectionChange={(event) =>
                                                this.setState({
                                                    dataTableSelection1:
                                                        event.value,
                                                })
                                            }
                                        >
                                            <Column
                                                field="codeElementConstitutif"
                                                header="Code EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Code EC"
                                            />
                                            <Column
                                                field="libelleElementConstitutif"
                                                header="Libellé EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="libelleEC"
                                            />
                                            <Column
                                                field="creditElementConstitutif"
                                                header="crédit EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Crédit EC"
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Fin EC obligatoires du deuxième semestre */}

                        {/* S'il existe des EC optionnels au choix */}
                        {this.state.existeOption && (
                            <React.Fragment>
                                <hr />
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <h6>Choix des EC Optionnels</h6>
                                    <hr />
                                </div>
                                {this.state.existeOption1 && (
                                    <React.Fragment>
                                        {/* Choix des EC du premier semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC Disponibles
                                                        en option pour le
                                                        premier semestre (
                                                        {
                                                            this.state
                                                                .nombreECChoix1
                                                        }{" "}
                                                        choix à effectuer)
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECOptionnel1
                                                            }
                                                            selectionMode="single"
                                                            header="EC disponibles au choix"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libellé EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="crédit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyECOption1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC choisis pour
                                                        le premier semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECChoisi1
                                                            }
                                                            selectionMode="single"
                                                            header="EC choisis"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libellé EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="crédit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyAnnulerChoix1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state.listeECChoisi1
                                                        .length <
                                                        this.state
                                                            .nombreECChoix1 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            nombre de choix
                                                            indiqué. ({" "}
                                                            {
                                                                this.state
                                                                    .nombreECChoix1
                                                            }{" "}
                                                            )
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin du choix des EC du premier semestre */}
                                    </React.Fragment>
                                )}
                                {this.state.existeOption2 && (
                                    <React.Fragment>
                                        {/* Choix si y'a des options au deuxième semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC disponibles
                                                        en option pour le
                                                        deuxième semestre (
                                                        {
                                                            this.state
                                                                .nombreECChoix2
                                                        }{" "}
                                                        choix à effectuer)
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECOptionnel2
                                                            }
                                                            selectionMode="single"
                                                            header="EC disponibles au choix"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .selectionUe1
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libellé EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="crédit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyECOption2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC choisis pour
                                                        le deuxième semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECChoisi2
                                                            }
                                                            selectionMode="single"
                                                            header="EC choisis"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection2:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libellé EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="crédit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyAnnulerChoix2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state.listeECChoisi2
                                                        .length <
                                                        this.state
                                                            .nombreECChoix2 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            nombre de choix
                                                            indiqué. ({" "}
                                                            {
                                                                this.state
                                                                    .nombreECChoix2
                                                            }{" "}
                                                            )
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin choix si y'a des options au deuxième semestre */}
                                    </React.Fragment>
                                )}
                                )
                            </React.Fragment>
                        )}
                        <hr />

                        {(this.state.existeUEOptionS1 ||
                            this.state.existeUEOptionS2) && (
                            <React.Fragment>
                                <hr />
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <h6>
                                        Choix  UE d'inscription parmis les
                                        options aux choix
                                    </h6>
                                    <hr />
                                </div>
                                {this.state.existeUEOptionS1 && (
                                    <React.Fragment>
                                        {/* Choix des EC du premier semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des UE
                                                        disponibles au choix au
                                                        premier semestre ( {this.state.nombreUEChoix1} UE à choisir)
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeUEOptionS1
                                                            }
                                                            selectionMode="single"
                                                            header="UE Disponible au choix"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeUniteEnseignement"
                                                                header="Code UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code UE"
                                                            />
                                                            <Column
                                                                field="libelleUniteEnseignement"
                                                                header="Libellé UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libellé UE"
                                                            />
                                                            <Column
                                                                field="creditUniteEnseignement"
                                                                header="crédit UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit UE"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyUEOption1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                            <div className="card">
                                                    <div className="card-header">
                                                        UE de votre choix
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeUEChoisi1
                                                            }
                                                            selectionMode="single"
                                                            header="UE choisis"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeUniteEnseignement"
                                                                header="Code UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code UE"
                                                            />
                                                            <Column
                                                                field="libelleUniteEnseignement"
                                                                header="Libellé UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libellé UE"
                                                            />
                                                            <Column
                                                                field="creditUniteEnseignement"
                                                                header="crédit UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit UE"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyUEOptionChoi1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state
                                                        .listeUEChoisi1
                                                        .length !== this.state.nombreUEChoix1 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            choix du nombre d'UE attendu
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin du choix des EC du premier semestre */}
                                    </React.Fragment>
                                )}

                                
                                {this.state.existeUEOptionS2 && (
                                    <React.Fragment>
                                        {/* Choix si y'a des options au deuxième semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des UE
                                                        disponibles au choix au
                                                        deuxième semestre ( {this.state.nombreUEChoix2} UE à choisir)
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeUEOptionS2
                                                            }
                                                            selectionMode="single"
                                                            header="UE disponibles en option"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection2:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeUniteEnseignement"
                                                                header="Code UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code UE"
                                                            />
                                                            <Column
                                                                field="libelleUniteEnseignement"
                                                                header="Libellé UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libellé UE"
                                                            />
                                                            <Column
                                                                field="creditUniteEnseignement"
                                                                header="crédit UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit UE"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyUEOption2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                            <div className="card">
                                                    <div className="card-header">
                                                        UE de votre choix
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeUEChoisi2
                                                            }
                                                            selectionMode="single"
                                                            header="UE Disponible en sellection"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection2:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeUniteEnseignement"
                                                                header="Code UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code UE"
                                                            />
                                                            <Column
                                                                field="libelleUniteEnseignement"
                                                                header="Libellé UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libellé UE"
                                                            />
                                                            <Column
                                                                field="creditUniteEnseignement"
                                                                header="crédit UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit UE"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyUEOptionChoi2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state
                                                        .listeUEChoisi2
                                                        .length !== this.state.nombreUEChoix2 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            choix du nombre d'UE attendu
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin choix si y'a des options au deuxième semestre */}
                                    </React.Fragment>
                                )}
                                )
                            </React.Fragment>
                        )}

                        <hr />

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>
                                Informations d'identité et complétion
                                d'informations
                            </h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Informations d'identité
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nom">
                                                        Nom
                                                    </label>
                                                    <InputText
                                                        id="nom"
                                                        value={
                                                            this.state.etudiant
                                                                .nom
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="prenoms">
                                                        Prénom(s)
                                                    </label>
                                                    <InputText
                                                        id="prenoms"
                                                        value={
                                                            this.state.etudiant
                                                                .prenom
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="ine">
                                                        INE
                                                    </label>
                                                    <InputText
                                                        id="ine"
                                                        value={
                                                            this.state.etudiant
                                                                .ine
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="dateNaissance">
                                                        Date de naissance
                                                    </label>
                                                    <InputText
                                                        id="dateNaissance"
                                                        value={
                                                            this.state.etudiant
                                                                .dateNaissance
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nationalite">
                                                        Nationalite
                                                    </label>
                                                    <InputText
                                                        id="nationalite"
                                                        value={
                                                            this.state.etudiant
                                                                .nomPays
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nationalite">
                                                        Téléphone
                                                    </label>
                                                    <InputText
                                                        id="nationalite"
                                                        value={
                                                            this.state.etudiant
                                                                .telephone
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="lieuNaissance">
                                                        Lieu de naissance
                                                    </label>
                                                    <InputText
                                                        id="lieuNaissance"
                                                        value={
                                                            this.state.etudiant
                                                                .lieuNaissance
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Complétion d'informations
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="telephone">
                                                        <span style={{color:"red"}}>
                                                            *
                                                        </span>Votre numéro de
                                                        téléphone actuel
                                                    </label>
                                                    <PhoneInput
                                                        id="telephone"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .telephone
                                                        }
                                                        defaultCountry="BF"
                                                        limitMaxLength="8"
                                                        onChange={(value) =>
                                                            this.onPhoneChange(
                                                                value,
                                                                "telephone"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .telephone && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit être un
                                                                numéro valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="mailActuel">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span>Votre adresse mail
                                                        actuelle
                                                    </label>
                                                    <InputText
                                                        id="mailActuel"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .email
                                                        }
                                                        onChange={(e) =>
                                                            this.onMailChange(
                                                                e,
                                                                "email"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .email && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire.
                                                            </small>
                                                        )}
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .valideMail && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Le mail est
                                                                invalide,
                                                                veuillez saisir
                                                                un mail valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="personnePrevenir">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Personne à prévenir en
                                                        cas de besoin(Nom
                                                        prénoms)
                                                    </label>
                                                    <InputText
                                                        id="personnePrevenir"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .personnePrevenir
                                                        }
                                                        onChange={(e) =>
                                                            this.onInputChange(
                                                                e,
                                                                "personnePrevenir"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .informationEtudiant
                                                            .personnePrevenir ||
                                                            this.state
                                                                .informationEtudiant
                                                                .personnePrevenir
                                                                .length <
                                                                4) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 4
                                                                caractères.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="contactPersonne">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Numéro de téléphone de
                                                        la personne à prevenir
                                                        en cas de besoin
                                                    </label>
                                                    <PhoneInput
                                                        id="contactPersonne"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .contactPersonnePrevenir
                                                        }
                                                        defaultCountry="BF"
                                                        limitMaxLength="8"
                                                        onChange={(value) =>
                                                            this.onPhoneChange(
                                                                value,
                                                                "contactPersonnePrevenir"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .contactPersonnePrevenir && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit être un
                                                                numéro valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>Photo d'identité</h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Photo d'identité | Tailles acceptées:{" "}
                                        <span
                                            style={{
                                                color: "green",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            (99px*128px); (138px*177px);
                                            (207px*266px)
                                        </span>
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="photo">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Photo d'identité
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="photo"
                                                        name="photo"
                                                        accept="image/*"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerPhoto(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="apercuPhoto">
                                                        Aperçu de la photo
                                                    </label>
                                                    <div className="card">
                                                        {this.state.photo && (
                                                            <img
                                                                className="card-img-top"
                                                                src={`data:image/${this.state.extension};base64, ${this.state.photo}`}
                                                                alt="Visualisation chargement"
                                                                ref={
                                                                    this.rfPhoto
                                                                }
                                                                style={{
                                                                    width: "207px",
                                                                    height: "266px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.photo ||
                                                        this.state
                                                            .errorFile) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFile
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-field">
                                    <h6>Engagement de conformité</h6>
                                    <hr />
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="confirmation"
                                            id="confirmation"
                                            onClick={this.confirmerFranchise}
                                            ref={this.conformite}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="confirmation"
                                        >
                                            J'accepte et je certifie par cette
                                            inscription que mes informations
                                            sont correctes.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value="validation"
                                            id="validation"
                                            onClick={this.confirmerFranchise}
                                            ref={this.franchise}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="validation"
                                        >
                                            Je m'engage à me conformer aux
                                            franchises universitaires de mon
                                            établissement d'accueil.
                                        </label>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>























                {/* Construction interface */}
                <Dialog
                    visible={this.state.inscriptionEnsDialog}
                    style={{ width: "98%" }}
                    header={this.state.titreFenetre}
                    modal
                    className="p-fluid"
                    footer={inscriptionEnsFooter}
                    onHide={this.hideEnsDialog}
                >
                    <hr />
                    <div className="row">

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>
                                Informations d'identité et complétion
                                d'informations
                            </h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Informations d'identité
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nom">
                                                        Nom
                                                    </label>
                                                    <InputText
                                                        id="nom"
                                                        value={
                                                            this.state.etudiant
                                                                .nom
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="prenoms">
                                                        Prénom(s)
                                                    </label>
                                                    <InputText
                                                        id="prenoms"
                                                        value={
                                                            this.state.etudiant
                                                                .prenom
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="ine">
                                                        Matricule
                                                    </label>
                                                    <InputText
                                                        id="ine"
                                                        value={
                                                            this.state.etudiant
                                                                .ine
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="dateNaissance">
                                                        Date de naissance
                                                    </label>
                                                    <InputText
                                                        id="dateNaissance"
                                                        value={
                                                            this.state.etudiant
                                                                .dateNaissance
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nationalite">
                                                        Nationalite
                                                    </label>
                                                    <InputText
                                                        id="nationalite"
                                                        value={
                                                            this.state.etudiant
                                                                .nomPays
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nationalite">
                                                        Téléphone
                                                    </label>
                                                    <InputText
                                                        id="nationalite"
                                                        value={
                                                            this.state.etudiant
                                                                .telephone
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="lieuNaissance">
                                                        Lieu de naissance
                                                    </label>
                                                    <InputText
                                                        id="lieuNaissance"
                                                        value={
                                                            this.state.etudiant
                                                                .lieuNaissance
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Complétion d'informations
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="telephone">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Votre numéro de
                                                        téléphone actuel
                                                    </label>
                                                    <PhoneInput
                                                        id="telephone"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .telephone
                                                        }
                                                        defaultCountry="BF"
                                                        limitMaxLength="8"
                                                        onChange={(value) =>
                                                            this.onPhoneChange(
                                                                value,
                                                                "telephone"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .telephone && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit être un
                                                                numéro valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="mailActuel">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Votre adresse mail
                                                        actuelle
                                                    </label>
                                                    <InputText
                                                        id="mailActuel"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .email
                                                        }
                                                        onChange={(e) =>
                                                            this.onMailChange(
                                                                e,
                                                                "email"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .email && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire.
                                                            </small>
                                                        )}
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .valideMail && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Le mail est
                                                                invalide,
                                                                veuillez saisir
                                                                un mail valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>




                                        <div className="p-grid">
                                            

                                        <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="personnePrevenir">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Personne à prévenir en
                                                        cas de besoin(Nom
                                                        prénoms)
                                                    </label>
                                                    <InputText
                                                        id="personnePrevenir"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .personnePrevenir
                                                        }
                                                        onChange={(e) =>
                                                            this.onInputChange(
                                                                e,
                                                                "personnePrevenir"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .informationEtudiant
                                                            .personnePrevenir ||
                                                            this.state
                                                                .informationEtudiant
                                                                .personnePrevenir
                                                                .length <
                                                                4) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 4
                                                                caractères.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>



                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="contactPersonne">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Numéro de téléphone de
                                                        la personne à prevenir
                                                        en cas de besoin
                                                    </label>
                                                    <PhoneInput
                                                        id="contactPersonne"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .contactPersonnePrevenir
                                                        }
                                                        defaultCountry="BF"
                                                        limitMaxLength="8"
                                                        onChange={(value) =>
                                                            this.onPhoneChange(
                                                                value,
                                                                "contactPersonnePrevenir"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .contactPersonnePrevenir && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit être un
                                                                numéro valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="p-grid">




                                        <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="paysNaissance">
                                                        <span style={{color:"red"}}>
                                                            *
                                                        </span> Votre pays de naissance
                                                    </label>
                                                    <Dropdown
                                                        optionLabel="libellePays"
                                                        optionValue="libellePays"
                                                        options={this.state.listePays} 
                                                        value={
                                                            this.state
                                                                .ensCompletionData
                                                                .paysNaissanceDto
                                                        }
                                                        onChange={(e) =>
                                                            this.onEnsInputChange(
                                                                e,
                                                                "paysNaissanceDto"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .ensCompletionData
                                                            .paysNaissanceDto && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire
                                                            </small>
                                                        )}
                                                </div>   
                                            </div>

                                            
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nomPere">
                                                    <span style={{color:"red"}}>
                                                            *
                                                        </span> Nom du père(Nom
                                                        prénoms)
                                                    </label>
                                                    <InputText
                                                        id="nomPere"
                                                        value={
                                                            this.state
                                                                .ensCompletionData
                                                                .nomPere
                                                        }
                                                        onChange={(e) =>
                                                            this.onEnsInputChange(
                                                                e,
                                                                "nomPere"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .ensCompletionData
                                                            .nomPere ||
                                                            this.state
                                                                .ensCompletionData
                                                                .nomPere
                                                                .length <
                                                                4) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 4
                                                                caractères.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>

                                        </div>
                                        





                                        <div className="p-grid">

                                        <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="professionPere">
                                                        Profession du père
                                                    </label>
                                                    <InputText
                                                        id="professionPere"
                                                        value={
                                                            this.state
                                                                .ensCompletionData
                                                                .professionPere
                                                        }
                                                        onChange={(e) =>
                                                            this.onEnsInputChange(
                                                                e,
                                                                "professionPere"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .ensCompletionData
                                                            .professionPere ||
                                                            this.state
                                                                .ensCompletionData
                                                                .professionPere
                                                                .length <
                                                                4) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 4
                                                                caractères.
                                                            </small>
                                                        )}
                                                </div>
    </div>

    
    <div className="p-col-12 p-md-12 p-lg-6">
        <div className="p-field">
            <label htmlFor="nomMere">
            <span style={{color:"red"}}>
                *
            </span> Nom de la mère(Nom
                prénoms)
            </label>
            <InputText
                id="nomMere"
                value={
                    this.state
                        .ensCompletionData
                        .nomMere
                }
                onChange={(e) =>
                    this.onEnsInputChange(
                        e,
                        "nomMere"
                    )
                }
            />
            {this.state.submitted &&
                (!this.state
                    .ensCompletionData
                    .nomMere ||
                    this.state
                        .ensCompletionData
                        .nomMere
                        .length <
                        4) && (
                    <small
                        className="p-invalid"
                        style={{
                            color: "red",
                        }}
                    >
                        Ce champs est
                        obligatoire et
                        doit compter au
                        moins 4
                        caractères.
                    </small>
                )}
        </div>
    </div>

</div>







<div className="p-grid">

<div className="p-col-12 p-md-12 p-lg-6">
        <div className="p-field">
            <label htmlFor="professionMere">
            <span style={{color:"red"}}>
                *
            </span> Profession de la mère
            </label>
            <InputText
                id="professionMere"
                value={
                    this.state
                        .ensCompletionData
                        .professionMere
                }
                onChange={(e) =>
                    this.onEnsInputChange(
                        e,
                        "professionMere"
                    )
                }
            />
            {this.state.submitted &&
                (!this.state
                    .ensCompletionData
                    .professionMere ||
                    this.state
                        .ensCompletionData
                        .professionMere
                        .length <
                        4) && (
                    <small
                        className="p-invalid"
                        style={{
                            color: "red",
                        }}
                    >
                        Ce champs est
                        obligatoire et
                        doit compter au
                        moins 4
                        caractères.
                    </small>
                )}
        </div>
</div>


                                    <div className="p-col-12 p-md-12 p-lg-6">
                                    <div className="p-field">
                                    <label htmlFor="diplome">
                                    <span style={{color:"red"}}>
                                        *
                                    </span> diplome 
                                    </label>
                                    <InputText
                                    id="nomMere"
                                    value={
                                    this.state
                                    .ensCompletionData
                                    .diplome
                                    }
                                    onChange={(e) =>
                                    this.onEnsInputChange(
                                    e,
                                    "diplome"
                                    )
                                    }
                                    />
                                    {this.state.submitted &&
                                    (!this.state
                                    .ensCompletionData
                                    .diplome ||
                                    this.state
                                    .ensCompletionData
                                    .diplome
                                    .length <
                                    4) && (
                                    <small
                                    className="p-invalid"
                                    style={{
                                    color: "red",
                                    }}
                                    >
                                    Ce champs est
                                    obligatoire et
                                    doit compter au
                                    moins 4
                                    caractères.
                                    </small>
                                    )}
                                    </div>
                                    </div>

                                    </div>








                                        <div className="p-grid">

                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="paysProvenanceDiplome">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Pays du diplome
                                                    </label>
                                                    <Dropdown
                                                        optionLabel="libellePays"
                                                        optionValue="libellePays"
                                                        options={this.state.listePays} 
                                                        value={
                                                            this.state
                                                                .ensCompletionData
                                                                .paysProvenanceDiplomeDto
                                                        }
                                                        onChange={(e) =>
                                                            this.onEnsInputChange(
                                                                e,
                                                                "paysProvenanceDiplomeDto"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .ensCompletionData
                                                            .paysProvenanceDiplomeDto && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire
                                                            </small>
                                                        )}
                                                </div>
                                            </div>


                                <div className="p-col-12 p-md-12 p-lg-6">
                                    <div className="p-field">
                                        <label htmlFor="mention">
                                        <span style={{color:"red"}}>
                                            *
                                        </span> Mention 
                                        </label>
                                        <InputText
                                            id="mention"
                                            value={
                                            this.state
                                            .ensCompletionData
                                            .mention
                                            }
                                            onChange={(e) =>
                                            this.onEnsInputChange(
                                            e,
                                            "mention"
                                            )
                                            }
                                        />
                                        {this.state.submitted &&
                                        (!this.state
                                        .ensCompletionData
                                        .mention ||
                                        this.state
                                        .ensCompletionData
                                        .mention
                                        .length <
                                        4) && (
                                        <small
                                        className="p-invalid"
                                        style={{
                                        color: "red",
                                        }}
                                        >
                                        Ce champs est
                                        obligatoire et
                                        doit compter au
                                        moins 4
                                        caractères.
                                        </small>
                                        )}
                                    </div>
                                    </div>

                                </div>




                                <div className="p-grid">

                                    <div className="p-col-12 p-md-12 p-lg-6">
                                    <div className="p-field">
                                            <label htmlFor="nationalite">
                                            <span style={{color:"red"}}>
                                                *
                                            </span> Nationalité
                                            </label>
                                            <InputText
                                            id="nationalite"
                                            value={this.state.ensCompletionData.nationalite}
                                            onChange={(e) => this.onEnsInputChange(e, "nationalite")}
                                            />
                                            {this.state.submitted &&
                                                        (!this.state
                                                            .ensCompletionData
                                                            .nationalite ||
                                                            this.state
                                                                .ensCompletionData
                                                                .nationalite
                                                                .length <
                                                                4) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 4
                                                                caractères.
                                                            </small>
                                                        )}
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-12 p-lg-6">
                                        <div className="p-field">
                                            <label htmlFor="anciennete">
                                                anciennete (uniquement pour les Professionnls)
                                            </label>
                                            <InputText
                                            id="anciennete"
                                            value={this.state.ensCompletionData.anciennete}
                                            onChange={(e) => this.onEnsInputChange(e, "anciennete")}
                                            type="number" step={1} min={0}
                                            />
                                        </div>
                                    </div>

                                </div>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="titreUniversitaire">
                                                        Titre universitaire
                                                    </label>
                                                    <InputText
                                                        id="titreUniversitaire"
                                                        value={
                                                            this.state
                                                                .ensCompletionData
                                                                .titreUniversitaire
                                                        }
                                                        onChange={(e) =>
                                                            this.onEnsInputChange(
                                                                e,
                                                                "titreUniversitaire"
                                                            )
                                                        }
                                                    />
                                                    
                                                </div>
                                        </div>


                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-field">
                                                <label htmlFor="diplomeProfessionel">
                                                {/*<span style={{color:"red"}}>
                                                    *
                                                </span>*/} diplome professionnel
                                                </label>
                                                <InputText  id="diplomeProfessionel"
                                                    value={this.state.ensCompletionData.diplomeProfessionel}
                                                    onChange={(e) => this.onEnsInputChange(e,"diplomeProfessionel")}
                                                />
                                                {
                                                    this.state.submitted 
                                                    && (!this.state.ensCompletionData.diplomeProfessionel 
                                                    ||
                                                    this.state.ensCompletionData.diplomeProfessionel.length <4) 
                                                    && 
                                                    (
                                                        <small className="p-invalid" style={{color: "red",}}
                                            >
                                            Ce champs est
                                            obligatoire et
                                            doit compter au
                                            moins 4
                                            caractères.
                                            </small>
                                            )}
                                            </div>
                                            </div>

                                        </div>



                                        <div className="p-grid">

                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="numeroCompteBancaire">
                                                        {/*<span style={{color:"red"}}>
                                                            *
                                                        </span>*/} Numéro compte bancaire
                                                    </label>
                                                    <InputText
                                                        id="numeroCompteBancaire"
                                                        value={
                                                            this.state
                                                                .ensCompletionData
                                                                .numeroCompteBancaire
                                                        }
                                                        onChange={(e) =>
                                                            this.onEnsInputChange(
                                                                e,
                                                                "numeroCompteBancaire"
                                                            )
                                                        }
                                                    />

                                                    {/*
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .ensCompletionData
                                                            .numeroCompteBancaire ||
                                                            this.state
                                                                .ensCompletionData
                                                                .numeroCompteBancaire
                                                                .length <
                                                                8) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 8
                                                                caractères.
                                                            </small>
                                                        )}
                                                        */}
                                                </div>
                                            </div>

                                        </div>






                                        <div className="p-grid">

                                            <div className="p-col-12 p-md-12 p-lg-12">


                                                <div className="p-field">
                                                    <hr/>
                                                    <label htmlFor="situationFamiliale">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Situation familiale
                                                    </label>
                                                    <hr/>

                                                    <div className="flex flex-wrap gap-3">
                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="celibataire" name="situationFamiliale" value="CELIBATAIRE" onChange={(e) => this.onSituationFamilialChange(e.value)} checked={this.state.ensCompletionData.situationFamiliale === 'CELIBATAIRE'} />
                                                            <label htmlFor="celibataire" className="ml-2">CELIBATAIRE</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="marie" name="situationFamiliale" value="MARIE" onChange={(e) => this.onSituationFamilialChange(e.value)} checked={this.state.ensCompletionData.situationFamiliale === 'MARIE'} />
                                                            <label htmlFor="marie" className="ml-2">MARIE</label>
                                                        </div>

                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="divorce" name="situationFamiliale" value="DIVORCE" onChange={(e) => this.onSituationFamilialChange(e.value)} checked={this.state.ensCompletionData.situationFamiliale === 'DIVORCE'} />
                                                            <label htmlFor="divorce" className="ml-2">DIVORCE</label>
                                                        </div>

                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="veuf" name="situationFamiliale" value="VEUF" onChange={(e) => this.onSituationFamilialChange(e.value)} checked={this.state.ensCompletionData.situationFamiliale === 'VEUF' || this.state.ensCompletionData.situationFamiliale === 'VEUVE'} />
                                                            <label htmlFor="veuf" className="ml-2">VEUF/VEUVE</label>
                                                        </div>

                                                        {this.state.submitted &&
                                                        (!this.state
                                                            .ensCompletionData
                                                            .situationFamiliale || this.state
                                                            .ensCompletionData
                                                            .situationFamiliale==="") && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire.
                                                            </small>
                                                        )}

                                                        </div>
                                                    </div>
                                            </div>
                                        </div>      




                                        <div className="p-grid">

                                            <div className="p-col-12 p-md-12 p-lg-12">


                                                <div className="p-field">
                                                    <hr/>
                                                    <label htmlFor="formatDateNaissance">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Selectionnez le format de votre date de naissance
                                                    </label>
                                                    <hr/>

                                                    <div className="flex flex-wrap gap-3">
                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="neVers" name="formatDate" value="neVers" onChange={(e) => this.onTypeDateSelect(e.value)} checked={this.state.typeDate === 'neVers'} />
                                                            <label htmlFor="neVers" className="ml-2">Né(e) vers</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="neEn" name="formatDate" value="neEn" onChange={(e) => this.onTypeDateSelect(e.value)} checked={this.state.typeDate === 'neEn'} />
                                                            <label htmlFor="neEn" className="ml-2">Né(e) en</label>
                                                        </div>

                                                        <div className="flex align-items-center">
                                                            <RadioButton inputId="datePrecis" name="formatDate" value="datePrecis" onChange={(e) => this.onTypeDateSelect(e.value)} checked={this.state.typeDate === 'datePrecis'} />
                                                            <label htmlFor="datePrecis" className="ml-2">Date précise</label>
                                                        </div>

                                                        </div>



                                                        {this.state.submitted &&
                                                        (!this.state.typeDate || this.state
                                                            .typeDate===null) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire.
                                                            </small>
                                                        )}


                                                    </div>
                                            </div>
                                        </div>




                                        <div className="p-grid">

                                        
                                            <div className="p-col-12 p-md-12 p-lg-6">

                                            {(this.state.typeDate === "datePrecis" ) && ( 
                                                <div className="p-field">
                                                    <label htmlFor="dateNaissance">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Date de naissance
                                                    </label>
                                                    <InputText
                                                        id="dateNaissance"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .dateNaissance
                                                        }
                                                        onChange={(e) =>
                                                            this.onInputChange(
                                                                e,
                                                                "dateNaissance"
                                                            )
                                                        }
                                                        type="date"
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .informationEtudiant
                                                            .dateNaissance) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire.
                                                            </small>
                                                        )}
                                                </div>
                                                )}




                                                {(this.state.typeDate === "neVers" || this.state.typeDate === "neEn") && ( 
                                                <div className="p-field">
                                                    <label htmlFor="anneeNaissance">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Année de naissance
                                                    </label>
                                                    <InputText
                                                        id="anneeNaissance"
                                                        value={
                                                            this.state
                                                                .anneeNaissance
                                                        }
                                                        onChange={(e) =>
                                                            this.onAnneeNaissanceChange(e)
                                                        }
                                                        type="number"
                                                        step={1} min={1900}
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .anneeNaissance ) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et doit etre superieur à 1900.
                                                            </small>
                                                        )}
                                                </div>
                                                )}
                                            </div>


                                        </div>





                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>Photo d'identité et autres documents utiles</h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Photo d'identité | Tailles acceptées:{" "}
                                        <span
                                            style={{
                                                color: "green",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            (99px*128px); (138px*177px);
                                            (207px*266px)
                                        </span>
                                    </div>


                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="photo">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Photo d'identité
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="photo"
                                                        name="photo"
                                                        accept="image/*"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerPhoto(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="apercuPhoto">
                                                        Aperçu de la photo
                                                    </label>
                                                    <div className="card">
                                                        {this.state.photo && (
                                                            <img
                                                                className="card-img-top"
                                                                src={`data:image/${this.state.extension};base64, ${this.state.photo}`}
                                                                alt="Cadre visualisation"
                                                                ref={
                                                                    this.rfPhoto
                                                                }
                                                                style={{
                                                                    width: "207px",
                                                                    height: "266px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.photo ||
                                                        this.state
                                                            .errorFilePhoto) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFilePhoto
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Copie légalisée du diplôme ayant servi lors du dépôt de candidature 
                                    </div>

                                    
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="photo">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Copie du diplome
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="diplome"
                                                        name="diplome"
                                                        accept=".pdf"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerDocumentDiplome(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                           
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.documentDiplome ||
                                                        this.state
                                                            .errorFileDiplome) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFileDiplome
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Copie de l'extrait d’acte de naissance 
                                    </div>

                                    
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="extrait">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Copie de l'extrait d'acte de naissance
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="diplome"
                                                        name="diplome"
                                                        accept=".pdf"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerDocumentExtrait(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                           
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.documentExtrait ||
                                                        this.state
                                                            .errorFileExtrait) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFileExtrait
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Copie du relevé d'identité bancaire 
                                    </div>

                                    
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="extrait">
                                                    Copie du relevé d'identité bancaire
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="diplome"
                                                        name="diplome"
                                                        accept=".pdf"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerDocumentReleveBancaire(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                           
                                           {/*
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.documentReleveBancaire ||
                                                        this.state
                                                            .errorFileReleveBanque) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFileReleveBanque
                                                            }
                                                        </small>
                                                    )}
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        
                           {(this.state.etudiant.idTypeStagiaire===2) && (
                            <>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Copie légalisée du certificat de cessation de service 
                                    </div>

                                    
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="photo">
                                                    <span style={{color:"red"}}>
                                                        *
                                                    </span> Copie légalisée du certificat de cessation de service 
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="diplome"
                                                        name="diplome"
                                                        accept=".pdf"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerDocumentCessation(e)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    ((this.state.submitted && !this.state.documentCessation) ||
                                                        this.state
                                                            .errorFileCessation) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFileCessation
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                            </>



                           )}
                        

                        


                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-field">
                                    <h6>Engagement de conformité</h6>
                                    <hr />
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="confirmation"
                                            id="confirmation"
                                            onClick={this.confirmerFranchise}
                                            ref={this.conformite}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="confirmation"
                                        >
                                            J'accepte et je certifie par cette
                                            inscription que mes informations
                                            sont correctes.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value="validation"
                                            id="validation"
                                            onClick={this.confirmerFranchise}
                                            ref={this.franchise}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="validation"
                                        >
                                            Je m'engage à me conformer aux
                                            franchises universitaires de mon
                                            établissement d'accueil.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>



                <Dialog
                    visible={this.state.viewEcOfUEChoiDialog}
                    style={{ width: "75%" }}
                    modal
                    className="p-fluid"
                    footer={detailsEcUeFooter}
                    onHide={this.hideDateilsEcUeDialog}
                >
                    <div className="p-col-12 p-md-12 p-lg-12">
                        <div className="card">
                            <div className="card-header">
                                Aperçu EC
                            </div>
                            <div className="card-body">
                                <DataTable
                                    ref={(el) =>
                                        (this.dt = el)
                                    }
                                    value={
                                        this.state
                                            .listeECUEOptionnelChoisi
                                    }
                                    selectionMode="single"
                                    header="Liste des EC de l'UE"
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state
                                            .globalFilter
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={
                                        this.state
                                            .listeSelection
                                    }
                                    onSelectionChange={(
                                        event
                                    ) =>
                                        this.setState({
                                            dataTableSelection2:
                                                event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="codeElementConstitutif"
                                        header="Code EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={false}
                                        filterPlaceholder="Code EC"
                                    />
                                    <Column
                                        field="libelleElementConstitutif"
                                        header="Libellé EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={false}
                                        filterPlaceholder="libellé EC"
                                    />
                                    <Column
                                        field="creditElementConstitutif"
                                        header="crédit EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={false}
                                        filterPlaceholder="Crédit EC"
                                    />

                                    <Column
                                        field="nbHeureCours"
                                        header="NB Heures CM"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={false}
                                        filterPlaceholder="NB Heures CM"
                                    />


                                    <Column
                                        field="nbHeureTD"
                                        header="NB Heures TD"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={false}
                                        filterPlaceholder="NB Heures TD"
                                    />

                                    <Column
                                        field="nbHeureTP"
                                        header="NB Heures TP"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={false}
                                        filterPlaceholder="NB Heures TP"
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                </Dialog>



            </div>
        );
    }
}

export default InscriptionPedagogique;
