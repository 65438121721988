import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { TypeSessionService } from '../../service/TypeSessionService'

export class TypeSession extends Component {
    emptyTypeSession = {
        idTypeSession: null,
        libelleTypeSession: "",
        
    }

    constructor() {
        super();
        this.state = {
            listeTypeSession : [],
            typeSessionDialog: false,
            deleteTypeSessionDialog: false,
            typeSession: this.emptyTypeSession,
            submitted: false,
            loading : false,
            globalFilter: null
        }
        this.typeSessionService = new TypeSessionService()
        
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveTypeSession = this.saveTypeSession.bind(this);
        this.editTypeSession = this.editTypeSession.bind(this);
        this.confirmDeleteTypeSession = this.confirmDeleteTypeSession.bind(this);
        this.deleteTypeSession = this.deleteTypeSession.bind(this);
        this.onLibelleTypeSessionChange = this.onLibelleTypeSessionChange.bind(this);
        
        this.hideDeleteTypeSessionDialog = this.hideDeleteTypeSessionDialog.bind(this);

    }


    componentDidMount() {
        this.setState({loading : true})
        this.typeSessionService.getAll().then(data => 
            this.setState({listeTypeSession: data, loading : false})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
       
    }
    openNew() {
        this.setState({
            typeSession: this.emptyTypeSession,
            submitted: false,
            typeSessionDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            typeSessionDialog: false
        });
    }
    hideDeleteTypeSessionDialog() {
        this.setState({ deleteTypeSessionDialog: false });
    }
    saveTypeSession() {
        let state = { submitted: true };

        if (this.state.typeSession.libelleTypeSession.trim() ) {

            const newTypeSession = {
                idTypeSession: parseInt(this.state.typeSession.idTypeSession) ,
                libelleTypeSession : this.state.typeSession.libelleTypeSession,
                
            }
            console.log(newTypeSession)
            if (this.state.typeSession.idTypeSession) {
                this.typeSessionService.update(parseInt(this.state.typeSession.idTypeSession), newTypeSession)
                                .then(data => {
                                    this.notify.success()
                
                                    this.setState({loading : true})
                                    this.typeSessionService.getAll().then(data => 
                                        this.setState({listeTypeSession: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)  
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.typeSessionService.create(newTypeSession).then(data => {
                    this.notify.success()
                    this.setState({loading : true})
                    this.typeSessionService.getAll().then(data => 
                        this.setState({listeTypeSession: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                typeSessionDialog: false,
                typeSession: this.emptyTypeSession
            };
        }

        this.setState(state);
    }
    editTypeSession(typeSession) {
        this.setState({
            typeSession,
            typeSessionDialog: true
        });
    }
    confirmDeleteTypeSession(typeSession) {
        this.setState({
            typeSession,
            deleteTypeSessionDialog: true
        });
    }
    deleteTypeSession(idTypeSession) {
        console.log(idTypeSession)
        this.typeSessionService.delete(parseInt(idTypeSession)).then(data => {
            this.notify.success()
            this.setState({
                deleteTypeSessionDialog: false, 
                typeSession: this.emptyTypeSession,
            });
            this.setState({loading : true})
            this.typeSessionService.getAll().then(data => 
                this.setState({listeTypeSession: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)  
                    console.log(error)
                    this.setState({loading : false})
                })
        })
    }
    onLibelleTypeSessionChange(e, libelleTypeSession) {
        const val = (e.target && e.target.value) || '';
        let typeSession = this.state.typeSession;
        typeSession[`${libelleTypeSession}`] = val;

        this.setState({typeSession });
    }
   


    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeSession(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeSession(rowData)} />
            </React.Fragment>
        );
    }
    render() {
       
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const typeSessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeSession} />
            </React.Fragment>
        );
        const deleteTypeSessionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteTypeSessionDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeSession(this.state.typeSession.idTypeSession) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeTypeSession} selectionMode="single" header="Liste de type de session" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeSession" header="Type session"  filterMatchMode="contains" sortable={true} filter={true} />
                            
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.typeSessionDialog} style={{ width: '450px' }} header="Informations type session" modal className="p-fluid" footer={typeSessionDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleTypeSession">Libelle type de session *</label>
                        <InputText id="libelleTypeSession" value={this.state.typeSession.libelleTypeSession}  onChange={(e) => this.onLibelleTypeSessionChange(e, 'libelleTypeSession')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle type de session'/>
                        {this.state.submitted && !this.state.typeSession.libelleTypeSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    
                </Dialog>
                <Dialog visible={this.state.deleteTypeSessionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeSessionDialogFooter} onHide={this.hideDeleteTypeSessionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.typeSession && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default TypeSession
