import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class AnneeService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/annees-academiques" 
       return axios.get(url).then(res => res.data) 
   }

   getAnneeAcademiqueForEtudiant(ine) {
       
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant-annees-academiques?ine="+ine
    return axios.get(url).then(res => res.data) 
    }

}