import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InputText } from 'primereact/inputtext';

import { SignataireService } from '../../service/SignataireService';
import { EnseignantService} from '../../service/EnseignantService'

export class Signataire extends Component {



    constructor() {
        super();
        this.state = {
            listeEnseignant : [],
            signataire: [],
            curentSignataire: {
                idSignataire: '',
                idEtablissement: '',
                autoriteSignataire: '',
                titreSignataire: '',
                enseignant: {}
            },
            viewCreateSignataire: false,
            submitted: false,
            idEtablissement: null,
            viewSelectEnseignantDialog: false
        }

        this.signataireService = new SignataireService();
        this.enseignantService = new EnseignantService()


        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }

    componentDidMount() {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({
            loading : true,
            idEtablissement: parseInt(token.code_etablissement)
        })

        this.signataireService.getAll(parseInt(token.code_etablissement)).then(data => {

            console.log(data)
            if(data){
                this.setState({
                    curentSignataire: data?data:{
                        idSignataire: '',
                        idEtablissement: '',
                        autoriteSignataire: '',
                        titreSignataire: '',
                        enseignant: {}
                    }
                })
            }
            
            this.setState({signataire: data?[data]:[], loading : false})
        })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })


        this.enseignantService.getAll().then(data => {
            this.setState({
                listeEnseignant: data
            })
        }).catch(error => {
            console.log(error)
        })
    }
    
    openNew = () => {
        this.setState({
            submitted: false,
            viewCreateSignataire: true
        });
    }

    hideDialog = () => {
        this.setState({
            submitted: false,
            viewCreateSignataire: false
        });
    }


    onSignataireEdite = (event, fieldName) => {

        const val = (event.target && event.target.value) || '';
        let curentSignataire = this.state.curentSignataire
        curentSignataire[`${fieldName}`] = val

        this.setState({
            curentSignataire
        })

        
    }



    saveSignataire = () => {

        this.setState({submitted: true})
        let curentSignataire = this.state.curentSignataire

        //On s'assure que toutes les informtaions obligatoires ont ete renseignes!
        if(curentSignataire.autoriteSignataire && curentSignataire.enseignant && curentSignataire.enseignant.idEnseignant ){

            curentSignataire['idEtablissement'] = this.state.idEtablissement
            if(curentSignataire.idSignataire){
                //Il s'agit d'une edition
                this.signataireService.update(curentSignataire.idSignataire, curentSignataire).then(data => {

                    this.setState({
                        loading: true,
                        submitted: false,
                        viewCreateSignataire: false
                    })
                    this.notify.success()

                    this.signataireService.getAll(parseInt(this.state.idEtablissement)).then(data => {


                        if(data){
                            this.setState({
                                curentSignataire: data
                            })
                        }
                        this.setState({signataire: data?[data]:[], loading : false})

                    }).catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                    })

                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })


            }else{
                //Il s'agit d'un premier enregistrement

                this.signataireService.create(curentSignataire).then(data => {

                    this.setState({
                        loading: true,
                        submitted: false,
                        viewCreateSignataire: false
                    })
                    this.notify.success()

                    this.signataireService.getAll(parseInt(this.state.idEtablissement)).then(data => {

                        console.log(data)
                        if(data){
                            this.setState({
                                curentSignataire: data
                            })
                        }
                        this.setState({signataire: data?[data]:[], loading : false})

                    }).catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                    })

                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })

            }
        }
        
    }


    selectionSignataireEnseignant = (enseignant) => {
        let curentSignataire = this.state.curentSignataire
        curentSignataire['enseignant'] = enseignant

        this.setState({
            curentSignataire: curentSignataire,
            viewSelectEnseignantDialog: false
        })
     }


    
    hideSelectSignataireDialog = () => {
        this.setState({
            viewSelectEnseignantDialog: false
        })
    }


    actionSelectSignataireBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={(e) => this.selectionSignataireEnseignant(rowData)} title="Sélectionner l'enseignant"/>
            </React.Fragment>
        );
    }

    
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editNiveau(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteNiveau(rowData)} />
            </React.Fragment>
        );
    }
    
    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const signataireSelectFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSignataire} />
            </React.Fragment>
        );

        const selectSignataireDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSignataireDialog} />
            </React.Fragment>
        )


        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20, marginTop: 5}}>
                        <div className="p-col-2">
                            <Button label="Ajouter / Changer signataire" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />

                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.signataire} selectionMode="single" header="Signataire de l'établissement" paginator={true} rows={10}responsive={true}>
                            <Column field="enseignant.matriculeEnseignant" header="Matricule du signataire" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher matricule"/>
                            <Column field="enseignant.nomEnseignant" header="Nom signataire" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par nom"/>
                            <Column field="enseignant.prenomEnseignant" header="Prénom signateur" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par prénom"/>
                            <Column field="enseignant.grade.libelleGrade" header="Grade de l'enseignant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par grade"/>
                            <Column field="titreSignataire" header="Distinction" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="Dictinction signataire"/>
                            <Column field="autoriteSignataire" header="Fonction" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="Fonction signataire"/>
                        </DataTable>
                        }
                    </div>
                </div>




                <Dialog visible={this.state.viewCreateSignataire} style={{ width: '75%' }} header="Sélection du signataire" modal className="p-fluid" footer={signataireSelectFooter} onHide={this.hideDialog}>
                    

                    <div className="p-grid p-lg-12">
                        
                        <div className="p-field p-col-6">
                            <div className="p-field">
                                <label htmlFor="titreSignataire">Distinction honorifique</label>
                                <InputText id="titreSignataire" value={this.state.curentSignataire.titreSignataire}  onChange={(e) => this.onSignataireEdite(e, 'titreSignataire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.curentSignataire.titreSignataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>

                        <div className="p-field p-col-6">
                            <div className="p-field">
                                <label htmlFor="autoriteSignataire">Fonction du signataire</label>
                                <InputText id="autoriteSignataire" value={this.state.curentSignataire.autoriteSignataire}  onChange={(e) => this.onSignataireEdite(e, 'autoriteSignataire')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.curentSignataire.autoriteSignataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>

                    

                    <div className='p-col-12 p-md-12 p-lg-12'>
                        <div className="p-field">

                            <div className="p-field p-col-12">
                                <hr/>
                                <label htmlFor="nomPrenom">Enseignant {console.log(this.state.curentSignataire.enseignant)}</label>
                            </div>

                            <div className="p-grid p-lg-12">
                                <div className="p-field p-col-11">
                                    <Dropdown optionLabel="nomPrenom" optionValue={this.state.curentSignataire.enseignant} value={this.state.curentSignataire.enseignant?this.state.curentSignataire.enseignant:""} options={this.state.listeEnseignant} onChange={(e) => {this.onSignataireEdite(e, 'enseignant')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Sélectionner un enseignant"/>
                                    {this.state.submitted && !this.state.curentSignataire.enseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                <div className="p-field p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={() => { this.setState({viewSelectEnseignantDialog: true})}} />
                                </div>
                            </div>



                        </div>
                    </div>

                </Dialog>



                <Dialog visible={this.state.viewSelectEnseignantDialog} style={{ width: '75%', height: 'auto' }} header="Sélection le signataire" modal className="p-fluid" footer={selectSignataireDialogFooter} onHide={this.hideSelectSignataireDialog}>
                    <DataTable ref={(el) => this.dt = el} value={this.state.listeEnseignant} header="Liste des enseignants" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="nomPrenom" header="Enseignant" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher un enseignant"/>
                        <Column field="grade.libelleGrade" header="Grade enseignant" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher un grade"/>
                        <Column header={actionHeader} body={this.actionSelectSignataireBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                </Dialog>

            </div> 
        )
    }
}

export default Signataire
