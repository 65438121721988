import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class NotationService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getAll() {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation"
        return axios.get(url).then(res => res.data) 
    }

    getHistoriqueNotation(idNotation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/reclamation?idNotation="
        return axios.get(url+idNotation).then(res => res.data) 
    }
    
    create(newNotations) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation"
        return axios.post(url,newNotations)
                    .then(res => res.data);
    }

    validationNotations(newNotations) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/valider-notes"
        return axios.post(url,newNotations)
                    .then(res => res.data);
    }
    chargementFichier(donneeFichier, idExamen, idDepartement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation/uploads/"
        return axios.put(url+idExamen+"/"+idDepartement,donneeFichier)
                    .then(res => res.data);
    }

    update(idNotation ,newNotation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation/"
        return axios.put(url+idNotation, newNotation)
                    .then(res => res.data);
    }

    delete(idNotation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation/"
        return axios.patch(url+idNotation)
                    .then(res => res.data)
    }

    getListNotation(idExamen){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation?idExamen="+idExamen
        return axios.get(url).then(res => res.data)
    }

    verifierValiditerNotations(idExamen){

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/verification-validation-notes?idExamen="+idExamen
        return axios.get(url).then(res => res.data);
    }

    getFichierNotation(idExamen){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/notation/download/"
        return axios.get(url+idExamen).then(res => res.data)
    }

    calculerMoyenneUniteEnseignement(idSession){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/moyenne-deliberation?idSession="+idSession
        return axios.post(url).then(res => res.data)
    }

    sendNotificationNotation(listeNotification){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/validation-mail"
        return axios.post(url,listeNotification)
                    .then(res => res.data);
    }

    getElementConstitutifs(idEtudiant,idAnneeAcademique,idSemestre){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/liste-inscriptions-etudiant-semestre?idEtudiant="+idEtudiant+"&idAnneeAcademique="+idAnneeAcademique+"&idSemestre="+idSemestre
        return axios.get(url)
                    .then(res => res.data);
    }


    // getElementConstitutifs(ineEtudiant, idElementConstitutif, idSemestre, idAnneeAcademique){
    //     const url = this.adresse.getAdresseTest() +"/service/api/campusfaso-lmd/campus-faso/notes-etudiant-ec"
    // }
    
}