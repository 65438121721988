import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { TypeActiviteService } from '../../service/TypeActiviteService';

export class TypeActivite extends Component {
    emptyTypeActivite = {
        idTypeActivite: null,
        libelleTypeActivite: "" 
    }
    constructor() {
        super();
        this.state = {
            listeTypeActivite : [],
            typeActiviteDialog: false,
            deleteTypeActiviteDialog: false,
            typeActivite: this.emptyTypeActivite,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }
        this.typeActiviteService = new TypeActiviteService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveTypeActivite = this.saveTypeActivite.bind(this);
        this.editTypeActivite = this.editTypeActivite.bind(this);
        this.confirmDeleteTypeActivite = this.confirmDeleteTypeActivite.bind(this);
        this.deleteTypeActivite = this.deleteTypeActivite.bind(this);
        this.onLibelleTypeActiviteChange = this.onLibelleTypeActiviteChange.bind(this);
        this.hideDeleteTypeActiviteDialog = this.hideDeleteTypeActiviteDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.typeActiviteService.getAll().then(data => 
            this.setState({listeTypeActivite: data, loading : false})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
    }
    openNew() {
        this.setState({
            typeActivite: this.emptyTypeActivite,
            submitted: false,
            typeActiviteDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            typeActiviteDialog: false
        });
    }
    hideDeleteTypeActiviteDialog() {
        this.setState({ deleteTypeActiviteDialog: false });
    }
    saveTypeActivite() {
        let state = { submitted: true };

        if (this.state.typeActivite.libelleTypeActivite.trim()) {

            const newTypeActivite = {
                idTypeActivite : parseInt(this.state.typeActivite.idTypeActivite) ,
                libelleTypeActivite : this.state.typeActivite.libelleTypeActivite
            }
            //console.log(newTypeActivite)
            if (this.state.typeActivite.idTypeActivite) {
                this.typeActiviteService.update(parseInt(this.state.typeActivite.idTypeActivite), newTypeActivite)
                                .then(data => {
                                    this.notify.success()
                                    //console.log(data)
                                    this.setState({redirect : true})

                                    this.setState({loading : true})
                                    this.typeActiviteService.getAll().then(data => {
                                    this.setState({
                                        listeTypeActivite: data,
                                        loading : false
                                    })
                                }
                            )
                            .catch(error => {
                                console.log(error)
                                this.setState({loading : false})
                            })
                        })
                        .catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error);
                        })
            }
            else {
                this.typeActiviteService.create(newTypeActivite).then(data => {
                    this.notify.success()
                    //console.log(data)
                    this.setState({redirect : true})
                    this.setState({loading : true})
                    this.typeActiviteService.getAll().then(data => {
                        this.setState({
                            listeTypeActivite: data,
                            loading : false
                        })
                        })
                        .catch(error => {
                            console.log(error)
                            this.setState({loading : false})
                        })
                    })
                    .catch(error => {
                        this.notify.echec(error.response.data.message)
                        console.log(error);
                    })
                
            }

            state = {
                ...state,
                typeActiviteDialog: false,
                typeActivite: this.emptyTypeActivite
            };
        }

        this.setState(state);
    }
    editTypeActivite(typeActivite) {
        this.setState({
            typeActivite,
            typeActiviteDialog: true
        });
    }
    confirmDeleteTypeActivite(typeActivite) {
        this.setState({
            typeActivite,
            deleteTypeActiviteDialog: true
        });
    }
    deleteTypeActivite(idTypeActivite) {
        this.typeActiviteService.delete(parseInt(idTypeActivite)).then(data => {
            this.notify.success()
            this.setState({
                deleteTypeActiviteDialog: false,
                typeActivite: this.emptyTypeActivite,
                redirect : true
            });
            this.setState({loading : true})
            this.typeActiviteService.getAll().then(data => {
                this.setState({
                    listeTypeActivite: data,
                    loading : false
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({loading : false})
            })
        })
    }
    onLibelleTypeActiviteChange(e, libelleTypeActivite) {
        const val = (e.target && e.target.value) || '';
        let typeActivite = this.state.typeActivite;
        typeActivite[`${libelleTypeActivite}`] = val;

        this.setState({typeActivite });
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeActivite(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeActivite(rowData)} />
            </React.Fragment>
        );
    }
    render() {
        if(this.state.redirect) {
            //window.location.reload();
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const typeActiviteDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeActivite} />
            </React.Fragment>
        );
        const deleteTypeActiviteDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteTypeActiviteDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeActivite(this.state.typeActivite.idTypeActivite) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeTypeActivite} selectionMode="single" header={`Liste des types de finalités (${this.state.listeTypeActivite.length})`} paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeActivite" header="Type Activite" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.typeActiviteDialog} style={{ width: '450px' }} header="Informations type activité" modal className="p-fluid" footer={typeActiviteDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleTypeActivite">Type activité</label>
                        <InputText id="libelleTypeActivite" value={this.state.typeActivite.libelleTypeActivite}  onChange={(e) => this.onLibelleTypeActiviteChange(e, 'libelleTypeActivite')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.typeActivite.libelleTypeActivite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteTypeActiviteDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeActiviteDialogFooter} onHide={this.hideDeleteTypeActiviteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.typeActivite && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default TypeActivite
