import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class JuryService {

    constructor(){
        this.adresse = new AdresseIP()
    }

   getAll() {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/jury"
       return axios.get(url).then(res => res.data) 
   }


   getAllByDepartement(idDepartement) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/jury?idDepartement="+idDepartement
    return axios.get(url).then(res => res.data) 
}
   
   create(newJury) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/jury"
       return axios.post(url,newJury)
                   .then(res => res.data);
   }
   
   update(idJury ,newJury) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/jury/"
       return axios.put(url+idJury, newJury)
                   .then(res => res.data);
   }
   delete(idJury) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/jury/"
       return axios.patch(url+idJury)
                   .then(res => res.data)
   }

}