import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ConditionService {

    constructor(){
        this.adresse = new AdresseIP()


 
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/conditions"
       return axios.get(url).then(res => res.data) 
   }
   
   get(idSession) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/conditions?idSession="
    return axios.get(url+idSession).then(res => res.data)
   }

   create(newCondition) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/conditions"
       return axios.post(url,newCondition)
                   .then(res => res.data);
   }
   
   update(idCondition ,newCondition) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/conditions/"
       return axios.put(url+idCondition, newCondition)
                   .then(res => res.data);
   }

   delete(idCondition) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/conditions/"
       return axios.patch(url+idCondition)
                   .then(res => res.data)
   }


}