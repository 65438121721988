import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP"

export default class GradeService {
    constructor(){
        this._addresseIP = new AdresseIP();
  
        this._backendUrl="service/api/campusfaso-volume-horaire/campus-faso/grades"
    }

    
    /**
     * 
     * @returns la liste complete des grades des enseignants disponible dans la base de données
     */
    getAll(){
        return axios.get(`${this._addresseIP.getAdresseVolumeHoraire()}/${this._backendUrl}`).then(response=>response.data);
    }

    /**
     * 
     * @param {*} grade une liste d'object de type grade
     * @returns undefined
     * enregistre dans la base de données une liste d'object de type grade
     */
    create(grade){
        return  axios.post(`${this._addresseIP.getAdresseVolumeHoraire()}/${this._backendUrl}`,grade).then(response=>response.status)
    }

    /**
     * 
     * @param {*} grade un object de type grade
     * @returns undefined
     * mise à jour d'une grade d'enseignant.
     */
    update(grade){
        return axios.put(`${this._addresseIP.getAdresseVolumeHoraire()}/${this._backendUrl}/${grade.idGrade}`,grade).then(response=>response.status);
    }

    /**
     * 
     * @param {*} idGrade identifiant du grade à supprimer
     * @returns undefined
     * suppression logique d'une grade de d'enseignant dans la base de données
     */
    delete(idGrade){
        return axios.patch(`${this._addresseIP.getAdresseVolumeHoraire()}/${this._backendUrl}/${idGrade}`).then(response=>response.status);
    }
}