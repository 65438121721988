import React, { Component } from 'react'

import {Button} from 'primereact/button'
import classNames from 'classnames'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ToastContainer} from 'react-toastify';
import { Notify } from '../../components/Notify';
import { Spinner } from '../../components/Spinner';
import EnseignantService from '../service/EnseignantService';
import { FacturationService } from '../service/FacturationService';


export class FactureHeureSupp extends Component {

  constructor(props){

    super(props);
    this.notify = new Notify()
    this.spinner = new Spinner()

    this.enseignantService = new EnseignantService()
    this.facturationService = new FacturationService()


    this.state = {
        listeEnseignant : [],
        enseignant : [],
        periodePaiement : [],
        listeHistorique : [],
        token : null,
        loadingChargement : false,
        submitted : false,
        globalFilter: null,
        dialogFact : false,
        dialogPaie : false,
        loadingFacture : false,
        factBool : false,
        infoPaie : {},
        montant : null,
        submitted : false,
        dialogHist : false
    }
    
}
componentDidMount = () => {
  const tokenDecoded = localStorage.getItem('tokenDecoded')
	const token = JSON.parse(tokenDecoded)
  this.setState({token : token})
  this.enseignantService.getListeEnseignantIesr(5).then(data => {
    //console.log(data)
    this.setState({listeEnseignant : data})
  })

}
facturation = (enseignant) => {
  this.setState({loadingFacture : true})
  this.facturationService.getPeriodePaiement(enseignant.idEnseignant, 5).then(data => {
    //console.log(data)
    
    let ListeUnenseignant = [enseignant]
    this.setState({loadingFacture : false ,dialogFact : true, enseignant : ListeUnenseignant, periodePaiement : data})
  })
  .catch(error => {
    this.setState({loadingFacture : false})
    console.log(error)
  })
}
paiementDialog = (paie) => {
  this.setState({dialogPaie : true, infoPaie : paie})
}
paiement = () => {
  this.setState({submitted : true})
  let enseignant = this.state.enseignant
  let idEnseignant = enseignant[0].idEnseignant
  let prenomEnseignant = enseignant[0].prenomEnseignant
  let nomEnseignant = enseignant[0].nomEnseignant
  let infoPaie = this.state.infoPaie
  let idAnnee = infoPaie.idAnnee
  let montant = this.state.montant
  if(idEnseignant && idAnnee && montant) {
      let facture = {
        idAnnee : idAnnee,
        idEnseignant : idEnseignant,
        montantAPayer : montant
      }
      

      if(montant <= this.state.infoPaie.resteApayer){
        this.facturationService.payerFacture(facture).then(data => {
          this.notify.success()
          //console.log(data)
          let recu = {
            reportType : "FACTURE",
            idEnseignant : idEnseignant,
            annee : idAnnee,
            facturation : data.idFacturation
          }
          //console.log(recu)
          this.facturationService.etatHeureSupp(recu).then(res => {
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `reçu_${prenomEnseignant}_${nomEnseignant}_${data.dateFacture}`;
            link.download = fileName;
            link.click();
          })
          this.facturationService.getPeriodePaiement(idEnseignant, 5).then(donnees => {
            this.setState({periodePaiement : donnees, dialogPaie : false, montant : null})
          })

        })
        .catch(error => {
          console.log(error)
          this.notify.echec(error.response.data.message)
        })
      }
      else {
        alert("Le montant à payer est supérieur au reste à payer !")
      }
      
  }
}
historiquePaiement = (idEnseignant) => {
  let codeIesr = 5
  this.facturationService.getPaiement(idEnseignant, codeIesr).then(data => {
    this.setState({listeHistorique : data})
  })
  .catch(error => {
    console.log(error)
  })
  this.setState({dialogHist : true})
}
onValueChange = (e) => {
  const val = (e.target && e.target.value) || ''
  this.setState({montant : val})
}
affichiePaiement = () => {
  this.setState({factBool : true})
}
hideFacturationDialog = () => {
  this.setState({
      dialogFact: false
  })
}
hidePaieDialog = () => {
  this.setState({
    dialogPaie: false
  })
}
hideHistDialog = () => {
  this.setState({
    dialogHist: false
  })
}
actionBodyTemplateEns = (rowData) => {
  return (
      <React.Fragment>
          <Button type="button" icon="pi pi-list" className="p-button-list" style={{marginRight: '.5em'}} title='Selectionner' onClick={ () => {this.historiquePaiement(rowData.idEnseignant)} } />
      </React.Fragment>
  );
}
actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          <Button type="button" icon="pi pi-list" className="p-button-list" style={{marginRight: '.5em'}} title='Selectionner' onClick={ () => {this.facturation(rowData)} } />
      </React.Fragment>
  );
}
actionBodyTemplateDetailFacture = (rowData) => {
  return (
      <React.Fragment>
          <Button type="button" icon="pi pi-shopping-cart" className="p-button-success" style={{marginRight: '.5em'}} title="Effectuer paiement" onClick={ () => this.paiementDialog(rowData) } />
          {/*<Button type="button" icon="pi pi-list" className="p-button-warning" style={{marginRight: '.5em'}} title="Liste paiements" onClick={ () => this.listePaiement(rowData) } />*/}
      </React.Fragment>
  )
}
  render() {
    let actionHeader = <Button type="button" icon="pi pi-cog"/>;
    let actionHeaderEns = <div style={{color : "red"}}>Historique paiement</div>;
    let actionHeaderHN = <div style={{color : "red"}}>Détails paiement</div>;
    const facturationDialogFooter = (
      <React.Fragment>
          <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideFacturationDialog} />
      </React.Fragment>
  );
  const paieDialogFooter = (
    <React.Fragment>
        <Button label="Payer" icon="pi pi-times" className="p-button-text" onClick={this.paiement} />
        <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hidePaieDialog} />
    </React.Fragment>
  );
  const histDialogFooter = (
    <React.Fragment>
        <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideHistDialog} />
    </React.Fragment>
  );
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="card p-lg-12" style={{marginLeft : "0%", marginTop : "5%"}}>
            <div className="table-header p-col-12">
                <span className="p-input-icon-left">
                    <InputText className='p-col-3' type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
          <div className="p-lg-12" style={{marginTop : 10}}>
              <DataTable ref={(el) => this.dt = el} value={this.state.listeEnseignant} selectionMode="single" header="Liste des enseignants" paginator={true} rows={10}
                  globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                  <Column field="matricule" header="Matricule/NIP" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un matricule ou un NIP"/>
                  <Column field="nomEnseignant" header="Nom" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher par nom"/>
                  <Column field="prenomEnseignant" header="Prénom" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher par prénom"/>
                  <Column field="sexeEnseignant" header="Sexe" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher par sexe"/>
                  <Column field="grade" header="Grade" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher par grade"/>
                  <Column field="statusEnseignant" header="Status" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher par status"/>
                  <Column field="telEnseignant" header="Telephone" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher par téléphone"/>
                  <Column field="emailEnseignant" header="Email" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher par email"/>
                  <Column header={actionHeader}  body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
              </DataTable>
          </div>
          </div>
          <Dialog visible={this.state.dialogFact} style={{ width: '100%' }} header={"Etat paiement"} modal className="p-fluid" footer={facturationDialogFooter} onHide={this.hideFacturationDialog}>
              <div className='p-grid' style={{marginTop:"0%"}}>
                {/*this.state.loadingFacture ? 
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {this.spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :*/

                    <div className="card card-w-title datatable-demo">
                      <DataTable  ref={(el) => this.dt = el} value={this.state.enseignant} header={"Informations enseignant"}  responsive={true} >
                          <Column field="matricule" header="Matricule/NIP" />
                          <Column field="nomEnseignant" header="Nom"/>
                          <Column field="prenomEnseignant" header="Prénom" />
                          <Column field="sexeEnseignant" header="Sexe" />
                          <Column field="grade" header="Grade" />
                          <Column field="statusEnseignant" header="Status" />
                          <Column body={this.actionBodyTemplateEns} style={{textAlign:'center', width: '8em'}} header={actionHeaderEns} />
                      </DataTable>
                    </div>
                }
                </div>
                <div className='p-grid'>
                  <div className="p-col-12 ">
                        {/*<div className="p-grid" style={{marginTop: 10, marginLeft : "25%"}}>
                            <div className="p-col-4">
                                <Button label="Consulter paiement(s) déja effectué(s)" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                            <div className="p-col-3">
                                <Button label="Consulter cours réalisés" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>*/}
        
                        <div  className="card card-w-title datatable-demo ">
                          <DataTable  ref={(el) => this.dt = el} value={this.state.periodePaiement} header={actionHeaderHN}  responsive={true} >
                            <Column field="periodePaiement" header="Année accadémique" />
                            <Column field="totalHeure" header="Heures supp."/>
                            <Column style={{color : "green", fontWeight:"bold"}} field="heureSupDejaPaye" header="Heure Supp. déjà Payé"/>
                            <Column field="heureSupNonPayer" header="Heure Supp. Non Payé"/>
                            <Column field="coutHeure" header="Prix/H" />
                            <Column field="totalMontant" header="Montant total" />
                            <Column style={{color : "green", fontWeight:"bold"}} field="montantDejaPaye" header="Montant déjà payé" />
                            <Column style={{color : "red", fontWeight : "bold"}} field="resteApayer" header="Reste à payer" />
                            <Column header={actionHeader} body={this.actionBodyTemplateDetailFacture} style={{textAlign:'center', width: '8em'}}/>
                          </DataTable>
                        </div>
                    </div>
                </div>
                
          </Dialog>
          <Dialog visible={this.state.dialogPaie} style={{ width: '30%' }} header={"Paiement"} modal className="p-fluid" footer={paieDialogFooter} onHide={this.hidePaieDialog}>
            <div className="p-grid">
              <div className='p-col-12 p-md-12 p-lg-12'>
                <div className="p-field">
                  <label htmlFor="libelleUniteEnseignement">Montnant à payer</label>
                  <InputText type='number' value={this.state.montant} onChange={(e) => {this.onValueChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                  {this.state.submitted && !this.state.montant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                </div>
              </div>
            </div>          
          </Dialog>
          <Dialog visible={this.state.dialogHist} style={{ width: '80%' }} header={"Historique Paiement"} modal className="p-fluid" footer={histDialogFooter} onHide={this.hideHistDialog}>
            <div className="table-header p-col-3">
                <span className="p-input-icon-left">
                    <InputText className='p-col-3' type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
            <div  className="card card-w-title datatable-demo ">
              <DataTable  ref={(el) => this.dt = el} value={this.state.listeHistorique} header={"Liste des paiement"}  responsive={true} paginator={true} rows={10}
              globalFilter={this.state.globalFilter} filterMatchMode="contains" selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                <Column field="libelleAnnee" header="Année accadémique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="année accadémique"/>
                <Column field="heureSupExecutee" header="Heures supp." filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder=""/>
                <Column style={{color : "green"}} field="heurePayer" header="Heure Supp. Payé" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder=""/>
                <Column field="prixHeure" header="Prix/H" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder=""/>
                <Column field="montantTotal" header="Montant total" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder=""/>
                <Column style={{color : "green", fontWeight:"bold"}} field="montant" header="Montant Payé" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder=""/>
                <Column field="dateFacture" header="datePaiement" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="date paiement"/>
              </DataTable>
            </div>     
          </Dialog>     
      </div>
    )
  }
}

export default FactureHeureSupp