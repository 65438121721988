import React, { Component, useDebugValue } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { UEService } from '../../service/UEService';
import { ParcoursService } from '../../service/ParcoursService';
import { ParcoursUEService } from '../../service/ParcoursUEService';
import { SemestreService } from '../../service/SemestreService';


export class ParcoursUE extends Component {

    constructor() {
        super();
        this.state = {
            listeParcoursUE : [],
            listeParcours : [],
            listeUE : [],
            listeUEBd : [],
            listeUEChoisi : [],
            creditSemestre: 0,
            creditParcoursSemestre: 0,
            parcoursUEDialog: false,
            deleteParcoursUEDialog: false,
            parcoursUE: {
                idParcoursUniteEnseignement: null,
                parcours: '',
                semestre : '',
                uniteEnseignement : ''
            },
            creditUEOptionnelParcours: null,
            nombreUEOptionnelChoix: "",
            submitted: false,
            redirect : false,
            loading : false,
            loadingUe: false,
            loadingUeDisponible: false,
            globalFilter: null,
            globalFilterPicklist : null,
            listeSemestre: [],
            listeSemestreAccueil: [],
            parcoursAccueil: null,
            semestreAccueil: null,
            listeUeParcours: [],
            selectionUeParcours: [],
            selectionChoiUE: [],
            existeUEOptionnel: false,
            idDepartement: null,
            selectParcoursAccueilDialog: false,
            selectSemestreAccueilDialog: false,
            selectSemestreDialog: false
        }

        this.parcoursUEService = new ParcoursUEService()
        this.parcoursService = new ParcoursService()
        this.semestreService = new SemestreService()
        this.ueService = new UEService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveParcoursUE = this.saveParcoursUE.bind(this);
        this.editParcoursUE = this.editParcoursUE.bind(this);
        this.confirmDeleteParcoursUE = this.confirmDeleteParcoursUE.bind(this);
        this.deleteParcoursUE = this.deleteParcoursUE.bind(this);
        this.onIdParcoursChange = this.onIdParcoursChange.bind(this);
        this.hideDeleteParcoursUEDialog = this.hideDeleteParcoursUEDialog.bind(this);
    }


    componentDidMount() {


        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({
            idDepartement: token.code_departement
        })

        this.parcoursService.getAll().then(data => {
            this.setState({listeParcours: data})
        })
        .catch(error => { 
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
            
    }

    openNew() {
        this.setState({
            parcoursUE: {
                idParcoursUniteEnseignement: null,
                parcours: '',
                semestre : '',
                uniteEnseignement : ''
            },
            submitted: false,
            parcoursUEDialog: true
        })
    }



    hideDialog() {
        this.setState({
            submitted: false,
            parcoursUEDialog: false,
            parcoursUE: {
                idParcoursUniteEnseignement: null,
                parcours: '',
                semestre : '',
                uniteEnseignement : ''
            },
            listeUE: [],
            listeUEChoisi: [],
            loadingUeDisponible: false,
            existeUEOptionnel: false,
            creditUEOptionnelParcours: null,
            nombreUEOptionnelChoix: "",
            creditSemestre: 0
        });
    }

    hideDeleteParcoursUEDialog() {
        this.setState({ deleteParcoursUEDialog: false });
    }

    saveParcoursUE() {

        this.setState({ 
            submitted: true 
        });
        let listeParcoursUE = []
        if (this.state.parcoursUE.parcours && this.state.parcoursUE.semestre && this.state.listeUEChoisi && this.state.listeUEChoisi.length>0 && this.state.creditSemestre===30) {
            
            this.state.listeUEChoisi.map(ue => {
                return listeParcoursUE.push({idParcoursUniteEnseignement:parseInt(ue.idParcoursUniteEnseignement), idParcours : this.state.parcoursUE.parcours.idParcours, idSemestre : this.state.parcoursUE.semestre.idSemestre, idUniteEnseignement : ue.idUniteEnseignement})
                
            })


            this.parcoursService.getNombreUEOptionnelParcoursSemestre(this.state.parcoursUE.parcours.idParcours, this.state.parcoursUE.semestre.idSemestre).then(dataNBUEOptionne => {
                
                let informationNBUEOptionnelChoix = dataNBUEOptionne
                //On regarde, si y'a ue optionnel dont l'option porte sur l'UE, on procede a l'enregistrement en base de donnees
                if(this.state.existeUEOptionnel){

                    if(informationNBUEOptionnelChoix.nbUeOptionnel){
                        //Existe deja donc faire un update
                        let structureNBUEChoix = {
                            nbUeOptionnel: this.state.nombreUEOptionnelChoix,
                            idParcours: this.state.parcoursUE.parcours.idParcours,
                            parcours: this.state.parcoursUE.parcours,
                            semestre: this.state.parcoursUE.semestre,
                            idSemestre: this.state.parcoursUE.semestre.idSemestre
                        }
                        this.parcoursService.updateNombreUEAChoisir(informationNBUEOptionnelChoix.idParcoursSemestre, structureNBUEChoix).then(data => {
                        }).catch(error => {
                            console.log(error)
                            this.notify.echec(error.response.data.message)
                        })

                    }else{

                        //N'existe pas, donc une creation 
                        let structureNBUEChoix = {
                            nbUeOptionnel: this.state.nombreUEOptionnelChoix,
                            idParcours: this.state.parcoursUE.parcours.idParcours,
                            parcours: this.state.parcoursUE.parcours,
                            idSemestre: this.state.parcoursUE.semestre.idSemestre,
                            semestre: this.state.parcoursUE.semestre
                        }
                        this.parcoursService.enregistrerNombreUEAChoisir(structureNBUEChoix).then(data => {
                        }).catch(error => {
                            console.log(error)
                            this.notify.echec(error.response.data.message)
                        })
                    }
                
                }else{

                    if(informationNBUEOptionnelChoix.nbUeOptionnel){

                        //Existe deja et doit etre supprimé
                        this.parcoursService.deleteNombreChoixUEOptionel(informationNBUEOptionnelChoix.idParcoursSemestre).then(data => {
                        }).catch(error => {
                            console.log(error)
                            this.notify.echec(error.response.data.message)
                        })
                    }

                }

            }).catch(error => {
                console.log(error)
            })

            let idParcours = this.state.parcoursUE.parcours.idParcours
            let idSemestre = this.state.parcoursUE.semestre.idSemestre
            this.parcoursUEService.update(idParcours, idSemestre,listeParcoursUE).then(data => {
            this.notify.success()

                this.setState({
                    parcoursUE: {
                        idParcoursUniteEnseignement: null,
                        parcours: '',
                        semestre : '',
                        uniteEnseignement : ''
                    },
                    parcoursUEDialog: false,
                    listeUE: [],
                    listeUEChoisi: [],
                    submitted: false,
                    loading: false,            
                    loadingUeDisponible: false,
                    existeUEOptionnel: false,
                    creditUEOptionnelParcours: null,
                    nombreUEOptionnelChoix: ""
                })

            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error);
            })
            
        }else{

            if(this.state.listeUEChoisi.length===0){
                this.notify.echec('Choisir au moins une UE avant d\'enregistrer !!!')
            }else if(this.state.creditSemestre!==30){
                this.notify.echec('Données invalides, le total des crédits est différent de 30 !!!')
            }
        }

    }


    editParcoursUE(parcoursUE) {
        this.setState({
            parcoursUE,
            parcoursUEDialog: true
        });
    }

    confirmDeleteParcoursUE(parcoursUE) {
        this.setState({
            parcoursUE,
            deleteParcoursUEDialog: true
        });
    }

    
    deleteParcoursUE(idParcoursUE) {

        this.parcoursUEService.delete(parseInt(idParcoursUE)).then(data => {
            this.notify.success()
            this.setState({
                deleteParcoursUEDialog: false,
                parcoursUE: {
                    idParcoursUniteEnseignement: null,
                    parcours: '',
                    semestre : '',
                    uniteEnseignement : ''
                },
                redirect : true
            });
            this.setState({loading : true})

            let idSemestre = (this.state.semestreAccueil)? this.state.semestreAccueil.idSemestre: null
            this.loadUeByParcoursAndSemestre(this.state.parcoursAccueil.idParcours, idSemestre)

            this.setState({loading : false})
        })
    }


    onIdParcoursChange(e, parcours) {

        const val = (e.target && e.target.value) || '';
        let parcoursUE = this.state.parcoursUE;
        parcoursUE[`${parcours}`] = val;
        parcoursUE['semestre'] = ""
        this.loadSemestre(parcoursUE.parcours.idParcours)
        this.setState({
            parcoursUE: parcoursUE,
            listeUEChoisi: [],
            loadingUeDisponible: true,
            existeUEOptionnel: false,
            creditUEOptionnelParcours: null,
            nombreUEOptionnelChoix: "",
            creditSemestre: 0
        });


        this.ueService.getUeLibreParcours(e.target.value.idParcours, this.state.idDepartement).then(data => {

            this.setState({
                listeUE: data,
                loadingUeDisponible: false
            })

        }).catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message)
            this.setState({
                loadingUeDisponible: false
            })
        })

    }


    onParcoursChangeByDatatable = (parcours) => {


        let parcoursUE = this.state.parcoursUE;
        parcoursUE["parcours"] = parcours;
        parcoursUE['semestre'] = ""
        this.loadSemestre(parcoursUE.parcours.idParcours)
        this.setState({
            parcoursUE: parcoursUE,
            listeUEChoisi: [],
            loadingUeDisponible: true,
            existeUEOptionnel: false,
            creditUEOptionnelParcours: null,
            nombreUEOptionnelChoix: "",
            creditSemestre: 0,
            selectParcoursDialog: false
        });


        this.ueService.getUeLibreParcours(parcours.idParcours, this.state.idDepartement).then(data => {

            this.setState({
                listeUE: data,
                loadingUeDisponible: false
            })

        }).catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message)
            this.setState({
                loadingUeDisponible: false
            })
        })
    }


    onIdParcoursAccueilChange = (event) => {
        let parcoursAccueil = event.target.value

        let semestreAccueil = this.state.semestreAccueil
        let idSemestre = semestreAccueil? semestreAccueil.idSemestre: null
        this.setState({
            loading: true,
            parcoursAccueil: parcoursAccueil
        })

        this.loadSemestreAccueil(parcoursAccueil.idParcours)
        this.loadUeByParcoursAndSemestre(parcoursAccueil.idParcours, idSemestre)

        this.setState({
            loading: false
        })
    }


    onIdParcoursAccueilChangeByDataTable = (parcourschoix) => {

        let semestreAccueil = this.state.semestreAccueil
        let idSemestre = semestreAccueil? semestreAccueil.idSemestre: null
        this.setState({
            loading: true,
            parcoursAccueil: parcourschoix
        })

        this.loadSemestreAccueil(parcourschoix.idParcours)
        this.loadUeByParcoursAndSemestre(parcourschoix.idParcours, idSemestre)

        this.setState({
            loading: false,
            selectParcoursAccueilDialog: false
        })
    }



    onSemestreChange(e, semestre) {

        //loadingUeDisponible
        const val = (e.target && e.target.value) || '';
        let parcoursUE = this.state.parcoursUE;
        parcoursUE[`${semestre}`] = val;
        
        this.loadUEChoisis(parcoursUE.parcours.idParcours, parcoursUE.semestre.idSemestre)
        
        this.setState({parcoursUE });
    }

    onSemestreAccueilChange = (event) => {
        let parcoursAccueil = this.state.parcoursAccueil
        this.setState({
            semestreAccueil: event.target.value
        })

        if(parcoursAccueil){
            this.setState({
                loading: true
            })
            this.loadUeByParcoursAndSemestre(parcoursAccueil.idParcours, event.target.value.idSemestre)
            
            this.setState({
                loading: false
            })
        }
    }


    onSemestreChangeByDatatable = (semestre) => {
        


        let parcoursUE = this.state.parcoursUE;
        parcoursUE["semestre"] = semestre;
        
        this.loadUEChoisis(parcoursUE.parcours.idParcours, parcoursUE.semestre.idSemestre)
        this.setState({
            parcoursUE: parcoursUE,
            selectSemestreDialog: false
        });
    }


    /// onParcoursChangeByDatatable

    onSemestreAccueilChangeByDatatable = (semestre) => {
        let parcoursAccueil = this.state.parcoursAccueil
        this.setState({
            semestreAccueil: semestre
        })

        if(parcoursAccueil){
            this.setState({
                loading: true
            })
            this.loadUeByParcoursAndSemestre(parcoursAccueil.idParcours, semestre.idSemestre)
            
            this.setState({
                loading: false,
                selectSemestreAccueilDialog: false
            })
        }
    }


    loadSemestre = (idParcours) => {
        this.semestreService.get(idParcours).then(data => {
            this.setState({listeSemestre : data})
        })
    }

    loadSemestreAccueil = (idParcours) => {
        this.semestreService.get(idParcours).then(data => {
            this.setState({listeSemestreAccueil : data})
        })
    }


    loadUEChoisis = (idParcours, idSemestre) => {

        this.setState({
            loadingUe: true,
            creditSemestre: 0
        })


        let creditSemestre = 0 
        this.parcoursUEService.get(idParcours, idSemestre).then(data => {

            this.setState({listeUEChoisi : data, target :data})

            


            let existeOptionUE = false
            let creditUEOptionnel = 0
            //Le semestre est choisi, on parcours pour voir si y'a UE optionnel dont l'option porte sur l'UE qui est là!
            if(idSemestre){

                data.map(ue => {
                    if(!ue.uniteEnseignement.ueOptionnel || ue.uniteEnseignement.nbEcObligatoire){
                        creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.creditUniteEnseignement) 
                    }else{
                        existeOptionUE = true
                        creditUEOptionnel = ue.uniteEnseignement.creditUniteEnseignement
                    }
                })
            }


            if(existeOptionUE && idSemestre){

                //On recupere le nombre d'UE obligatoire 
                this.parcoursService.getNombreUEOptionnelParcoursSemestre(idParcours, idSemestre).then(dataNBUEOptionne => {

                    if(dataNBUEOptionne.nbUeOptionnel){
                        creditUEOptionnel = parseFloat(creditUEOptionnel)*parseFloat(dataNBUEOptionne.nbUeOptionnel)
                    }

                    creditSemestre = parseFloat(creditSemestre) + parseFloat(creditUEOptionnel)
                    this.setState({
                        creditSemestre: creditSemestre,
                        creditUEOptionnelParcours: creditUEOptionnel,
                        nombreUEOptionnelChoix: dataNBUEOptionne.nbUeOptionnel,
                        existeUEOptionnel: true
                    })

                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })
            }


            this.setState({
                loadingUe: false,
                existeUEOptionnel: existeOptionUE,
                creditSemestre: creditSemestre
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }


    loadUeByParcoursAndSemestre = (idParcours, idSemestre) => {

        this.parcoursUEService.get(idParcours, idSemestre).then(data => {


            let creditParcours = 0
        
            data.map(ue => {
                creditParcours = creditParcours + parseFloat(ue.creditUniteEnseignement)
            })

            this.setState({
                listeUeParcours: data,
                creditParcoursSemestre: creditParcours
            })

        }).catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message)
        })
    }


    onUESelectionChange = (e) => {

        if(this.state.selectionUeParcours.length>0){

            //On insert les élément selectionnés dans les choix d'UE
            let listeUEChoisi = this.state.listeUEChoisi

            //On recupère la liste globale des UE disponibles
            let listeUE = this.state.listeUE

            //On recupère aussi les id UE pour les retirer de la selection
            let listeIdUniteEnseignement = []

            this.state.selectionUeParcours.map(ue => {
                listeUEChoisi.push(ue)
                listeIdUniteEnseignement.push(ue.idUniteEnseignement)
            })

            let creditSemestre = 0 
            let creditUEOptionnel = 0
            let existeOptionUE = false

            listeUEChoisi.map(ue => {

                if((ue.uniteEnseignement && ue.uniteEnseignement.typeUniteEnseignement.idTypeUniteEnseignement===4 && ue.ueOptionnel) || (ue.idTypeUniteEnseignement && ue.idTypeUniteEnseignement===4 && ue.ueOptionnel)){
                    existeOptionUE = true

                    if(ue.uniteEnseignement){
                        creditUEOptionnel = ue.uniteEnseignement.creditUniteEnseignement
                    }else{
                        creditUEOptionnel = ue.creditUniteEnseignement
                    }

                }else{

                    if(ue.uniteEnseignement){
                        creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.uniteEnseignement.creditUniteEnseignement)
                    }else{
                        creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.creditUniteEnseignement)
                    }
                    
                }

                if(this.state.nombreUEOptionnelChoix && this.state.nombreUEOptionnelChoix>0){
                    creditSemestre = parseFloat(creditSemestre) + parseFloat(this.state.nombreUEOptionnelChoix)*parseFloat(creditUEOptionnel)
                }

                this.setState({
                    creditSemestre: creditSemestre
                })
                
            })



            let newListeUEDisponible = listeUE.filter(ue => {
                return (listeIdUniteEnseignement.indexOf(ue.idUniteEnseignement)===-1)
            })

            //On positionne les élements selectionnés
            this.setState({
                listeUEChoisi: listeUEChoisi,
                selectionUeParcours: [],
                listeUE: newListeUEDisponible,
                existeUEOptionnel: existeOptionUE
            })
        }
    }



    choixParcoursAccueil = () => {

        this.setState({
            selectParcoursAccueilDialog: true
        })
        //console.log(this.state.listeParcours)

    }

    choixSemestreAccueil = () => {

        let parcoursAccueil = this.state.parcoursAccueil

        if(parcoursAccueil){
            this.setState({
                selectSemestreAccueilDialog: true
            })
        }else{
            this.notify.echec("Choisir un parcours avant de sélectionner le semestre !")
        }
        
    }




    onNombreUEOptionnelChoixChange = (event) => {

        let nbUEChoix = (  !isNaN(parseInt(event.target.value)) && event.target.value>0)?parseFloat(event.target.value):"";

        this.setState({
            nombreUEOptionnelChoix: nbUEChoix
        })

        let listeUEParcoursSemestre = this.state.listeUEChoisi
        let creditUEOptionnel = 0
        let creditSemestre = 0

        nbUEChoix = (!isNaN(parseInt(nbUEChoix)) && nbUEChoix>0)?parseInt(nbUEChoix):0

        listeUEParcoursSemestre.map(ue => {
            
            if((ue.uniteEnseignement && ue.uniteEnseignement.typeUniteEnseignement.idTypeUniteEnseignement===4) || (ue.idTypeUniteEnseignement && ue.idTypeUniteEnseignement===4)){

                if(ue.uniteEnseignement){
                    creditUEOptionnel = ue.uniteEnseignement.creditUniteEnseignement
                }else{
                    creditUEOptionnel = ue.creditUniteEnseignement
                }
            }else{

                if(ue.uniteEnseignement){
                    creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.uniteEnseignement.creditUniteEnseignement)
                }else{
                    creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.creditUniteEnseignement)
                }
            }
            
        })

        creditSemestre = parseFloat(creditSemestre) + parseFloat(nbUEChoix)*parseFloat(creditUEOptionnel)

        this.setState({
            creditSemestre: creditSemestre
        })

    }

    onUEParcoursDelete = (e) => {

        if(this.state.selectionChoiUE.length>0){

            //Liste des UE disponibles
            let listeUE = this.state.listeUE

            //Liste des UE choisi
            let listeUEChoisi = this.state.listeUEChoisi

            //On filtre pour ne prendre en compte ce qui n'as pas été selectionné pour suppression
            let idUniteEnseignementASupprimer = []

            this.state.selectionChoiUE.map(ue => {
                idUniteEnseignementASupprimer.push(ue.idUniteEnseignement)
                listeUE.push(ue)
            })
            
            //Liste des UE choisi restant
            let listeUERestant = listeUEChoisi.filter(ue => {
                return (idUniteEnseignementASupprimer.indexOf(ue.idUniteEnseignement)===-1)
            })

            let creditSemestre = 0 
            let creditUEOptionnel = 0
            let existeOptionUE = false

            listeUERestant.map(ue => {

                if((ue.uniteEnseignement && ue.uniteEnseignement.typeUniteEnseignement.idTypeUniteEnseignement===4 && ue.ueOptionnel) || (ue.idTypeUniteEnseignement && ue.idTypeUniteEnseignement===4 && ue.ueOptionnel)){
                    existeOptionUE = true

                    if(ue.uniteEnseignement){
                        creditUEOptionnel = ue.uniteEnseignement.creditUniteEnseignement
                    }else{
                        creditUEOptionnel = ue.creditUniteEnseignement
                    }
                }else{
                    if(ue.uniteEnseignement){
                        creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.uniteEnseignement.creditUniteEnseignement)
                    }else{
                        creditSemestre = parseFloat(creditSemestre) + parseFloat(ue.creditUniteEnseignement)
                    }
                }
            })

            if(this.state.nombreUEOptionnelChoix && this.state.nombreUEOptionnelChoix>0){
                creditSemestre = parseFloat(creditSemestre) + parseFloat(this.state.nombreUEOptionnelChoix)*parseFloat(creditUEOptionnel)
            }

            this.setState({
                selectionChoiUE: [],
                listeUE: listeUE,
                listeUEChoisi: listeUERestant,
                existeUEOptionnel: existeOptionUE,
                creditSemestre: creditSemestre
            })
        }
        
    }


    choixSemestre = () => {

        let parcours = this.state.parcoursUE.parcours

        if(parcours){
            this.setState({
                selectSemestreDialog: true
            })
        }else{
            this.notify.echec("Sélectionner un parcours avant de selectionner le semestre !")
        }
        
    }


    choixParcours = () => {
        this.setState({
            selectParcoursDialog: true
        })
    }

    hideSelectParcoursAccueil = () => {
        this.setState({
            selectParcoursAccueilDialog: false
        })
    }

    hideSelectSemestreAccueil = () => {
        this.setState({
            selectSemestreAccueilDialog: false
        })
    }

    hideSelectSemestre = () => {
        this.setState({
            selectSemestreDialog: false
        })
    }

    hideSelectParcours = () => {
        this.setState({
            selectParcoursDialog: false
        })
    }
    
    itemTemplate(item) {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleUniteEnseignementCode}</h6>
                </div>
            </div>
        );
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteParcoursUE(rowData)} />*/}
            </React.Fragment>
        );
    }

    choixParcoursAccueilAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-check-circle" className="p-button-success" onClick={() => this.onIdParcoursAccueilChangeByDataTable(rowData)} />
            </React.Fragment>
        );
    }


    choixSemestreAccueilAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-check-circle" className="p-button-success" onClick={() => this.onSemestreAccueilChangeByDatatable(rowData)} />
            </React.Fragment>
        );
    }

    choixSemestreAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-check-circle" className="p-button-success" onClick={() => this.onSemestreChangeByDatatable(rowData)} />
            </React.Fragment>
        );
    }

    choixParcoursAction = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-check-circle" className="p-button-success" onClick={() => this.onParcoursChangeByDatatable(rowData)} />
            </React.Fragment>
        );
    }

    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const parcoursUEDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveParcoursUE} />
            </React.Fragment>
        );


        const footerSelectParcoursAccueil = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectParcoursAccueil} />
            </React.Fragment>
        );


        const footerSelectSemestreAccueil = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSemestreAccueil} />
            </React.Fragment>
        );


        const footerSelectSemestre = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSemestre} />
            </React.Fragment>
        );


        const footerSelectParcours = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectParcours} />
            </React.Fragment>
        )

        const deleteParcoursUEDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteParcoursUEDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteParcoursUE(this.state.parcoursUE.idParcoursUniteEnseignement) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter/ Retirer" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />

                    <div className="card">
                        <div className="card-header" style={{textAlign: 'center'}}>
                            <h3>Informations du parcours</h3>
                        </div>
                        <div className="card-body" style={{padding: '0px'}}>
                            <div className="p-grid">
                                <div className="p-field p-col-8">
                                    <label htmlFor="libelleParcours">Sélectionner le parcours</label>
                                    <br/>
                                    <div className="p-grid p-lg-12">
                                        <div className="p-field p-col-11">
                                            <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.parcoursAccueil} options={this.state.listeParcours} onChange={(e) => {this.onIdParcoursAccueilChange(e)}} required autoFocus className="p-dropdown p-component  p-col-12" placeholder="Selectionner un parcours"/>
                                        </div>
                                        <div className="p-field p-col-1">
                                            <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.choixParcoursAccueil} />
                                        </div>
                                    <div/>
                                </div>
                            </div>

                            <div className="p-field p-col-4">
                                <label htmlFor="libelleSemestre">Sélectionner le semestre</label>
                                <br/>
                                <div className="p-grid p-lg-12">
                                    <div className="p-field p-col-11">
                                        <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.semestreAccueil} options={this.state.listeSemestreAccueil} onChange={(e) => {this.onSemestreAccueilChange(e)}} required className="p-dropdown p-component  p-col-12" placeholder="Selectionner un semestre"/>
                                    </div>
                                    <div className="p-field p-col-1">
                                        <Button type="button" icon="pi pi-search" className="p-button-info"  onClick={this.choixSemestreAccueil}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :

                        (this.state.parcoursAccueil) &&

                            <React.Fragment>
                                <hr/>
                                <React.Fragment>
                                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeUeParcours} selectionMode="single" header={`Liste des UEs concernés par la selection ( ${this.state.creditParcoursSemestre} crédits (cumule des crédits UE) )  (${this.state.listeUeParcours.length} UEs)`} paginator={true} rows={10}
                                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                        <Column field="uniteEnseignement.codeUniteEnseignement" header="Code UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code UE"/>
                                        <Column field="uniteEnseignement.libelleUniteEnseignement" header="Libelle UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher UE"/>
                                        <Column field="uniteEnseignement.creditUniteEnseignement" header="Crédit UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher crédit UE"/>
                                        <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                                    </DataTable>
                                </React.Fragment>
                            </React.Fragment>
                        }
                        
                    </div>
                </div>


























                <Dialog visible={this.state.parcoursUEDialog} style={{ width: '95%' }} header="Informations parcours-UE" modal className="p-fluid" footer={parcoursUEDialogFooter} onHide={this.hideDialog}>
                    <div className="p-grid">
                        <div className="p-field p-col-4">
                            <label htmlFor="libelleParcours">Parcours</label>
                            <div className="p-grid p-lg-12">
                                <div className="p-field p-col-11">
                                    <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.parcoursUE.parcours} options={this.state.listeParcours} onChange={(e) => {this.onIdParcoursChange(e, 'parcours')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un parcours"/>
                                    {this.state.submitted && !this.state.parcoursUE.parcours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                <div className="p-field p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.choixParcours}/>
                                </div>
                            </div>
                        </div>


                        <div className="p-field p-col-4">
                            <label htmlFor="libelleSemestre">Semestre</label>
                            <div className="p-grid p-lg-12">
                                <div className="p-field p-col-11">
                                    <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.parcoursUE.semestre} options={this.state.listeSemestre} onChange={(e) => {this.onSemestreChange(e, 'semestre')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                    {this.state.submitted && !this.state.parcoursUE.semestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                <div className="p-field p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.choixSemestre}/>
                                </div>
                            </div>
                        </div>

                        {this.state.listeUEChoisi.length>0 && 
                        <div className="p-field p-col-2">
                        <label htmlFor="nbCredit">Valeur des crédits</label>
                            <InputText value={this.state.creditSemestre} readOnly/>
                        </div>
                        }

                    </div>

                    <hr/>
                    <div className="p-grid">


                        {this.state.loadingUeDisponible ? 
                            <div className="p-field p-col-5">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                            :
                            <div className="p-field p-col-5">
                                <DataTable  ref={(el) => this.dt = el} value={this.state.listeUE} header="Liste des UEs disponibles" paginator={true} rows={5}
                                    globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.selectionUeParcours} onSelectionChange={event => this.setState({selectionUeParcours: event.value})}>
                                    <Column field="libelleUniteEnseignementCode" header="Code UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code UE"/>
                                    <Column field="libelleUniteEnseignement" header="Libelle UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher UE"/>
                                    <Column field="creditUniteEnseignement" header="Crédit UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher crédit UE"/>
                                    <Column selectionMode="multiple" headerStyle={{ width: '5em' }}></Column>
                                </DataTable>
                                <Button label="Choisir la sélection" icon="pi pi-check" className="p-button-text" onClick={e => this.onUESelectionChange(e)} />
                            </div>
                        }

                        <div className="p-field p-col-2">
                            <Button label="Choisir la sélection ==>" icon="pi pi-check" className="btn btn-success" onClick={e => this.onUESelectionChange(e)}  style={{marginBottom: '5px'}}/>
                            <Button label="<== Retirer la sélection" icon="pi pi-trash" className="btn btn-danger" onClick={e => this.onUEParcoursDelete(e)}/>
                        </div>

                        {this.state.loadingUe ? 
                        <div className="p-field p-col-5">
                            {this.spinner.loadingSpinner("spin", "black")}
                        </div>
                        :
                        <div className="p-field p-col-5">
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeUEChoisi} header="Liste des UEs choisis" paginator={true} rows={5}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.selectionChoiUE} onSelectionChange={event => this.setState({selectionChoiUE: event.value})}>
                                <Column field="libelleUniteEnseignementCode" header="Code UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher code UE"/>
                                <Column field="libelleUniteEnseignement" header="Libelle UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher UE"/>
                                <Column field="creditUniteEnseignement" header="Crédit UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher crédit UE"/>
                                <Column selectionMode="multiple" headerStyle={{ width: '5em' }}></Column>
                            </DataTable>
                            <Button label="Retirer la sélection" icon="pi pi-trash" className="p-button-text p-danger" onClick={e => this.onUEParcoursDelete(e)} />
                        </div>
                        }
                    </div>

                    <hr/>
                    <div className="p-grid">
                        {this.state.existeUEOptionnel &&
                        <div className="p-field p-col-12">
                            <label htmlFor="nbUEObligatoire">Preciser le nombre d'UE optionnel(s) à choisir par les étudiants</label>
                            <InputText type="number" min="1" value={this.state.nombreUEOptionnelChoix} onChange={(e) => {this.onNombreUEOptionnelChoixChange(e)}} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Preciser le nombre d'UE obligatoire"/>
                            {this.state.submitted && !this.state.nombreUEOptionnelChoix && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        }

                    </div>
                
                </Dialog>


                <Dialog visible={this.state.selectParcoursAccueilDialog} style={{ width: '55%' }} header="Sélection du parcours" modal className="p-fluid" footer={footerSelectParcoursAccueil} onHide={this.hideSelectParcoursAccueil}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeParcours} header="Liste des parcours" paginator={true} rows={10} filterMatchMode="contains" responsive={true} >
                        <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher un parcours"/>
                        <Column header={actionHeader} body={this.choixParcoursAccueilAction} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                </Dialog>





                <Dialog visible={this.state.selectSemestreAccueilDialog} style={{ width: '55%' }} header="Sélection du semestre" modal className="p-fluid" footer={footerSelectSemestreAccueil} onHide={this.hideSelectSemestreAccueil}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeSemestreAccueil} header="Liste des semestres" paginator={true} rows={10} filterMatchMode="contains" responsive={true} >
                        <Column field="libelleSemestre" header="Semestres" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher un semestre"/>
                        <Column header={actionHeader} body={this.choixSemestreAccueilAction} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                </Dialog>

                
                <Dialog visible={this.state.deleteParcoursUEDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteParcoursUEDialogFooter} onHide={this.hideDeleteParcoursUEDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.parcoursUE && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.selectSemestreDialog} style={{ width: '55%' }} header="Sélection du semestre" modal className="p-fluid" footer={footerSelectSemestre} onHide={this.hideSelectSemestre}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeSemestre} header="Liste des semestres" paginator={true} rows={10} filterMatchMode="contains" responsive={true} >
                        <Column field="libelleSemestre" header="Semestres" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher un semestre"/>
                        <Column header={actionHeader} body={this.choixSemestreAction} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                </Dialog>



                <Dialog visible={this.state.selectParcoursDialog} style={{ width: '55%' }} header="Sélection du parcours" modal className="p-fluid" footer={footerSelectParcours} onHide={this.hideSelectParcours}>
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeParcours} header="Liste des parcours" paginator={true} rows={10} filterMatchMode="contains" responsive={true} >
                        <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher un parcours"/>
                        <Column header={actionHeader} body={this.choixParcoursAction} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                </Dialog>




            </div> 
        )
    }
}

export default ParcoursUE
