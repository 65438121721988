import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { ConditionService } from '../../service/ConditionService'
import { SessionService } from '../../service/SessionService'
import { TypeDemandeService } from '../../service/TypeDemandeService'
import { CategorieDemandeService } from '../../service/CategorieDemandeService'
import { StructureService } from '../../service/StructureService'

export class Condition extends Component {
    emptyCondition = {
        idCondition: null,
        libelleCondition: "",
        contenu : "",
        typeDemande : {},
        session : {},
    }

    constructor() {
        super();
        this.state = {
            listeCondition : [],
            listeTypeDemande : [],
            listeSession : [],
            structure : {},
            anneeSession : {},
            categorieDemande : {},
            listeCategorieDemande : [],
            listeAnnee : [],
            conditionDialog: false,
            deleteConditionDialog: false,
            condition: this.emptyCondition,
            submitted: false,
            loading : false,
            globalFilter: null
        }
        this.conditionService = new ConditionService()
        this.typeDemandeService = new TypeDemandeService();
        this.sessionService = new SessionService();
        this.categorieDemandeService = new CategorieDemandeService();
        this.structureService = new StructureService();
        
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCondition = this.saveCondition.bind(this);
        this.editCondition = this.editCondition.bind(this);
        this.confirmDeleteCondition = this.confirmDeleteCondition.bind(this);
        this.deleteCondition = this.deleteCondition.bind(this);
        this.onLibelleConditionChange = this.onLibelleConditionChange.bind(this);
        this.onContenuConditionChange = this.onContenuConditionChange.bind(this);
        this.onSessionConditionChange = this.onSessionConditionChange.bind(this);
        this.onTypeDemandeConditionChange = this.onTypeDemandeConditionChange.bind(this);
        this.hideDeleteConditionDialog = this.hideDeleteConditionDialog.bind(this);
        this.onSessionChange = this.onSessionChange.bind(this);

    }


    componentDidMount() {
    
        let today = new Date();
        let year = today.getFullYear();
        const structure = localStorage.getItem('structures')
        const struct = JSON.parse(structure)
        this.setState({structure : struct});

        this.sessionService.get(year, 385, 'NIVEAU_III',"OUVERT","").then(donnee => {
            console.log(donnee);
            this.setState({listeSession : donnee});
        })
        .catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message);
        })

        this.categorieDemandeService.get(385, 'NIVEAU_III').then(data => 
            this.setState({listeCategorieDemande: data})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })

    }
    openNew() {
        this.setState({
            condition: this.emptyCondition,
            submitted: false,
            conditionDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            conditionDialog: false
        });
    }
    hideDeleteConditionDialog() {
        this.setState({ deleteConditionDialog: false });
    }
    saveCondition() {
        let state = { submitted: true };

        if (this.state.condition.session.idSession && this.state.condition.typeDemande.idTypeDemande && this.state.condition.libelleCondition.trim() && this.state.condition.contenu.trim()) {

            const newCondition = {
                idCondition : parseInt(this.state.condition.idCondition) ,
                idTypeDemande : parseInt(this.state.condition.typeDemande.idTypeDemande),
                idSession : parseInt(this.state.condition.session.idSession),
                libelleCondition : this.state.condition.libelleCondition,
                contenu : this.state.condition.contenu
            }
           console.log(newCondition)
            if (this.state.condition.idCondition) {
                this.conditionService.update(parseInt(this.state.condition.idCondition), newCondition)
                                .then(data => {
                                    this.notify.success()
                
                                    this.setState({loading : true})
                                    this.conditionService.get(this.state.condition.session.idSession).then(data => 
                                        this.setState({listeContenu: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)  
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    this.setState({loading : false})
                                    console.log(error);
                                })
            }
            else {
                this.conditionService.create(newCondition).then(data => {
                    this.notify.success()
                    this.setState({loading : true})
                    this.conditionService.get(this.state.condition.session.idSession).then(data => 
                        this.setState({listeCondition: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                conditionDialog: false,
                condition: this.emptyCondition
            };
        }

        this.setState(state);



        
    }
    editCondition(condition) {
        this.setState({
            condition,
            conditionDialog: true
        });
    }
    confirmDeleteCondition(condition) {
        this.setState({
            condition,
            deleteConditionDialog: true
        });
    }
    deleteCondition(idCondition) {
        //console.log(idCondition)
        this.conditionService.delete(parseInt(idCondition)).then(data => {
            this.notify.success()
            this.setState({
                deleteConditionDialog: false,
                condition: this.emptyCondition,
            });
            this.setState({loading : true})
            this.conditionService.getAll().then(data => 
                this.setState({listeCondition: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)  
                    console.log(error)
                    this.setState({loading : false})
                })
        })
    }
    onLibelleConditionChange(e, libelleCondition) {
        const val = (e.target && e.target.value) || '';
        let condition = this.state.condition;
        condition[`${libelleCondition}`] = val;

        this.setState({condition });
    }
    onTypeDemandeConditionChange(e, typeDemande) {
        const val = (e.target && e.target.value) || '';
        let condition = this.state.condition;
        condition[`${typeDemande}`] = val;

        this.setState({condition });
    }
    onSessionConditionChange(e, session) {
        const val = (e.target && e.target.value) || '';
        let condition = this.state.condition;
        condition[`${session}`] = val;

        this.setState({condition });
    }
    onContenuConditionChange(e, contenuCondition) {
        const val = (e.target && e.target.value) || '';
        let condition = this.state.condition;
        condition[`${contenuCondition}`] = val;

        this.setState({condition });
    }

    onIdCategorieDemandeChange(e, categorieDemande) {
        const val = (e.target && e.target.value) || '';
        this.setState({ categorieDemande : val });
        this.typeDemandeService.get(val.idCategorieDemande).then(data => {
            this.setState({listeTypeDemande : data});
        })
        .catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message);
        })
    }

    onSessionChange(e, session){
        const val = (e.target && e.target.value) || '';
        let condition = this.state.condition;
        condition[`${session}`] = val;
        this.setState({ session, loading : true });
        this.conditionService.get(val.idSession).then(data => {
            console.log(data);
            this.setState({listeCondition : data, loading : false});
        })
        .catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message);
            this.setState({loading : false})
        })
    }


    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCondition(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteCondition(rowData)} />
            </React.Fragment>
        );
    }
    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const conditionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCondition} />
            </React.Fragment>
        );
        const deleteConditionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteConditionDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCondition(this.state.condition.idCondition) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                <ToastContainer />
                
                <div className="p-col-12">
                <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid p-col-12">
                            <div className="p-col-5" ></div>
                            <div className="p-col-6 p-field">
                                <label htmlFor="libelleSession">Session : </label>
                                <Dropdown className="p-col-4" style={{marginLeft : 10}} optionLabel="libelleSession" optionValue="idSession" value={this.state.condition.session} options={this.state.listeSession} onChange={(e) => {this.onSessionChange(e, 'session')}} required autoFocus  />
                            </div>
                        </div>
                        {header}
                        {this.state.loading ?
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeCondition} selectionMode="single" header="Liste des conditions" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeDemande" header="Type demande"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleSession" header="Session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleCondition" header="Condition"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="contenu" header="Contenu"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                

                <Dialog visible={this.state.conditionDialog} style={{ width: '450px' }} header="Informations contenu" modal className="p-fluid" footer={conditionDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleSession">Session</label>
                        <Dropdown optionLabel="libelleSession" optionValue="idSession" value={this.state.condition.session} options={this.state.listeSession} onChange={(e) => this.onSessionConditionChange(e, 'session')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle session' />
                        {this.state.submitted && !this.state.condition.session.idSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCategorieDemande">Categorie de de demande</label>
                        <Dropdown optionLabel="libelleCategorieDemande" optionValue="idCategorieDemande" value={this.state.categorieDemande} options={this.state.listeCategorieDemande} onChange={(e) => {this.onIdCategorieDemandeChange(e, 'categorieDemande')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.categorieDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleTypeDemande">Type demande</label>
                        <Dropdown optionLabel="libelleTypeDemande" optionValue="idTypeDemande" value={this.state.condition.typeDemande} options={this.state.listeTypeDemande} onChange={(e) => this.onTypeDemandeConditionChange(e, 'typeDemande')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle type demande' />
                        {this.state.submitted && !this.state.condition.typeDemande.idTypeDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="libelleCondition">Libelle condition *</label>
                        <InputText id="libelleCondition" value={this.state.condition.libelleCondition}  onChange={(e) => this.onLibelleConditionChange(e, 'libelleCondition')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle condition'/>
                        {this.state.submitted && !this.state.condition.libelleCondition && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="contenuCondition">Contenu *</label>
                        <InputTextarea id="contenuCondition" value={this.state.condition.contenu}  onChange={(e) => this.onContenuConditionChange(e, 'contenu')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Contenu condition'/>
                        {this.state.submitted && !this.state.condition.contenu && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteConditionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteConditionDialogFooter} onHide={this.hideDeleteConditionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.condition && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                </div>
            </div> 
        )
    }
}

export default Condition
