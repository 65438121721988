import React, {Component} from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
//import logoCampusFaso from './images/CampusFaso_Logo.png'
import logoCampusFaso from './images/campusfaso_logo_wb.png'
import './css/style.css'
import  './css/themify-icons.css'

export class AppTopbar extends Component {


	constructor() {
		super();
		this.state = {
			token : null
		};

	}

	
	componentDidMount = () => {
        
		const tokenDecoded = localStorage.getItem('tokenDecoded')
		console.log(tokenDecoded)
		const token = JSON.parse(tokenDecoded)
        this.setState({token : token})
		
        
    }
	render() {
		
			//console.log(this.state.token)
		//let topbarItemsClassName = classNames('topbar-menu fadeInDown', {'topbar-menu-visible': this.props.topbarMenuActive});

		return (
                <div className="grid-container">
                    <header className="header">
                        {this.state.token ?
                        <div className="header-text">
                            <i className="ti-user"></i>
                                    Bienvenue <a href="#">m.kaboret</a> | 
                                    <a href="#"> 
                                        <i className="ti-close"></i> 
                                        Déconnexion
                                    </a> |
                                    <a href="#" onClick={()=>window.location.reload()} className="btn">
                                           <i className="ti-home"></i> 
                                        Accueil
                                    </a>
                                </div>
                                :
                                ""
                        }
                    </header>
                    <aside className="sidenav">
                        <div className="logo">
                            <img src={logoCampusFaso} alt=""/>
                            <div className="txt">
                                Plateforme intégrée de <br/>
                                l’enseignement supérieur <br/>
                                et de la recherche
                            </div>
                        </div>
                    </aside>      
                    
                            </div>
                        
		)
	}
}
export default AppTopbar