import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { TypeFormationService } from '../../service/TypeFormationService';

export class TypeFormation extends Component {
    emptyTypeFormation = {
        idTypeFormation: null,
        libelleTypeFormation: ""  
    }
    constructor() {
        super();
        this.state = {
            listeTypeFormation : [],
            typeFormationDialog: false,
            deleteTypeFormationDialog: false,
            typeFormation: this.emptyTypeFormation,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }
        this.typeFormationService = new TypeFormationService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveTypeFormation = this.saveTypeFormation.bind(this);
        this.editTypeFormation = this.editTypeFormation.bind(this);
        this.confirmDeleteTypeFormation = this.confirmDeleteTypeFormation.bind(this);
        this.deleteTypeFormation = this.deleteTypeFormation.bind(this);
        this.onLibelleTypeFormationChange = this.onLibelleTypeFormationChange.bind(this);
        this.hideDeleteTypeFormationDialog = this.hideDeleteTypeFormationDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.typeFormationService.getAll().then(data => 
            this.setState({listeTypeFormation: data, loading : false})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
    }
    openNew() {
        this.setState({
            typeFormation: this.emptyTypeFormation,
            submitted: false,
            typeFormationDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            typeFormationDialog: false
        });
    }
    hideDeleteTypeFormationDialog() {
        this.setState({ deleteTypeFormationDialog: false });
    }
    saveTypeFormation() {
        let state = { submitted: true };

        if (this.state.typeFormation.libelleTypeFormation.trim()) {

            const newTypeFormation = {
                idTypeFormation : parseInt(this.state.typeFormation.idTypeFormation) ,
                libelleTypeFormation : this.state.typeFormation.libelleTypeFormation
            }
            //console.log(newTypeFormation)
            if (this.state.typeFormation.idTypeFormation) {
                this.typeFormationService.update(parseInt(this.state.typeFormation.idTypeFormation), newTypeFormation)
                                .then(data => {
                                    this.notify.success()
                                    //console.log(data)
                                    this.setState({redirect : true})
                                    this.typeFormationService.getAll().then(data => 
                                        this.setState({listeTypeFormation: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)  
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.typeFormationService.create(newTypeFormation).then(data => {
                    this.notify.success()
                    this.setState({redirect : true})
                    this.typeFormationService.getAll().then(data => 
                        this.setState({listeTypeFormation: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                typeFormationDialog: false,
                typeFormation: this.emptyTypeFormation
            };
        }

        this.setState(state);
    }
    editTypeFormation(typeFormation) {
        this.setState({
            typeFormation,
            typeFormationDialog: true
        });
    }
    confirmDeleteTypeFormation(typeFormation) {
        this.setState({
            typeFormation,
            deleteTypeFormationDialog: true
        });
    }
    deleteTypeFormation(idTypeFormation) {
        this.typeFormationService.delete(parseInt(idTypeFormation)).then(data => {
            this.notify.success()
            this.setState({
                deleteTypeFormationDialog: false,
                typeFormation: this.emptyTypeFormation,
                redirect : true
            });
            this.typeFormationService.getAll().then(data => 
                this.setState({listeTypeFormation: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)  
                    console.log(error)
                    this.setState({loading : false})
                })
        })
    }
    onLibelleTypeFormationChange(e, libelleTypeFormation) {
        const val = (e.target && e.target.value) || '';
        let typeFormation = this.state.typeFormation;
        typeFormation[`${libelleTypeFormation}`] = val;

        this.setState({typeFormation });
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeFormation(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeFormation(rowData)} />
            </React.Fragment>
        );
    }
    render() {
        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const typeFormationDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeFormation} />
            </React.Fragment>
        );
        const deleteTypeFormationDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteTypeFormationDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeFormation(this.state.typeFormation.idTypeFormation) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeTypeFormation} selectionMode="single" header="Liste des types de formations" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeFormation" header="Type formation" />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.typeFormationDialog} style={{ width: '450px' }} header="Informations type formation" modal className="p-fluid" footer={typeFormationDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleTypeFormation">Type formation</label>
                        <InputText id="libelleTypeFormation" value={this.state.typeFormation.libelleTypeFormation}  onChange={(e) => this.onLibelleTypeFormationChange(e, 'libelleTypeFormation')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.typeFormation.libelleTypeFormation && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteTypeFormationDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeFormationDialogFooter} onHide={this.hideDeleteTypeFormationDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.typeFormation && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default TypeFormation
