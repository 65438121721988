import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import {Checkbox} from 'primereact/checkbox';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

import { ParametreService } from '../../service/ParametreService';
import { EntiteService } from '../../service/EntiteService';
import { EntiteParametreService } from '../../service/EntiteParametreService';

const EntiteParametre = () => {
    
    let emptyEntiteParametre = {
        idParametreEntite : null,
        valeurPropriete : null,
        observation : "",
        entite : null,
        parametre : null,

    }
    const [parametreEntites, setParametreEntites] = useState([])
    const [entites, setEntites] = useState(null)
    const [entite, setEntite] = useState(null)
    const [parametres, setParametres] = useState(null)
    const [parametreEntite, setParametreEntite] = useState(emptyEntiteParametre)
    const [parametreEntiteDialog, setParametreEntiteDialog] = useState(false)
    const [deleteParametreEntiteDialog, setDeleteParametreEntiteDialog] = useState(false)
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [choixValeur, setChoixValeur] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedParametreEntites, setSelectedParametreEntites] = useState([]);
    const [checked, setChecked] = useState([]);
    const [parametreDetailEntiteDialog , setParametreDetailEntiteDialog] = useState(false)
    const dt = useRef(null);
    const parametreService = new ParametreService();
    const entiteService = new EntiteService();
    const entiteParametreService = new EntiteParametreService();
    const notify = new Notify();
    const spinner = new Spinner();

    useEffect(() => {
        //entiteParametreService.getAll().then(data => setParametreEnties(data))
        setLoading(true)
        entiteService.getAll().then(data => {
            setEntites(data)
            parametreService.getAll().then(data => setParametres(data))
            setLoading(false);
            //notify.success();
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            notify.echec(error.response.data.message);
        })
        
    }, []);

    const openNew = () => {
        setParametreEntite(emptyEntiteParametre);
        setChoixValeur([]);
        setSubmitted(false);
        setParametreEntiteDialog(true);
    }
    
    const hideDialog = () => {
        setSubmitted(false);
        setParametreEntiteDialog(false);
    }
    const hideDialogDetail = () => {
        setSubmitted(false);
        setParametreDetailEntiteDialog(false);
    }
    
    
    const hideDeleteParametreEntiteDialog = () => {
        setDeleteParametreEntiteDialog(false);
    }

    const saveParametreEntite = () => {
        setSubmitted(true);
    
        if (parametreEntite.entite.idEntite && parametreEntite.parametre.idParametre && parametreEntite.valeurPropriete.trim()) {

            let _parametreEntite = choixValeur;
             
            if (parametreEntite.idParametreEntite) {
                entiteParametreService.update(parametreEntite.idParametreEntite, _parametreEntite).then(data => {
                    console.log(data)
                    entiteParametreService.getAll().then(data => setParametreEntites(data));
                    notify.success();
                    setChoixValeur([]);
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                entiteParametreService.create(_parametreEntite).then(data => {
                    console.log(data)
                    entiteParametreService.getAll().then(data => setParametreEntites(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                
            }

            setParametreEntiteDialog(false);
            setParametreEntite(emptyEntiteParametre);
        }
    }
    const editParametreEntite = () => {
        //console.log(entite.idEntite)
        console.log(parametreEntites)

        entiteParametreService.update(parametreEntites).then(data => {
            console.log(data)
            setParametreEntites([]);
            setParametreDetailEntiteDialog(false);
            notify.success()
        })
        .catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
        
    }
    
    const formDeleteParametreEntite = (_entite) => {
        setEntite(_entite)
        setDeleteParametreEntiteDialog(true);
        entiteParametreService.getListeParametreEntites(_entite.idEntite).then(data => {
            setParametreEntites(data)
            //console.log(data)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
    }

    const onParametreEntiteChange = (e) => {
        let _selectedParametreEntites = [...selectedParametreEntites];

        if (e.checked) {
            _selectedParametreEntites.push(e.value);
        }
        else {
            for (let i = 0; i < _selectedParametreEntites.length; i++) {
                const selectedParametreEntite = _selectedParametreEntites[i];

                if (selectedParametreEntite.idParametreEntite === e.value.idParametreEntite) {
                    _selectedParametreEntites.splice(i, 1);
                    break;
                }
            }
        }
        console.log(_selectedParametreEntites)
        setSelectedParametreEntites(_selectedParametreEntites);
    }
    
    const deleteParametreEntite = () => {
        //console.log(selectedParametreEntites)
        entiteParametreService.delete(selectedParametreEntites).then(data => {
            //entiteParametreService.getAll().then(data => setParametreEntites(data))
            setSelectedParametreEntites([]);
            notify.success();
            console.log(data)
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setDeleteParametreEntiteDialog(false);
        
    }
    
    const onChampsChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _parametreEntite = {...parametreEntite};
        _parametreEntite[`${name}`] = val;
    
        setParametreEntite(_parametreEntite);
    }

    const onChampsMultipleChange = (e, name, index) => {
        const val = (e.target && e.target.value) || '';
        let _parametreEntites = [...parametreEntites];
        _parametreEntites[index][`${name}`] = val;
    
        setParametreEntite(_parametreEntites);
    }

    const choixValeurPropriete = () => {

        //let verife = Object.keys(parametreEntite).length === 0;
        //console.log(verife)
        //if(verife){
            let id  = createId();
            let idEntite = parametreEntite.entite.idEntite
            let libelleEntite = parametreEntite.entite.libelleEntite
            let idParametre = parametreEntite.parametre.idParametre
            let valeur = parametreEntite.parametre.valeur
            let valeurPropriete = parametreEntite.valeurPropriete
            let observation = parametreEntite.observation
            
            if(id && idEntite && idParametre && valeurPropriete !== ""){
                let choix = { id, idEntite, libelleEntite, idParametre, valeur, valeurPropriete, observation}
                let listeChoix = [...choixValeur,choix]
                setChoixValeur(listeChoix)
            }
            else {
                notify.echec();
                console.log("Veuillez renseigner les champs obligatoire !")
            }
        /*}
        else {
            notify.echec();
            console.log("Veuillez renseigner les champs obligatoire !")
        }*/
        
        
    }

    const removeChoix = (id) => {
        console.log(id)
        let listeChoix = choixValeur.filter(function(item) {
            console.log(item.id)
            return item.id !== id
        });
        console.log(listeChoix)
        setChoixValeur(listeChoix)
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        console.log(id)
        return id;
    }

    const detailParametreEntites = (_entite) => {
       setEntite(_entite)
        setParametreDetailEntiteDialog(true);
        
        //setLoading(true)
        entiteParametreService.getListeParametreEntites(_entite.idEntite).then(data => {
            setParametreEntites(data)
            console.log(data)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
        
        
    }
        

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => detailParametreEntites(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => formDeleteParametreEntite(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }
    
    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche globale..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );
    const parametreEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveParametreEntite} />
        </React.Fragment>
    );
    const parametreDetailEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialogDetail} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={editParametreEntite} />
        </React.Fragment>
    )
    const deleteParametreEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDeleteParametreEntiteDialog} />
            <Button label="Supprimer" icon="pi pi-check" className="p-button-text" onClick={deleteParametreEntite} />
        </React.Fragment>
    );
    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return(
        <div className="">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="card">
                {header}
                {loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                </div>
                :
                <DataTable ref={dt} value={entites} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des entités">

                    <Column field="libelleEntite" header="Entité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>
            <Dialog visible={parametreEntiteDialog} style={{ width: '850px' }} header="Informations paramètre-entité" modal className="p-fluid" footer={parametreEntiteDialogFooter} onHide={hideDialog}>
            <div className="p-grid p-col-12">
                <div className="p-clo-7 p-fieldset">
                    <div className="p-field">
                        <label htmlFor="libelleEntite">Entité</label>
                        <Dropdown optionLabel="libelleEntite" optionValue="idEntite" value={parametreEntite.entite} options={entites} onChange={(e) => onChampsChange(e, 'entite')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.entite })} placeholder="Selectionner une entité"/>
                        {submitted && !parametreEntite.entite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                            <label htmlFor="valeur">Paramètre</label>
                            <Dropdown optionLabel="valeur" optionValue="idParametre" value={parametreEntite.parametre} options={parametres} onChange={(e) => onChampsChange(e, 'parametre')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.parametre })} placeholder="Selectionner un paramètre"/>
                            {submitted && !parametreEntite.parametre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                            <label htmlFor="valeurPropriete">Valeur propriété</label>
                            <InputText id="valeurPropriete" value={parametreEntite.valeurPropriete} onChange={(e) => onChampsChange(e, 'valeurPropriete')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.valeurPropriete })} />
                            {submitted && !parametreEntite.valeurPropriete && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="observation">Observation</label>
                        <InputText id="observation" value={parametreEntite.observation} onChange={(e) => onChampsChange(e, 'observation')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.observation })} />
                        {submitted && !parametreEntite.observation && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="" style={{marginTop : 5}}>
                        <Button onClick={choixValeurPropriete} icon="pi pi-plus" type="button" label="Ajouter" className="p-button-success p-mr-2" />
                    </div>
                </div>
                <div className="p-col-1"></div>
                <div className="p-col-6 p-fieldset">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Propriété</th>
                                <th>valeur</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            choixValeur.map(choix =>
                                <tr>
                                    <td>{choix.valeur}</td>
                                    <td>{choix.valeurPropriete}</td>
                                    <td><Button icon="pi pi-trash" className="p-button-danger" onClick={() => {removeChoix(choix.id)}} /></td>
                                </tr>
                            )
                        }
                        </tbody>      
                    </table>
                </div>
            </div>
  
                {/*<div className="p-field">
                    <label htmlFor="cle">Clé</label>
                    <InputText id="cle" value={parametre.cle} onChange={(e) => onChampsChange(e, 'cle')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametre.cle })} />
                    {submitted && !parametre.cle && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="valeur">Propriété</label>
                    <InputText id="valeur" value={parametre.valeur} onChange={(e) => onChampsChange(e, 'valeur')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametre.valeur })} />
                    {submitted && !parametre.valeur && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            
            <div className="p-field">
                <label htmlFor="observation">Observations</label>
                <InputTextarea id="observation" value={parametre.observation} onChange={(e) => onChampsChange(e, 'observation')} required rows={3} cols={20} />
            </div>*/}
        </Dialog>
        <Dialog visible={parametreDetailEntiteDialog} style={{ width: '850px' }} header="Informations paramètre-entité" modal className="p-fluid" footer={parametreDetailEntiteDialogFooter} onHide={hideDialogDetail}>
            <div className="p-grid">
                <div className="p-field p-col-6" >
                    <label htmlFor="libelleEntite">Entité</label>
                    <Dropdown optionLabel="libelleEntite" desabled optionValue="idEntite" value={entite} options={entites} onChange={(e) => onChampsChange(e, 'entite')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.entite })} placeholder="Selectionner une entité"/>
                    {submitted && !parametreEntite.entite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                </div>
            </div>
            {parametreEntites.map (function(item, index){
                
                return (
                <div className="p-grid">
                    <div className="p-field p-col-4">
                        <label htmlFor="valeur">Paramètre</label>
                        <InputText value={item.valeur} disabled />
                    </div>
                    <div className="p-field p-col-4">
                        <label htmlFor={"valeurPropriete"}>Valeur propriété</label>
                        <InputText id={"valeurPropriete"} value={item.valeurPropriete} onChange={(e) => onChampsMultipleChange(e, 'valeurPropriete', index)} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.valeurPropriete })} />
                        {submitted && !parametreEntite.valeurPropriete && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field p-col-4">
                        <label htmlFor={"observation"}>Observation</label>
                        <InputText id={"observation"} value={item.observation} onChange={(e) => onChampsMultipleChange(e, 'observation', index)} required autoFocus className={classNames({ 'p-invalid': submitted && !parametreEntite.observation })} />
                        {submitted && !parametreEntite.observation && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                
                </div> )
                
            })
        }
                
        </Dialog>

        <Dialog visible={deleteParametreEntiteDialog} style={{ width: '650px' }} header="Confirmer" modal footer={deleteParametreEntiteDialogFooter} onHide={hideDeleteParametreEntiteDialog}>
            <div className="p-field">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Propriété</th>
                                <th>valeur</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            parametreEntites.map(function(item, index){
                                return (
                                <tr>
                                    <td>{item.valeur}</td>
                                    <td>{item.valeurPropriete}</td>
                                    <td><Checkbox inputId={"cata"+item.idParametreEntite} name="param" value={item} onChange={onParametreEntiteChange} checked={selectedParametreEntites.some((val) => val.idParametreEntite === item.idParametreEntite)} /></td>
                                </tr>
                                )
                            })
                        }
                        </tbody>      
                    </table>
                </div>
        </Dialog>

    </div>
    )
}

export default EntiteParametre