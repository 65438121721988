import axios from "axios";
import {AdresseIP} from '../../components/AdresseIP';

export default class StatutService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/statuts-enseignants"
    }

    /**
     * 
     * @returns la liste complete des statuts enseignant
     * 
     */

    getAll(){ 
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }


    /**
     * 
     * @param {*} ListStatut liste d'objects de type statut
     * @returns undefined
     * enregistre la liste des statuts des enseignants dans la base de données
     */
    create(ListStatut){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,ListStatut).then(response=>response.status)
    }

    /**
     * 
     * @param {*} statut 
     * @returns undefined
     * mise à jour des informations concernants un statuts pour enseignant
     */
    update(statut){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${statut.idStatutEnseignant}`,statut).then(response=>response.status);
    }

    /**
     * 
     * @param {*} statutId 
     * @returns undefined
     * suppression logique du statut dont l'identifiant est passé en paramètres
     */
    delete(statutId){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${statutId}`).then(response=>response.status);
    }
}