import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { MentionService } from '../../service/MentionService';
import { TypeFinaliteService } from '../../service/TypeFinaliteService';
import { TypeFormationService } from '../../service/TypeFormationService';
import { CycleService } from '../../service/CycleService';
import { Dropdown } from 'primereact/dropdown';
import { DomaineService } from '../../service/DomaineService';
import { SpecialiteService } from '../../service/SpecialiteService';


export class Specialite extends Component {
    
    emptySpecialite = {
        idSpecialite: null,
        libelleSpecialite: '',
        sigleSpecialite: '',
        observations: '',
        cycle: null,
        typeFinalite: null,
        typeFormation: null,
        mention: null,
        choixCalculNote : null
    }

    constructor(){
        super();

        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelMention = new MentionService();
        this.ModelDomaine = new DomaineService();
        this.ModelTypeFormation = new TypeFormationService();
        this.ModelTypeFinalite = new TypeFinaliteService();
        this.ModelCycle = new CycleService();
        this.ModelSpecialite = new SpecialiteService();

        this.state = {
            curentSpecialite: this.emptySpecialite,
            typeFormationList: [],
            typeFinaliteList: [],
            cycleList: [],
            mentionList: [],
            specialiteList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateSpecialite: false,
            allowSave: false,
            viewDeleteSpecialiteDialog: false,
            deletionId: null,
            viewSelectMentionDialog: false,
            listChoixCalculNote : [
                {
                    choix : "Calcul moyenne par les credits",
                    value : false
                },
                {
                    choix : "Calcul moyenne par les coefficients",
                    value : true
                },
            ]
        }
    }


    
    componentDidMount(){

        //Temoin de chargement
        this.setState({loading : true})

        //Liste des specialités
        this.ModelSpecialite.getAll().then(data => {
            //console.log(data)
            this.setState({
                specialiteList: data
            })
            this.setState({loading : false})
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({loading : false})
        })

        //On recupère la liste des mentions
        this.ModelMention.getAllSansObjet().then(data => {
            this.setState({
                mentionList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })


        //On recupère la liste des type formation
        this.ModelTypeFormation.getAll().then(data => {
            this.setState({
                typeFormationList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })


        //On recupère la liste des type finalites
        this.ModelTypeFinalite.getAll().then(data => {
            this.setState({
                typeFinaliteList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })


        this.ModelCycle.getAll().then(data => {

            this.setState({
                cycleList: data
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                viewCreateSpecialite: true
            }
        )
    }

    hideDialog = () => {
        // Reinitialiser l'objet courant
        let emptySpecialite = {
            idSpecialite: null,
            sigleSpecialite: '',
            observation: '',
            cycle: null,
            mention: null,
            typeFinalite: null,
            typeFormation: null,
            libelleSpecialite: ''
        }

        this.setState({
            viewCreateSpecialite: false,
            curentSpecialite: emptySpecialite
        })
    }


    onDropDownChange = (event, field) => {

        let curentSpecialite = this.state.curentSpecialite;

        curentSpecialite[`${field}`] = event.target.value

        this.setState(
            {
                curentSpecialite: curentSpecialite,
            }
        )

        let allowSave = this.isValidabled(curentSpecialite)
        this.setState({
            allowSave: allowSave

        })
    }


    selectionMention = (metion) => {
        let curentSpecialite = this.state.curentSpecialite;

        curentSpecialite["mention"] = metion
        let allowSave = this.isValidabled(curentSpecialite)

        this.setState({
            allowSave: allowSave,
            curentSpecialite: curentSpecialite,
            viewSelectMentionDialog: false
        })
    }

    onInputSpecialiteChange = (event, field) => {

        let curentSpecialite = this.state.curentSpecialite

        //On change la valeur concernée
        curentSpecialite[`${field}`] = event.target.value

        //On met à jour le state
        this.setState({
            curentSpecialite: curentSpecialite
        })

        let allowSave = this.isValidabled(curentSpecialite)
        this.setState({
            allowSave: allowSave
        })
    }

    saveSpecialite = () => {

        let curentSpecialite = {
            idSpecialite: parseInt(this.state.curentSpecialite.idSpecialite),
            sigleSpecialite: this.state.curentSpecialite.sigleSpecialite,
            libelleSpecialite: this.state.curentSpecialite.libelleSpecialite,
            observations: this.state.curentSpecialite.observations,
            idTypeFormation: this.state.curentSpecialite.typeFormation.idTypeFormation,
            idTypeFinalite: this.state.curentSpecialite.typeFinalite.idTypeFinalite,
            idCycle: this.state.curentSpecialite.cycle.idCycle,
            idMention: this.state.curentSpecialite.mention.idMention,
            fgDeliberationCoefficient : this.state.curentSpecialite.choixCalculNote.value
        }

        //console.log(curentSpecialite)

        this.setState({submitted: true})
        if(curentSpecialite.idSpecialite){

            //Mise à jour
            this.ModelSpecialite.update(curentSpecialite.idSpecialite, curentSpecialite).then(data => {
                this.notify.success();
                this.setState({
                    viewCreateSpecialite:false,
                    submitted: false
                })
                this.ModelSpecialite.getAll().then(data => {
                    this.setState({
                        specialiteList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }else{

            //Création de la spécialité
            this.ModelSpecialite.create(curentSpecialite).then(data => {
                this.notify.success();
                this.setState({
                    viewCreateSpecialite: false,
                    submitted: false
                })
                this.ModelSpecialite.getAll().then(data => {
                    this.setState({
                        specialiteList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }


    }

    //Fonction permettant de tester si le formulaire est en état d'être validé
    isValidabled = (specialite) => {
        return ( 
             (specialite.libelleSpecialite.trim()).length>=5
             && 
             (specialite.sigleSpecialite.trim()).length>=2 
             && 
             specialite.cycle!==null 
             && 
             specialite.cycle!==undefined
             &&
             specialite.typeFinalite!==null
             &&
             specialite.typeFinalite!==undefined
             &&
             specialite.typeFormation!==null
             &&
             specialite.typeFormation!==undefined
             && 
             specialite.mention!==null
             &&
             specialite.mention!==undefined
             &&
             specialite.choixCalculNote!==null
             &&
             specialite.choixCalculNote!==undefined
            )? true:false
    }

    editSpecialite = (curentSpecialite) => {
        let choixCalcul = {}
        if (curentSpecialite.fgDeliberationCoefficient === false){
            choixCalcul = {
                
                    choix : "Calcul moyenne par les credits",
                    value : false
            }
        }
        else {
            choixCalcul = {
                choix : "Calcul moyenne par les coefficients",
                value : true
            }
        }
        
        curentSpecialite.choixCalculNote = choixCalcul
    
        this.setState({
            curentSpecialite: curentSpecialite,
            viewCreateSpecialite: true
        })

        //Differer le critère de validation pour s'assurer que les valeurs sont là
        let allowSave = this.isValidabled(curentSpecialite)
        this.setState({
            allowSave: allowSave
        })
        

    }

    hideDeleteSpecialiteDialog = () => {
        this.setState({
            viewDeleteSpecialiteDialog: false,
            deletionId: null
        })
    }

    confirmDeleteSpecialite = (data) => {

        this.setState({
            deletionId: data.idSpecialite,
            viewDeleteSpecialiteDialog: true
        })

    }
    
    deleteSpecialite = (deletionId) =>{
        
        this.ModelSpecialite.delete(parseInt(deletionId)).then(data => {
            this.notify.success()
            this.setState({
                viewDeleteSpecialiteDialog: false,
                redirect: true
            })
            this.ModelSpecialite.getAll().then(data => {
                this.setState({
                    specialiteList: data,
                    loading : false
                })
            })
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({loading : false})
            })
        }).catch(error => {
            this.notify.echec(error.response.data.message)
            this.setState({
                viewDeleteSpecialiteDialog: false,
            })
            console.log(error)
        })

    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editSpecialite(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteSpecialite(rowData)} />
            </React.Fragment>
        );
    }
 
    hideSelectMentionDialog = () => {
        this.setState({
            viewSelectMentionDialog: false
        })
    }


    actionSelectMentionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={() => this.selectionMention(rowData)} title='Sélectionner la mention'/>
            </React.Fragment>
        );
    }

    
    render() {

        if(this.state.redirect) {
            //window.location.reload();
        }

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const selectMentionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectMentionDialog} />
            </React.Fragment>
        )
        
        const deleteSpecialiteDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSpecialiteDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteSpecialite(this.state.deletionId) } />
            </React.Fragment>
            )

            
        

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSpecialite} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const specialiteDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );
        
       let actionHeader = <Button type="button" icon="pi pi-cog"/>
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
                        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.specialiteList} selectionMode="single" header={`Liste des spécialités (${this.state.specialiteList.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                {/*<Column field="idSpecialite" header="Id Specialite"  sortable={true} filter={true} filterPlaceholder="rechercher spécialité"/>*/}
                                <Column field="sigleSpecialite" header="Sigle spécialité"  sortable={true} filter={true} filterPlaceholder="rechercher sigle spécialité"/>
                                <Column field="libelleSpecialite" header="Spécialité"  sortable={true} filter={true} filterPlaceholder="rechercher spécialité"/>
                                <Column field="mention.libelleMention" header="Mention de la formation"  sortable={true} filter={true} filterPlaceholder="rechercher mention"/>
                                <Column field="typeFormation.libelleTypeFormation" header="Type formation"  sortable={true} filter={true} filterPlaceholder="rechercher type formation"/>
                                <Column field="typeFinalite.libelleTypeFinalite" header="Type finalité"  sortable={true} filter={true} filterPlaceholder="rechercher type finalité"/>
                                <Column field="cycle.libelleCycle" header="Cycle de la spécialite"  sortable={true} filter={true} filterPlaceholder="rechercher cycle"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                                
                            </DataTable>
                            }
                        </div>

                    </div>

                <Dialog visible={this.state.viewCreateSpecialite} style={{ width: '90%' }} header="Informations de la spécialité" modal className="p-fluid" footer={specialiteDialogFooter} onHide={this.hideDialog}>
                    
                    
                   <div className="p-field">
                        <label htmlFor="libelleMention"><span style={{color: 'red'}}>*</span> Mention</label>
                        <div className="p-grid p-lg-12">
                            <div className="p-field p-col-11">
                                <Dropdown optionLabel="libelleMention" optionValue="idMention" value={this.state.curentSpecialite.mention} options={this.state.mentionList} onChange={(e) => {this.onDropDownChange(e, 'mention')}} required autoFocus className={classNames({'p-invalid': this.state.submitted})} placeholder="Selectionner la mention" />
                                {this.state.submitted && !this.state.curentSpecialite.mention && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                            <div className="p-field p-col-1">
                                <Button type="button" icon="pi pi-search" className="p-button-info" onClick={ () => this.setState({viewSelectMentionDialog: true})} />
                            </div>
                        </div>
                   </div>
                    
                    <hr/>

                    <div className="p-field">
                        <label htmlFor="libelleCycle"><span style={{color: 'red'}}>*</span> Cycle</label>
                        <Dropdown optionLabel="libelleCycle" optionValue={this.state.curentSpecialite.cycle} value={this.state.curentSpecialite.cycle} options={this.state.cycleList} onChange={(e) => {this.onDropDownChange(e, 'cycle')}} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le cycle"/>
                        {this.state.submitted && !this.state.curentSpecialite.cycle && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="libelleTypeFormation"><span style={{color: 'red'}}>*</span> Type formation</label>
                        <Dropdown optionLabel="libelleTypeFormation" optionValue={this.state.curentSpecialite.typeFormation} value={this.state.curentSpecialite.typeFormation} options={this.state.typeFormationList} onChange={(e) => {this.onDropDownChange(e, 'typeFormation')}} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le type de formation"/>
                        {this.state.submitted && !this.state.curentSpecialite.cycle && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="libelleTypeFinalite"><span style={{color: 'red'}}>*</span> Type finalite</label>
                        <Dropdown optionLabel="libelleTypeFinalite" optionValue={this.state.curentSpecialite.typeFinalite} value={this.state.curentSpecialite.typeFinalite} options={this.state.typeFinaliteList} onChange={(e) => {this.onDropDownChange(e, 'typeFinalite')}} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le type finalité"/>
                        {this.state.submitted && !this.state.curentSpecialite.cycle && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>

                    <div className="p-field">
                        <label htmlFor="sigleSpecialite"><span style={{color: 'red'}}>*</span> Sigle spécialité</label>
                        <InputText id="sigleSpecialite" value={this.state.curentSpecialite.sigleSpecialite}  onChange={(e) => this.onInputSpecialiteChange(e, 'sigleSpecialite')} required className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Sigle spécialité" />
                        {this.state.submitted && !this.state.curentSpecialite.sigleSpecialite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit compter au moins deux caractères.</small>}
                    </div>

                    <hr/>

                    <div className="p-field">
                        <label htmlFor="libelleSpecialite"><span style={{color: 'red'}}>*</span> Specialité</label>
                        <InputText id="libelleSpecialite" value={this.state.curentSpecialite.libelleSpecialite}  onChange={(e) => this.onInputSpecialiteChange(e, 'libelleSpecialite')} required  className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Libellé spécialité" />
                        {this.state.submitted && !this.state.curentSpecialite.libelleSpecialite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit compter au moins cinq caractères.</small>}
                    </div>

                    <hr/>

                    <div className="p-field">
                        <label htmlFor="choixCalculNote"><span style={{color: 'red'}}>*</span> Choix calcul des notes</label>
                        <Dropdown optionLabel="choix" value={this.state.curentSpecialite.choixCalculNote} options={this.state.listChoixCalculNote} onChange={(e) => {this.onDropDownChange(e, 'choixCalculNote')}} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder=""/>
                        {this.state.submitted && !this.state.curentSpecialite.choixCalculNote && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>

                    <hr/>

                    <div className="p-field">
                        <label htmlFor="observation">Observation</label>
                        <textarea id="observation" value={this.state.curentSpecialite.observations} onChange={(e) => this.onInputSpecialiteChange(e, 'observations')}  placeholder="Observation sur la spécialité" className='p-inputtext p-component'/>
                    </div>

                </Dialog>


                <Dialog visible={this.state.viewDeleteSpecialiteDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSpecialiteDialogFooter} onHide={this.hideDeleteSpecialiteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.viewSelectMentionDialog} style={{ width: '75%', height: 'auto' }} header="Sélection de la mention" selectionMode="single" modal className="p-fluid" footer={selectMentionDialogFooter} onHide={this.hideSelectMentionDialog} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                    <DataTable ref={(el) => this.dt = el} value={this.state.mentionList} header="Liste des mentions disponibles" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="libelleMention" header="Mention"  sortable={true} filter={true} filterPlaceholder="rechercher une mention"/>
                        <Column header={actionHeader} body={this.actionSelectMentionBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                </Dialog>


            </div> 
        )
}

}

export default Specialite
