import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class DomaineService {
     constructor(){
         this.adresse = new AdresseIP()
     }


    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/domaines"
        return axios.get(url).then(res => res.data) 
    }
    

    
    create(newDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/domaines"
        return axios.post(url,newDomaine)
                    .then(res => res.data);
    }

    
    update(idDomaine ,newDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/domaines/"
        return axios.put(url+idDomaine, newDomaine)
                    .then(res => res.data);
    }

    
    delete(idDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/domaines/"
        return axios.patch(url+idDomaine)
                    .then(res => res.data)
    }
    
}