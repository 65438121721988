import React, {useState, useEffect, useRef} from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { ToastContainer} from 'react-toastify';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { CritereService } from '../../service/CritereService';
import { TypeCritereService } from '../../service/TypeCritereService';
import { SessionService } from '../../service/SessionService';

const Critere = () => {
    let emptyCritere = {
        idCritere : null,
        libelleCritere : "",
        coefficient : null,
        obligatoire : false,
        observation : "",
        typeCritere : {}
    }

    const [criteres, setCriteres] = useState([]);
    const [typeCriteres, setTypeCriteres] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [session, setSession] = useState({});
    const [critere, setCritere] = useState(emptyCritere);
    const [structure, setStructure] = useState({});
    const [selectedCriteres, setSelectedCriteres] = useState(null);
    const [critereDialog, setCritereDialog] = useState(false);
    const [deleteCritereDialog, setDeleteCritereDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const dt = useRef(null);
    
    const notify = new Notify();
    const spinner = new Spinner();

    const critereService = new CritereService();
    const typeCritereService = new TypeCritereService();
    const sessionService = new SessionService();

    useEffect( () => {
        let today = new Date();
        let year = today.getFullYear();
        const structure = localStorage.getItem('structure')
        const struct = JSON.parse(structure)
        setStructure(struct);
        sessionService.get(year, 385, 'NIVEAU_III',"OUVERT","").then(data => {
            setSessions(data);
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        }) 

    }, [] );

    const openNew = () => {
        setCritere(emptyCritere);
        setSubmitted(false);
        setCritereDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCritereDialog(false);
    }

    const hideDeleteCritereDialog = () => {
        setDeleteCritereDialog(false);
    }

    const saveCritere = () => {
        setSubmitted(true);
        if(critere.typeCritere.idTypeCritere && critere.libelleCritere) {
            
            const newCritere = {
                idCritere : critere.idCritere,
                libelleCritere : critere.libelleCritere,
                coefficient : parseInt(critere.coefficient),
                obligatoire : critere.obligatoire,
                observation : critere.observation,
                idTypeCritere : critere.typeCritere.idTypeCritere
            }
            if(critere.idCritere){
                critereService.update(critere.idCritere, newCritere).then(data => {
                    console.log(data);
                    //critereService.getAll().then(data => setCriteres(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                console.log(newCritere);
                critereService.create(newCritere).then(data => {
                    console.log(data);
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
        }
        setCritere(emptyCritere);
        setCritereDialog(false);
    }

    const editCritere = (critere) => {
        setCritere(critere);
        setCritereDialog(true);
    }

    const confirmDeleteCritere = (critere) => {
        setCritere(critere);
        setDeleteCritereDialog(true);
    }

    const deleteCritere = () => {
        critereService.delete(critere.idCritere).then(data => {
            console.log(data);
            critereService.getAll().then(data => setCriteres(data));
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setCritereDialog(false);
    }

    const onChampsChange = (e, name) => {
        let val = (e.target && e.target.value) || "";
        let _critere = {...critere}
        _critere[`${name}`] = val;

        setCritere(_critere);

    }

    const onSessionChange = (e, session) => {
        const val = (e.target && e.target.value) || '';
        setSession(val);
        typeCritereService.get(val.idSession).then(data => {
            console.log(data);
            setTypeCriteres(data);
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
    }

    const actionObligatoire = (rowData) => {
        return (
            <React.Fragment>
                <InputSwitch checked={rowData.obligatoire} />
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editCritere(rowData)}  />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteCritere(rowData)} style={{marginLeft : 5}}/>
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );
    const critereDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveCritere} />
        </React.Fragment>
    );
    const deleteCritereDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCritereDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteCritere} />
        </React.Fragment>
    );
    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="p-grid">
                <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                />
                <ToastContainer />
                <div className="p-col-12">
    
                    <div className="card card-w-title datatable-demo">
                        {header}
                        {loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={dt} value={criteres} selection={selectedCriteres} onSelectionChange={(e) => setSelectedCriteres(e.value)}
                        dataKey="id" paginator rows={10} 
                        globalFilter={globalFilter}
                        header="Liste des critères">
                            <Column field="libelleTypeCritere" header="Type critère"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleCritere" header="critère"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="obligatoire" body={actionObligatoire} header="Obligatoire" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                

                <Dialog visible={critereDialog} style={{ width: '450px' }} header="Informations critère" modal className="p-fluid" footer={critereDialogFooter} onHide={hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleSession">Session</label>
                        <Dropdown optionLabel="libelleSession" optionValue="idSession" value={session} options={sessions} onChange={(e) => onSessionChange(e, 'session')} required autoFocus className={classNames({ 'p-invalid': submitted && !session.idSession })} />
                        {submitted && !session.idSession && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleTypeCritere">Type critère</label>
                        <Dropdown optionLabel="libelleTypeCritere" optionValue="idTypeCritere" value={critere.typeCritere} options={typeCriteres} onChange={(e) => onChampsChange(e, 'typeCritere')} required autoFocus className={classNames({ 'p-invalid': submitted && !critere.typeCritere.idTypeCritere })} />
                        {submitted && !critere.typeCritere.idTypeCritere && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCritere">Libelle critère </label>
                        <InputText id="libelleCritere" value={critere.libelleCritere}  onChange={(e) => onChampsChange(e, 'libelleCritere')} required autoFocus className={classNames({ 'p-invalid': submitted && !critere.libelleCritere })}/>
                        {submitted && !critere.libelleCritere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="coefficient">coefficient </label>
                        <InputText type="int" id="coefficient" value={critere.coefficient}  onChange={(e) => onChampsChange(e, 'coefficient')} required autoFocus />
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="obligatoire">Obligatoire</label>
                        <InputSwitch style={{marginLeft : 5}} checked={critere.obligatoire} onChange={(e) => onChampsChange(e, 'obligatoire') } />
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="observation">Observation</label>
                        <InputTextarea id="observation" value={critere.observation}  onChange={(e) => onChampsChange(e, 'observation')} required autoFocus />
                    </div>
                </Dialog>
                <Dialog visible={deleteCritereDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteCritereDialogFooter} onHide={hideDeleteCritereDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {critere && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                </div>
            </div> 
    )
}

export default Critere