import React, { Component } from 'react'
import {BrowserRouter, Route, Link, NavLink} from 'react-router-dom';
import {PanelMenu} from 'primereact/panelmenu';
import TypeFormation from './TypeFormation';
import TypeFinalite from './TypeFinalite';
import Niveau from './Niveau';
import Semestre from './Semestre';
import AnneeSemestre from './AnneeSemestre';
import Domaine from './Domaine';
import Mention from './Mention';
import Specialite from './Specialite';
import UniteEnseignement from './UniteEnseignement';
import ModeEnseignement from './ModeEnseignement';
import TypeActivite from './TypeActivite';
import Enseignant from './Enseignant';
import ParcoursUE from './ParcoursUE';
//import Parcours from './Parcours';
import Econstitutif from './Econstitutif';
import TypeUniteEnseignement from './TypeUniteEnseignement'

import Parcours from './parcours/Parcours';
import TypeMembreJury from './TypeMembreJury';
import ParcoursFiliere from './ParcoursFiliere';
import Curricula from './Curricula';
import Session from './Session'
import ECHoraire from './ECHoraire';
import Examen from './Examen'
import Notation from './Notation'



export class MenuParamLmd extends Component {
    constructor() {
        super();
        this.state = {

            panelMenuItems: [
               {
                  label:"Unité d'enseignement ",
                  items:[
                     {
                        label:'Nouveau type UE', command:()=>{ window.location = "/lmd/parametres/type-ue"}
                     },
                     {
                      label:'Nouvelle UE', command:()=>{ window.location = "/lmd/parametres/ue"}
                      },
                      /*
                      {
                        label:'Nouveau EC', command:()=>{ window.location = "/lmd/parametres/ec"}
                      },*/
                      {
                        label:"Nouveau type d'activité", command:()=>{ window.location = "/lmd/parametres/type-activite"}
                      },
                      {
                        label:"Affecter UE au parcours", command:()=>{ window.location = "/lmd/parametres/parcours-ue"}
                      },
                  ]
               },
                {
                    label:'Parcours',
                    items:[
                     {label:'Nouveau domaine', command:()=>{ window.location = "/lmd/parametres/domaine"}},
                     {label:'Nouvelle mention', command:()=>{ window.location = "/lmd/parametres/mention"}},
                     {label:'Nouvelle specialité', command:()=>{ window.location = "/lmd/parametres/specialite"}},
                     {label:'Nouveau parcours', command:()=>{ window.location = "/lmd/parametres/parcours"}},
                     {label:'Affecter UE aux parcours', command:()=>{ window.location = "/lmd/parametres/parcours-ue"}},
                     {label:'Nouveau Type de formation', command:()=>{ window.location = "/lmd/parametres/type-formation"}},
                     {label:'Nouveau Type finalité', command:()=>{ window.location = "/lmd/parametres/type-finalite"}},
                     {label:'Mode enseignement', command:()=>{ window.location = "/lmd/parametres/mode-enseignement"}},
                     {label:'Parcours Filière', command:()=>{ window.location = "/lmd/parametres/parcours-filiere"}},
                     {label:'Affecter EC aux enseignants', command:()=>{ window.location = "/lmd/parametres/enseignant-ec"}},
                     {label:'Nouveau curriculat', command:()=>{ window.location = "/lmd/parametres/curriculats"}}
                    ]
                 },
                 {
                    label:'Autres paramètres ',
                    items:[
                       
                       {
                        label:'Nouveau niveau', command:()=>{ window.location = "/lmd/parametres/niveau"}
                        },
                        {
                           label:'Nouvelle semestre ', command:()=>{ window.location = "/lmd/parametres/semestre"}
                        },
                        {
                           label:'Affecter les semestres aux années ', command:()=>{ window.location = "/lmd/parametres/annee-semestre"}
                        },
                        {
                            label:'Nouveau enseignant ', command:()=>{ window.location = "/lmd/parametres/enseignant"}
                         },
                    ]
                 },
                 {
                    label:'Gestion deliberation ',
                    items:[
                       
                       {
                            label:'Type membre jury', command:()=>{ window.location.href = "/lmd/parametres/type-membre-jury"}
                            
                        },
                        {
                            label:'Session d\'examen', command:()=>{ window.location.href = "/lmd/parametres/session"}
                        }
                    ]
                 }
            ]
        };
    }
    
    render() {
        //console.log(window.location.hash)
        return (
            <BrowserRouter>
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-2">
                        <PanelMenu model={this.state.panelMenuItems} />
                </div>
                <div className="p-lg-10">
                    <Route path="/lmd/parametres/type-formation" component={TypeFormation}/>
                    <Route path="/lmd/parametres/type-finalite" component={TypeFinalite}/>
                    <Route path="/lmd/parametres/niveau" component={Niveau}/>
                    <Route path="/lmd/parametres/semestre" component={Semestre} />
                    <Route path="/lmd/parametres/annee-semestre" component={AnneeSemestre} />
                    <Route path="/lmd/parametres/ec" component={Econstitutif} />
                    <Route path="/lmd/parametres/type-membre-jury" component={TypeMembreJury} />
                    <Route path="/lmd/parametres/domaine" component={Domaine} />
                    <Route path="/lmd/parametres/mention" component={Mention} />
                    <Route path="/lmd/parametres/specialite" component={Specialite} />
                    <Route path="/lmd/parametres/type-ue" component={TypeUniteEnseignement} />
                    <Route path="/lmd/parametres/type-activite" component={TypeActivite}  />
                    <Route path="/lmd/parametres/curriculats" component={Curricula}  />
                    <Route path="/lmd/parametres/ue" component={UniteEnseignement} />
                    <Route path="/lmd/parametres/mode-enseignement" component={ModeEnseignement}/>
                    <Route path="/lmd/parametres/enseignant" component={Enseignant} />
                    <Route path="/lmd/parametres/parcours-ue" component={ParcoursUE} />
                    <Route path="/lmd/parametres/session" component={Session} />
                    <Route path="/lmd/parametres/examen/:idSession" component={Examen} />
                    <Route path="/lmd/parametres/notation/:idExamen" component={Notation} />
                    <Route path="/lmd/parametres/parcours" component={Parcours}/>
                    <Route path="/lmd/parametres/parcours-filiere" component={ParcoursFiliere}/>
                    <Route path="/lmd/parametres/enseignant-ec" component={ECHoraire}/>
                </div>
            </div>
            </BrowserRouter>
        )
    }
}

export default MenuParamLmd
