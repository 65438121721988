import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { UEService } from '../../service/UEService';
import { ParcoursService } from '../../service/ParcoursService'

export class UniteEnseignementLibre extends Component {


    constructor() {
        super();
        this.state = {
            listeParcours : [],
            selectEcDialog: false,
            ueLibre: null,
            submitted: false,
            loading : false,
            globalFilter: null,
            idDepartement: null,
            listeEC: [],
            ecChoisi: [],
            ecChoix: null,
            informationParcours: []
        }
        this.useService = new UEService()
        this.parcoursService = new ParcoursService()
        this.spinner = new Spinner()
        this.notify = new Notify()


    }

    
    componentDidMount() {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({
            loading : true,
            idDepartement: token.code_departement
        })

        this.parcoursService.getListeParcoursUELibre(token.code_departement).then(data => {
            //console.log(data)
            this.setState({
                listeParcours: data,
                loading: false
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({
                loading: false
            })
        })

    }

    choixECUELibre = (parcours) => {

        this.useService.getListeEcLibreUe(parcours.idParcours, parcours.idSemestre).then(listeEcOption => {

            let informationParcours = this.state.informationParcours
            informationParcours.push(parcours)

            this.parcoursService.getChoixElementConstitutifChoisi(parcours.idParcours, parcours.idSemestre, this.state.idDepartement).then(ecEnOption => {

                //console.log(ecEnOption)
                
                this.setState({
                    listeEC: listeEcOption,
                    selectEcDialog: true,
                    informationParcours: informationParcours,
                    ecChoisi: ecEnOption
                })

            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })

            

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    choisirEcLibre = (ec) => {
        this.setState({
            ecChoisi: [ec]
        })
    }

    saveChoixEc = () => {

        this.setState({
            submitted: true
        })

        if(this.state.ecChoisi.length>0){

            let choixEC = {
                idDepartement: this.state.idDepartement,
                idElementConstitutif: this.state.ecChoisi[0].idElementConstitutif,
                idParcours: this.state.informationParcours[0].idParcours,
                idSemestre: this.state.informationParcours[0].idSemestre
            }
            
            this.parcoursService.choixElementConstitutifEnOption(choixEC).then(data => {
                this.notify.success()
                this.setState({
                    listeEC: [],
                    selectEcDialog: false,
                    informationParcours: [],
                    ecChoisi: [],
                    submitted: false
                })

            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
            
        }

    }


    hideDialog = () => {
        this.setState({
            listeEC: [],
            selectEcDialog: false,
            informationParcours: [],
            ecChoisi: [],
            submitted: false
        })
    }




    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-warning" onClick={() => this.choixECUELibre(rowData)} style={{marginRight: '.5em'}} title="Choix de l'EC en option" />
            </React.Fragment>
        );
    }


    actionBodyChoixEcTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-warning" onClick={() => this.choisirEcLibre(rowData)} style={{marginRight: '.5em'}} title="Choisir l'élement constitutif" />
            </React.Fragment>
        );
    }


    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        )
        
        const selectEcDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.saveChoixEc} />
            </React.Fragment>
        )

        return (
            <div className="p-grid">
                <div className="p-col-12">

                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeParcours} selectionMode="none" header="Liste des parcours avec UE libres" paginator={true} rows={10} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleParcours" header="Libelle parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher parcours"/>
                            <Column field="libelleNiveau" header="Libelle niveau" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher niveau"/>
                            <Column field="libelleSemestre" header="Libelle semestre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher semestre"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>


                <Dialog visible={this.state.selectEcDialog} style={{ width: '80%' }} header="Choix de l'element constitutif à prendre en compte" modal className="p-fluid" footer={selectEcDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>

                            <DataTable  ref={(el) => this.dt = el} value={this.state.informationParcours} selectionMode="none" header="Informations du parcours concerné" paginator={false} rows={1} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleParcours" header="Libelle parcours" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="libelleNiveau" header="Libelle niveau" filterMatchMode="contains" sortable={false} filter={false} />
                                <Column field="libelleSemestre" header="Libelle semestre" filterMatchMode="contains" sortable={false} filter={false} />
                            </DataTable>

                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeEC} selectionMode="none" header="Liste élements constitutifs disponibles en option" paginator={true} rows={10} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleUniteEnseignement" header="Unité d'enseignement concernée" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="code element constitutif"/>
                                <Column field="codeElementConstitutif" header="Code element constitutif" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="code element constitutif"/>
                                <Column field="libelleElementConstitutif" header="Libelle element constitutif" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="element constitutif"/>
                                <Column field="creditElementConstitutif" header="Credit element constitutif" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="credit element constitutif"/>
                                <Column header={actionHeader} body={this.actionBodyChoixEcTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <DataTable  ref={(el) => this.dt = el} value={this.state.ecChoisi} selectionMode="none" header="L'option actuellement pris en compte" paginator={true} rows={10} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="codeElementConstitutif" header="Code element constitutif" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="code element constitutif"/>
                                <Column field="libelleElementConstitutif" header="Libelle element constitutif" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="element constitutif"/>
                                <Column field="creditElementConstitutif" header="Credit element constitutif" filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder="credit element constitutif"/>
                            </DataTable>
                            {this.state.submitted && this.state.ecChoisi.length===0 && <small className="p-invalid" style={{color : "red"}}>Choisir obligatoirement un élement constitutif</small>}
                   
                            
                        </div>

                    </div>
                    
                </Dialog>

            </div>
        )
    }
}

export default UniteEnseignementLibre
