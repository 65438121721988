import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP";


export default class DashboardService{
    constructor(){
        this.addresse = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso";
    }
    
    getActivites(idAnnee, matricule){

        //console.log(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/enseignant-activites?matricule=${matricule}&idAnnee=${idAnnee} `)
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/enseignant-activites?matricule=${matricule}&idAnnee=${idAnnee} `).then(response=>{return response.data});
    }

    getActivitesDepartement(idDepartement, idCohorte,idAnnee, idSemestre){

        let url = `${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/departement-activites?idDepartement=${idDepartement}&idAnnee=${idAnnee}`
        if(idCohorte)
        {
            url = url + `&cohorte=${idCohorte}`
        }
        if(idSemestre){
            url = url + `&semestre=${idSemestre}`
        }
        //console.log(url)
        return axios.get(url);
    }

    getEnseignant(matricule, idAnnee){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/enseignant-tb?matricule=${matricule}&idAnnee=${idAnnee} `).then(response=>{return response.data});
    }

    getEnseignant2(matricule){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/enseignant-tb?matricule=${matricule}`).then(response=>{return response.data});
    }

    getInfoVhEns(matricule, idAnnee){
        //console.log(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/bilan-activite-annuelle-enseignant?matricule=${matricule}&idAnnee=${idAnnee} `)
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/bilan-activite-annuelle-enseignant?matricule=${matricule}&idAnnee=${idAnnee} `).then(response=>{return response.data});
    }

    getActiviteDepMonth(idDepartement, idAnnee){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/programmations-month?idDepartement=${idDepartement}&idAnnee=${idAnnee}`).then(response=>{return response.data});
    }
    
    afficherFicheSuivi(fiche){

        
        const url = this.addresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/reporting"
        //console.log(url, fiche)
        return axios.post(url,fiche,{responseType: "arraybuffer"})
                    .then(res => res);
    }

    afficherFicheSuiviEc(fiche){
        const url = this.addresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/reporting"

        //console.log(url, fiche)
        return axios.post(url,fiche,{responseType: "arraybuffer"})
                    .then(res => res);
    }

    afficherFicheSuiviDepartement(fiche){
        const url = this.addresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/reporting"

        //console.log(url)
        return axios.post(url,fiche,{responseType: "arraybuffer"})
                    .then(res => res);
    }
}  
