import axios from 'axios';
import { AdresseIP } from "../../components/AdresseIP";

export class CritereService {
    constructor(){
        this.adresse = new AdresseIP();
    }
    getAll() {
       
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/criteres"
        return axios.get(url).then(res => res.data) 
    }
    
 
    create(newCritere) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/criteres"
        return axios.post(url,newCritere)
                    .then(res => res.data);
    }
    
    update(idCritere ,newCritere) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/criteres/"
        return axios.put(url+idCritere, newCritere)
                    .then(res => res.data);
    }
 
    delete(idCritere) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/criteres/"
        return axios.patch(url+idCritere)
                    .then(res => res.data)
    }
}