import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ParcoursService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours"
        return axios.get(url).then(res => res.data) 
    }

    getZonePaiementList(){

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/zone-paiement"
        return axios.get(url).then(res => res.data)
    }

    getListeFraisPaiement(idDepartement){

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/frais-paiement?idDepartement="+idDepartement
        return axios.get(url).then(res => res.data)
    }

    enregistrerFraisPaiement(objetPaiement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/frais-paiement"
        return axios.post(url,objetPaiement)
                    .then(res => res.data);
    }

    deleteFraisPaiement(idFrais){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/frais-paiement/"
        return axios.patch(url+idFrais)
                    .then(res => res.data)
    }

    getStatutEtudiantList(){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/statut-etudiant"
        return axios.get(url).then(res => res.data)
    }

    getListeTypeFrais(){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-frais-paiement"
        return axios.get(url).then(res => res.data)
    }
   
    create(newParcours) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours"
        return axios.post(url,newParcours)
                    .then(res => res.data);
    }

    update(idParcours ,newParcours) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours/"
        return axios.put(url+idParcours, newParcours)
                    .then(res => res.data);
    }

    delete(idParcours) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours/"
        return axios.patch(url+idParcours)
                    .then(res => res.data)
    }

    getListeParcoursDepartement(idDepartementFiliere){
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-parcours/"
        return axios.patch(url+idDepartementFiliere)
                    .then(res => res.data)
    }


    getListeParcoursDepartementByIdDepartement(idDepartement){
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-departement/"
        return axios.get(url+idDepartement)
                    .then(res => res.data)
    }

    getParcoursEnfant(idParcoursEnfant){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-debouches?idParcours="
        return axios.get(url+idParcoursEnfant)
                    .then(res => res.data)
    }

    getParcoursCandidat(idSemestre){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-departement?semestreSortant="
        return axios.get(url+idSemestre)
                    .then(res => res.data)
    }

    saveParcoursShield(idParcours, chieldsListe){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-debouches?idParcours="
        return axios.post(url+idParcours,chieldsListe)
                    .then(res => res.data);
    }

    getListeParcoursUELibre(idDepartement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-ue-libre?idDepartement="
        return axios.get(url+idDepartement)
                    .then(res => res.data);
    }

    choixElementConstitutifEnOption(ecChoisi){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-ec"
        return axios.post(url, ecChoisi)
                    .then(res => res.data);
    }

    getChoixElementConstitutifChoisi(idParcours, idSemestre, idDepartement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-ec?idParcours="+idParcours+"&idSemestre="+idSemestre+"&idDepartement="+idDepartement
        return axios.get(url)
                    .then(res => res.data);
    }


    getNombreUEOptionnelParcoursSemestre(idParcours, idSemestre){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-semestre?idParcours="+idParcours+"&idSemestre="+idSemestre
       return axios.get(url)
                   .then(res => res.data)
    }


    enregistrerNombreUEAChoisir(informationNombreUE){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-semestre"
       return axios.post(url, informationNombreUE)
                   .then(res => res.data)
    }

    updateNombreUEAChoisir(idParcoursSemestre, informationNombreUE){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-semestre/"
        return axios.put(url+idParcoursSemestre, informationNombreUE)
                   .then(res => res.data)
    }


    deleteNombreChoixUEOptionel(idParcoursSemestre){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-semestre/"
        return axios.patch(url+idParcoursSemestre)
                   .then(res => res.data)
    }

}