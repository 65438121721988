import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP";


export default class SpecialiteService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/specialites"
    }
    /**
     * 
     * @returns la liste complete des specialites des enseignant
     */
    getAll(){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }

    /**
     * 
     * @param {*} ListSpecialite une liste d'objects de type speciliate
     * @returns undefined
     * enregistre les specialites de la liste dans la basse de données
     * 
     */

    create(ListSpecialite){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,ListSpecialite);
    }

    /**
     * 
     * @param {*} specialite 
     * @returns undefined
     * mise à jour d'une specialite enseignant
     * 
     */
    update(specialite){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${specialite.idSpecialite}`,specialite);
    }

    /**
     * 
     * @param {*} specialiteId 
     * @returns undefined
     * suppression logique d'une specialite enseignant
     */
    delete(specialiteId){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${specialiteId}`);
    }
}