import axios from "axios";
import {AdresseIP} from '../../components/AdresseIP';


export default class ResponsabiliteService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/responsabilites"
    }
  
    /**
     * 
     * @returns la liste des responsabilite des enseignant
     */

    getAll(){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }
    

    /**
     * 
     * @param {*} ListResponsabilite 
     * @returns undefined
     * enregistre une liste des responsabilités dans la base de données
     * la liste est contituée de plusieurs objects de type responsabilité
     */

    create(ListResponsabilite){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,ListResponsabilite);
    }

    /**
     * 
     * @param {*} responsabilite 
     * @returns undefined
     * mise à jour d'une responsabilite
     */
    update(responsabilite){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${responsabilite.idResponsabilite}`,responsabilite);
    }

    /**
     * 
     * @param {*} responsabiliteId le id de la responsabilite conrespondant
     * @returns undefined
     * supprime de manière logique une responsabilite dans la base de données
     */
    delete(responsabiliteId){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${responsabiliteId}`);
    }
}