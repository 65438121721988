import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { EntiteService } from '../../service/EntiteService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

const Entite = () => {
    let emptyEntite = {
        idEntite: null,
        libelleEntite: ''
    };

    const [entites, setEntites] = useState(null);
    const [entiteDialog, setEntiteDialog] = useState(false);
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false);
    const [entite, setEntite] = useState(emptyEntite);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const entiteService = new EntiteService();
    const notify = new Notify();
    const spinner = new Spinner();


    useEffect(() => {
        
        setLoading(true)
        entiteService.getAll().then(data => {
            setEntites(data)
            setLoading(false);
            //notify.success();
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            notify.echec(error.response.data.message);
        })
    }, []);

    const openNew = () => {
        setEntite(emptyEntite);
        setSubmitted(false);
        setEntiteDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEntiteDialog(false);
    }

    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false);
    }

    const saveEntite = () => {
        setSubmitted(true);

        if (entite.libelleEntite.trim()) {
            let _entite = {...entite};
            console.log(_entite)
            if (entite.idEntite) {
                entiteService.update(entite.idEntite, _entite).then(data => {
                    console.log(data);
                    entiteService.getAll().then(data => setEntites(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                entiteService.create(_entite).then(data => {
                    console.log(data);
                    entiteService.getAll().then(data => setEntites(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                
            }

            setEntiteDialog(false);
            setEntite(emptyEntite);
        }
    }
    const editEntite = (entite) => {
        setEntite({...entite});
        setEntiteDialog(true);
    }

    const confirmDeleteEntite = (entite) => {
        setEntite(entite);
        setDeleteEntiteDialog(true);
    }

    const deleteEntite = () => {
        entiteService.delete(entite.idEntite).then(data => {
            setEntite(emptyEntite);
            console.log(data);
            entiteService.getAll().then(data => setEntites(data));
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setDeleteEntiteDialog(false);
        
    }

    const onChampsChange = (e, name) => {
        const val = (e.target && e.target.value).toUpperCase() || '';
        let _entite = {...entite};
        _entite[`${name}`] = val;

        setEntite(_entite);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editEntite(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteEntite(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );
    const entiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveEntite} />
        </React.Fragment>
    );
    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteEntite} />
        </React.Fragment>
    );
    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={entites} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des entités">

                    <Column field="libelleEntite" header="Entité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>
            <Dialog visible={entiteDialog} style={{ width: '450px' }} header="Informations entité" modal className="p-fluid" footer={entiteDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleEntite">Libelle Entité</label>
                    <InputText id="libelleEntite" value={entite.libelleEntite} onChange={(e) => onChampsChange(e, 'libelleEntite')} required autoFocus className={classNames({ 'p-invalid': submitted && !entite.libelleEntite })} />
                    {submitted && !entite.libelleEntite && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {entite && <span>Etes vous sûr de supprimer cet élément ? <b>{entite.libelleEntite}</b>?</span>}
                </div>
            </Dialog>

        </div>
    )

}
export default Entite