
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './index.css';

  

import { DataTable } from 'primereact/datatable';
import React,{Component, useState}  from 'react';
import { Column } from 'primereact/column';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Controller,useForm } from 'react-hook-form';  
import classNames from 'classnames';
import { Notify } from '../../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner';
import SpecialiteService from '../../service/SpecialiteService';
import DisciplineService from '../../service/DisciplineService';

export default function Specialite(){

    const spinner = new Spinner();
    const notify = new Notify();
    const specialiteService = new SpecialiteService();
    const disciplineService = new DisciplineService();

    // Default value bloc

    const defaultValues = {
        libelleSpecialite: '',
        discipline:'',
        idDiscipline:'',
        idSpecialite:''
    };
    
    const dialogOpenHandler = {
        add:false,
        modify:false,
        delete:false,
        
    }


    // variables declaration Bloc

    const [listSpecialite,setListSpecialite] = useState();
    const [listDiscipline,setListDiscipline] = useState();
    const [specialiteDelete,setSpecialiteDelete]=useState(defaultValues);
    const [saveList,setSaveList] = useState([]);
    const [cancelAndReset,setCancelAndReset]=useState(false);
    const [loading,setLoading] = useState(false);
    const [dialog,setDialog]=useState(dialogOpenHandler);
    const { control,setValue,getValues, formState: { errors }, handleSubmit, reset } = useForm({defaultValues});
    const [spin,setSpin] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [httpErrors, setHttpErrors] = useState(false);

    // variables declaration EndBloc

    // functions handler Bloc
    const handleSpecialiteSubmit = (data) => {
        setSaveList([...saveList,data]); // sauvegarde temporaire de la liste des specialite
        reset(); // reinitialisation des champs du formulaire

    };

    const handleModification = (dataToModify)=>{
        const discipline = listDiscipline.filter((disc)=>(disc.libelleDiscipline === dataToModify.libelleDiscipline))[0];

        // variable d'etat contenant les informations à modifier
        setValue("libelleSpecialite",dataToModify.libelleSpecialite);
        setValue("discipline",discipline);
        setValue("idSpecialite",dataToModify.idSpecialite);
        setDialog({modify:true});
    }


    /**
     * 
     * @param {*} dataForm 
     * la function handleSave est charger d'ajouter ou modifier les informations d'une specialite
     * en fonctions du button qui à declencher le traitement
     * 
     */
    const handleSave=(dataForm=null)=>{
        setSpin(true);
        if(dialog.add && saveList.length!==0){
        const finalDTO = [];
        saveList.forEach(function(d){
            finalDTO.push({idDiscipline:d.discipline.idDiscipline,libelleSpecialite:d.libelleSpecialite});
            
        })
        specialiteService.create(finalDTO)
            .then(()=>{
                notify.success(); // notification de success
                setLoading(true);
                setDialog(false);
                specialiteService.getAll().then(response=>setListSpecialite(response))
               
                reset(); // reinitialisation des champs du formulaire    
                
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec

            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })
        }else if(dialog.modify){    
            setSpin(true);
            const libelleSpecialite=dataForm.libelleSpecialite;
            const idDiscipline = dataForm.discipline.idDiscipline;
            const idSpecialite = dataForm.idSpecialite;

            specialiteService.update({libelleSpecialite,idDiscipline,idSpecialite})
            .then(()=>{
                setLoading(true);
                specialiteService.getAll().then((data)=>{setListSpecialite(data)});
                setDialog(false);
                reset();// reinitialisation des champs du formulaire
            })
            .catch((error)=>{notify.echec(error.response.data.message)})
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })
        }
        
    }

   

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]); 
        reset(); // reinitialisation des champs du formulaire
    }


    // delete user information management
    const handleSpecialiteDeletion = (dataToDelete="")=>{
        
        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
                setSaveList([]); // reinitialisation de la liste contenant les specialites a enregistrer
                setCancelAndReset(false);
                setDialog(false);
                setSpin(false);
            },2000);
        }else if(dialog.delete){
            setSpin(true);
            
            specialiteService.delete(specialiteDelete.idSpecialite)
            .then(()=>{
                setLoading(true);
                specialiteService.getAll().then(response=>{
                    setListSpecialite(response)
                });
                setDialog({delete:false})
            })
            .catch(errors=>{
                let errors_str = String(errors);
                setDialog({delete:false});
                let http_errors = +errors_str.match(/\d+/)[0];
                if(http_errors === 403){
                    setHttpErrors(true);
                }else{
                    notify.echec(errors.response.data.message);
                }
            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })

        }else{
            setSpecialiteDelete(dataToDelete)
            setDialog({delete:true})
        }
    }

    // hide and lifecycle functions management
    const onHide = ()=>{
        if(cancelAndReset && dialog.add){
            setCancelAndReset(false);
        }else if(dialog.add || dialog.delete || dialog.modify){
            setDialog(dialogOpenHandler);
            reset(); // reinitialisation des champs du formulaire
        }
        setSpin(false);
        setSaveList([]); // reinistialisation de la liste contenant les specialites a enregistrer 
    }

    useEffect(() => {
        setLoading(true);
        specialiteService.getAll().then(response=>setListSpecialite(response)).catch((errors)=>{console.log(errors);notify.echec(errors.response.data.message)}).finally(()=>{setLoading(false);});
        disciplineService.getAll().then((data)=>{setListDiscipline(data.map(d=>{return{idDiscipline:d.idDiscipline,libelleDiscipline:d.libelleDiscipline}}))});
    }, [])


    // JSX function management
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button type="button" icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em'}} onClick={()=>handleModification(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={()=>handleSpecialiteDeletion(rowData)} />
                </React.Fragment>
            );
        }

        
        const saveSpecialiteFooter=(
            <React.Fragment>
                 <div className="p-grid p-justify-end" style={{width:"100%"}}>
                    <Button type="submit" label="Enregistrer" className="p-button-success" disabled={spin||saveList.length===0?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} onClick={handleSave} />
                    <Button type="button" label="Quitter" disabled={spin?true:false} className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
                </div>
            </React.Fragment>
        )
        const deleteSpecialiteFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={onHide} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleSpecialiteDeletion} />
            </React.Fragment>
            
        )
        const cancelSpecialiteFooter =(

            <React.Fragment>
                <Button label="NON" type="button" icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
                <Button label="OUI" type="button" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleSpecialiteDeletion} />
            </React.Fragment>
            
        )

        const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setDialog({...dialog,add:true})} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );
    // function handler EndBloc

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">

                <div style={{width:"100%",textAlign:"center"}}>{header}</div>
                    {
                    loading ?
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    :
                    <DataTable 
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                        rows={5}
                        selectionMode="single" filterMatchMode="contains"
                        paginator={true} responsive={true}
                        value={listSpecialite}
                        globalFilter={globalFilter}
                        header="Liste des spécialités"
                    >
                        <Column field="libelleSpecialite" header="Spécialité" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleDiscipline" header="Discipline" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{width:"120px"}} />
                    </DataTable>
                    
                    }
                    
                </div>

            {/* Add Specialite Bloc */}
                <Dialog className="card" visible={dialog.add} style={{width:"60vw"}} footer={saveSpecialiteFooter} onHide={onHide}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center">
                                <h5 className="text-header">Ajouter une spécialité</h5>
                                <form onSubmit={handleSubmit(handleSpecialiteSubmit)} className="p-fluid">
                                    <div style={{height:"170px"}}>
                                        <div className="p-field">
                                            <span>
                                                <label htmlFor="libelleDiscipline">Discipline</label>
                                                    <Controller name="discipline" 
                                                        control={control} 
                                                        rules={{ required: 'champ obligatoire.'}}
                                                        render={({ field}) => {
                                                            return (
                                                            <Dropdown 
                                                                id={field.name} value={field.value} name={field.name}
                                                                onChange={(e)=>field.onChange(e.value)} options={listDiscipline} 
                                                                optionLabel="libelleDiscipline" 
                                                            />
                                                        )} }
                                                    />
                                            </span>
                                        </div>
                                        <div className="p-field">
                                            <span>
                                                <label htmlFor="libelleSpecialite">Libelle spécialité {getFormErrorMessage('libelleSpecialite')}</label>
                                                <Controller name= "libelleSpecialite" 
                                                    control={control} 
                                                    rules={{ required: 'champ obligatoire.'}}
                                                    render={({ field, fieldState }) => (
                                                    <InputText type="text" id={field.name} {...field} autoFocus />
                                                )} />
                                            </span>
                                            
                                        </div>
                                    </div>
                                    <div style={{width:"100px"}}>
                                        <Button type="submit" className="p-button-info" label="ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <h5 className="text-header">Nouvellement ajouté </h5>

                            <div className=" p-fieldset">
                                <table className="table" style={{minHeight:"300px"}}>
                                    <thead>
                                        <tr className="p-grid">
                                            <th className="p-col-4">Spécialité</th>
                                            <th className="p-col-5">Discipline</th>
                                            <th className="p-col-3" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            saveList.map((spec,index) =>
                                                <tr className="p-grid" key={index+1}>
                                                    <td className="p-col-4">{spec.libelleSpecialite}</td>
                                                    <td className="p-col-5">{spec.discipline.libelleDiscipline}</td>
                                                    <td><Button icon="pi pi-trash" className="p-button-danger p-col-3" onClick={() => {removeItem(index)}} 
                                                    /></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>      
                                </table>
                               
                            </div>
                        </div>
                    </div>

                </Dialog>
            {/* Add Specialite EndBloc */}
   

            {/* Modify Specialite Bloc */}
                <Dialog className="card" visible={dialog.modify} style={{width:"30vw",height:"350px"}} onHide={onHide}>

                    <div className="p-d-flex p-jc-center">
                        <h5 className="text-header">Modification</h5>
                        <form onSubmit={handleSubmit(handleSave)} className="p-fluid">
                            <div>
                                <div className="p-field" >
                                    <label htmlFor="discipline">Discipline</label>
                                    <Controller 
                                        name="discipline"
                                        control={control}
                                        render={({field})=>{
                                            return (
                                                <Dropdown 
                                                    value={field.value} name={field.name} id={field.name}
                                                    options={listDiscipline} optionLabel="libelleDiscipline"
                                                    {...field}
                                                    onChange={(e)=>field.onChange(e.value)}
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="p-field" >
                                    <div>
                                        <label htmlFor="specialite">Spécialité</label>
                                            <Controller 
                                                name="libelleSpecialite"
                                                control={control}
                                                render={({field})=>{
                                                    return (
                                                        <InputText 
                                                            value={field.value} id={field.name} name={field.name} {...field}
                                                        />
                                                    )
                                                }}
                                            />
                                        
                                    </div>
                                </div>
                            </div>
                            <div style={{display:"flex"}} className='mt-5'>
                                <Button disabled={spin?true:false} className=" p-button-success" type="submit" label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} />
                                <Button disabled={spin?true:false} className=" p-button-danger" type="button" label="Quitter"  icon="pi pi-times"  onClick={onHide}/>
                            </div> 
                                
                        </form>
                    </div>
                </Dialog>
            {/* Modify Specialite EndBloc */}


            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSpecialiteFooter} onHide={onHide}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de supprimer cet élément ?</span>
                </div>
            </Dialog>
            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelSpecialiteFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>
            <Dialog visible={httpErrors} header="Confirmez" modal onHide={()=>setHttpErrors(false)}>
                <div className="httperrors confirmation-content">
                    <p>
                        <span>Vous n'êtes pas autorisé à supprimer <cite><strong>{specialiteDelete.libelleSpecialite}</strong></cite></span><br />
                        <span>Veuillez-contacter votre administrateur système.</span>
                    </p>
                    {/* <ProgressBar value={progressValue}/>  */}
                </div>
            </Dialog>
        </div>
    )
}