import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SessionService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions"
       return axios.get(url).then(res => res.data) 
   }

   get(annee, idStructure, niveau, statutSession, typeDemande) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions?anneeSession="+annee+"&idStructure="+idStructure+"&niveau="+niveau+"&statutSession="+statutSession+"&typeDemande="+typeDemande
    console.log(url)
    return axios.get(url).then(res => res.data) 
   }

   getSessionByTypeSession(typeSession) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions/type-demande?typeDemande="+typeSession
    console.log(url)
    
    return axios.get(url).then(res => res.data) 
   }

   create(newSession) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions"
       return axios.post(url,newSession)
                   .then(res => res.data);
   }
   
   update(idSession ,newSession) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions/"
       return axios.put(url+idSession, newSession)
                   .then(res => res.data);
   }

   delete(idSession) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions/"
       return axios.patch(url+idSession)
                   .then(res => res.data)
   }

   getListeSessionTypeDemande(libelleTypeDemande){
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/sessions/type-demande?typeDemande="+libelleTypeDemande
    return axios.get(url).then(res => res.data)
   }


}