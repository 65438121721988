import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { EnseignantService } from '../../service/EnseignantService';
import { Dropdown } from 'primereact/dropdown'
import { RadioButton } from 'primereact/radiobutton';

export class Enseignant extends Component {
    emptyEnseignant = {
        idEnseignant: null,
        matriculeEnseignant: "",
        nomEnseignant: "",
        prenomEnseignant: "",
        telEnseignant : "",
        emailEnseigant : "",
        grade: "",
    }
    constructor() {
        super();
        this.state = {
            listeEnseignant : [],
            listeGrade: [], 
            enseignantDialog: false,
            deleteEnseignantDialog: false,
            enseignant: this.emptyEnseignant,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null,
            regExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i
        }
        this.enseignantService = new EnseignantService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveEnseignant = this.saveEnseignant.bind(this);
        this.editEnseignant = this.editEnseignant.bind(this);
        this.confirmDeleteEnseignant = this.confirmDeleteEnseignant.bind(this);
        this.deleteEnseignant = this.deleteEnseignant.bind(this);
        this.onNomEnseignantChange = this.onNomEnseignantChange.bind(this);
        this.onPrenomEnseignantChange = this.onPrenomEnseignantChange.bind(this);
        this.onTelEnseignantChange = this.onTelEnseignantChange.bind(this);
        this.onEmailEnseignantChange = this.onEmailEnseignantChange.bind(this);
        this.hideDeleteEnseignantDialog = this.hideDeleteEnseignantDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.enseignantService.getAll().then(data => {
            this.setState({listeEnseignant: data, loading : false})
        })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })


            this.enseignantService.getListeGrade().then(data => {
                this.setState({
                    listeGrade: data
                })
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
    }
    openNew() {
        this.setState({
            enseignant: this.emptyEnseignant,
            submitted: false,
            enseignantDialog: true
        })
    }

    hideDialog() {
        this.setState({
            submitted: false,
            enseignantDialog: false
        });
    }

    hideDeleteEnseignantDialog() {
        this.setState({ deleteEnseignantDialog: false });
    }

    saveEnseignant() {
        let state = { submitted: true };

        if (
            this.state.enseignant.grade!=null 
            && 
            this.state.enseignant.matriculeEnseignant!=null 
            && 
            this.state.enseignant.matriculeEnseignant.trim() 
            && 
            this.state.enseignant.nomEnseignant.trim() 
            && 
            this.state.enseignant.prenomEnseignant.trim() 
            && 
            this.state.enseignant.telEnseignant.trim() 
            && 
            this.state.enseignant.emailEnseignant.trim()
            && 
            this.state.regExp.test(this.state.enseignant.emailEnseignant)
            &&
            this.state.enseignant.sexeEnseignant

        ) {

            const newEnseignant = {
                idEnseignant : parseInt(this.state.enseignant.idEnseignant) ,
                matriculeEnseignant: this.state.enseignant.matriculeEnseignant,
                nomEnseignant : this.state.enseignant.nomEnseignant,
                prenomEnseignant : this.state.enseignant.prenomEnseignant,
                telEnseignant : this.state.enseignant.telEnseignant,
                emailEnseignant : this.state.enseignant.emailEnseignant,
                grade: this.state.enseignant.grade,
                sexeEnseignant: this.state.enseignant.sexeEnseignant
            }

            if (this.state.enseignant.idEnseignant) {
                this.enseignantService.update(parseInt(this.state.enseignant.idEnseignant), newEnseignant)
                                .then(data => {
                                    this.notify.success()
                                    this.setState({redirect : true})
                                    this.setState({loading : true})
                                    this.enseignantService.getAll().then(data => 
                                        this.setState({listeEnseignant: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.enseignantService.create(newEnseignant).then(data => {
                    this.notify.success()
                    this.setState({redirect : true})
                    this.setState({loading : true})
                    this.enseignantService.getAll().then(data => 
                        this.setState({listeEnseignant: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                enseignantDialog: false,
                enseignant: this.emptyEnseignant
            };
        }

        this.setState(state);
    }
    editEnseignant(enseignant) {
        this.setState({
            enseignant,
            enseignantDialog: true
        });
    }
    confirmDeleteEnseignant(enseignant) {
        this.setState({
            enseignant,
            deleteEnseignantDialog: true
        });
    }
    deleteEnseignant(idEnseignant) {
        this.enseignantService.delete(parseInt(idEnseignant)).then(data => {
            this.notify.success()
            this.setState({
                enseignantDialog: false,
                enseignant: this.emptyEnseignant,
                redirect : true,
                deleteEnseignantDialog: false
            });
            this.setState({loading : true})
            this.enseignantService.getAll().then(data => 
                this.setState({listeEnseignant: data, loading : false})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
        })
    }
    onNomEnseignantChange(e, nomEnseignant) {
        const val = (e.target && e.target.value) || '';
        let enseignant = this.state.enseignant;
        enseignant[`${nomEnseignant}`] = val;

        this.setState({enseignant });
    }
    onPrenomEnseignantChange(e, prenomEnseignant) {
        const val = (e.target && e.target.value) || '';
        let enseignant = this.state.enseignant;
        enseignant[`${prenomEnseignant}`] = val;

        this.setState({enseignant });
    }

    onMatriculeEnseignantChange = (e, matriculeEnseignant) => {
        const val = (e.target && e.target.value) || '';
        let enseignant = this.state.enseignant;
        enseignant[`${matriculeEnseignant}`] = val;

        this.setState({enseignant });
    }

    onTelEnseignantChange(e, telEnseignant) {
        const val = (e.target && e.target.value) || '';
        let enseignant = this.state.enseignant;
        enseignant[`${telEnseignant}`] = val;

        this.setState({enseignant });
    }


    onEmailEnseignantChange(e, emailEnseignant) {
        const val = (e.target && e.target.value) || '';
        let enseignant = this.state.enseignant;
        enseignant[`${emailEnseignant}`] = val;

        this.setState({enseignant });
    }


    onGenreEnseignantChance = (value) => {
        let enseignant = this.state.enseignant

        enseignant['sexeEnseignant'] = value
        this.setState({
            enseignant: enseignant
        })
    }

    onGradeChange = (e, grade) => {
        const val = (e.target && e.target.value) || '';
        let enseignant = this.state.enseignant;
        enseignant[`${grade}`] = val;

        this.setState({enseignant });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editEnseignant(rowData)} style={{marginRight: '.5em'}}/>
                {<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteEnseignant(rowData)} />}
            </React.Fragment>
        );
    }
    render() {


        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const enseignantDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveEnseignant} />
            </React.Fragment>
        );
        const deleteEnseignantDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEnseignantDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteEnseignant(this.state.enseignant.idEnseignant) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeEnseignant} selectionMode="single" header={`Liste des enseignants (${this.state.listeEnseignant.length})`} paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="matriculeEnseignant" header="Matricule" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher le matricule"/>
                            <Column field="nomEnseignant" header="Nom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            <Column field="prenomEnseignant" header="Prénom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>
                            <Column field="telEnseignant" header="Téléphone" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher téléphone"/>
                            <Column field="emailEnseignant" header="Email" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher email"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>


                <Dialog visible={this.state.enseignantDialog} style={{ width: '80%' }} header="Informations enseignant" modal className="p-fluid" footer={enseignantDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <br/>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="matriculeEnseignant"><span style={{color:'red'}}>*</span> Matricule</label>
                                <InputText id="matriculeEnseignant" value={this.state.enseignant.matriculeEnseignant || ""}  onChange={(e) => this.onMatriculeEnseignantChange(e, 'matriculeEnseignant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.enseignant.matriculeEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nomEnseignant"><span style={{color:'red'}}>*</span> Nom</label>
                                <InputText id="nomEnseignant" value={this.state.enseignant.nomEnseignant || ""}  onChange={(e) => this.onNomEnseignantChange(e, 'nomEnseignant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.enseignant.nomEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="prenomEnseignant"><span style={{color:'red'}}>*</span> Prénom</label>
                                <InputText id="prenomEnseignant" value={this.state.enseignant.prenomEnseignant || ""}  onChange={(e) => this.onPrenomEnseignantChange(e, 'prenomEnseignant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.enseignant.prenomEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="telEnseignant"><span style={{color:'red'}}>*</span> Téléphone</label>
                                <InputText id="telEnseignant" value={this.state.enseignant.telEnseignant || ""}  onChange={(e) => this.onTelEnseignantChange(e, 'telEnseignant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.enseignant.telEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="emailEnseignant"><span style={{color:'red'}}>*</span> Email</label>
                                <InputText type='email' id="emailEnseignant" value={this.state.enseignant.emailEnseignant || ""}  onChange={(e) => this.onEmailEnseignantChange(e, 'emailEnseignant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && (!this.state.enseignant.emailEnseignant ||  (!this.state.regExp.test(this.state.enseignant.emailEnseignant) )) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit être un mail valide.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleGrade"><span style={{color:'red'}}>*</span> Selectioner le grade de l'enseignant</label>
                                <Dropdown optionLabel="libelleGrade" optionValue="idGrade" value={(this.state.enseignant.grade)? this.state.enseignant.grade: ""} options={this.state.listeGrade} onChange={(e) => {this.onGradeChange(e, 'grade')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le grade de l'enseignant"/>
                                {this.state.submitted && !this.state.enseignant.grade && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="sexeEnseignant"><span style={{color:'red'}}>*</span> Selectioner le genre de l'enseignant</label>

                                <div className="flex flex-wrap gap-3">
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="masculin" name="sexeEnseignant" value="M" onChange={(e) => this.onGenreEnseignantChance(e.value)} checked={this.state.enseignant.sexeEnseignant === 'M'} />
                                        <label htmlFor="masculin" className="ml-2">Masculin</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="feminin" name="sexeEnseignant" value="F" onChange={(e) => this.onGenreEnseignantChance(e.value)} checked={this.state.enseignant.sexeEnseignant === 'F'} />
                                        <label htmlFor="feminin" className="ml-2">Feminin</label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </Dialog>

                <Dialog visible={this.state.deleteEnseignantDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteEnseignantDialogFooter} onHide={this.hideDeleteEnseignantDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.enseignant && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

            </div>
        )
    }
}

export default Enseignant
