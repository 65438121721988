import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';
import { DepartementService } from '../../../lmd/service/DepartementService';
//import { set } from 'core-js/core/dict';

export default function ParametrerCompte(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    const [selectedEntites, setSelectedEntites] = useState(null)
    const [listeHierarchie, setListeHierarchie] = useState([])

    const [listeNouveauHierarchie, setListeNouveauHierarchie] = useState([])
    const [idIesr, setIdIesr] = useState(token.code_iesr)

    const [isEdition, setIsEdition] = useState(false)
    const [editionIndex, setEditionIndex] = useState(null)

    const [creationHierarchieDialog, setCreationHierarchieDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const [spin,setSpin] = useState(false)
    const [hierarchieDialog, setHierarchieDialog] = useState(false)

    const [acteur, setActeur] = useState("")
    const [superieur, setSuperieur] = useState("")
    const [curentDepartement, setCurentDepartement] = useState({})
    const [listeDepartementIesr, setListeDepartementIesr] = useState([])
    const [curentHierarchie, setCurentHierarchie] = useState({})
    

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()
    const departementService = new DepartementService()


    useEffect(() => {
        setLoading(true)
        invitationService.listeDepartementParametreUtilisateur(idIesr).then(data => {
            setListeHierarchie(data)
            setLoading(false)
            //console.log(data)

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setLoading(false)

        })

        departementService.getListeDepartement(idIesr).then(data => {
            setListeDepartementIesr(data)

            //console.log(data)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
        }, [] 
    );




    const hideDialog = () => {
        setHierarchieDialog(false)
        setCurentHierarchie({})
        setActeur("")
        setSuperieur("")
        setCurentDepartement({})
        setSubmitted(false)
        setIsEdition(false)
        setEditionIndex(null)
    }



    const creerHierarchie = () => {
        setCreationHierarchieDialog(true)
        setCurentDepartement({})
    }



    const editerHierarchie = (informationHierarchie) => {

        setCurentHierarchie(informationHierarchie)
        setActeur(informationHierarchie.acteur)
        setSuperieur(informationHierarchie.superieur)
        setCurentDepartement(informationHierarchie.departementEtabDto)

        if(!informationHierarchie.departementEtabDto){
            setCurentDepartement({})
        }

        //console.log(curentDepartement)
        setHierarchieDialog(true)
    }





    const editerNewHierarchie = (rowData, index) => {
        setIsEdition(true)
        setEditionIndex(index.rowIndex)


        if(rowData.departementEtabDto){
            setCurentDepartement(rowData.departementEtabDto)
        }else{
            setCurentDepartement({})
        }
        
        setActeur(rowData.acteur)
        setSuperieur(rowData.superieur)

        //console.log(index,rowData)
    }


    




    const saveHierarchieCompte = () => {

        //console.log(listeNouveauHierarchie)
        invitationService.enregistrerHierarchieUtilisateur(listeNouveauHierarchie).then(data => {
            setLoading(true)
            notify.success()
            invitationService.listeDepartementParametreUtilisateur(idIesr).then(data => {
                setListeHierarchie(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })
            hideCreationHierarchieDialog()
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
    }



    const editHierarchieCompte = () => {

        setSubmitted(true)
        if(acteur && superieur && curentDepartement.idDepartementEtab){

            let hierarchie = {
                idActeur: curentHierarchie.idActeur,
                acteur: acteur,
                superieur: superieur,
                departementEtabDto: curentDepartement,
                idIesr: idIesr,
                idDepartement: curentDepartement.idDepartementEtab
            }

            invitationService.editionActeur(curentHierarchie.idActeur, hierarchie).then(data => {
                setLoading(true)
                notify.success()
                invitationService.listeDepartementParametreUtilisateur(idIesr).then(data => {
                    setListeHierarchie(data)
                    setLoading(false)
                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                    setLoading(false)
                })
                hideDialog()
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
        }

    }


    const deleteHierarchie = (informationHierarchie) => {
        
        let confirmation = window.confirm("Voulez vous vraiment supprimer cette hierarchie ?")
        if(confirmation){
            invitationService.suppressionActeur(informationHierarchie.idActeur).then(data => {
                setLoading(true)
                notify.success()
                invitationService.listeDepartementParametreUtilisateur(idIesr).then(data => {
                    setListeHierarchie(data)
                    setLoading(false)
                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                    setLoading(false)
                })
                hideDialog()
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
        }
    }


    const onActeurChange = (e) => {
        setActeur(e.target.value)
    }

    const onSuperieurChange = (e) => {
        setSuperieur(e.target.value)
    }

    const onDepartementChange = (e) => {
        setCurentDepartement(e.target.value)
    }

    const ajoutHierarchie = () => {
        
        setSubmitted(true)
        if(acteur && superieur && curentDepartement.idDepartementEtab){

            let hierarchie = {
                acteur: acteur,
                superieur: superieur,
                departementEtabDto: curentDepartement,
                idIesr: idIesr,
                idDepartement: curentDepartement.idDepartementEtab
            }

            //console.log(idIesr)
            let listeActeur = listeNouveauHierarchie

            if(isEdition){
                listeActeur[`${editionIndex}`] = hierarchie
                setIsEdition(false)
                setEditionIndex(null)
            }else{
                listeActeur.push(hierarchie)
            }
            
            setListeNouveauHierarchie(listeActeur)
            setCurentDepartement({})
            setActeur("")
            setSuperieur("")
            setSubmitted(false)
        }

    }


    const deteleteNewHierarchie = (rowData, index) => {

        let listeActeur = listeNouveauHierarchie.filter((hierarchie, indexNumber) =>{
            return indexNumber!==index.rowIndex
        })

       setListeNouveauHierarchie(listeActeur)
    }


    const hideCreationHierarchieDialog = () => {
        setHierarchieDialog(false)
        setCurentHierarchie({})
        setActeur("")
        setSuperieur("")
        setCurentDepartement({})
        setSubmitted(false)
        setCreationHierarchieDialog(false)
        setListeNouveauHierarchie([])
        setIsEdition(false)
        setEditionIndex(null)
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Editer hiérarchie utilisateur" style={{marginRight: '.5em'}} onClick={() => editerHierarchie(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger p-mr-2" title="Supprimer hiérarchie utilisateur" style={{marginRight: '.5em'}} onClick={() => deleteHierarchie(rowData)} />
            </React.Fragment>
        );
    }

    const newHierarchieactionBodyTemplate = (rowData, index) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Editer hiérarchie utilisateur" style={{marginRight: '.5em'}} onClick={() => editerNewHierarchie(rowData, index)} />
                <Button icon="pi pi-trash" className="p-button-danger p-mr-2" title="Supprimer hierarchie utilisateur" style={{marginRight: '.5em'}} onClick={() => deteleteNewHierarchie(rowData, index)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">

            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>creerHierarchie()} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    /*
    const confirmerEnvoiInvitationFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideConfirmerInvitationDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={confirmerValidationInvitation} />
        </React.Fragment>
    );
    */
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeHierarchie} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Hierarchie dans la gestion des compte">
                    <Column field="departementEtabDto.lbDepartementEtab" header="Departement" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="acteur" header="Acteur" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="superieur" header="Superieur" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>


 
            <Dialog visible={hierarchieDialog} style={{ width: '95%' }} header="Parametrage d'une hierarchie" modal className="p-fluid" onHide={hideDialog}>
                
               {curentDepartement.idDepartementEtab && (
                    <div className="p-grid">
                        <div className="p-col-12" >
                            <div className="p-field">
                                <label htmlFor="departement">Departement </label>
                                <Dropdown optionLabel="lbDepartementEtab" optionValue={curentDepartement.lbDepartementEtab} value={curentDepartement}  options={listeDepartementIesr} onChange={(e) => {onDepartementChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                {submitted && !curentDepartement.lbDepartementEtab && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>} 
                            </div>
                        </div>
                    </div>
               )}
                
                
                <hr/>
                <div className="p-grid">
                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="acteur">Nom d'utilisateur de l'acteur</label>
                            <InputText id="acteur" value={acteur}  onChange={(e) => {onActeurChange(e)}} required autoFocus placeholder="Nom utilisateur acteur" className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !acteur && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}            
                        </div>
                    </div>
                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="superieur">Nom d'utilisateur responsable</label>
                            <InputText  id="superieur" value={superieur}  onChange={(e) => {onSuperieurChange(e)}} required autoFocus placeholder="Nom utilisateur superieur" className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !superieur && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}            
                        </div>
                    </div>
                </div>

                <hr/>
                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideDialog}  disabled={spin?true:false} />
                    <Button label='Enregistrer' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={editHierarchieCompte}  disabled={spin?true:false} />
                </div>

            </Dialog>





            <Dialog visible={creationHierarchieDialog} style={{ width: '95%' }} header="Parametrage d'une hierarchie" modal className="p-fluid" onHide={hideCreationHierarchieDialog}>
                <hr/>

                <div className="p-grid">
                    <div className="p-col-12" >
                        <div className="p-field">
                            <label htmlFor="departement">Departement </label>
                            <Dropdown optionLabel="lbDepartementEtab" optionValue={curentDepartement.lbDepartementEtab} value={curentDepartement}  options={listeDepartementIesr} onChange={(e) => {onDepartementChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentDepartement.lbDepartementEtab && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>} 
                        </div>
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="acteur">Nom d'utilisateur de l'acteur</label>
                            <InputText id="acteur" value={acteur}  onChange={(e) => {onActeurChange(e)}} required autoFocus placeholder="Nom utilisateur acteur" className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !acteur && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}            
                        </div>
                    </div>
                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="superieur">Nom d'utilisateur responsable</label>
                            <InputText  id="superieur" value={superieur}  onChange={(e) => {onSuperieurChange(e)}} required autoFocus placeholder="Nom utilisateur superieur" className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !superieur && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}            
                        </div>
                    </div>
                </div>

                <hr/>
                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label='Ajouter' icon={spin?"pi pi-spin pi-spinner":"pi pi-plus"} className='p-button-success mr-2' onClick={ajoutHierarchie}  disabled={spin?true:false} />
                </div>
                <hr/>


                <DataTable value={listeNouveauHierarchie} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Hierarchie dans la gestion des compte">
                    <Column field="acteur" header="Acteur" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="superieur" header="Superieur" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={newHierarchieactionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>


                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideCreationHierarchieDialog}  disabled={spin?true:false} />

                    <Button label='Enregistrer' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={saveHierarchieCompte}  disabled={(spin || (listeNouveauHierarchie.length)<=0)?true:false}  />
                </div>

            </Dialog>


            {/*

            creationHierarchieDialog

            <Dialog visible={confirmerEnvoiInvitationDialog} style={{ width: '450px' }} header="Confirmmation d'envoi bordereau" modal footer={confirmerEnvoiInvitationFooter} onHide={hideConfirmerInvitationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-info-circle p-mr-3" style={{ fontSize: '2rem'}} />
                    <center>
                        {informationBordereau && <span>Êtes vous sûr de vouloir confirmer l'envoi de ce bordereau au département ? </span>}
                    </center>
                </div>
            </Dialog>
            */}

        </div>
    )
}

