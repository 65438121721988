import React, { Component } from 'react'
import Keycloak from 'keycloak-js';

export class Deconnexion extends Component {
    
    componentDidMount = () => {
        let keycloak = localStorage.getItem('keycloak')
        keycloak = JSON.parse(keycloak)
        console.log(keycloak)
	}
    render() {
        return (
            <div>
                Composant Deconnexion
            </div>
        )
    }
}

export default Deconnexion
