import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { CohorteServices } from '../../service/CohorteServices';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { TypeActiviteService } from '../../../lmd/service/TypeActiviteService';
import { ECService } from '../../../lmd/service/ECService';

const CohortesTdTp = () => {
    
    let emptyEntite = {
        nombreParCohorte: "",
        critereTrie: ""
    };


    let optionTrie = [
        {
            critere: 'ordreAlphabetique',
            libelleCritere: 'Ordre alphabetique'
        },
        {
            critere: 'parAge',
            libelleCritere: 'Ordonner suivant l\'âge'
        },
        {
            critere: 'aleatoire',
            libelleCritere: 'Ordre aléatoire'
        }
    ]


    const [curentCohorte, setCurentCohorte] = useState(null);
    const [curentTypeActivite, setCurentTypeActivite] = useState(null);
    const [curentElementConstitutif, setCurentElementConstitutif] = useState(null);
    const [curentCritere, setCurentCritere] = useState(null)
    const [listeCohortes, setlisteCohortes] = useState(null);
    const [listeTypeActivite, setListeTypeActivite] = useState(null);
    const [listeEtudiantsCohorte, setListeEtudiantsCohorte] = useState(null);
    const [loadingLlisteEtudiant, setLoadingListeEtudiant] = useState(false)
    const [nombreEtudiants, setNombreEtudiants] = useState(0);
    const [cohorteTested, setCohorteTested] = useState(false);
    const [cohorteForm, setCohorteForm] = useState(emptyEntite);
    const [cohorte, setCohorte] = useState(null);
    const [listeElementConstitutif, setListeElementConstitutif] = useState(null);
    const [noEtudiant, setNoEtudiant] = useState(false)
    const [entiteDialog, setEntiteDialog] = useState(false);
    const [etudiantsDialog, setEtudiantsDialog] = useState(false);
    const [etudiantsDialogTitre, setEtudiantsDialogTitre] = useState(null);
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [idIesr, setIdIesr] = useState(null);
    const [idDepartement, setIdDepartement] = useState(null);
    const [listeCohortesCm, setListeCohortesCm] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [existeCohorte, setExisteCohorte] = useState(false);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const cohortesServices = new CohorteServices();
    const typeActiviteService = new TypeActiviteService();
    const ecService = new ECService();
    const notify = new Notify();
    const spinner = new Spinner();

    useEffect(() => {

        //On recupère l'identifiant de l'université ou du département (A corriger pour une effectivité)
        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)


        setLoading(true)
        let idTypeActivite = 2
        if(token.code_departement){
            setIdDepartement(token.code_departement);
            cohortesServices.getListeCohorteDepartement(token.code_departement, idTypeActivite).then(data => {
                setlisteCohortes(data)
                setLoading(false)
                cohortesServices.getListeCohorteDepartement(token.code_departement, "").then(data => {
                    setListeCohortesCm(data)
                }).catch(error => {
                    console.log(error)
                })

            }).catch(error => {
                console.log(error);
                notify.echec(error.response.data.message)
                setLoading(false)
            })


        }else
        if (token.code_iesr){

            setIdIesr(token.code_iesr)
            cohortesServices.getListeCohorteUniversite(token.code_iesr, idTypeActivite).then(data => {

                setlisteCohortes(data)
                setLoading(false)
                cohortesServices.getListeCohorteUniversite(token.code_departement, "").then(data => {
                    setListeCohortesCm(data)
                }).catch(error => {
                    console.log(error)
                })

            }).catch(error => {
                console.log(error);
                notify.echec(error.response.data.message)
                setLoading(false)
            })
        }


        }, []
    );

   
    const openNew = () => {
        setCohorteForm(emptyEntite);
        setSubmitted(false);
        setEntiteDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEntiteDialog(false);
        setCohorteTested(false) 
        setNombreEtudiants(0)
        setNoEtudiant(false)
    }

    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false);
    }

    const validerFormCohorte = () => {
        let curentCohorteForm = cohorteForm
        setSubmitted(true)
        if(curentCohorte && curentElementConstitutif){
            
            let objetCohorte = {
                idParcoursFiliere: curentCohorte.idParcoursFiliere,
                idNiveau: curentCohorte.idNiveau,
                idAnneeAcademique: curentCohorte.idAnneeAcademique,
                paramOrder: curentCohorteForm.critereTrie.critere,
                idTypeActivite: curentTypeActivite.idTypeActivite,
                nbEtudiantCohorte: curentCohorteForm.nombreParCohorte,
                idCohorteParent: curentCohorte.idCohorte,
                idElementConstitutif: curentElementConstitutif.idElementConstitutif
            }

            //console.log(objetCohorte)
            
            cohortesServices.createCohorteTd(objetCohorte).then(data => {
                notify.success()
                //console.log(data)
                setLoading(true)

                
                //On recupère de nouveau la liste des cohorte pour constater le nouveau changement
                let idTypeActivite = 2
                cohortesServices.getListeCohorteUniversite(1, idTypeActivite).then(data => {
                    setlisteCohortes(data)
                }).catch(error => {
                    console.log(error);
                })




        if(idDepartement){
            
            cohortesServices.getListeCohorteDepartement(idDepartement, idTypeActivite).then(data => {


                setlisteCohortes(data)
                setLoading(false)

            }).catch(error => {
                console.log(error);
                setLoading(false)
            })


        }else
        if (idIesr){
            
            cohortesServices.getListeCohorteUniversite(idIesr, idTypeActivite).then(data => {

                setlisteCohortes(data)
                setLoading(false)

            }).catch(error => {
                console.log(error);
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }
                
            setCohorteForm(emptyEntite)
            setSubmitted(false)
            setEntiteDialog(false)
            setCohorteTested(false) 
            setNombreEtudiants(0)
            setNoEtudiant(false)

            }).catch(error => {
                console.log(error)
            })
            
        }
    }

    const testerExistanceCohorte = () => {
        setSubmitted(true)

        if(curentCohorte){
            cohortesServices.testerExistanceCohorteTdTp(curentCohorte.idParcoursFiliere, curentCohorte.idNiveau, curentCohorte.idAnneeAcademique, curentTypeActivite.idTypeActivite).then(data => {
                

                if(data.length>0){
                    setExisteCohorte(true)
                }else{



                    //Si le nombre est renvoyé, on passe à l'étape du choix du nombre d'étudiants par cohorte et du critère de trie
                    cohortesServices.getNbEtudiantsInscritEc(curentCohorte.idParcoursFiliere, curentCohorte.idNiveau, curentCohorte.idAnneeAcademique, curentElementConstitutif.idElementConstitutif).then(data => {
                        if(data>0){
                            setNombreEtudiants(data)
                            setCohorteTested(true)
                        }else{
                            setNoEtudiant(true)
                        }
                    }).catch(error => {
                        console.log(error)
                    })

                }

            }).catch(error => {
                console.log(error)
                setSubmitted(false)
            })
        }

        
    }


    const showListeEtudiantsCohorte = (cohorte) => {
        setCohorte(cohorte)
        
        setLoadingListeEtudiant(true)
        let titre = "Liste des étudiants de la cohorte: "+cohorte.libelleCohorte
        setEtudiantsDialogTitre(titre)
        setEtudiantsDialog(true)

        cohortesServices.getListeEtudiantsCohorte(cohorte.idCohorte).then(data => {
            setListeEtudiantsCohorte(data)
            titre = titre + " ( " + data.length + " etudiants ) "
            setEtudiantsDialogTitre(titre)

            setLoadingListeEtudiant(false)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setLoadingListeEtudiant(false)
        })
    }


    
    const confirmDeleteCohorte = (cohorte) => {

        //console.log(cohorte)
        setCohorte(cohorte);
        setDeleteEntiteDialog(true);
    }

    
    const deleteEntite = () => {

        //console.log(cohorte.idCohorte)
        cohortesServices.deleteCohorte(cohorte.idCohorte).then(data => {

            notify.success()
            setLoading(true)
            let idTypeActivite = 2

            if(idIesr){
                cohortesServices.getListeCohorteUniversite(idIesr, idTypeActivite).then(data => {
                    setlisteCohortes(data)
                    setLoading(false)
                    
                    setCohorte(null)
                    setDeleteEntiteDialog(false)
                }).catch(error => {
                    console.log(error);
                    setLoading(false)
                })
            }else 
            if(idDepartement){

                cohortesServices.getListeCohorteDepartement(idDepartement, idTypeActivite).then(data => {
                    setlisteCohortes(data)
                    setLoading(false)
                    
                    setCohorte(null)
                    setDeleteEntiteDialog(false)
                }).catch(error => {
                    console.log(error);
                    setLoading(false)
                })

            }

        }).catch(error => {
            notify.echec('Echec de la suppression')
            console.log(error)
        })

    }

    const hideEtudians = () => {
        setEtudiantsDialog(false)
    }
    

    const onNumberChampsChange = (e, name) => {

        //Si rien n'est saisie, la valeur retenue est ""
        let val = (e.target && e.target.value) || "";
        let curentCohorteForm = {...cohorteForm}
        //Si la valeur n'est pas un numerique, et est different de chaine vide alors on prend temporairement une chaine vide ""
        val = (val!=="" && !isNaN(val))?val:"";

        if(val==""){
            //A ce statde, si la valeur est une chaine vide, il se peut que ce soit parceque c'est pas un numerique, ou que c'est effectivement une chaine vide
            val = (!isNaN(curentCohorteForm[`${name}`]) && curentCohorteForm[`${name}`].length>1)?curentCohorteForm[`${name}`]:"";
        }
        curentCohorteForm[`${name}`] = val;
        setCohorteForm(curentCohorteForm);
    }

    const onDropDownChange = (e, element) => {
        let curentFormData = cohorteForm

        curentFormData[`${element}`] = e.target.value
        setCohorteForm(curentFormData)
        if(submitted){
            setExisteCohorte(false)
            setNoEtudiant(false)
            setSubmitted(false)
        }

        if(element==='critereTrie'){
            setCurentCritere(e.target.value)
        }

        //console.log(cohorteForm)
    }

    const onCohorteChange = (e) => {
        setCurentCohorte(e.target.value)

        //on positionne alors la liste des types activites
        typeActiviteService.getAll().then(data => {

            let idTypeActiviteChoix = [2,3]

            let listeTypeActivite = data.filter(typeActivite => {
                return (idTypeActiviteChoix.indexOf(typeActivite.idTypeActivite)!==-1)
            })

            //console.log(listeTypeActivite)

            setListeTypeActivite(listeTypeActivite)

        }).catch(error => {
            console.log(error)
        })
    }
    
    const onTypeActiviteChange = (e) => {
        setCurentTypeActivite(e.target.value)

        //On peut maimtenant recuperer la liste des EC
        //console.log(curentCohorte.idAnneeAcademique)

        ecService.getListeEcTypeActivite(curentCohorte.idParcoursFiliere, e.target.value.idTypeActivite, curentCohorte.idAnneeAcademique).then(data =>  {
            setListeElementConstitutif(data)

            //console.log(data)

        }).catch(error => {
            //console.log(curentCohorte)
            //console.log(e.target.value.idTypeActivite)
            console.log(error)
        })

    }


    const onElementConstitutifChange = (e) => {
        setCurentElementConstitutif(e.target.value)

        testerExistanceCohorte()
    }



   
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-list" className="p-button-warning p-mr-2" title="Liste des étudiants" onClick={() => showListeEtudiantsCohorte(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" title="Suppression" onClick={() => confirmDeleteCohorte(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }


    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                {/* <i className="pi pi-search" /> */}
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );


    const entiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {cohorteTested? 
                (<Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={validerFormCohorte} />):
                (<Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={testerExistanceCohorte} />)}
        </React.Fragment>
    );



    const etudiantsDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideEtudians} />
        </React.Fragment>
    );

    

    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteEntite} />
        </React.Fragment>
    );

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={listeCohortes} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des groupes de TD/TP">
                    <Column field="libelleCohorte" header="Libelle groupe" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="libelleAnnee" header="Année académique" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nbEtudiantCohorte" header="Nombre d'étudiant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="cohorteParent.libelleCohorte" header="Cohorte" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>


            <Dialog visible={entiteDialog} style={{ width: '85%' }} header="Création de cohorte" modal className="p-fluid" footer={entiteDialogFooter} onHide={hideDialog}>
                
                <div className="p-grid">
                    <div className="p-col-12" >
                        <hr/>
                    </div>

                    <div className="p-col-12" >
                        <div className="p-field">
                            <label htmlFor="libelleCohorte">Cohorte de cours magistrale concernée par le TD/TP</label>
                            <Dropdown optionLabel="libelleCohorte" optionValue="idCohorte" value={curentCohorte} options={listeCohortesCm} onChange={(e) => {onCohorteChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted && !curentCohorte })} placeholder="Parcours" filter showClear filterBy="libelleCohorte"/>
                            {submitted && !curentCohorte && <small className="p-error">Ce champs est obligatoire.</small>}
                        </div>
                    </div>



                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="libelleTypeActivite">Selectionner le type d'activite concerné</label>
                            <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={curentTypeActivite} options={listeTypeActivite} onChange={(e) => {onTypeActiviteChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted && !curentTypeActivite })} placeholder="Type activité" />
                            {submitted && !curentTypeActivite && <small className="p-error">Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="libelleElementConstitutif">Element constitutif</label>
                            <Dropdown optionLabel="libelleElementConstitutif" optionValue="idElementConstitutif" value={curentElementConstitutif} options={listeElementConstitutif} onChange={(e) => {onElementConstitutifChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted && !curentElementConstitutif })} placeholder="Element constitutif" filter showClear filterBy="libelleElementConstitutif"/>
                            {submitted && !curentElementConstitutif && <small className="p-error">Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className="p-col-12" >
                        {existeCohorte && <small className="p-error">Ce parcours dispose déjà de cohortes</small>}
                        {noEtudiant && <small className="p-error">Ce parcours ne dispose d'aucun étudiant inscrit. Veuillez changer la sélection</small>}
                    </div>

                    {!cohorteTested &&
                    <React.Fragment>
                        <div className="p-col-12">
                            <hr/>
                                Nombre d'étudiants inscrits: {nombreEtudiants}
                            <hr/>
                        </div>

                        <div className="p-col-6" >
                            <div className="p-field">
                                <label htmlFor="nombreParCohorte">Nombre d'étudiant par cohorte</label>
                                <InputText id="nombreParCohorte" value={cohorteForm.nombreParCohorte} onChange={(e) => onNumberChampsChange(e, 'nombreParCohorte')} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.nombreParCohorte })} />
                                {submitted && !cohorteForm.nombreParCohorte && <small className="p-error">Ce champs est obligatoire.</small>}
                            </div>
                        </div>

                        <div className="p-col-6" >
                            <div className="p-field">
                                <label htmlFor="libelleCritere">Critère de trie</label>
                                <Dropdown optionLabel="libelleCritere" optionValue="critere" value={curentCritere} options={optionTrie} onChange={(e) => {onDropDownChange(e, 'critereTrie')}} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.critereTrie })} placeholder="Sélectionner le critère de trie" />
                                {submitted && !cohorteForm.critereTrie && <small className="p-error">Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </React.Fragment>
                }
                </div>

            </Dialog>
            

            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {cohorte && <span>Etes vous sûr de supprimer cet élément ? <b>{cohorte.libelleCohorte}</b>?</span>}
                </div>
            </Dialog>



            
            <Dialog visible={etudiantsDialog} style={{ width: '95%' }} header={etudiantsDialogTitre} modal className="p-fluid" footer={etudiantsDialogFooter} onHide={hideEtudians}>
                <hr/>
                {loadingLlisteEtudiant ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={listeEtudiantsCohorte} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={50} 
                    globalFilter={globalFilter}
                    header={etudiantsDialogTitre}>
                    <Column field="ine" header="INE" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nom" header="Nom" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="prenom" header="Prénom(s)" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateNaissance" header="Date de naissance" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="telephone" header="Telephone" filterMatchMode="contains" sortable={true} filter={true}></Column>
                </DataTable>
                }
            </Dialog>

        </div>
    )

}
export default CohortesTdTp
