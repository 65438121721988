import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ModeEnseignementService {
     constructor(){
         this.adresse = new AdresseIP()
     }
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mode-enseignement"
        return axios.get(url).then(res => res.data) 
    }
    
    create(newModeEnseignement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mode-enseignement"
        return axios.post(url,newModeEnseignement)
                    .then(res => res.data);
    }
    update(idModeEnseignement ,newModeEnseignement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mode-enseignement/"
        return axios.put(url+idModeEnseignement, newModeEnseignement)
                    .then(res => res.data);
    }
    delete(idModeEnseignement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mode-enseignement/"
        return axios.patch(url+idModeEnseignement)
                    .then(res => res.data)
    }

}