export class AdresseIP {

    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    getAdresseIP() {
        //return 'http://192.168.212.187:8084'
        //return 'http://192.168.43.14:8084'
        //return "https://api2.campusfaso.bf:8084";
        return 'https://api2.campusfaso.bf:8084'
        //return 'http://192.168.43.22:8084'
        //return 'http://192.168.223.187:8084'
    }

    getAdresseIPDepotEnLigne() {
        //return 'https://api2.campusfaso.bf:8086'
        return "https://api2.campusfaso.bf:8086";
        //return 'http://192.168.223.187:8086'
    }

    getAdresseVolumeHoraire() {
        //return 'http://192.168.212.187:8088'
        //return 'http://192.168.43.22:8088'
        //return 'http://192.168.43.133:8088'
        return "https://api2.campusfaso.bf:8088";
        //return 'https://api2.campusfaso.bf:8088'
    }

    getAdresseIneFaso() {
        //return 'http://192.168.212.187:8082'
        //return 'http://192.168.43.22:8082'
        return "https://api2.campusfaso.bf:8082";
        //return 'https://api2.campusfaso.bf:8082'
    }  

   
}
