import React, { Component } from 'react'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'

export class Compte extends Component {
    
     emptyUser = {
        ine : ""
    }
    state = {
        user : this.emptyUser,
        submitted : false,
        inscriptionDialog: false,
    }

    componentDidMount = () => {
        this.setState({
            user: this.emptyUser,
            submitted: false,
            inscriptionDialog: true
        });
    }

    hideDialogInscription = () => {
        this.setState({
            submitted: false,
            inscriptionDialog: false
        });
    }

    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user
        user[`${field}`] = val
        this.setState({user})      
    }

    render() {
        const inscriptionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogInscription} />
                <Button label="S'inscrire" icon="pi pi-check" className="p-button-text" onClick={this.verifeUser} />
            </React.Fragment>
        );
        return (
            <div>
                <Dialog visible={this.state.inscriptionDialog} style={{ width: '30%' }} header="Création de compte" modal className="p-fluid" footer={inscriptionDialogFooter} onHide={this.hideDialogInscription}>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="libelleUniteEnseignement">Entrer votre INE</label>
                                <InputText value={this.state.user.ine} onChange={(e) => {this.onValueChange(e, 'ine')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.user && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                            </div>
                        </div>
                    </div>

                </Dialog>
            </div>
        )
    }
}

export default Compte
