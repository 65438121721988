import React, { Component } from "react";
import { Menubar } from "primereact/menubar";
import { Route } from "react-router-dom";
import InscriptionAdministrative from "../lmd/components/InscriptionAdministrative";
import InscriptionPedagogique from "../lmd/components/InscriptionPedagogique";
import EmploisDuTemps from "../volumeHoraire/components/EmploisDuTemps";

export class MenuEtudiant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: 1,
            structure: {},
            keycloak: null,
            verife: false,
            boolenAcceuil: false,
        };

        //this.structureService = new StructureService();
    }
    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        this.setState({ token: token });
    };

    render() {
        const itemsEtudiant = [
            {
                label: "Tableau de bord",
                icon: "pi pi-fw pi-power-offs",
                command: () => {
                    window.location.hash = "/lmd/dashboard";
                },
            },
            {
                label: "Gestion des inscriptions",
                icon: "pi pi-fw pi-files",
                items: [
                    {
                        label: "Inscription administrative",
                        icon: "pi pi-fw pi-pluss",
                        command: () => {
                            window.location.hash =
                                "/lmd/inscription-administrative";
                        },
                    },
                    {
                        separator: true,
                    },
                    {
                        label: "Inscription pédagogique",
                        icon: "pi pi-fw pi-pencils",
                        command: () => {
                            window.location.hash =
                                "/lmd/inscription-pedagogique";
                        },
                    },
                ],
            },
            {
                label: "Emploi du temps",
                icon: "pi pi-fw pi-power-offs",
                command: () => {
                    window.location.hash = "/volume-horaire/emploi-temps";
                },
            },
            {
                label: "Séances effectuées",
                icon: "pi pi-fw pi-power-offs",
                command: () => {
                    window.location.hash = "/volume-horaire/seance-effectuee";
                },
            },
            {
                label: "Parcours",
                icon: "pi pi-fw pi-power-offs",
                command: () => {
                    window.location.hash = "/lmd/parcours-etudiant";
                },
            },
        ];
        return (
            <div>
                <div className="html">
                    <div className="body">
                        <div className="grid-container">
                            <header className="header">
                                <div className="logo">
                                    <a
                                        className="navbar-brand link-menu scroll-to-target current"
                                        href="/"
                                    >
                                        <img
                                            style={{ marginLeft: -700 }}
                                            className="logo"
                                            src="/assets/layout/images/CampusFaso_Logo.png"
                                            alt="CampusFaso"
                                        />
                                    </a>
                                </div>
                                <div className="">
                                    <text
                                        style={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            marginLeft: -600,
                                        }}
                                    >
                                        Plateforme integrée de l'Enseignement
                                        Supérieur CAMPUSFASO
                                    </text>
                                </div>
                                {this.state.token ? (
                                    <div className="header-text">
                                        <i className="ti-user"></i>
                                        Bienvenue{" "}
                                        <span style={{ color: "green" }}>
                                            {this.state.token.name}
                                        </span>
                                        {" ( " +
                                            this.state.structure
                                                .libelleStructure +
                                            " ) "}{" "}
                                        |
                                        <a
                                            href="#"
                                            onClick={() => {
                                                localStorage.clear();
                                                {
                                                    /*this.props.clickService  ?  this.props.keycloak.keycloak.logout() :*/ this.props.keycloak.logout();
                                                }
                                            }}
                                        >
                                            <i className="ti-close"></i>
                                            Déconnexion
                                        </a>{" "}
                                        |
                                        <div
                                            href="#"
                                            className="btn"
                                            onClick={this.loadAcceuilPage}
                                        >
                                            <i className="ti-home"></i>
                                            Accueil
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </header>

                            <main className="main">
                                <div className="cards titles">
                                    <Menubar model={itemsEtudiant} />
                                </div>
                                <div>
                                    <Route
                                        path="/lmd/inscription-administrative"
                                        component={InscriptionAdministrative}
                                    />
                                    <Route
                                        path="/lmd/inscription-pedagogique"
                                        component={InscriptionPedagogique}
                                    />
                                    <Route
                                        path="/volume-horaire/emploi-temps"
                                        component={EmploisDuTemps}
                                    />
                                </div>
                                {/*<div className="title">Mes <span style={{fontWeight : "bold"}}>services</span></div>*/}
                            </main>
                            <div className="footer">
                                <ul className="liens">
                                    <li>
                                        <a href="">CGU</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            Politique de confidentialité
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">FAQ</a>
                                    </li>
                                </ul>
                                <p>
                                    &copy; 2021 &middot; MESRSI &middot;Tous
                                    droits réservés
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuEtudiant;
