import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { ECHoraireService } from '../../service/ECHoraireService';
import { ParcoursFiliereService } from '../../service/ParcoursFiliereService';
import { SemestreService } from '../../service/SemestreService';
import { UEService } from '../../service/UEService';
import { TypeActiviteService } from '../../service/TypeActiviteService';
import { EnseignantService } from '../../service/EnseignantService';
import { ParcoursUEService } from '../../service/ParcoursUEService';
import { AnneeService } from '../../service/AnneeService';

export class ECHoraire extends Component {

    emptyECHoraire = {
        idECHoraire: null,
        nbHeure : null,
        parcours: null,
        semestre: null,
        ue: null,
        ec: null,
        typeActivite: null,
        enseignant: null,
        annee : null
    }

    constructor() {
        super();
        this.state = {
            ecHoraire: this.emptyECHoraire,
            ecHoraires : null,
            ecHoraireEns : null,
            listeEcHoraire : [],
            listeParcours : [],
            listeSemestre : [],
            listeUE : [],
            listeEC : [],
            listeTypeActivite : [],
            listeEnseignant : [],
            listAnnee : [],
            listeChoixEcEnseignant : [],
            listeChoixEcEnseignantEnvoie : [],
            ecHoraireDialog: false,
            deleteEcHoraireDialog: false,
            afficheEcHoraireDialog : false,
            afficheEcHoraireModifDialog : false,
            submitted: false,
            verife : false,
            confirmDialog : false,
            redirect : false,
            loading : false,
            globalFilter: null,
            curentAnnee: {},
            annee : {},
            anneePrecedente : null,
            curentParcours: {},
            idDepartement: null,
            listeCleUnicite: [],
            viewSelectEnseignantDialog: false,
            confirmDuplicationDialog : false,
            dataExist : true
        }
         
        this.notify = new Notify()
        this.ecHoraireService = new ECHoraireService();
        this.parcoursFiliereService = new ParcoursFiliereService();
        this.semestreService = new SemestreService()
        this.ueService = new UEService()
        this.parcoursUEService = new ParcoursUEService()
        this.typeActiviteService = new TypeActiviteService()
        this.enseignantService = new EnseignantService()
        this.anneeService = new AnneeService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveEcHoraire = this.saveEcHoraire.bind(this);
        this.confirmDeleteEcHoraire = this.confirmDeleteEcHoraire.bind(this);
        this.deleteEcHoraire = this.deleteEcHoraire.bind(this);
        this.hideDeleteEcHoraireDialog = this.hideDeleteEcHoraireDialog.bind(this);
    }

    componentDidMount() {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)
        this.setState({
            idDepartement: token.code_departement
        })

       



        this.parcoursFiliereService.getListeParcoursDepartement(token.code_departement).then(data => {
            this.setState({listeParcours: data})
            })
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
        
        // this.setState({
        //     loading: true
        // })

        // this.ecHoraireService.getAllEcHoraireDepartement(token.code_departement).then(data => 
        //     this.setState({
        //         listeEcHoraire : data,
        //         loading: false
        //     })
        // ).catch(error => {
        //     console.log(error)
        //     this.notify.echec(error.response.data.message)
        //     this.setState({
        //         loading: false
        //     })
        // })

        
        /*
        this.ecHoraireService.getAll().then(data => {

            console.log(data);
            this.setState({listeEcHoraire : data})
            this.setState({
                loading: false
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({
                loading: false
            })
        })
        */


        this.anneeService.getAll().then(data => {
            this.setState({listAnnee: data,})
            
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        this.typeActiviteService.getAll().then(data => 
            this.setState({listeTypeActivite : data})            
        )
        this.enseignantService.getAll().then(data => {
            this.setState({listeEnseignant : data})
        }
        )

        
        // this.semestreService.get().then(data => {
        //     this.setState({listeSemestre : data})
        //     console.log(data)
        // })
        
        
    }



   

    validerChoix = () => {

        const anneePrecedenteDebut = this.state.ecHoraire.annee.anneeDebut - 1
        const anneePrecedenteFin = this.state.ecHoraire.annee.anneeFin - 1
        const valAnneePrecedente = anneePrecedenteDebut+"-"+anneePrecedenteFin
       

        this.setState({
            anneePrecedente : valAnneePrecedente
        })
          
        const idAnneeAcademique = this.state.ecHoraire.annee.idAnneeAcademique
        const idParcours = this.state.ecHoraire.parcours.idParcours
        const idParcoursFiliere = this.state.ecHoraire.parcours.idParcoursFiliere
        const idSemestre = this.state.ecHoraire.semestre.idSemestre
        //console.log(idAnnee, idParcoursFiliere, idSemestre, idDepartementFiliere)
        console.log(idParcoursFiliere)
       
            this.setState({
                loading : true
            })
            this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(idAnneeAcademique,idParcoursFiliere,idSemestre).then(data => 
            {  
                if(data.length === 0 ){
                    //console.log("Pas de données pour cette année.", this.state.dataExist)
                    
                    this.setState({
                        dataExist : false,
                        listeEcHoraire : data,                        
                        loading: false
                    })

                    //console.log("Pas de données pour cette année.", this.state.dataExist)
                   
                    
                }else{
                    console.log("Données disponible.")

                    this.setState({
                        dataExist : true,
                        listeEcHoraire : data,
                        loading: false    
                    })
                }
            }
            ).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })

    }

    reconduireAffectation = () => {
       
        const idAnneeAcademique = this.state.ecHoraire.annee.idAnneeAcademique
        const idParcoursFiliere = this.state.ecHoraire.parcours.idParcoursFiliere
        //const idDepartement = this.state.idDepartement
        const idSemestre = this.state.ecHoraire.semestre.idSemestre
        this.setState({
            loading : true,
            confirmDuplicationDialog : false
        })

        //console.log(idAnneeAcademique,idParcoursFiliere,idDepartement,idSemestre)
      


        this.ecHoraireService.getListeEnseignantEcAnnee(idAnneeAcademique,idParcoursFiliere,idSemestre).then(data =>{
            this.setState({
                dataExist : true,
                listeEcHoraire : data,
                loading: false
                 
            })

            this.notify.success()
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({
                loading: false
            })
        })
    }



   
    
    openNew() {
        
        this.setState({
            ecHoraire: this.emptyECHoraire,
            submitted: false,
            ecHoraireDialog: true,
            listeCleUnicite: []
        });
    }
    hideDialog () {
        if(this.state.listeChoixEcEnseignantEnvoie.length > 0) {
            this.setState({confirmDialog : true})   
        }
        else {
            this.setState({
                submitted: false,
                ecHoraireDialog: false,
                listeCleUnicite: []
            });
        }
        
        
    }

    hideDeleteEcHoraireDialog() {
        this.setState({ 
            deleteEcHoraireDialog: false,
            listeCleUnicite: [] 
        });
    }

    hideConfirmDuplicationDialog =() => {
        this.setState({ 
            confirmDuplicationDialog: false,
        });
    }

    saveEcHoraire() {
        let state = { submitted: true };

        const idAnneeAcademique = this.state.ecHoraire.annee.idAnneeAcademique
        const idParcoursFiliere = this.state.ecHoraire.parcours.idParcoursFiliere
        const idSemestre = this.state.ecHoraire.semestre.idSemestre
        let listeEcHoraire = this.state.listeChoixEcEnseignantEnvoie
        //console.log(listeEcHoraire)
        this.ecHoraireService.create(listeEcHoraire).then(data => {
            console.log(data)

            console.log("Annee :", idAnneeAcademique)
            console.log("ParcoursFiliere :", idParcoursFiliere)
            console.log("Departement :", this.state.idDepartement)


            this.setState({ecHoraire: this.emptyECHoraire,ecHoraires: null,})
            this.notify.success()


            state = {
                ...state,
                ecHoraireDialog: false,
                ecHoraire: this.emptyECHoraire,
                listeChoixEcEnseignant : [],
                listeChoixEcEnseignantEnvoie : [],
                listeCleUnicite: []
            };
            this.setState(state);

            this.setState({loading: true})

            

            // if(!this.state.curentAnnee.idAnneeAcademique && !this.state.curentParcours.idParcours){
                
            //     this.ecHoraireService.getAllEcHoraireDepartement(this.state.idDepartement).then(data => 
            //         this.setState({
            //             listeEcHoraire : data,
            //             loading: false
            //         })
            //     ).catch(error => {
            //         console.log(error)
            //         this.notify.echec(error.response.data.message)
            //         this.setState({
            //             loading: false
            //         })
            //     })

            // }
            //else
            // if(this.state.curentAnnee.idAnneeAcademique && this.state.curentParcours.idParcours){

            //     this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(this.state.idDepartement, this.state.curentParcours.idParcours, this.state.curentAnnee.idAnneeAcademique).then(data => 
            //         this.setState({
            //             listeEcHoraire : data,
            //             loading: false
            //         })
            //     ).catch(error => {
            //         console.log(error)
            //         this.notify.echec(error.response.data.message)
            //         this.setState({
            //             loading: false
            //         })
            //     })

            // }else

            // if(this.state.curentAnnee.idAnneeAcademique){

            //     this.ecHoraireService.getEcHoraireDepartementAnnee(this.state.idDepartement, this.state.curentAnnee.idAnneeAcademique).then(data => 
            //         this.setState({
            //             listeEcHoraire : data,
            //             loading: false
            //         })
            //     ).catch(error => {
            //         console.log(error)
            //         this.notify.echec(error.response.data.message)
            //         this.setState({
            //             loading: false
            //         })
            //     })
            // }else{

            this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(idAnneeAcademique,idParcoursFiliere,idSemestre).then(data => 
                this.setState({
                    listeEcHoraire : data,
                    loading: false
                })
            ).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })
            //}


        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error);
        })
            
    }
    
    confirmDeleteEcHoraire(ecHoraires) {
        this.setState({
            ecHoraires,
            deleteEcHoraireDialog: true,
            listeCleUnicite: []
        });
    }
    deleteEcHoraire(idECHoraire) {
        this.ecHoraireService.delete(parseInt(idECHoraire)).then(data => {
            this.notify.success()
            this.setState({
                deleteEcHoraireDialog: false,
                ecHoraire: this.emptyECHoraire,
                ecHoraires: null,
                redirect : true,
                listeCleUnicite: []
            });


            this.setState({loading: true})

            if(!this.state.curentAnnee.idAnneeAcademique && !this.state.curentParcours.idParcours){
                
                this.ecHoraireService.getAllEcHoraireDepartement(this.state.idDepartement).then(data => 
                    this.setState({
                        listeEcHoraire : data,
                        loading: false
                    })
                ).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loading: false
                    })
                })

            }else
            if(this.state.curentAnnee.idAnneeAcademique && this.state.curentParcours.idParcours){

                this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(this.state.idDepartement, this.state.curentParcours.idParcours, this.state.curentAnnee.idAnneeAcademique).then(data => 
                    this.setState({
                        listeEcHoraire : data,
                        loading: false
                    })
                ).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loading: false
                    })
                })

            }else

            if(this.state.curentAnnee.idAnneeAcademique){

                this.ecHoraireService.getEcHoraireDepartementAnnee(this.state.idDepartement, this.state.curentAnnee.idAnneeAcademique).then(data => 
                    this.setState({
                        listeEcHoraire : data,
                        loading: false
                    })
                ).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loading: false
                    })
                })
            }else{

                this.ecHoraireService.getEcHoraireDepartementParcours(this.state.idDepartement, this.state.curentParcours.idParcours).then(data => 
                    this.setState({
                        listeEcHoraire : data,
                        loading: false
                    })
                ).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({
                        loading: false
                    })
                })
            }


            this.ecHoraireService.getAll().then(data => {
                this.setState({listeEcHoraire : data})
                
            })
        })
    }
   

    confirmDuplication = () => {
        this.setState({
            confirmDuplicationDialog: true,
        });
    }
    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        let ecHoraire = this.state.ecHoraire
        ecHoraire[`${field}`] = val
        
        if(field ==="annee") {

            this.setState({annee : val})
            //console.log(val)
        }

        if(field === "parcours"){
            this.loadSemestre(ecHoraire.parcours.idParcours)
            //console.log(ecHoraire.parcours.idParcours, ecHoraire.parcours.idParcoursFiliere, ecHoraire.parcours.idDepartementFiliere)
            //this.setState({idDepartementFiliere : ecHoraire.parcours.idDepartementFiliere})
        }
        if(field === "semestre"){
            this.loadUE(this.state.ecHoraire.parcours.idParcours, ecHoraire.semestre.idSemestre)
            //console.log(ecHoraire.semestre.idSemestre)
        } 
        if(field === "ue"){
            this.setState({listeEC : val.uniteEnseignement.listeElementConstitutif})
        }
        
        if(field === "ec" || field === "typeActivite"){
            if(ecHoraire["ec"] && ecHoraire["typeActivite"]){

                if(ecHoraire["typeActivite"].idTypeActivite === 1){
                    ecHoraire["nbHeure"] = ecHoraire["ec"].nbHeureCours
                } else if(ecHoraire["typeActivite"].idTypeActivite === 2){
                    ecHoraire["nbHeure"] = ecHoraire["ec"].nbHeureTD
                } else if(ecHoraire["typeActivite"].idTypeActivite === 2){
                    ecHoraire["nbHeure"] = ecHoraire["ec"].nbHeureTP
                }else{
                    ecHoraire["nbHeure"] = 0
                }
            }
        }
        this.setState({ecHoraire})

    }


    selectionEnseignant = (enseignant) => {

        let ecHoraire = this.state.ecHoraire
        ecHoraire["enseignant"] = enseignant
        this.setState({
            ecHoraire: ecHoraire,
            viewSelectEnseignantDialog: false
        })
    }

   


    onAnneeChange = (e) => {
        this.setState({
            curentAnnee: e.target.value
        })


        this.setState({
            loading: true
        })

        if(this.state.curentParcours.idParcours){

            this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(this.state.idDepartement, this.state.curentParcours.idParcours, e.target.value.idAnneeAcademique).then(data => 
                this.setState({
                    listeEcHoraire : data,
                    loading: false
                })
            ).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })

        }else{


            this.ecHoraireService.getEcHoraireDepartementAnnee(this.state.idDepartement, e.target.value.idAnneeAcademique).then(data => 
                this.setState({
                    listeEcHoraire : data,
                    loading: false
                })
            ).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })

        }

    }

    onParcoursChange = (e) =>  {
        this.setState({
            curentParcours: e.target.value
        })


        this.setState({
            loading: true
        })

        if(this.state.curentAnnee.idAnneeAcademique){

            this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(this.state.idDepartement, e.target.value.idParcours, this.state.curentAnnee.idAnneeAcademique).then(data => 
                this.setState({
                    listeEcHoraire : data,
                    loading: false
                })
            ).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })

        }else{

            this.ecHoraireService.getEcHoraireDepartementParcours(this.state.idDepartement, e.target.value.idParcours).then(data => 
                this.setState({
                    listeEcHoraire : data,
                    loading: false
                })
            ).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({
                    loading: false
                })
            })

        }
    }


    saveListe = (e) => {

        let annee = this.state.ecHoraire.annee
        let parcours = this.state.ecHoraire.parcours
        let semestre = this.state.ecHoraire.semestre
        let ue = this.state.ecHoraire.ue
        let ec = this.state.ecHoraire.ec
        let typeActivite = this.state.ecHoraire.typeActivite
        let enseignant = this.state.ecHoraire.enseignant
        let nbHeure = {nbHeure : parseInt(this.state.ecHoraire.nbHeure)}
        let idChoix = parcours.idParcours+""+semestre.idSemestre+""+ue.idUniteEnseignement+""+ec.idElementConstitutif+""+typeActivite.idTypeActivite+""+enseignant.idEnseignant

        //Controle de l'unicité par id ec, id annee academique, id type activite et id departement filiere
        let cleUnicite = parseInt(ec.idElementConstitutif+""+annee.idAnneeAcademique+""+typeActivite.idTypeActivite+""+parcours.idDepartementFiliere)
        let listeCle = this.state.listeCleUnicite
        
        idChoix = parseInt(idChoix)
        let listeChoixEcEnseignant = this.state.listeChoixEcEnseignant

        //console.log(idChoix)
        //console.log(listeChoixEcEnseignant)
        

        let isDoublon = false

        if(listeCle.length>0){
            if(listeCle.indexOf(cleUnicite)!== -1) {
                isDoublon = true
            }
        }

        if(isDoublon){
            this.notify.echec('Affectation déjà présente dans la liste')
        }else{

                listeCle.push(parseInt(cleUnicite))
                this.setState({
                    listeCleUnicite: listeCle
                })

                let choixEnvoie = {
                    idChoix : idChoix,
                    anneeAcademique : annee.idAnneeAcademique, 
                    idDepartementFiliere : parcours.idDepartementFiliere,
                    idElementConstitutif:ec.idElementConstitutif,
                    idTypeActivite: typeActivite.idTypeActivite, 
                    idEnseignant: enseignant.idEnseignant,
                    nbHeure: nbHeure.nbHeure,
                    idParcours : parcours.idParcours,
                    idSemestre : semestre.idSemestre,
                    libelleParcours : parcours.libelleParcours,
                    libelleSemestre : semestre.libelleSemestre
        
                 }
                 let choix = {
                    idChoix : idChoix,
                    anneeAcademique : annee.idAnneeAcademique, idDepartementFiliere : parcours.idDepartementFiliere,
                    idParcours : parcours.idParcours, libelleParcours : parcours.libelleParcours,
                    idSemestre : semestre.idSemestre, libelleSemestre : semestre.libelleSemestre,
                    idUniteEnseignement : ue.idUniteEnseignement, libelleUniteEnseignement : ue.libelleUniteEnseignement,
                    idElementConstitutif:ec.idElementConstitutif, libelleElementConstitutif:ec.libelleElementConstitutif,
                    idTypeActivite: typeActivite.idTypeActivite, libelleTypeActivite: typeActivite.libelleTypeActivite,
                    idEnseignant: enseignant.idEnseignant, nomPrenom : enseignant.nomPrenom, nomEnseignant: enseignant.nomEnseignant,prenomEnseignant : enseignant.prenomEnseignant,
                    nbHeure: nbHeure.nbHeure
                 }

                 //console.log(choix)
                 
                this.setState({listeChoixEcEnseignant : [...this.state.listeChoixEcEnseignant ,choix]})
                this.setState({listeChoixEcEnseignantEnvoie : [...this.state.listeChoixEcEnseignantEnvoie ,choixEnvoie]})

        } 
    }


    removeChoix = (choix) => {

        //console.log(choix)
        let cleUniqueToRemove = parseInt(choix.idElementConstitutif+""+choix.anneeAcademique+""+choix.idTypeActivite+""+choix.idDepartementFiliere) 
        
        //console.log(cleUniqueToRemove)
        
        let ListeChoix = this.state.listeChoixEcEnseignant.filter(function(item) {
                    return item.idChoix !== choix.idChoix
        });

        let newListeCleUnique = this.state.listeCleUnicite.filter(function(item) {
            //console.log(item)
            return item !== cleUniqueToRemove
        });

        //console.log(newListeCleUnique)

        this.setState({
            listeChoixEcEnseignant: ListeChoix,
            listeCleUnique: newListeCleUnique
        })

        let choixEnvoie = this.state.listeChoixEcEnseignantEnvoie.filter(function(item) {
            return item.idChoix !== choix.idChoix
        });

        this.setState({
            listeChoixEcEnseignantEnvoie: choixEnvoie,
        })
        
        
    }


    loadSemestre = (idParcours) => {
        this.semestreService.get(idParcours).then(data => {
            this.setState({listeSemestre : data})
        })
    }
    loadUE = (idParcours, idSemestre) => {
        this.parcoursUEService.get(idParcours, idSemestre).then(data => {
            this.setState({listeUE : data})
            
        })
    }
    afficheEcHoraire = (ecHoraires) => {
            this.setState({
                ecHoraires,
                afficheEcHoraireDialog: true,
                listeCleUnicite: []
            });
    }
    saveModifEcHoraire = () => {
        let state = { submitted: true };
        const ecHoraireModif = {
            idElementConstitutifHoraire : this.state.ecHoraire.idElementConstitutifHoraire,
            idDepartementFiliere : this.state.ecHoraire.idDepartementFiliere,
            nbHeure : this.state.ecHoraire.nbHeure,
            nbHeureElementConstitutif : this.state.ecHoraire.nbHeureElementConstitutif,
            anneeAcademique : this.state.ecHoraire.annee.idAnneeAcademique,
            idParcours : this.state.ecHoraire.parcours.idParcours,
            idSemestre : this.state.ecHoraire.semestre.idSemestre,
            idElementConstitutif : this.state.ecHoraire.ec.idElementConstitutif,
            idEnseignant: this.state.ecHoraire.enseignant.idEnseignant,
            idTypeActivite : this.state.ecHoraire.typeActivite.idTypeActivite

        }
        console.log(ecHoraireModif)

        if(ecHoraireModif.idElementConstitutifHoraire && ecHoraireModif.anneeAcademique && ecHoraireModif.idDepartementFiliere && ecHoraireModif.idEnseignant){
            this.ecHoraireService.update(ecHoraireModif.idElementConstitutifHoraire, ecHoraireModif).then(data => {
                console.log(data)
                this.notify.success()
                state = {
                    ...state,
                    afficheEcHoraireModifDialog: false,
                    ecHoraire: this.emptyECHoraire,
                };
                this.setState(state);
                this.setState({loading: true})
                if(this.state.curentAnnee.idAnneeAcademique && this.state.curentParcours.idParcours){

                    this.ecHoraireService.getEcHoraireDepartementParcoursAnnee(this.state.idDepartement, this.state.curentParcours.idParcours, this.state.curentAnnee.idAnneeAcademique).then(data => 
                        this.setState({
                            listeEcHoraire : data,
                            loading: false
                        })
                    ).catch(error => {
                        console.log(error)
                        this.notify.echec(error.response.data.message)
                        this.setState({
                            loading: false
                        })
                    })
    
                }else
    
                if(this.state.curentAnnee.idAnneeAcademique){
    
                    this.ecHoraireService.getEcHoraireDepartementAnnee(this.state.idDepartement, this.state.curentAnnee.idAnneeAcademique).then(data => 
                        this.setState({
                            listeEcHoraire : data,
                            loading: false
                        })
                    ).catch(error => {
                        console.log(error)
                        this.notify.echec(error.response.data.message)
                        this.setState({
                            loading: false
                        })
                    })
                }else{
    
                    this.ecHoraireService.getEcHoraireDepartementParcours(this.state.idDepartement, this.state.curentParcours.idParcours).then(data => 
                        this.setState({
                            listeEcHoraire : data,
                            loading: false
                        })
                    ).catch(error => {
                        console.log(error)
                        this.notify.echec(error.response.data.message)
                        this.setState({
                            loading: false
                        })
                    })
                }
    
    
                

            })
            .catch(error => {
                console.log(error)
                this.notify.echec();
            })
        }
        
    }
    afficheFormModifAffectation = (ecHoraire)  => {
        console.log(ecHoraire)
        
        const annee = {
            idAnneeAcademique : ecHoraire.anneeAcademique,
            libelleAnnee : ecHoraire.libelleAnneeAcademique
        }
        const parcours = {
            idParcours : ecHoraire.idParcours,
            libelleParcours : ecHoraire.libelleParcours
        }
        const semestre = {
            idSemestre : ecHoraire.idSemestre,
            libelleSemestre : ecHoraire.libelleSemestre
        }
        const ue = {
            idUniteEnseignement : ecHoraire.idUniteEnseignement,
            libelleUniteEnseignement : ecHoraire.libelleUniteEnseignement
        }
        const ec = {
            idElementConstitutif : ecHoraire.idElementConstitutif,
            codeElementConstitutif : ecHoraire.codeElementConstitutif,
            libelleElementConstitutif : ecHoraire.libelleElementConstitutif
        }
        const typeActivite = {
            idTypeActivite : ecHoraire.idTypeActivite,
            libelleTypeActivite : ecHoraire.libelleTypeActivite
        }
        const enseignant = {
            idEnseignant : ecHoraire.idEnseignant,
            nomEnseignant : ecHoraire.nomEnseignant,
            prenomEnseignat : ecHoraire.prenomEnseignant,
            nomPrenom : ecHoraire.nomEnseignant+" "+ecHoraire.prenomEnseignant
        }

        const ecHoraireEns = {
            idElementConstitutifHoraire : ecHoraire.idElementConstitutifHoraire,
            idDepartementFiliere : ecHoraire.idDepartementFiliere,
            nbHeure : ecHoraire.nbHeure,
            nbHeureElementConstitutif : ecHoraire.nbHeureElementConstitutif,
            annee : annee,
            parcours : parcours,
            semestre : semestre,
            ue : ue,
            ec : ec,
            enseignant: enseignant,
            typeActivite : typeActivite
        }
        console.log(ecHoraireEns)
        this.setState({
            ecHoraire : ecHoraireEns,
            afficheEcHoraireModifDialog: true
        });
        
    }
    hideAfficherEcHoraireDialog = () => {
        this.setState({
            afficheEcHoraireDialog: false,
            listeCleUnicite: []
        });
    }
    hideAfficherEcHoraireModifDialog = () => {
        this.setState({
            afficheEcHoraireModifDialog: false,
            //ecHoraire : null
        });
    }
    /*hideDeleteEcHoraireDialog() {
        this.setState({ 
            deleteEcHoraireDialog: false ,
            listeCleUnicite: []
        });
    }*/
    hideConfirmDialog = () => {
        this.setState({confirmDialog : false})
    }
    hideConfirmQuitterDialog = () => {
        this.setState({
            confirmDialog : false, 
            ecHoraireDialog : false, 
            listeChoixEcEnseignant: [], 
            listeChoixEcEnseignantEnvoie : [],
            listeCleUnicite: []
        })
    }

    hideSelectEnseignantDialog = () => {
        this.setState({
            viewSelectEnseignantDialog: false
        })
    }

    actionSelectEnseignantBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={() => this.selectionEnseignant(rowData)} title="Sélectionner l'enseignant"/>
            </React.Fragment>
        );
    }
    
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.afficheFormModifAffectation(rowData)} style={{marginRight: '.5em'}}/>
                <Button type="button" icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteEcHoraire(rowData)} style={{marginRight: '.5em'}}/>
            </React.Fragment>
        );
    }
    
    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const ECHoraireDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveEcHoraire} />
            </React.Fragment>
        );
        const ECHoraireModifDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideAfficherEcHoraireModifDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveModifEcHoraire} />
            </React.Fragment>
        );
        const deleteEcHoraireDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteEcHoraireDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteEcHoraire(this.state.ecHoraires.idElementConstitutifHoraire) } />
            </React.Fragment>
        );
        const afficheEcHoraireDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideAfficherEcHoraireDialog} />
            </React.Fragment>
        );
        const confirmDialogFooter = (
            <React.Fragment>
                <Button label="OUI" icon="pi pi-times" className="p-button-text" onClick={this.hideConfirmQuitterDialog} />
                <Button label="NON" icon="pi pi-check" className="p-button-text" onClick={this.hideConfirmDialog} />
            </React.Fragment>
        );


        const selectEnseignantDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectEnseignantDialog} />
            </React.Fragment>
        )

        const confirmDuplicationDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideConfirmDuplicationDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={this.reconduireAffectation}  />
            </React.Fragment>
        );



        
        //console.log(this.state.listeEcHoraire)
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {/*
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
                    */}
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        <React.Fragment>
                            <div className="p-grid p-lg-12">
                                <div className="p-col-2">
                                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} /> 
                                </div>
                                <div className="p-grid p-lg-12">
                                    {/* <div className='p-lg-3'>
                                        <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.curentAnnee} options={this.state.listAnnee} onChange={(e) => {this.onAnneeChange(e)}} required autoFocus className="p-lg-12" placeholder="Selectionner une année académique"/>
                                    </div>
                                    <div className="p-lg-3">
                                        <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.curentParcours} options={this.state.listeParcours} onChange={(e) => {this.onParcoursChange(e); }} required autoFocus className="p-lg-12" placeholder="Selectionner un parcours"/>
                                    </div>

                                    <div className="p-lg-3">
                                        <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.curentParcours} options={this.state.listeParcours} onChange={(e) => {this.onParcoursChange(e); }} required autoFocus className="p-lg-12" placeholder="Selectionner le semestre"/>
                                    </div> */}
                                    <div className=''> <br/>
                                        <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.ecHoraire.annee}  options={this.state.listAnnee} onChange={(e) => {this.onValueChange(e, 'annee')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                                        {this.state.submitted && !this.state.ecHoraire.annee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                                    </div>
                                </div>
                                <div className="p-grid p-lg-12">
                                    <div className=""><br/>
                                        <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.ecHoraire.parcours} options={this.state.listeParcours} onChange={(e) => {this.onValueChange(e, 'parcours'); }} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un parcours"/>
                                        {this.state.submitted && !this.state.ecHoraire.parcours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>
                                </div>
                                <div className="p-grid p-lg-12">
                                    <div className=""><br/>
                                        <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.ecHoraire.semestre} options={this.state.listeSemestre} onChange={(e) => {this.onValueChange(e, 'semestre')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                        {this.state.submitted && !this.state.ecHoraire.semestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>
                                </div>
                                <div className="p-grid p-lg-12">
                                    <div className="">
                                        <Button 
                                            label="Valider" 
                                            icon="pi pi-plus" 
                                            className="p-button-success p-mr-2" 
                                            onClick={this.validerChoix}
                                            disabled={!this.state.ecHoraire.annee || !this.state.ecHoraire.parcours || !this.state.ecHoraire.semestre  } 
                                        />
                                    </div>
                                </div>

                                    
                            </div>
                            {/*header*/}
                        </React.Fragment>
                        
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <hr/>
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeEcHoraire} selectionMode="single" header={`Liste des ECs affectés aux enseignants (${this.state.listeEcHoraire.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleAnneeAcademique" header="Année académique" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher année"/>
                                <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher parcours"/>
                                <Column field="libelleSemestre" header="Semestre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher semestre"/>
                                <Column field="libelleUniteEnseignement" header="UE" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher UE"/>
                                <Column field="libelleElementConstitutif" header="EC" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher EC"/>
                                <Column field="libelleTypeActivite" header="Type activité" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher type activité"/>
                                {/*<Column field="nbHeure" header="Nb. heure" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher nb. d'heure"/>*/}
                                <Column field="nomEnseignant" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher Enseignant"/>
                                <Column field="prenomEnseignant" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher Enseignant"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>

                            
                        {
                            !this.state.dataExist &&
                            <div className="p-lg-4" >
                            <Button  
                                label= {`Reconduire pour l'année ${this.state.anneePrecedente}`} 
                                icon="pi pi-copy" 
                                className="p-button-success p-mr-2" 
                                onClick={this.confirmDuplication}
                               // onClick={this.reconduireAffectation}
                                // disabled={!this.state.ecHoraire.annee || !this.state.ecHoraire.parcours || !this.state.ecHoraire.semestre  } 
                                />
                            </div>
                        }
                        
                            
                        </React.Fragment>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.confirmDuplicationDialog} style={{ width: '450px' }} header="Confirmez" modal footer={confirmDuplicationDialogFooter} onHide={this.hideConfirmDuplicationDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        { <span>Etes-vous sûr de dupliquer ces affectations ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.ecHoraireDialog} style={{ width: '850px' }} header="Informations EC Enseignant" modal className="p-fluid" footer={ECHoraireDialogFooter} onHide={this.hideDialog }>
                    <div className="p-grid p-lg-12">
                        <div className='p-lg-4'>
                            <label htmlFor="libelleAnnee">Année académique</label>
                            <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.ecHoraire.annee}  options={this.state.listAnnee} onChange={(e) => {this.onValueChange(e, 'annee')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                            {this.state.submitted && !this.state.ecHoraire.annee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                        </div>
                        <div className="p-lg-4">
                            <label htmlFor="libelleParcours">Parcours</label>
                            <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.ecHoraire.parcours} options={this.state.listeParcours} onChange={(e) => {this.onValueChange(e, 'parcours'); }} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un parcours"/>
                            {this.state.submitted && !this.state.ecHoraire.parcours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className="p-lg-4">
                            <label htmlFor="libelleSemestre">Semestre</label>
                            <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.ecHoraire.semestre} options={this.state.listeSemestre} onChange={(e) => {this.onValueChange(e, 'semestre')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                            {this.state.submitted && !this.state.ecHoraire.semestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <div className="p-grid p-lg-12" style={{marginTop: -30}}>
                        <div className="p-lg-4">
                            <label htmlFor="libelleUniteEnseignement">UE</label>
                            <Dropdown optionLabel="libelleUniteEnseignement" optionValue="idUniteEnseignement" value={this.state.ecHoraire.ue} options={this.state.listeUE} onChange={(e) => {this.onValueChange(e, 'ue')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un UE"/>
                            {this.state.submitted && !this.state.ecHoraire.ue && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className=" p-lg-4">
                            <label htmlFor="libelleEC">EC</label>
                            <Dropdown optionLabel="libelleElementConstitutif" optionValue="idElementConstitutif" value={this.state.ecHoraire.ec} options={this.state.listeEC} onChange={(e) => {this.onValueChange(e, 'ec')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un EC"/>
                            {this.state.submitted && !this.state.ecHoraire.ec && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className=" p-lg-4">
                            <label htmlFor="libelleTypeActivite">Type Activité</label>
                            <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={this.state.ecHoraire.typeActivite} options={this.state.listeTypeActivite} onChange={(e) => {this.onValueChange(e, 'typeActivite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un type d'activité"/>
                            {this.state.submitted && !this.state.ecHoraire.typeActivite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <div style={{marginTop: -20}} className="p-grid p-lg-12">
                        <div className=" p-lg-6">
                            <label htmlFor="nomPrenom">Enseignant</label>

                            <div className="p-grid p-lg-12">
                                <div className="p-field p-col-11">
                                    <Dropdown optionLabel="nomPrenom" optionValue="idEnseignant" value={this.state.ecHoraire.enseignant} options={this.state.listeEnseignant} showClear filterBy="nomPrenom" onChange={(e) => {this.onValueChange(e, 'enseignant')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un enseignant"/>
                                    {this.state.submitted && !this.state.ecHoraire.enseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                <div className="p-field p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={() => { this.setState({viewSelectEnseignantDialog: true})}} />
                                </div>
                            </div>
                            
                        </div>

                        <div className=" p-lg-4" hidden>
                            <label htmlFor="nbHeure">Nombre d'heure</label>
                            <InputText id="nbHeure" value={this.state.ecHoraire.nbHeure}  onChange={(e) => this.onValueChange(e, 'nbHeure')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}/>
                            {this.state.submitted && !this.state.ecHoraire.nbHeure && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}          
                        </div>
                        <div className="p-lg-4">
                            <div style={{marginTop: 30}}>
                                <Button onClick={this.saveListe} label="Valider" />
                            </div> 
                        </div>
                    </div>
                    <div className="p-grid p-lg-12 table-overflow">
                        
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Element Constitutif</th>
                                    <th>Type activité</th>
                                    {/*<th>Nombre d'heure</th>*/}
                                    <th>Enseignant</th>
                                    <th></th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                {this.state.listeChoixEcEnseignant ?
                                this.state.listeChoixEcEnseignant.map(choix =>
                                  <tr>
                                    <td>{choix.libelleElementConstitutif}</td>
                                    <td>{choix.libelleTypeActivite}</td>
                                    {/*<td>{choix.nbHeure}</td>*/}
                                    <td>{choix.nomPrenom}</td>
                                    <td><Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.removeChoix(choix)} /></td>
                                  </tr>
                                )
                                :
                                ""
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </Dialog>
                <Dialog visible={this.state.afficheEcHoraireDialog} style={{ width: '85%' }}  modal className="p-fluid" footer={afficheEcHoraireDialogFooter} onHide={this.hideAfficherEcHoraireDialog}>
                    {this.state.ecHoraires ?
                    <div className="p-field">
                        <h6>Liste des enseignants concernés par l' Element constitutif : {this.state.ecHoraires.libelleElementConstitutif}</h6>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Type activité</th>
                                    <th>Nombre d'heure</th>
                                    <th>Enseignant</th>
                                    <th></th>
                                </tr>

                            </thead>
                            <tbody>
                                  <tr>
                                    <td>{this.state.ecHoraires.libelleTypeActivite}</td>
                                    <td>{this.state.ecHoraires.nbHeure}</td>
                                    <td>{this.state.ecHoraires.nomEnseignant}</td>
                                    <td><Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.removeChoix(1)} /></td>
                                  </tr> 
                            </tbody>
                        </table>
                    </div>
                    :
                    ""
                    }
                </Dialog>

                <Dialog visible={this.state.afficheEcHoraireModifDialog} style={{ width: '850px' }} header="Informations EC Enseignant" modal className="p-fluid" footer={ECHoraireModifDialogFooter} onHide={this.hideAfficherEcHoraireModifDialog }>
                   
                    <div className="p-grid p-lg-12">
                        <div className='p-lg-4'>
                            <label htmlFor="libelleAnnee">Année académique</label>
                            <Dropdown optionLabel="libelleAnnee"  value={this.state.ecHoraire.annee}  options={this.state.listAnnee} onChange={(e) => {this.onValueChange(e, 'annee')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                            {this.state.submitted && !this.state.ecHoraire.annee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                        </div>
                        <div className="p-lg-4">
                            <label htmlFor="libelleParcours">Parcours</label>
                            <Dropdown optionLabel="libelleParcours" optionValue="idParcours" value={this.state.ecHoraire.parcours} options={this.state.listeParcours} onChange={(e) => {this.onValueChange(e, 'parcours'); }} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un parcours"/>
                            {this.state.submitted && !this.state.ecHoraire.parcours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className="p-lg-4">
                            <label htmlFor="libelleSemestre">Semestre</label>
                            <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre" value={this.state.ecHoraire.semestre} options={this.state.listeSemestre} onChange={(e) => {this.onValueChange(e, 'semestre')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                            {this.state.submitted && !this.state.ecHoraire.semestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <div className="p-grid p-lg-12" style={{marginTop: -30}}>
                        <div className="p-lg-4">
                            <label htmlFor="libelleUniteEnseignement">UE</label>
                            <Dropdown optionLabel="libelleUniteEnseignement" optionValue="idUniteEnseignement" value={this.state.ecHoraire.ue} options={this.state.listeUE} onChange={(e) => {this.onValueChange(e, 'ue')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un UE"/>
                            {this.state.submitted && !this.state.ecHoraire.ue && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className=" p-lg-4">
                            <label htmlFor="libelleEC">EC</label>
                            <Dropdown optionLabel="libelleElementConstitutif" optionValue="idElementConstitutif" value={this.state.ecHoraire.ec} options={this.state.listeEC} onChange={(e) => {this.onValueChange(e, 'ec')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un EC"/>
                            {this.state.submitted && !this.state.ecHoraire.ec && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        <div className=" p-lg-4">
                            <label htmlFor="libelleTypeActivite">Type Activité</label>
                            <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={this.state.ecHoraire.typeActivite} options={this.state.listeTypeActivite} onChange={(e) => {this.onValueChange(e, 'typeActivite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un type d'activité"/>
                            {this.state.submitted && !this.state.ecHoraire.typeActivite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    <div style={{marginTop: -20}} className="p-grid p-lg-12">
                        <div className=" p-lg-6">
                            <label htmlFor="nomPrenom">Enseignant</label>

                            <div className="p-grid p-lg-12">
                                <div className="p-field p-col-11">
                                    <Dropdown optionLabel="nomPrenom" optionValue="idEnseignant" value={this.state.ecHoraire.enseignant} options={this.state.listeEnseignant} showClear filterBy="nomPrenom" onChange={(e) => {this.onValueChange(e, 'enseignant')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un enseignant"/>
                                    {this.state.submitted && !this.state.ecHoraire.enseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                </div>
                                <div className="p-field p-col-1">
                                    <Button type="button" icon="pi pi-search" className="p-button-info" onClick={() => { this.setState({viewSelectEnseignantDialog: true})}} />
                                </div>
                            </div>
                            
                        </div>

                        <div className=" p-lg-4" hidden>
                            <label htmlFor="nbHeure">Nombre d'heure</label>
                            <InputText id="nbHeure" value={this.state.ecHoraire.nbHeure}  onChange={(e) => this.onValueChange(e, 'nbHeure')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}/>
                            {this.state.submitted && !this.state.ecHoraire.nbHeure && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}          
                        </div>
                    </div>
                    
                </Dialog>

                <Dialog visible={this.state.viewSelectEnseignantDialog} style={{ width: '75%', height: 'auto' }} header="Sélection d'un enseignant" modal className="p-fluid" footer={selectEnseignantDialogFooter} onHide={this.hideSelectEnseignantDialog}>
                    <DataTable ref={(el) => this.dt = el} value={this.state.listeEnseignant} header="Liste des enseignants" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="nomPrenom" header="Enseignant"  sortable={true} filter={true} filterMatchMode="contains"  filterPlaceholder="rechercher un enseignant"/>
                        <Column header={actionHeader} body={this.actionSelectEnseignantBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                </Dialog>



                <Dialog visible={this.state.deleteEcHoraireDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteEcHoraireDialogFooter} onHide={this.hideDeleteEcHoraireDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.ecHoraires && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                <Dialog visible={this.state.confirmDialog} style={{ width: '450px' }} header="Confirmez" modal footer={confirmDialogFooter} onHide={this.hideConfirmDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        <span>Voulez - vous quitter sans enregistrer les nouvelles modifications ?</span>
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default ECHoraire
