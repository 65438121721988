import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class StructureService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/structures/CENTRALE/NIVEAU_I"
       return axios.get(url).then(res => res.data) 
   }

   get(idDepartement) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/structures?idDepartement="
    return axios.get(url+idDepartement).then(res => res.data)
   }
   

   create(newStructure) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/structures/CENTRALE/NIVEAU_I"
       return axios.post(url,newStructure)
                   .then(res => res.data);
   }
   n
   update(idStructure ,newStructure) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/structures/CENTRALE/NIVEAU_I"
       return axios.put(url+idStructure, newStructure)
                   .then(res => res.data);
   }

   delete(idStructure) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/structures/CENTRALE/NIVEAU_I"
       return axios.patch(url+idStructure)
                   .then(res => res.data)
   }


}