import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeUEService {

    constructor(){
        this.adresse = new AdresseIP()
    }

   getAll() {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-unite-enseignement"
       return axios.get(url).then(res => res.data)
   }
   
   create(newTypeUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-unite-enseignement"
       return axios.post(url,newTypeUE)
                   .then(res => res.data);
   }
   
   update(idTypeUE ,newTypeUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-unite-enseignement/"
       return axios.put(url+idTypeUE, newTypeUE)
                   .then(res => res.data);
   }
   delete(idTypeUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-unite-enseignement/"
       return axios.patch(url+idTypeUE)
                   .then(res => res.data)
   }

}