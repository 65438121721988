import React, { useState, useEffect } from "react";
import { Menubar } from "primereact/menubar";
import logoCampusFaso from "../images/campusfaso_logo_wb.png";
import { InputText } from "primereact/inputtext";
//import AppTopbar from '../AppTopbar'
import { Route } from "react-router-dom";
import TypeUniteEnseignement from "../lmd/components/parametres/TypeUniteEnseignement";
import UniteEnseignement from "../lmd/components/parametres/UniteEnseignement";
import TypeFormation from "../lmd/components/parametres/TypeFormation";
import TypeFinalite from "../lmd/components/parametres/TypeFinalite";
import Niveau from "../lmd/components/parametres/Niveau";
import Semestre from "../lmd/components/parametres/Semestre";
import AnneeSemestre from "../lmd/components/parametres/AnneeSemestre";
import Econstitutif from "../lmd/components/parametres/Econstitutif";
import TypeMembreJury from "../lmd/components/parametres/TypeMembreJury";
import Domaine from "../lmd/components/parametres/Domaine";
import Mention from "../lmd/components/parametres/Mention";
import Specialite from "../lmd/components/parametres/Specialite";
import TypeActivite from "../lmd/components/parametres/TypeActivite";
import Parcours from "../lmd/components/parametres/parcours/Parcours";
import ParcoursUE from "../lmd/components/parametres/ParcoursUE";
import ParcoursFiliere from "../lmd/components/parametres/ParcoursFiliere";
import ECHoraire from "../lmd/components/parametres/ECHoraire";
import Curricula from "../lmd/components/parametres/Curricula";
import Session from "../lmd/components/parametres/Session";
import ModeEnseignement from "../lmd/components/parametres/ModeEnseignement";
import Enseignant from "../lmd/components/parametres/Enseignant";
import Examen from "../lmd/components/Examen";
import ImportNoteEnseignantResp from "../lmd/components/ImportNoteEnseignantResp";
import GestionNoteEnseignantResp from "../lmd/components/GestionNoteEnseignantResp";
import GestionNote from "../lmd/components/GestionNote";
import ImportNote from "../lmd/components/ImportNote";
import Deliberation from "../lmd/components/Deliberation";
import Jury from "../lmd/components/Jury";
//import { AppConfig } from '../AppConfig';
import ReleveNoteEtudiant from "../lmd/components/ReleveNoteEtudiant";
import Dashboard from "../lmd/components/Dashboard";
import ImportInscription from "../lmd/components/parametres/ImportInscription";
import InscriptionAdministrative from "../lmd/components/InscriptionAdministrative";
import InscriptionPedagogique from "../lmd/components/InscriptionPedagogique";
import Soutenances from "../lmd/components/Soutenances";
import EcEtudiant from "../lmd/components/parametres/EcEtudiant";
import ProgrammationAdministrative from "../lmd/components/ProgrammationAdministrative";
import ProgrammationPedagogique from "../lmd/components/ProgrammationPedagogique";
import ParametrerFraisPaiement from '../lmd/components/ParametrerFraisPaiement';
import Connexion from "./Connexion";
import MenuHome from "../home/MenuHome";
import PvDeliberation from "../lmd/components/PvDeliberation";
import UniteEnseignementLibre from "../lmd/components/parametres/UniteEnseignementLibre";
import ListeInscription from "../lmd/components/parametres/ListeInscription";
import SessionReclammation from "../lmd/components/SessionReclammation";
import Surveillants from "../lmd/components/parametres/Surveillants";
import ListeEnseignant from "../volumeHoraire/components/ListeEnseignant";
import DashboardDepart from "../lmd/components/DashboardDepart";
import DashboardDirectry from "../lmd/components/DashboardDirectry";
import DashboardPresident from "../lmd/components/DashboardPresident";
import DashboardMinistere from "../lmd/components/DashboardMinistere";
import Signataire from "../lmd/components/parametres/Signataire";
import ImportEnseignant from "../lmd/components/parametres/ImportEnseignant";
import GestionExamen from "../lmd/components/GestionExamen";
import ValidationNotes from "../lmd/components/ValidationNotes";
import ValidationNotesEnseignantResp from "../lmd/components/ValidationNotesEnseignantResp";
import Reclamation from "../lmd/components/Reclamation";
import DashboardEnseignant from "../lmd/components/DashboardEnseignant";
import TestPaiement from "../lmd/components/parametres/TestPaiement";
import NoteEtudiant from "../lmd/components/NoteEtudiant";
import ImportInscriptionENS from "../lmd/components/parametres/ImportInscriptionENS";
import ListeInscriptionENS from "../lmd/components/parametres/ListeInscriptionENS";
import DeliberationPartielle from "../lmd/components/DeliberationPartielle";
import SessionPartielle from "../lmd/components/parametres/SessionPartielle";
import SignataireScolarite from "../lmd/components/SignataireScolarite";
import SignataireReleveNote from "../lmd/components/SignataireReleveNote";
import SignataireCarteEtudiant from "../lmd/components/SignataireCarteEtudiant";

const Lmd = (keycloak) => {
    //console.log(keycloak)
    const tailleEcran = typeof window === "object";
    const largeurEcran = tailleEcran ? window.innerWidth : undefined
    //const hauteurEcran= tailleEcran ? window.innerHeight : undefined
    let ecranF = null
    if(largeurEcran < 768) ecranF = 1   
    else  ecranF = 0;

    const tokenDecoded = localStorage.getItem("tokenDecoded");
    const token = JSON.parse(tokenDecoded);
    const structure = localStorage.getItem("structure");
    const typeStructure = localStorage.getItem("typeStructure");

    //let boolenAcceuil = false
    //let booleanService = false
    let roles = null;
    let nomStructure = "";
    if (token.realm_access.roles) roles = token.realm_access.roles;
    const struct = JSON.parse(structure);

    const [ecran, setEcran] = useState(ecranF)
    const [booleanService, setBooleanService] = useState(false);
    const [booleanAcceuil, setBooleanAcceuil] = useState(false);
    const typeStruct = JSON.parse(typeStructure);
    if (typeStruct === "departement") {
        nomStructure = struct.lbDepartementEtab;
    } else if (typeStruct === "etablissement") {
        //nomStructure = struct[0].lbDepartementEtab
    } else if (typeStruct === "iesr") {
        //nomStructure = struct[0].lbDepartementEtab
    } else if (typeStruct === "ministere") {
        nomStructure = "MESRSI";
    }
    else {
        nomStructure = "";
    }
    //console.log(depart)

    let campusPresident = 0,
        campusVpEip = 0,
        campusDaoi = 0,
        campusDdip = 0;
    let campusDirecteur = 0,
        campusDa = 0,
        campusScolarite = 0,
        campusPresidentJury = 0;
    let campusSecretaireJury = 0,
        campusMemebreJury = 0,
        campusChefDepartement = 0;
    let campusEnseignant = 0,
        campusMinistere = 0,
        campusDepartement = 0,
        campusDGESup = 0,
        campusEtudiant = 0;
    let campusDelegue = 0,
        notConnected = 0;
    let ensAdmin = 0;

    const profiles = roles.filter((role) => {
        if (role === "campus-admin") {
            campusDGESup = 1;
            localStorage.setItem("campusDGESup", JSON.stringify(campusDGESup));
        } else if (role === "campus-president") {
            campusPresident = 1;
            localStorage.setItem(
                "campusPresident",
                JSON.stringify(campusPresident)
            );
        } else if (role === "campus-vp-eip") {
            campusVpEip = 1;
            localStorage.setItem("campusVpEip", JSON.stringify(campusVpEip));
        }  else if (role === "campus-ministere") {
            campusMinistere = 1;
            localStorage.setItem("campusMinistere", JSON.stringify(campusMinistere));
        }
        else if (role === "campus-daoi") {
            campusDaoi = 1;
            localStorage.setItem("campusDaoi", JSON.stringify(campusDaoi));
        } else if (role === "campus-ddip") {
            campusDdip = 1;
            localStorage.setItem("campusDdip", JSON.stringify(campusDdip));
        } else if (role === "campus-directeur") {
            campusDirecteur = 1;
            localStorage.setItem(
                "campusDirecteur",
                JSON.stringify(campusDirecteur)
            );
        } else if (role === "campus-da") {
            campusDa = 1;
            localStorage.setItem("campusDa", JSON.stringify(campusDa));
        } else if (role === "campus-scolarite") {
            campusScolarite = 1;
            localStorage.setItem(
                "campusScolarite",
                JSON.stringify(campusScolarite)
            );
        } else if (role === "campus-president-jury") {
            campusPresidentJury = 1;
            localStorage.setItem(
                "campusPresidentJury",
                JSON.stringify(campusPresidentJury)
            );
        } else if (role === "campus-secretaire-jury") {
            campusSecretaireJury = 1;
            localStorage.setItem(
                "campusSecretaireJury",
                JSON.stringify(campusSecretaireJury)
            );
        } else if (role === "campus-membre-jury") {
            campusMemebreJury = 1;
            localStorage.setItem(
                "campusMemebreJury",
                JSON.stringify(campusMemebreJury)
            );
        } else if (role === "campus-chef-departement") {
            campusChefDepartement = 1;
            localStorage.setItem(
                "campusChefDepartement",
                JSON.stringify(campusChefDepartement)
            );
        } else if (role === "campus-admin-departement") {
            campusDepartement = 1;
            localStorage.setItem(
                "campusDepartement",
                JSON.stringify(campusDepartement)
            );
        } else if (role === "campus-admin") {
            campusDGESup = 1;
            localStorage.setItem("campusDGESup", JSON.stringify(campusDGESup));
        } else if (role === "campus-enseignant") {
            campusEnseignant = 1;
            localStorage.setItem(
                "campusEnseignant",
                JSON.stringify(campusEnseignant)
            );
        } else if (role === "campus-etudiant") {
            campusEtudiant = 1;
            localStorage.setItem(
                "campusEtudiant",
                JSON.stringify(campusEtudiant)
            );
        } else if (role === "campus-delegue") {
            campusDelegue = 1;
            localStorage.setItem(
                "campusDelegue",
                JSON.stringify(campusDelegue)
            );
        }
        else if (role === "ens-admin-departement") {
            ensAdmin = 1;
            localStorage.setItem(
                "ensAdmin",
                JSON.stringify(ensAdmin)
            );
        }
         else {
        }
        return role;
    });

    useEffect(() => {
        if (campusDepartement) {
            window.location.hash = "/lmd/department/dashboard";
        } else if (campusDirecteur || campusDa) {
            window.location.hash = "/lmd/directry/dashboard";
        } else if (campusPresident || campusVpEip) {
            window.location.hash = "/lmd/president/dashboard";
        } 
        else if (campusMinistere) {
            window.location.hash = "/lmd/ministere/dashboard";
        }
        else if (campusEnseignant) {
            window.location.hash = "/lmd/dashboard-enseignant";
        } else if (campusEtudiant) {
            window.location.hash = "lmd/dashboard-etudiant";
        } else if (campusScolarite) {
        } 
        else {
            window.location.hash = "/lmd/dashboard";
        }
    }, []);

    //const [keycloakLmd, setKeycloakLmd] = useState(keycloak);
    //console.log(verife)
    //const [profile, setProfile] = useState("aaaaa")

    //console.log(profiles)
    //setProfile(profiles[0])
    const itemsDGESup = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/dashboard";
            },
        },
        {
            label: "Données statistiques",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Inscription",
                    icon: "pi pi-fw pi-align-left",
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "Offres de formation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
        {
            label: "Paramètres",
            icon: "pi pi-fw pi-userss",
            items: [
                {
                    label: "Unité d'enseignement",
                    icon: "pi pi-fw pi-user-pluss",
                    items: [
                        {
                            label: "Type UE",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-ue";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "UE",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash = "/lmd/parametres/ue";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Import inscriptionENS",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/parametres/inscriptionENS";
                            },
                        },
                        {
                            label: "Type d'activité ",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-activite";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Parcours",
                    icon: "pi pi-fw pi-user-minuss",
                    items: [
                        {
                            label: "Domaine",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/domaine";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Mention",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/mention";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Specialité",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/specialite";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Parcours",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/parcours";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Parcours/UE",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/parcours-ue";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Curricula",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/curriculats";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    separator: true,
                },
                {
                    label: "Autres paramètres",
                    icon: "pi pi-fw pi-userss",
                    items: [
                        {
                            label: "Niveau",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash = "/lmd/parametres/niveau";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Semestre",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/semestre";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Type formation",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-formation";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Type finalité",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-finalite";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Type membre jury",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-membre-jury";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Mode enseignement",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/mode-enseignement";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Import enseignants",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/import-enseignants";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Enseignant",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/enseignant";
                            },
                        },
                    ],
                },

                {
                    label: "interface paiement",
                    icon: "pi pi-fw pi-userss",
                    command: () => {
                        window.location.hash = "/lmd/interface-parametrage-frais";
                    },
                },
            ],
        },
    ];

    const items = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
        },
        {
            label: "Gestion des examens",
            icon: "pi pi-fw pi-files",
            items: [
                {
                    label: "Examen",
                    icon: "pi pi-fw pi-pluss",
                    command: () => {
                        window.location.hash = "/lmd/examen";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Saisie de notes",
                    icon: "pi pi-fw pi-trashs",
                    command: () => {
                        window.location.hash = "/lmd/saisie-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Import de notes",
                    icon: "pi pi-fw pi-internal-link",
                    command: () => {
                        window.location.hash = "/lmd/import-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Validation de notes",
                    icon: "pi pi-check-square",
                    command: () => {
                        window.location.hash = "/lmd/validation-note";
                    },
                },
            ],
        },
        {
            label: "Gestion de la déliberation",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Création de jury",
                    icon: "pi pi-fw pi-align-left",
                    command: () => {
                        window.location.hash = "/lmd/jury";
                    },
                },
                {
                    label: "Déliberation",
                    icon: "pi pi-fw pi-align-left",
                    command: () => {
                        window.location.hash = "/lmd/deliberation";
                    },
                },
                {
                    label: "Relévé de notes",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "PV de deliberation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
        {
            label: "Paramètres",
            icon: "pi pi-fw pi-userss",
            items: [
                {
                    label: "Unité d'enseignement",
                    icon: "pi pi-fw pi-user-pluss",
                    items: [
                        {
                            label: "Type UE",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-ue";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "UE",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash = "/lmd/parametres/ue";
                            },
                        },
                        {
                            separator: true,
                        },

                        {
                            label: "Import inscriptionENS",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/parametres/inscriptionENS";
                            },
                        },
                        {
                            label: "Type d'activité ",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-activite";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Parcours",
                    icon: "pi pi-fw pi-user-minuss",
                    items: [
                        {
                            label: "Domaine",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/domaine";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Mention",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/mention";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Specialité",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/specialite";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Parcours",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/parcours";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Parcours/UE",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/parcours-ue";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Parcours/département",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/parcours-departement";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Enseignants/EC",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/enseignant-ec";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Curricula",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/curriculats";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Examen",
                    icon: "pi pi-fw pi-userss",
                    items: [
                        {
                            label: "Session",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/session";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Type membre jury",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-membre-jury";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Autres paramètres",
                    icon: "pi pi-fw pi-userss",
                    items: [
                        {
                            label: "Niveau",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash = "/lmd/parametres/niveau";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Semestre",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/semestre";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Type formation",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-formation";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Type finalité",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/type-finalite";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Mode enseignement",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/mode-enseignement";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Enseignant",
                            icon: "pi pi-fw pi-barss",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/enseignant";
                            },
                        },
                    ],
                },
            ],
        },
    ];

    const itemsEnseignant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/dashboard-enseignant";
            },
        },
        {
            label: "Gestion des examens",
            icon: "pi pi-fw pi-files",
            items: [
                {
                    label: "Saisie de notes",
                    icon: "pi pi-fw pi-trashs",
                    command: () => {
                        window.location.hash = "/lmd/saisie-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Import de notes",
                    icon: "pi pi-fw pi-internal-link",
                    command: () => {
                        window.location.hash = "/lmd/import-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Validation de notes",
                    icon: "pi pi-check-square",
                    command: () => {
                        window.location.hash = "/lmd/validation-note";
                    },
                },
            ],
        },
    ];

    const itemsEnseignantDepartementAdmin = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/department/dashboard";
            },
        },

        {
            label: "Gestion des examens",
            icon: "pi pi-fw pi-userss",
            items: [
                {
                    label: "Département",
                    icon: "pi pi-fw pi-sitemap",
                    items: [
                        {
                            label: "Examen",
                            icon: "pi pi-fw pi-plus",
                            command: () => {
                                window.location.hash = "/lmd/examen";
                            },
                        },

                        {
                            label: "Surveillants",
                            icon: "pi pi-fw pi-user",
                            command: () => {
                                window.location.hash =
                                    "/lmd/gestion-surveillants";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Saisie de notes",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                                window.location.hash = "/lmd/saisie-note";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Import de notes",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/import-note";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Validation de notes",
                            icon: "pi pi-check-square",
                            command: () => {
                                window.location.hash = "/lmd/validation-note";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Gestion soutenance",
                            icon: "pi pi-fw pi-plus",
                            command: () => {
                                window.location.hash = "/lmd/soutenances";
                            },
                        },
                        {
                            separator: true,
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-user",
                    items: [
                        {
                            label: "Saisie de notes",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                                window.location.hash = "/lmd/saisie-note-enseignant";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Import de notes",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/import-note-enseignant";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Validation de notes",
                            icon: "pi pi-check-square",
                            command: () => {
                                window.location.hash = "/lmd/validation-note-enseignant";
                            },
                        },
                    ],
                },
            ],
        },
        {
            label: "Gestion de la déliberation",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Création de jury",
                    icon: "pi pi-fw pi-align-left",
                    command: () => {
                        window.location.hash = "/lmd/jury";
                    },
                },
                {
                    label: "Déliberation",
                    icon: "pi pi-fw pi-align-left",
                    command: () => {
                        window.location.hash = "/lmd/deliberation";
                    }
                },

                /*{
                {
                    label: "Déliberation",
                    icon: "pi pi-fw pi-align-left",
                    items: [
                        {
                            label: "Déliberation ordinaire",
                            icon: "pi pi-fw pi-align-left",
                            command: () => {
                                window.location.hash = "/lmd/deliberation";
                            }
                        },
                        {
                            label: "Déliberation partielle",
                            icon: "pi pi-fw pi-align-left",
                            command: () => {
                                window.location.hash = "/lmd/deliberation-partielle";
                            }
                        }
                    ]
                }
                
                /*{
                    label: 'Relévé de notes',
                    icon: 'pi pi-fw pi-align-right'
                },
                }*/
                {
                    label: "Relévé de notes",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash = "/lmd/releve-note-par-etudiant";
                    },
                },
                {
                    label: "PV de deliberation",
                    icon: "pi pi-fw pi-align-center",
                    command: () => {
                        window.location.hash = "/lmd/pv-deliberation";
                    },
                }/*,
                {
                    label: "Reclammations",
                    icon: "pi pi-fw pi-align-center",
                    command: () => {
                        window.location.hash = "/lmd/reclammation-session";
                    },
                },*/
            ],
        },
        {
            label: "Paramètres",
            icon: "pi pi-fw pi-userss",
            items: [
                {
                    label: "Enseignants",
                    icon: "pi pi-fw pi-user",
                    command: () => {
                        window.location.hash = "/lmd/parametres/enseignant";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Parcours",
                    icon: "pi pi-fw pi-sitemap",
                    items: [
                        {
                            label: "Parcours/dép.",
                            icon: "pi pi-fw pi-sitemap",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/parcours-departement";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "UE libre/Parcours.",
                            icon: "",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/ue-libre-parcours";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Enseignants/EC",
                            icon: "pi pi-fw pi-user",
                            command: () => {
                                window.location.hash =
                                    "/lmd/parametres/enseignant-ec";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Session",
                    icon: "pi pi-fw pi-filters",
                    command: () => {
                        window.location.hash = "/lmd/parametres/session";
                    },
                },

                /*
                {
                    label: "Session",
                    items: [
                        {
                            label: "Session ordinaire",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash = "/lmd/parametres/session";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Session partielle",
                            icon: "pi pi-fw pi-filters",
                            command: () => {
                                window.location.hash = "/lmd/parametres/session-partielle";
                            },
                        }
                    
                    ]
                },*/
                {
                    separator: true,
                },
                {
                    label: "Liste inscription",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash =
                            "/lmd/parametres/liste-inscription";
                    },
                },

                {
                    label: "Liste inscriptionENS ",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash =
                            "/lmd/parametres/liste-inscriptionENS";
                    },
                },
                {
                    label: "Import inscription",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/parametres/inscription";
                    },
                },
                {
                    separator: true,
                },

                

                {
                    label: "Import inscriptionENS",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/parametres/inscriptionENS";
                    },
                },
                {
                    label: "Choix Ec/Etudiant",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/parametres/ec-etudiant";
                    },
                },

                /*
                {
                    label: 'Test paiement',
                    icon: 'pi pi-fw pi-file',
                    command:()=>{window.location.hash = "/lmd/parametres/test-paiement?idTocken=2&value=14"}
                } 
                */
            ],
        },
    ];

    const itemsEtudiant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/dashboard";
            },
        },
        {
            label: "Gestion des inscriptions",
            icon: "pi pi-fw pi-files",
            items: [
                {
                    label: "Inscription administrative",
                    icon: "pi pi-fw pi-pluss",
                    command: () => {
                        window.location.hash =
                            "/lmd/inscription-administrative";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Inscription pédagogique",
                    icon: "pi pi-fw pi-pencils",
                    command: () => {
                        window.location.hash = "/lmd/inscription-pedagogique";
                    },
                },
            ],
        },
        {
            label: "Note",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/note";
            },
        },
        /*{
            label: "Réclamation",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/reclamation";
            },
        },*/
        /*{
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/emploi-temps";
            },
        },*/
        /*{
            label: "Parcours",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/parcours-etudiant";
            },
        },*/
    ];

    const itemsDelegue = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/dashboard";
            },
        },
        {
            label: "Gestion des inscriptions",
            icon: "pi pi-fw pi-files",
            items: [
                {
                    label: "Inscription administrative",
                    icon: "pi pi-fw pi-pluss",
                    command: () => {
                        window.location.hash =
                            "/lmd/inscription-administrative";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Inscription pédagogique",
                    icon: "pi pi-fw pi-pencils",
                    command: () => {
                        window.location.hash = "/lmd/inscription-pedagogique";
                    },
                },
            ],
        },
        {
            label: "Note",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/note";
            },
        },
        /*{
            label: "Réclamation",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/reclamation";
            },
        },
        /*{
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/emploi-temps";
            },
        },
        {
            label: "Parcours",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/parcours-etudiant";
            },
        },*/
    ];

    const itemsScolarite = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/dashboard";
            },
        },
        {
            label: "Gestion des évaluations",
            icon: "pi pi-fw pi-files",
            items: [
                {
                    label: "Gestion examens",
                    icon: "pi pi-fw pi-plus",
                    command: () => {
                        window.location.hash = "/lmd/gestion-examen";
                    },
                },

                {
                    label: "Gestion surveillants",
                    icon: "pi pi-fw pi-user",
                    command: () => {
                        window.location.hash = "/lmd/gestion-surveillants";
                    },
                },
            ],
        },
        {
            label: "Gestion des inscriptions",
            icon: "pi pi-fw pi-files",
            items: [

                {
                    label: "Programmation session administrative",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash =
                            "/lmd/prog-inscription-administrative";
                    },
                },
                {
                    label: "Programmation session pédagogique",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/prog-inscription-pedagogique";
                    },
                },

                {
                    separator: true,
                },
                {
                    label: "Liste inscription",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash =
                            "/lmd/parametres/liste-inscription";
                    },
                },
                {
                    label: "Import inscription",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/parametres/inscription";
                    },
                },
                {
                    label: "Liste inscriptionENS ",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash =
                            "/lmd/parametres/liste-inscriptionENS";
                    },
                },
                {
                    label: "Import inscriptionENS",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/parametres/inscriptionENS";
                    },
                },
                {
                    separator: true,
                },
               
                {
                    label: "Choix Ec/Etudiant",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/parametres/ec-etudiant";
                    },
                },
                
            ],
        },
        {
            label: "Rélevé de notes",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/releve-note-par-etudiant";
            },
        },
        {
            label: "Réclamation",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/reclamation";
            },
        },
    ];
    const itemsMinistere = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/ministere/dashboard";
            },
        },
        {
            label: "Gestion des déliberation",
            icon: "pi pi-fw pi-pencils",
            items: [
                /*{
                    label: "Examen",
                    icon: "pi pi-fw pi-align-left",
                    command: () => {
                        window.location.hash = "/lmd/examen";
                    },
                },*/
                {
                    label: "Note",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash = "/lmd/import-note";
                    },
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-center",
                    command: () => {
                        window.location.hash = "/lmd/deliberation";
                    },
                },
            ],
        },
        {
            label: "Saisie notes",
            icon: "",
            items: [
                {
                    label: "Saisie de notes",
                    icon: "pi pi-fw pi-pencil",
                    command: () => {
                        window.location.hash = "/lmd/saisie-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Import de notes",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/import-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Validation de notes",
                    icon: "pi pi-check-square",
                    command: () => {
                        window.location.hash = "/lmd/validation-note";
                    },
                },
            ],
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
        {
            label: "Données statistiques",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Inscription",
                    icon: "pi pi-fw pi-align-left",
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "Offres de formation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
    ];

    const itemsVpEipEnseignant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/president/dashboard";
            },
        },
        {
            label: "Données statistiques",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Inscription",
                    icon: "pi pi-fw pi-align-left",
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "Offres de formation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
        {
            label: "Saisie notes",
            icon: "",
            items: [
                {
                    label: "Saisie de notes",
                    icon: "pi pi-fw pi-pencil",
                    command: () => {
                        window.location.hash = "/lmd/saisie-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Import de notes",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/import-note";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Validation de notes",
                    icon: "pi pi-check-square",
                    command: () => {
                        window.location.hash = "/lmd/validation-note";
                    },
                },
            ],
        },



        {
            label: "Signataires",
            icon: "",
            items: [
                {
                    label: "Signataires scolarités",
                    icon: "pi pi-fw pi-pencil",
                    command: () => {
                        window.location.hash = "/lmd/signataire-scolarite";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Signataires relevé de notes",
                    icon: "pi pi-fw pi-pencil",
                    command: () => {
                        window.location.hash = "/lmd/signataire-releve-notes";
                    },
                },
                {
                    separator: true,
                },
                {
                    label: "Signataires cartes étudiants ",
                    icon: "pi pi-fw pi-pencil",
                    command: () => {
                        window.location.hash = "/lmd/signataire-carte-etudiant";
                    },
                },
            ],
        },






        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsEnseignantDirecteurUfr = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/directry/dashboard";
            },
        },
        {
            label: "Données statistiques",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Inscription",
                    icon: "pi pi-fw pi-align-left",
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "Offres de formation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
        {
            label: "Examen/Notes",
            icon: "pi pi-fw pi-userss",
            items: [
                {
                    label: "Département",
                    icon: "pi pi-fw pi-sitemap",
                    items: [
                        {
                            label: "Notes",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/import-note";
                            },
                        },
                        {
                            label: "Gestion soutenance",
                            icon: "pi pi-fw pi-plus",
                            command: () => {
                                window.location.hash = "/lmd/soutenances";
                            },
                        },

                        {
                            separator: true,
                        },

                        {
                            label: "Surveillants",
                            icon: "pi pi-fw pi-user",
                            command: () => {
                                window.location.hash =
                                    "/lmd/gestion-surveillants";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-user",
                    items: [
                        {
                            label: "Saisie de notes",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                                window.location.hash = "/lmd/saisie-note-enseignant";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Import de notes",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/import-note-enseignant";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Validation de notes",
                            icon: "pi pi-check-square",
                            command: () => {
                                window.location.hash = "/lmd/validation-note-enseignant";
                            },
                        },
                    ],
                },
                {
                    label: "Gestion signataire",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/signataire-releves";
                    },
                },
            ],
        },
    ];
    const itemsEnseignantDAUfr = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/directry/dashboard";
            },
        },
        {
            label: "Données statistiques",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Inscription",
                    icon: "pi pi-fw pi-align-left",
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "Offres de formation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
        {
            label: "Examen/Notes",
            icon: "pi pi-fw pi-userss",
            items: [
                {
                    label: "Département",
                    icon: "pi pi-fw pi-sitemap",
                    items: [
                        {
                            label: "Notes",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/import-note";
                            },
                        },
                        {
                            label: "Gestion soutenance",
                            icon: "pi pi-fw pi-plus",
                            command: () => {
                                window.location.hash = "/lmd/soutenances";
                            },
                        },

                        {
                            separator: true,
                        },

                        {
                            label: "Surveillants",
                            icon: "pi pi-fw pi-user",
                            command: () => {
                                window.location.hash =
                                    "/lmd/gestion-surveillants";
                            },
                        },
                    ],
                },
                {
                    separator: true,
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-user",
                    items: [
                        {
                            label: "Saisie de notes",
                            icon: "pi pi-fw pi-pencil",
                            command: () => {
                                window.location.hash = "/lmd/saisie-note-enseignant";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Import de notes",
                            icon: "pi pi-fw pi-file",
                            command: () => {
                                window.location.hash = "/lmd/import-note-enseignant";
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Validation de notes",
                            icon: "pi pi-check-square",
                            command: () => {
                                window.location.hash = "/lmd/validation-note-enseignant";
                            },
                        },
                    ],
                },
            ],
        },
        {
            label: "PV/relévé de notes",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Déliberation",
                    icon: "pi pi-fw pi-align-left",
                    command: () => {
                        window.location.hash = "/lmd/deliberation";
                    },
                },
                /*{
                    label: 'Relévé de notes',
                    icon: 'pi pi-fw pi-align-right'
                },*/
                {
                    label: "Relévé de notes",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash = "/lmd/releve-note-par-etudiant";
                    },
                },
                {
                    label: "PV de deliberation",
                    icon: "pi pi-fw pi-align-center",
                    command: () => {
                        window.location.hash = "/lmd/pv-deliberation";
                    },
                },
                {
                    label: "Reclammations",
                    icon: "pi pi-fw pi-align-center",
                    command: () => {
                        window.location.hash = "/lmd/reclammation-session";
                    },
                },
                {
                    label: "Gestion signataire",
                    icon: "pi pi-fw pi-file",
                    command: () => {
                        window.location.hash = "/lmd/signataire-releves";
                    },
                },
            ],
        },
    ];

    const itemsEnseignantVPEIP = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/lmd/president/dashboard";
            },
        },
        {
            label: "Données statistiques",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Inscription",
                    icon: "pi pi-fw pi-align-left",
                },
                {
                    label: "Deliberation",
                    icon: "pi pi-fw pi-align-right",
                },
                {
                    label: "Offres de formation",
                    icon: "pi pi-fw pi-align-center",
                },
            ],
        },
    ];

    const loadMenuHome = () => {
        //console.log("rentrer");
        return (
            <div>
                <MenuHome keycloak={keycloak} />
            </div>
        );
    };

    const start = (
        <img
            alt="logo"
            src="showcase/images/logo.png"
            onError={(e) =>
                (e.target.src =
                    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            height="40"
            className="p-mr-2"
        ></img>
    );
    const end = <InputText placeholder="Search" type="text" />;

    if (booleanAcceuil) {
        //console.log(keycloak.keycloak)
        return (
            <>
                <Connexion keyLmd={1} keycloakCon={keycloak.keycloak} />
            </>
        );
    }

    if (booleanService) {
        //console.log(keycloak.keycloak.logout())
        return (
            <>
                <MenuHome clickService={true} keycloak={keycloak.keycloak} />
            </>
        );
    }

    const enseignantDepartementAdmin = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEnseignantDepartementAdmin} />
            </div>
            <div>
                {/*<Route path="/" exact component={Home} />*/}
                <Route
                    path="/lmd/department/dashboard"
                    component={DashboardDepart}
                />
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />

                <Route path="/lmd/parametres/session-partielle" component={SessionPartielle} />

                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue-libre-parcours"
                    component={UniteEnseignementLibre}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/inscription"
                    component={ImportInscription}
                />
                <Route
                    path="/lmd/parametres/liste-inscription"
                    component={ListeInscription}
                />
                <Route
                    path="/lmd/parametres/ec-etudiant"
                    component={EcEtudiant}
                />
                <Route path="/lmd/examen" component={Examen} />
                <Route
                    path="/lmd/gestion-surveillants"
                    component={Surveillants}
                />
                <Route path="/lmd/saisie-note-enseignant" component={GestionNoteEnseignantResp} />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route
                    path="/lmd/validation-note-enseignant"
                    component={ValidationNotesEnseignantResp}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route path="/lmd/import-note-enseignant" component={ImportNoteEnseignantResp} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/deliberation-partielle" component={DeliberationPartielle} />

                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/soutenances" component={Soutenances} />
                <Route
                    path="/lmd/releve-note-par-etudiant"
                    component={ReleveNoteEtudiant}
                />
                {<Route path="/lmd/dashboard" component={Dashboard} />}
                {
                    <Route
                        path="/lmd/enseignant/dashboard"
                        component={DashboardEnseignant}
                    />
                }
                <Route
                    path="/lmd/prog-inscription-administrative"
                    component={ProgrammationAdministrative}
                />
                <Route
                    path="/lmd/prog-inscription-pedagogique"
                    component={ProgrammationPedagogique}
                />
                <Route path="/lmd/pv-deliberation" component={PvDeliberation} />

                <Route 
                    path="/lmd/parametres/inscriptionENS" 
                    component={ImportInscriptionENS} 
                /> 

                <Route 
                    path="/lmd/parametres/liste-inscriptionENS" 
                    component={ListeInscriptionENS} 
                />
                {/*<Route path="/lmd/reclammation-session" component={SessionReclammation} />*/}

                {/*<Route path="/lmd/parametres/test-paiement" component={TestPaiement}/>*/}
            </div>
        </main>
    );

    const DGESup = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDGESup} />
            </div>
            <div>
                <Route path="/lmd/dashboard" component={Dashboard} />
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />
                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/import-enseignants"
                    component={ImportEnseignant}
                />
                <Route path="/lmd/examen" component={Examen} />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/interface-parametrage-frais" component={ParametrerFraisPaiement} />
            </div> 
        </main>
    );

    const enseignantDirecteurUfr = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEnseignantDirecteurUfr} />
            </div>
            <div>
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />
                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue-libre-parcours"
                    component={UniteEnseignementLibre}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/inscription"
                    component={ImportInscription}
                />
                <Route
                    path="/lmd/parametres/liste-inscription"
                    component={ListeInscription}
                />
                <Route
                    path="/lmd/parametres/ec-etudiant"
                    component={EcEtudiant}
                />
                <Route path="/lmd/examen" component={Examen} />
                <Route
                    path="/lmd/gestion-surveillants"
                    component={Surveillants}
                />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route
                    path="/lmd/validation-note-enseignant"
                    component={ValidationNotesEnseignantResp}
                />
                <Route path="/lmd/saisie-note-enseignant" component={GestionNoteEnseignantResp} />
                <Route path="/lmd/import-note-enseignant" component={ImportNoteEnseignantResp} />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/soutenances" component={Soutenances} />
                <Route
                    path="/lmd/releve-note-par-etudiant"
                    component={ReleveNoteEtudiant}
                />
                {
                    <Route
                        path="/lmd/directry/dashboardd"
                        component={DashboardDirectry}
                    />
                }
                <Route
                    path="/lmd/prog-inscription-administrative"
                    component={ProgrammationAdministrative}
                />
                <Route
                    path="/lmd/prog-inscription-pedagogique"
                    component={ProgrammationPedagogique}
                />
                <Route path="/lmd/pv-deliberation" component={PvDeliberation} />
                <Route path="/lmd/signataire-releves" component={Signataire} />
            </div>
        </main>
    );

    const enseignantDaUfr = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEnseignantDAUfr} />
            </div>
            <div>
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />
                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue-libre-parcours"
                    component={UniteEnseignementLibre}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/inscription"
                    component={ImportInscription}
                />
                <Route
                    path="/lmd/parametres/liste-inscription"
                    component={ListeInscription}
                />
                <Route
                    path="/lmd/parametres/ec-etudiant"
                    component={EcEtudiant}
                />
                <Route path="/lmd/examen" component={Examen} />
                <Route
                    path="/lmd/gestion-surveillants"
                    component={Surveillants}
                />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route
                    path="/lmd/validation-note-enseignant"
                    component={ValidationNotesEnseignantResp}
                />
                <Route path="/lmd/saisie-note-enseignant" component={GestionNoteEnseignantResp} />
                <Route path="/lmd/import-note-enseignant" component={ImportNoteEnseignantResp} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/soutenances" component={Soutenances} />
                <Route
                    path="/lmd/releve-note-par-etudiant"
                    component={ReleveNoteEtudiant}
                />
                {<Route path="/lmd/dashboard" component={Dashboard} />}
                <Route
                    path="/lmd/prog-inscription-administrative"
                    component={ProgrammationAdministrative}
                />
                <Route
                    path="/lmd/prog-inscription-pedagogique"
                    component={ProgrammationPedagogique}
                />
                <Route path="/lmd/pv-deliberation" component={PvDeliberation} />

                {
                    <Route
                        path="/lmd/directry/dashboardd"
                        component={DashboardDirectry}
                    />
                }
                <Route path="/lmd/signataire-releves" component={Signataire} />
            </div>
        </main>
    );

    const enseignantMinistere = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsMinistere} />
            </div>
            <div>
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />
                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue-libre-parcours"
                    component={UniteEnseignementLibre}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/inscription"
                    component={ImportInscription}
                />
                <Route
                    path="/lmd/parametres/liste-inscription"
                    component={ListeInscription}
                />
                <Route
                    path="/lmd/parametres/ec-etudiant"
                    component={EcEtudiant}
                />
                <Route path="/lmd/examen" component={Examen} />
                <Route
                    path="/lmd/gestion-surveillants"
                    component={Surveillants}
                />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/soutenances" component={Soutenances} />
                <Route
                    path="/lmd/releve-note-par-etudiant"
                    component={ReleveNoteEtudiant}
                />
                {<Route path="/lmd/dashboard" component={Dashboard} />}
                <Route
                    path="/lmd/prog-inscription-administrative"
                    component={ProgrammationAdministrative}
                />
                <Route
                    path="/lmd/prog-inscription-pedagogique"
                    component={ProgrammationPedagogique}
                />
                <Route path="/lmd/pv-deliberation" component={PvDeliberation} />

                {
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                }
                <Route
                    path="/lmd/president/dashboard"
                    component={DashboardPresident}
                />
                <Route
                    path="/lmd/ministere/dashboard"
                    component={DashboardMinistere}
                />
                
                 <Route path="/lmd/examen" component={Examen} />
            </div>
        </main>
    );    

    const enseignantVPEIP = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsVpEipEnseignant} />
            </div>
            <div>
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />
                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue-libre-parcours"
                    component={UniteEnseignementLibre}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/inscription"
                    component={ImportInscription}
                />
                <Route
                    path="/lmd/parametres/liste-inscription"
                    component={ListeInscription}
                />
                <Route
                    path="/lmd/parametres/ec-etudiant"
                    component={EcEtudiant}
                />
                <Route path="/lmd/examen" component={Examen} />
                <Route
                    path="/lmd/gestion-surveillants"
                    component={Surveillants}
                />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/soutenances" component={Soutenances} />
                <Route
                    path="/lmd/releve-note-par-etudiant"
                    component={ReleveNoteEtudiant}
                />
                {<Route path="/lmd/dashboard" component={Dashboard} />}
                <Route
                    path="/lmd/prog-inscription-administrative"
                    component={ProgrammationAdministrative}
                />
                <Route
                    path="/lmd/prog-inscription-pedagogique"
                    component={ProgrammationPedagogique}
                />
                <Route path="/lmd/pv-deliberation" component={PvDeliberation} />

                {
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                }
                <Route
                    path="/lmd/president/dashboard"
                    component={DashboardPresident}
                />


                <Route
                    path="/lmd/signataire-scolarite"
                    component={SignataireScolarite}
                />


                <Route
                    path="/lmd/signataire-releve-notes"
                    component={SignataireReleveNote}
                />


                <Route
                    path="/lmd/signataire-carte-etudiant"
                    component={SignataireCarteEtudiant}
                />


            </div>
        </main>
    );

    const enseignant = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEnseignant} />
            </div>
            <div>
                <Route
                    path="/lmd/dashboard-enseignant"
                    component={DashboardEnseignant}
                />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
            </div>
        </main>
    );

    const etudiant = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEtudiant} />
            </div>
            <div>
                {<Route path="/lmd/note" component={NoteEtudiant} />}
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route path="/lmd/reclamation" component={Reclamation} />
                <Route
                    path="/lmd/inscription-administrative"
                    component={InscriptionAdministrative}
                />
                <Route
                    path="/lmd/inscription-pedagogique"
                    component={InscriptionPedagogique}
                />

                {/*<Route path="/lmd/prog-inscription-administrative" component={ProgrammationAdministrative}/>
                <Route path="/lmd/prog-inscription-pedagogique" component={ProgrammationPedagogique}/>*/}
                {/*/lmd/inscription-administrative*/}
            </div>
        </main>
    );

    const delegue = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDelegue} />
            </div>
            <div>
            <Route path="/lmd/note" component={NoteEtudiant} />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route path="/lmd/reclamation" component={Reclamation} />
                <Route
                    path="/lmd/inscription-administrative"
                    component={InscriptionAdministrative}
                />
                <Route
                    path="/lmd/inscription-pedagogique"
                    component={InscriptionPedagogique}    
                />
                {/*/lmd/inscription-administrative*/}
            </div>
        </main>
    );

    const scolarite = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsScolarite} />
            </div>
            <div>
                {/*<Route path="/" exact component={Home} />*/}
                <Route
                    path="/lmd/department/dashboard"
                    component={DashboardDepart}
                />
                <Route
                    path="/lmd/parametres/type-ue"
                    component={TypeUniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue"
                    component={UniteEnseignement}
                />
                <Route
                    path="/lmd/parametres/typeFormation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/TypeFinalite"
                    component={TypeFinalite}
                />
                <Route path="/lmd/parametres/niveau" component={Niveau} />
                <Route path="/lmd/parametres/semestre" component={Semestre} />
                <Route
                    path="/lmd/parametres/annee-semestre"
                    component={AnneeSemestre}
                />
                <Route path="/lmd/parametres/ec" component={Econstitutif} />
                <Route
                    path="/lmd/parametres/type-membre-jury"
                    component={TypeMembreJury}
                />
                <Route path="/lmd/parametres/domaine" component={Domaine} />
                <Route path="/lmd/parametres/mention" component={Mention} />
                <Route
                    path="/lmd/parametres/specialite"
                    component={Specialite}
                />
                <Route
                    path="/lmd/parametres/type-activite"
                    component={TypeActivite}
                />
                <Route path="/lmd/parametres/parcours" component={Parcours} />
                <Route
                    path="/lmd/parametres/parcours-ue"
                    component={ParcoursUE}
                />
                <Route
                    path="/lmd/parametres/parcours-departement"
                    component={ParcoursFiliere}
                />
                <Route
                    path="/lmd/parametres/enseignant-ec"
                    component={ECHoraire}
                />
                <Route
                    path="/lmd/parametres/curriculats"
                    component={Curricula}
                />
                <Route path="/lmd/parametres/session" component={Session} />
                <Route
                    path="/lmd/parametres/type-formation"
                    component={TypeFormation}
                />
                <Route
                    path="/lmd/parametres/type-finalite"
                    component={TypeFinalite}
                />
                <Route
                    path="/lmd/parametres/mode-enseignement"
                    component={ModeEnseignement}
                />
                <Route
                    path="/lmd/parametres/ue-libre-parcours"
                    component={UniteEnseignementLibre}
                />
                <Route
                    path="/lmd/parametres/enseignant"
                    component={Enseignant}
                />
                <Route
                    path="/lmd/parametres/inscription"
                    component={ImportInscription}
                />
                <Route
                    path="/lmd/parametres/liste-inscription"
                    component={ListeInscription}
                />
                <Route 
                    path="/lmd/parametres/inscriptionENS" 
                    component={ImportInscriptionENS} 
                /> 

                <Route 
                    path="/lmd/parametres/liste-inscriptionENS" 
                    component={ListeInscriptionENS} 
                />
                <Route
                    path="/lmd/parametres/ec-etudiant"
                    component={EcEtudiant}
                />
                <Route path="/lmd/gestion-examen" component={GestionExamen} />
                <Route
                    path="/lmd/gestion-surveillants"
                    component={Surveillants}
                />
                <Route path="/lmd/saisie-note" component={GestionNote} />
                <Route
                    path="/lmd/validation-note"
                    component={ValidationNotes}
                />
                <Route path="/lmd/import-note" component={ImportNote} />
                <Route path="/lmd/deliberation" component={Deliberation} />
                <Route path="/lmd/jury" component={Jury} />
                <Route path="/lmd/soutenances" component={Soutenances} />
                <Route
                    path="/lmd/releve-note-par-etudiant"
                    component={ReleveNoteEtudiant}
                />
                {<Route path="/lmd/dashboard" component={Dashboard} />}
                <Route
                    path="/lmd/prog-inscription-administrative"
                    component={ProgrammationAdministrative}
                />
                <Route
                    path="/lmd/prog-inscription-pedagogique"
                    component={ProgrammationPedagogique}
                />
                <Route path="/lmd/pv-deliberation" component={PvDeliberation} />
                {<Route path="/lmd/reclamation" component={Reclamation} />}
            </div>
        </main>
    );

    return (
        <div className="html">
            <div className="body">
                {token ? (
                    <div className="grid-container">
                        <header className="header" style={{"backgroundColor" : "#c3e7d1"}}>
                            <div className="logo p-col-4">
                                <a
                                    className="navbar-brand link-menu scroll-to-target current"
                                    href="/"
                                >
                                    <img
                                         src={ecran ? "/assets/layout/images/cf_bullet.svg" : "/assets/layout/images/CampusFaso_Logo.png"}
                                        alt="CampusFaso"
                                    />
                                </a>
                            </div>
                            <div className="p-col-5 titlePi">
                                <p
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        //marginLeft: -500,
                                        fontStyle: "italic",
                                    }}
                                >
                                    Système de gestion du Licence Master Doctorat
                                </p>
                            </div>
                            {token ? (
                                <div className="header-text">
                                    <i className="ti-user"></i>
                                    Bienvenue{" "}
                                    <span style={{ color: "green" }}>
                                        {token.name}{" "}
                                    </span>
                                    {" ( " + nomStructure + " ) "}|
                                    <span style={{cursor : "pointer", marginLeft : 10}}
                                        className="btns"
                                        onClick={() => {
                                            keycloak.keycloak.logout();
                                            localStorage.clear();
                                        }}
                                    >
                                        <i className="ti-close"></i>
                                        Déconnexion
                                    </span>{" "}
                                    |
                                    <span style={{marginLeft : 10, cursor : "pointer"}}
                                        className="btns"
                                        onClick={() => {
                                            setBooleanService(true);
                                        }}
                                    >
                                        <i className="ti-homes"></i>
                                        Services
                                    </span>
                                    <span style={{marginLeft : 10, cursor : "pointer"}}
                                        className="btns"
                                        onClick={() => {
                                            setBooleanAcceuil(true);
                                        }}
                                    >
                                        <i className="ti-home"></i>
                                        Accueil
                                    </span>
                                </div>
                            ) : (
                                ""
                            )}
                        </header>
                        <aside className="sidenav">
                            <div className="logo">
                                <img src={logoCampusFaso} alt="" />
                                <div className="txt">
                                    Plateforme intégrée de <br />
                                    l’enseignement supérieur <br />
                                    et de la recherche
                                </div>
                            </div>
                        </aside>
                        {campusDGESup !== 0 && DGESup}
                        {campusEnseignant !== 0 &&
                            campusVpEip !== 0 &&
                            enseignantVPEIP}
                        {campusEnseignant !== 0 && campusMinistere!== 0 && enseignantMinistere}
                        {campusEnseignant !== 0 &&
                            campusDirecteur !== 0 &&
                            enseignantDirecteurUfr}
                        {campusEnseignant !== 0 &&
                            campusDa !== 0 &&
                            enseignantDaUfr}
                        {campusEnseignant !== 0 &&
                            campusDepartement !== 0 &&
                            enseignantDepartementAdmin}

                        {campusEnseignant !== 0 &&
                            campusVpEip === 0 &&
                            campusDirecteur === 0 &&
                            campusDa === 0 &&
                            campusDepartement === 0 &&
                            campusMinistere === 0 &&
                            enseignant}
                        {campusScolarite !== 0 && scolarite}
                        {(campusEtudiant !== 0 && campusDelegue === 0 ) && etudiant}
                        {(campusDelegue !== 0 && campusEtudiant !== 0)  && delegue}
                        {notConnected && ""}

                        <div className="footer">
                            <ul className="liens">
                                <li>
                                    <a href="">CGU</a>
                                </li>
                                <li>
                                    <a href="">Politique de confidentialité</a>
                                </li>
                                <li>
                                    <a href="">FAQ</a>
                                </li>
                            </ul>
                            <p>
                                &copy; 2021 &middot; MESRSI &middot;Tous droits
                                réservés
                            </p>
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            <Connexion
                                keyLmd={1}
                                keycloakCon={keycloak.keycloak}
                            />
                        }
                    </>
                )}
            </div>
        </div>
    );
};

export default Lmd;
