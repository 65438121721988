import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class CycleService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/cycles"
       return axios.get(url).then(res => res.data) 
   }
   
   create(newCycle) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/cycles"
       return axios.post(url,newCycle)
                   .then(res => res.data);
   }
   update(idCycle ,newCycle) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/cycles/"
       return axios.put(url+idCycle, newCycle)
                   .then(res => res.data);
   }
   delete(idCycle) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/cycles/"
       return axios.patch(url+idCycle)
                   .then(res => res.data)
   }

}