import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';

import { ParametreService } from '../../service/ParametreService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

const Parametre = () => {

    let emptyParametre = {
        idParametre : null,
        cle : "",
        valeur : "",
        declinable : false,
        observation : ""
    };

    const [parametres, setParametres] = useState(null)
    const [parametre, setParametre] = useState(emptyParametre)
    const [parametreDialog, setParametreDialog] = useState(false)
    const [deleteParametreDialog, setDeleteParametreDialog] = useState(false)
    const [selectedParametres, setSelectedParametres] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const parametreService = new ParametreService();
    const notify = new Notify();
    const spinner = new Spinner();


useEffect(() => {
    setLoading(true)
    parametreService.getAll().then(data => {
        setParametres(data)
        setLoading(false);
        //notify.success()
    })
    .catch(error => {
        console.log(error);
        setLoading(false);
        notify.echec(error.response.data.message);
    })
    
}, []);

const openNew = () => {
    setParametre(emptyParametre);
    setSubmitted(false);
    setParametreDialog(true);
}

const hideDialog = () => {
    setSubmitted(false);
    setParametreDialog(false);
}

const hideDeleteParametreDialog = () => {
    setDeleteParametreDialog(false);
}

const saveParametre = () => {
    setSubmitted(true);

    if (parametre.cle.trim() && parametre.valeur.trim() ) {
        let _parametre = {...parametre};
        if (parametre.idParametre) {
            parametreService.update(parametre.idParametre, _parametre).then(data => {
                console.log(data)
                parametreService.getAll().then(data => setParametres(data));
                notify.success();
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
        }
        else {
            parametreService.create(_parametre).then(data => {
                console.log(data)
                parametreService.getAll().then(data => setParametres(data));
                notify.success();
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
            })
            
        }

        setParametreDialog(false);
        setParametre(emptyParametre);
    }
}
const editParametre = (parametre) => {
    setParametre({...parametre});
    setParametreDialog(true);
}

const confirmDeleteParametre = (parametre) => {
    setParametre(parametre);
    setDeleteParametreDialog(true);
}

const deleteParametre = () => {
    parametreService.delete(parametre.idParametre).then(data => {
        setParametre(emptyParametre);
        console.log(data)
        parametreService.getAll().then(data => setParametres(data))
        notify.success()
    })
    .catch(error => {
        console.log(error);
        notify.echec(error.response.data.message);
    })
    setDeleteParametreDialog(false);
    
}

const onChampsChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _parametre = {...parametre};
    _parametre[`${name}`] = val;

    setParametre(_parametre);
}

const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editParametre(rowData)} />
            <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteParametre(rowData)} style={{marginLeft : 5}} />
        </React.Fragment>
    );
}

const actionDeclinable = (rowData) => {
    return (
        <React.Fragment>
            <InputSwitch checked={rowData.declinable} />
        </React.Fragment>
    );
}

const header = (
    <div className="table-header p-col-12">
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche globale..." />
            <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
        </span>
    </div>
);
const parametreDialogFooter = (
    <React.Fragment>
        <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveParametre} />
    </React.Fragment>
);
const deleteParametreDialogFooter = (
    <React.Fragment>
        <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteParametreDialog} />
        <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteParametre} />
    </React.Fragment>
);
const actionHeader = <Button type="button" icon="pi pi-cog"/>;

return (
    <div className="">
        <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className="card">
            {header}
            {loading ?
            <div className="p-grid">
                <div className="p-col-6" ></div>
                <div className="p-col-6" >
                    {spinner.loadingSpinner("spin", "black")}
                </div>
            </div>
             :
            <DataTable ref={dt} value={parametres} selection={selectedParametres} onSelectionChange={(e) => setSelectedParametres(e.value)}
                dataKey="id" paginator rows={10} 
                globalFilter={globalFilter}
                header="Liste des paramètres">

                <Column field="cle" header="Clé" filterMatchMode="contains" sortable={true} filter={true}></Column>
                <Column field="valeur" header="Propriété" filterMatchMode="contains" sortable={true} filter={true}></Column>
                <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
            </DataTable>
            }
        </div>
        <Dialog visible={parametreDialog} style={{ width: '450px' }} header="Informations paramètre" modal className="p-fluid" footer={parametreDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="cle">Clé</label>
                    <InputText id="cle" value={parametre.cle} onChange={(e) => onChampsChange(e, 'cle')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametre.cle })} />
                    {submitted && !parametre.cle && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="valeur">Propriété</label>
                    <InputText id="valeur" value={parametre.valeur} onChange={(e) => onChampsChange(e, 'valeur')} required autoFocus className={classNames({ 'p-invalid': submitted && !parametre.valeur })} />
                    {submitted && !parametre.valeur && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            
            <div className="p-field">
                <label htmlFor="observation">Observations</label>
                <InputTextarea id="observation" value={parametre.observation} onChange={(e) => onChampsChange(e, 'observation')} required rows={3} cols={20} />
            </div>
        </Dialog>

        <Dialog visible={deleteParametreDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteParametreDialogFooter} onHide={hideDeleteParametreDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                {parametre && <span>Etes vous sûr de supprimer cet élément ? <b>{parametre.valeur}</b>?</span>}
            </div>
        </Dialog>

    </div>
)
}

export default Parametre

