import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { DomaineService } from '../../service/DomaineService';

export class Domaine extends Component {

    //Model de l'objet domaine
    

    constructor(props){
        super(props);

        let emptyDomaine = {
            idDomaine: null,
            libelleDomaine: ''
        }

        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelDomaine = new DomaineService();
        this.state = {
            curentDomaine: emptyDomaine,
            domaineList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateDomaine: false,
            allowSave: false,
            viewDeleteDomaineDialog: false,
            deletionId: null
        }
        
    }
    
    componentDidMount(){
        //Temoin de chargement
        this.setState({loading : true})

        this.ModelDomaine.getAll().then(data => {
            this.setState({
                domaineList: data
            })
            this.setState({loading : false})
            //console.log(this.state.domaineList);
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        console.log(this.props)
    }
    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                viewCreateDomaine: true
            }
        )
    }

    //Gestion de l'edition du domaine
    editDomaine = (event) => {
 
        //On positionne le domaine que nous voulons editer dans le state afin de l'ouvrir directement dans le formulaire
        let curentDomaine = {
            idDomaine: event.rowData.idDomaine,
            libelleDomaine: event.rowData.libelleDomaine
        }
        this.setState({
            curentDomaine: curentDomaine,
            viewCreateDomaine: true,
            allowSave: (curentDomaine.libelleDomaine.trim()).length>=5
        })

    }

    //Gestion de l'edition du domaine
    onLibelleDomaineChange = (event, fieldName) => {

        let newDomaine = this.state.curentDomaine;
        newDomaine.libelleDomaine  = event.target.value;

        this.setState({
            curentDomaine: newDomaine
        })

        
        this.setState({
            allowSave: (this.state.curentDomaine.libelleDomaine.trim()).length>=5
        })
    }

    //Gestion de l'enregistrement du domaine
    saveDomaine = () => {

        //On prepare l'objet à l'enregistrement
        let newDomaine = {
            idDomaine: parseInt(this.state.curentDomaine.idDomaine),
            libelleDomaine: this.state.curentDomaine.libelleDomaine
        };

        if( this.state.curentDomaine.idDomaine ){
            
            //Mise à jour de domaine   update
            //Ajout de domaine
            console.log('Edition de domaine')
            this.ModelDomaine.update(newDomaine.idDomaine, newDomaine).then(data => {
                this.notify.success()
                console.log(data)
                this.setState({redirect : true})
                this.setState({loading : true})
                    this.ModelDomaine.getAll().then(data => {
                        this.setState({
                            domaineList: data,
                            loading : false
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        this.notify.echec(error.response.data.message)
                        this.setState({loading : false})
                    })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
            }
            else{

            console.log('Ajout de domaine')
            this.ModelDomaine.create(newDomaine).then(data => {
                this.notify.success()
                console.log(data)
                this.setState({redirect : true})
                this.ModelDomaine.getAll().then(data => {
                    this.setState({
                        domaineList: data,
                        loading : false
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                })
            })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error);
            })

        }

        let emptyDomaine = {
            idDomaine: null,
            libelleDomaine: ''
        }

        this.setState({
            curentDomaine: emptyDomaine,
            viewCreateDomaine: false
            
        })
    }


    hideDialog = () => {
        //Si on decide de fermer le formulaire, on doit s'assurer le reinitialiser le domaine courant
        let emptyDomaine = {
            idDomaine: null,
            libelleDomaine: ''
        }

        this.setState({
            curentDomaine: emptyDomaine,
            viewCreateDomaine: false
        })
    }

    //Ouverture de la fenêtre de suppression domaine
    confirmDeleteDomaine = (event) => {
        this.setState({
            viewDeleteDomaineDialog: true,
            deletionId: event.rowData.idDomaine
        })
    }



    hideDeleteDomaineDialog = () => {
        this.setState(
            {
                viewDeleteDomaineDialog: false,
                deletionId: null
            }
        )
    }

    deleteDomaine = (domaineId) => {
        this.ModelDomaine.delete(parseInt(domaineId)).then(data => {
            this.notify.success()

            this.setState({
                viewDeleteDomaineDialog: false,
                deletionId: null,
                redirect : true
            })
            this.ModelDomaine.getAll().then(data => {
                this.setState({
                    domaineList: data,
                    loading : false
                })
            })
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
                this.setState({loading : false})
            })
        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error);

            this.setState({
                viewDeleteDomaineDialog: false,
                deletionId: null,
            })
        });
    }


    

    



    actionBodyTemplate = (event, rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editDomaine(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDomaine(rowData)} />
            </React.Fragment>
        );
    }

    
    render() {

        if(this.state.redirect) {
            //window.location.reload();
        }
    
        //Pied de page 
        const allow = this.state.allowSave? (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveDomaine} />) : (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled/>)
        const DomaineDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allow}
            </React.Fragment>
        );

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );


        const deleteDomaineDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDomaineDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteDomaine(this.state.deletionId) } />
            </React.Fragment>
        );

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                                <div className="p-col-6" ></div>
                                <div className="p-col-6" >
                                    {this.spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.domaineList} selectionMode="single" header={`Liste des domaines RESAO (${this.state.domaineList.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                {/*<Column field="idDomaine" header="Id Domaine"   sortable={true} filter={true} filterPlaceholder="rechercher identifiant"/>*/}
                                <Column field="libelleDomaine" header="Libellé domaine"   sortable={true} filter={true} filterPlaceholder="rechercher domaine"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>


                    <Dialog visible={this.state.viewCreateDomaine} style={{ width: '450px' }} header="Informations du domaine" modal className="p-fluid" footer={DomaineDialogFooter} onHide={this.hideDialog}>
                        <div className="p-field">
                            <label htmlFor="libelleDomaine">Domaine *</label>
                            <InputText id="libelleDomaine" value={this.state.curentDomaine.libelleDomaine}  onChange={(e) => this.onLibelleDomaineChange(e, 'libelleDomaine')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                            {this.state.submitted && !this.state.semestre.libelleSemestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={this.state.viewDeleteDomaineDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDomaineDialogFooter} onHide={this.hideDeleteDomaineDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                        </div>
                    </Dialog>
                    
                    
                </div> 
    
        )
}
}

export default Domaine
