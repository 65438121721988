import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './index.css';


import { DataTable } from 'primereact/datatable';
import React,{useState, useRef}  from 'react';
import { Column } from 'primereact/column';
import {Button} from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {ProgressBar} from 'primereact/progressbar'
import { Controller,useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Notify } from '../../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner';
import DisciplineService from '../../service/DisciplineService';

export default function Discipline(){


    // const resolver = useValidationResolver(validationSchema);
    const spinner = new Spinner();
    const notify = new Notify();
    const disciplineService = new DisciplineService();
    const interval = useRef(null);
    // Default value bloc

    const defaultValues = {
        libelleDiscipline: '',
        idDiscipline:''
    };
    const dialogOpenHandler = {
        add:false,
        modify:false,
        delete:false,
        
    }


    // variables declaration Bloc

    const [listOfDiscipline,setListOfDiscipline] = useState([]);
    const [disciplineDelete,setDisciplineDelete]=useState(defaultValues);
    const [saveList,setSaveList] = useState([]);
    const [cancelAndReset,setCancelAndReset]=useState(false);
    const [loading,setLoading] = useState(false);
    const [dialog,setDialog]=useState(dialogOpenHandler);
    const { control, formState: { errors}, handleSubmit, reset,setValue,getValues } = useForm({defaultValues});
    const [spin,setSpin] = useState(false); // declaration de la variable d'etat qui permet de charger les boutons
    const [globalFilter, setGlobalFilter] = useState(null);
    const [httpErrors, setHttpErrors] = useState(false);
    const [progressValue, setProgressValue] = useState(100);

    // variables declaration EndBloc

    // functions handler Bloc
    const handleDisciplineSubmit = (data) => {
        setSaveList([...saveList,data]);
        reset(); //reinitialisation des champs du formulaire d'envoie

    };  

    const handleModification = (dataToModify)=>{
        setValue("libelleDiscipline",dataToModify.libelleDiscipline);
        setValue("idDiscipline",dataToModify.idDiscipline);
        setDialog({modify:true});
    }

    // add and modify user information management
    const handleSave=(dataForm=null)=>{
        setSpin(true);
        if(dialog.add && saveList.length!==0){
            
            disciplineService.create(saveList)
            .then(()=>{
                notify.success(); // notification de success du traitement
                setLoading(true);
                disciplineService.getAll().then(response=>setListOfDiscipline(response))
                setDialog(false);
                reset(); //reinitialisation des champs du formulaire d'envoie

            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec du traitement
            })
            .finally(()=>{ setSpin(false); setLoading(false);});
        }else if(dialog.modify){
            disciplineService.update(dataForm)
            .then(()=>{
                setLoading(true);
                disciplineService.getAll().then((data)=>{setListOfDiscipline(data)});
                notify.success(); // notification de success du traitement
                setDialog(false);
                reset(); //reinitialisation des champs du formulaire d'envoie
            })
            .catch((error)=>{notify.echec(error.response.data.message)})
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })
        }
        
    }

    
    const removeItem =(key)=>{

        saveList.splice(key,1);
        setSaveList([...saveList]);
        reset(defaultValues);
    }


    // delete user information management
    const handleDisciplineDeletion = (dataToDelete=null)=>{
        
        if(cancelAndReset){
            setSpin(true);
            
            setTimeout(()=>{
                setSaveList([]);
                setSpin(false);
                setCancelAndReset(false);
                setDialog(false);
            },2000);
           
        }
        else if(dialog.delete){
           setSpin(true);
            disciplineService.delete(disciplineDelete.idDiscipline)
            .then((response)=>{
                setDialog({delete:false});
                setLoading(true);
                disciplineService.getAll().then(response=>{setListOfDiscipline(response)});
                notify.success(); // notification de success du traitement
            })
            .catch((errors)=>{
                
                let errors_str = String(errors);
                setDialog({delete:false});
                let http_errors = +errors_str.match(/\d+/)[0];
                if(http_errors === 403){
                    setHttpErrors(true);
                }else{
                    notify.echec(errors.response.data.message);
                }
            })
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            });
        }else{
            setDisciplineDelete(dataToDelete)
            setDialog({delete:true})
        }
    }
    


    // hide and lifecycle functions management
    const onHide = ()=>{
        if(cancelAndReset && dialog.add){
            setCancelAndReset(false);
        }else if(dialog.add || dialog.delete || dialog.modify){
            setDialog(dialogOpenHandler);
            reset(); //reinitialisation des champs du formulaire d'envoie
        }
        setSaveList([]);
    }

    useEffect(() => {
        setLoading(true);
        disciplineService.getAll()
        .then(response=>{setListOfDiscipline(response)})
        .catch((errors)=>{console.log(errors);notify.echec(errors.response.data.message)})
        .finally(()=>{setLoading(false);});
    }, []);


    // JSX function management
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button type="button" icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em', color:"white"}} onClick={()=>handleModification(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={()=>handleDisciplineDeletion(rowData)} />
                </React.Fragment>
            );
        }

        
        const saveDisciplineFooter=(
            <React.Fragment>
                 <div className="p-grid p-justify-end" style={{width:"100%"}}>
                    <Button type="submit" className="p-button-success" label="Enregistrer" disabled={dialog.add && (spin||saveList.length===0)} icon={spin?"pi pi-spin pi-spinner":"pi pi-save" } style={{marginRight:"15px"}} onClick={handleSave} />
                    <Button type="button" label="Quitter" className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
                </div>
            </React.Fragment>
        )
        const deleteDisciplineFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={onHide} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleDisciplineDeletion} />
            </React.Fragment>
            
        )
        const cancelDisciplineFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleDisciplineDeletion} />
            </React.Fragment>
            
        )
        const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setDialog({...dialog,add:true})} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );

    // function handler EndBloc

    return (
        <div>
           
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">

            <div style={{width:"100%",textAlign:"center"}}>{header}</div>
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                <DataTable 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    rows={5}
                    selectionMode="single" filterMatchMode="contains"
                    paginator={true} speconsive={true}
                    value={listOfDiscipline}
                    globalFilter={globalFilter}
                    header="Liste des disciplines enseignant"
                >
                    <Column field="libelleDiscipline" header="Libelle" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{width:"120px"}} />
                        
                </DataTable>
                
                }
                
            </div>

            {/* Add discipline Bloc */}
                <Dialog className="card" visible={dialog.add} style={{width:"60vw"}} footer={saveDisciplineFooter} onHide={onHide}>

                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center">
                                <h5 className="text-header">Ajouter un discipline</h5>
                                <form onSubmit={handleSubmit(handleDisciplineSubmit)} className="p-fluid">
                                    <div style={{height:"100px"}}>
                                        <div className="p-field" >
                                            <label htmlFor="libelleDiscipline" >Libelle {getFormErrorMessage('libelleDiscipline')}</label>
                                            <Controller name="libelleDiscipline" control={control} rules={{ required: 'champ obligatoire.'}} render={({ field, fieldState }) => (
                                                <InputText type="text" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                        </div>
                                    </div>
                                   
                                    <div style={{width:"100px"}}>
                                        <Button type="submit" className="p-button-info" label="ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                                    </div>
                                    
                                </form>
                            </div>
                        </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <h5 className="text-header">Nouvellement ajouté </h5>

                            <div className=" p-fieldset">
                                <table className="table" style={{minHeight:"300px"}}>
                                    <thead>
                                        <tr className="p-grid">
                                            <th className="p-col-8">Libelle</th>
                                            <th className="p-col-4" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            saveList.map((spec,index) =>
                                                <tr className="p-grid" key={index+1}>
                                                    <td className="p-col-8">{spec.libelleDiscipline}</td>
                                                    <td><Button icon="pi pi-trash" className=" p-button-danger p-col-4" onClick={() => {removeItem(index)}} 
                                                    /></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>      
                                </table>
                            </div>
                        </div>
                    </div>
                </Dialog>
            {/* Add discipline EndBloc */}


            {/* Modify dicipline Bloc */}
            <Dialog className="card" visible={dialog.modify} style={{width:"25vw"}} onHide={onHide}>

                <div className="p-d-flex p-jc-center">
                    <h5 className="text-header">Modification</h5>
                    <form onSubmit={handleSubmit(handleSave)} className="p-fluid">
                        <div className="p-field">
                            <div>
                                <label htmlFor="libelleDiscipline">Libelle Discipline</label>
                                <Controller 
                                    control={control}
                                    defaultValue={getValues("libelleDiscipline")}
                                    name="libelleDiscipline"
                                    render = {({field})=>(
                                        <InputText id={field.name} type="text" name={field.name} value={field.libelleDiscipline} {...field}/>
                                    )}
                                
                                />
                            </div>
                        </div>
                        
                        <div className='mt-4' style={{display:"flex"}}>
                            <Button type="submit" className="p-button-success" label="Enregistrer"icon={spin?"pi pi-spin pi-spinner":"pi pi-save" } style={{marginRight:"15px"}} />
                            <Button type="button" label="Quitter" className="p-button-danger" icon="pi pi-times" onClick={onHide}/>
                        </div>
                    </form>
                </div>

            </Dialog>

            {/* Modify Statut EndBloc */}


            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDisciplineFooter} onHide={onHide}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>

            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelDisciplineFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>
            <Dialog visible={httpErrors} header="Confirmez" modal onHide={()=>setHttpErrors(false)}>
                <div className="httperrors confirmation-content">
                    <p>
                        <span>Vous n'êtes pas autorisé à supprimer <cite><strong>{disciplineDelete.libelleDiscipline}</strong></cite></span><br />
                        <span>Veuillez-contacter votre administrateur système.</span>
                    </p>
                </div>
            </Dialog>
        </div>
    )
}
