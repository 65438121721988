import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../../components/Spinner";
import { Notify } from "../../../components/Notify";
import { CohorteServices } from "../../../volumeHoraire/service/CohorteServices";
import { ParcoursFiliereService } from "../../service/ParcoursFiliereService";
import { AnneeService } from "../../service/AnneeService";
import { SemestreService } from "../../service/SemestreService";
import { NiveauService } from "../../service/NiveauService";
import { InscriptionService } from "../../service/InscriptionService";
import { Checkbox } from "primereact/checkbox";

export class EcEtudiant extends Component {
    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.cohorteServices = new CohorteServices();
        this.parcoursDepartementService = new ParcoursFiliereService();
        this.anneeService = new AnneeService();
        this.semestreService = new SemestreService();
        this.niveauService = new NiveauService();
        this.inscriptionService = new InscriptionService();

        const campusScolarite = localStorage.getItem("campusScolarite");
        const campusDepartement = localStorage.getItem("campusDepartement");
        this.isScolarite = parseInt(JSON.parse(campusScolarite));
        this.isDepartement = parseInt(JSON.parse(campusDepartement));

        this.state = {
            departement: {},
            etudiant: {},
            parcours: {},
            annee: {},
            niveau: {},
            semestre: {},
            listeEC: [],
            listeECOptionnel: [],
            listeUEOptionnel: [],
            listeDepartemnt: [],
            listeParcours: [],
            listeAnnee: [],
            listeNiveau: [],
            listeSemestre: [],
            listeEtudiant: [],
            selectionChoixEc: [],
            selectionChoixUe: {},
            token: null,
            loadingChargement: false,
            bool: false,
            submitted: false,
            globalFilter: null,
            dialogEc: false,
            dialogUe: false,
            optionUE: false,
        };
    }

    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        this.setState({ token: token });

        if (this.isDepartement) {
            this.parcoursDepartementService
                .getListeParcoursDepartement(token.code_departement)
                .then((data) => {
                    this.setState({ listeParcours: data });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (this.isScolarite) {
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    this.setState({
                        listeDepartement: data.departementEtabDtos,
                    });
                })
                .catch((error) => console.log(error));
        }
        this.anneeService
            .getAll()
            .then((data) => {
                this.setState({ listeAnnee: data });
            })
            .catch((error) => {
                console.log(error);
                //this.notify.echec(error.response.data.message)
            });
    };

    listeInscris = () => {
        let idDepartement;
        if (this.isDepartement)
            idDepartement = this.state.token.code_departement;
        else if (this.isScolarite)
            idDepartement = this.state.departement.idDepartementEtab;
        let idParcours = this.state.parcours.idParcours;
        let idAnneAccademique = this.state.annee.idAnneeAcademique;
        let idNiveau = this.state.niveau.idNiveau;
        let idSemestre = this.state.semestre.idSemestre;
        this.setState({ loadingChargement: true });
        this.inscriptionService
            .getListeEtudiantInscriptionEc(
                idDepartement,
                idParcours,
                idAnneAccademique,
                idNiveau,
                idSemestre
            )
            .then((data) => {
                this.setState({
                    listeEtudiant: data,
                    loadingChargement: false,
                });
                //console.log(data);
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loadingChargement: false });
            });
        /*let listeEtudiant = [
            {
                createdBy: "ygalpen@gmail.com",
                createdDate: "2022-03-30 17:28:36",
                lastModifiedBy: "ygalpen@gmail.com",
                lastModifiedDate: "2022-03-30 17:28:36",
                idEtudiant: 3256,
                ine: "19-5443 P",
                nom: "BABINE",
                prenom: "Jean Aristide    ",
                dateNaissance: "1998-09-08",
                lieuNaissance: "Réo",
                sexe: "M",
                idAnneeAcademique: 2,
                idInscriptionPedagogique: 3062,
                idParcoursFiliere: null,
                listeUe: [
                    {
                        createdBy: "mwangre@yahoo.fr",
                        createdDate: "2021-07-16 13:56:10",
                        lastModifiedBy: "mwangre@yahoo.fr",
                        lastModifiedDate: "2021-07-26 17:22:20",
                        idUniteEnseignement: 690,
                        codeUniteEnseignement: " CAPACITE PRO",
                        libelleUniteEnseignement: "CAPACITE PROFESSIONNELLE (MPC1)",
                        ueOptionnel: true,
                        visible: null,
                        listeElementConstitutif: [
                            {
                                createdBy: "mwangre@yahoo.fr",
                                createdDate: "2021-07-16 13:56:10",
                                lastModifiedBy: "mwangre@yahoo.fr",
                                lastModifiedDate: "2021-07-16 13:56:10",
                                idInscriptionEc: null,
                                idElementConstitutif: 1083,
                                codeElementConstitutif: "Pedago01",
                                codeUniteEnseignement: " CAPACITE PRO",
                                libelleUniteEnseignement: "CAPACITE PROFESSIONNELLE (MPC1)",
                                idTypeUe: 4,
                                libelleTypeUe: "Optionnel",
                                libelleElementConstitutif: "Pédagogie générale 1",
                                idInscriptionPedagogique: 3062,
                                dateInscription: null,
                                visible: true
                            },
                            {
                                createdBy: "mwangre@yahoo.fr",
                                createdDate: "2021-07-16 14:26:39",
                                lastModifiedBy: "mwangre@yahoo.fr",
                                lastModifiedDate: "2021-07-16 14:26:39",
                                idInscriptionEc: null,
                                idElementConstitutif: 1114,
                                codeElementConstitutif: "Psycho02",
                                codeUniteEnseignement: " CAPACITE PRO",
                                libelleUniteEnseignement: "CAPACITE PROFESSIONNELLE (MPC1)",
                                idTypeUe: 4,
                                libelleTypeUe: "Optionnel",
                                libelleElementConstitutif: "Psychopédagogie",
                                idInscriptionPedagogique: 3062,
                                dateInscription: null,
                                visible: true
                            }
                        ]
                    },
                    {
                        createdBy: "mwangre@yahoo.fr",
                        createdDate: "2021-07-16 13:58:43",
                        lastModifiedBy: "mwangre@yahoo.fr",
                        lastModifiedDate: "2021-07-26 17:23:55",
                        idUniteEnseignement: 691,
                        codeUniteEnseignement: "ENVIRONNE01",
                        libelleUniteEnseignement: "ENVIRONNEMENT (MPC1)",
                        ueOptionnel: true,
                        visible: null,
                        listeElementConstitutif: [
                            {
                                createdBy: "mwangre@yahoo.fr",
                                createdDate: "2021-07-16 13:58:43",
                                lastModifiedBy: "mwangre@yahoo.fr",
                                lastModifiedDate: "2021-07-16 13:58:43",
                                idInscriptionEc: null,
                                idElementConstitutif: 1084,
                                codeElementConstitutif: "Informa27",
                                codeUniteEnseignement: "ENVIRONNE01",
                                libelleUniteEnseignement: "ENVIRONNEMENT (MPC1)",
                                idTypeUe: 4,
                                libelleTypeUe: "Optionnel",
                                libelleElementConstitutif: "Informatique",
                                idInscriptionPedagogique: 3062,
                                dateInscription: null,
                                visible: true
                            },
                            {
                                createdBy: "mwangre@yahoo.fr",
                                createdDate: "2021-07-16 14:28:59",
                                lastModifiedBy: "mwangre@yahoo.fr",
                                lastModifiedDate: "2021-07-16 14:28:59",
                                idInscriptionEc: null,
                                idElementConstitutif: 1116,
                                codeElementConstitutif: "TEEO",
                                codeUniteEnseignement: "ENVIRONNE01",
                                libelleUniteEnseignement: "ENVIRONNEMENT (MPC1)",
                                idTypeUe: 4,
                                libelleTypeUe: "Optionnel",
                                libelleElementConstitutif: "Technique d'expression écrite et orale",
                                idInscriptionPedagogique: 3062,
                                dateInscription: null,
                                visible: true
                            },
                            {
                                createdBy: "mwangre@yahoo.fr",
                                createdDate: "2021-07-16 14:28:59",
                                lastModifiedBy: "mwangre@yahoo.fr",
                                lastModifiedDate: "2021-07-16 14:28:59",
                                idInscriptionEc: null,
                                idElementConstitutif: 1115,
                                codeElementConstitutif: "Anglais02",
                                codeUniteEnseignement: "ENVIRONNE01",
                                libelleUniteEnseignement: "ENVIRONNEMENT (MPC1)",
                                idTypeUe: 4,
                                libelleTypeUe: "Optionnel",
                                libelleElementConstitutif: "Anglais",
                                idInscriptionPedagogique: 3062,
                                dateInscription: null,
                                visible: true
                            }
                        ]
                    }
                ],
                listeElementConst: [
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1073,
                        codeElementConstitutif: "Optique03",
                        codeUniteEnseignement: "PHYSI",
                        libelleUniteEnseignement: "PHYSIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Optique géométrique",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1072,
                        codeElementConstitutif: "Electrici02",
                        codeUniteEnseignement: "PHYSI",
                        libelleUniteEnseignement: "PHYSIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Electricité 1 : électrostatique et électrocinétique",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1071,
                        codeElementConstitutif: "Mécani01",
                        codeUniteEnseignement: "PHYSI",
                        libelleUniteEnseignement: "PHYSIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Mécanique du point matériel",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1077,
                        codeElementConstitutif: "Chimie01",
                        codeUniteEnseignement: "CHIMIE",
                        libelleUniteEnseignement: "CHIMIE (MPC1)",
                        idTypeUe: 4,
                        libelleTypeUe: "Optionnel",
                        libelleElementConstitutif: "Thermochimie et équilibre chimique",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: true
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1082,
                        codeElementConstitutif: "Géo01",
                        codeUniteEnseignement: "MATH",
                        libelleUniteEnseignement: "MATHEMATIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Géométrie 1",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1081,
                        codeElementConstitutif: "HistMath",
                        codeUniteEnseignement: "MATH",
                        libelleUniteEnseignement: "MATHEMATIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Histoire des maths",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1080,
                        codeElementConstitutif: "Alg01",
                        codeUniteEnseignement: "MATH",
                        libelleUniteEnseignement: "MATHEMATIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Algèbre 1",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1111,
                        codeElementConstitutif: "Alg02",
                        codeUniteEnseignement: "MATH",
                        libelleUniteEnseignement: "MATHEMATIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Algèbre 2",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1112,
                        codeElementConstitutif: "Analys01",
                        codeUniteEnseignement: "MATH",
                        libelleUniteEnseignement: "MATHEMATIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Analyse 1",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1113,
                        codeElementConstitutif: "Géo02",
                        codeUniteEnseignement: "MATH",
                        libelleUniteEnseignement: "MATHEMATIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Géométrie 2",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1102,
                        codeElementConstitutif: "PhysiExp05",
                        codeUniteEnseignement: "PHYSI",
                        libelleUniteEnseignement: "PHYSIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Physique expérimentale 1",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1101,
                        codeElementConstitutif: "Magneto04",
                        codeUniteEnseignement: "PHYSI",
                        libelleUniteEnseignement: "PHYSIQUE (MPC1)",
                        idTypeUe: 2,
                        libelleTypeUe: "Fondamentale",
                        libelleElementConstitutif: "Magnétostatique et régime variable",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: false
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1107,
                        codeElementConstitutif: "ChimiExp03",
                        codeUniteEnseignement: "CHIMIE",
                        libelleUniteEnseignement: "CHIMIE (MPC1)",
                        idTypeUe: 4,
                        libelleTypeUe: "Optionnel",
                        libelleElementConstitutif: "Chimie expérimentale 1",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: true
                    },
                    {
                        createdBy: "ygalpen@gmail.com",
                        createdDate: "2022-03-30 17:28:36",
                        lastModifiedBy: "ygalpen@gmail.com",
                        lastModifiedDate: "2022-03-30 17:28:36",
                        idInscriptionEc: null,
                        idElementConstitutif: 1106,
                        codeElementConstitutif: "StrucMatie02",
                        codeUniteEnseignement: "CHIMIE",
                        libelleUniteEnseignement: "CHIMIE (MPC1)",
                        idTypeUe: 4,
                        libelleTypeUe: "Optionnel",
                        libelleElementConstitutif: "Structure de la matière",
                        idInscriptionPedagogique: 3062,
                        dateInscription: null,
                        visible: true
                    }
                ]
            }
        ]
        
        this.setState({listeEtudiant : listeEtudiant})*/
    };

    affichieEcOptionnel = (etudiant) => {
        this.setState({ etudiant: etudiant });
        let listeUE = etudiant.listeUe;
        if (listeUE.length > 0) {
            this.setState({
                listeUEOptionnel: listeUE,
                dialogUe: true,
                optionUE: true,
            });
        } else {
            let listeEC = etudiant.listeElementConst;
            let listeEcOptionnel = listeEC.filter((ec) => {
                if (ec.visible === true) return ec;
            });
            this.setState({
                dialogEc: true,
                listeEC: listeEC,
                listeECOptionnel: listeEcOptionnel,
            });
        }
    };

    saveChoixEc = () => {
        let idDepartement;
        if (this.isDepartement)
            idDepartement = this.state.token.code_departement;
        else if (this.isScolarite)
            idDepartement = this.state.departement.idDepartementEtab;
        let idParcours = this.state.parcours.idParcours;
        let idAnneAccademique = this.state.annee.idAnneeAcademique;
        let idNiveau = this.state.niveau.idNiveau;
        let idSemestre = this.state.semestre.idSemestre;
        if (this.state.optionUE) {
            let listeEc = this.state.etudiant.listeElementConst;
            //console.log(listeEc.length)
            let listeUeChoisi = this.state.selectionChoixUe;
            let listeEcUe = listeUeChoisi.listeElementConstitutif;
            listeEcUe.map((ec) => {
                listeEc.push(ec);
            });
            //console.log(listeEc.length)
            //console.log(listeEc)
            this.inscriptionService
                .getListeInscriptionEc(
                    idDepartement,
                    idParcours,
                    idAnneAccademique,
                    idNiveau,
                    idSemestre,
                    listeEc
                )
                .then((data) => {
                    //alert("YO !")
                    this.notify.success();
                    this.setState({ etudiant: {}, dialogUe: false });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({ dialogUe: false });
                });
            this.setState({ etudiant: {}, dialogUe: false });
        } else {
            let listeEc = this.state.listeEC;
            let listeEcOptionnel = this.state.listeECOptionnel;
            let listeEcChoisi = this.state.selectionChoixEc;
            let listeIdElementConstitutifAsupprimer = [];
            let listeIdElementConstitutifEcNonChoisie = [];

            listeEcChoisi.map((ec) => {
                listeIdElementConstitutifAsupprimer.push(
                    ec.idElementConstitutif
                );
            });

            let listeEcOptionnelRestant = listeEcOptionnel.filter((ec) => {
                return (
                    listeIdElementConstitutifAsupprimer.indexOf(
                        ec.idElementConstitutif
                    ) === -1
                );
            });
            /*console.log(listeEcChoisi)
            console.log(listeEcOptionnel)
            console.log(listeEcRestant)*/

            listeEcOptionnelRestant.map((ec) => {
                listeIdElementConstitutifEcNonChoisie.push(
                    ec.idElementConstitutif
                );
            });

            let listeEcInscriptionPedagogique = listeEc.filter((ec) => {
                return (
                    listeIdElementConstitutifEcNonChoisie.indexOf(
                        ec.idElementConstitutif
                    ) === -1
                );
            });

            /*let listeEcInscriptionPedadogiqueEtudiant = listeEcInscriptionPedagogique.map(ec => {
                ec.idEtudiant = this.state.etudiant.idEtudiant
                return ec;
            })*/

            //console.log(listeEcInscriptionPedadogiqueEtudiant)
            //console.log(listeEcInscriptionPedagogique)
            /*console.log(idDepartement)
            console.log(idParcours)
            console.log(idAnneAccademique)
            console.log(idNiveau)
            console.log(idSemestre)*/
            this.inscriptionService
                .getListeInscriptionEc(
                    idDepartement,
                    idParcours,
                    idAnneAccademique,
                    idNiveau,
                    idSemestre,
                    listeEcInscriptionPedagogique
                )
                .then((data) => {
                    // alert("YO !")
                    this.notify.success();
                    this.setState({ dialogEc: false });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({ dialogEc: false });
                });
        }
    };

    onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === "departement") {
            this.setState({ departement: val });
            this.parcoursDepartementService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    this.setState({ listeParcours: data });
                })
                .catch((error) => console.log(error));
        }
        if (field === "parcours") {
            this.setState({ parcours: val });
            let niveau = [];
            this.semestreService.get(val.idParcours).then((data) => {
                let semestre = data;
                this.setState({ listeSemestre: data });

                this.niveauService.getAll().then((response) => {
                    response.map((niv) => {
                        semestre.map((se) => {
                            if (niv.idNiveau === se.idNiveau) niveau.push(niv);
                        });
                    });
                    let collectionIdNiveau = [];
                    niveau.map((nive) => {
                        collectionIdNiveau.push(nive.idNiveau);
                    });
                    //console.log(collectionIdNiveau)
                    let listeNiveau = [];
                    niveau.map((nivo, index) => {
                        for (
                            let i = index + 1;
                            i < collectionIdNiveau.length;
                            i++
                        ) {
                            if (nivo.idNiveau === collectionIdNiveau[i]) {
                                //console.log(nivo)
                                listeNiveau.push(nivo);
                            }
                        }
                    });
                    //console.log(listeNiveau)
                    this.setState({ listeNiveau: listeNiveau });
                });
            });
        } else if (field === "annee") {
            //console.log(val)
            this.setState({ annee: val });
        } else if (field === "niveau") {
            this.setState({ niveau: val });
            this.semestreService
                .getSemestreNiveau(val.idNiveau)
                .then((data) => {
                    this.setState({ listeSemestre: data.semestreDtoList });
                    //console.log(data)
                });
        } else if (field === "semestre") this.setState({ semestre: val });
    };

    hideDialogEc = () => {
        this.setState({
            dialogEc: false,
        });
    };
    hideDialogUe = () => {
        this.setState({
            dialogUe: false,
        });
    };

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des Ec à affecter"
                    onClick={() => this.affichieEcOptionnel(rowData)}
                />
            </React.Fragment>
        );
    };

    render() {
        const choixEcDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDialogEc}
                />
                <Button
                    label="Enregistrer"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.saveChoixEc}
                />
            </React.Fragment>
        );
        const choixUeDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDialogUe}
                />
                <Button
                    label="Enregistrer"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.saveChoixEc}
                />
            </React.Fragment>
        );
        return (
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >
                    <div className="p-grid p-lg-12" style={{ marginTop: "3%" }}>
                        {this.isScolarite ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "5%" }}
                                >
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={this.state.departement}
                                        options={this.state.listeDepartement}
                                        onChange={(e) => {
                                            this.onChampsChange(
                                                e,
                                                "departement"
                                            );
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un département"
                                    />
                                    {this.state.submitted &&
                                        !this.state.departement && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un département
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={this.state.parcours}
                                        value={this.state.parcours}
                                        options={this.state.listeParcours}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {this.state.submitted &&
                                        !this.state.parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        optionValue={this.state.annee}
                                        value={this.state.annee}
                                        options={this.state.listeAnnee}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {this.state.submitted &&
                                        !this.state.annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={this.state.niveau}
                                        value={this.state.niveau}
                                        options={this.state.listeNiveau}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {this.state.submitted &&
                                        !this.state.niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleSemestre"
                                        optionValue={this.state.semestre}
                                        value={this.state.semestre}
                                        options={this.state.listeSemestre}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "semestre");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un semestre"
                                    />
                                    {this.state.submitted &&
                                        !this.state.semestre && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un semestre
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Afficher"
                                        onClick={this.listeInscris}
                                    />
                                </div>
                            </>
                        ) : this.isDepartement ? (
                            <>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: "20%" }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        optionValue={this.state.parcours}
                                        value={this.state.parcours}
                                        options={this.state.listeParcours}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "parcours");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {this.state.submitted &&
                                        !this.state.parcours && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un parcours.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        optionValue={this.state.annee}
                                        value={this.state.annee}
                                        options={this.state.listeAnnee}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "annee");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {this.state.submitted &&
                                        !this.state.annee && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner une année.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleNiveau"
                                        optionValue={this.state.niveau}
                                        value={this.state.niveau}
                                        options={this.state.listeNiveau}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "niveau");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un niveau"
                                    />
                                    {this.state.submitted &&
                                        !this.state.niveau && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un niveau.
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleSemestre"
                                        optionValue={this.state.semestre}
                                        value={this.state.semestre}
                                        options={this.state.listeSemestre}
                                        onChange={(e) => {
                                            this.onChampsChange(e, "semestre");
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": this.state.submitted,
                                        })}
                                        placeholder="Selectionner un semestre"
                                    />
                                    {this.state.submitted &&
                                        !this.state.semestre && (
                                            <small
                                                className="p-invalid"
                                                style={{ color: "red" }}
                                            >
                                                Selectionner un semestre
                                            </small>
                                        )}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Afficher"
                                        onClick={this.listeInscris}
                                    />
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    {this.state.loadingChargement ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="p-lg-12" style={{ marginTop: 10 }}>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeEtudiant}
                                    selectionMode="single"
                                    header="Liste des étudiants inscrits"
                                    paginator={true}
                                    rows={10}
                                    globalFilter={this.state.globalFilter}
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="ine"
                                        header="INE"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un ine"
                                    />
                                    <Column
                                        field="nom"
                                        header="Nom"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un nom"
                                    />
                                    <Column
                                        field="prenom"
                                        header="Prénom"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un prénom"
                                    />
                                    <Column
                                        field="sexe"
                                        header="Sexe"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher par sexe"
                                    />
                                    <Column
                                        field="dateNaissance"
                                        header="date de naiss"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher date de naiss."
                                    />
                                    <Column
                                        field="lieuNaissance"
                                        header="Lieu de naiss."
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher par lieu de naiss."
                                    />
                                    <Column
                                        body={this.actionBodyTemplate}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                        </>
                    )}

                    <Dialog
                        visible={this.state.dialogEc}
                        style={{ width: "450px" }}
                        header="Choix Ec"
                        modal
                        className="p-fluid"
                        footer={choixEcDialogFooter}
                        onHide={this.hideDialogEc}
                    >
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.listeECOptionnel}
                            selectionMode="multiple"
                            selection={this.state.selectionChoixEc}
                            onSelectionChange={(event) =>
                                this.setState({ selectionChoixEc: event.value })
                            }
                            header="Liste des ECUE optionnels"
                            paginator={true}
                            rows={10}
                            responsive={true}
                        >
                            <Column
                                field="codeElementConstitutif"
                                header="Code EC"
                                sortable={true}
                            />
                            <Column
                                field="libelleElementConstitutif"
                                header="Libelle EC"
                                sortable={true}
                            />
                            <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "5em" }}
                            />
                        </DataTable>
                    </Dialog>
                    <Dialog
                        visible={this.state.dialogUe}
                        style={{ width: "450px" }}
                        header="Choix UE"
                        modal
                        className="p-fluid"
                        footer={choixUeDialogFooter}
                        onHide={this.hideDialogUe}
                    >
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.listeUEOptionnel}
                            selectionMode="single"
                            selection={this.state.selectionChoixUe}
                            onSelectionChange={(event) =>
                                this.setState({ selectionChoixUe: event.value })
                            }
                            header="Liste des UE optionnels"
                            paginator={true}
                            rows={10}
                            responsive={true}
                        >
                            <Column
                                field="codeUniteEnseignement"
                                header="Code UE"
                                sortable={true}
                            />
                            <Column
                                field="libelleUniteEnseignement"
                                header="Libelle UE"
                                sortable={true}
                            />
                            <Column
                                selectionMode="single"
                                header={"Choix"}
                                headerStyle={{ width: "5em" }}
                            />
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default EcEtudiant;
