import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class EnseignantService {
     constructor(){
         this.adresse = new AdresseIP()
     }

    getListeGrade() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/grade"
        return axios.get(url).then(res => res.data) 
    }


    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant"
        return axios.get(url).then(res => res.data) 
    }
    
    create(newEnseignant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant"
        return axios.post(url,newEnseignant)
                    .then(res => res.data);
    }
    update(idEnseignant ,newEnseignant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant/"
        return axios.put(url+idEnseignant, newEnseignant)
                    .then(res => res.data);
    }
    delete(idEnseignant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant/"
        return axios.patch(url+idEnseignant)
                    .then(res => res.data)
    }
    getEnseignant(matricule) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant/"
        return axios.get(url+matricule)
                    .then(res => res.data)
    }

    getListeEnseignant(idSession, idDepartementFiliere, idParcours){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant?idSession="+idSession+"&idDepartementFiliere="+idDepartementFiliere+"&idParcours="+idParcours
        return axios.get(url)
                    .then(res => res.data)
    }
    getEcEnseignant(idEnseignant, idDepartement, idAnnee, idSemestre) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif/notation?idDepartement="+idDepartement+"&idAnneeAcademique="+idAnnee+"&idSemestre="+idSemestre+"&idEnseignant="+idEnseignant
        return axios.get(url)
                    .then(res => res.data)
    }

    chargementFichier(donneeFichier){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant/uploads"
        return axios.post(url, donneeFichier)
                    .then(res => res.data);
    }
}