import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Notify } from '../../components/Notify';
import { ToastContainer} from 'react-toastify';
import { Spinner } from '../../components/Spinner';
import { ECService } from '../service/ECService';
import { ExamenService } from '../service/ExamenService';
import { ParamNoteService } from '../service/ParamNoteService';
import { ParcoursUEService } from '../service/ParcoursUEService';
import { SessionExamenService } from '../service/SessionExamenService';
import { TypeActiviteService } from '../service/TypeActiviteService';
import  '../../css/style.css'
import { SurveillantService } from '../service/SurveillantService'
import { Checkbox } from 'primereact/checkbox'

export class GestionExamen extends Component {
        
    emptyExamen = {
        idExamen: null,
        libelleExamen: null,
        heureFinExamen: null,
        intituleExamen: null,
        dateExamen: null,
        heureExamen: null,
        observationExamen: null,
        ec: null,
        ue: null,
        paramNote: null,
        typeActivite: null,
        session: null,
        infrastructure: null,
        nbCopie: null,
        incident: null,
        fgIncident: false,
        dateRemiseCopies: '',
        dateRemiseNotes: ''
    }


    
    constructor(props){

        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.examenService = new ExamenService()
        this.elementConstitutifService = new ECService()
        this.sessionService = new SessionExamenService()
        this.TypeActiviteService = new TypeActiviteService()
        this.paramNoteService = new ParamNoteService()
        this.parcoursUEService = new ParcoursUEService()
        this.surveillantService = new SurveillantService()        
        this.idExisteIncident = React.createRef()


        this.state = {
            listeExamen : [],
            listeSession : [],
            listeUe : [],
            listeEc : [],
            listeParamNote : [],
            listeExamenEc: [],
            listeInfrastructure: [],
            listeSurveillant: [],
            listeSurveillantExamen: [],
            selectionEnsembleSurveillant: [],
            selectionSurveillantExamen: [],
            surveillantsDisponible: [],
            surveillantExamenDialog: false,
            createSurveillantDialog: false,
            surveillant: {},
            examen : this.emptyExamen,
            examenSurveillant: {},
            informationExamen: [],
            session: null,
            submitted: false,
            redirect : false,
            loading : false,
            loadingListe: false,
            globalFilter: null,
            globalFilterListeExamen : null,
            globalFilterListeSurveillant: null,
            examenDialog: false,
            sessionDialog : false,
            deleteExamenDialog: false,
            listeSessionDialog : false,
            listeTitle: "",
            editionTaux: false,
            verifTaux: false,
            messageTaux: "",
            messageInfrastructure: "",
            spin: false,
            fgIncident: false,
            code_etablissement: null,
            remiseCopieDialog: false

        }

 
    }

    componentDidMount(){

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        //console.log(token)

        this.setState({
            listeSessionDialog: true,
            loading: true,
            code_etablissement: token.code_etablissement
        })

        this.sessionService.getSessionEtablissement(token.code_etablissement).then(data => {
            this.setState({
                listeSession : data.data,
                loading: false
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

        this.surveillantService.getAllEtablissement(token.code_etablissement).then(data => {
            this.setState({
                listeSurveillant: data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

    }
       


    afficheExamen = (session) => {
        
        this.setState({
            examenDialog: true,
            session : session

        });
        
        let idParcours = session.parcoursFiliere.idParcours
        let idSemestre = session.anneeSemestre.idSemestre
        this.parcoursUEService.get(idParcours, idSemestre).then(data => {
            this.setState({
                listeUe: data
            })
        })
    }
    

    onExamenSurveillantChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        let examenSurveillant = this.state.examenSurveillant
        examenSurveillant[`${field}`] = val
        this.setState({examenSurveillant})
    }


    gestionSurveillant = (examen) => {

        this.examenService.getListeSurveillantExamen(examen.idExamen).then(data => {

            let examenSurveillant =   (data.length>0)? data[0].examen: {}

            let listeSurveillantExamen = data.map(examenSurveillant => {
                let actuelSurveillant = examenSurveillant.surveillant
                actuelSurveillant['idExamenSurveillant'] = examenSurveillant['idExamenSurveillant']
                return actuelSurveillant
            })

            let listeIdSurveillantExamen = listeSurveillantExamen.map( surveillant => {
                return surveillant.idSurveillant
            })
            
            let surveillantsDisponible = this.state.listeSurveillant.filter(surveillant => {
                return listeIdSurveillantExamen.indexOf(surveillant.idSurveillant)===-1
            })

            this.setState({
                examen: examen,
                informationExamen: [examen],
                examenSurveillant: examenSurveillant,
                surveillantExamenDialog: true,
                listeTitle: examen.libelleExamen,
                listeSurveillantExamen: listeSurveillantExamen,
                surveillantsDisponible: surveillantsDisponible
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

    }


    gestionRemiseCopies = (examen) => {

        this.examenService.getListeSurveillantExamen(examen.idExamen).then(data => {

            let examenSurveillant =   (data.length>0)? data[0].examen: {}

            let listeSurveillantExamen = data.map(examenSurveillant => {
                let actuelSurveillant = examenSurveillant.surveillant
                actuelSurveillant['idExamenSurveillant'] = examenSurveillant['idExamenSurveillant']
                return actuelSurveillant
            })

            let listeIdSurveillantExamen = listeSurveillantExamen.map( surveillant => {
                return surveillant.idSurveillant
            })
            
            let surveillantsDisponible = this.state.listeSurveillant.filter(surveillant => {
                return listeIdSurveillantExamen.indexOf(surveillant.idSurveillant)===-1
            })

            this.setState({
                examen: examen,
                informationExamen: [examen],
                examenSurveillant: examenSurveillant,
                remiseCopieDialog: true,
                listeTitle: examen.libelleExamen,
                listeSurveillantExamen: listeSurveillantExamen,
                surveillantsDisponible: surveillantsDisponible
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }



    ajouterSurveillantExamen = () => {
        if(this.state.selectionEnsembleSurveillant.length>0){
            //On colectionne les id surveillants qui ont ete selectionne
            let idSurveillantSelectionne = this.state.selectionEnsembleSurveillant.map(surveillant => {
                return surveillant.idSurveillant
            })

            //On les retire de la liste des surveillant disponibles puisqu'ayant ete selectionnee
            let newSurveillantDisponible = this.state.surveillantsDisponible.filter(surveillant => {
                return idSurveillantSelectionne.indexOf(surveillant.idSurveillant)===-1
            })

            //On ajoute la selection a la liste des surveillant de l'examen
            let ensembleSelectionSurveillant = this.state.listeSurveillantExamen
            this.state.selectionEnsembleSurveillant.map(surveillant => {
                ensembleSelectionSurveillant.push(surveillant)
            })

            this.setState({
                surveillantsDisponible: newSurveillantDisponible,
                listeSurveillantExamen: ensembleSelectionSurveillant,
                selectionEnsembleSurveillant: []
            })
        }
    }


    retirerSurveillant = () => {

        if(this.state.selectionSurveillantExamen.length>0){

            //La liste que l'on veut retirer des surveillant
            let listeRetraitSurveillant = this.state.selectionSurveillantExamen

            //On constitue leurs id
            let idSurveillantRetrait = listeRetraitSurveillant.map(surveillant => {
                return surveillant.idSurveillant
            })

            //On les retir de la liste des surveillants de l'examen
            let newListeSurveillantExamen = this.state.listeSurveillantExamen.filter(surveillant => {
                return idSurveillantRetrait.indexOf(surveillant.idSurveillant)===-1
            })

            //On les ajoute a la liste selectionnable puisqu'ayant ete retire de la selection
            let listeSurveillantDisponible = this.state.surveillantsDisponible

            listeRetraitSurveillant.map(surveillant => {
                listeSurveillantDisponible.push(surveillant)
            })

            this.setState({
                surveillantsDisponible: listeSurveillantDisponible,
                listeSurveillantExamen: newListeSurveillantExamen,
                selectionSurveillantExamen: []
            })
        }
    }


//////////////////////////////////////////
/////////////////////////////////////////
    enregistrerSurveillantExamen = () => {

        if(this.state.listeSurveillantExamen.length>0 ){

            let examen = this.state.examen
            let examenSurveillant = this.state.examenSurveillant
            this.setState({
                submitted: true
            })
            let listeExamenSurveillant = this.state.listeSurveillantExamen.map(surveillant => {
                return {
                    idExamenSurveillant: surveillant.idExamenSurveillant,
                    examen: null,
                    surveillant
                }
            })

            let newStruct = {
                idExamen: examen.idExamen,
                incident: examenSurveillant.incident,
                nbCopie: examenSurveillant.nbCopie,
                fgIncident: examenSurveillant.fgIncident,
                dateRemiseCopies: examenSurveillant.dateRemiseCopies,
                dateRemiseNotes: examenSurveillant.dateRemiseNotes,
                listeExamenSurveillantDto: listeExamenSurveillant
            }

            this.examenService.getSaveSurveillantExamen(newStruct).then(data => {
                this.notify.success("Surveillant enregistré avec succès !")
                this.setState({
                    submitted: false
                })
                this.hideListeSurveillantDialog()
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
            
            

        }else{
            this.notify.echec('Veuillez choisir au moins un surveillant avant enregistrement')
        }
    }



    enregistrerInformationCopieExamen = () => {

        if(this.state.listeSurveillantExamen.length>0 ){




            let examen = this.state.examen
            let examenSurveillant = this.state.examenSurveillant
            this.setState({
                submitted: true
            })

            let valideForm = false

            if(examenSurveillant.fgIncident){

                if(examenSurveillant.incident.trim().length>3 && examenSurveillant.nbCopie && examenSurveillant.nbCopie>0 && examenSurveillant.dateRemiseCopies){
                    valideForm = true
                }

            }else{

                if(examenSurveillant.nbCopie && examenSurveillant.nbCopie>0 && examenSurveillant.dateRemiseCopies){
                    valideForm = true
                }
            }

            if(valideForm){

                let listeExamenSurveillant = this.state.listeSurveillantExamen.map(surveillant => {
                    return {
                        idExamenSurveillant: surveillant.idExamenSurveillant,
                        examen: null,
                        surveillant
                    }
                })
    
                let newStruct = {
                    idExamen: examen.idExamen,
                    incident: examenSurveillant.incident,
                    nbCopie: examenSurveillant.nbCopie,
                    fgIncident: examenSurveillant.fgIncident,
                    dateRemiseCopies: examenSurveillant.dateRemiseCopies,
                    dateRemiseNotes: examenSurveillant.dateRemiseNotes,
                    listeExamenSurveillantDto: listeExamenSurveillant
                }
    
                this.examenService.getSaveSurveillantExamen(newStruct).then(data => {
                    this.notify.success("Surveillant enregistré avec succès !")
                    this.setState({
                        submitted: false
                    })
                    this.hideListeSurveillantDialog()
                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })

            }
            


        }else{
            this.notify.echec('Aucun surveillant ne figure dans la gestion de l\'examen, veillez renseigner d\'abors les surveillants')
        }
    }


    hideListeSurveillantDialog = () => {
        this.setState({
            surveillantExamenDialog: false,
            remiseCopieDialog: false,
            examenSurveillant: {},
            informationExamen: [],
            selectionSurveillantExamen: [],
            selectionEnsembleSurveillant: [],
            surveillantsDisponible: [],
            listeSurveillantExamen: [],
            examen: {
                idExamen: null,
                libelleExamen: null,
                heureFinExamen: null,
                intituleExamen: null,
                dateExamen: null,
                heureExamen: null,
                observationExamen: null,
                ec: null,
                ue: null,
                paramNote: null,
                typeActivite: null,
                session: null
            },
            submitted: false
        })

    }


    


    hideSessionDialog = () => {
        this.setState({
            sessionDialog: false
        });
    }

    hideListeSessionDialog = () => {
        this.setState({
            listeSessionDialog: false
        });
    }


    viewSelectSession = () => {
        this.setState({
            listeSessionDialog : true, 
        })
    }



    listeExamen = (session) => {

        let idSession = session.idSession
        this.setState({
            listeSessionDialog : false, 
            session : session,
            loadingListe: true,
            listeTitle: session.libelleSession
        })

        this.examenService.examenBySession(idSession).then(data => {

            this.setState({
                listeExamen : data,
                loadingListe: false
            })
        })
        
    }

    onMatriculeSurveillantChange = (e, matriculeSurveillant) => {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${matriculeSurveillant}`] = val;
        this.setState({surveillant });
    }

    onNomSurveillantChange = (e, nomSurveillant) => {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${nomSurveillant}`] = val;

        this.setState({surveillant});
    }

    onPrenomSurveillantChange(e, prenomSurveillant) {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${prenomSurveillant}`] = val;

        this.setState({surveillant });
    }

    onTelSurveillantChange(e, telSurveillant) {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${telSurveillant}`] = val;
        this.setState({surveillant });
    }

    onEmailSurveillantChange(e, emailSurveillant) {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${emailSurveillant}`] = val;
        this.setState({surveillant });
    }




    saveCreateSurveillant = () => {

        this.setState({submitted: true})
        if (this.state.surveillant.matriculeSurveillant!=null && this.state.surveillant.nomSurveillant.trim() && this.state.surveillant.prenomSurveillant.trim() && this.state.surveillant.telSurveillant.trim() && this.state.surveillant.emailSurveillant.trim()) {
            const newSurveillant = {
                idSurveillant : parseInt(this.state.surveillant.idSurveillant) ,
                matriculeSurveillant: this.state.surveillant.matriculeSurveillant,
                nomSurveillant : this.state.surveillant.nomSurveillant,
                prenomSurveillant : this.state.surveillant.prenomSurveillant,
                telSurveillant : this.state.surveillant.telSurveillant,
                emailSurveillant : this.state.surveillant.emailSurveillant,
                idEtablissement: this.state.code_etablissement
            }

            this.surveillantService.create(newSurveillant).then(data => {

                

                this.surveillantService.getAllEtablissement(this.state.code_etablissement).then(data => {

             
                    //On effectue le nouveau filtrage pour ajouter le nouveau
                    let listeIdSurveillantExamen = this.state.listeSurveillantExamen.map(surveillant => {
                        return surveillant.idSurveillant
                    })

                    let surveillantDisponible = data.filter(surveillant => {
                        return listeIdSurveillantExamen.indexOf(surveillant.idSurveillant)===-1
                    })

                    this.setState({
                        listeSurveillant: data,
                        surveillantsDisponible: surveillantDisponible,
                        createSurveillantDialog: false,
                        surveillant: {},
                        submitted: false
                    })

                }).then(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })



            })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error);
            })
        }
    }

    newSurveillantExamen = () => {
        this.setState({
            createSurveillantDialog: true
        })
    }


    hideCreateSurveillantDialog = () => {
        this.setState({
            surveillant: {},
            submitted: false,
            createSurveillantDialog: false
        })
    }


    confirmerDeclarationIncident = () => {
        this.setState({
            fgIncident: this.idExisteIncident.current.checked
        })

        let examen = this.state.examen
        let examenSurveillant = this.state.examenSurveillant

        if(!this.idExisteIncident.current.checked){
            examen['incident'] = ""
            examenSurveillant['incident'] = ""
            examen['fgIncident'] = false
            examenSurveillant['fgIncident'] = false
            this.setState({
                examen,
                examenSurveillant
            })
        }else{
            examen['fgIncident'] = true
            examenSurveillant['fgIncident'] = true
        }
    }


    choixSession = () => {
        console.log(2)
    }



    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-list" className="p-button-warning" style={{marginRight: '.5em'}} title="Liste des notes" onClick={ () => this.listeNoteExamen(rowData) } />
            </React.Fragment>
        )
    }

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-warning" style={{marginRight: '.5em'}} title="Liste des examens" onClick={ () => this.listeExamen(rowData) } />
            </React.Fragment>
        )
    }

    actionBodyTemplateListeExamen = (rowData) => {
        return (
            <React.Fragment>

                <Button type="button" icon="pi pi-inbox" className="p-button-success" style={{marginRight: '.5em'}} title="Remise des copies" onClick={ () => this.gestionRemiseCopies(rowData) } disabled={this.state.session.etatSession==="FERME" || this.state.session.estDelibere } />


                <Button type="button" icon="pi pi-users" className="p-button-success" style={{marginRight: '.5em'}} title="Gestion surveillant" onClick={ () => this.gestionSurveillant(rowData) } disabled={this.state.session.etatSession==="FERME" || this.state.session.estDelibere }/>


                
            </React.Fragment>
        )
    }

    render() {

        //Formulaire de recherche superieur
        let actionHeader = <Button className="enteteDatatable" type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText className="" type="search" onInput={(e) => this.setState({ globalFilterSession: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const examenDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} disabled={this.state.spin?true:false} />
                <Button label="Enregistrer" icon={this.state.spin?"pi pi-spin pi-spinner":"pi pi-check"}  className="p-button-text" onClick={this.saveExamen} disabled={this.state.spin?true:false}/>
            </React.Fragment>
        );



        const listeSessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeSessionDialog} />
            </React.Fragment>
        );

        const listeSurveillantDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeSurveillantDialog} />
                <Button label="Enregistrer" icon="pi pi-save" className="p-button-text" onClick={this.enregistrerSurveillantExamen} />
            </React.Fragment>
        );


        const informationRemiseCopieFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeSurveillantDialog} />
                <Button label="Enregistrer" icon="pi pi-save" className="p-button-text" onClick={this.enregistrerInformationCopieExamen} />
            </React.Fragment>
        );


        const createSurveillantDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideCreateSurveillantDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCreateSurveillant} />
            </React.Fragment>
        );
   
        return (

                <div className="p-grid">
                    <div className="p-col-12">


                        <div className="p-grid" style={{marginLeft : 20, marginTop: 5}}>
                            <div className="p-col-2">
                                <Button label="Choisir une session" icon="pi pi-check-circle" className="p-button-success p-mr-2" onClick={this.viewSelectSession} />
                            </div>
                        </div>


                        <div className="card card-w-title datatable-demo">
                        {<ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />}

                            {header}
                            <DataTable ref={(el) => this.dt = el} value={this.state.listeExamen} selectionMode="single" header={(this.state.session)?<>{`Liste des examens : ${this.state.listeTitle}`}</>:<>En attante de sélection de la session</> } paginator={true} rows={10}
                                globalFilter={this.state.globalFilterSession} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="dateExamen" header="Date de l'examen" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher une date"/>
                                <Column field="heureExamen" header="Heure de debut examen" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher une heure"/>
                                <Column field="heureFinExamen" header="Heure de fin examen" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher une heure de fin"/>
                                <Column field="libelleInfrastructure" header="Infrastructure" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une infrastructure"/>
                                <Column field="paramNote.valParam" header="Taux de notation en %" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un taux"/>
                                <Column field="observationExamen" header="Observation" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un lieu"/>
                                <Column header={actionHeader} body={this.actionBodyTemplateListeExamen} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            
                        </div>
                    </div>
                
                <Dialog visible={this.state.examenDialog} style={{ width: '80%' }} header="Informations de l'examen" modal className="p-fluid" footer={examenDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleUniteEnseignement">Selectionner l'unité d'enseignement</label>
                                <Dropdown optionLabel="libelleUniteEnseignement" optionValue="idUniteEnseignement" value={this.state.examen.ue} options={this.state.listeUe} onChange={(e) => {this.onValueChange(e,'ue')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'unité d'enseignement"/>
                                {this.state.submitted && !this.state.examen.ue && <small className="p-invalid" style={{color : "red"}}>Selectionner l'UE.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleTypeActivite">Selectionner le type d'activité</label>
                                <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={this.state.examen.typeActivite} options={this.state.listeTypeActivite} onChange={(e) => {this.onValueChange(e, 'typeActivite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le type d'activite"/>
                                {this.state.submitted && !this.state.examen.typeActivite && <small className="p-invalid" style={{color : "red"}}>Selectionner le type d'activite.</small>}
                            </div>
                        </div>
                        
                    </div>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleElementConstitutif">Selectionner l'élement constitutif</label>
                                <Dropdown optionLabel="libelleElementConstitutif" optionValue="idElementConstitutif" value={this.state.examen.ec}  options={this.state.listeEc} onChange={(e) => {this.onValueChange(e, 'ec')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'élément constitutif"/>
                                {this.state.submitted && !this.state.examen.ec && <small className="p-invalid" style={{color : "red"}}>Selectionner l'élement constitutif</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="valParam">Selectionner le taux (facultatif)</label>
                                <Dropdown optionLabel="valParam" optionValue="idParamNote" value={this.state.examen.paramNote}  options={this.state.listeParamNote} onChange={(e) => {this.onValueChange(e, 'paramNote')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le taux" disabled/>
                            </div>
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        
                        {(this.state.messageInfrastructure) && this.state.submitted && 
                        (
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <div className="alert alert-warning d-flex align-items-center" role="alert">
                                    <div>
                                        {this.state.messageInfrastructure}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <label htmlFor="dateExamen">Entrer la date de l'examen</label>
                            <input type="date" id="dateExamen" value={this.state.examen.dateExamen}  onChange={(e) => this.onValueChange(e, 'dateExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer la date de l'examen"/>
                            {this.state.submitted && !this.state.examen.dateExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>

                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <label htmlFor="heureExamen">Entrer l'heure de début de l'examen</label>
                            <input type="time" id="heureExamen" value={this.state.examen.heureExamen}  onChange={(e) => this.onValueChange(e, 'heureExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer l'heure de début de l'examen"/>
                        </div>

                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <label htmlFor="heureFinExamen">Entrer l'heure de fin de l'examen</label>
                            <input type="time" id="heureFinExamen" value={this.state.examen.heureFinExamen}  onChange={(e) => this.onValueChange(e, 'heureFinExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer l'heure de fin de l'examen"/>
                        </div>

                    </div>
                    <hr/>

                    <div className='p-col-12 p-md-12 p-lg-12'>
                        <div className="p-field">
                            <label htmlFor="libelleInfrastructure">Infrastructure concernée</label>
                            <Dropdown optionLabel="libelleInfrastructure" optionValue="idInfrastructure" value={this.state.examen.infrastructure}  options={this.state.listeInfrastructure} onChange={(e) => {this.onValueChange(e, 'infrastructure')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'infrastructure"/>
                            {/*this.state.submitted && !this.state.examen.infrastructure && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>*/}
                        </div>
                    </div>

                    <div className='p-col-12 p-md-12 p-lg-12'>
                        <div className="p-grid">
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <label htmlFor="observationExamen">Observation</label>
                                <textarea id="observationExamen" value={this.state.examen.observationExamen} onChange={(e) => this.onValueChange(e, 'observationExamen')}  placeholder="Description" className='p-inputtext p-component' />
                            </div>
                        </div>
                    </div>
                    
                </Dialog>


                <Dialog visible={this.state.listeSessionDialog} style={{ width: '80%' }} header="Sélection de la session" modal className="p-fluid" footer={listeSessionDialogFooter} onHide={this.hideListeSessionDialog}>
                {this.state.loading ? 
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {this.spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable ref={(el) => this.dt = el} value={this.state.listeSession} selectionMode="single" header={`Liste des sessions (${this.state.listeSession.length} sessions)`} paginator={true} rows={10}
                        globalFilter={this.state.globalFilterSession} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="parcoursFiliere.libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                        <Column field="anneeSemestre.libelleAnneeAcademique" header="Année académique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une année"/>
                        <Column field="anneeSemestre.libelleSemestre" header="Semestre" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un semestre"/>
                        <Column field="typeSession.libelleTypeSession" header="Session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher une session"/>
                        <Column field="etatSession" header="Etat de la session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un état"/>
                        <Column header={actionHeader} body={this.actionBodyTemplateSession} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                }
                </Dialog>








                <Dialog visible={this.state.surveillantExamenDialog} style={{ width: '95%' }} header={`Liste des surveillant de l'examen: ${this.state.listeTitle}`} modal className="p-fluid" footer={listeSurveillantDialogFooter} onHide={this.hideListeSurveillantDialog}>
                {this.state.loadingListe ? 
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {this.spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :

                    <React.Fragment>

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <DataTable  ref={(el) => this.dt = el} value={this.state.informationExamen} selectionMode="single" header="Information de l'examen" rows={1}>
                                <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="dateExamen" header="Date de composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une date"/>
                                <Column field="heureExamen" header="Heure de composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une heure"/>
                                <Column field="heureFinExamen" header="Heure de fin composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une heure de fin"/>
                                <Column field="libelleInfrastructure" header="Infrastructure composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une infrastructure"/>
                                <Column field="paramNote.valParam" header="Taux de notation en %" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher un taux"/>
                            </DataTable>
                        </div>
                        <div className='p-col-12 p-md-12 p-lg-12'>
                        <hr/>

                        <div className='row'>
                            <div className="p-col-12">
                                <h6>Gestion des surveillants</h6>
                            </div>
                            
                            <div className="p-col-2">
                                <Button label="Ajouter surveillant" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.newSurveillantExamen} />
                            </div>
                        </div>
                        
                        <hr/>

                        <div className='row'>
                            <div className='p-col-5 p-md-5 p-lg-5'>
                                <DataTable  ref={(el) => this.dt = el} value={this.state.surveillantsDisponible} header="Liste des surveillants" paginator={true} rows={5}  selection={this.state.selectionEnsembleSurveillant} onSelectionChange={event => this.setState({selectionEnsembleSurveillant: event.value})} >
                                <Column field="matriculeSurveillant" header="Matricule surveillant" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un matricule"/>
                                <Column field="nomSurveillant" header="Nom surveillant" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                                <Column field="prenomSurveillant" header="Prénom(s) surveillant" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une heure"/>
                                <Column selectionMode="multiple" selectionAriaLabel="Sélection" header="Cocher pour sélectionner"></Column>
                                </DataTable>
                            </div>

                            <div className='p-md-2 p-lg-2'>

                                <div className="p-col-12">
                                    <Button label="Ajouter ===>" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.ajouterSurveillantExamen} />
                                </div>

                                <div className="p-col-12">
                                    <Button label="<=== Retirer" icon="pi pi-trash" className="p-button-danger p-mr-2" onClick={this.retirerSurveillant} />
                                </div>
                            </div>

                            <div className='p-col-5 p-md-5 p-lg-5'>
                                <DataTable  ref={(el) => this.dt = el} value={this.state.listeSurveillantExamen} header="Liste des surveillants de l'examen" paginator={true} rows={5}  selection={this.state.selectionSurveillantExamen}  onSelectionChange={event => this.setState({selectionSurveillantExamen: event.value})} >
                                <Column field="matriculeSurveillant" header="Matricule surveillant" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un matricule"/>
                                <Column field="nomSurveillant" header="Nom surveillant" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                                <Column field="prenomSurveillant" header="Prénom(s) surveillant" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une heure"/>
                                <Column selectionMode="multiple" selectionAriaLabel="Sélection" header="Cocher pour sélectionner"></Column>
                                </DataTable>
                            </div>
                        </div>
                        

                        </div>

                    </React.Fragment>
                }
                </Dialog>








                <Dialog visible={this.state.remiseCopieDialog} style={{ width: '95%' }} header={`Information sur les copies : ${this.state.listeTitle}`} modal className="p-fluid" footer={informationRemiseCopieFooter} onHide={this.hideListeSurveillantDialog}>


                    <React.Fragment>

                    <div className='p-col-12 p-md-12 p-lg-12'>
                            <DataTable  ref={(el) => this.dt = el} value={this.state.informationExamen} selectionMode="single" header="Information de l'examen" rows={1}>
                                <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="dateExamen" header="Date de composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une date"/>
                                <Column field="heureExamen" header="Heure de composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une heure"/>
                                <Column field="heureFinExamen" header="Heure de fin composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une heure de fin"/>
                                <Column field="libelleInfrastructure" header="Infrastructure composition" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher une infrastructure"/>
                                <Column field="paramNote.valParam" header="Taux de notation en %" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="rechercher un taux"/>
                            </DataTable>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className='row'>

                                <div className="p-col-12">
                                    <hr/>
                                    <h6>Enregistrement des copies</h6>
                                    <hr/>
                                </div>

                                <div className="p-col-12">

                                    <div className="p-grid">

                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="nbCopie">Nombre de copies reçu</label>
                                                <input type="number" id="nbCopie" value={this.state.examenSurveillant.nbCopie}  onChange={(e) => this.onExamenSurveillantChange(e, 'nbCopie')} required autoFocus className='p-inputtext p-component' placeholder="Entrer le nombre de copies" min={1}/>
                                                {this.state.submitted && (!this.state.examenSurveillant.nbCopie || this.state.examenSurveillant.nbCopie<=0) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit être supérieur à 0.</small>}
                                            </div>
                                        </div>

                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="form-check">
                                                
                                                <input className="form-check-input" type="checkbox" value="confirmation" id="confirmation" onClick={this.confirmerDeclarationIncident} ref={this.idExisteIncident} checked={this.state.examenSurveillant.fgIncident}/>
                                                <label className="form-check-label" for="confirmation">
                                                    Le deroulement de cet examen a connu un incident ! (Cocher si oui)
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    {(this.state.fgIncident || this.state.examenSurveillant.fgIncident ) && 
                                        <div className="p-grid">
                                            <div className='p-col-12 p-md-12 p-lg-12'>
                                                <div className="flex align-items-center">
                                                    <label htmlFor="incident" className="ml-2">Renseigner les details sur l'incident constate</label>
                                                    <InputTextarea id="incident" value={this.state.examenSurveillant.incident} onChange={(e) => this.onExamenSurveillantChange(e, 'incident')} rows={5} cols={30} placeholder="Information concernant l'incident"/>
                                                    {this.state.submitted && (this.state.fgIncident || this.state.examenSurveillant.fgIncident ) && (!this.state.examenSurveillant.incident || this.state.examenSurveillant.incident.trim().length<4) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire lorsqu'un incident est signalé et doit compter au moins 4 caractères.</small>}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div className="p-col-12">
                                    <hr/>
                                    <h6>Information sur la remise de copies et de notes</h6>
                                    <hr/>
                                </div>

                                <div className="p-col-12">

                                    <div className="p-grid">

                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <label htmlFor="dateRemiseCopies">Date de remise des copies</label>
                                            <input type="date" id="dateRemiseCopies" value={this.state.examenSurveillant.dateRemiseCopies}  onChange={(e) => this.onExamenSurveillantChange(e, 'dateRemiseCopies')} required autoFocus className='p-inputtext p-component' placeholder="Entrer la date de remise des copies"/>
                                            {this.state.submitted && !this.state.examenSurveillant.dateRemiseCopies && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                        </div>

                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <label htmlFor="dateRemiseNotes">Date de remise des notes</label>
                                            <input type="date" id="dateRemiseNotes" value={this.state.examenSurveillant.dateRemiseNotes}  onChange={(e) => this.onExamenSurveillantChange(e, 'dateRemiseNotes')} required autoFocus className='p-inputtext p-component' placeholder="Entrer la date de remise des notes"/>
                                        </div>

                                    </div>

                                </div>


                            </div>
                            
                        </div>
                    </React.Fragment>

                </Dialog>
                


                <Dialog visible={this.state.createSurveillantDialog} style={{ width: '80%' }} header="Informations surveillant" modal className="p-fluid" footer={createSurveillantDialogFooter} onHide={this.hideCreateSurveillantDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="matriculeSurveillant"><span style={{color:'red'}}>*</span> Matricule</label>
                                <InputText id="matriculeSurveillant" value={this.state.surveillant.matriculeSurveillant || ""}  onChange={(e) => this.onMatriculeSurveillantChange(e, 'matriculeSurveillant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.matriculeSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nomSurveillant"><span style={{color:'red'}}>*</span> Nom</label>
                                <InputText id="nomSurveillant" value={this.state.surveillant.nomSurveillant || ""}  onChange={(e) => this.onNomSurveillantChange(e, 'nomSurveillant')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.nomSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="prenomSurveillant"><span style={{color:'red'}}>*</span> Prénom</label>
                                <InputText id="prenomSurveillant" value={this.state.surveillant.prenomSurveillant || ""}  onChange={(e) => this.onPrenomSurveillantChange(e, 'prenomSurveillant')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.prenomSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                    <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="telSurveillant"><span style={{color:'red'}}>*</span> Téléphone</label>
                                <InputText id="telSurveillant" value={this.state.surveillant.telSurveillant || ""}  onChange={(e) => this.onTelSurveillantChange(e, 'telSurveillant')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.telSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="emailSurveillant"><span style={{color:'red'}}>*</span> Email</label>
                                <InputText id="emailSurveillant" value={this.state.surveillant.emailSurveillant || ""}  onChange={(e) => this.onEmailSurveillantChange(e, 'emailSurveillant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.emailSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>

                </Dialog>
                
            </div> 
        )

    }
    
}
export default GestionExamen