
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Notify {

    success = (message='Opération réussie!') => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    echec = (message="Echec de l'opération !") => toast.error(message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    info = (message="ceci est une information")=>toast.info(message,{
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        // style:{width:"400px"}
    })
    successVH=(message="",position="") => toast.success(message, {
        position: position,
        autoClose: false,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
}
