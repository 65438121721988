import React, {Component} from 'react';
import logoOrange from './images/om_logo.png'
import logoMoove from './images/mobicash_logo.jpg'
import logoCorisMoney from './images/cm_logo.png'
import styleFooter from './styleFooter.css'

export class AppFooter extends Component {

	render() {
		return (
            <footer className="">
			    <div className="layout-footer">
				<div className="p-grid">
					<div className="p-col-4">
						<div className="footer-bloc">
                            <span className="logo-site">Plateforme intégré CampusFaso</span>
                            <div className="by-campusfaso">by CampusFaso</div>
                            <br/>
                            <div className="small-text">
                                Programme présidentiel un étudiant, un ordinateur...
                                sous la supervision de la Direction Générale de l'Enseignement Supérieur (DGESup)...
                                <br/>
                                Ministère de l'Enseignement Supérieur, de la Recherche Scientifique et de l'Innovation (MESRSI)
                                
                            </div>
                        </div>
					</div>
					<div className="p-col-4">
						<div className="footer-bloc">
                            <h3>Autres plateformes</h3>
                            <br/>
                            <ul>
                                <li><a href="www.campusfaso.bf">CampusFaso</a></li>
                                <li><a href="#">Identification des étudiants</a></li>
                            </ul>
                            <h3>Paiements acceptés</h3>
                            <ul className="inline-mode">
                                <li className="payment-icon"><img src={logoOrange} alt="Orange Money"/></li>
                                <li className="payment-icon"><img src={logoMoove} alt="Mobicash"/></li>
                                <li className="payment-icon"><img src={logoCorisMoney} alt="Coris Money"/></li>
                            </ul>
                        </div>
					</div>
					<div className="p-col-4">
						<div className="footer-bloc">
                            <h3>Contacts</h3>
                            <br/>
                            <div className="small-text">
                                

							<strong>Téléphone : </strong> <br/>
                                (+226)  07 76 76 76 / 52 36 69 71 / 58 50 56 55 <br/>
                                <span className="small" style={{fontStyle: "italic"}}>Tous les jours ouvrables de 8h00 à 18h00</span>
                                <br/>



                                <strong>Email : </strong> <br/>
                                <a href="#" className="link-text">orientation@campusfaso.bf</a> <br/>
                                <a href="#" className="link-text">campusfaso@gmail.com</a>
                                <br/><br/>
                                <ul className="footer-social inline-mode link-text">
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
					</div>
				</div>
                
                
				<div class="copyright">
               		<div class="container">
						<div class="row">
							<div class="col-md-12">
								<div class="site-info"> Copyright ©&nbsp;<span itemprop="copyrightYear">2020</span>&nbsp; <span itemprop="copyrightHolder">DGESup - <a href="https:www.campusfaso.bf" rel="nofollow" target="_blank">CAMPUSFASO</a> | Tous droist réservés </span></div>
							</div>
						</div>
                	</div>
            	</div>		
                </div>
                </footer>
		)
	}
}