import React, { Component } from "react";
import { Button } from "primereact/button";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../../components/Spinner";
import { Notify } from "../../../components/Notify";
import { TestPaiementService } from "../../../volumeHoraire/service/TestPaiementServices";
import {
    Redirect,
    BrowserRouter as Router,
    Route,
    Link,
} from "react-router-dom";
import md5 from "md5";

export class TestPaiement extends Component {
    constructor(props) {
        super(props);

        this.notify = new Notify();
        this.spinner = new Spinner();

        this.testPaiementService = new TestPaiementService();

        this.state = {
            etudiant: {
                nom: "Zida",
                prenom: "Tinwendé Christian",
                dateNaissance: "13/05/1991",
                numero: 75181183,
                email: "christianzida@gmail.com",
            },
            montant: 100,
            readyToRedirect: false,
        };
    }

    //Extraction des parametre dans l'URL
    extractParamsUrl = (chaineGET) => {
        chaineGET = chaineGET.split("&");
        var result = {};

        chaineGET.forEach(function (el) {
            var param = el.split("=");
            param[0] = param[0].replace("?", "");
            result[param[0]] = param[1];
        });
        return result;
    };

    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        this.setState({ token: token });
    };

    payerFraisLigdicash = () => {
        let curentDate = Date();

        let structureData = {
            commande: {
                invoice: {
                    items: [
                        {
                            name: "Paiement des frais d'inscription",
                            description:
                                "Il s'agit du paiement des frais d'inscription par LigdiCach",
                            quantity: 1,
                            unit_price: this.state.montant,
                            total_price: this.state.montant,
                        },
                    ],
                    total_amount: this.state.montant,
                    devise: "XOF",
                    description:
                        "Paiement des frais de formation pour l'accès à l'université",
                    customer: "",
                    customer_firstname: this.state.etudiant.prenom,
                    customer_lastname: this.state.etudiant.nom,
                    customer_email: this.state.etudiant.email,
                },
                store: {
                    name: "Plateforme integrée de l'enseignement superieur",
                    website_url: "https://services.campusfaso.bf",
                },
                actions: {
                    cancel_url:
                        "https://services.campusfaso.bf/#/lmd/parametres/test-paiement?dossierId=12result=filed",
                    return_url:
                        "https://services.campusfaso.bf/#/lmd/parametres/test-paiement?dossierId=12&result=success",
                    callback_url:
                        "https://services.campusfaso.bf/#/lmd/parametres/test-paiement",
                },
                custom_data: {
                    transaction_id: md5(curentDate),
                },
            },
        };

        let headerData = {
            headers: {
                Apikey: "1KJGG956U3DSI84EL",
                Authorization:
                    "Bearer " +
                    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9hcHAiOiIxMTMwNSIsImlkX2Fib25uZSI6MzI1NDY1LCJkYXRlY3JlYXRpb25fYXBwIjoiMjAyMy0wOS0wNyAxMjoyMjo1OSJ9.mLgNMERt-p2Es-PYh8H0eiwpDkaqTZSJJIiElFVck8I",
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        this.testPaiementService
            .effectuerPaiement(structureData, headerData)
            .then((result) => {
                console.log(result);

                if (result && result.response_code === "00") {
                    window.location.replace(result.response_text);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des Ec à affecter"
                    onClick={() => {}}
                />
            </React.Fragment>
        );
    };

    render() {
        return (
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >
                    <div
                        className="p-grid p-lg-12"
                        style={{ marginTop: "3%" }}
                    ></div>
                    {this.state.loadingChargement ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="p-lg-6" style={{ marginTop: 10 }}>
                                <h4>Mes informations</h4>
                            </div>

                            <div className="row">
                                <div
                                    className="p-lg-6"
                                    style={{ marginTop: 10 }}
                                >
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Nom</th>
                                                <td>
                                                    {this.state.etudiant.nom}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Prénom</th>
                                                <td>
                                                    {this.state.etudiant.prenom}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    Date de naissance
                                                </th>
                                                <td>
                                                    {
                                                        this.state.etudiant
                                                            .dateNaissance
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Telephone</th>
                                                <td>
                                                    {
                                                        this.state.etudiant
                                                            .dateNaissance
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    className="p-lg-6"
                                    style={{ marginTop: 10 }}
                                >
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    Montant à payer
                                                </th>
                                                <td>{this.state.montant}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {this.state.readyToRedirect ? (
                                <Button
                                    type="button"
                                    icon="pi pi-chart"
                                    label="Preparer le paiement"
                                    className="p-button-warning"
                                    style={{ marginRight: ".5em" }}
                                    title="Bouton de paiement"
                                    onClick={() => this.payerFraisLigdicash()}
                                />
                            ) : (
                                <Button
                                    type="button"
                                    icon="pi pi-chart"
                                    label="Preparer le paiement"
                                    className="p-button-warning"
                                    style={{ marginRight: ".5em" }}
                                    title="Bouton de paiement"
                                    onClick={() => this.payerFraisLigdicash()}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default TestPaiement;
