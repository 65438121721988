import React, { Component, createRef } from 'react'
import Axios from 'axios'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import jwt_decode from "jwt-decode";
import MenuHome from '../home/MenuHome';
import { ToastContainer} from 'react-toastify';
import '../css/acceuil.css'
import '../css/style_nouveau.css'
import SlideShow from './SlideImage';
import Compte from './Compte';
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { UserService } from '../service/UserService';
import { Spinner } from './Spinner';
import { Notify } from './Notify';
import {AppTopbar} from '../AppTopbar';
import {AppBreadcrumb} from '../AppBreadcrumb';
import {AppFooter} from '../AppFooter';
import {AppConfig} from '../AppConfig';
import {AppMenu} from '../AppMenu';
import {AppInlineProfile} from '../AppInlineProfile';
import {withRouter} from 'react-router';
import MenuEtudiant from '../home/MenuEtudiant';
import MenuEnseignant from '../home/MenuEnseignant';
import { Dropdown } from 'primereact/dropdown';


export class Connexion extends Component {

    emptyUser = {
        idEtudiant : null,
        ine : "",
        matricule : "",
        nomEtudiant : "",
        prenomEtudiant : "",
        sexeEtudiant : "",
        idIesr : null,
        universite : "",
        filiere : "",
        niveau : "",
        villeOrientation : "",
        emailEtudiant : "",
        telEtudiant : "",
        anneeBac : null,
        serieBac : "",
        moyenneBac : null,
        dateNaissance : "",
        lieuNaissance : "",
        idPays : null,
        libellePays : "",
        typePays : "",
        personnelBesoin : "",
        contact : null,
        engagementStatutReglement : null,
        engagementPersonnelCaution : null,
        luApprouve : null
    }
    emptyClient = {
        idClient : null,
        nom : "",
        prenom : "",
        sexe : null,
        jourNaiss : "",
        moisNaiss : "",
        anneeNaiss : "",
        dateNaissance: "",
        lieuNaissance: "",
        numeroTelephone : "",
        adresseEmail : "",
        passeClient : "",
        ville : ""
    }

    constructor(props){
        super(props)

        this.state = {  
            keycloakState : null,
            authenticated : false,
            token : "",
            logout : "",
            bool : false,
            items : [],
            click : false,
            clickService : false,
            firstEnter : false,
            booleenMesServices : false,
            creerCompte : false,
            user : this.emptyUser,
            client : this.emptyClient,
            submitted : false,
            inscriptionDialog: false,
            inscriptionClientDialog: false,
            inscriptionEnsDialog : false,
            etudiantDialog : false,
            etudiantEnsDialog : false,
            isEtudiant : false,
            sexes : [{idSexe : 1, libelleSexe : "MASCULIN"},{idSexe : 2, libelleSexe : "FEMININ"}],
            jourNaisss : [], moisNaisss : [], anneeNaisss : []
        }
       this.spinner = new Spinner() 
       this.notify = new Notify()
       this.userService = new UserService()

    }
    componentDidMount = () => {
        const connectepasse = localStorage.getItem('connectepasse')
		let connecte = JSON.parse(connectepasse)
        const accessToken = localStorage.getItem('accessToken')
		const token = JSON.parse(accessToken)
        
        const keyBr = localStorage.getItem('keycloak')
		const key = JSON.parse(keyBr)

        let listeJours = []
        let listeMois = []
        let listeAnnee = []
        let today = new Date();
        let year = today.getFullYear();
        let j, k=""
        let l, m=""
        for(let i = 0; i < 31; i++){
            if(i<9) {
                j = i+1
                k = "0"+j
                listeJours.push({idJourNaiss : i+1, libelleJourNaiss : k })
            }
            else {
                l = i+1
                m = ""+l
                listeJours.push({idJourNaiss : i+1, libelleJourNaiss : m })
            }
            
        }

        for(let i = 0; i < 12; i++){
            if(i<9) {
                j = i+1
                k = "0"+j
                listeMois.push({idMoisNaiss : i+1, libelleMoisNaiss : k })
            }
            else {
                l = i+1
                m = ""+l
                listeMois.push({idMoisNaiss : i+1, libelleMoisNaiss : m })
            }

        }

        //console.log(listeMois)
        
        for(let i = 0; i < 100; i++){
            listeAnnee.push({idAnneeNaiss : year-14-i, libelleAnneeNaiss : year-14-i })
        }
        this.setState({jourNaisss : listeJours})
        this.setState({moisNaisss : listeMois})
        this.setState({anneeNaisss : listeAnnee})
        
        
        /*if(key){
            console.log(key)
            //connecte = false
            this.setState({keycloakState : key})
        }
        //else connecte = true
        if(token){
            this.setState({token : token})
        } */
        
        if(connecte){
            
            const keycloak = Keycloak({
                realm: "campusFaso",
                //url: "http://192.168.212.187:8180/auth/",
                //url: "http://192.168.210.187:8180/auth/",
                //url: "http://192.168.43.22:8180/auth/",
                url: "https://login.campusfaso.bf/auth/",
                resource: "campus-faso",
                clientId : "campus-faso",
                credentials: {
                    secret: "secret"
                },
              });
            
            keycloak.init({onLoad: 'login-required'}).then(authenticated => {
                this.setState({bool : true , keycloakState: keycloak, token : keycloak.token, authenticated: authenticated })
                //console.log(keycloak.token)
                localStorage.setItem('keycloak', JSON.stringify(keycloak))
                //localStorage.setItem('logout', JSON.stringify(keycloak.logout()))
                localStorage.setItem('accessToken', JSON.stringify(keycloak.token))
                localStorage.setItem('authenticated', JSON.stringify(authenticated))
                document.location.hash="#services" ;
                
              })
        } 
    }

    decodeKeycloak = () => {
        let decoded = jwt_decode(this.state.token);
        localStorage.setItem('tokenDecoded', JSON.stringify(decoded))
    }

    clickConnexion = () => {
        this.setState({click : true})
        localStorage.setItem('connectepasse', JSON.stringify(true))
    }

    clickDeconnexion = () => {
        localStorage.clear()
        console.log(this.props.keycloakCon)
        if(this.props.keycloakCon)
            this.props.keycloakCon.logout()
        
    }
    loadMesServices = () => {
        //sconsole.log(this.props.keycloakCon)
        this.setState({booleenMesServices : true, clickService: false, keycloakState : this.props.keycloakCon})
    }

    loadCreerCompte = () => {
        this.setState({creerCompte : true})
    }

    hideDialogInscription = () => {
        this.setState({
            submitted: false,
            inscriptionDialog: false
        });
    }
    hideDialogInscriptionEns = () => {
        this.setState({
            submitted: false,
            inscriptionEnsDialog: false
        });
    }
    hideDialogInscriptionClient = () => {
        this.setState({
            submitted: false,
            inscriptionClientDialog: false
        });
    }

    hideDialogEtudiant = () => {
        this.setState({
            submitted: false,
            etudiantDialog: false
        });
    }
    hideDialogEtudiantEns = () => {
        this.setState({
            submitted: false,
            etudiantEnsDialog: false
        });
    }

    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user
        user[`${field}`] = val
        console.log(val)
        this.setState({user})      
    }

    onValueChangeClient =(e, field) => {
        const val = (e.target && e.target.value) || '';
        let client = this.state.client
        client[`${field}`] = val
        if(field === "anneeNaiss"){
            
            if(this.state.client.jourNaiss && this.state.client.moisNaiss && val){
                const dateNaiss = this.state.client.jourNaiss.libelleJourNaiss+'-'+this.state.client.moisNaiss.libelleMoisNaiss+'-'+val.libelleAnneeNaiss
                client['dateNaissance'] = dateNaiss
            }
            else {
                alert("Veuillez bien renseigner la date de naissance !")
            }
        }

        this.setState({client})
    }

    openNewInscription = () => {
        this.setState({
            user: this.emptyUser,
            submitted: false,
            inscriptionDialog: true
        });
    } 
    openNewInscriptionClient = () => {
        this.setState({
            client: this.emptyClient,
            submitted: false,
            inscriptionClientDialog: true
        });
    }
    openNewInscriptionENS = () => {
        this.setState({
            user: this.emptyUser,
            submitted: false,
            inscriptionEnsDialog: true
        });
    } 
    openDetailEtudiant = () => {
        //aller chercher les infos de l'etudiant à faire ici
        this.setState({
            inscriptionDialog : false,
            //user: this.emptyUser,
            submitted: false,
            etudiantDialog: true
        });
    } 

    verifeUser = () => {
        //aller chercher les infos de l'etudiant à faire ici
        this.userService.getUser(this.state.user.ine).then(data => {
            //console.log(data)
            this.setState({
                inscriptionDialog : false,
                user: data,
                submitted: false,
                etudiantDialog: true
            });
        })
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
       
    }
    verifeUserEns = () => {
        //aller chercher les infos de l'etudiant à faire ici

        console.log(this.state.user.matricule)
        console.log(this.state.user)
        this.userService.getUserEns(this.state.user.matricule).then(data => {
            //console.log(data)
            this.setState({
                inscriptionEnsDialog : false,
                user: data,
                submitted: false,
                etudiantEnsDialog: true
            });
        })
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({inscriptionEnsDialog : false})
        })
       
    }

    creerCompte = () => {
        this.setState({submitted : true})
        this.userService.creerCompte(this.state.user).then(data => {
            this.notify.success()
            this.setState({etudiantDialog: false})
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            
        })
    }
    creerCompteEns = () => {
        this.setState({submitted : true})
        //console.log(this.state.user)
        this.userService.creerCompteEns(this.state.user).then(data => {
            this.notify.success()
            this.setState({etudiantEnsDialog: false})
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            this.setState({etudiantEnsDialog: false})
            console.log(error)
            
        })
    }
    inscriptionClient = () => {
        //console.log(this.state.client)
        const client = {
            idClient : this.state.client.idClient,
            nom : this.state.client.nom,
            prenom : this.state.client.prenom,
            sexe : this.state.client.sexe.libelleSexe,
            dateNaissance : this.state.client.dateNaissance,
            lieuNaissance : this.state.client.lieuNaissance,
            numeroTelephone : this.state.client.numeroTelephone,
            adresseEmail : this.state.client.adresseEmail,
            passeClient : this.state.client.passeClient,
            ville : this.state.client.ville

        }
        console.log(client)
        this.setState({submitted : true})
        if(client.nom, client.prenom, client.sexe, client.dateNaissance, client.lieuNaissance, client.adresseEmail, client.numeroTelephone, client.passeClient, client.ville){
            this.userService.creeCompteClient(client).then(data => {
                this.notify.success()
                this.setState({inscriptionClientDialog : false, submitted:false})
                console.log(data)
            })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }
        else {
            //alert("Veuillez remplir les champs obligatoire !")
        }
        //this.setState({submitted : false})
    }
	
	isIE() {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
	}

	replaceLink(linkElement, href) {
		if(this.isIE()){
			linkElement.setAttribute('href', href);
		}
		else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		}
	}
    openNav() {
        document.getElementById("mySidenav").style.width = "250px";
      }
      
      closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }
    
    render() {
        const layoutClassName = classNames('layout-wrapper', {
			'layout-horizontal': this.state.layoutMode === 'horizontal',
			'layout-overlay': this.state.layoutMode === 'overlay',
			'layout-static': this.state.layoutMode === 'static',
			'layout-slim': this.state.layoutMode === 'slim',
			'layout-static-inactive': this.state.staticMenuDesktopInactive,
			'layout-mobile-active': this.state.staticMenuMobileActive,
			'layout-overlay-active': this.state.overlayMenuActive,
			'layout-menu-dark': this.state.darkMenu,
			'layout-menu-light':!this.state.darkMenu
		});
		const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);
        const inscriptionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogInscription} />
                <Button label="S'inscrire" icon="pi pi-check" className="p-button-text" onClick={this.verifeUser} />
            </React.Fragment>
        );
        const inscriptionEnsDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogInscriptionEns} />
                <Button label="S'inscrire" icon="pi pi-check" className="p-button-text" onClick={this.verifeUserEns} />
            </React.Fragment>
        );
        const inscriptionClientDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogInscriptionClient} />
                <Button label="S'inscrire" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionClient} />
            </React.Fragment>
        );
        const etudiantDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogEtudiant} />
                <Button label="Créer compte" icon="pi pi-check" className="p-button-text" onClick={this.creerCompte} />
            </React.Fragment>
        );
        const etudiantEnsDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogEtudiantEns} />
                <Button label="Créer compte" icon="pi pi-check" className="p-button-text" onClick={this.creerCompteEns} />
            </React.Fragment>
        );
        if(this.state.click){
            this.componentDidMount()
            //this.componentDidUpdate()
        }
        if(this.state.bool){
            this.decodeKeycloak()
            const token = this.state.keycloakState.token
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        }
        else {
            //let refreshToken = this.state.keycloakState.refreshToken
            //Axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken
        }

        
        if(this.state.authenticated || this.state.booleenMesServices){
            const tokenDecoded = localStorage.getItem('tokenDecoded')
            const tokenD = JSON.parse(tokenDecoded)
            console.log(tokenD);
            let roles = null
            if(tokenD.realm_access.roles) roles = tokenD.realm_access.roles
            let campusMinistere = 0, campusPresident = 0, campusVpEip = 0, campusDaoi = 0, campusDdip = 0;
            let campusDirecteur = 0, campusDa = 0, campusScolarite = 0, campusPresidentJury = 0;
            let campusSecretaireJury = 0, campusMemebreJury = 0, campusChefDepartement = 0;
            let campusEnseignant = 0, campusDepartement = 0, campusDGESup = 0, campusEtudiant = 0;
    
            const profiles = roles.filter(role => {
               
                if(role === "campus-president") {
                    campusPresident = 1
                    localStorage.setItem('campusPresident', JSON.stringify(campusPresident))
                }   
                else if(role === "campus-vp-eip"){
                    campusVpEip = 1
                    localStorage.setItem('campusVpEip', JSON.stringify(campusVpEip))
                }
                else if(role === "campus-daoi"){
                    campusDaoi = 1
                    localStorage.setItem('campusDaoi', JSON.stringify(campusDaoi))
                } 
                else if(role === "campus-ddip"){
                    campusDdip = 1
                    localStorage.setItem('campusDdip', JSON.stringify(campusDdip))
                } 
                else if(role === "campus-directeur"){
                    campusDirecteur = 1
                    localStorage.setItem('campusDirecteur', JSON.stringify(campusDirecteur))
                }  
                else if(role === "campus-da"){
                    campusDa = 1
                    localStorage.setItem('campusDa', JSON.stringify(campusDa))
                } 
                else if(role === "campus-scolarite"){
                    campusScolarite = 1
                    localStorage.setItem('campusScolarite', JSON.stringify(campusScolarite))
                } 
                else if(role === "campus-president-jury"){
                    campusPresidentJury = 1
                    localStorage.setItem('campusPresidentJury', JSON.stringify(campusPresidentJury))
                } 
                else if(role === "campus-secretaire-jury"){
                    campusSecretaireJury = 1
                    localStorage.setItem('campusSecretaireJury', JSON.stringify(campusSecretaireJury))
                } 
                else if(role === "campus-membre-jury"){
                    campusMemebreJury = 1
                    localStorage.setItem('campusMemebreJury', JSON.stringify(campusMemebreJury))
                }
                else if(role === "campus-chef-departement"){
                    campusChefDepartement = 1
                    localStorage.setItem('campusChefDepartement', JSON.stringify(campusChefDepartement))
                }
                else if(role === "campus-admin-departement"){
                    campusDepartement = 1
                    localStorage.setItem('campusDepartement', JSON.stringify(campusDepartement))
                }
                else if(role === "campus-admin"){
                    campusDGESup = 1
                    localStorage.setItem('campusDGESup', JSON.stringify(campusDGESup))
                }
                else if(role === "campus-enseignant"){
                    campusEnseignant = 1
                    localStorage.setItem('campusEnseignant', JSON.stringify(campusEnseignant))
                }
                else if(role === "campus-etudiant"){
                    campusEtudiant = 1
                    localStorage.setItem('campusEtudiant', JSON.stringify(campusEtudiant))
                }
                else if(role === "campus-ministere"){
                    campusMinistere = 1
                    localStorage.setItem('campusMinistere', JSON.stringify(campusMinistere))
                }
                else {
                    
                }
                return role
            })
            
            return (
                <div>
                    <MenuHome clickService = {this.state.clickService} keycloak = {this.state.keycloakState} /> 
                </div> 
            )
            
            /*if(campusEtudiant){
                return (
                    <div>
                    { <MenuEtudiant keycloak = {this.state.keycloakState} /> }
                    </div>
                )
            }
            else if(campusEnseignant){
                return(
                    <div>
                        {<MenuEnseignant keycloak = {this.state.keycloakState}/>}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        { <MenuHome clickService = {this.state.clickService} keycloak = {this.state.keycloakState} /> }
                    </div> 
                )
            }*/
             
            
        }
        else {
           
            return (
                
                            <div className="bodys">
                                <ToastContainer
                                    position="top-right"
                                    autoClose={2000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                                <ToastContainer />
                                <Dialog visible={this.state.inscriptionDialog}  header="Création de compte" modal className="p-fluid" footer={inscriptionDialogFooter} onHide={this.hideDialogInscription}>
                                    <div className="p-grid">
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="libelleUniteEnseignement">Entrer votre INE</label>
                                                <InputText value={this.state.user.ine} onChange={(e) => {this.onValueChange(e, 'ine')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.user.ine && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                                <Dialog visible={this.state.inscriptionEnsDialog}  header="Création de compte ENS" modal className="p-fluid" footer={inscriptionEnsDialogFooter} onHide={this.hideDialogInscriptionEns}>
                                    <div className="p-grid">
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="libelleUniteEnseignement">Entrer votre matricule</label>
                                                <InputText value={this.state.user.matricule} onChange={(e) => {this.onValueChange(e, 'matricule')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.user.matricule && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                                <Dialog visible={this.state.inscriptionClientDialog} header="Création de compte" modal className="p-fluid" footer={inscriptionClientDialogFooter} onHide={this.hideDialogInscriptionClient}>
                                    <div className='p-form-grid'> 
                                        <div className='p-grid'>
                                            <div className="p-field p-col-6 ">
                                                <label htmlFor="nom">Nom</label>
                                                <InputText value={this.state.client.nom} onChange={(e) => {this.onValueChangeClient(e, 'nom')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.nom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="prenom">Prénom</label>
                                                <InputText value={this.state.client.prenom} onChange={(e) => {this.onValueChangeClient(e, 'prenom')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="sexe">Sexe</label>
                                                {<Dropdown value={this.state.client.sexe} onChange={(e) => {this.onValueChangeClient(e, 'sexe')}} options={this.state.sexes} optionLabel="libelleSexe" />}
                                                {/*<InputText value={this.state.client.sexe} onChange={(e) => {this.onValueChangeClient(e, 'sexe')}} required autoFocus className={className({ 'p-invalid': this.state.submitted })} />*/}
                                                {this.state.submitted && !this.state.client.sexe && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="nationalite">Nationalité</label>
                                                <InputText value={this.state.client.nationalite} onChange={(e) => {this.onValueChangeClient(e, 'nationalite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.nationalite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field p-col-12">
                                                <label htmlFor="dateNaiss">Date de naissance</label>
                                                {<div className='p-grid'>
                                                    <div className='p-col-4'>
                                                        <Dropdown value={this.state.client.jourNaiss} onChange={(e) => {this.onValueChangeClient(e, 'jourNaiss')}} options={this.state.jourNaisss} optionLabel="libelleJourNaiss" placeholder='Jour' />
                                                        {this.state.submitted && !this.state.client.jourNaiss && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                                    </div>
                                                    <div className='p-col-4'>
                                                        <Dropdown value={this.state.client.moisNaiss} onChange={(e) => {this.onValueChangeClient(e, 'moisNaiss')}} options={this.state.moisNaisss} optionLabel="libelleMoisNaiss"  placeholder='Mois' />
                                                        {this.state.submitted && !this.state.client.moisNaiss && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                                    </div>
                                                    <div className='p-col-4'>
                                                        <Dropdown value={this.state.client.anneeNaiss} onChange={(e) => {this.onValueChangeClient(e, 'anneeNaiss')}} options={this.state.anneeNaisss} optionLabel="libelleAnneeNaiss" placeholder='Année' />
                                                        {this.state.submitted && !this.state.client.anneeNaiss && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                                    </div>
                                                    
                                                </div>}
                                                
                                                {/*<InputText type='text' value={this.state.client.dateNaissance} onChange={(e) => {this.onValueChangeClient(e, 'dateNaissance')}} required autoFocus className={className({ 'p-invalid': this.state.submitted })} />*/}
                                                {/*<Calendar value={this.state.client.dateNaissance} onChange={(e) => {this.onValueChangeClient(e, 'dateNaissance')}} dateFormat="dd/mm/yy" />*/}
                                                {/*this.state.submitted && !this.state.client.dateNaissance && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>*/}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="lieuNaissance">Lieu de naissance</label>
                                                <InputText value={this.state.client.lieuNaissance} onChange={(e) => {this.onValueChangeClient(e, 'lieuNaissance')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.lieuNaissance && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="ville">Ville actuelle</label>
                                                <InputText value={this.state.client.ville} onChange={(e) => {this.onValueChangeClient(e, 'ville')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.ville && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="telClient">Téléphone</label>
                                                <InputText value={this.state.client.numeroTelephone} onChange={(e) => {this.onValueChangeClient(e, 'numeroTelephone')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.numeroTelephone && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="email">Email</label>
                                                <InputText value={this.state.client.adresseEmail} onChange={(e) => {this.onValueChangeClient(e, 'adresseEmail')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.adresseEmail && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="identifiant">Identifiant</label>
                                                <InputText readOnly value={this.state.client.adresseEmail} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.adresseEmail && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field p-col-6">
                                                <label htmlFor="password">Mot de passe</label>
                                                <InputText type="password" value={this.state.client.passeClient} onChange={(e) => {this.onValueChangeClient(e, 'passeClient')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.passeClient && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                    </div>
                                        
                                </Dialog>

                                <Dialog visible={this.state.etudiantDialog} header="Création de compte" modal className="p-fluid" footer={etudiantDialogFooter} onHide={this.hideDialogEtudiant}>
                                    <div className="p-grid">
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="ine">Votre INE</label>
                                                <InputText value={this.state.user.ine}  required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="nom">Nom</label>
                                                <InputText value={this.state.user.nomEtudiant}  required autoFocus  />  
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="prenom">Prénom</label>
                                                <InputText value={this.state.user.prenomEtudiant}  required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="sexe">Sexe</label>
                                                <InputText value={this.state.user.sexeEtudiant}  required autoFocus  /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="dateNaiss">Date de naissance</label>
                                                <InputText value={this.state.user.dateNaissance}  required autoFocus  /> 
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="lieuNaiss">Lieu de naissance</label>
                                                <InputText value={this.state.user.lieuNaissance}  required autoFocus  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="email">Email <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText value={this.state.user.emailEtudiant} onChange={(e) => {this.onValueChange(e, 'emailEtudiant')}}  required autoFocus  />
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="tel">Téléphone <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText value={this.state.user.telEtudiant} onChange={(e) => {this.onValueChange(e, 'telEtudiant')}}   required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="université">Université</label>
                                                <InputText value={this.state.user.universite}  required autoFocus />  
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="filiere">Filière</label>
                                                <InputText value={this.state.user.filiere}  required autoFocus />
                                            </div>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="p-grid">
                                        
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="sexe">Niveau</label>
                                                <InputText value={this.state.user.niveau}  required autoFocus  /> 
                                            </div>
                                        </div>
                                    </div>
                                    */}
                                </Dialog>
                                <Dialog visible={this.state.etudiantEnsDialog} header="Création de compte ENS" modal className="p-fluid" footer={etudiantEnsDialogFooter} onHide={this.hideDialogEtudiantEns}>
                                    <div className="p-grid">
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="ine">Votre Matricule</label>
                                                <InputText value={this.state.user.ine}  required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="nom">Nom</label>
                                                <InputText value={this.state.user.nom}  required autoFocus  />  
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="prenom">Prénom</label>
                                                <InputText value={this.state.user.prenom}  required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="email">Email <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText defaultValue={this.state.user.email} onChange={(e) => {this.onValueChange(e, 'emailEtudiant')}}  required autoFocus  />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="p-grid">
                                        
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="dateNaiss">Date de naissance</label>
                                                <InputText value={this.state.user.dateNaiss}  required autoFocus  /> 
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="lieuNaiss">Lieu de naissance</label>
                                                <InputText value={this.state.user.lieuNaissance}  required autoFocus  />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="email">Email <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText value={this.state.user.email} onChange={(e) => {this.onValueChange(e, 'emailEtudiant')}}  required autoFocus  />
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="tel">Téléphone <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText value={this.state.user.telEtudiant} onChange={(e) => {this.onValueChange(e, 'telEtudiant')}}   required autoFocus  />  
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="université">Université</label>
                                                <InputText value={this.state.user.universite}  required autoFocus />  
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="filiere">Filière</label>
                                                <InputText value={this.state.user.filiere}  required autoFocus />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="p-grid">
                                        
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="sexe">Niveau</label>
                                                <InputText value={this.state.user.niveau}  required autoFocus  /> 
                                            </div>
                                        </div>
                                    </div> */}
                                </Dialog>
                                

    {!this.state.token ?                      
    <div className="app ">
        <div className="app-inner">
            <header className="header">
                <div className="header-desktop">
                    <div className="header-top">
                        <ul className="nav">
                            <li><a className='navLink' href="https://www.campusfaso.bf/">Orientations</a></li>
                            <li><a className='navLink' href="https://ordinateur.campusfaso.bf/">1Etudiant1Ordianteur</a></li>
                            {/*<li> <a className='navLink' href="#" onClick={this.clickConnexion}>Connexion</a></li>*/}
                        </ul>
                    </div>
                    <div className="header-main">
                        <a href="#" className="header-logo">
                            {/*<img className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>*/}
                            <svg id="Calque_1" className="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 465.68 136.14"><title>logo_</title>
                                <path className="cls-1" d="M132.44,117.29a17.74,17.74,0,0,1-6.87-1.32,15.66,15.66,0,0,1-5.52-3.87,18.49,18.49,0,0,1-3.7-6.26A24.91,24.91,0,0,1,115,97.31a23.26,23.26,0,0,1,1.46-8.52,19,19,0,0,1,3.9-6.26A15.83,15.83,0,0,1,126,78.69a17.74,17.74,0,0,1,6.68-1.3,26,26,0,0,1,3.92.28,20.45,20.45,0,0,1,3.09.69,19.42,19.42,0,0,1,2.26.83,9.28,9.28,0,0,1,1.44.74l-1.22,3.26a21.56,21.56,0,0,0-3.5-1.63,17.22,17.22,0,0,0-11.53.41,12.31,12.31,0,0,0-4.5,3.42,14.66,14.66,0,0,0-2.73,5.25,23.26,23.26,0,0,0-.91,6.73,24,24,0,0,0,.91,6.87,14.64,14.64,0,0,0,2.67,5.22,11.92,11.92,0,0,0,4.34,3.31,14.14,14.14,0,0,0,5.87,1.16,22.93,22.93,0,0,0,6.1-.69,17.17,17.17,0,0,0,3.72-1.41l1,3.2a6.57,6.57,0,0,1-1.32.63q-.94.36-2.37.75a24.76,24.76,0,0,1-3.31.63A31.52,31.52,0,0,1,132.44,117.29Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-1" d="M177.14,116.46c-.73-1.87-1.4-3.68-2-5.41s-1.19-3.43-1.75-5.07H154.68c-.63,1.65-1.26,3.34-1.87,5.08s-1.26,3.54-1.94,5.4H147q2.19-6,4.12-11.13c1.29-3.43,2.56-6.69,3.8-9.75s2.48-6,3.7-8.85,2.51-5.66,3.86-8.51h3.31q2,4.25,3.84,8.5t3.68,8.85q1.88,4.61,3.79,9.76t4.23,11.13ZM164,82.53c-1.39,3.17-2.73,6.4-4,9.67s-2.66,6.79-4.07,10.58h16.33q-2.13-5.69-4.13-10.6T164,82.53Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-1" d="M204,111c-.37-.92-.9-2.14-1.58-3.65s-1.45-3.15-2.3-4.94-1.72-3.62-2.61-5.51-1.73-3.67-2.55-5.33-1.53-3.11-2.16-4.36-1.09-2.13-1.38-2.65q-.72,6.9-1.13,15t-.75,17h-3.7q.49-10.42,1.13-20t1.58-18.27h3.31c1.1,1.84,2.28,4,3.54,6.37s2.49,4.92,3.73,7.51,2.43,5.15,3.59,7.67,2.19,4.79,3.07,6.82q1.33-3,3.07-6.82t3.6-7.67c1.23-2.59,2.47-5.1,3.73-7.51s2.43-4.53,3.54-6.37h3.14q.94,8.72,1.58,18.27t1.13,20h-3.81q-.33-8.88-.75-17t-1.13-15c-.29.52-.75,1.4-1.38,2.65s-1.34,2.7-2.15,4.36-1.66,3.43-2.54,5.33-1.75,3.73-2.59,5.51-1.61,3.44-2.29,4.94-1.21,2.73-1.57,3.65Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-1" d="M244.13,77.89q8.12,0,12.09,3.09a10.46,10.46,0,0,1,4,8.77A12.08,12.08,0,0,1,259,95.3a9.37,9.37,0,0,1-3.31,3.7,14.88,14.88,0,0,1-5.27,2,35.65,35.65,0,0,1-7,.63h-5.19v14.79h-3.75V78.94a25.94,25.94,0,0,1,4.66-.8C240.92,78,242.59,77.89,244.13,77.89Zm.17,3.26c-1.4,0-2.59,0-3.56.13l-2.51.25V98.42H243a38.17,38.17,0,0,0,5.5-.36,12.37,12.37,0,0,0,4.19-1.32,6.77,6.77,0,0,0,2.68-2.68,9.09,9.09,0,0,0,.93-4.36,7.86,7.86,0,0,0-1-4.19,7.56,7.56,0,0,0-2.7-2.63,12,12,0,0,0-3.84-1.35A24.61,24.61,0,0,0,244.3,81.15Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-1" d="M280.94,117.29a16.11,16.11,0,0,1-6.48-1.16,11.08,11.08,0,0,1-4.33-3.2,12.8,12.8,0,0,1-2.41-4.77,22.55,22.55,0,0,1-.74-5.88V78.22h3.75v23.56a17.57,17.57,0,0,0,.75,5.44,10.24,10.24,0,0,0,2.07,3.78,8.12,8.12,0,0,0,3.2,2.21,12.58,12.58,0,0,0,8.39,0,8.19,8.19,0,0,0,3.2-2.21,10.53,10.53,0,0,0,2.07-3.78,17.89,17.89,0,0,0,.74-5.44V78.22h3.75v24.06a22.55,22.55,0,0,1-.74,5.88,12.93,12.93,0,0,1-2.4,4.77,11.23,11.23,0,0,1-4.33,3.2A16.2,16.2,0,0,1,280.94,117.29Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-1" d="M312.89,114a12.61,12.61,0,0,0,7-1.64q2.47-1.62,2.48-5.28a7.06,7.06,0,0,0-.8-3.51,7.54,7.54,0,0,0-2.12-2.38,14.41,14.41,0,0,0-3-1.65c-1.14-.46-2.34-.94-3.59-1.46a44.43,44.43,0,0,1-4.25-1.91,15.08,15.08,0,0,1-3.2-2.23,8.51,8.51,0,0,1-2-2.85,9.37,9.37,0,0,1-.69-3.75c0-3.27,1.08-5.76,3.25-7.45s5.19-2.54,9.06-2.54a21.72,21.72,0,0,1,3,.22,24.06,24.06,0,0,1,2.79.58,22.62,22.62,0,0,1,2.37.8,9.84,9.84,0,0,1,1.79.94l-1.32,3.15a13.31,13.31,0,0,0-3.89-1.77,17.77,17.77,0,0,0-4.94-.66,14.49,14.49,0,0,0-3.34.36,7.2,7.2,0,0,0-2.62,1.16,5.62,5.62,0,0,0-1.74,2,6.31,6.31,0,0,0-.63,2.93,6.19,6.19,0,0,0,.66,3A6.44,6.44,0,0,0,309,92.18a14.39,14.39,0,0,0,2.78,1.6c1.07.48,2.23,1,3.48,1.49,1.51.63,2.93,1.25,4.28,1.88a15.37,15.37,0,0,1,3.5,2.21,9.06,9.06,0,0,1,2.37,3.09,10.14,10.14,0,0,1,.89,4.47q0,5.19-3.62,7.78t-9.79,2.59a27.59,27.59,0,0,1-4-.27,28.41,28.41,0,0,1-3.26-.69,18.87,18.87,0,0,1-2.42-.86,13.88,13.88,0,0,1-1.55-.77l1.21-3.26c.37.22.87.48,1.49.78a21.09,21.09,0,0,0,2.21.85,19.52,19.52,0,0,0,2.87.69A21.32,21.32,0,0,0,312.89,114Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-2" d="M332.65,116.46V78.22h25.61v7.23h-17v8h15.12v7.23H341.26v15.78Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-2" d="M387.33,116.46c-.42-1.32-.88-2.69-1.37-4.09l-1.46-4.18H369.56c-.48,1.39-1,2.79-1.45,4.19s-.94,2.76-1.36,4.08h-8.94q2.16-6.18,4.1-11.42t3.79-9.88q1.86-4.64,3.65-8.8t3.74-8.14h8.16q1.89,4,3.72,8.14t3.68,8.8q1.86,4.64,3.8,9.88t4.1,11.42ZM377,86.88c-.19.56-.47,1.32-.84,2.27l-1.27,3.32c-.47,1.25-1,2.63-1.58,4.13s-1.18,3.09-1.78,4.74h10.94l-1.7-4.75c-.55-1.51-1.07-2.89-1.57-4.15s-.93-2.35-1.3-3.31S377.22,87.43,377,86.88Z" transform="translate(-5.38 -11.25)"/><path className="cls-2" d="M412.94,109.9a12,12,0,0,0,3-.31,5.41,5.41,0,0,0,1.9-.82,2.83,2.83,0,0,0,1-1.25,4.47,4.47,0,0,0,.27-1.6,3.69,3.69,0,0,0-1.76-3.11,25.3,25.3,0,0,0-6.07-2.68c-1.25-.44-2.51-.95-3.76-1.52a13.87,13.87,0,0,1-3.36-2.15,10.14,10.14,0,0,1-2.43-3.15,9.77,9.77,0,0,1-.94-4.49,11.05,11.05,0,0,1,1-4.78,10.21,10.21,0,0,1,2.81-3.61A12.64,12.64,0,0,1,409,78.14a19.94,19.94,0,0,1,5.85-.8A23.48,23.48,0,0,1,426.19,80l-2.49,6.78a24,24,0,0,0-3.56-1.46,15.43,15.43,0,0,0-4.71-.63,8.7,8.7,0,0,0-4.45.85,2.89,2.89,0,0,0-1.35,2.62,3.05,3.05,0,0,0,.5,1.77,4.82,4.82,0,0,0,1.41,1.3,13.13,13.13,0,0,0,2.09,1c.79.31,1.67.63,2.62,1,2,.74,3.72,1.46,5.19,2.18a14,14,0,0,1,3.67,2.51,9.08,9.08,0,0,1,2.18,3.37A13.48,13.48,0,0,1,428,106a9.94,9.94,0,0,1-3.75,8.3q-3.76,3-11.32,3a31.62,31.62,0,0,1-4.58-.31,30,30,0,0,1-3.61-.74,22.15,22.15,0,0,1-2.71-.94,18.72,18.72,0,0,1-1.9-.94l2.43-6.84a21,21,0,0,0,4.22,1.68A21.71,21.71,0,0,0,412.94,109.9Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-2" d="M471.05,97.31a23.62,23.62,0,0,1-1.46,8.64,18,18,0,0,1-4,6.24,16.55,16.55,0,0,1-6,3.78,22.14,22.14,0,0,1-7.54,1.27,21.53,21.53,0,0,1-7.39-1.27,17.1,17.1,0,0,1-6.07-3.78,17.87,17.87,0,0,1-4.09-6.24A23.2,23.2,0,0,1,433,97.31a22.38,22.38,0,0,1,1.55-8.63,18.51,18.51,0,0,1,4.17-6.27,17,17,0,0,1,6.07-3.8A20.67,20.67,0,0,1,452,77.34a21.35,21.35,0,0,1,7.4,1.27,16.73,16.73,0,0,1,6.07,3.8,18,18,0,0,1,4.08,6.27A23.18,23.18,0,0,1,471.05,97.31Zm-29.19,0a17.39,17.39,0,0,0,.69,5.08,11.44,11.44,0,0,0,2,3.89,8.61,8.61,0,0,0,3.17,2.51,10.78,10.78,0,0,0,8.58,0,8.55,8.55,0,0,0,3.2-2.51,11.62,11.62,0,0,0,2-3.89,17.39,17.39,0,0,0,.69-5.08,17.67,17.67,0,0,0-.69-5.1,11.68,11.68,0,0,0-2-3.92,8.75,8.75,0,0,0-3.2-2.51A10,10,0,0,0,452,84.9a9.67,9.67,0,0,0-4.3.91,9,9,0,0,0-3.17,2.54,11.41,11.41,0,0,0-2,3.92A17.4,17.4,0,0,0,441.86,97.31Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-3" d="M5.38,59.17c57.72,31.52,71.48,46.42,71.48,46.42s30.81-40.4,77.72-73.65c0,0-64.29,51.65-73.37,115.45C56.56,89.66,5.38,59.17,5.38,59.17Z" transform="translate(-5.38 -11.25)"/>
                                <path className="cls-3" d="M86.54,82.35A13.07,13.07,0,1,1,73.47,69.28,13.07,13.07,0,0,1,86.54,82.35Z" transform="translate(-5.38 -11.25)"/>
                                <polygon className="cls-3" points="67.89 65.9 38.69 64.86 63.95 50.18 93.14 51.22 67.89 65.9"/>
                                <polygon className="cls-4" points="163.94 34.49 152.81 26.29 141.58 34.34 145.93 21.23 134.8 13.03 148.63 13.12 152.98 0 157.17 13.17 170.99 13.26 159.76 21.32 163.94 34.49"/>
                            </svg>
                        </a>
                        <div className="header-main-menu">
                            {<ul className="nav">
                                
                                <li><a className='navLink' onClick={this.openNewInscription} href="#">Création de compte étudiant</a></li>
                                <li><a className='navLink' onClick={this.openNewInscriptionENS} href="#">Création de compte ENS</a></li>
                                <li><a className='navLink' onClick={this.openNewInscriptionClient} href="#">Auto-Enseigner/Equiv-diplôme</a></li>
                                <li><a className='navLink' href="#" onClick={this.clickConnexion} >Connexion</a></li>
                               
                            </ul>}
                           
                        </div>
                          
                    </div>
                </div>
                
                <div className="header-mobile">
                      {<button className="layout-menu-button p-link"  onClick={this.openNav}>
                        <svg id="menu-open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>  
				      </button>}  
                      <div className='sidenav' id="mySidenav">
                        <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
                        <ul className="nav">  
                            <li><a className='navLink' href="#" onClick={this.clickConnexion} >Connexion</a></li>    
                            <li><a className='navLink' onClick={this.openNewInscription} href="#">Création de compte étudiant</a></li>
                            <li><a className='navLink' onClick={this.openNewInscriptionENS} href="#">Création de compte ENS</a></li>
                            <li><a className='navLink' onClick={this.openNewInscriptionClient} href="#">Auto-Enseigner/Equiv-diplôme</a></li>      
                        </ul>
                      </div>           
                </div>
            </header>
            <main className="main">
                {/*<AppBreadCrumbWithRouter/>*/}
                <div className="hero">
                    <div className="hero-inner">
                        <div className="hero-text">
                            <div className="hero-text-inner">
                                <div className="hero-accroche">
                                    <span>#Choisir</span> 
                                    <br/>
                                    sa formation sur CampusFaso
                                </div>
                                <div className="hero-desc">
                                    Bacheliers du Burkina Faso et de l'extérieur, 
                                    CampusFaso vous offre la possibilité de postuler aux différentes formations dans les universités publiques et privées du pays
                                </div>
                                <div className="hero-button">
                                    <a href="#" onClick={this.openNewInscription} className="btn btn-pri">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                            <path d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>
                                        Ouvrir un compte étudiant
                                    </a>
                                    <a href="#" className="btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                            <path d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                        </svg> 
                                        Trouver une formation
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section" >
                    <div className="section-inner">
                        <div className="section-title">
                            <img src="/assets/layout/images/cf_bullet.svg" className="title-bullet"/>
                            Communiqués
                        </div>
                        <div className="news">
                            <div className="news-item">
                                <a>
                                    Ouverture de la session de rattrapage d'orientation et d'inscription 2022 des nouveaux bacheliers dans les institutions d'enseignement du Burkina Faso. Les dossiers sont recevables via « Campusfaso » du 5 au 15 septembre 2022.
                                </a>
                            </div>
                            <div className="news-item">
                                <a>
                                    Ouverture de la session de rattrapage d'orientation et d'inscription 2022 des nouveaux bacheliers dans les institutions d'enseignement du Burkina Faso. Les dossiers sont recevables via « Campusfaso » du 5 au 15 septembre 2022.
                                </a>
                            </div>
                            <div className="news-item">
                                <a>
                                    Ouverture de la session de rattrapage d'orientation et d'inscription 2022 des nouveaux bacheliers dans les institutions d'enseignement du Burkina Faso. Les dossiers sont recevables via « Campusfaso » du 5 au 15 septembre 2022.
                                </a>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="section help" style={{"backgroundColor" : "#575C5C", "color" : "white"}}>
                    <div className="section-inner">
                        <div className="section-title">
                            <img src="/assets/layout/images/cf_bullet.svg" className="title-bullet"/>
                            Besoin d'aide ?
                        </div>
                        <div className="section-help">
                            <div className="section-help-item">
                                <img src="/assets/layout/images/help.svg" className="help-icon"/>
                                <div className="help-text">
                                    <div className="help-text-title">Nous contacter</div>
                                    <div className="help-text-content">
                                        <div className="mb10">
                                            Par téléphone tous les jours ouvrables de 8h à 18h <br/>
                                            <a href="tel:07767676">(+226) 07 76 76 76 </a> / <a href="tel:52366971">52 36 69 71</a> / <a href="tel:58505655">58 50 56 55</a>
                                        </div>
                                        <div className="mb10">
                                            Par mail : <br/>
                                            <a href="mailto:orientation@campusfaso.bf">orientation@campusfaso.bf</a>
                                        </div>
                                        <div className="mb10">
                                            sur les réseaux sociaux : <br/>
                                            <ul className="socials">
                                                <li><a href="" title="Facebook"><i className="fa-brands fa-square-facebook"></i></a></li>
                                                <li><a href="" title="Twitter"><i className="fa-brands fa-square-twitter"></i></a></li>
                                                <li><a href="" title="Instagram"><i className="fa-brands fa-square-instagram"></i></a></li>
                                                <li><a href="" title="Youtube"><i className="fa-brands fa-square-youtube"></i></a></li>
                                                <li><a href="" title="TikTok"><i className="fa-brands fa-tiktok"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-help-item">
                                <img src="/assets/layout/images/info.svg" className="help-icon"/>
                                <div className="help-text">
                                    <div className="help-text-title">Le guide CampusFaso</div>
                                    <div className="help-text-content">
                                        <p>
                                            Vous souhaitez en savoir plus sur la plateforme CampusFaso et ses services ? <br/>
                                            Notre guide répondra à la plupart des questions que vous vous posez.
                                        </p>
                                        <br/>
                                        <a href="">Suivre le guide <i className="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section video" style={{"backgroundColor": "#fff5c5"}}>
                    <div className="section-inner">
                        <div className="section-title">
                            <img src="assets/layout/images/cf_bullet.svg" className="title-bullet"/>
                            Comment débuter ?
                        </div>
                        <div className="section-video">
                            <div className="section-video-content">
                                <div className="section-video-title">Tutoriel d'utilsation CampusFaso</div>
                                <div>
                                    Comment utiliser la plateforme d’orientation dans les établissements
publics et privés. Cette vidéo vous détaille toutes les étapes à suivre pour s’inscrire, 
faire ses choix d’orientation, visualiser ses résultats et payer ses frais d’inscription.
                                </div>
                            </div>
                            <div className="section-video-player mb10">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/rZS0hkAhizM" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="section find">
                    <div className="section-inner">
                        <div className="section-find">
                            <div className="section-find-title">
                                Trouver la formation qui vous convient le mieux
                            </div>
                            <a href="" className="btn btn-pri"> <i className="fa fa-search"></i>&nbsp;Trouver ma formation</a>
                        </div>
                    </div>
                </div>
            </main>
            <footer>
                <div className="footer-menu">
                    <div className="ft-menu-right">
                        <ul>
                            <li><a href="">Mentions légales</a></li>
                            <li><a href="">Engagement confidentialité</a></li>
                            <li><a href="">Protection des données</a></li>
                        </ul>
                    </div>
                    <div className="ft-menu-social">
                        <ul className="socials">
                            <li><a href="" title="Facebook"><i className="fa-brands fa-square-facebook"></i></a></li>
                            <li><a href="" title="Twitter"><i className="fa-brands fa-square-twitter"></i></a></li>
                            <li><a href="" title="Instagram"><i className="fa-brands fa-square-instagram"></i></a></li>
                            <li><a href="" title="Youtube"><i className="fa-brands fa-square-youtube"></i></a></li>
                            <li><a href="" title="TikTok"><i className="fa-brands fa-tiktok"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-copy">
                    <div>
                        &copy; 2018 - 2022 - Gouverment du Burkina Faso - CampusFaso. Tous droits réservés.
                    </div>
                </div>
            </footer>
        </div> 
    </div> 
    :
    <div className="app">
        <div className="app-inner">
            <header className="header">
                <div className="logo-mobile">
                    <a href="" className="header-logo"> 
                        <img style={{width : 200, height:45}}  className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                    </a> 
                </div>
                <div className="header-desktop">
                    <div className="header-top">
                        <ul className="nav">
                            <li><a href="http://hebergement2.campusfaso.bf/">Orientations</a></li>
                            <li><a href="http://hebergement2.campusfaso.bf/" target="_blank">Hébergement</a></li>
                            <li><a href="http://ordinateur.campusfaso.bf/" target="_blank">1Etudiant1Ordinateur</a></li>
                            <li onClick={this.clickDeconnexion}><div className="nav-link" >Deconnexion</div></li>
                        </ul>
                    </div>
                    <div className="header-main">
                        <a href="" className="header-logo"> 
                            <img style={{width : 200, height:45}}  className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                        </a> 
                        <div className="header-main-menu">
                            <ul className="nav">
                                <li><a href="">Accueil</a></li>
                                <li><a href="">Candidatures</a></li>
                                <li><a href="">Communiqués</a></li>
                                <li><a href="">Trouver une filière</a></li>
                                <li><a href="">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-space"></div>
                <div className="header-mobile">
                    <svg id="menu-open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                      </svg>                      
                </div>
            </header>
            <div className="main">
                <div className="flash"></div> 
               
            </div> 
            <footer>
                <div className="">
                    <div className="ft-menu-right">
                        <ul>
                            <li><a href="">Mentions légales</a></li>
                            <li><a href="">Engagement confidentialité</a></li>
                            <li><a href="">Protection des données</a></li>
                        </ul>
                    </div>
                    <div className="ft-menu-social">
                        <ul className="socials">
                            <li><a href="" title="Facebook"><i className="fa-brands fa-square-facebook"></i></a></li>
                            <li><a href="" title="Twitter"><i className="fa-brands fa-square-twitter"></i></a></li>
                            <li><a href="" title="Instagram"><i className="fa-brands fa-square-instagram"></i></a></li>
                            <li><a href="" title="Youtube"><i className="fa-brands fa-square-youtube"></i></a></li>
                            <li><a href="" title="TikTok"><i className="fa-brands fa-tiktok"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-copy">
                    <div>
                        &copy; 2018 - 2024 - Gouvernement du Burkina Faso - CampusFaso. Tous droits réservés.
                    </div>
                </div>
            </footer>
        </div> 
    </div> 
    
    }
                       
</div>

            )
        }
        
    }
}
export default Connexion
