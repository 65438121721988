import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';


export class FacturationService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd"
       return axios.get(url).then(res => res.data)
   }
   getPeriodePaiement(idEnseignant, idIesr) {
    const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infos-facturations-enseignant?idIesr="+idIesr+"&idEnseignant="+idEnseignant
    return axios.get(url)
                .then(res => res.data)
    }
    payerFacture(facture) {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/facturations"
        return axios.post(url,facture)
                    .then(res => res.data);
    }
    getPaiement(idEnseignant, idIesr){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/details-facturations?idIesr="+idIesr+"&idEnseignant="+idEnseignant
        return axios.get(url)
                .then(res => res.data)
    }
    etatHeureSupp = (recu) => {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/reporting"
        return axios.post(url,recu,{responseType: "arraybuffer"})
                    .then(res => res);
    }

}