import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { InfrastructureServices } from '../../service/InfrastructureServices';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

const EtatInfrastructure = () => {
    
    let emptyEntite = {
        idEtatInfrastructure: null,
        libelleEtatInfrastructure: ''
    };

    const [listeEtatInfrastructure, setListeEtatInfrastructure] = useState(null);
    const [entiteDialog, setEntiteDialog] = useState(false);
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false);
    const [etatInfrastructure, setEtatInfrastructure] = useState(emptyEntite);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const utiliteInfrastructureService = new InfrastructureServices();
    const notify = new Notify();
    const spinner = new Spinner();


    useEffect(() => {
        
            setLoading(true)
            utiliteInfrastructureService.getListeEtatInfrastructure().then(data => {
                setListeEtatInfrastructure(data)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(()=>setLoading(false));
        }, []
    );

    const openNew = () => {
        setEtatInfrastructure(emptyEntite);
        setSubmitted(false);
        setEntiteDialog(true);
    }
   
    const hideDialog = () => {
        setSubmitted(false);
        setEntiteDialog(false);
    }
    
    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false);
    }
    
    const saveEntite = () => {

        setSubmitted(true);
        if (etatInfrastructure.libelleEtatInfrastructure.trim()) {

            if (etatInfrastructure.idEtatInfrastructure) {
                utiliteInfrastructureService.updateEtatInfrastructure(etatInfrastructure.idEtatInfrastructure, etatInfrastructure).then(data => {
                    utiliteInfrastructureService.getListeEtatInfrastructure().then(data => setListeEtatInfrastructure(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                utiliteInfrastructureService.createEtatInfrastructure(etatInfrastructure).then(data => {
                    utiliteInfrastructureService.getListeEtatInfrastructure().then(data => setListeEtatInfrastructure(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                
            }
            
            setEntiteDialog(false);
            setEtatInfrastructure(emptyEntite);
        }
    }
    
    const editEntite = (etatInfrastructure) => {

       
        setEtatInfrastructure(etatInfrastructure);
        setEntiteDialog(true);
    }

    
    const confirmDeleteEntite = (etatInfrastructure) => {
        setEtatInfrastructure(etatInfrastructure);
        setDeleteEntiteDialog(true);
    }
    
    const deleteEntite = () => {

        utiliteInfrastructureService.deleteEtatInfrastructure(etatInfrastructure.idEtatInfrastructure).then(data => {
            setEtatInfrastructure(emptyEntite);
            utiliteInfrastructureService.getListeEtatInfrastructure().then(data => setListeEtatInfrastructure(data));
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setDeleteEntiteDialog(false);
        
    }
    
    const onChampsChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let curentEtatInfrastructure = {...etatInfrastructure};
        curentEtatInfrastructure[`${name}`] = val;

        setEtatInfrastructure(curentEtatInfrastructure);
    }
    

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editEntite(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteEntite(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );

    const entiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-succes" onClick={saveEntite} />
        </React.Fragment>
    );

    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-succes" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={deleteEntite} />
        </React.Fragment>
    );

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={listeEtatInfrastructure} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={5} 
                    globalFilter={globalFilter}
                    header="Liste des états d'infrastructure">

                    <Column field="libelleEtatInfrastructure" header="Etat infrastructure" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>



            {/* Ajout d'une utilité  */}
            <Dialog visible={entiteDialog} style={{ width: '450px' }} header="Edition état infrastructure" modal className="p-fluid" footer={entiteDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleEtatInfrastructure">Etat infrastructure</label>
                    <InputText id="libelleEtatInfrastructure" value={etatInfrastructure.libelleEtatInfrastructure} onChange={(e) => onChampsChange(e, 'libelleEtatInfrastructure')} required autoFocus className={classNames({ 'p-invalid': submitted && !etatInfrastructure.libelleEtatInfrastructure })} />
                    {submitted && !etatInfrastructure.libelleEtatInfrastructure && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </Dialog>



            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {etatInfrastructure && <span>Etes vous sûr de supprimer cet élément ? <b>{etatInfrastructure.libelleEtatInfrastructure}</b>?</span>}
                </div>
            </Dialog>

        </div>
    )

}
export default EtatInfrastructure