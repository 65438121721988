import axios from 'axios';
import { AdresseIP } from '../components/AdresseIP';


export class EtablissementService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etablissement-lmd"
       return axios.get(url).then(res => res.data)
   }
   get(idEtablissement) {
    const url = this.adresse.getAdresseIneFaso() +"/service/api/campusfaso-lmd/campus-faso/etablissement-lmd?idEtablissement="+idEtablissement
    return axios.get(url)
                .then(res => res.data)
    }

}