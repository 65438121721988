import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../../components/Spinner";
import { Notify } from "../../../components/Notify";
import { CohorteServices } from "../../../volumeHoraire/service/CohorteServices";
import { ParcoursFiliereService } from "../../service/ParcoursFiliereService";
import { AnneeService } from "../../service/AnneeService";
import { SemestreService } from "../../service/SemestreService";
import { NiveauService } from "../../service/NiveauService";
import { ImportInscruptionService } from "../../service/ImportInscriptionService";
import { EnseignantService } from "../../service/EnseignantService";

export class ImportEnseignant extends Component {
    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.cohorteServices = new CohorteServices();
        this.parcoursDepartementService = new ParcoursFiliereService();
        this.anneeService = new AnneeService();
        this.semestreService = new SemestreService();
        this.niveauService = new NiveauService();
        this.enseigantService = new EnseignantService();

        this.state = {
            nomFichier: "",
            fichier: "",
            username: "",
            dateFichier: "",
            token: null,
            loadingChargement: false,
            bool: false,
            submitted: false,
            verifier: false,
        };
    }

    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
    };

    onFileChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.split(".").pop();
            let ext = extension.toUpperCase();
            if (ext === "CSV") {
                this.setState({ nomFichier: file.name });
                const reader = new FileReader();
                reader.onload = (readerEvt) => {
                    let binaryString = readerEvt.target.result;
                    let fichier = btoa(binaryString);
                    this.setState({ fichier: fichier });
                    this.setState({ username: "osaidoupro@gmail.com" });
                    this.setState({ dateFichier: "21-03-2021" });
                };
                reader.readAsBinaryString(file);
            }
        }
    };
    saveImportFichier = () => {
        let nomFichier = this.state.nomFichier;
        let fichier = this.state.fichier;
        let username = this.state.username;
        let dateFichier = this.state.dateFichier;
        let verifier = this.state.verifier;
        //this.state.departement ?idDepartement = this.state.departement.idDepartementEtab : idDepartement = this.state.token.code_departement
        let donneeFichier = {
            nomFichier: nomFichier,
            fichier: fichier,
            username: username,
            dateFichier: dateFichier,
            verifier: verifier,
        };
        //console.log(donneeFichier)
        this.setState({ loadingChargement: true });
        this.enseigantService
            .chargementFichier(donneeFichier)
            .then((data) => {
                this.setState({
                    loadingChargement: false,
                    bool: true,
                    submitted: true,
                });
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
                this.setState({
                    loadingChargement: false,
                    bool: false,
                    submitted: true,
                });
            });
    };

    /*convertToCSV = (fichier) => {
        let buff = new Buffer(fichier, 'base64');
        let base64ToStringNew = buff.toString('ascii');
        return base64ToStringNew
    }

    exportCSVFile = (file, fileTitle) => {
        
        let jsonObject = JSON.stringify(file)
        let csv = this.convertToCSV(jsonObject)
        let exportedFilename = fileTitle || 'export.csv'
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename)
        } else {
            let link = document.createElement('a')
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', exportedFilename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }*/

    render() {
        return (
            <div>
                {/*<div className="card p-lg-10" style={{marginLeft : "10%"}}>
                        {
                            <h6 style={{textAlign : "center"}}> Page d'import des inscriptions </h6>
                                
                        }
                    </div>*/}
                {/*<div className="p-lg-10" style={{marginLeft : "10%", textAlign : "center"}}>Chargement par fichier CSV</div>*/}
                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >
                    <div className="p-grid p-lg-12" style={{ marginTop: "3%" }}>
                        <div className="p-lg-12">
                            <h3
                                style={{
                                    textDecoration: "underline",
                                    textAlign: "center",
                                }}
                            >
                                <a
                                    href="/assets/layout/fichiers/canevas_enseignant.csv"
                                    target="_blank"
                                >
                                    Cliquer ici pour telecharger le canevas des
                                    enseignants
                                </a>
                            </h3>
                        </div>
                    </div>
                    <div className="p-lg-12" style={{ marginTop: 10 }}>
                        <div
                            className="p-lg-12 p-grid"
                            style={{ marginTop: 10, marginLeft: "10%" }}
                        >
                            <div
                                className="p-lg-12 p-grid"
                                style={{ marginLeft: "0%" }}
                            >
                                <div
                                    className="p-lg-4"
                                    style={{ marginTop: -20 }}
                                >
                                    <input
                                        type="file"
                                        style={{ marginLeft: "0%" }}
                                        onChange={(e) => {
                                            this.onFileChange(e);
                                        }}
                                    />
                                </div>
                                {this.state.loadingChargement ? (
                                    <div className="p-lg-2">
                                        {this.spinner.loadingSpinner(
                                            "spin",
                                            "black"
                                        )}
                                    </div>
                                ) : (
                                    <div className="p-lg-2">
                                        {this.state.submitted &&
                                            this.state.bool && (
                                                <span
                                                    style={{ color: "green" }}
                                                >
                                                    Chargement réussi !
                                                </span>
                                            )}
                                        {this.state.submitted &&
                                            !this.state.bool && (
                                                <span style={{ color: "red" }}>
                                                    Echec de chargement !
                                                </span>
                                            )}
                                    </div>
                                )}
                                <div
                                    className="p-lg-4"
                                    style={{ marginTop: -20 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Valider le chargement"
                                        onClick={this.saveImportFichier}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportEnseignant;
