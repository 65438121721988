import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';


export class EquipementServices {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getListeEquipements() {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/equipements"
        return axios.get(url)
                .then(res => res.data)
    }

    getListeEquipementDeInfrastructure(idInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/equipements-infrastructures?idInfrastructure="
        return axios.get(url+idInfrastructure)
                .then(res => res.data)
    }

    update(idEquipement, equipement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/equipements/"
        return axios.put(url+idEquipement, equipement)
                   .then(res => res.data);  
    }

    create(equipement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/equipements"
        return axios.post(url, equipement)
                   .then(res => res.data);
    }


    delete(idEquipement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/equipements/"
        return axios.patch(url+idEquipement)
                   .then(res => res.data);  
    }


    enregistrerEquipementsInfrastructure(listeEquipement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/equipements-infrastructures"
        return axios.post(url, listeEquipement)
                   .then(res => res.data);  
    }

}
