import axios from 'axios';
import { AdresseIP } from "../../components/AdresseIP";

export class TypeCritereService {
    constructor(){
        this.adresse = new AdresseIP();
    }
    getAll() {
       
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-criteres"
        return axios.get(url).then(res => res.data) 
    }
    
    get (idSession) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-criteres?idSession="
        return axios.get(url+idSession).then(res => res.data)
       }
 
    create(newTypeCritere) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-criteres"
        return axios.post(url,newTypeCritere)
                    .then(res => res.data);
    }
    
    update(idTypeCritere ,newTypeCritere) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-criteres/"
        return axios.put(url+idTypeCritere, newTypeCritere)
                    .then(res => res.data);
    }
 
    delete(idTypeCritere) {
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-criteres/"
        return axios.patch(url+idTypeCritere)
                    .then(res => res.data)
    }
}