import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { CohorteServices } from '../../service/CohorteServices';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { TypeActiviteService } from '../../../lmd/service/TypeActiviteService';
import { SemestreService } from '../../../lmd/service/SemestreService';
import { ECService } from '../../../lmd/service/ECService';

const Cohortes = () => {
    let emptyEntite = {
        parcours: "",
        niveau: "",
        annee: "",
        nombreParCohorte: "",
        critereTrie: ""
    };

    const defaultValues = {
        parcours: "",
        niveau: "",
        annee: "",
        nombreParCohorte: "",
        critere: "",
        typeActivite: "",
        cohorteParent: "",
        elementConstitutif: ""
    };


    let optionTrie = [
        {
            critere: 'ordreAlphabetique',
            libelleCritere: 'Ordre alphabetique'
        },
        {
            critere: 'parAge',
            libelleCritere: 'Ordonner suivant l\'âge'
        },
        {
            critere: 'aleatoire',
            libelleCritere: 'Ordre aléatoire'
        }
    ];


    const [curentParcours, setCurentParcours] = useState(null);
    const [curentNiveau, setCurentNiveau] = useState(null);
    const [curentAnnee, setCurentAnnee] = useState(null);
    const [curentCritere, setCurentCritere] = useState(null)
    const [listeCohortes, setlisteCohortes] = useState(null);
    const [listeParcours, setListeParcours] = useState(null);
    const [listeNiveaux, setListeNiveaux] = useState(null);
    const [listeEtudiantsCohorte, setListeEtudiantsCohorte] = useState(null);
    const [loadingLlisteEtudiant, setLoadingListeEtudiant] = useState(false)
    const [nombreEtudiants, setNombreEtudiants] = useState(0);
    const [cohorteTested, setCohorteTested] = useState(false);
    const [cohorteForm, setCohorteForm] = useState(emptyEntite);
    const [cohorte, setCohorte] = useState(null);
    const [listeAnneesAcademique, setListeAnneesAcademique] = useState(null);
    const [noEtudiant, setNoEtudiant] = useState(false)
    const [entiteDialog, setEntiteDialog] = useState(false);
    const [etudiantsDialog, setEtudiantsDialog] = useState(false);
    const [etudiantsDialogTitre, setEtudiantsDialogTitre] = useState(null);
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [idDepartement, setIdDepartement] = useState(null);
    const [idIesr, setIdIesr] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [existeCohorte, setExisteCohorte] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTPTD, setLoadingTPTD] = useState(false);
    const [spin, setSpin] = useState(false);
    const [spinTest, setspinTest] = useState(false);
    const { control, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({ defaultValues: defaultValues });
    const [dimension, setdimension] = useState({ width: 0, height: 0 });
    const [listTypeActivite, setlistTypeActivite] = useState([]);
    const [cours, setcours] = useState(false);
    const [filterCohorteListe, setfilterCohorteListe] = useState([]);
    const [dialogCohorteTP_TD, setdialogCohorteTP_TD] = useState(false);
    const [readOnly, setreadOnly] = useState(false);
    const [listSemestre, setlistSemestre] = useState([]);
    const [listeEC, setlisteEC] = useState([]);
    const [listeCohortesTPTD, setListeCohortesTPTD] = useState([]);
    const [dialogTPTD, setDialogTPTD] = useState(false);
    const [selectedCohorte, setSelectedCohorte] = useState({});


    const dt = useRef(null);
    const cohortesServices = new CohorteServices();
    const notify = new Notify();
    const spinner = new Spinner();
    const typeActiviteService = new TypeActiviteService();
    const semestreService = new SemestreService();
    const ecService = new ECService();

    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);
    let code_departement = token.code_departement;

    useEffect(() => {

        //On recupère l'identifiant de l'université ou du département (A corriger pour une effectivité)

        

        if (code_departement) {

            setLoading(true)
            setIdDepartement(code_departement);

            

            //A revoir apres pour recuperer la bonne liste à travers l'idDepartement de l'utilisateur connecté
            //Par departement
            cohortesServices.getListeCohorteDepartement(code_departement).then(data => {
                setlisteCohortes(data)
                setLoading(false)

            }).catch(error => {
                console.log(error);
                notify.echec(error.response.data.message)
            }).finally(() => setLoading(false));

            //ID de l'universite à recuperer dans les paramètres de l'utilisateur
            cohortesServices.getListeParcoursDepartement(code_departement).then(data => {
                setListeParcours(data)
            }).catch(error => {
                console.log(error)
            })


        } else
            if (token.code_iesr) {

                setLoading(true)
                setIdIesr(token.code_iesr)

                
                //A revoir apres pour recuperer la bonne liste à travers l'idIesr de l'utilisateur connecté
                //Par université
                let idTypeActivite = ""
                cohortesServices.getListeCohorteUniversite(token.code_iesr, idTypeActivite).then(data => {

                    setlisteCohortes(data)
                    setLoading(false)
                }).catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message)
                    setLoading(false)
                })

                //ID de l'universite à recuperer dans les paramètres de l'utilisateur
                cohortesServices.getListeParcoursUniversite(token.code_iesr).then(data => {
                    setListeParcours(data)
                }).catch(error => {
                    console.log(error)
                })
            }

        //La liste des années académiques
        cohortesServices.getListeAnneeAcademique().then(data => {
            let listeAnnees = data['anneeAcademiqueDtos']

            let anneesAcademique = listeAnnees.map(annee => {
                let curentAnnee = {
                    idAnneeAcademique: annee['idAnneeAcademique'],
                    libelleAnnee: annee['anneeDebut'] + "-" + annee['anneeFin']
                }
                return curentAnnee
            })

            setListeAnneesAcademique(anneesAcademique)
        }).catch(error => {
            console.log(error)
        })

        //La liste des niveaux dans LMD
        cohortesServices.getListeNiveau().then(data => {

            let listeNiveaux = data.map(niveau => {
                let curentNiveau = {
                    idNiveau: niveau['idNiveau'],
                    libelleNiveau: niveau['libelleNiveau']
                }

                return curentNiveau
            })

            setListeNiveaux(listeNiveaux)
        }).catch(error => {
            console.log(error)
        });

        typeActiviteService.getAll().then(response => { setlistTypeActivite(response) });
        //typeActiviteService.getAll().then(response=>{setlistTypeActivite(response.filter(data=>data.libelleTypeActivite !=="Cours Magistral (CM)"))});

    }, []
    );


    const openNew = () => {
        setCohorteForm(emptyEntite);
        setSubmitted(false);
        setEntiteDialog(true);
        setExisteCohorte(false)
        setNoEtudiant(true)


        typeActiviteService.getAll().then(response => { 
            setlistTypeActivite(response) 
            setValue("typeActivite", response.find(activite => activite.libelleTypeActivite === "Cours Magistral (CM)"));
            setreadOnly(true);
        }).catch(errors => {
            console.log(errors)
        });

        
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEntiteDialog(false);
        setCohorteTested(false)
        setNombreEtudiants(0)
        setNoEtudiant(false)
        setSpin(false)
    }

    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false);
    }

    const handleCohorteSubmit = dataForm => {
        let objetCohorteDTO = {};
        setSpin(true);
        if (entiteDialog) {
            objetCohorteDTO = {
                idParcoursFiliere: dataForm.parcours.idParcoursFiliere,
                idNiveau: dataForm.niveau.idNiveau,
                idAnneeAcademique: dataForm.annee.idAnneeAcademique,
                paramOrder: dataForm.critere.critere,
                idTypeActivite: dataForm.typeActivite.idTypeActivite,
                nbEtudiantCohorte: dataForm.nombreParCohorte
            };

            cohortesServices.createCohorte(objetCohorteDTO).then(data => {
                notify.success();
                setLoading(true);
                cohortesServices.getListeCohorteDepartement(code_departement).then(data => {
                    setlisteCohortes(data);

                }).catch(error => {
                    console.log(error);
                });

                //Réinitialisation des differentes variables

                setLoading(true);
                reset();
                setNoEtudiant(false);
                setNombreEtudiants(0);
                setEntiteDialog(false);

            }).catch(error => {
                console.log(error)
            })
                .finally(() => {
                    setLoading(false);
                    setSpin(false);
                });
        } else if (dialogCohorteTP_TD) {
            objetCohorteDTO = {
                idParcoursFiliere: dataForm.cohorteParent.idParcoursFiliere,
                idNiveau: dataForm.cohorteParent.idNiveau,
                idAnneeAcademique: dataForm.cohorteParent.idAnneeAcademique,
                paramOrder: dataForm.critere.critere,
                idTypeActivite: dataForm.typeActivite.idTypeActivite,
                nbEtudiantCohorte: +dataForm.nombreParCohorte,
                idCohorteParent: dataForm.cohorteParent.idCohorte,
                idElementConstitutif: dataForm.elementConstitutif.idElementConstitutif,
                libelleElementConstitutif: dataForm.elementConstitutif.libelleElementConstitutif
            };


            cohortesServices.createCohorteTd(objetCohorteDTO).then(data => {
                notify.success()
                setLoadingTPTD(true)
                
                cohortesServices.getListeCohorteDepartement(code_departement, objetCohorteDTO.idTypeActivite, objetCohorteDTO.idCohorteParent).then(data => {
                    setListeCohortesTPTD(data);
                    setLoadingTPTD(false)
                }).catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message)
                    setLoadingTPTD(false)
                });
                setdialogCohorteTP_TD(false);


                //Réinitialisation des differentes variables
                setLoading(false);
                reset();
                setNoEtudiant(false);
                setNombreEtudiants(0);

            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            }).finally(() => {
                    setLoading(false);
                    setSpin(false)
                });


        }


    }


    const testerExistanceCohorte = (parcours, niveau, annee) => {

        let typeActivite = getValues("typeActivite");

        setspinTest(true);
        // cohortesServices.testerExistanceCohorte(parcours.idParcoursFiliere, niveau.idNiveau, annee.idAnneeAcademique)
        cohortesServices.getCohorteByTypeActivite(parcours.idParcoursFiliere, niveau.idNiveau, annee.idAnneeAcademique, typeActivite.idTypeActivite)
            .then(data => {

                if (data.length > 0) {
                    setExisteCohorte(true);

                } else {
                    //On va recupérer le nombre d'étudiants de la promotion
                    //Si le nombre est renvoyé, on passe à l'étape du choix du nombre d'étudiants par cohorte et du critère de trie
                    cohortesServices.getNbEtudiantsInscrit(parcours.idParcoursFiliere, niveau.idNiveau, annee.idAnneeAcademique)
                        .then(data => {
                            if (data > 0) {
                                setNombreEtudiants(data)
                                setNoEtudiant(false) 
                            } else {
                                setNoEtudiant(true)
                            }
                        }).catch(error => {
                            console.log(error)
                            notify.echec(error.response.data.message)
                        })
                }
            }).catch(error => {
                console.log(error)
                setSubmitted(false)
            })
            .finally(() => setspinTest(false));

    }


    const showListeEtudiantsCohorte = (cohorte) => {
        setCohorte(cohorte)
        setLoadingListeEtudiant(true)
        let titre = "Liste des étudiants de la cohorte: " + cohorte.libelleCohorte
        setEtudiantsDialogTitre(titre)
        setEtudiantsDialog(true)

        cohortesServices.getListeEtudiantsCohorte(cohorte.idCohorte).then(data => {
            setListeEtudiantsCohorte(data)
            titre = titre + " ( " + data.length + " etudiants ) "
            setEtudiantsDialogTitre(titre)

            setLoadingListeEtudiant(false)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setLoadingListeEtudiant(false)
        })
    }



    const confirmDeleteCohorte = (cohorte) => {
        setCohorte(cohorte);
        setDeleteEntiteDialog(true);
    }



    const deleteEntite = () => {
        setSpin(true);


        notify.success()
        let idTypeActivite = cohorte.idTypeActivite

        if(idTypeActivite===1){
            setLoading(true)
        }else{
            setLoadingTPTD(true)
        }

        //console.log(cohorte)

        
        cohortesServices.deleteCohorte(cohorte.idCohorte)
            .then(data => {

                setDeleteEntiteDialog(false)

                let idCohorteParant = cohorte.cohorteParent? cohorte.cohorteParent.idCohorte:null
               
                if (idIesr) {
                    cohortesServices.getListeCohorteUniversite(idIesr, idTypeActivite, idCohorteParant).then(data => {

                        if(idTypeActivite===1){
                            setlisteCohortes(data)
                            setLoading(false)
                        }else{
                            setListeCohortesTPTD(data)
                            setLoadingTPTD(false)
                        }
                        
                        setCohorte(null)
                        setDeleteEntiteDialog(false)
                    }).catch(error => {
                        console.log(error);
                        setLoading(false)
                    })
                } else
                    if (idDepartement) {

                        cohortesServices.getListeCohorteDepartement(idDepartement, idTypeActivite, idCohorteParant).then(data => {
                            if(idTypeActivite===1){
                                setlisteCohortes(data)
                                setLoading(false)
                            }else{
                                setListeCohortesTPTD(data)
                                setLoadingTPTD(false)
                            }
                            
                            setCohorte(null)
                            setDeleteEntiteDialog(false)
                        }).catch(error => {
                            console.log(error);
                            setLoading(false)
                        })

                    }

            }).catch(error => {
                notify.echec(error.response.data.message)
                console.log(error)
            })
            .finally(() => { setLoading(false); setSpin(false) })
            
            

    }






    const hideEtudians = () => {
        setEtudiantsDialog(false)
        setDialogTPTD(false)
        setListeCohortesTPTD([])
    }




    const onNumberChampsChange = (e, name) => {

        //Si rien n'est saisie, la valeur retenue est ""
        let val = (e.target && e.target.value) || "";
        let curentCohorteForm = { ...cohorteForm }
        //Si la valeur n'est pas un numerique, et est different de chaine vide alors on prend temporairement une chaine vide ""
        val = (val !== "" && !isNaN(val)) ? val : "";

        if (val === "") {
            //A ce statde, si la valeur est une chaine vide, il se peut que ce soit parceque c'est pas un numerique, ou que c'est effectivement une chaine vide
            val = (!isNaN(curentCohorteForm[`${name}`]) && curentCohorteForm[`${name}`].length > 1) ? curentCohorteForm[`${name}`] : "";
        }
        curentCohorteForm[`${name}`] = val;
        setCohorteForm(curentCohorteForm);
    }

    const onDropDownChange = (e, element) => {
        let curentFormData = cohorteForm

        curentFormData[`${element}`] = e.target.value
        setCohorteForm(curentFormData)
        if (submitted) {
            setExisteCohorte(false)
            setNoEtudiant(false)
            setSubmitted(false)
        }

        if (element === 'parcours') {
            setCurentParcours(e.target.value)
        }

        if (element === 'niveau') {
            setCurentNiveau(e.target.value)
        }

        if (element === 'annee') {
            setCurentAnnee(e.target.value)
        }

        if (element === 'critereTrie') {
            setCurentCritere(e.target.value)
        }

    }
    const onHide = () => {
        setEntiteDialog(false);
        setdialogCohorteTP_TD(false);
        setNoEtudiant(true)
        setExisteCohorte(false)
        reset()
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-list" className="p-button-warning p-mr-2" title="Liste des étudiants" onClick={() => showListeEtudiantsCohorte(rowData)} />
                <Button icon="pi pi-chevron-circle-up" className="p-button-info p-mr-2" title="TP/TD" onClick={() => handleTDTPSelection(rowData)} style={{ marginLeft: 5 }} />
                <Button icon="pi pi-trash" className="p-button-danger" title="Suppression" onClick={() => confirmDeleteCohorte(rowData)} style={{ marginLeft: 5 }} />
            </React.Fragment>
        );
    }

    const handleTDTPSelection = (rowData) => {
        setSelectedCohorte(rowData);
        setLoadingTPTD(true);


        cohortesServices.getListeCohorteDepartement(code_departement, rowData.idTypeActivite, rowData.idCohorte).then(data => {
            setListeCohortesTPTD(data);

            //console.log(data)
            setDialogTPTD(true);

            // cohortesServices.getListeCohorteDepartement(code_departement, "").then(data => {
            //     setListeCohortesCm(data)
            // }).catch(error => {
            //     console.log(error)
            // })

        }).catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        .finally(() => {
            setLoadingTPTD(false);
        })


        
    }

    const openNewTPTD = ()=>{
        setdialogCohorteTP_TD(true);
        setValue("cohorteParent", selectedCohorte);


        //console.log(selectedCohorte)


        setfilterCohorteListe([selectedCohorte]);
        setNombreEtudiants(selectedCohorte.nbEtudiantCohorte);
        setNoEtudiant(false)


        
        setlistTypeActivite(listTypeActivite.filter(data=>data.libelleTypeActivite !=="Cours Magistral (CM)"))
    }


    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{ marginLeft: 10 }} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                {/* <i className="pi pi-search" /> */}
                <InputText style={{ marginLeft: 20, width: "300px" }} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    const headerTPTD = (
        <div style={{width:100}}>
            <span className="p-input-icon-left">
                <Button style={{ marginLeft: 10, marginBottom:10 }} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNewTPTD} />
            </span>
        </div>
    );


    const entiteDialogFooter = (
        <React.Fragment>
            
            <div style={{ display: "flex", marginTop: 20 }}>
                <Button type='button' style={{ width: 125, marginRight: 15 }} label="Quitter" icon="pi pi-times" disabled={spin} className="p-button-danger" onClick={onHide} />

                {!existeCohorte && !noEtudiant && <Button type='submit' style={{ width: 125 }} label="Enregistrer" icon={spin ? "pi pi-spinner pi-spin" : "pi pi-save"} disabled={spin} className="p-button-success" />}
                
            </div>
        </React.Fragment>
    );



    const etudiantsDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-danger" onClick={hideEtudians} />
        </React.Fragment>
    );



    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-success" disabled={spin} onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" icon={spin ? "pi pi-spin pi-spinner" : "pi pi-check"} disabled={spin} className="p-button-danger" onClick={deleteEntite} />
        </React.Fragment>
    );

    const actionHeader = <Button type="button" icon="pi pi-cog" />;
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">*{errors[name].message}</small>
    };

    const handleAnnee = (annee) => {
        setExisteCohorte(false);
        setNoEtudiant(true);
        let parcours = getValues("parcours");
        let niveau = getValues("niveau");
        let typeActivite = getValues("typeActivite");
        
        if (annee && parcours && niveau) {
            testerExistanceCohorte(parcours, niveau, typeActivite);
        }
    }

    const handleParcours = parcours => {
        let niveau = getValues("niveau");
        let annee = getValues("annee");
        setExisteCohorte(false);
        setNoEtudiant(true);
        if (annee && parcours && niveau) {
            testerExistanceCohorte(parcours, niveau, annee);
        }
    }
    const handleNiveau = niveau => {
        setExisteCohorte(false)
        setNoEtudiant(true);
        let parcours = getValues("parcours");
        let annee = getValues("annee");
        if (annee && parcours && niveau) {
            testerExistanceCohorte(parcours, niveau, annee);
        }
    }

    const handleTypeActivite = (typeActivite) => {
        let cohorteParent = getValues("cohorteParent")
        ecService.getListeEcTypeActivite(cohorteParent.idParcoursFiliere, typeActivite.idTypeActivite, cohorteParent.idAnneeAcademique)
            .then(data => {
                let dataSet = new Set(data);
                setlisteEC([...dataSet])
            }).catch(error => {
                console.log(error)
            })
    }

    const cohorteBody = rowData => {
        return (
            <span title={rowData.libelleCohorte}>{rowData.libelleCohorte.substring(0, 9)} ...</span>
        )
    }


    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">

                {header}
                {loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable ref={dt} value={listeCohortes} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                        dataKey="id" paginator rows={10}
                        globalFilter={globalFilter}
                        header="Liste des cohortes de cours magistral">
                        {/* <Column field='cohorteParent' header="Cohorte Parent" filterMatchMode="contains" sortable={true} filter={true}></Column> */}
                        {/* <Column body={cohorteBody} header="Cohorte Parent" filterMatchMode="contains" sortable={true} filter={true}></Column> */}
                        <Column field="libelleCohorte" header="Libelle cohorte" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="libelleAnnee" header="Année académique" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="nbEtudiantCohorte" header="Nombre d'étudiant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{ textAlign: 'center', width: '12em' }}></Column>
                    </DataTable>
                }
            </div>

            <Dialog className="card" visible={entiteDialog || dialogCohorteTP_TD} style={{ width: "50vw", height: "500px" }} onHide={onHide}>

                <div className="p-d-flex p-jc-center">
                    {
                        entiteDialog ?
                            <div>
                                <h5 className="text-header">Ajouter des cohortes/classes de cours</h5>
                            </div> :
                            <div>
                                <h5 className="text-header">Ajouter des cohortes/classes TP/TD</h5>
                            </div>
                    }
                    <hr />
                    <form onSubmit={handleSubmit(handleCohorteSubmit)} className="p-fluid">

                        {
                            dialogCohorteTP_TD &&
                            <div>

                                <div className="p-field">
                                    <label htmlFor="cohorteParent">Cohorte/Classe Mère</label> {getFormErrorMessage('cohorteMere')}
                                    <Controller
                                        control={control}
                                        name="cohorteParent"
                                        render={({ field }) => (
                                            <Dropdown style={{ height: 40 }}
                                                optionLabel='libelleCohorte' options={filterCohorteListe} value={field.value}
                                                {...field}
                                                onChange={(e) => { field.onChange(e.value) }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className='p-grid'>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="annee">Type Activité</label> {getFormErrorMessage('typeActivite')}
                                        <Controller
                                            control={control}
                                            name="typeActivite"
                                            render={({ field }) => (
                                                <Dropdown style={{ height: 40 }}
                                                    optionLabel='libelleTypeActivite' options={listTypeActivite} value={field.value}
                                                    {...field}
                                                    onChange={(e) => { field.onChange(e.value); handleTypeActivite(e.value) }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="elementConstitutif">Element Constitutif (EC)</label> {getFormErrorMessage('elementConstitutif')}
                                        <Controller
                                            control={control}
                                            name="elementConstitutif"
                                            render={({ field }) => (
                                                <Dropdown style={{ height: 40 }}
                                                    optionLabel='libelleElementConstitutif' options={listeEC} value={field.value}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            entiteDialog &&
                            <div>
                                <div className="p-field">
                                    <label htmlFor="annee">Année académique</label> {getFormErrorMessage('annee')}
                                    <Controller
                                        control={control}
                                        name="annee"
                                        render={({ field }) => (
                                            <Dropdown style={{ height: 40 }}
                                                optionLabel='libelleAnnee' options={listeAnneesAcademique} value={field.value}
                                                {...field}
                                                onChange={(e) => { field.onChange(e.value); handleAnnee(e.value) }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <label htmlFor="parcours">Parcours</label> {getFormErrorMessage('parcours')}
                                        <Controller
                                            control={control}
                                            name="parcours"
                                            render={({ field }) => (
                                                <Dropdown style={{ height: 40 }}
                                                    optionLabel='libelleParcours' options={listeParcours} value={field.value}
                                                    {...field}
                                                    onChange={(e) => { field.onChange(e.value); handleParcours(e.value) }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="niveau">niveau</label> {getFormErrorMessage('niveau')}
                                        <Controller
                                            control={control}
                                            name="niveau"
                                            render={({ field }) => (
                                                <Dropdown style={{ height: 40 }}
                                                    optionLabel='libelleNiveau' options={listeNiveaux} value={field.value}
                                                    {...field}
                                                    onChange={(e) => { field.onChange(e.value); handleNiveau(e.value) }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {existeCohorte && <small className="p-error">Ce parcours et/ou ce Niveau dispose déjà de cohortes, changer puis réessayez.</small>}
                        {noEtudiant && getValues("parcours") && getValues("annee") && getValues("niveau") && !existeCohorte &&  <small className="p-error">Aucun étudiant, changer le parcours et/ou le Niveau puis réesayez.</small>}
                        {/*(nombreEtudiants !==0 && !existeCohorte) && <div style={{width:"100px", marginTop:15}}>
                                <Button onClick={testerExistanceCohorte} disabled={spinTest} type="button" className="p-button-info" label="suivant" icon={spinTest?"pi pi-spin pi-spinner":"pi pi-plus"} style={{marginRight:"15px"}} />
                            </div>
                        */} 


                        <div>
                            <div>
                                {
                                    !noEtudiant &&
                                    <div>
                                        <hr />
                                        Nombre d'étudiants inscrits: {nombreEtudiants}
                                        <hr />
                                    </div>
                                }


                                {
                                (!noEtudiant && !existeCohorte ) &&
                                (<div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <label htmlFor="nombreParCohorte">Nombre d'étudiant par cohorte</label> {getFormErrorMessage('nombreParCohorte')}
                                        <Controller
                                            control={control}
                                            name="nombreParCohorte"
                                            render={({ field }) => (
                                                <InputText style={{ height: 40 }} name={field.name} id={field.name} value={field.value} {...field} />
                                            )}

                                        />
                                    </div>

                                    <div className="p-field p-col-6">
                                        <label htmlFor="critere">Critère de trie</label> {getFormErrorMessage('nombreParCohorte')}
                                        <Controller
                                            control={control}
                                            name="critere"
                                            render={({ field }) => (
                                                <Dropdown style={{ height: 40 }}
                                                    value={field.value}
                                                    optionLabel='libelleCritere' options={optionTrie}
                                                    onChange={(e) => field.onChange(e.value)}
                                                />
                                            )}

                                        />
                                    </div>

                                </div>)
                                }





                            </div>
                            {entiteDialogFooter}
                        </div>
                       
                    </form>
                </div>
            </Dialog>

            {/* <Dialog visible={!entiteDialog} style={{ width: '85%', minHeight:500 }} header="Création de cohorte" modal className="p-fluid" footer={entiteDialogFooter} onHide={hideDialog}>
                
                <div className="p-grid">
                    <div className="p-col-12" >
                        <hr/>
                    </div>
                    <div className="p-col-12" >
                        <div className="p-field">
                            <label htmlFor="libelleParcours"></label>
                            <Dropdown optionLabel="libelleParcours" optionValue="idParcoursFiliere" value={curentParcours} options={listeParcours} onChange={(e) => {onDropDownChange(e, 'parcours')}} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.parcours })} placeholder="Parcours" />
                            {submitted && !cohorteForm.parcours && <small className="p-error">Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    ------------------------
                    
                    

                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="libelleNiveau">Selectionner le niveau</label>
                            <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau" value={curentNiveau} options={listeNiveaux} onChange={(e) => {onDropDownChange(e, 'niveau')}} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.niveau })} placeholder="Niveau"/>
                            {submitted && !cohorteForm.niveau && <small className="p-error">Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="libelleAnnee">Année académique</label>
                            <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={curentAnnee} options={listeAnneesAcademique} onChange={(e) => {onDropDownChange(e, 'annee')}} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.annee })} placeholder="Année" />
                            {submitted && !cohorteForm.annee && <small className="p-error">Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className="p-col-12" >
                        {existeCohorte && <small className="p-error">Ce parcours dispose déjà de cohortes</small>}
                        {noEtudiant && <small className="p-error">Ce parcours ne dispose d'aucun étudiant inscrit. Veuillez changer la sélection</small>}
                    </div>

                    {cohorteTested &&
                    <React.Fragment>
                        

                        <div className="p-col-6" >
                            <div className="p-field">
                                <label htmlFor="nombreParCohorte">Critère de trie</label>
                                <InputText id="nombreParCohorte" value={cohorteForm.nombreParCohorte} onChange={(e) => onNumberChampsChange(e, 'nombreParCohorte')} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.nombreParCohorte })} />
                                {submitted && !cohorteForm.nombreParCohorte && <small className="p-error">Ce champs est obligatoire.</small>}
                            </div>
                        </div>

                        <div className="p-col-6" >
                            <div className="p-field">
                                <label htmlFor="libelleCritere">Critère de trie</label>
                                <Dropdown optionLabel="libelleCritere" optionValue="critere" value={curentCritere} options={optionTrie} onChange={(e) => {onDropDownChange(e, 'critereTrie')}} required autoFocus className={classNames({ 'p-invalid': submitted && !cohorteForm.critereTrie })} placeholder="Sélectionner le critère de trie" />
                                {submitted && !cohorteForm.critereTrie && <small className="p-error">Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </React.Fragment>
                }
                </div>

            </Dialog> */}


            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3 danger" style={{ fontSize: '2rem' }} />
                    {cohorte && <span>
                        <br />La suppression d'une cohorte entraine la supression de tous les autres cohortes de la même promotion.
                        <br />Etes vous sûr de vouloir supprimer la cohorte:
                        <br /><b>{cohorte.libelleCohorte}</b> ?</span>
                    }
                </div>
            </Dialog>




            <Dialog visible={etudiantsDialog} style={{ width: '100vw', height: "100vh" }} header={etudiantsDialogTitre} modal className="p-fluid" footer={etudiantsDialogFooter} onHide={hideEtudians}>
                <hr />
                {loadingLlisteEtudiant ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable ref={dt} value={listeEtudiantsCohorte} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                        dataKey="id" paginator rows={50}
                        globalFilter={globalFilter}
                        header={etudiantsDialogTitre}>
                        <Column field="ine" header="INE" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="nom" header="Nom" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="prenom" header="Prénom(s)" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="dateNaissance" header="Date de naissance" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="telephone" header="Telephone" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    </DataTable>
                }
            </Dialog>


            <Dialog visible={dialogTPTD} style={{ width: '100vw', height: "100vh" }} modal className="p-fluid" footer={etudiantsDialogFooter} onHide={() => setDialogTPTD(false)}>
                <div>
                    <div className="card">
                        {headerTPTD}
                        {loadingTPTD ?
                            <div className="p-grid">
                                <div className="p-col-6" ></div>
                                <div className="p-col-6" >
                                    {spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                            :
                            <DataTable ref={dt} value={listeCohortesTPTD} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                                dataKey="id" paginator rows={10}
                                header="Liste des groupes de TD/TP"
                            >
                                <Column field="libelleCohorte" header="Libelle groupe" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column field="libelleAnnee" header="Année académique" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column field="nbEtudiantCohorte" header="Nombre d'étudiant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column field="cohorteParent.libelleCohorte" header="Cohorte" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column header={actionHeader} body={actionBodyTemplate} style={{ textAlign: 'center', width: '8em' }}></Column>
                            </DataTable>
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    )

}
export default Cohortes

