import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { CategorieDemandeService } from '../../service/CategorieDemandeService'
import { TypeDemandeService } from '../../service/TypeDemandeService'
import { StructureService } from '../../service/StructureService';


export class TypeDemande extends Component {
    emptyTypeDemande = {
        TypeDemande: null,
        categorieDemande : null,
        libelleTypeDemande: ""
        
    }

    constructor() {
        super();
        this.state = {
            listeTypeDemande : [],
            listeCategorieDemande : [],
            structure : {},
            typeDemandeDialog: false,
            deleteTypeDemandeDialog: false,
            typeDemande: this.emptyTypeDemande,
            submitted: false,
            loading : false,
            globalFilter: null
        }
        this.typeDemandeService = new TypeDemandeService()
        this.categorieDemandeService = new CategorieDemandeService()
        this.structureService = new StructureService();
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveTypeDemande = this.saveTypeDemande.bind(this);
        this.editTypeDemande = this.editTypeDemande.bind(this);
        this.confirmDeleteTypeDemande = this.confirmDeleteTypeDemande.bind(this);
        this.deleteTypeDemande = this.deleteTypeDemande.bind(this);
        this.onLibelleTypeDemandeChange = this.onLibelleTypeDemandeChange.bind(this);
        
        this.hideDeleteTypeDemandeDialog = this.hideDeleteTypeDemandeDialog.bind(this);

    }


    componentDidMount() {
        
        const structure = localStorage.getItem('structure')
        const struct = JSON.parse(structure)
        this.setState({structure : struct});

        this.setState({loading : true})
        this.typeDemandeService.getListeTypeDemandeStructure(385, 'NIVEAU_III').then(donnee => {
            this.setState({listeTypeDemande : donnee});
            this.setState({loading : false});
        })
        .catch(error => {
            console.log(error);
            this.setState({loading : false});
            this.notify.echec(error.response.data.message);
        })

        this.categorieDemandeService.get(385, 'NIVEAU_III').then(data => 
            this.setState({listeCategorieDemande: data})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
                
            
    }
    openNew() {
        
        this.setState({
            typeDemande: this.emptyTypeDemande,
            submitted: false,
            typeDemandeDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            typeDemandeDialog: false
        });
    }
    hideDeleteTypeDemandeDialog() {
        this.setState({ deleteTypeDemandeDialog: false });
    }
    saveTypeDemande() {
        let state = { submitted: true };

        
        if (this.state.typeDemande.categorieDemande && this.state.typeDemande.libelleTypeDemande.trim() ) {
            const newTypeDemande = {
                idTypeDemande: parseInt(this.state.typeDemande.idTypeDemande) ,
                idCategorieDemande : parseInt(this.state.typeDemande.categorieDemande.idCategorieDemande) ,
                libelleTypeDemande : this.state.typeDemande.libelleTypeDemande
                
                
            }
            console.log(newTypeDemande)
            if (this.state.typeDemande.idTypeDemande) {
                this.typeDemandeService.update(parseInt(this.state.typeDemande.idTypeDemande), newTypeDemande)
                                .then(data => {
                                    this.notify.success()
                
                                    this.setState({loading : true})
                                    this.typeDemandeService.getListeTypeDemandeStructure(385, 'NIVEAU_III').then(donnee => {
                                        this.setState({listeTypeDemande : donnee});
                                        this.setState({loading : false});
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        this.setState({loading : false});
                                        this.notify.echec(error.response.data.message);
                                    })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.typeDemandeService.create(newTypeDemande).then(data => {
                    this.notify.success()
                    this.setState({loading : true})
                    this.typeDemandeService.getListeTypeDemandeStructure(385, 'NIVEAU_III').then(donnee => {
                        this.setState({listeTypeDemande : donnee});
                        this.setState({loading : false});
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({loading : false});
                        this.notify.echec(error.response.data.message);
                    })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            } 

            state = {
                ...state,
                typeDemandeDialog: false,
                typeDemande: this.emptyTypeDemande
            };
        }

        this.setState(state);
    }
    editTypeDemande(typeDemande) {
        console.log(typeDemande)
        this.setState({
            typeDemande,
            typeDemandeDialog: true
        });
    }
    confirmDeleteTypeDemande(typeDemande) {
        this.setState({
            typeDemande,
            deleteTypeDemandeDialog: true
        });
    }
    deleteTypeDemande(idTypeDemande) {
        console.log(idTypeDemande)
       this.typeDemandeService.delete(parseInt(idTypeDemande)).then(data => {
            this.notify.success()
            this.setState({
                deleteTypeDemandeDialog: false, 
                typeDemande: this.emptyTypeDemande,
            });
            this.setState({loading : true})
            this.typeDemandeService.getAll().then(data => 
                this.setState({listeTypeDemande: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)  
                    console.log(error)
                    this.setState({loading : false})
                })
        })
    }
    onLibelleTypeDemandeChange(e, libelleTypeDemande) {
        const val = (e.target && e.target.value) || '';
        let typeDemande = this.state.typeDemande;
        typeDemande[`${libelleTypeDemande}`] = val;

        this.setState({typeDemande });
    }
    onIdCategorieDemandeChange(e, categorieDemande) {
        const val = (e.target && e.target.value) || '';
        let typeDemande = this.state.typeDemande;
        typeDemande[`${categorieDemande}`] = val;

        this.setState({ typeDemande });

    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeDemande(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeDemande(rowData)} />
            </React.Fragment>
        );
    }
    render() {
       
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const typeDemandeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeDemande} />
            </React.Fragment>
        );
        const deleteTypeDemandeDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteTypeDemandeDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeDemande(this.state.typeDemande.idTypeDemande) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeTypeDemande} selectionMode="single" header="Liste de type de demande" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleTypeDemande" header="Type demande"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleCategorieDemande" header="Categorie demande"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.typeDemandeDialog} style={{ width: '450px' }} header="Informations type demande" modal className="p-fluid" footer={typeDemandeDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleCategorieDemande">Categorie de de demande</label>
                        <Dropdown optionLabel="libelleCategorieDemande" optionValue="idCategorieDemande" value={this.state.typeDemande.categorieDemande} options={this.state.listeCategorieDemande} onChange={(e) => {this.onIdCategorieDemandeChange(e, 'categorieDemande')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un categorie de demande"/>
                        {this.state.submitted && !this.state.typeDemande.categorieDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="libelleTypeDemande">Libelle type de demande *</label>
                        <InputText id="libelleTypeDemande" value={this.state.typeDemande.libelleTypeDemande}  onChange={(e) => this.onLibelleTypeDemandeChange(e, 'libelleTypeDemande')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle type de demande'/>
                        {this.state.submitted && !this.state.typeDemande.libelleTypeDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    
                </Dialog>
                <Dialog visible={this.state.deleteTypeDemandeDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeDemandeDialogFooter} onHide={this.hideDeleteTypeDemandeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.typeDemande && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default TypeDemande
