import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { FiliereService } from '../../service/FiliereService'
import { ParcoursService } from '../../service/ParcoursService'
import { SemestreService } from '../../service/SemestreService'
import { AnneeService } from '../../service/AnneeService'
import { SessionExamenService } from '../../service/SessionExamenService'
import { ParcoursFiliereService } from '../../service/ParcoursFiliereService'

import { Dropdown } from 'primereact/dropdown'

export class Session extends Component {

    //Model de l'objet Notation
    emptySession = {
        idSession: null,
        dateOuverture: null,
        dateFermeture: null,
        typeSession: null,
        anneeSemestre: null,
        parcoursFiliere: null,
        //codeSession: null,
        //libelleSession: null
    }

    constructor(props){
        super(props);

        this.notify = new Notify()
        this.spinner = new Spinner()

        this.ModelDepartementFiliere = new FiliereService()
        this.ModelParcours = new ParcoursService()
        this.ModelSemestre = new SemestreService()
        this.ModelAnnee = new AnneeService()
        this.ModelSession = new SessionExamenService()
        this.ModelParcoursFiliere = new ParcoursFiliereService()

        this.state = {
            curentSession: this.emptySession,
            token : null,
            idDepartement : 0,
            sessionList: [],
            departementList: [],
            parcoursList: [],
            semestreList: [],
            anneeList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateSessionDialog: false,
            allowSave: false,
            currentAnnee: null,
            currentParcours: null,
            currentSemestre: null,
            currentTypeSession: null,
            currentDepartement: null,
            anneeSelected: false,
            deleteSessionDialog : false,
            actionConfirmCloseDialog : false,
            actionConfirmOpenDialog : false,
            idDepartementFiliere: null,
            listeTypeSession: [],
            spin: false
        }
    }

    componentDidMount(){
        
        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)

        const departement = localStorage.getItem('departement')
        const depart = JSON.parse(departement)

        this.setState({
            token : token,
            idDepartement : token.code_departement,
            currentDepartement: depart
        })

        this.setState({loading : true})
        this.ModelSession.getSessionDepartement(token.code_departement).then(data => {


            console.log(data)
            this.setState({
                sessionList: data.data
            })
            this.setState({loading : false})
            }
        )
        .catch(error => {
            //this.notify.echec(error.response.data.message)
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })


        this.ModelSession.getListeTypeSession().then(data => {
            this.setState({
                listeTypeSession: data.data
            })
        }).catch(error => {
            console.log(error)
            //this.notify.echec()
            this.notify.echec(error.response.data.message)
        })

    }


    
    //Gestion de l'ouverture du formulaire
    openNew = () => {

        this.ModelParcoursFiliere.getListeParcoursDepartement(this.state.idDepartement).then(data => {
            this.setState({parcoursList: data})
            })
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })

        this.setState(
            {
                curentSession: this.emptySession,
                viewCreateSessionDialog: true,
                anneeSelected: false
            }
        )

    }

    hideDialog = () => {

        this.setState({
            curentSession: this.emptySession,
            viewCreateSessionDialog: false
        })

    }

    hideDeleteSessionDialog = () => {
        this.setState({ deleteSessionDialog: false });
    }

    deleteSession = (idSession) => {
        this.ModelSession.delete(parseInt(idSession)).then(data => {
            this.notify.success()
            this.setState({
                deleteSessionDialog: false,
                curentSession: this.emptySession,
                redirect : true
            });
            this.setState({loading : true})
            this.ModelSession.getSessionDepartement(this.state.idDepartement).then(data => {
                this.setState({
                    sessionList: data.data
                })
                this.setState({loading : false})
                }
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })
            })
    }

    onDepartementChange = (event) => {

        let departement = event.target.value
        this.setState({
            currentDepartement: departement
        })

        //On recupère la liste des parcours pour positionner dans la selection des parcours
        this.ModelParcoursFiliere.getListeParcoursDepartement(parseInt(departement.idDepartementFiliere)).then(data => {
            //On reinitialise les champs de selections suivant
            this.setState({
                parcoursList: data,
                currentParcours: null,
                currentSemestre: null,
                currentTypeSession: null,
                curentSession: this.emptySession
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    onAnneeChange = (event) => {

        let annee = event.target.value

        this.setState({
            currentAnnee: annee
        })

        //On ne peut arriver à ce niveau que si l'ensemble des autres élements ont été selectionnés
        //On procède donc à la detection de la session concerné

        let informationSemestre = {
            idDepartementFiliere: parseInt(this.state.idDepartementFiliere),
            idParcours: parseInt(this.state.currentParcours.idParcours),
            idSemestre: parseInt(this.state.currentSemestre.idSemestre),
            idAnneeAcademique: parseInt(annee.idAnneeAcademique)
        }
        //console.log(informationSemestre)

        this.ModelSession.getInfosSession(informationSemestre).then(data => {
            let session = this.state.curentSession
            session.anneeSemestre = data.anneeSemestre
            ////////////////////////////////////////////////
            ////////////////////////////////////////////////
            ///////////////////////////////////////////////
            //session.typeSession = data.typeSession
            session.parcoursFiliere = data.parcoursFiliere
            this.setState({
                curentSession: session,
                currentTypeSession: data.typeSession
            })
        }).catch(error => {

            ////////////////////////////////////////////////
            ////////////////////////////////////////////////
            ////////////////////////////////////////////////
            this.setState({
                currentTypeSession: null,
                //anneeSelected: true
                anneeSelected: false
            })
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    onParcoursChange = (event) => {

        //On met à jour la valeur du parcours selectionné
        let parcours = event.target.value
        this.setState({
            currentParcours: parcours,
            idDepartementFiliere: parcours.idDepartementFiliere
        })

        //On va selectionner les semestres du parcours pour les disponibiliser dans la selection
        this.ModelSemestre.get(parseInt(parcours.idParcours)).then(data => {
            this.setState({
                semestreList: data,
                currentSemestre: null,
                currentTypeSession: null,
                curentSession: {}
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })

    }

    onSemestreChange = (event) => {
        let semestre = event.target.value
        this.setState({
            currentSemestre: semestre
        })

        //On selectionne la liste des années pour disponibiliser à la selection 
        this.ModelAnnee.getAll().then(data => {
            this.setState({
                anneeList: data,
                currentAnnee: null,
                curentSession: {}
            })
            }
        )
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    onInputChange = (event, field) => {
        
        let curentSession = this.state.curentSession
        curentSession[`${field}`] = event.target.value

        this.setState({
            curentSession,
            allowSave : this.validationOk(curentSession)
        })
    }

    onDateChange = (event, field) => {
        
        let curentSession = this.state.curentSession

        curentSession[`${field}`] = event.target.value

        this.setState({
            curentSession: curentSession,
            allowSave : this.validationOk(curentSession)
        })
    }

    saveSession = () => {

        let session = this.state.curentSession
        console.log(session)
        this.ModelSession.create(session).then(data => {

            this.setState({
                viewCreateSessionDialog: false,
                curentSession: {},
                currentAnnee: {},
                currentDepartement: {},
                currentParcours: {},
                currentSemestre: {},
                currentTypeSession: {}
            })

            //On recharge la liste des sessions
            this.setState({loading : true})
            this.ModelSession.getSessionDepartement(this.state.token.code_departement).then(data => {
                
                this.setState({
                sessionList: data.data
            })
            this.setState({loading : false})
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })
            this.notify.success()

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })
        
    }

    validationOk = (session) =>{
        return (
                    session.dateOuverture
                    && session.dateFermeture
                    && session.typeSession
                    && session.anneeSemestre
                    && session.parcoursFiliere
                    && session.dateOuverture < session.dateFermeture
        ) ? true:false
    }

    listeExamenPage = (rowData) => {

        let idSession = rowData.idSession
        this.props.history.push('/lmd/parametres/examen/'+idSession)
    }


    confirmDeleteSession = (session) => {
        this.setState({
            curentSession : session,
            deleteSessionDialog: true
        });
    }



    confirmerFermetureSession = (session) => {
        this.setState({
            actionConfirmCloseDialog: true,
            curentSession: session
        })
    }


    confirmOpenSession = (session) => {
        this.setState({
            actionConfirmOpenDialog: true,
            curentSession: session
        })
    }


    hideConfirmCloseSession = () => {
        this.setState({
            actionConfirmCloseDialog: false,
            curentSession: this.emptySession
        })
    }


    hideConfirmOpenSessionSession = () => {
        this.setState({
            actionConfirmOpenDialog: false,
            curentSession: this.emptySession
        })
    }


    confirmCloseSession = () => {
        let actusession = this.state.curentSession
        actusession['etatSession'] = "FERME"

        this.setState({spin:true})
        this.ModelSession.update(actusession.idSession, actusession).then(response => {

            this.notify.success()
            this.setState({
                loading: true
            })
            this.ModelSession.getSessionDepartement(this.state.idDepartement).then(data => {
                this.setState({
                    sessionList: data.data,
                    loading: false,
                    spin: false
                })
                }
            )
            .catch(error => {
                console.log(error)
            })

            this.hideConfirmCloseSession()
        })
        .catch(errors => {
            actusession['etatSession'] = "OUVERT"
            console.log(errors)
            this.notify.echec(errors.response.data.message)
            this.hideConfirmCloseSession();
            this.setState({spin: false})
        })
    }


    confirmActionOpenSession = () => {
        let actusession = this.state.curentSession
        actusession['etatSession'] = "OUVERT"

        this.setState({spin:true})
        this.ModelSession.update(actusession.idSession, actusession).then(response => {

            this.notify.success()
            this.setState({
                loading: true
            })
            this.ModelSession.getSessionDepartement(this.state.idDepartement).then(data => {
                this.setState({
                    sessionList: data.data,
                    loading: false,
                    spin: false
                })
                }
            )
            .catch(error => {
                console.log(error)
            })

            this.hideConfirmOpenSessionSession()
        })
        .catch(errors => {
            actusession['etatSession'] = "FERME"
            console.log(errors)
            this.notify.echec(errors.response.data.message)
            this.hideConfirmOpenSessionSession();
            this.setState({
                spin: false
            })
        })
    }



    ////////////////////////////////////
    ////////////////////////////////////
    onTypeSessionChange = (e) => {

        let curentSession = this.state.curentSession
        curentSession.typeSession = e.target.value
        this.setState({
            curentSession
        })
    }
    
    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                {
                    rowData.etatSession==="OUVERT"? 
                        <Button type="button" icon="pi pi-lock" className="p-button-danger" style={{marginRight: '.5em'}} title='Fermer' onClick={ () => {this.confirmerFermetureSession(rowData)} } />
                        :
                        <Button type="button" icon="pi pi-lock-open" className="p-button-success" style={{marginRight: '.5em'}} title='Ouvrir' onClick={ () => {this.confirmOpenSession(rowData)} } />
                }
                <Button type="button" icon="pi pi-trash" className="p-button-danger" style={{marginRight: '.5em'}} title='Supprimer' onClick={ () => {this.confirmDeleteSession(rowData)} } />
            </React.Fragment>
        );
    }

    
    render() {

        if(this.state.redirect) {
            //window.location.reload();
        }
        
        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const deleteSessionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" disabled={this.state.spin }  className="p-button-text" onClick={this.hideDeleteSessionDialog} />
                <Button label="OUI" icon={this.state.spin ? "pi pi-spin pi-spinner" : "pi pi-check"} disabled={this.state.spin } className="p-button-text" onClick={()=> this.deleteSession(this.state.curentSession.idSession)} />
            </React.Fragment>
        );


        const confirmCloseSessionFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" disabled={this.state.spin } onClick={this.hideConfirmCloseSession} />
                <Button label="OUI" icon={this.state.spin ? "pi pi-spin pi-spinner" : "pi pi-check"} disabled={this.state.spin } className="p-button-text" onClick={()=> this.confirmCloseSession()} />
            </React.Fragment>
        );


        const confirmOpenSessionFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" disabled={this.state.spin }  onClick={this.hideConfirmOpenSessionSession} />
                <Button label="OUI"   icon={this.state.spin ? "pi pi-spin pi-spinner" : "pi pi-check"} disabled={this.state.spin } className="p-button-text" onClick={()=> this.confirmActionOpenSession()} />
            </React.Fragment>
        ); 




        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSession} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const sessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.sessionList} selectionMode="single" header="Liste des sessions" paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="parcoursFiliere.libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="anneeSemestre.libelleAnneeAcademique" header="Année académique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une année"/>
                                <Column field="anneeSemestre.libelleSemestre" header="Semestre" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un semestre"/>
                                <Column field="typeSession.libelleTypeSession" header="Session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher une session"/>
                                <Column field="dateOuverture" header="Date ouverture"  sortable={true} filter={true} filterPlaceholder="date ouverture"/>
                                <Column field="dateFermeture" header="Date fermeture"  sortable={true} filter={true} filterPlaceholder="date fermeture"/>
                                <Column field="etatSession" header="Etat de la session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un état"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                <Dialog visible={this.state.viewCreateSessionDialog} style={{ width: '80%' }} header="Informations de la session" modal className="p-fluid" footer={sessionDialogFooter} onHide={this.hideDialog}>
                    {/*
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Annee académique</label>
                                <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.currentAnnee}  options={this.state.anneeList} onChange={(e) => {this.onAnneeChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                                { !this.state.currentAnnee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                            </div>
                        </div>
                    </div>*/}
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le parcours</label>
                                <Dropdown optionLabel="libelleParcours" optionValue={this.state.currentParcours} value={this.state.currentParcours}  options={this.state.parcoursList} onChange={(e) => {this.onParcoursChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le parcours"/>
                                {this.state.currentDepartement && !this.state.currentParcours && <small className="p-invalid" style={{color : "red"}}>Selectionner le parcours.</small>}
                            </div>
                        </div>
                    </div>
                
                    
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Selectionner le semestre</label>
                                <Dropdown optionLabel="libelleSemestre" optionValue="idSemestre"  options={this.state.semestreList} value={this.state.currentSemestre} onChange={(e) => {this.onSemestreChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un semestre"/>
                                {this.state.currentParcours && !this.state.currentSemestre && <small className="p-invalid" style={{color : "red"}}>Selectionner un semestre.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="annee">Annee académique</label>
                                <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.currentAnnee}  options={this.state.anneeList} onChange={(e) => {this.onAnneeChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'année académique"/>
                                {this.state.currentSemestre && !this.state.currentAnnee && <small className="p-invalid" style={{color : "red"}}>Selectionner l'année.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="annee">Type de session</label>
                                {/* 
                                    ////////////////////////////////////////////////////////////////
                                    ///////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////
                                    <InputText id="libelleTypeSession" value={this.state.currentTypeSession?this.state.currentTypeSession.libelleTypeSession:""} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Type session (auto)" readOnly/>
                                */}
                                <Dropdown optionLabel="libelleTypeSession" optionValue="idTypeSession" value={this.state.curentSession.typeSession}  options={this.state.listeTypeSession} onChange={(e) => {this.onTypeSessionChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le type de session"/>
                                {this.state.currentSemestre && !this.state.currentTypeSession && this.state.anneeSelected && <small className="p-invalid" style={{color : "red"}}>Une session ne peut être crééer pour l'instant pour ce parcours.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateOuvertureSession">Date debut de la session</label>
                            <input type='date'  value={this.state.curentSession.dateOuvertureSession} onChange={(e) => this.onDateChange(e, 'dateOuverture')} readOnlyInput  placeholder='Entrer la date ouverture'  className='p-inputtext p-component'/>
                            {this.state.currentSemestre && this.state.currentAnnee && !this.state.curentSession.dateOuverture && <small className="p-invalid" style={{color : "red"}}>Entrer la date ouverture session.</small>}
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <label htmlFor="dateFermetureSession">Date fermeture </label>
                            <input type='date'  value={this.state.curentSession.dateFermetureSession} onChange={(e) => this.onDateChange(e, 'dateFermeture')} readOnlyInput  placeholder='Entrer la date fermetutre'  className='p-inputtext p-component'/>
                            {this.state.currentSemestre && this.state.currentAnnee && (!this.state.curentSession.dateFermeture || this.state.curentSession.dateFermeture<this.state.curentSession.dateOuverture ) && <small className="p-invalid" style={{color : "red"}}>Entrer la date ouverture session (doit être supérieur à la date de début).</small>}
                        </div>
                    </div>
                    
                </Dialog>



               

                <Dialog visible={this.state.actionConfirmCloseDialog} style={{ width: '450px' }} header="Confirmez" modal footer={confirmCloseSessionFooter} onHide={this.hideConfirmCloseSession}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        <center>
                            {this.state.curentSession && <span>Êtes-vous sûr de vouloir clôturer cette session <br/>(si oui toute action sera impossible sur cette session)<br/>?</span>}
                        </center>
                    </div>
                </Dialog>


                <Dialog visible={this.state.actionConfirmOpenDialog} style={{ width: '450px' }} header="Confirmez" modal footer={confirmOpenSessionFooter} onHide={this.hideConfirmOpenSessionSession}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        <center>
                            {this.state.curentSession && <span>Êtes-vous sûr de vouloir ouvrir cette session <br/>?</span>}
                        </center>
                    </div>
                </Dialog>



                <Dialog visible={this.state.deleteSessionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSessionDialogFooter} onHide={this.hideDeleteSessionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.curentSession && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )

}

}

export default Session
