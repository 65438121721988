import React, { createContext, useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import logoCampusFaso from "../images/campusfaso_logo_wb.png";
import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../src/volumeHoraire/components/parametres/index.css";
import Grade from "../volumeHoraire/components/parametres/Grade";
import Responsabilite from "../volumeHoraire/components/parametres/Responsabilite";
import Enseignant from "../volumeHoraire/components/parametres/Enseignant";
import Statut from "../volumeHoraire/components/parametres/Statut";
import Specialite from "../volumeHoraire/components/parametres/Specialite";
import NominationEnseignant from "../volumeHoraire/components/NominationEnseignant";
import Affectation from "../volumeHoraire/components/Affectation";
import Cohortes from "../volumeHoraire/components/parametres/Cohortes";
import Discipline from "../volumeHoraire/components/parametres/Discipline";
import UtiliteInfrastructure from "../volumeHoraire/components/parametres/UtiliteInfrastructure";
import Equipement from "../volumeHoraire/components/parametres/Equipement";
import ProgrammationSeance from "../volumeHoraire/components/parametres/ProgrammationSeance";
import Infrastructures from "../volumeHoraire/components/parametres/Infrastructures";
import EtatInfrastructure from "../volumeHoraire/components/parametres/EtatInfrastructure";
import Programmation from "../volumeHoraire/components/Programmation";
import TypeInfrastructure from "../volumeHoraire/components/parametres/TypeInfrastructure";
import CohortesTdTp from "../volumeHoraire/components/parametres/CohortesTdTp";
import EmploisDuTemps from "../volumeHoraire/components/EmploisDuTemps";
import SeanceEffectuer from "../volumeHoraire/components/SeanceEffectuer";
import SeanceEffectuerEnseignant from "../volumeHoraire/components/SeanceEffectuerEnseignant";
import SeanceEffectuerDA from "../volumeHoraire/components/SeanceEffectuerDA";
import FichePedagogique from "../volumeHoraire/components/FichePedagogique";
import Connexion from "./Connexion";
import MenuHome from "../home/MenuHome";
import ReservationInfrastructure from "../volumeHoraire/components/parametres/ReservationInfrastructure";
import ResponsableInfrastructure from "../volumeHoraire/components/parametres/ResponsableInfrastructure";
import EtatReservation from "../volumeHoraire/components/parametres/EtatReservation";
import FactureHeureSupp from "../volumeHoraire/components/FactureHeureSupp";
import Dashbord from "../volumeHoraire/components/Dashbord";
import DashboardMinistere from "../volumeHoraire/components/DashboardMinistere";

import InvitationEnseignantSortant from "../volumeHoraire/components/parametres/InvitationEnseignantSortant";
import InvitationEnseignantSortantVp from "../volumeHoraire/components/parametres/InvitationEnseignantSortantVp";
import InvitationEnseignantEntrantVp from "../volumeHoraire/components/parametres/InvitationEnseignantEntrantVp";
import InvitationEnseignantEntrant from "../volumeHoraire/components/parametres/InvitationEnseignantEntrant";
import InvitationEnseignant from "../volumeHoraire/components/parametres/InvitationEnseignant";
import Rang from "../volumeHoraire/components/parametres/Rang";
import BorderoInvitation from "../volumeHoraire/components/parametres/BorderoInvitation";
import BordereauSortantDepartement from "../volumeHoraire/components/parametres/BordereauSortantDepartement";
import ParametrerCompte from "../volumeHoraire/components/parametres/ParametrerCompte";
import BordereauEntrantDepartement from "../volumeHoraire/components/parametres/BordereauEntrantDepartement";
import BordeauEntrant from "../volumeHoraire/components/parametres/BordeauEntrant";
import ParametrerSignataire from "../volumeHoraire/components/parametres/ParametrerSignataire";
import EmploiDuTempsEnseignant from "../volumeHoraire/components/EmploiDuTempsEnseignant";
import NominationVacataire from "../volumeHoraire/components/NominationVacataire";
import DashbordEnseignant from "../volumeHoraire/components/DashbordEnseignant";
import DashbordDepartement from "../volumeHoraire/components/DashbordDepartement";
import DashbordDA from "../volumeHoraire/components/DashbordDA";
import SeanceEffectuerSP from "../volumeHoraire/components/SeanceEffectuerSP";
import SeanceEffectuerDelegue from "../volumeHoraire/components/SeanceEffectuerDelegue";
import ProgrammationDA from "../volumeHoraire/components/ProgrammationDA";
import ProgrammationIes from "../volumeHoraire/components/ProgrammationIes";
import SeanceEffectuerDepartement from "../volumeHoraire/components/SeanceEffectuerDepartement";
import ListeEnseignant from "../volumeHoraire/components/ListeEnseignant";
import SeanceEffectuerMinistere from "../volumeHoraire/components/SeanceEffectuerMinistere";

export const UserConnectedState = createContext();

const VolumeHoraire = (keycloak) => {
    /*
        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)
        //console.log(token.code_departement)
        const structure = JSON.parse(localStorage.getItem('structure'));
    */

    // const token = true

    const tailleEcran = typeof window === "object";
    const largeurEcran = tailleEcran ? window.innerWidth : undefined
    //const hauteurEcran= tailleEcran ? window.innerHeight : undefined
    let ecranF = null
    if(largeurEcran < 768) ecranF = 1   
    else  ecranF = 0;

    const [ecran, setEcran] = useState(ecranF)
    const tokenDecoded = localStorage.getItem("tokenDecoded");
    const token = JSON.parse(tokenDecoded);
    const structure = localStorage.getItem("structure");
    const typeStructure = localStorage.getItem("typeStructure");
    const typeStruct = JSON.parse(typeStructure);
    let roles = token ? token.realm_access.roles : [];
    const struct = JSON.parse(structure);
    let nomStructure = "";
    const [booleanService, setBooleanService] = useState(false);
    const [booleanAcceuil, setBooleanAcceuil] = useState(false);
    const [visible, setvisible] = useState(false);
    if (typeStruct === "departement") {
        nomStructure = struct.lbDepartementEtab;
    } else if (typeStruct === "etablissement") {
        //nomStructure = struct[0].lbDepartementEtab
    } else if (typeStruct === "iesr") {
        //nomStructure = struct[0].lbDepartementEtab
    } else if (typeStruct === "ministere") {
        nomStructure = "MESRSI";
    }
     else {
        nomStructure = "";
    }
    let campusPresident = 0,
        campusVpEip = 0,
        campusDaoi = 0,
        campusDdip = 0;
    let campusDirecteur = 0,
        campusDa = 0,
        campusScolarite = 0,
        campusPresidentJury = 0;
    let campusSecretaireJury = 0,
        campusMemebreJury = 0,
        campusChefDepartement = 0;
    let campusEnseignant = 0,
        campusSP = 0,
        campusDelegue = 0,
        campusDepartement = 0,
        campusDGESup = 0,
        campusMinistere = 0,
        campusEtudiant = 0;
    let chefDepartement = 0,
        chefFinancier = 0,
        notConnected = 0,
        campusSecretaireDepartement = 0;

    roles.forEach((role) => {
        switch (role) {
            case "campus-ministere":
                campusMinistere = 1;
                localStorage.setItem(
                    "campusMinistere",
                    JSON.stringify(campusMinistere)
                );
                break;
            case "campus-enseignant":
                campusEnseignant = 1;
                localStorage.setItem(
                    "campusEnseignant",
                    JSON.stringify(campusEnseignant)
                );
                break;
            case "campus-etudiant":
                campusEtudiant = 1;
                localStorage.setItem(
                    "campusEtudiant",
                    JSON.stringify(campusEtudiant)
                );
                break;
            case "campus-president":
                campusPresident = 1;
                localStorage.setItem(
                    "campusPresident",
                    JSON.stringify(campusPresident)
                );
                break;
            case "campus-vp-eip":
                campusVpEip = 1;
                localStorage.setItem(
                    "campusVpEip",
                    JSON.stringify(campusVpEip)
                );
                break;
            case "campus-daoi":
                campusDaoi = 1;
                localStorage.setItem("campusDaoi", JSON.stringify(campusDaoi));
                break;
            case "campus-ddip":
                campusDdip = 1;
                localStorage.setItem("campusDdip", JSON.stringify(campusDdip));
                break;
            case "campus-directeur":
                campusDirecteur = 1;
                localStorage.setItem(
                    "campusDirecteur",
                    JSON.stringify(campusDirecteur)
                );
                break;
            case "campus-da":
                campusDa = 1;
                localStorage.setItem("campusDa", JSON.stringify(campusDa));
                break;
            case "campus-membre-jury":
                campusMemebreJury = 1;
                localStorage.setItem(
                    "campusMemebreJury",
                    JSON.stringify(campusMemebreJury)
                );
                break;
            case "campus-chef-departement":
                campusChefDepartement = 1;
                localStorage.setItem(
                    "campusChefDepartement",
                    JSON.stringify(campusChefDepartement)
                );
                break;

            case "campus-admin":
                campusDGESup = 1;
                localStorage.setItem(
                    "campusDGESup",
                    JSON.stringify(campusDGESup)
                );
                break;

            case "campus-chef-financier":
                chefFinancier = 1;
                localStorage.setItem(
                    "chefFinancier",
                    JSON.stringify(chefFinancier)
                );
                break;
            case "campus-delegue":
                campusDelegue = 1;
                localStorage.setItem(
                    "campusDelegue",
                    JSON.stringify(campusDelegue)
                );
                break;
            case "campus-secretaire-principal":
                campusSP = 1;
                localStorage.setItem("campusSP", JSON.stringify(campusSP));
                break;
            case "campus-secretaire-departement":
                campusSecretaireDepartement = 1;
                localStorage.setItem(
                    "campusSecretaireDepartement",
                    JSON.stringify(campusSecretaireDepartement)
                );
                break;
            case "campus-admin-departement":
                campusDepartement = 1;
                localStorage.setItem(
                    "campusDepartement",
                    JSON.stringify(campusDepartement)
                );
                break;
            default:
                notConnected = 1;
        }
    });

    /*useEffect(()=>{
        //window.location.hash="/Volume-horaire"
        
    },[]);*/

    useEffect(() => {
        if(campusMinistere){
            console.log("access !")
            window.location.hash =
                "/volume-horaire/dashboard-ministere";
        }else if (campusDa) {
            window.location.hash =
                "/volume-horaire/dashboard-directeur-adjoint";
        } else if (campusDirecteur || campusVpEip) {
            window.location.hash = "/volume-horaire/dashboard";
        } else if (campusDepartement) {
            window.location.hash =
                "/volume-horaire/tableau-de-bord-departement";
        } else if (campusEnseignant) {
            window.location.hash = "/volume-horaire/dashbord-enseignant";
        }
         else if (campusDelegue) {
            window.location.hash = "/volume-horaire/tableau-de-bord";
        } else {
            window.location.hash = "/volume-horaire/tableau-de-bord";
        }
    }, []);

    const itemsEtudiant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/tableau-de-bord";
            },
        },
        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emploi-temps";
            },
        },
        {
            label: "Séances effectuées",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuee";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsFinancier = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
        },
        {
            label: "Facturation heures supplémentaires",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/facture-heure-supplementaire";
            },
        },
        {
            label: "Facturation copies supplémentaires",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/facture-copie-supplementaire";
            },
        },
        {
            label: "Facturation jury de soutenance",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/facture-soutenance";
            },
        },
        {
            label: "Facturation voyage d'étude",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/facture-voyage-etude";
            },
        },
        {
            label: "Facturation primes de recherche",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/facture-prime-recherche";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsDepartementAdmin = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/tableau-de-bord";
            },
        },

        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emplois-du-temps";
            },
        },

        {
            label: "Gestion volume horaire",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Affectation de cours",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/gestion-affectation";
                    },
                },
                {
                    label: "Programmation de séances",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/programmation-activite";
                    },
                },

                {
                    label: "Séances Effectuées",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/seance-effectuer";
                    },
                },

                {
                    label: "fiche pédagogique",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/Gestion-fiches-pedagogique";
                    },
                },
                {
                    label: "Nomination titulaire",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-enseignant/nomination-enseignants";
                    },
                },

                {
                    label: "Nomination vacataire",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-enseignant/nomination-vacataire";
                    },
                },

                {
                    label: "Gestion cohortes",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-infrastructure/cohortes";
                    },
                    /*items: [
                        {
                            label: 'Cohortes cours magistral',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/cohortes"}
                        },
                        {
                            label: 'Cohortes TP/TD',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/cohortes-td-tp"}
                        }
                    ]*/
                },
            ],
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
        {
            label: "Paramètres",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Enseignant",
                    items: [
                        {
                            label: "Enseignant",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/enseignant";
                            },
                        },
                        {
                            label: "Grade",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/grade";
                            },
                        },
                        {
                            label: "Rang",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/rang";
                            },
                        },
                        {
                            label: "Responsabilité",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/responbalite";
                            },
                        },
                        {
                            label: "Spécialité",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/specialite";
                            },
                        },
                        {
                            label: "Discipline",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/discipline";
                            },
                        },
                        {
                            label: "Statut",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/statut";
                            },
                        },
                    ],
                },
                {
                    label: "Infrastructure",
                    items: [
                        {
                            label: "Infrastructure",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Infrastructure";
                            },
                        },
                        {
                            label: "Utilités",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Utilite-infrastructure";
                            },
                        },

                        {
                            label: "Etat actuel",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Etat-infrastructure";
                            },
                        },

                        {
                            label: "Equipement",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Equipement";
                            },
                        },
                        {
                            label: "Type",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Type-infrastructure";
                            },
                        },
                    ],
                },
                {
                    label: "Invitations d'enseignants",
                    items: [
                        /*
                        {
                            label: 'Invitations sortantes',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/invitations-enseignants-sortants"}
                        },*/
                        {
                            label: "Bordereaux entrants",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/bordereaux-entrants-invitation";
                            },
                        },
                        {
                            label: "Bordereaux sortants",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/bordereaux-sortants-invitation";
                            },
                        },
                    ],
                },
                {
                    label: "Responsable infrastructure",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-responsable-infrastructure";
                    },
                },
            ],
        },
    ];

    const itemsDepartementAdminEnseignant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            items: [
                {
                    label: "Département",
                    icon: "pi pi-fw pi-power-offs",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/tableau-de-bord-departement";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-power-offs",
                    //command:()=>{window.location.hash = "/volume-horaire/dashboard"} tableau-de-bord-enseignant

                    command: () => {
                        window.location.hash =
                            "/volume-horaire/tableau-de-bord-enseignant";
                    },
                },
            ],
        },

        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            items: [
                {
                    label: "Département",
                    icon: "pi pi-fw pi-power-offs",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/emplois-du-temps-departement";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-power-offs",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/emploi-du-temps-enseignant";
                    },
                },
            ],
        },

        {
            label: "Gestion volume horaire",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Affectation de cours",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/gestion-affectation";
                    },
                },
                {
                    label: "Programmation de séances",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/programmation-activite";
                    },
                },

                {
                    label: "Séances Effectuées",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/seance-effectuer";
                    },
                },

                {
                    label: "fiche pédagogique",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/Gestion-fiches-pedagogique";
                    },
                },
                {
                    label: "Nomination administrative",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-enseignant/nomination-enseignants";
                    },
                },

                {
                    label: "Nomination vacataire",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-enseignant/nomination-vacataire";
                    },
                },

                {
                    label: "Gestion cohortes",
                    icon: "pi pi-fw pi-align-right",
                    items: [
                        {
                            label: "Cohortes cours magistral",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/cohortes";
                            },
                        },
                        /*{
                            label: 'Cohortes TP/TD',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/cohortes-td-tp"}
                        }*/
                    ],
                },
            ],
        },
        {
            label: "seances-enseignant",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/enseignant-seance-effectuer";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
        {
            label: "Paramètres",
            icon: "pi pi-fw pi-pencils",
            items: [
                {
                    label: "Enseignant",
                    items: [
                        {
                            label: "Enseignant",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/enseignant";
                            },
                        },
                        {
                            label: "Grade",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/grade";
                            },
                        },
                        {
                            label: "Rang",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/rang";
                            },
                        },
                        {
                            label: "Responsabilité",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/responbalite";
                            },
                        },
                        {
                            label: "Spécialité",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/specialite";
                            },
                        },
                        {
                            label: "Discipline",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/discipline";
                            },
                        },
                        {
                            label: "Statut",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-enseignant/statut";
                            },
                        },
                    ],
                },
                {
                    label: "Infrastructure",
                    items: [
                        {
                            label: "Infrastructure",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Infrastructure";
                            },
                        },
                        {
                            label: "Utilités",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Utilite-infrastructure";
                            },
                        },

                        {
                            label: "Etat actuel",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Etat-infrastructure";
                            },
                        },

                        {
                            label: "Equipement",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Equipement";
                            },
                        },
                        {
                            label: "Type",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/gestion-infrastructure/Type-infrastructure";
                            },
                        },
                    ],
                },
                {
                    label: "Invitations d'enseignants",
                    items: [
                        /*
                        {
                            label: 'Invitations sortantes',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/invitations-enseignants-sortants"}
                        },*/
                        {
                            label: "Bordereaux entrants",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/bordereaux-entrants-invitation";
                            },
                        },
                        {
                            label: "Bordereaux sortants",
                            icon: "pi pi-fw pi-align-right",
                            command: () => {
                                window.location.hash =
                                    "/volume-horaire/parametres/bordereaux-sortants-invitation";
                            },
                        },
                    ],
                },
                {
                    label: "Responsable infrastructure",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-responsable-infrastructure";
                    },
                },
            ],
        },
    ];

    const itemsEnseignant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/tableau-de-bord";
            },
        },
        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/enseignant-emploi-du-temps";
            },
        },
        {
            label: "seances-enseignant",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/enseignant-seance-effectuer";
            },
        },
        {
            label: "invitation enseignant",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/parametres/invitations-enseignants";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsDirecteurUfr = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/dashboard";
            },
        },
        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offss",
            command: () => {
                window.location.hash = "/volume-horaire/emplois-du-temps";
            },
        },
        {
            label: "Affectation de cours",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/gestion-affectation";
            },
        },
        {
            label: "Programmation de séances",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/programmation-activite";
            },
        },

        {
            label: "seances-effectuée",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsDA = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/dashboard-directeur-adjoint";
            },
        },
        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emplois-du-temps";
            },
        },
        {
            label: "Affectation de cours",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/gestion-affectation";
            },
        },
        {
            label: "Programmation de séances",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/programmation-activite";
            },
        },

        {
            label: "seances-effectuée",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsDAEnseignant = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            items: [
                {
                    label: "Directeur Adjoint",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/dashboard-directeur-adjoint";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/dashboard-enseignant";
                    },
                },
            ],
        },
        {
            label: "Emploi du temps",
            items: [
                {
                    label: "Directeur Adjoint",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/emplois-du-temps-directeur-adjoint";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/emplois-du-temps-enseignant";
                    },
                },
            ],
        },
        {
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emplois-du-temps";
            },
        },
        {
            label: "Affectation de cours",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/gestion-affectation";
            },
        },
        {
            label: "Programmation de séances",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/programmation-activite";
            },
        },

        {
            label: "seances-effectuée",
            items: [
                {
                    label: "Directeur Adjoint",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/seance-effectuer-directeur-adjoint";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/seance-effectuer-enseignant";
                    },
                },
            ],
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsDelegue = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/tableau-de-bord";
            },
        },
        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emploi-temps";
            },
        },
        {
            label: "Séances effectuées",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash =
                    "/volume-horaire/delegue-seance-effectuer";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];
    // const itemsCampusSP = [
    //     {
    //         label: 'seances',
    //         icon: 'pi pi-fw pi-power-offs',
    //         command:()=>{window.location.hash = "/volume-horaire/secretaire-seance-effectuer"}
    //     },
    // ]

    const itemsDIPEnseignat = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            /*command:()=>{window.location.hash = "/volume-horaire/dashboard"}*/
            items: [
                {
                    label: "Département",
                    icon: "pi pi-fw pi-power-offs",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/tableau-de-bord-departement";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-power-offs",
                    command: () => {
                        window.location.hash = "/volume-horaire/dashboard";
                    },
                },
            ],
        },
        /*{
            label: 'Emploi du temps',
            icon: 'pi pi-fw pi-power-offs',
            command:()=>{window.location.hash = "/volume-horaire/emplois-du-temps"}
        },
        {
            label: "Affectation de cours",
            icon: 'pi pi-fw pi-align-rights',
            command:()=>{window.location.hash = "/volume-horaire/gestion-affectation"}
        },
        {
            label: 'Programmation de séances',
            icon: 'pi pi-fw pi-align-rights',
            command:()=>{window.location.hash = "/volume-horaire/programmation-activite"}
        },*/

        /*{
            label: 'seances-effectuée',
            icon: 'pi pi-fw pi-power-offs',
            items :[
                {
                    label: 'seances-effectuée/dept',
                    command:()=>{window.location.hash = "/volume-horaire/seance-effectuer"}
                },
                {
                    label: 'seances-effectuée',
                    command:()=>{window.location.hash = "/volume-horaire/seance-effectuer-enseignant"}
                },

            ]
            
        },*/

        /*{
            label: 'Invitations d\'enseignants',
            items: [
                    
                        
                        {
                            label: 'Invitations sortantes',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/vp-invitations-enseignants-sortants"}
                        },
                        {
                            label: 'Invitations entrantes',
                            icon: 'pi pi-fw pi-align-right',
                            command:()=>{window.location.hash = "/volume-horaire/parametres/vp-invitations-enseignants-entrants"}
                        }
                        
                    ],   
            
        },*/

        {
            label: "Gestion Infrastructure",
            items: [
                {
                    label: "Infrastructure",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-infrastructure/Infrastructure";
                    },
                },
                {
                    label: "Utilités",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-infrastructure/Utilite-infrastructure";
                    },
                },

                {
                    label: "Etat actuel",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-infrastructure/Etat-infrastructure";
                    },
                },

                {
                    label: "Equipement",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-infrastructure/Equipement";
                    },
                },
                {
                    label: "Type infrastructure",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/gestion-infrastructure/Type-infrastructure";
                    },
                },

                /*{
                    label: 'Hierarchie de compte',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/volume-horaire/parametres/vp-parametrer-compte"}
                },
                {
                    label: 'Signataire',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/volume-horaire/parametres/vp-parametrer-signataire"}
                }*/
            ],
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsVPEIPEnseignat = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            items: [
                {
                    label: "VPEIP",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/dashboard";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/dashboard-enseignant";
                    },
                },
            ],
        },
        {
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emplois-du-temps";
            },
        },
        {
            label: "Affectation de cours",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/gestion-affectation";
            },
        },
        {
            label: "Programmation de séances",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/programmation-activite";
            },
        },

        {
            label: "seances-effectuée",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer";
            },
        },
        {
            label: "Invitations d'enseignants",
            items: [
                {
                    label: "Invitations sortantes",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/vp-invitations-enseignants-sortants";
                    },
                },
                {
                    label: "Invitations entrantes",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/vp-invitations-enseignants-entrants";
                    },
                },
            ],
        },

        {
            label: "Parametrer compte",
            items: [
                {
                    label: "Hierarchie de compte",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/vp-parametrer-compte";
                    },
                },
                {
                    label: "Signataire",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/parametres/vp-parametrer-signataire";
                    },
                },
            ],
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsMinistere = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            items: [
                {
                    label: "Ministere",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/dashboard-ministere";
                    },
                },
                {
                    label: "Enseignant",
                    icon: "pi pi-fw pi-align-right",
                    command: () => {
                        window.location.hash =
                            "/volume-horaire/dashboard-enseignant";
                    },
                },
            ],
        },
        /*{
            label: "Emploi du temps",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/emplois-du-temps";
            },
        },
        {
            label: "Affectation de cours",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/gestion-affectation";
            },
        },*/
        /*{
            label: "Programmation de séances",
            icon: "pi pi-fw pi-align-rights",
            command: () => {
                window.location.hash = "/volume-horaire/programmation-activite";
            },
        },*/

        {
            label: "seances-effectuée",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer-ministere";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsSecretaireDepartement = [
        {
            label: "Nouveaux Bordereaux d'invitation",
            icon: "pi pi-fw pi-align-right",
            command: () => {
                window.location.hash =
                    "/volume-horaire/parametres/bordereau-invitation";
            },
        },
        {
            label: "Bordereaux  d'invitation entrants",
            icon: "pi pi-fw pi-align-right",
            command: () => {
                window.location.hash =
                    "/volume-horaire/parametres/bordereau-entrant";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const itemsCampusSP = [
        {
            label: "Tableau de bord",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/dashboard";
            },
        },
        {
            label: "seances-effectuées",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer";
            },
        },
        {
            label: "Fiche de suivi",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer";
            },
        },
        {
            label: "Fiche programmatique",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/seance-effectuer";
            },
        },
        {
            label: "Liste des enseignants",
            icon: "pi pi-fw pi-power-offs",
            command: () => {
                window.location.hash = "/volume-horaire/liste-enseignants";
            },
        },
    ];

    const CompteCampusSecretaireDepartement = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsSecretaireDepartement} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/parametres/bordereau-invitation"
                        component={BorderoInvitation}
                    />
                    <Route
                        path="/volume-horaire/parametres/bordereau-entrant"
                        component={BordeauEntrant}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusDepartementEnseignant = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDepartementAdminEnseignant} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Utilite-infrastructure"
                        component={UtiliteInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Etat-infrastructure"
                        component={EtatInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Equipement"
                        component={Equipement}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Programmation"
                        component={ProgrammationSeance}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Cohortes"
                        component={Cohortes}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/cohortes-td-tp"
                        component={CohortesTdTp}
                    />

                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Infrastructure"
                        component={Infrastructures}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/type-infrastructure"
                        component={TypeInfrastructure}
                    />

                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Rang"
                        component={Rang}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Grade"
                        component={Grade}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Responbalite"
                        component={Responsabilite}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Enseignant"
                        component={Enseignant}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Statut"
                        component={Statut}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Specialite"
                        component={Specialite}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Discipline"
                        component={Discipline}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Nomination-enseignants"
                        component={NominationEnseignant}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Nomination-vacataire"
                        component={NominationVacataire}
                    />

                    <Route
                        path="/volume-horaire/tableau-de-bord-departement"
                        component={DashbordDepartement}
                    />
                    <Route
                        path="/volume-horaire/Gestion-fiches-pedagogique"
                        component={FichePedagogique}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={Programmation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuerDepartement}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-departement"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/groupe-travaux-diriges_pratiques"
                        component={CohortesTdTp}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/gererReservation"
                        component={ReservationInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/suivi-reservation"
                        component={EtatReservation}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"
                        component={Infrastructures}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-responsable-infrastructure"
                        component={ResponsableInfrastructure}
                    />

                    <Route
                        path="/volume-horaire/parametres/invitations-enseignants-sortants"
                        component={InvitationEnseignantSortant}
                    />
                    <Route
                        path="/volume-horaire/parametres/invitations-enseignants-entrants"
                        component={InvitationEnseignantEntrant}
                    />
                    <Route
                        path="/volume-horaire/parametres/bordero-invitation"
                        component={BorderoInvitation}
                    />
                    <Route
                        path="/volume-horaire/parametres/bordereaux-sortants-invitation"
                        component={BordereauSortantDepartement}
                    />

                    <Route
                        path="/volume-horaire/parametres/bordereaux-entrants-invitation"
                        component={BordereauEntrantDepartement}
                    />
                    {
                        <Route
                            path="/volume-horaire/tableau-de-bord-enseignant"
                            component={DashbordEnseignant}
                        />
                    }
                    {/*<Route path="/volume-horaire/dashboard" component={Dashbord} />*/}
                    <Route
                        path="/volume-horaire/enseignant-seance-effectuer"
                        component={() =>
                            SeanceEffectuer({ role: "campus-enseignant" })
                        }
                    />
                    <Route
                        path="/volume-horaire/parametres/invitations-enseignants"
                        component={InvitationEnseignant}
                    />
                    <Route
                        path="/volume-horaire/emploi-du-temps-enseignant"
                        component={EmploiDuTempsEnseignant}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusDirecteurUfr = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDirecteurUfr} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/dashboard"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuerDA}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationDA}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusDaUfr = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDA} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/dashboard-directeur-adjoint"
                        component={DashbordDA}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuerDA}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationDA}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Programmation"
                        component={ProgrammationSeance}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusDaEnseignant = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDAEnseignant} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/dashboard-directeur-adjoint"
                        component={DashbordDA}
                    />
                    <Route
                        path="/volume-horaire/dashboard-enseignant"
                        component={DashbordEnseignant}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer-directeur-adjoint"
                        component={SeanceEffectuerDA}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer-enseignant"
                        component={SeanceEffectuerEnseignant}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-directeur-adjoint"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-enseignant"
                        component={EmploiDuTempsEnseignant}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationDA}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusEnseignantVPEIP = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsVPEIPEnseignat} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/parametres/vp-invitations-enseignants-sortants"
                        component={InvitationEnseignantSortantVp}
                    />
                    <Route
                        path="/volume-horaire/parametres/vp-invitations-enseignants-entrants"
                        component={InvitationEnseignantEntrantVp}
                    />

                    <Route
                        path="/volume-horaire/dashboard"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/dashboard-enseignant"
                        component={DashbordEnseignant}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuer}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationIes}
                    />

                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Programmation"
                        component={ProgrammationSeance}
                    />

                    <Route
                        path="/volume-horaire/parametres/vp-parametrer-compte"
                        component={ParametrerCompte}
                    />
                    <Route
                        path="/volume-horaire/parametres/vp-parametrer-signataire"
                        component={ParametrerSignataire}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />

                    <Route
                        path="/volume-horaire/seance-effectuer-enseignant"
                        component={SeanceEffectuerEnseignant}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-directeur-adjoint"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-enseignant"
                        component={EmploiDuTempsEnseignant}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationDA}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusEnseignantMinistere = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsMinistere} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/parametres/vp-invitations-enseignants-sortants"
                        component={InvitationEnseignantSortantVp}
                    />
                    <Route
                        path="/volume-horaire/parametres/vp-invitations-enseignants-entrants"
                        component={InvitationEnseignantEntrantVp}
                    />

                    <Route
                        path="/volume-horaire/dashboard"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuer}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer-ministere"
                        component={SeanceEffectuerMinistere}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationIes}
                    />

                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Programmation"
                        component={ProgrammationSeance}
                    />

                    <Route
                        path="/volume-horaire/parametres/vp-parametrer-compte"
                        component={ParametrerCompte}
                    />
                    <Route
                        path="/volume-horaire/parametres/vp-parametrer-signataire"
                        component={ParametrerSignataire}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />

                    <Route
                        path="/volume-horaire/seance-effectuer-enseignant"
                        component={SeanceEffectuerEnseignant}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-directeur-adjoint"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-enseignant"
                        component={EmploiDuTempsEnseignant}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={ProgrammationDA}
                    />
                    <Route
                        path="/volume-horaire/dashboard-ministere"
                        component={DashboardMinistere} 
                    />
                    <Route
                        path="/volume-horaire/dashboard-enseignant"
                        component={DashbordEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusEnseignantDIP = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDIPEnseignat} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Infrastructure"
                        component={Infrastructures}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/type-infrastructure"
                        component={TypeInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Utilite-infrastructure"
                        component={UtiliteInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Etat-infrastructure"
                        component={EtatInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Equipement"
                        component={Equipement}
                    />

                    <Route
                        path="/volume-horaire/parametres/vp-invitations-enseignants-sortants"
                        component={InvitationEnseignantSortantVp}
                    />
                    <Route
                        path="/volume-horaire/parametres/vp-invitations-enseignants-entrants"
                        component={InvitationEnseignantEntrantVp}
                    />

                    <Route
                        path="/volume-horaire/dashboard"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuer}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Programmation"
                        component={ProgrammationSeance}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />

                    <Route
                        path="/volume-horaire/seance-effectuer-enseignant"
                        component={SeanceEffectuerEnseignant}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps-enseignant"
                        component={EmploiDuTempsEnseignant}
                    />
                    <Route
                        path="/volume-horaire/tableau-de-bord-departement"
                        component={DashbordDepartement}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    if (booleanAcceuil) {
        return <>{<Connexion keyLmd={1} keycloakCon={keycloak.keycloak} />}</>;
    }

    const campusDepartementMain = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDepartementAdmin} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Utilite-infrastructure"
                        component={UtiliteInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Etat-infrastructure"
                        component={EtatInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Equipement"
                        component={Equipement}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Programmation"
                        component={ProgrammationSeance}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Cohortes"
                        component={Cohortes}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/cohortes-td-tp"
                        component={CohortesTdTp}
                    />

                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/Infrastructure"
                        component={Infrastructures}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/type-infrastructure"
                        component={TypeInfrastructure}
                    />

                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Rang"
                        component={Rang}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Grade"
                        component={Grade}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Responbalite"
                        component={Responsabilite}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Enseignant"
                        component={Enseignant}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Statut"
                        component={Statut}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Specialite"
                        component={Specialite}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Discipline"
                        component={Discipline}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Nomination-enseignants"
                        component={NominationEnseignant}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-enseignant/Nomination-vacataire"
                        component={NominationVacataire}
                    />

                    <Route
                        path="/volume-horaire/tableau-de-bord"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/Gestion-fiches-pedagogique"
                        component={FichePedagogique}
                    />
                    <Route
                        path="/volume-horaire/Gestion-affectation"
                        component={Affectation}
                    />
                    <Route
                        path="/volume-horaire/programmation-activite"
                        component={Programmation}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuerDepartement}
                    />
                    <Route
                        path="/volume-horaire/emplois-du-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/groupe-travaux-diriges_pratiques"
                        component={CohortesTdTp}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/gererReservation"
                        component={ReservationInfrastructure}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/suivi-reservation"
                        component={EtatReservation}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"
                        component={Infrastructures}
                    />
                    <Route
                        path="/volume-horaire/parametres/gestion-responsable-infrastructure"
                        component={ResponsableInfrastructure}
                    />

                    <Route
                        path="/volume-horaire/parametres/invitations-enseignants-sortants"
                        component={InvitationEnseignantSortant}
                    />
                    <Route
                        path="/volume-horaire/parametres/invitations-enseignants-entrants"
                        component={InvitationEnseignantEntrant}
                    />
                    <Route
                        path="/volume-horaire/parametres/bordero-invitation"
                        component={BorderoInvitation}
                    />
                    <Route
                        path="/volume-horaire/parametres/bordereaux-sortants-invitation"
                        component={BordereauSortantDepartement}
                    />

                    <Route
                        path="/volume-horaire/parametres/bordereaux-entrants-invitation"
                        component={BordereauEntrantDepartement}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const etudiant = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEtudiant} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/tableau-de-bord"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/emploi-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuer}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const chefFinancierMain = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsFinancier} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/facture-heure-supplementaire"
                        component={FactureHeureSupp}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusDelegueMain = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsDelegue} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/tableau-de-bord"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/emploi-temps"
                        component={EmploisDuTemps}
                    />
                    <Route
                        path="/volume-horaire/delegue-seance-effectuer"
                        component={SeanceEffectuerDelegue}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    /*const campusDelegueMain = (
        <main className="main">
            { <div className="cards titles" >
                <Menubar model={itemsEtudiant} />
            </div> }
            <div>
                <UserConnectedState.Provider value={structure}>
                    <SeanceEffectuerDelegue/>
                </UserConnectedState.Provider>  
            </div>  
        </main>
    )*/

    const campusEnseignantMain = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsEnseignant} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    <Route
                        path="/volume-horaire/enseignant-seance-effectuer"
                        component={SeanceEffectuerEnseignant}
                    />
                    <Route
                        path="/volume-horaire/parametres/invitations-enseignants"
                        component={InvitationEnseignant}
                    />
                    <Route
                        path="/volume-horaire/enseignant-emploi-du-temps"
                        component={EmploiDuTempsEnseignant}
                    />
                    <Route
                        path="/volume-horaire/tableau-de-bord"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    const campusSPMain = (
        <main className="main">
            <div className="cards titles">
                <Menubar model={itemsCampusSP} />
            </div>
            <div>
                <UserConnectedState.Provider value={structure}>
                    {/* <SeanceEffectuerSP/> */}
                    <Route
                        path="/volume-horaire/dashboard"
                        component={Dashbord}
                    />
                    <Route
                        path="/volume-horaire/seance-effectuer"
                        component={SeanceEffectuerSP}
                    />
                    <Route
                        path="/volume-horaire/liste-enseignants"
                        component={ListeEnseignant}
                    />
                </UserConnectedState.Provider>
            </div>
        </main>
    );

    if (booleanService) {
        return (
            <>{<MenuHome clickService={true} keycloak={keycloak.keycloak} />}</>
        );
    }
    return (
        <div className="html">
            <div className="body">
                {token ? (
                    <div className="grid-container">
                        <header className="header" style={{"backgroundColor" : "#c3e7d1"}}>
                            <div className="logo p-col-3">
                                <a
                                    className="navbar-brand link-menu scroll-to-target current"
                                    href="/"
                                >
                                    <img
                                        src={ecran ? "/assets/layout/images/cf_bullet.svg" : "/assets/layout/images/CampusFaso_Logo.png"}
                                        alt="CampusFaso"
                                    />
                                </a>
                            </div>
                            <div className="p-col-4 titlePi">
                                <p
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        //marginLeft: -500,
                                        fontStyle: "italic",
                                    }}
                                >
                                    Système de gestion des Volumes Horaires
                                </p>
                            </div>
                            {token ? (
                                <div className="header-text">
                                    <i className="ti-user"></i>
                                    Bienvenue{" "}
                                    <span style={{ color: "green" }}>
                                        {token.name}{" "}
                                    </span>
                                    {" ( " + nomStructure + " ) "}|
                                    <span style={{cursor:"pointer", marginLeft : 10}}
                                        className="btns"
                                        onClick={() => {
                                            keycloak.keycloak.logout();
                                            localStorage.clear();
                                        }}
                                    >
                                        <i className="ti-close"></i>
                                        Déconnexion
                                    </span>{" "}
                                    |
                                    <span style={{cursor:"pointer", marginLeft : 10}}
                                        className="btns"
                                        onClick={() => {
                                            setBooleanService(true);
                                        }}
                                    >
                                        <i className="ti-homes"></i>
                                        Services
                                    </span>
                                    <span
                                        className="btns" style={{cursor:"pointer", marginLeft : 10}}
                                        onClick={() => {
                                            setBooleanAcceuil(true);
                                        }}
                                    >
                                        <i className="ti-home"></i>
                                        Accueil
                                    </span>
                                </div>
                            ) : (
                                ""
                            )}
                        </header>
                        <aside className="sidenav">
                            <div className="logo">
                                <img src={logoCampusFaso} alt="" />
                                <div className="txt">
                                    Plateforme intégrée de <br />
                                    l’enseignement supérieur <br />
                                    et de la recherche
                                </div>
                            </div>
                        </aside>
                        {campusEnseignant !== 0 && campusMinistere !== 0 && campusEnseignantMinistere}
                        {campusEnseignant !== 0 &&
                            campusDa !== 0 &&
                            campusDaEnseignant}
                        {campusEnseignant !== 0 &&
                            campusDepartement !== 0 &&
                            campusDepartementEnseignant}
                        {campusEnseignant !== 0 &&
                            campusDirecteur !== 0 &&
                            campusDirecteurUfr}
                        {campusEnseignant !== 0 &&
                            campusVpEip !== 0 &&
                            campusEnseignantVPEIP}
                        {campusEnseignant !== 0 &&
                            campusDdip !== 0 &&
                            campusEnseignantDIP}
                        {campusEnseignant !== 0 &&
                            campusVpEip === 0 &&
                            campusDdip === 0 &&
                            campusDa === 0 &&
                            campusDirecteur === 0 &&
                            campusDepartement === 0 &&
                            campusMinistere === 0 &&
                            campusEnseignantMain}
                        {(campusEtudiant !== 0 && campusDelegue === 0) && etudiant}

                        {chefFinancier !== 0 && chefFinancierMain}

                        {(campusDelegue !== 0  && campusEtudiant !== 0) && campusDelegueMain}

                        {campusSP !== 0 && campusSPMain}

                        {campusSecretaireDepartement !== 0 &&
                            CompteCampusSecretaireDepartement}

                        {notConnected && ""}

                        <div className="footer">
                            <ul className="liens">
                                <li>
                                    <a href="">CGU</a>
                                </li>
                                <li>
                                    <a href="">Politique de confidentialité</a>
                                </li>
                                <li>
                                    <a href="">FAQ</a>
                                </li>
                            </ul>
                            <p>
                                &copy; 2021 &middot; MESRSI &middot;Tous droits
                                réservés
                            </p>
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            <Connexion
                                keyLmd={1}
                                keycloakCon={keycloak.keycloak}
                            />
                        }
                    </>
                )}
            </div>
        </div>
    );
};

export default VolumeHoraire;
