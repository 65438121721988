import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { TypeUEService } from '../../service/TypeUEService';
import { Dropdown } from 'primereact/dropdown';

export class TypeUniteEnseignement extends Component {

    constructor(props){
        super(props);

        //Model de l'objet type unite enseignement
        let emptyTypeUniteEnseignement = {
            idTypeUniteEnseignement: null,
            libelleTypeUniteEnseignement: null,
            typeUniteEnseignement: null
        }

        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelTypeUE = new TypeUEService();

        this.state = {
            curentTypeUniteEnseignement: emptyTypeUniteEnseignement,
            typeUEList: [],
            redirect : false,
            loading : false,
            globalFilter: null,
            viewCreateTypeUE: false,
            allowSave: false,
            viewDeleteTypeUEDialog: false,
            deletionId: null,
            parentError: null
        }
    }

    componentDidMount(){
        //Temoin de chargement
        this.setState({loading : true})
        this.ModelTypeUE.getAll().then(data => {
                this.setState({
                    typeUEList: data,
                    loading : false
                })
            }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)  
            console.log(error)
            this.setState({loading : false})
        })
    }


    
    //Gestion de l'ouverture du formulaire
    openNew = () => {
        this.setState(
            {
                viewCreateTypeUE: true
            }
        )
    }

    hideDialog = () => {
        //Model de l'objet type unite enseignement
        let emptyTypeUniteEnseignement = {
            idTypeUniteEnseignement: null,
            libelleTypeUniteEnseignement: null,
            typeUniteEnseignement: null
        }

        this.setState({
            curentTypeUniteEnseignement: emptyTypeUniteEnseignement,
            viewCreateTypeUE: false
        })
    }

    onInputChange = (event, field) => {

        let curentTypeUniteEnseignement = this.state.curentTypeUniteEnseignement
        
        curentTypeUniteEnseignement[`${field}`] = event.target.value
        
        //Il faut s'assurer que le type unite d'enseignement parent n'est pas le même que celui que l'on edite 
        if(field==='typeUniteEnseignement'){
            
            if(curentTypeUniteEnseignement.idTypeUniteEnseignement===curentTypeUniteEnseignement.typeUniteEnseignement.idTypeUniteEnseignement){
                //curentTypeUniteEnseignement['typeUniteEnseignement'] = null
                this.setState({
                    parentError: "L'unité d'enseignement parent doit être different de l'unité d'enseignement concerné !!!",
                })
            }else{
                this.setState({
                    parentError: null
                })
            }

        }

        let allowSave = this.testAllowSave(curentTypeUniteEnseignement);
        this.setState({
            curentTypeUniteEnseignement: curentTypeUniteEnseignement,
            allowSave: allowSave
        })
    }

    editTypeUniteEnseignement = (rowData) => {

        let allowSave = this.testAllowSave(rowData)

        this.setState({
            curentTypeUniteEnseignement: rowData,
            viewCreateTypeUE: true,
            allowSave: allowSave
        })
    }

    testAllowSave = (typeUniteEnseignement) =>{

        let goodParent = (
            (typeUniteEnseignement.typeUniteEnseignement===null || typeUniteEnseignement.typeUniteEnseignement===undefined )
            || ( typeUniteEnseignement.typeUniteEnseignement!==null && typeUniteEnseignement.idTypeUniteEnseignement!==typeUniteEnseignement.typeUniteEnseignement.idTypeUniteEnseignement)
        )? true:false;

        return (goodParent && typeUniteEnseignement.libelleTypeUniteEnseignement!==null && (typeUniteEnseignement.libelleTypeUniteEnseignement.trim()).length>=5);

    }



    saveTypeUniteEnseignement = () => {
        
        let newTypeUniteEnseignement = {
            idTypeUniteEnseignement: parseInt(this.state.curentTypeUniteEnseignement.idTypeUniteEnseignement),
            libelleTypeUniteEnseignement: this.state.curentTypeUniteEnseignement.libelleTypeUniteEnseignement,
            idTypeUniteEnseignementParent: (this.state.curentTypeUniteEnseignement.typeUniteEnseignement!==null && this.state.curentTypeUniteEnseignement.typeUniteEnseignement!==undefined )? this.state.curentTypeUniteEnseignement.typeUniteEnseignement.idTypeUniteEnseignement: null
        }

        //console.log(newTypeUniteEnseignement)

        
        //On peut proceder à la mise à jour ou à l'ajout du type 
        if(newTypeUniteEnseignement.idTypeUniteEnseignement){
            
            this.ModelTypeUE.update(newTypeUniteEnseignement.idTypeUniteEnseignement, newTypeUniteEnseignement).then(data => {
                this.notify.success()
                this.setState({
                    viewCreateTypeUE: false,
                })


                //Temoin de chargement
                this.setState({loading : true})
                this.ModelTypeUE.getAll().then(data => {
                        this.setState({
                            typeUEList: data,
                            loading : false
                        })
                    }
                )
                .catch(error => {
                    console.log(error)
                    this.setState({loading : false})
                })


            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }else{

            this.ModelTypeUE.create(newTypeUniteEnseignement).then(data => {
                this.notify.success()
                this.setState({
                    viewCreateTypeUE: false,
                })

                //Temoin de chargement
                this.setState({loading : true})
                this.ModelTypeUE.getAll().then(data => {
                        this.setState({
                            typeUEList: data,
                            loading : false
                        })
                    }
                )
                .catch(error => {
                    console.log(error)
                    this.setState({loading : false})
                })


            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }
    }

    confirmDeleteTypeUniteEnseignement = (rowData) => {

        this.setState(
            {
                deletionId: rowData.idTypeUniteEnseignement,
                viewDeleteTypeUEDialog: true
            }
        )
    }

    deleteTypeUniteEnseignement = (idTypeUE) => {

        this.ModelTypeUE.delete(parseInt(idTypeUE)).then(data =>{
            this.notify.success()
            this.setState({
                viewDeleteTypeUEDialog: false,
                deletionId: null
            })

            //Temoin de chargement
            this.setState({loading : true})
            this.ModelTypeUE.getAll().then(data => {
                    this.setState({
                        typeUEList: data,
                        loading : false
                    })
                }
            )
            .catch(error => { 
                console.log(error)
                this.setState({loading : false})
            })

        }).catch(error=>{
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({
                viewDeleteTypeUEDialog: false
            })
        })
    }

    hideDeleteUniteEnseignementDialog = () => {

        this.setState(
            {
                viewDeleteTypeUEDialog: false,
                deletionId: null
            }
        )
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeUniteEnseignement(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeUniteEnseignement(rowData)} />
            </React.Fragment>
        );
    }

    
    render() {

        //console.log(this.state.typeUEList)
        if(this.state.redirect) {
            //window.location.reload();
        }

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

       const deleteTypeUEDialogFooter = (
        <React.Fragment>
            <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteUniteEnseignementDialog} />
            <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeUniteEnseignement(this.state.deletionId) } />
        </React.Fragment>
        )

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        let allowSave = (this.state.allowSave)? 
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeUniteEnseignement} />):
        (<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />);
        const typeUniteDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {allowSave}
            </React.Fragment>
        );
   
        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <DataTable  ref={(el) => this.dt = el} value={this.state.typeUEList} selectionMode="single" header={`Liste des types d'UE (${this.state.typeUEList.length})`} paginator={true} rows={10}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                {/*<Column field="idTypeUniteEnseignement" header="Id type unite enseignement"  sortable={true} filter={true} filterPlaceholder="rechercher identifiant"/>*/}
                                <Column field="libelleTypeUniteEnseignement" header="Libellé type unité enseignement"  sortable={true} filter={true} filterPlaceholder="rechercher type unité enseignement"/>
                                <Column field="libelleTypeUniteEnseignementParent" header="Libelle type unité enseignement parent"  sortable={true} filter={true} filterPlaceholder="rechercher parent type unité enseignement"/>
                                <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>

                
                <Dialog visible={this.state.viewCreateTypeUE} style={{ width: '450px' }} header="Informations du type d'unité d'enseignement" modal className="p-fluid" footer={typeUniteDialogFooter} onHide={this.hideDialog}>
                
                    <div className="p-field">
                        <label htmlFor="libelleTypeUniteEnseignement">Libellé type unité d'enseignement</label>
                        <InputText id="libelleTypeUniteEnseignement" value={this.state.curentTypeUniteEnseignement.libelleTypeUniteEnseignement}  onChange={(e) => this.onInputChange(e, 'libelleTypeUniteEnseignement')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.curentTypeUniteEnseignement.libelleTypeUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>

                    <div className="p-field">
                        <label htmlFor="libelleuniteEnseignement">Selectionner le type unite parent</label>
                        <Dropdown optionLabel="libelleTypeUniteEnseignement" optionValue={this.state.curentTypeUniteEnseignement.typeUniteEnseignement} value={this.state.curentTypeUniteEnseignement.typeUniteEnseignement} options={this.state.typeUEList} onChange={(e) => {this.onInputChange(e, 'typeUniteEnseignement')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner un UE parent"/>
                        {this.state.parentError!==null && <small className="p-invalid" style={{color : "red"}}>{this.state.parentError}</small>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.viewDeleteTypeUEDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeUEDialogFooter} onHide={this.hideDeleteUniteEnseignementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                

            </div> 
        )

}

}

export default TypeUniteEnseignement
