import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { ECService } from "../service/ECService";
import { ExamenService } from "../service/ExamenService";
import { ParamNoteService } from "../service/ParamNoteService";
import { ParcoursUEService } from "../service/ParcoursUEService";
import { SessionExamenService } from "../service/SessionExamenService";
import { TypeActiviteService } from "../service/TypeActiviteService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import "../../css/style.css";

export class Soutenances extends Component {
    emptyExamen = {
        idExamen: null,
        libelleExamen: null,
        dureeExamen: null,
        intituleExamen: null,
        dateExamen: null,
        heureExamen: null,
        observationExamen: null,
        ec: null,
        ue: null,
        paramNote: null,
        typeActivite: null,
        session: null,
    };

    emptyFile = {
        fichier: null,
        nomFichier: null,
        note: "",
    };

    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.examenService = new ExamenService();
        this.elementConstitutifService = new ECService();
        this.sessionService = new SessionExamenService();
        this.TypeActiviteService = new TypeActiviteService();
        this.paramNoteService = new ParamNoteService();
        this.parcoursUEService = new ParcoursUEService();
        this.cohorteServices = new CohorteServices();

        this.state = {
            listeExamen: [],
            listeSession: [],
            listeUe: [],
            listeEc: [],
            listeParamNote: [],
            listeEtudiantSoutenance: [],
            listeEtudiantEc: [],
            examen: this.emptyExamen,
            session: null,
            submitted: false,
            redirect: false,
            loading: false,
            loadingListe: false,
            globalFilter: null,
            globalFilterListeExamen: null,
            examenDialog: false,
            sessionDialog: false,
            deleteExamenDialog: false,
            listeExamenDialog: false,
            listeTitle: "",
            selectEtudiantDialog: false,
            loadingListeEtudiant: false,
            importNoteDialog: false,
            importFichier: this.emptyFile,
            noteSoutenance: "",
            errorNote: "",
            errorFile: "",
            fichierActu: null,
            nomFichier: "",
            verifeEnseignant: false,
            verifeDept: false,
            verifeEtab: false,
            isDGESup: false,
            isVp: false,
            isEnseignant: false,
            isEtudiant: false,
            isDepartement: false,
            isDirecteur: false,
            isDA: false,
            editionTaux: false,
            verifTaux: false,
            messageTaux: "",
        };
    }

    componentDidMount() {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        const campusDGESup = localStorage.getItem("campusDGESup");
        const campusVpEip = localStorage.getItem("campusVpEip");
        const campusEnseignant = localStorage.getItem("campusEnseignant");
        const campusEtudiant = localStorage.getItem("campusEtudiant");
        const campusDirecteur = localStorage.getItem("campusDirecteur");
        const campusDa = localStorage.getItem("campusDa");
        const campusDepartement = localStorage.getItem("campusDepartement");
        const isDGESup = parseInt(JSON.parse(campusDGESup));
        const isVp = parseInt(JSON.parse(campusVpEip));
        const isEnseignant = parseInt(JSON.parse(campusEnseignant));
        const isEtudiant = parseInt(JSON.parse(campusEtudiant));
        const isDepartement = parseInt(JSON.parse(campusDepartement));
        const isDirecteur = parseInt(JSON.parse(campusDirecteur));
        const isDA = parseInt(JSON.parse(campusDa));

        this.setState({
            loading: true,
            token: token,
            isDepartement: isDepartement,
            verifeDept: isDepartement,
            verifeEtab: isDirecteur || isDA,
            isDA: isDA,
            isDirecteur: isDirecteur,
            isVp: isVp,
        });

        this.setState({
            loading: true,
            idDepartement: token.code_departement,
        });

        if (isDA || isDirecteur) {
            this.setState({ verifeEtab: true });
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    const depts = data.departementEtabDtos;
                    this.setState({ listeDepartement: depts, loading: false });
                });
        } else if (isDepartement) {
            this.sessionService
                .getSessionDepartement(token.code_departement)
                .then((data) => {
                    let listeSessionOuvert = data.data.filter(
                        (curentSession) => {
                            return curentSession.etatSession === "OUVERT";
                        }
                    );
                    this.setState({
                        listeSession: listeSessionOuvert,
                        loading: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                });
        }

        this.TypeActiviteService.getAll()
            .then((data) => {
                this.setState({ listeTypeActivite: data });
            })
            .catch((error) => {
                console.log(error);
            });

        this.paramNoteService
            .getAll()
            .then((data) => {
                this.setState({
                    listeParamNote: data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onDepartementChange = (e) => {
        const val = (e.target && e.target.value) || "";
        this.setState({ departement: val });
        this.setState({ loading: true });
        this.sessionService
            .getSessionDepartement(val.idDepartementEtab)
            .then((data) => {
                //On filtre uniquement les sessions qui sont ouvert
                let listeSessionOuvert = data.data.filter((curentSession) => {
                    return curentSession.etatSession === "OUVERT";
                });
                this.setState({
                    listeSession: listeSessionOuvert,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({ loading: false });
            });
    };

    openNew = () => {
        this.setState({
            examen: this.emptyExamen,
            submitted: false,
            sessionDialog: true,
        });
    };

    afficheExamen = (session) => {
        this.setState({
            examenDialog: true,
            session: session,
        });

        let idParcours = session.parcoursFiliere.idParcours;
        let idSemestre = session.anneeSemestre.idSemestre;
        this.parcoursUEService.get(idParcours, idSemestre).then((data) => {
            this.setState({
                listeUe: data,
            });
        });
    };

    hideTauxDialog = () => {
        if (this.state.verifTaux) {
            this.setState({
                editionTaux: false,
                messageTaux: "",
            });
        } else {
            this.setState({
                editionTaux: false,
                submitted: false,
                examenDialog: false,
                examen: {
                    idExamen: null,
                    libelleExamen: null,
                    heureFinExamen: null,
                    intituleExamen: null,
                    dateExamen: null,
                    heureExamen: null,
                    observationExamen: null,
                    ec: null,
                    ue: null,
                    paramNote: null,
                    typeActivite: null,
                    session: null,
                },
                verifTaux: false,
                messageTaux: "",
                listeExamenEc: [],
            });
        }
    };

    validerTaux = () => {
        let totalTaux = 0;
        if (this.state.examen.paramNote) {
            //On fait l'adition des taux pour s'assurer que ça ne va pas au délà de 100%
            totalTaux =
                totalTaux + parseInt(this.state.examen.paramNote.valeurParam);
            this.state.listeExamenEc.map((examen) => {
                totalTaux = totalTaux + parseInt(examen.paramNote.valeurParam);
            });

            if (parseInt(totalTaux) !== 100) {
                this.setState({
                    messageTaux:
                        "Revoir les taux car la somme des taux est différente de 100% !!!",
                });
            } else {
                this.setState({
                    verifTaux: true,
                    editionTaux: false,
                });
            }
        } else {
            this.setState({
                messageTaux:
                    "Veuillez selectionner un taux de notation pour le nouveau examen !!!",
            });
        }
    };

    onListeExamenTauxChange = (value, valueIndex) => {
        let listeExamenEc = this.state.listeExamenEc;
        listeExamenEc[valueIndex].paramNote = value;

        this.setState({
            listeExamenEc: listeExamenEc,
        });
    };

    inputTauxEditor = (props) => {
        return (
            <Dropdown
                optionLabel="valParam"
                optionValue="idParamNote"
                value={props.rowData.paramNote}
                options={this.state.listeParamNote}
                onChange={(e) => {
                    this.onListeExamenTauxChange(
                        e.target.value,
                        props.rowIndex
                    );
                }}
                required
                autoFocus
                className={classNames({ "p-invalid": this.state.submitted })}
                placeholder="Selectionner le taux"
            />
        );
    };

    editTauxExamen = (props) => {
        return this.inputTauxEditor(props);
    };

    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        let examen = this.state.examen;
        examen[`${field}`] = val;
        this.setState({ examen });
        if (field === "ue") {
            this.setState({
                listeEc: val.uniteEnseignement.listeElementConstitutif,
            });
        }

        if (field === "ec") {
            this.setState({
                verifTaux: true,
                listeExamenEc: [],
            });

            /*
            this.examenService.getListeExamenByEc(this.state.session.idSession, examen.ec.idElementConstitutif).then(data => {

                if(data.length>0){

                    this.setState({
                        listeExamenEc: data,
                        editionTaux: true,
                        verifTaux: false
                    })
                }else{

                    this.setState({
                        verifTaux: true,
                        listeExamenEc: []
                    })
                }

            }).catch(error => {
                console.log(error);
                this.notify.echec(error.response.data.message)
            })
            */
        }
    };

    hideDialog = () => {
        this.setState({
            submitted: false,
            listeEtudiantEc: [],
            listeEtudiantSoutenance: [],
            examen: {
                idExamen: null,
                libelleExamen: null,
                dureeExamen: null,
                intituleExamen: null,
                dateExamen: null,
                heureExamen: null,
                observationExamen: null,
                ec: null,
                ue: null,
                paramNote: null,
                typeActivite: null,
                session: null,
            },
            examenDialog: false,
        });
    };

    hideImportDialog = () => {
        this.setState({
            submitted: false,
            listeEtudiantEc: [],
            listeEtudiantSoutenance: [],
            examen: {
                idExamen: null,
                libelleExamen: null,
                dureeExamen: null,
                intituleExamen: null,
                dateExamen: null,
                heureExamen: null,
                observationExamen: null,
                ec: null,
                ue: null,
                paramNote: null,
                typeActivite: null,
                session: null,
            },
            importNoteDialog: false,
        });
    };

    hideSelectEtudiant = () => {
        this.setState({
            selectEtudiantDialog: false,
        });
    };

    hideSessionDialog = () => {
        this.setState({
            sessionDialog: false,
        });
    };

    hideListeExamenDialog = () => {
        this.setState({
            listeExamenDialog: false,
        });
    };

    hideDeleteExamenDialog = () => {
        this.setState({ deleteExamenDialog: false });
    };

    confirmDeleteExamen = (examen) => {
        this.setState({
            examen: examen,
            deleteExamenDialog: true,
        });
    };

    noterSoutenance = (soutenance) => {
        this.setState({
            examen: soutenance,
            importNoteDialog: true,
        });

        this.examenService
            .getListeEtudiantSoutenance(soutenance.idExamen)
            .then((data) => {
                this.setState({
                    listeEtudiantSoutenance: data,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        //On recupère le fichier de la soutenance en question si celle-ci existe
        this.examenService
            .getFileSoutenance(this.state.idDepartement, soutenance.idExamen)
            .then((data) => {
                let importFichier = {
                    fichier: data.fichier ? data.fichier : "",
                    nomFichier: data.nomFichier ? data.nomFichier : "",
                    note: data.note ? data.note : "",
                };
                this.setState({
                    fichierActu: data.fichier,
                    nomFichier: data.nomFichier,
                    importFichier: importFichier,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    selectionEtudiant = (etudiant) => {
        let listeEtudiantConcerne = this.state.listeEtudiantSoutenance;
        //On parcours la liste pour voir si celui qu'on a selectionné n'est pas déjà dans la liste
        let listeIdEtudiantConcerne = listeEtudiantConcerne.map(
            (etudiantActu) => {
                return etudiantActu.idEtudiant;
            }
        );

        //Si déjà selectionnée, pas bésoin d'ajouté
        if (listeIdEtudiantConcerne.indexOf(etudiant.idEtudiant) === -1) {
            listeEtudiantConcerne.push(etudiant);
        }

        //On actualise la liste
        this.setState({
            listeEtudiantSoutenance: listeEtudiantConcerne,
        });
    };

    removeEtudiant = (etudiant) => {
        let listeEtudiantConcerne = this.state.listeEtudiantSoutenance;

        let nouvelleListeEtudiantSoutenance = listeEtudiantConcerne.filter(
            (etudiantActu) => {
                return etudiantActu.idEtudiant !== etudiant.idEtudiant;
            }
        );

        this.setState({
            listeEtudiantSoutenance: nouvelleListeEtudiantSoutenance,
        });
    };

    selectEtudiantSoutenance = () => {
        this.setState({
            submitted: true,
        });

        if (
            this.state.session &&
            this.state.examen.ue &&
            this.state.examen.ec &&
            this.state.examen.typeActivite &&
            this.state.examen.dateExamen &&
            this.state.examen.dateExamen.trim()
        ) {
            this.setState({
                selectEtudiantDialog: true,
                loadingListeEtudiant: true,
            });

            this.sessionService
                .getListeEtudiantEcSession(
                    this.state.session.idSession,
                    this.state.examen.ec.idElementConstitutif
                )
                .then((data) => {
                    this.setState({
                        loadingListeEtudiant: false,
                        listeEtudiantEc: data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loadingListeEtudiant: false,
                    });
                });

            this.setState({
                submitted: false,
            });
        }
    };

    deleteExamen = (idExamen) => {
        this.examenService
            .delete(parseInt(idExamen))
            .then((data) => {
                this.notify.success();
                this.setState({
                    deleteExamenDialog: false,
                    examen: this.emptyExamen,
                    redirect: true,
                });

                this.examenService
                    .soutenanceBySession(this.state.session.idSession)
                    .then((data) => {
                        this.setState({
                            listeExamen: data,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
            });
    };

    saveExamen = () => {
        if (
            this.state.session &&
            this.state.examen.ue &&
            this.state.examen.ec &&
            this.state.examen.typeActivite &&
            this.state.examen.dateExamen &&
            this.state.examen.dateExamen.trim()
        ) {
            const newExamen = {
                idExamen: parseInt(this.state.examen.idExamen),
                session: this.state.session,
                typeActivite: this.state.examen.typeActivite,
                elementConstitutif: this.state.examen.ec,
                dateExamen: this.state.examen.dateExamen,
                heureExamen: this.state.examen.heureExamen,
                dureeExamen: this.state.examen.dureeExamen,
                observationExamen: this.state.examen.observationExamen,
                paramNote: this.state.examen.paramNote,
            };

            let listeEtudiantSoutenance = this.state.listeEtudiantSoutenance;
            let informationSoutenance = {
                examen: newExamen,
                listeEtudiant: listeEtudiantSoutenance,
                listeExamen: this.state.listeExamenEc,
                idDepartement: this.state.idDepartement,
            };
            console.log(informationSoutenance);
            this.examenService
                .createSoutenance(informationSoutenance)
                .then((data) => {
                    this.notify.success();
                    this.setState({
                        examen: {
                            idExamen: null,
                            libelleExamen: null,
                            dureeExamen: null,
                            intituleExamen: null,
                            dateExamen: null,
                            heureExamen: null,
                            observationExamen: null,
                            ec: null,
                            ue: null,
                            paramNote: null,
                            typeActivite: null,
                            session: null,
                        },
                        session: null,
                        examenDialog: false,
                        sessionDialog: false,
                        listeEtudiantEc: [],
                        listeEtudiantSoutenance: [],
                        submitted: false,
                        verifTaux: false,
                    });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);
                });
        } else {
            this.setState({ submitted: true });
        }
    };

    onFileChange = (event) => {
        let file = event.target.files[0];

        let extension = file.name.split(".").pop();
        if (
            file &&
            extension.toLowerCase() === "pdf" &&
            file.size <= 31457280
        ) {
            this.setState({ nomFichier: file.name });
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);
                let newFichier = this.state.importFichier;
                newFichier["fichier"] = fichier;
                newFichier["nomFichier"] = file.name;

                this.setState({
                    submitted: true,
                    importFichier: newFichier,
                });
            };

            reader.readAsBinaryString(file);
        } else {
            let errorFile = "";
            if (extension.toLowerCase() !== "pdf") {
                errorFile = "Le fichier doit être un pdf";
            } else if (file.size > 31457280) {
                errorFile = "Le fichier excède 30 Mo!";
            }
            this.setState({
                errorFile: errorFile,
            });
        }
    };

    onNumberChampsChange = (e) => {
        let val = (e.target && e.target.value) || "";
        let note = this.state.importFichier.note;
        let errorNote = "";

        if (!isNaN(val) && parseFloat(val) >= 0 && parseFloat(val) <= 20) {
            note = parseFloat(val);
        } else if (val === "") {
            note = "";
            let errorNote = "Saisir une note valide !!!";
        }

        let importFichier = this.state.importFichier;

        importFichier["note"] = note;

        this.setState({
            importFichier: importFichier,
            errorNote: errorNote,
        });
    };

    telechargerRapport = () => {
        const bytes = atob(this.state.fichierActu);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
        let blob = new Blob([out], { type: "application/pdf" });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = this.state.nomFichier;
        link.download = fileName;
        link.click();
    };

    importMemoire = () => {
        let importFichier = this.state.importFichier;
        let valide = false;
        if (
            !isNaN(importFichier.note) &&
            parseFloat(importFichier.note) >= 0 &&
            parseFloat(importFichier.note) <= 20
        ) {
            if (importFichier.fichier) {
                valide = true;
            } else {
                if (
                    window.confirm(
                        "Aucun fichier valide chargé, voulez vous enregistrer la note sans le fichier ?"
                    )
                ) {
                    valide = true;
                }
            }

            if (valide) {
                this.examenService
                    .saveFileSoutenance(
                        this.state.idDepartement,
                        this.state.examen.idExamen,
                        importFichier
                    )
                    .then((data) => {
                        this.notify.success();
                        this.setState({
                            importNoteDialog: false,
                            importFichier: this.emptyFile,
                        });
                    })
                    .catch((error) => {
                        this.notify.echec(error.response.data.message);
                        console.log(error);
                    });
            }
        } else {
            this.setState({
                errorNote: "Saisir une note valide !!!",
            });
        }
    };

    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////

    listeSoutenance = (session) => {
        let idSession = session.idSession;

        this.setState({
            listeExamenDialog: true,
            session: session,
            loadingListe: true,
            listeTitle: session.libelleSession,
            ec: session.elementConstitutif,
            typeActivite: session.typeActivite,
            tauxNotation: session.paramNote,
        });

        this.examenService
            .soutenanceBySession(idSession)
            .then((data) => {
                this.setState({
                    listeExamen: data,
                    loadingListe: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des notes"
                    onClick={() => this.listeNoteExamen(rowData)}
                />
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Supprimer l'évaluation"
                    onClick={() => this.deleteExamen(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                {this.state.verifeDept ? (
                    <Button
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-success"
                        style={{ marginRight: ".5em" }}
                        title="Créer une soutenance"
                        onClick={() => this.afficheExamen(rowData)}
                        disabled={rowData.estDelibere}
                    />
                ) : (
                    ""
                )}
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des soutenances"
                    onClick={() => this.listeSoutenance(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyTemplateListeExamen = (rowData) => {
        return (
            <React.Fragment>
                {this.state.verifeDept ? (
                    <Button
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-danger"
                        style={{ marginRight: ".5em" }}
                        title="Supprimer l'évaluation"
                        onClick={() => this.confirmDeleteExamen(rowData)}
                        disabled={this.state.session.estDelibere}
                    />
                ) : (
                    ""
                )}
                <Button
                    type="button"
                    icon="pi pi-pencil"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Notation soutenance"
                    onClick={() => this.noterSoutenance(rowData)}
                    disabled={this.state.session.estDelibere}
                />
            </React.Fragment>
        );
    };

    actionBodyTemplateSelectEtudiant = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Selectionner l'étudiant"
                    onClick={() => this.selectionEtudiant(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyTemplateRemoveEtudiant = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Supprimer l'étudiant"
                    onClick={() => this.removeEtudiant(rowData)}
                />
            </React.Fragment>
        );
    };

    render() {
        //Formulaire de recherche superieur
        let actionHeader = (
            <Button
                className="enteteDatatable"
                type="button"
                icon="pi pi-cog"
            />
        );

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText
                        className=""
                        type="search"
                        onInput={(e) =>
                            this.setState({
                                globalFilterSession: e.target.value,
                            })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );

        const examenDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDialog}
                />
                <Button
                    label="Enregistrer"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.saveExamen}
                />
            </React.Fragment>
        );

        const importDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideImportDialog}
                />
                <Button
                    label="Valider"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.importMemoire}
                />
            </React.Fragment>
        );

        const selectEtudiantDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideSelectEtudiant}
                />
            </React.Fragment>
        );

        const sessionDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideSessionDialog}
                />
            </React.Fragment>
        );

        const listeExamenDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListeExamenDialog}
                />
            </React.Fragment>
        );

        const deleteExamenFooter = (
            <React.Fragment>
                <Button
                    label="NON"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDeleteExamenDialog}
                />
                <Button
                    label="OUI"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() =>
                        this.deleteExamen(this.state.examen.idExamen)
                    }
                />
            </React.Fragment>
        );

        const tauxExamenDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideTauxDialog}
                />
                <Button
                    label="Valider"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.validerTaux}
                />
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        }

                        {header}
                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : this.state.verifeDept ? (
                            <>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header={`Liste des sessions disponibles (${this.state.listeSession.length})`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année académique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ) : this.state.verifeEtab ? (
                            <>
                                <div className="form-grid">
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onDepartementChange(e);
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un departement"
                                        />
                                    </div>
                                </div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header={`Liste des sessions disponibles (${this.state.listeSession.length})`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année académique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <Dialog
                    visible={this.state.examenDialog}
                    style={{ width: "80%" }}
                    header="Informations de la soutenance"
                    modal
                    className="p-fluid"
                    footer={examenDialogFooter}
                    onHide={this.hideDialog}
                >
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="libelleUniteEnseignement">
                                    Selectionner l'unité d'enseignement
                                </label>
                                <Dropdown
                                    optionLabel="libelleUniteEnseignement"
                                    optionValue="idUniteEnseignement"
                                    value={this.state.examen.ue}
                                    options={this.state.listeUe}
                                    onChange={(e) => {
                                        this.onValueChange(e, "ue");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                    placeholder="Selectionner l'unité d'enseignement"
                                />
                                {this.state.submitted &&
                                    !this.state.examen.ue && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Selectionner l'UE.
                                        </small>
                                    )}
                            </div>
                        </div>
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="libelleTypeActivite">
                                    Selectionner le type d'activité
                                </label>
                                <Dropdown
                                    optionLabel="libelleTypeActivite"
                                    optionValue="idTypeActivite"
                                    value={this.state.examen.typeActivite}
                                    options={this.state.listeTypeActivite}
                                    onChange={(e) => {
                                        this.onValueChange(e, "typeActivite");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                    placeholder="Selectionner le type d'activite"
                                />
                                {this.state.submitted &&
                                    !this.state.examen.typeActivite && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Selectionner le type d'activite.
                                        </small>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="libelleElementConstitutif">
                                    Selectionner l'élement constitutif
                                </label>
                                <Dropdown
                                    optionLabel="libelleElementConstitutif"
                                    optionValue="idElementConstitutif"
                                    value={this.state.examen.ec}
                                    options={this.state.listeEc}
                                    onChange={(e) => {
                                        this.onValueChange(e, "ec");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                    placeholder="Selectionner l'élément constitutif"
                                />
                                {this.state.submitted &&
                                    !this.state.examen.ec && (
                                        <small
                                            className="p-invalid"
                                            style={{ color: "red" }}
                                        >
                                            Selectionner l'élement constitutif
                                        </small>
                                    )}
                            </div>
                        </div>
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="valParam">
                                    Selectionner le taux (facultatif)
                                </label>
                                <Dropdown
                                    optionLabel="valParam"
                                    optionValue="idParamNote"
                                    value={this.state.examen.paramNote}
                                    options={this.state.listeParamNote}
                                    onChange={(e) => {
                                        this.onValueChange(e, "paramNote");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                    placeholder="Selectionner le taux"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        {this.state.messageInfrastructure &&
                            this.state.submitted && (
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <div
                                        className="alert alert-warning d-flex align-items-center"
                                        role="alert"
                                    >
                                        <div>
                                            {this.state.messageInfrastructure}
                                        </div>
                                    </div>
                                </div>
                            )}

                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="dateExamen">
                                Entrer la date de l'examen
                            </label>
                            <input
                                type="date"
                                id="dateExamen"
                                value={this.state.examen.dateExamen}
                                onChange={(e) =>
                                    this.onValueChange(e, "dateExamen")
                                }
                                required
                                autoFocus
                                className="p-inputtext p-component"
                                placeholder="Entrer la date de l'examen"
                            />
                            {this.state.submitted &&
                                !this.state.examen.dateExamen && (
                                    <small
                                        className="p-invalid"
                                        style={{ color: "red" }}
                                    >
                                        Ce champs est obligatoire.
                                    </small>
                                )}
                        </div>

                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="heureExamen">
                                Entrer l'heure de l'examen
                            </label>
                            <input
                                type="time"
                                id="heureExamen"
                                value={this.state.examen.heureExamen}
                                onChange={(e) =>
                                    this.onValueChange(e, "heureExamen")
                                }
                                required
                                autoFocus
                                className="p-inputtext p-component"
                                placeholder="Entrer l'heure de l'examen"
                            />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <label htmlFor="dureeExamen">
                                Entrer la durée de l'examen
                            </label>
                            <InputText
                                id="dureeExamen"
                                value={this.state.examen.dureeExamen}
                                onChange={(e) =>
                                    this.onValueChange(e, "dureeExamen")
                                }
                                required
                                autoFocus
                                className={classNames({
                                    "p-invalid": this.state.submitted,
                                })}
                                placeholder="Entrer la durée de l'examen"
                            />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <label htmlFor="observationExamen">
                                Observation
                            </label>
                            <textarea
                                id="observationExamen"
                                value={this.state.examen.observationExamen}
                                onChange={(e) =>
                                    this.onValueChange(e, "observationExamen")
                                }
                                placeholder="Description"
                                className="p-inputtext p-component"
                            />
                        </div>
                    </div>

                    <Button
                        label="Selection de(s) étudiant(e)(s) concerné(e)(s)"
                        icon="pi pi-check"
                        className="p-button-success p-col-4"
                        onClick={() => this.selectEtudiantSoutenance()}
                    />
                    <hr />

                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEtudiantSoutenance}
                                selectionMode="none"
                                header="Etudiant(s) concernés par la soutenance"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilterSession}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="ine"
                                    header="INE"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="Rechercher INE"
                                />
                                <Column
                                    field="nom"
                                    header="Nom"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher Nom"
                                />
                                <Column
                                    field="prenom"
                                    header="Prénom(s)"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher Prénom(s)"
                                />
                                <Column
                                    field="dateNaissance"
                                    header="Date de naissance"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="recherhcher Date de naissance"
                                />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.importNoteDialog}
                    style={{ width: "80%" }}
                    header="Informations de la soutenance"
                    modal
                    className="p-fluid"
                    footer={importDialogFooter}
                    onHide={this.hideDialog}
                >
                    {/*
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="libelleElementConstitutif">Element constitutif concerné</label>
                                <input type="text" value={this.state.listeEc[0].libelleElementConstitutif} required autoFocus className='p-inputtext p-component' placeholder="Entrer la date de l'examen" readOnly/>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleTypeActivite">Type d'activité</label>
                                <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={this.state.examen.typeActivite} options={this.state.listeTypeActivite} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} readOnly/>
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="valParam">Taux (facultatif)</label>
                                <InputText id="dureeExamen" value={this.state.examen.paramNote.valeurParam} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} readOnly/>
                            </div>
                        </div>
                    </div>
                    */}

                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="dateExamen">
                                Date de la soutenance
                            </label>
                            <input
                                type="date"
                                id="dateExamen"
                                value={this.state.examen.dateExamen}
                                required
                                autoFocus
                                className="p-inputtext p-component"
                                readOnly
                            />
                        </div>

                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="heureExamen">
                                Heure de la soutenance
                            </label>
                            <input
                                type="time"
                                id="heureExamen"
                                value={this.state.examen.heureExamen}
                                required
                                autoFocus
                                className="p-inputtext p-component"
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <label htmlFor="dureeExamen">
                                Durée de l'examen
                            </label>
                            <InputText
                                id="dureeExamen"
                                value={this.state.examen.dureeExamen}
                                required
                                autoFocus
                                className={classNames({
                                    "p-invalid": this.state.submitted,
                                })}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <label htmlFor="observationExamen">
                                Observation
                            </label>
                            <textarea
                                id="observationExamen"
                                value={this.state.examen.observationExamen}
                                placeholder="Description"
                                className="p-inputtext p-component"
                                readOnly
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEtudiantSoutenance}
                                selectionMode="none"
                                header="Etudiant(s) concernés par la soutenance"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilterSession}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="ine"
                                    header="INE"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="Rechercher INE"
                                />
                                <Column
                                    field="nom"
                                    header="Nom"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher Nom"
                                />
                                <Column
                                    field="prenom"
                                    header="Prénom(s)"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher Prénom(s)"
                                />
                                <Column
                                    field="dateNaissance"
                                    header="Date de naissance"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="recherhcher Date de naissance"
                                />
                            </DataTable>
                        </div>
                    </div>

                    <hr />
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="noteSoutenance">
                                Entrer la note obtenue
                            </label>
                            <InputText
                                id="noteSoutenance"
                                value={this.state.importFichier.note}
                                required
                                autoFocus
                                className={classNames({
                                    "p-invalid": this.state.submitted,
                                })}
                                placeholder="Entrer la durée de l'examen"
                                onChange={(e) => {
                                    this.onNumberChampsChange(e);
                                }}
                            />
                            {this.state.errorNote && (
                                <small
                                    className="p-invalid"
                                    style={{ color: "red" }}
                                >
                                    {this.state.errorNote}
                                </small>
                            )}
                        </div>

                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="fichierMemoire">
                                Charger le fichier (pdf et 30 Mo Max){" "}
                                {this.state.fichierActu && (
                                    <a
                                        className="p-invalid"
                                        style={{ color: "blue" }}
                                        onClick={this.telechargerRapport}
                                    >
                                        Télécharger le rapport
                                    </a>
                                )}
                            </label>
                            <input
                                type="file"
                                onChange={(e) => {
                                    this.onFileChange(e);
                                }}
                                className="p-inputtext p-component"
                            />
                            {this.state.errorFile && (
                                <small
                                    className="p-invalid"
                                    style={{ color: "red" }}
                                >
                                    {this.state.errorFile}
                                </small>
                            )}
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.selectEtudiantDialog}
                    style={{ width: "95%" }}
                    header="Liste des étudiants inscrits à l'EC"
                    modal
                    className="p-fluid"
                    footer={selectEtudiantDialogFooter}
                    onHide={this.hideSelectEtudiant}
                >
                    <div className="row" style={{ padding: 5 }}>
                        {this.state.loadingListeEtudiant ? (
                            <>
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="p-col-6 p-md-12 p-lg-6">
                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.listeEtudiantEc}
                                        selectionMode="none"
                                        header="Etudiant(e)(s) inscrit(e)(s) à l'EC"
                                        paginator={true}
                                        rows={10}
                                        globalFilter={
                                            this.state.globalFilterSession
                                        }
                                        filterMatchMode="contains"
                                        responsive={true}
                                        selection={
                                            this.state.dataTableSelection1
                                        }
                                        onSelectionChange={(event) =>
                                            this.setState({
                                                dataTableSelection1:
                                                    event.value,
                                            })
                                        }
                                    >
                                        <Column
                                            field="ine"
                                            header="INE"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Rechercher INE"
                                        />
                                        <Column
                                            field="nom"
                                            header="Nom"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="rechercher Nom"
                                        />
                                        <Column
                                            field="prenom"
                                            header="Prénom(s)"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="rechercher Prénom(s)"
                                        />
                                        <Column
                                            header={actionHeader}
                                            body={
                                                this
                                                    .actionBodyTemplateSelectEtudiant
                                            }
                                            style={{
                                                textAlign: "center",
                                                width: "8em",
                                            }}
                                        />
                                    </DataTable>
                                </div>
                            </>
                        )}

                        <div className="p-col-6 p-md-12 p-lg-6">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEtudiantSoutenance}
                                selectionMode="none"
                                header="Etudiant(e)(s) concerné(e)(s) par la soutenance"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilterSession}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="ine"
                                    header="INE"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="Rechercher INE"
                                />
                                <Column
                                    field="nom"
                                    header="Nom"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher Nom"
                                />
                                <Column
                                    field="prenom"
                                    header="Prénom(s)"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher Prénom(s)"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateRemoveEtudiant}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.deleteExamenDialog}
                    style={{ width: "450px" }}
                    header="Confirmez"
                    modal
                    footer={deleteExamenFooter}
                    onHide={this.hideDeleteExamenDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        {this.state.examen && (
                            <span>
                                Etes-vous sûr de supprimer cet élément ?
                            </span>
                        )}
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.listeExamenDialog}
                    style={{ width: "80%" }}
                    header={`Liste des soutenances: ${this.state.listeTitle}`}
                    modal
                    className="p-fluid"
                    footer={listeExamenDialogFooter}
                    onHide={this.hideListeExamenDialog}
                >
                    {this.state.loadingListe ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.listeExamen}
                            selectionMode="single"
                            header={`Liste des examens (${this.state.listeExamen.length})`}
                            paginator={true}
                            rows={5}
                            globalFilter={this.state.globalFilterListeExamen}
                            filterMatchMode="contains"
                            responsive={true}
                            selection={this.state.dataTableSelection1}
                            onSelectionChange={(event) =>
                                this.setState({
                                    dataTableSelection1: event.value,
                                })
                            }
                        >
                            <Column
                                field="elementConstitutif.libelleElementConstitutif"
                                header="Element constitutif"
                                filterMatchMode="contains"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Rechercher un parcours"
                            />
                            <Column
                                field="dateExamen"
                                header="Date de composition"
                                filterMatchMode="contains"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="rechercher une date"
                            />
                            <Column
                                field="heureExamen"
                                header="Heure de composition"
                                filterMatchMode="contains"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="rechercher une heure"
                            />
                            <Column
                                field="dureeExamen"
                                header="Durée de l'examen"
                                filterMatchMode="contains"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="rechercher une durée"
                            />
                            <Column
                                field="paramNote.valeurParam"
                                header="Taux de notation en %"
                                filterMatchMode="contains"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="rechercher un taux"
                            />
                            <Column
                                field="observationExamen"
                                header="Observation"
                                filterMatchMode="contains"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="recherhcher un lieu"
                            />
                            <Column
                                header={actionHeader}
                                body={this.actionBodyTemplateListeExamen}
                                style={{ textAlign: "center", width: "8em" }}
                            />
                        </DataTable>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.editionTaux}
                    style={{ width: "60%" }}
                    header="Informations sur les taux d'examen"
                    modal
                    className="p-fluid"
                    footer={tauxExamenDialogFooter}
                    onHide={this.hideTauxDialog}
                >
                    <hr />

                    {this.state.messageTaux ? (
                        <div
                            className="alert alert-danger d-flex align-items-center"
                            role="alert"
                        >
                            <div>{this.state.messageTaux}</div>
                        </div>
                    ) : (
                        <div
                            className="alert alert-warning d-flex align-items-center"
                            role="alert"
                        >
                            <div>
                                Editer les taux pour que la somme des taux soit
                                égale à 100%
                            </div>
                        </div>
                    )}

                    <hr />
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeExamenEc}
                                selectionMode="single"
                                header="Liste de(s) examen(s) déjà existant de l'EC"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilterSession}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="libelleExamen"
                                    header="Examen"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="Rechercher un parcours"
                                />
                                <Column
                                    field="paramNote.valParam"
                                    header="Taux de notation"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    editor={(props) =>
                                        this.editTauxExamen(props)
                                    }
                                />
                            </DataTable>
                        </div>
                    </div>

                    <hr />
                    <div className="p-grid">
                        <div className="p-col-4 p-md-4 p-lg-4">
                            <div className="p-field">
                                <label htmlFor="tauxNotation">
                                    Taux du nouveau examen:{" "}
                                </label>
                            </div>
                        </div>
                        <div className="p-col-8 p-md-8 p-lg-8">
                            <div className="p-field">
                                <Dropdown
                                    optionLabel="valParam"
                                    optionValue="idParamNote"
                                    value={this.state.examen.paramNote}
                                    options={this.state.listeParamNote}
                                    onChange={(e) => {
                                        this.onValueChange(e, "paramNote");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": this.state.submitted,
                                    })}
                                    placeholder="Selectionner le taux"
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default Soutenances;
