import React, { Component } from 'react'
import { Menubar } from 'primereact/menubar';
import logoCampusFaso from '../images/campusfaso_logo_wb.png'
import { InputText } from 'primereact/inputtext';
import { Route } from 'react-router-dom';

const Hebergement = (keycloak) => {

    const itemsDr = [
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs'
        },
        {
            label: 'Analyse de dossiers',
            icon: 'pi pi-fw pi-power-offs'
        },
        {
            label: 'Delibération',
            icon: 'pi pi-fw pi-power-offs'
        },
        {
            label: 'Affectation',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Cité',
                    icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Lit',
                    icon: 'pi pi-fw pi-align-right'
                } 

            ]
        },
        {
            label: 'Données statistiques',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Inscription',
                    icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Dépôt',
                    icon: 'pi pi-fw pi-align-right'
                },
                {
                    label: 'Analyse',
                    icon: 'pi pi-fw pi-align-center'
                },
                {
                    label: 'Delibération',
                    icon: 'pi pi-fw pi-align-center'
                },
                {
                    label: 'Affectation',
                    icon: 'pi pi-fw pi-align-center'
                },
            ]
        }
        
    ];

    return (
        <div className="html">
            <div className="body">
                <div className="grid-container">
                <header className="header">
                   
                    <div className="header-text">
                        <i className="ti-user"></i>
                            <span>Bienvenue <span style={{color : "green"}}></span></span>
                             |  
                            <a href="#" onClick={()=>{localStorage.clear(); keycloak.keycloak.logout()}}> 
                                <i className="ti-close"></i> 
                                Déconnexion
                            </a> |
                            <a href="#" className="btn" onClick={()=> {window.location.reload()}} >
                                <i className="ti-home"></i> 
                                Accueil
                            </a>
                        </div>
                        
                    </header>
                    <aside className="sidenav">
                        <div className="logo">
                            <img src={logoCampusFaso} alt=""/>
                            <div className="txt">
                                Plateforme intégrée de <br/>
                                l’enseignement supérieur <br/>
                                et de la recherche
                            </div>
                        </div>
                    </aside>  
                    <main className="main">
                        <div className="cards titles" >
                            <Menubar model={itemsDr} />
                        </div>
                        <div >
                            <Route />
                            
                        </div>  
                    </main>
                    <div className="footer">
                        <ul className="liens">
                            <li><a href="">CGU</a></li>
                            <li><a href="">Politique de confidentialité</a></li>
                            <li><a href="">FAQ</a></li>
                        </ul>
                        <p>
                            &copy; 2021 &middot; 
                            MESRSI
                            &middot;Tous droits réservés
                        </p>
                    </div>
                </div>
            </div>
        </div> 
    )
}
export default Hebergement
