import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { Dropdown } from 'primereact/dropdown'
import { StructureService } from '../../service/StructureService'

export class Structure extends Component {
    emptyStructure = {
        idStructure: null,
        libelleStructure: "",
        sigleStructure : ""
    }

    constructor() {
        super();
        this.state = {
            listeStructure : [],
            structureDialog: false,
            deleteStructureDialog: false,
            structure: this.emptyStructure,
            submitted: false,
            loading : false,
            globalFilter: null
        }

        this.spinner = new Spinner()
        this.notify = new Notify()
        this.structureService = new StructureService()

    }

    componentDidMount = () => {
        this.setState({loading : true})
        this.structureService.getAll().then(data => {
            this.setState({listeStructure : data, loading : false})
        })
        .catch(error => {
            console.log(error)
            this.setState({loading :false})
        })
        /*const data = [
            {
                idStructure : 1,
                libelleStructure : "Direction Générale de l'Enseignement Supérieur",
                sigleStructure : "DGESup"
            },
            {
                idStructure : 2,
                libelleStructure : "Fond National d'Aide...",
                sigleStructure : "FONER"
            },
            {
                idStructure : 3,
                libelleStructure : "Centre d'instruction, d'Orientation Scolaire, Professionnel et de Bourses ",
                sigleStructure : "CIOSPB"
            }
        ]
        this.setState({listeStructure : data})*/
    }

    openNew = () => {
        this.setState({
            structure: this.emptyStructure,
            submitted: false,
            structureDialog: true
        })
    }
    hideDialog = () => {
        this.setState({
            submitted: false,
            structureDialog: false
        });
    }

    onValueChange = (e, field) => {

        const val = (e.target && e.target.value) || '';
        let structure = this.state.structure; 
        structure[`${field}`] = val;
        this.setState({structure})     
    }

    saveStructure = () => {
        let state = { submitted: true };

        if (this.state.structure.libelleStructure.trim()) {

            const newStructure = {
                idSrtucture : parseInt(this.state.structure.idStructure) ,
                libelleStructure : this.state.structure.libelleStructure,
                sigleStructure : this.state.structure.sigleStructure
            }
            //console.log(newStructure)

            if (this.state.stucture.idStructure) {
                this.structureService.update(parseInt(this.state.stucture.idStructure), newStructure)
                                .then(data => {
                                    this.notify.success()
                                    this.setState({loading : true})
                                    this.structureService.getAll().then(data => 
                                        this.setState({listeStructure: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)  
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.structureService.create(newStructure).then(data => {
                    this.notify.success()
                    this.setState({loading : true})
                    this.structureService.getAll().then(data => 
                        this.setState({listeStructure: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                structureDialog: false,
                structure: this.emptyStructure
            };
        }

        this.setState(state);
    }
    confirmDeleteStructure = (structure) => {
        this.setState({
            structure,
            deleteStructureDialog: true
        });
    }
    hideDeleteStructureDialog = () => {
        this.setState({ deleteStructureDialog: false });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editStructure(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteStructure}/>
            </React.Fragment>
        );
    }
    
    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const structureDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveStructure} />
            </React.Fragment>
        );
        const deleteStructureDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideStructureDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteStructure(this.state.structure.idStructure) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeStructure} selectionMode="single" header="Liste des stuctures" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleStructure" header="Structure"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="sigleStructure" header="Sigle"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>
                <Dialog visible={this.state.structureDialog} style={{ width: '450px' }} header="Informations structure" modal className="p-fluid" footer={structureDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleStructure">Libelle Structure *</label>
                        <InputText id="libelleStructure" value={this.state.structure.libelleStructure}  onChange={(e) => this.onValueChange(e, 'libelleStructure')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle du structure'/>
                        {this.state.submitted && !this.state.structure.libelleStructure && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="sigleStructure">Sigle Structure</label>
                        <InputText id="sigleStructure" value={this.state.structure.sigleStructure}  onChange={(e) => this.onValueChange(e, 'sigleStructure')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Code du semestre'/>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteStructureDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteStructureDialogFooter} onHide={this.hideDeleteStructureDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.structure && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Structure

