import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Notify } from '../../components/Notify';
import { ToastContainer} from 'react-toastify';
import { Spinner } from '../../components/Spinner';
import { ECService } from '../service/ECService';
import { ExamenService } from '../service/ExamenService';
import { ParamNoteService } from '../service/ParamNoteService';
import { ParcoursUEService } from '../service/ParcoursUEService';
import { SessionExamenService } from '../service/SessionExamenService';
import { TypeActiviteService } from '../service/TypeActiviteService';
import  '../../css/style.css'

export class SessionReclammation extends Component {
        
    emptyExamen = {
        idExamen: null,
        libelleExamen: null,
        heureFinExamen: null,
        intituleExamen: null,
        dateExamen: null,
        heureExamen: null,
        observationExamen: null,
        ec: null,
        ue: null,
        paramNote: null,
        typeActivite: null,
        session: null,
        infrastructure: null
    }
    
    constructor(props){

        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.examenService = new ExamenService()
        this.elementConstitutifService = new ECService()
        this.sessionService = new SessionExamenService()
        this.TypeActiviteService = new TypeActiviteService()
        this.paramNoteService = new ParamNoteService()
        this.parcoursUEService = new ParcoursUEService()

        this.state = {
            listeExamen : [],
            listeSession : [],
            listeUe : [],
            listeEc : [],
            listeParamNote : [],
            listeExamenEc: [],
            listeInfrastructure: [],
            examen : this.emptyExamen,
            session: null,
            submitted: false,
            redirect : false,
            loading : false,
            loadingListe: false,
            globalFilter: null,
            globalFilterListeExamen : null,
            examenDialog: false,
            sessionDialog : false,
            deleteExamenDialog: false,
            listeExamenDialog : false,
            listeTitle: "",
            editionTaux: false,
            verifTaux: false,
            messageTaux: "",
            messageInfrastructure: ""
        }
    }

        componentDidMount(){

            const tokenDecoded = localStorage.getItem('tokenDecoded')
		    const token = JSON.parse(tokenDecoded)
            //console.log(token);

            this.setState({
                loading: true,
                idDepartement: token.code_departement
            })
            this.sessionService.getSessionDepartement(token.code_departement).then(data => {
                this.setState({
                    listeSession : data.data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })

            this.TypeActiviteService.getAll().then(data => {
                this.setState({listeTypeActivite : data})
            }).catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })

            this.paramNoteService.getAll().then(data => {
                this.setState({
                    listeParamNote: data
                })
                }
            )
            .catch(error => {
                console.log(error)
                this.notify.echec(error.response.data.message)
            })
        }
       
    openNew = () => {
        this.setState({
            examen: this.emptyExamen,
            submitted: false,
            sessionDialog: true
        });
    }

    afficheExamen = (session) => {
        
        this.setState({
            examenDialog: true,
            session : session

        });
        
        let idParcours = session.parcoursFiliere.idParcours
        let idSemestre = session.anneeSemestre.idSemestre
        this.parcoursUEService.get(idParcours, idSemestre).then(data => {
            this.setState({
                listeUe: data
            })
        })
    }
    
    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        let examen = this.state.examen

        examen[`${field}`] = val
        this.setState({examen})
        if(field === "ue"){
            this.setState({listeEc : val.uniteEnseignement.listeElementConstitutif})
        }else

        if(field === "ec"){

            console.log(this.state.session.idSession, examen.ec.idElementConstitutif);

            this.examenService.getListeExamenByEc(this.state.session.idSession, examen.ec.idElementConstitutif).then(data => {
                
                console.log(data);

                if(data.length>0){

                    this.setState({
                        listeExamenEc: data,
                        editionTaux: true,
                        verifTaux: false
                    })
                }else{

                    this.setState({
                        verifTaux: true,
                        listeExamenEc: []
                    })
                }

            }).catch(error => {
                console.log(error);
                this.notify.echec(error.response.data.message)
            })

        }


        if(field === "heureFinExamen" || field === "heureExamen" || field === "dateExamen" ){

            if(!examen['dateExamen'] || !examen['heureExamen'] || !examen['heureFinExamen']){
                console.log("Note OK");
                this.setState({
                    messageInfrastructure: "Veuillez completer les informations concernant la date et l'heure de l'examen !!!"
                })
            } else
            if(examen['dateExamen'] && examen['heureExamen'] && examen['heureFinExamen'] && examen['heureFinExamen'] <= examen['heureExamen']){
                console.log("Note OK");
                this.setState({
                    messageInfrastructure: "L'heure de début doit être inférieur à l'heure de fin !!!"
                })
            }else{

                let idDepartement = 1
                this.examenService.getListeInfrastructure(/*this.state.idDepartement*/ idDepartement, examen['dateExamen'], examen['heureExamen'], examen['heureFinExamen']).then(data => {
                    
                    console.log(this.state.idDepartement);
                    console.log(data);
                    this.setState({
                        listeInfrastructure: data
                    })

                }).catch(error => {
                    console.log(error);
                    this.notify.echec(error.response.data.message)
                })

                this.setState({
                    messageInfrastructure: ""
                })
            }
        }

    }

    hideDialog = () => {
        this.setState({
            submitted: false,
            examenDialog: false,
            examen: {
                idExamen: null,
                libelleExamen: null,
                heureFinExamen: null,
                intituleExamen: null,
                dateExamen: null,
                heureExamen: null,
                observationExamen: null,
                ec: null,
                ue: null,
                paramNote: null,
                typeActivite: null,
                session: null
            },
            verifTaux: false,
            listeExamenEc: []
        });
    }

    hideTauxDialog = () => {

        if(this.state.verifTaux){

            this.setState({
                editionTaux: false,
                messageTaux: ""
            });

        }else{

            this.setState({
                editionTaux: false,
                submitted: false,
                examenDialog: false,
                examen: {
                    idExamen: null,
                    libelleExamen: null,
                    heureFinExamen: null,
                    intituleExamen: null,
                    dateExamen: null,
                    heureExamen: null,
                    observationExamen: null,
                    ec: null,
                    ue: null,
                    paramNote: null,
                    typeActivite: null,
                    session: null
                },
                verifTaux: false,
                messageTaux: "",
                listeExamenEc: []
            });
        }
    }

    validerTaux = () => {

        let totalTaux = 0
        if(this.state.examen.paramNote){

            //On fait l'adition des taux pour s'assurer que ça ne va pas au délà de 100%
            totalTaux = totalTaux + parseInt(this.state.examen.paramNote.valeurParam)
            this.state.listeExamenEc.map(examen => {
                totalTaux = totalTaux + parseInt(examen.paramNote.valeurParam)
            })

            if(parseInt(totalTaux)!==100){
                this.setState({
                    messageTaux: "Revoir les taux car la somme des taux est différente de 100% !!!"
                })
            }else{

                this.setState({
                    verifTaux: true,
                    editionTaux: false
                })
            }

        }else{
            this.setState({
                messageTaux: "Veuillez selectionner un taux de notation pour le nouveau examen !!!"
            })
        }
    }

    onListeExamenTauxChange = (value, valueIndex) => {
        let listeExamenEc = this.state.listeExamenEc
        listeExamenEc[valueIndex].paramNote = value

        this.setState({
            listeExamenEc: listeExamenEc
        })
    }

    inputTauxEditor = (props) => {
        return <Dropdown optionLabel="valParam" optionValue="idParamNote" value={props.rowData.paramNote}  options={this.state.listeParamNote} onChange={(e) => {this.onListeExamenTauxChange(e.target.value, props.rowIndex)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le taux"/>
    }

    editTauxExamen = (props) => {
        return this.inputTauxEditor(props);
    }

    hideSessionDialog = () => {
        this.setState({
            sessionDialog: false
        });
    }
    hideListeExamenDialog = () => {
        this.setState({
            listeExamenDialog: false
        });
    }

    hideDeleteExamenDialog = () => {
        this.setState({ deleteExamenDialog: false });
    }

    confirmDeleteExamen = (examen) => {
        this.setState({
            examen: examen,
            deleteExamenDialog: true
        });
    }

    deleteExamen = (idExamen) => {
        this.examenService.delete(parseInt(idExamen)).then(data => {
            
            this.notify.success()
            this.setState({
                deleteExamenDialog: false,
                examen: {
                    idExamen: null,
                    libelleExamen: null,
                    heureFinExamen: null,
                    intituleExamen: null,
                    dateExamen: null,
                    heureExamen: null,
                    observationExamen: null,
                    ec: null,
                    ue: null,
                    paramNote: null,
                    typeActivite: null,
                    session: null
                },
                redirect : true
            });
            this.examenService.examenBySession(this.state.session.idSession).then(data => {
                this.setState({listeExamen : data})
            })
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error);
        })
        
    }

    saveExamen = () => {
        let state = { submitted: true };

        if (this.state.session && this.state.examen.ue && this.state.examen.ec && this.state.examen.typeActivite && this.state.examen.dateExamen && this.state.examen.dateExamen.trim() && this.state.examen.infrastructure) {
            
            const newExamen = {
                idExamen : parseInt(this.state.examen.idExamen) ,
                session : this.state.session ,
                typeActivite : this.state.examen.typeActivite,
                elementConstitutif : this.state.examen.ec,
                dateExamen: this.state.examen.dateExamen,
                heureExamen: this.state.examen.heureExamen,
                heureFinExamen : this.state.examen.heureFinExamen,
                observationExamen: this.state.examen.observationExamen,
                paramNote: this.state.examen.paramNote,
                idInfrastructure: this.state.examen.infrastructure.idInfrastructure
            }

            let dataStruct = {
                examenDto: newExamen,
                listeExamen: this.state.listeExamenEc,
                idDepartement: this.state.idDepartement
            }

            console.log(dataStruct);

            if (this.state.examen.idExamen) {
                this.examenService.update(parseInt(this.state.examen.idExamen), newExamen)
                                .then(data => {
                                    this.notify.success()
                                    this.setState({
                                        examen: {
                                            idExamen: null,
                                            libelleExamen: null,
                                            heureFinExamen: null,
                                            intituleExamen: null,
                                            dateExamen: null,
                                            heureExamen: null,
                                            observationExamen: null,
                                            ec: null,
                                            ue: null,
                                            paramNote: null,
                                            typeActivite: null,
                                            session: null
                                        },
                                        session : null,
                                        examenDialog: false,
                                        sessionDialog : false,
                                        verifTaux: false
                                    })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.examenService.create(dataStruct).then(data => {
                    this.notify.success()
                    this.setState({
                        examen: {
                            idExamen: null,
                            libelleExamen: null,
                            heureFinExamen: null,
                            intituleExamen: null,
                            dateExamen: null,
                            heureExamen: null,
                            observationExamen: null,
                            ec: null,
                            ue: null,
                            paramNote: null,
                            typeActivite: null,
                            session: null
                        },
                        session : null,
                        examenDialog: false,
                        sessionDialog : false,
                        verifTaux: false
                    })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

        }
        this.setState(state)
    }

    listeExamen = (session) => {

        let idSession = session.idSession
        this.setState({
            listeExamenDialog : true, 
            session : session,
            loadingListe: true,
            listeTitle: session.libelleSession
        })

        this.examenService.examenBySession(idSession).then(data => {

            console.log(data);
            this.setState({
                listeExamen : data,
                loadingListe: false
            })
        })
        
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-list" className="p-button-warning" style={{marginRight: '.5em'}} title="Liste des notes" onClick={ () => this.listeNoteExamen(rowData) } />
                <Button type="button" icon="pi pi-trash" className="p-button-danger" style={{marginRight: '.5em'}} title="Supprimer l'évaluation" onClick={ () => this.deleteExamen(rowData) } />
            </React.Fragment>
        )
    }

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-plus" className="p-button-success" style={{marginRight: '.5em'}} title="Créer un examen" onClick={ () => this.afficheExamen(rowData) } />
                <Button type="button" icon="pi pi-list" className="p-button-warning" style={{marginRight: '.5em'}} title="Liste des examens" onClick={ () => this.listeExamen(rowData) } />
            </React.Fragment>
        )
    }

    actionBodyTemplateListeExamen = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-danger" style={{marginRight: '.5em'}} title="Supprimer l'évaluation" onClick={ () => this.confirmDeleteExamen(rowData) } />
            </React.Fragment>
        )
    }

    render() {

        //Formulaire de recherche superieur
        let actionHeader = <Button className="enteteDatatable" type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText className="" type="search" onInput={(e) => this.setState({ globalFilterSession: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const examenDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveExamen} />
            </React.Fragment>
        );

        const tauxExamenDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideTauxDialog} />
                <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.validerTaux} />
            </React.Fragment>
        );

        const sessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSessionDialog} />
            </React.Fragment>
        );

        const listeExamenDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideListeExamenDialog} />
            </React.Fragment>
        );

        const deleteExamenFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteExamenDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteExamen(this.state.examen.idExamen) } />
            </React.Fragment>
        );
   
        return (

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title datatable-demo">
                        {<ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />}

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                                <div className="p-col-6" ></div>
                                    <div className="p-col-6" >
                                        {this.spinner.loadingSpinner("spin", "black")}
                                    </div>
                                </div>
                            :
                            <DataTable ref={(el) => this.dt = el} value={this.state.listeSession} selectionMode="single" header="Liste des sessions" paginator={true} rows={10}
                                globalFilter={this.state.globalFilterSession} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="parcoursFiliere.libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="anneeSemestre.libelleAnneeAcademique" header="Année académique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une année"/>
                                <Column field="anneeSemestre.libelleSemestre" header="Semestre" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un semestre"/>
                                <Column field="typeSession.libelleTypeSession" header="Session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher une session"/>
                                <Column field="etatSession" header="Etat de la session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un état"/>
                                <Column header={actionHeader} body={this.actionBodyTemplateSession} style={{textAlign:'center', width: '8em'}}/>
                            </DataTable>
                            }
                        </div>
                    </div>
                
                <Dialog visible={this.state.examenDialog} style={{ width: '80%' }} header="Informations de l'examen" modal className="p-fluid" footer={examenDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleUniteEnseignement">Selectionner l'unité d'enseignement</label>
                                <Dropdown optionLabel="libelleUniteEnseignement" optionValue="idUniteEnseignement" value={this.state.examen.ue} options={this.state.listeUe} onChange={(e) => {this.onValueChange(e,'ue')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'unité d'enseignement"/>
                                {this.state.submitted && !this.state.examen.ue && <small className="p-invalid" style={{color : "red"}}>Selectionner l'UE.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleTypeActivite">Selectionner le type d'activité</label>
                                <Dropdown optionLabel="libelleTypeActivite" optionValue="idTypeActivite" value={this.state.examen.typeActivite} options={this.state.listeTypeActivite} onChange={(e) => {this.onValueChange(e, 'typeActivite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le type d'activite"/>
                                {this.state.submitted && !this.state.examen.typeActivite && <small className="p-invalid" style={{color : "red"}}>Selectionner le type d'activite.</small>}
                            </div>
                        </div>
                        
                    </div>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="libelleElementConstitutif">Selectionner l'élement constitutif</label>
                                <Dropdown optionLabel="libelleElementConstitutif" optionValue="idElementConstitutif" value={this.state.examen.ec}  options={this.state.listeEc} onChange={(e) => {this.onValueChange(e, 'ec')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'élément constitutif"/>
                                {this.state.submitted && !this.state.examen.ec && <small className="p-invalid" style={{color : "red"}}>Selectionner l'élement constitutif</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="valParam">Selectionner le taux (facultatif)</label>
                                <Dropdown optionLabel="valParam" optionValue="idParamNote" value={this.state.examen.paramNote}  options={this.state.listeParamNote} onChange={(e) => {this.onValueChange(e, 'paramNote')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le taux" disabled/>
                            </div>
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        
                        {(this.state.messageInfrastructure) && this.state.submitted && 
                        (
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <div className="alert alert-warning d-flex align-items-center" role="alert">
                                    <div>
                                        {this.state.messageInfrastructure}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <label htmlFor="dateExamen">Entrer la date de l'examen</label>
                            <input type="date" id="dateExamen" value={this.state.examen.dateExamen}  onChange={(e) => this.onValueChange(e, 'dateExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer la date de l'examen"/>
                            {this.state.submitted && !this.state.examen.dateExamen && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>

                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <label htmlFor="heureExamen">Entrer l'heure de début de l'examen</label>
                            <input type="time" id="heureExamen" value={this.state.examen.heureExamen}  onChange={(e) => this.onValueChange(e, 'heureExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer l'heure de début de l'examen"/>
                        </div>

                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <label htmlFor="heureFinExamen">Entrer l'heure de fin de l'examen</label>
                            <input type="time" id="heureFinExamen" value={this.state.examen.heureFinExamen}  onChange={(e) => this.onValueChange(e, 'heureFinExamen')} required autoFocus className='p-inputtext p-component' placeholder="Entrer l'heure de fin de l'examen"/>
                        </div>

                    </div>
                    <hr/>

                    <div className='p-col-12 p-md-12 p-lg-12'>
                        <div className="p-field">
                            <label htmlFor="libelleInfrastructure">Infrastructure concernée</label>
                            <Dropdown optionLabel="libelleInfrastructure" optionValue="idInfrastructure" value={this.state.examen.infrastructure}  options={this.state.listeInfrastructure} onChange={(e) => {this.onValueChange(e, 'infrastructure')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner l'infrastructure"/>
                            {this.state.submitted && !this.state.examen.infrastructure && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className='p-col-12 p-md-12 p-lg-12'>
                        <div className="p-grid">
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <label htmlFor="observationExamen">Observation</label>
                                <textarea id="observationExamen" value={this.state.examen.observationExamen} onChange={(e) => this.onValueChange(e, 'observationExamen')}  placeholder="Description" className='p-inputtext p-component' />
                            </div>
                        </div>
                    </div>
                    
                </Dialog>

                <Dialog visible={this.state.deleteExamenDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteExamenFooter} onHide={this.hideDeleteExamenDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.examen && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.editionTaux} style={{ width: '60%' }} header="Informations sur les taux d'examen" modal className="p-fluid" footer={tauxExamenDialogFooter} onHide={this.hideTauxDialog}>
                    <hr/>
                    
                    {(this.state.messageTaux?
                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <div>
                                {this.state.messageTaux}
                            </div>
                        </div>
                        :
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <div>
                                Editer les taux pour que la somme des taux soit égale à 100%
                            </div>
                        </div>)
                    }

                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <DataTable ref={(el) => this.dt = el} value={this.state.listeExamenEc} selectionMode="single" header="Liste de(s) examen(s) déjà existant de l'EC" paginator={true} rows={10}
                                globalFilter={this.state.globalFilterSession} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                <Column field="libelleExamen" header="Examen" filterMatchMode="contains"  sortable={false} filter={false} filterPlaceholder="Rechercher un parcours"/>
                                <Column field="paramNote.valParam" header="Taux de notation" filterMatchMode="contains"  sortable={false} filter={false}  editor={(props) => this.editTauxExamen(props)} />
                            </DataTable>
                        </div>
                    </div>

                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-4 p-md-4 p-lg-4'>
                            <div className="p-field">
                                <label htmlFor="tauxNotation">Taux du nouveau examen: </label>
                            </div>
                        </div>
                        <div className='p-col-8 p-md-8 p-lg-8'>
                            <div className="p-field">
                            <Dropdown optionLabel="valParam" optionValue="idParamNote" value={this.state.examen.paramNote}  options={this.state.listeParamNote} onChange={(e) => {this.onValueChange(e, 'paramNote')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le taux"/>
                            </div>
                        </div>
                    </div>

                </Dialog>

                <Dialog visible={this.state.listeExamenDialog} style={{ width: '80%' }} header={`Liste des examens: ${this.state.listeTitle}`} modal className="p-fluid" footer={listeExamenDialogFooter} onHide={this.hideListeExamenDialog}>
                {this.state.loadingListe ? 
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {this.spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable  ref={(el) => this.dt = el} value={this.state.listeExamen} selectionMode="single" header={`Liste des examens ( ${this.state.listeExamen.length} examen(s) )`} paginator={true} rows={5} globalFilter={this.state.globalFilterListeExamen} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                        <Column field="dateExamen" header="Date de composition" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une date"/>
                        <Column field="heureExamen" header="Heure de composition" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une heure"/>
                        <Column field="heureFinExamen" header="Heure de fin composition" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une heure de fin"/>
                        <Column field="libelleInfrastructure" header="Infrastructure composition" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une infrastructure"/>
                        <Column field="paramNote.valParam" header="Taux de notation en %" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un taux"/>
                        <Column field="observationExamen" header="Observation" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un lieu"/>
                        <Column header={actionHeader} body={this.actionBodyTemplateListeExamen} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                }

                </Dialog>
                
            </div> 
        )

    }
    
}

export default SessionReclammation
