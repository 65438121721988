import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { PickList } from 'primereact/picklist';
import TypeInfrastructureService from '../../service/TypeInfrastructureService';
import { CohorteServices } from '../../service/CohorteServices';
import { InfrastructureServices } from '../../service/InfrastructureServices';


export default function ReservationInfrastructure(){

    const [idDepartement, setIdDepartement] = useState(null);
    const [listeProgrammation, setListeProgrammation] = useState(null);
    const [showRejetReservation, setShowRejetReservation] = useState(null);
    const [reservation, setReservation] = useState(null);
    const [spin, setSpin] = useState(null);
    const [showConfirmationReservation, setShowConfirmationReservation] = useState(false)
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);


    const notify = new Notify();
    const spinner = new Spinner();
    const infrastructureServices = new InfrastructureServices();


    useEffect(() => {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)

        console.log(token.code_departement)

        setIdDepartement(token.code_departement)
        setLoading(true)

        infrastructureServices.getlisteReservationInfrastructure(token.code_departement).then(data => {
                
                setListeProgrammation(data)

                //setListeProgrammation(listeReservation)
                setLoading(false)
                //console.log(data);
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message);
                setLoading(false)
            })

        }, []
    );




    const validerReservation = (rowData) => {
        setReservation(rowData)
        setShowConfirmationReservation(true)
    }


    const rejeterReservation = (rowData) => {
        setReservation(rowData)
        setShowRejetReservation(true)
    }


    const hideConfirmReservationDialog = () => {
        setShowConfirmationReservation(false)
    }


    const hideConfirmRejetReservationDialog = () => {
        setShowRejetReservation(false)
    }


    const confirmerReservation = () => {

        setSpin(true)
        infrastructureServices.gestionReservationInfrastructure(reservation.idSeanceProg, 3).then(data => {
            notify.success()
            setLoading(true)
            setShowConfirmationReservation(false)
            setSpin(false)
            infrastructureServices.getlisteReservationInfrastructure(idDepartement).then(data => {
                setListeProgrammation(data)
                setLoading(false)
                //console.log(data);
            })
            .catch(error => {
                console.log(error);
                setLoading(false)
            })

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setSpin(false)
        })

    }


    const confirmerRejetReservation = () => {

        setSpin(true)
        infrastructureServices.gestionReservationInfrastructure(reservation.idSeanceProg, 4).then(data => {
            notify.success()
            setLoading(true)
            setShowRejetReservation(false)
            setSpin(false)
            infrastructureServices.getlisteReservationInfrastructure(idDepartement).then(data => {
                setListeProgrammation(data)
                setLoading(false)
                //console.log(data);
            })
            .catch(error => {
                console.log(error);
                setLoading(false)
            })

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setSpin(false)
        })

    }

    

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-check-circle" className="p-button-success p-mr-2" title="Valider la reservation" style={{marginRight: '.5em'}} onClick={() => validerReservation(rowData)}/>
                <Button icon="pi pi-times-circle" className="p-button-danger" title="Rejeter la demande" onClick={() => rejeterReservation(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }


    const confirmReservationDialogFooter = (
        <React.Fragment>
            <Button label="Oui" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-success" onClick={confirmerReservation}/>
            <Button label="Non" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-times"} className="p-button-danger" onClick={hideConfirmReservationDialog} />
        </React.Fragment>
    );


    const confirmRejetReservationDialogFooter = (
        <React.Fragment>
            <Button label="Oui" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-success" onClick={confirmerRejetReservation}/>
            <Button label="Non" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-times"} className="p-button-danger" onClick={hideConfirmRejetReservationDialog} />
        </React.Fragment>
    );


    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
            <div className="datatable-crud-demo">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <div className="card">
                    {loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable value={listeProgrammation} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                        dataKey="id" paginator rows={10} 
                        globalFilter={globalFilter}
                        header="Liste des demandes de reservation infrastructure">
                        <Column field="libelleInfrastructure" header="Infrastructure" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="dateProgrammation" header="Date de la reservation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="heureDebutSeanceProg" header="Heure debut reservation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="heureFinSeanceProg" header="Heure fin reservation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="libelleDepartementEtab" header="Departement demandeur" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                    </DataTable>
                    }
                </div>



                <Dialog visible={showConfirmationReservation} style={{ width: '450px' }} header="Confirmer réservation" modal footer={confirmReservationDialogFooter} onHide={hideConfirmReservationDialog}>
                    <hr/>
                    <div className="confirmation-content" style={{textAlign: 'center'}}>
                        <i className="pi pi-exclamation-circle p-mr-3" style={{ fontSize: '2rem'}} />
                        {
                            reservation && <span>Après validation de cette programmation, aucun programme en conflit avec cette programmation ne pourra être accepte.
                            Voulez-vous vraiment accepter cette programmation ? 
                            </span>
                        }
                    </div>
                    <hr/>
                </Dialog>


                <Dialog visible={showRejetReservation} style={{ width: '450px' }} header="Rejeter réservation" modal footer={confirmRejetReservationDialogFooter} onHide={hideConfirmRejetReservationDialog}>
                    <hr/>
                    <div className="confirmation-content" style={{textAlign: 'center'}}>
                        <i className="pi pi-exclamation-circle p-mr-3" style={{ fontSize: '2rem'}} />
                        {
                            reservation && <span>Après rejet de cette réservation , le programme sera refuse et le département réservant en sera informé.
                                Voulez-vous vraiment rejeter cette réservation ?                             
                            </span>
                        }
                    </div>
                    <hr/>
                </Dialog>


            </div>
        )

    }