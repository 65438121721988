import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { CategorieDemandeService } from '../../service/CategorieDemandeService'
import { StructureService } from '../../service/StructureService'

export class CategorieDemande extends Component {
    emptyCategorieDemande= {
        idCategorieDemande: null,
        libelleCategorieDemande: "",
        niveau : "",
        structure : {},
    }

    constructor() {
        super();
        this.state = {
            listeCategorieDemande : [],
            categorieDemandeDialog: false,
            deleteCategorieDemandeDialog: false,
            categorieDemande: this.emptyCategorieDemande,
            submitted: false,
            loading : false,
            globalFilter: null
        }
        this.categorieDemandeService = new CategorieDemandeService();
        this.structureService = new StructureService();
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveCategorieDemande = this.saveCategorieDemande.bind(this);
        this.editCategorieDemande = this.editCategorieDemande.bind(this);
        this.confirmDeleteCategorieDemande = this.confirmDeleteCategorieDemande.bind(this);
        this.deleteCategorieDemande = this.deleteCategorieDemande.bind(this);
        this.onLibelleCategorieDemandeChange = this.onLibelleCategorieDemandeChange.bind(this);
        this.onLibelleNiveauChange = this.onLibelleNiveauChange.bind(this);
        this.hideDeleteCategorieDemandeDialog = this.hideDeleteCategorieDemandeDialog.bind(this);

    }


    componentDidMount() {
        
            const tokenDecoded = localStorage.getItem('tokenDecoded')
	        const token = JSON.parse(tokenDecoded)
            const structure = localStorage.getItem('structure')
            const struct = JSON.parse(structure)
            console.log(struct)
            this.setState({structure : struct});
            this.setState({loading : true})
            /*this.categorieDemandeService.getAll(data => {
                console.log(data)
                this.setState({categorieDemande: data, loading : false})
            })
            .catch(error => {
                this.setState({loading : false})
                console.log(error)
            })*/

            if(token.code_iesr){
                this.structureService.get(token.code_iesr).then(data => {
                    console.log(data)
                    let categorieDemande = this.state.categorieDemande
                    categorieDemande['structure'] = data;
                    this.setState({categorieDemande: data, loading : false})
                })
                    .catch(error => {
                        this.notify.echec(error.response.data.message)  
                        console.log(error)
                        this.setState({loading : false})
                    }) 
            }
            else if(token.code_etablissement){
                this.structureService.get(token.code_etablissement).then(data => {
                    console.log(data)
                    let categorieDemande = this.state.categorieDemande
                    categorieDemande['structure'] = data;
                    this.setState({categorieDemande: data, loading : false})
                })
                    .catch(error => {
                        this.notify.echec(error.response.data.message)  
                        console.log(error)
                        this.setState({loading : false})
                    }) 
            }
            else if(token.code_departement){
                
                this.setState({loading : true})
                this.categorieDemandeService.get(385, 'NIVEAU_III').then(donnee => {
                        this.setState({listeCategorieDemande : donnee, loading : false});
                })
                .catch(error => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({loading : false});
                })
                    
                
            }
            else {

            }
             
                

    }
    openNew() {
        this.setState({
            categorieDemande: this.emptyCategorieDemande,
            submitted: false,
            categorieDemandeDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            categorieDemandeDialog: false
        });
    }
    hideDeleteCategorieDemandeDialog() {
        this.setState({ deleteCategorieDemandeDialog: false });
    }
    saveCategorieDemande() {
        let state = { submitted: true };

        if (this.state.categorieDemande.libelleCategorieDemande.trim()) {

            const newCategorieDemande = {
                idCategorieDemande: parseInt(this.state.categorieDemande.idCategorieDemande) ,
                idStructure : 385,
                libelleCategorieDemande : this.state.categorieDemande.libelleCategorieDemande,
                niveau : 'NIVEAU_III',
                structure : this.state.categorieDemande.structure
                
            }
           console.log(newCategorieDemande)
           if (this.state.categorieDemande.idCategorieDemande) {
                this.categorieDemandeService.update(parseInt(this.state.categorieDemande.idCategorieDemande), newCategorieDemande)
                                .then(data => {
                                    this.notify.success()
                
                                    this.setState({loading : true})
                                    this.categorieDemandeService.getAll().then(data => 
                                        this.setState({listeCategorieDemande: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)  
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.categorieDemandeService.create(newCategorieDemande).then(data => {
                    this.notify.success()
                    this.setState({loading : true})
                    this.categorieDemandeService.getAll().then(data => 
                        this.setState({listeCategorieDemande: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                 console.log(error);
                })
                
            }

            state = {
                ...state,
                categorieDemandeDialog: false,
                categorieDemande: this.emptyCategorieDemande
            };
        }

        this.setState(state);
    }
   editCategorieDemande(categorieDemande) {
        this.setState({
            categorieDemande,
            categorieDemandeDialog: true
        });
    }
    confirmDeleteCategorieDemande(categorieDemande) {
        this.setState({
            categorieDemande,
            deleteCategorieDemandeDialog: true
        });
    }
    deleteCategorieDemande(idCategorieDemande) {
        console.log(idCategorieDemande)
        this.categorieDemandeService.delete(parseInt(idCategorieDemande)).then(data => {
            this.notify.success()
            this.setState({
                deleteCategorieDemandeDialog: false, 
                categorieDemande: this.emptyCategorieDemande,
            });
            this.setState({loading : true})
            this.categorieDemandeService.getAll().then(data => 
                this.setState({listeCategorieDemande: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)  
                    console.log(error)
                    this.setState({loading : false})
                })
        })
    }
    onLibelleCategorieDemandeChange(e, libelleCategorieDemande) {
        const val = (e.target && e.target.value) || '';
        let categorieDemande = this.state.categorieDemande;
        categorieDemande[`${libelleCategorieDemande}`] = val;

        this.setState({categorieDemande });
    }
   
    onLibelleNiveauChange(e, niveau) {
        const val = (e.target && e.target.value) || '';
        let categorieDemande = this.state.categorieDemande;
        categorieDemande[`${niveau}`] = val;

        this.setState({categorieDemande });
    }
    onIdStructureChange(e, structure) {
        const val = (e.target && e.target.value) || '';
        let categorieDemande = this.state.categorieDemande;
        categorieDemande[`${structure}`] = val;

        this.setState({ categorieDemande });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editCategorieDemande(rowData)} style={{marginRight: '.5em'}}/>
                {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteCategorieDemande(rowData)} />*/}
            </React.Fragment>
        );
    }
    render() {
       
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const categorieDemandeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveCategorieDemande} />
            </React.Fragment>
        );
        const deleteCategorieDemandeDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteCategorieDemandeDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteCategorieDemande(this.state.categorieDemande.idCategorieDemande) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeCategorieDemande} selectionMode="single" header="Liste de Categorie de demande" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleCategorieDemande" header="Categorie demande"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="niveau" header="Niveau "  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="structure.libelleStructure" header="Structure" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher cycle"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.categorieDemandeDialog} style={{ width: '450px' }} header="Informations categorie demande" modal className="p-fluid" footer={categorieDemandeDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleStructure">Structure</label>
                        <InputText id="structure" value={this.state.categorieDemande.structure.libelleStructure} desabled required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.categorieDemande.structure.idStructure && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="libelleCategorieDemande">Libelle categorie demande *</label>
                        <InputText id="libelleCategorieDemande" value={this.state.categorieDemande.libelleCategorieDemande}  onChange={(e) => this.onLibelleCategorieDemandeChange(e, 'libelleCategorieDemande')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle categorie de demande'/>
                        {this.state.submitted && !this.state.categorieDemande.libelleCategorieDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    
                </Dialog>
                <Dialog visible={this.state.deleteCategorieDemandeDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteCategorieDemandeDialogFooter} onHide={this.hideDeleteCategorieDemandeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.categorieDemande && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default CategorieDemande
