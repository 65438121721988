
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './index.css';

  
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import React,{useState}  from 'react';
import { Column } from 'primereact/column';
import {Button} from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Controller,useForm } from 'react-hook-form';
import classNames from 'classnames';  
import { Notify } from '../../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner';
import ResponsabiliteService from '../../service/ResponsabiliteService';


export default function Responsabilite(){

    const spinner = new Spinner();
    const notify = new Notify();
    const responsabiliteService = new ResponsabiliteService();

    // Default value bloc

    const defaultValues = {
        libelleResponsabilite: '',
        tauxRabattement:'',
        indiceRabattement:'',
        idResponsabilite:''
    };
    const dialogOpenHandler = {
        add:false,
        modify:false,
        delete:false,
        
    }

    const indiceTauxRabbatement = [
        {
            name:"heure",
            value:"heure"
        },
        {
            name:"pourcentage",
            value:"pourcentage"
        }
    ]


    // variables declaration Bloc

    const [listOfResponsabilite,setListOfResponsabilite] = useState();
    const [responsabiliteDelete,setResponsabiliteDelete]=useState();
    const [saveList,setSaveList] = useState([]);
    const [cancelAndReset,setCancelAndReset]=useState(false);
    const [loading,setLoading] = useState(false);
    const [dialog,setDialog]=useState(dialogOpenHandler);
    const { control, formState: { errors }, handleSubmit, reset,setValue,getValues } = useForm({defaultValues});
    const [spin,setSpin] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);


    // variables declaration EndBloc
    // functions handler Bloc
    const handleResponsabiliteSubmit = (data) => {
        // reconstitution de la donnée à envoyer
        data.indiceRabattement=data.indiceRabattement.value;
        setSaveList([...saveList,data]);
        reset();

    };


    // add and modify user information management
    const handleSave=(dataForm=null)=>{
        setSpin(true)
        if(dialog.add && saveList.length!==0){
            setLoading(true);
            responsabiliteService.create(saveList)
            .then(()=>{
                responsabiliteService.getAll().then(response=>setListOfResponsabilite(response))
                notify.success();
                setDialog(dialogOpenHandler);
                reset();
            })
            .catch((error)=>{
                notify.echec(error.response.data.message);

            })
            .finally(()=>{
                  
                setSpin(false);
                setLoading(false);
            })
        }else if(dialog.modify && dataForm.indiceRabattement){
            setLoading(true);
            dataForm.indiceRabattement=dataForm.indiceRabattement.value
            responsabiliteService.update(dataForm)
            .then(()=>{
                responsabiliteService.getAll().then((data)=>{setListOfResponsabilite(data)});
                notify.success()
                setDialog(dialogOpenHandler);
                reset();
            })
            .catch((error)=>{notify.echec(error.response.data.message)})
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })
        }
        
    }

    const handleModification = (dataToModify)=>{
        setValue("idResponsabilite",dataToModify.idResponsabilite);
        let indiceRabattement = dataToModify.indiceRabattement ==="heure"?indiceTauxRabbatement[0]:indiceTauxRabbatement[1];
        setValue("indiceRabattement",indiceRabattement);
        setValue("libelleResponsabilite",dataToModify.libelleResponsabilite);
        setValue("tauxRabattement",dataToModify.tauxRabattement);
        setDialog({...dialog, modify:true});
    }

    

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]);
        reset(defaultValues);
    }


    // delete user information management
    const handleResponsabiliteDeletion = (dataToDelete="")=>{
        
        if(cancelAndReset){
            setSpin(true);
           setTimeout(()=>{
            setSaveList([]);
            setCancelAndReset(false);
            setDialog(dialogOpenHandler);
            setSpin(false)
           },2000);
        }else if(dialog.delete){
            setSpin(true);
            responsabiliteService.delete(responsabiliteDelete.idResponsabilite)
            .then(()=>{
                notify.success();
                setLoading(true);
                setDialog({delete:false})
                responsabiliteService.getAll().then(response=>{
                    setListOfResponsabilite(response)
                })
            })
            .catch((error)=>{
                notify.echec(error.response.data.message);
            })
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })

        }else{
            setResponsabiliteDelete(dataToDelete)
            setDialog({delete:true})
        }
    }
    


    // hide and lifecycle functions management
    const onHide = ()=>{
        if(cancelAndReset && dialog.add){
            setCancelAndReset(false);
        }else if(dialog.add || dialog.delete || dialog.modify){
            setDialog(dialogOpenHandler);
            reset(defaultValues);
        }
        setSaveList([]);
    }

    useEffect(() => {
        setLoading(true);
        responsabiliteService.getAll().then(response=>{
            setListOfResponsabilite(response);
        })
        .catch(errors=>{
            console.log(errors);
            notify.echec(errors.response.data.message)
        })
        .finally(()=>{
            setLoading(false);
        })

    }, [])


    // JSX function management
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button type="button" icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em'}} onClick={()=>handleModification(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={()=>handleResponsabiliteDeletion(rowData)} />
                </React.Fragment>
            );
        }

        
        const ResponsabiliteFooter=(
            <React.Fragment>
                 <div className="p-grid p-justify-end" style={{width:"100%"}}>
                    <Button type="submit" label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className="p-button-success" disabled={dialog.add && (spin || saveList.length===0)?true:false} style={{marginRight:"15px"}} onClick={handleSave} />
                    <Button type="button" label="Quitter" disabled={dialog.add && spin?true:false} className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
                </div>
            </React.Fragment>
        )
        const deleteResponsabiliteFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={onHide} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleResponsabiliteDeletion} />
            </React.Fragment>
            
        )
        const cancelResponsabiliteFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleResponsabiliteDeletion} />
            </React.Fragment>
            
        )

        const bodyTauxTemplate = (rowData)=>{
            return(
                <span>{rowData.indiceRabattement==="heure"?rowData.tauxRabattement+"h":rowData.tauxRabattement+"%"} </span>
            )

        }

        const header = (
            <div className="table-header p-col-12">
                <span className="p-input-icon-left">
                    <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setDialog({...dialog,add:true})} />
                    <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                    
                </span>
            </div>
        );

    // function handler EndBloc

    return (
        <div>
            
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">

                <div style={{width:"100%",textAlign:"center"}}>{header}</div>
                {
                loading ? 
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                <DataTable 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    rows={5}
                    selectionMode="single" filterMatchMode="contains"
                    paginator={true} responsive={true}
                    value={listOfResponsabilite}
                    globalFilter={globalFilter}
                    header="Liste des responsabilités"
                >
                    <Column field="libelleResponsabilite" header="Libelle" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                    <Column body={bodyTauxTemplate} header="Taux de Rabattement" headerStyle={{textTransform:"capitalize"}} filter sortable />
                    <Column header={actionHeader} body={actionBodyTemplate} style={{width:"120px"}} />
                        
                </DataTable>
                
                }
                
            </div>

            {/* Add Responsabilite Bloc */}
                <Dialog className="card" visible={dialog.add} style={{width:"60vw",height:"75vh"}} footer={ResponsabiliteFooter} onHide={onHide}>

                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center" style={{height:"30vh"}}>
                                <h5 className="text-header">Ajouter une  responsabilité enseignant</h5>
                                <form onSubmit={handleSubmit(handleResponsabiliteSubmit)} style={{height:"300px"}} className="p-fluid">
                                    <div style={{height:"230px"}}>
                                        <div className="p-field">
                                            <div >
                                                <label htmlFor="libelleResponsabilite" style={{marginRight:"15px"}}>Libelle responsabilite</label>{getFormErrorMessage('libelleResponsabilite')}
                                                <Controller name="libelleResponsabilite" control={control} render={({ field, fieldState }) => (
                                                    <InputText type="text" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                                )} />
                                            </div>
                                            
                                        </div>
                                        <div className="p-field" >
                                            <div>
                                                <label htmlFor="tauxRabattement" style={{marginRight:"15px"}}>Taux Rabattement</label>  {getFormErrorMessage('tauxRabattement')}
                                                <Controller name="tauxRabattement" control={control} render={({ field, fieldState }) => (
                                                    <InputText keyfilter="num" type="text" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                                )} />
                                                
                                            </div>
                                           
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="indiceRabattement">indice du taux de rabattement</label>{getFormErrorMessage('indiceRabattement')}

                                            <Controller name="indiceRabattement" control={control} render={({ field, fieldState }) => {
                                                 return  <Dropdown 
                                                   options={indiceTauxRabbatement} 
                                                   value={field.value}
                                                   onChange={(e)=>field.onChange(e.value)}
                                                   name={field.name} optionLabel="name"
                                                   {...field}
                                               />  
                                            }} />
                                        </div>
                                    </div>

                                    <div style={{width:"100px"}}>
                                        <Button type="submit" className="p-button-info" label="Ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                                    </div>
                                    
                                </form>
                            </div>
                        </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <h5 className="text-header">Nouvellement ajouté </h5>

                            <div className=" p-fieldset">
                                <table className="table" style={{minHeight:"300px"}}>
                                    <thead>
                                        <tr className="p-grid">
                                            <th className="p-col-5">Libelle</th>
                                            <th className="p-col-4">Taux</th>
                                            <th className="p-col-3" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            saveList.map((resp,key) =>
                                                <tr className="p-grid" key={key+1}>
                                                    <td className="p-col-5">{resp.libelleResponsabilite}</td>
                                                    <td className="p-col-4">{resp.indiceRabattement==="pourcentage"?resp.tauxRabattement+"%":resp.tauxRabattement +"h"}</td>
                                                    <td><Button icon="pi pi-trash" className="p-button-danger p-col-3" onClick={() => {removeItem(key)}} 
                                                    /></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>      
                                </table>
                               
                            </div>
                        </div>
                    </div>

                </Dialog>
            {/* Add Responsabilite EndBloc */}


            {/* Modify Responsabilite Bloc */}
                <Dialog className="card" visible={dialog.modify} style={{width:"35vw",height:"55vh"}} onHide={onHide}>

                    <div className="p-d-flex p-jc-center" style={{height:"25vh"}}>
                        <h5 className="text-header">Modification</h5>
                        <form onSubmit={handleSubmit(handleSave)} className="p-fluid">
                            <div>
                                <div className="p-field">
                                    <span >
                                        <label htmlFor="libelleResponsabilite">Libelle responsabilite</label>
                                        <Controller 
                                            control={control} rules={{ required: 'Champ obligatoire.'}}
                                            name="libelleResponsabilite"
                                            defaultValue={getValues("libelleResponsabilite")}
                                            render={({field})=>(
                                            <InputText name={field.name} id={field.name} value={field.value} {...field} />   
                                            )}
                                        />
                                        
                                    </span>
                                </div>

                                <div className="p-field">
                                    <span >
                                        <label htmlFor="Rabattement"> Taux de Rabattement </label>
                                        <Controller 
                                            control={control}
                                            name="tauxRabattement"
                                            defaultValue={getValues("tauxRabattement")}
                                            render={({field})=>(
                                                <InputText id={field.name} name={field.name} value={field.value} {...field} />
                                            )}
                                        
                                        />
                                        
                                    </span>
                                </div>
                                <div className="p-field">
                                    <label htmlFor="statut">indice du taux de rabattement</label>
                                    <Controller control={control} name="indiceRabattement" defaultValue={getValues("indiceRabattement")}
                                        render={({field})=>{
                                            return (
                                                <Dropdown 
                                                    options={indiceTauxRabbatement} 
                                                    value={field.value}                               
                                                    name={field.name} id={field.name}  optionLabel="name"
                                                    {...field}
                                                    onChange={(e)=>field.onChange(e.value)}
                                                />
                                            ) 
                                        }}
                                    />
                                        
                                </div>
                            </div>

                            <div className='mt-5'   style={{display:"flex", width:"100%"}}>
                                <Button type="submit" label="Enregistrer" className="p-button-success" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} />
                                <Button type="button" label="Quitter" disabled={spin?true:false} className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
                            </div>
                        </form>
                    </div>

                </Dialog>
            {/* Modify Responsabilite EndBloc */}


            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteResponsabiliteFooter} onHide={onHide}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>
            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelResponsabiliteFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>



        </div>
    )
}