import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class DemandeService {

    constructor(){
        this.adresse = new AdresseIP()
    }


    getInformationPaiementDemande(idDossier){
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes/"

        return axios.get(url+idDossier).then(result => result.data )
    }


   getDemandeByTypeOperationIdClient(typeOperation, idClient, idSession) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes?idCandidat="+idClient+"&typeDemande="+typeOperation+"&idSession="+idSession
    //console.log(url)
    return axios.get(url).then(res => res.data)
   }
   

   create(newDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes"
       return axios.post(url,newDemande)
                   .then(res => res.data);
   }
   
   update(idDemande ,newDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes"
       return axios.put(url+idDemande, newDemande)
                   .then(res => res.data);
   }

   delete(idDemande) {

    //console.log(idDemande)
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes/"
       return axios.patch(url+idDemande)
                   .then(res => res.data)
   }

   getAllByTypeOperation(typeOperation){
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes?typeDemande="+typeOperation
    return axios.get(url).then(res => res.data)
   }

   getDocumentsDemande(documentData){

    //console.log(documentData)
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/download-file/"
    return axios.post(url,documentData).then(res => res.data)
   }

   getDemandesClient(idCandidat ){
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes/candidat?idCandidat="+idCandidat
    return axios.get(url).then(res => res.data)
   }
   
   getDemandeSession(idSession) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/demandes/session?idSession="+idSession
    return axios.get(url).then(res => res.data)
   }

    effectuerPaiement(dataCommande, headers){
        return axios.post('https://app.ligdicash.com/pay/v01/redirect/checkout-invoice/create',dataCommande, headers).then(res => res.data)
    }


    initierPaiementLigdicash(dataInitiation){
        const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/ligdi-cash/init-transation"
        return axios.post(url, dataInitiation).then(res => res.data)
    }

}