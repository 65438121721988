import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { PickList } from 'primereact/picklist';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { AnneeService } from '../../service/AnneeService';
import { SemestreService } from '../../service/SemestreService';
import { AnneeSemestreService } from '../../service/AnneeSemestreService';

export class AnneeSemestre extends Component {
    emptyAnneeSemestre = {
        annee: null,
        semestre: null
    }
    constructor() {
        super();
        this.state = {
            listeAnneeSemestre : [],
            listeAnnee : [],
            listeSemestre : [],
            listeSemestreChoisi : [],
            AnneeSemestreDialog: false,
            deleteAnneeSemestreDialog: false,
            anneeSemestre: this.emptyAnneeSemestre,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null
        }
        this.anneeSemestreService = new AnneeSemestreService()
        this.anneeService = new AnneeService()
        this.semestreService = new SemestreService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveAnneeSemestre = this.saveAnneeSemestre.bind(this);
        this.editAnneeSemestre = this.editAnneeSemestre.bind(this);
        this.confirmDeleteAnneeSemestre = this.confirmDeleteAnneeSemestre.bind(this);
        this.deleteAnneeSemestre = this.deleteAnneeSemestre.bind(this);
        this.onIdAnneeChange = this.onIdAnneeChange.bind(this);
        this.onSemestrePicklistChange = this.onSemestrePicklistChange.bind(this);
        this.hideDeleteAnneeSemestreDialog = this.hideDeleteAnneeSemestreDialog.bind(this);
    }
    componentDidMount() {
        this.setState({loading : true})
        this.anneeSemestreService.getAll().then(data => 
            this.setState({listeAnneeSemestre: data, loading : false})
            )

            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })


            this.anneeService.getAll().then(data => {
                this.setState({listeAnnee: data})
                
            })
            .catch(error => { 
                    console.log(error)
                    this.notify.echec(error.response.data.message)
            })
            this.semestreService.getAll().then(data => {
                this.setState({listeSemestre: data, source : data})
                
            })
            .catch(error => { 
                    console.log(error)
                    this.notify.echec(error.response.data.message)
            })
    }
    openNew() {
        this.setState({
            anneeSemestre: this.emptyAnneeSemestre,
            submitted: false,
            anneeSemestreDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            anneeSemestreDialog: false
        });
    }
    hideDeleteAnneeSemestreDialog() {
        this.setState({ deleteAnneeSemestreDialog: false });
    }
    saveAnneeSemestre() {
        let state = { submitted: true };
        let listeAnneeSemestre = []
        if (this.state.anneeSemestre.annee && this.state.listeSemestreChoisi) {
            
            this.state.listeSemestreChoisi.map(se => {
                return listeAnneeSemestre.push({idAnneeAcademique : this.state.anneeSemestre.annee.idAnneeAcademique, idSemestre : se.idSemestre})
                
            })
            //console.log(listeAnneeSemestre)
            this.anneeSemestreService.create(listeAnneeSemestre).then(data => {
                this.notify.success()
                //console.log(data)
                this.setState({redirect : true})
            })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error);
            })
            state = {
                ...state,
                anneeSemestreDialog: false,
                listeSemestre: this.state.listeSemestre,
                listeSemestreChoisi: null,
            };
        }

        this.setState(state);
    }
    editAnneeSemestre(anneeSemestre) {
        this.setState({
            anneeSemestre,
            anneeSemestreDialog: true
        });
    }
    confirmDeleteAnneeSemestre(anneeSemestre) {
        this.setState({
            anneeSemestre,
            deleteAnneeSemestreDialog: true
        });
    }
    deleteAnneeSemestre(idAnneeSemestre) {
        this.anneeSemestreService.delete(parseInt(idAnneeSemestre)).then(data => {
            this.notify.success()
            this.setState({
                deleteAnneeSemestreDialog: false,
                anneeSemestre: this.emptyAnneeSemestre,
                redirect : true
            });
        })
    }
    onIdAnneeChange(e, annee) {
        const val = (e.target && e.target.value) || '';
        let anneeSemestre = this.state.anneeSemestre;
        anneeSemestre[`${annee}`] = val;

        this.setState({anneeSemestre });
    }
    onSemestrePicklistChange(e) {
        this.setState({listeSemestre: e.source, listeSemestreChoisi: e.target})

    }
    
    itemTemplate(item) {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleSemestre}</h6>
                </div>
            </div>
        );
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editAnneeSemestre(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteAnneeSemestre(rowData)} />
            </React.Fragment>
        );
    }
    render() {
        //console.log(this.state.source)
        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const anneeSemestreDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveAnneeSemestre} />
            </React.Fragment>
        );
        const deleteAnneeSemestreDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteAnneeSemestreDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteAnneeSemestre(this.state.anneeSemestre.idAnneeSemestre) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeAnneeSemestre} selectionMode="single" header="Liste des années semestres" paginator={true} rows={5}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleAnnee" header="Année académique" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher année"/>
                            <Column field="libelleSemestre" header="Semestre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher semestre"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                        
                    </div>
                </div>

                <Dialog visible={this.state.anneeSemestreDialog} style={{ width: '850px' }} header="Informations année-semestre" modal className="p-fluid" footer={anneeSemestreDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field p-col-4">
                        <label htmlFor="libelleAnneeAcademique">Année académique</label>
                        <Dropdown optionLabel="anneeDebut" optionValue="idAnneeAcademique" value={this.state.anneeSemestre.annee} options={this.state.listeAnnee} onChange={(e) => {this.onIdAnneeChange(e, 'annee')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner année"/>
                        {this.state.submitted && !this.state.anneeSemestre.annee && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field" style={{marginTop : 30}}>
                        <PickList source={this.state.listeSemestre} target={this.state.listeSemestreChoisi} itemTemplate={this.itemTemplate}
                            sourceHeader="Semestre" targetHeader="Semestre choisis"
                            sourceStyle={{ height: '250px', width : '250px' }} targetStyle={{ width : '250px' ,height: '250px' }}
                            onChange={this.onSemestrePicklistChange}>
                        </PickList>
                    </div>
                
                </Dialog>
                <Dialog visible={this.state.deleteAnneeSemestreDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteAnneeSemestreDialogFooter} onHide={this.hideDeleteAnneeSemestreDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.anneeSemestre && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default AnneeSemestre
