import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ReclamationService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-reclamation"
       return axios.get(url).then(res => res.data) 
   }
   
   create(newSessionReclamation) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-reclamation"
       return axios.post(url,newSessionReclamation)
                   .then(res => res.data);
   }
   
   update(idSessionReclamation ,newSessionReclamation) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-reclamation/"
       return axios.put(url+idSessionReclamation, newSessionReclamation)
                   .then(res => res.data);
   }

   delete(idSessionReclamation) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-reclamation/"
       return axios.patch(url+idSessionReclamation)
                   .then(res => res.data)
   }
   get(idParcours) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres/?idParcours="+idParcours
    return axios.get(url)
                .then(res => res.data)
    }
    getListSession(idSession, idParcoursFiliere, idAnnee, idSemestre) {
        console.log('idSession : '+idSession )
        console.log('idParcoursFiliere : '+idParcoursFiliere )
        console.log('idAnneeAcademique : '+idAnnee )
        console.log('idSemestre : '+idSemestre )
        const url = `${this.adresse.getAdresseIP()}/service/api/campusfaso-lmd/campus-faso/session-reclamation`
        //const url = `${this.adresse.getAdresseIP()}/service/api/campusfaso-lmd/campus-faso/session-reclamation?idSession=${idSession}&idParcoursFiliere=${idParcoursFiliere}&idAnneeAcademique=${idAnnee}&idSemestre=${idSemestre}`
        return axios.get(url)
            .then(res => res.data)
    }
    getListeReclamationSession(idSessionReclamation) {
        const url = `${this.adresse.getAdresseIP()}/service/api/campusfaso-lmd/campus-faso/reclamation-session?idSessionReclamation=${idSessionReclamation}`
        return axios.get(url)
            .then(res => res.data)
    }

    getExamenEtudiant(idSession, idEtudiant) {
        const url = `${this.adresse.getAdresseIP()}/service/api/campusfaso-lmd/campus-faso/etudiant-examen?idSession=${idSession}&idEtudiant=${idEtudiant}`
        return axios.get(url)
            .then(res => res.data)
    }

    createReclamation(listeReclamation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/demande-reclamation"
       return axios.post(url,listeReclamation)
                   .then(res => res.data);
    }

    getListeReclamationSessionEtudiant(idEtudiant,idSessionReclamation){
        const url = `${this.adresse.getAdresseIP()}/service/api/campusfaso-lmd/campus-faso/demande-reclamation?idEtudiant=${idEtudiant}&idSessionReclamation=${idSessionReclamation}`
        return axios.get(url)
            .then(res => res.data)
    }
    getListeDemandeReclamationSession(idSessionReclamation){
        const url = `${this.adresse.getAdresseIP()}/service/api/campusfaso-lmd/campus-faso/demande-reclamation?idSessionReclamation=${idSessionReclamation}`
        return axios.get(url)
            .then(res => res.data)
    }
}