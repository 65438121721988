import { AdresseIP } from "../../components/AdresseIP";
import axios from "axios";


class TypeInfrastructureService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/types-infrastructures";
    }

    getAll(){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }

    create(listTypeInfrastructure){
        return axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,listTypeInfrastructure);
    }
    update(typeInfrastructure){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${typeInfrastructure.idTypeInfrastructure}`,typeInfrastructure);
    }
    delete(idTypeInfrastructure){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${idTypeInfrastructure}`);
    }


}

export default TypeInfrastructureService;