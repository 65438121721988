import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer } from "react-toastify";
import ProgrammationService from "../service/ProgrammationService";
import { SemestreService } from "../../lmd/service/SemestreService";
import { CohorteServices } from "../service/CohorteServices";
import { Spinner } from "../../components/Spinner";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { AnneeService } from "../../lmd/service/AnneeService";
import DashboardService from "../service/DashboardService";
import {IesrService} from "../../lmd/service/IesrService"
import { Notify } from "../../components/Notify";

export default function DashbordEnseignant(){

    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);

    const {control:searchControl,setValue:searchSetValue} = useForm({defaultValues:{semestre:'',parcours:'',cohorte:''}});
    const [programmation, setprogrammation] = useState({nbProgrammer:undefined,nbRealiser:undefined});
    const [listSemestreSearch, setListSemestreSearch] = useState([]);
    const [listeAnnee, setListeAnnee] = useState([]);
    const [annee, setAnnee] = useState({});
    const [departements, setDepartements] = useState([]);
    const [departement, setDepartement] = useState({});
    const [etablissements, setEtablissements] = useState([]);
    const [etablissement, setEtablissement] = useState({});
    const [loading, setloading] = useState(false);
    const [listCohorteSearch, setlistCohorteSearch] = useState([]);
    const [programmationByParcours, setprogrammationByParcours] = useState([]);
    const [programmationFilter, setprogrammationFilter] = useState([]);
    const [listParcours, setlistParcours] = useState([]);
    const [listeElementsEffectues, setListeElementsEffectues] = useState([]);
    const [enseignant, setEnseignant] = useState(null);
    const [infoVhEns, setInfoVhEns] = useState(null);
    const [ficheSuiviDialog, SetFicheSuiviDialog] = useState(false);
    const [semestre, setSemestre] = useState(null);
    const [listeSemestre, setListeSetSemestre] = useState([]);
    const [cohorte, setCohorte] = useState(null);
    const [listeCohorte, setListeCohorte] = useState([]);
    const [listeActiviteDep, setListeActiviteDep] = useState([]);
    const [listeVarPie, setListeVarPie] = useState(null);
    const [isAffiche, setIsAffiche] = useState(false);
    const [listeIes, setListeIes] = useState([]);
    const [ies, setIes] = useState(null);
    const [listeEc, setListeEc] = useState([]);
    const [ec, setEc] = useState(null);
    const [chartData, setCharData] = useState({})
    const [lineData, setLineData] = useState({})
    const [ficheSuiviEcDialog, setFicheSuiviEcDialog] = useState(null)

    const programmationService = new ProgrammationService();
    const semestreService = new SemestreService();
    const cohorteServices = new CohorteServices();
    const spinner = new Spinner();
    const anneeService = new AnneeService();
    const dashboardService = new DashboardService();
    const iesrService = new IesrService();
    const notify = new Notify();
    
    

    useEffect(() => {
        /*setloading(true);
        programmationService.getProgrammationDepartement(code_departement).then((response=[])=>{
            let nbProgrammer = response.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
            let nbRealiser = response.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
            setprogrammation({...programmation,nbProgrammer,nbRealiser});
        })
        .finally(()=>setloading(false))*/

        //cohorteServices.getListeParcoursDepartement(code_departement).then(response=>{console.log(response);setlistParcours(response)})
        
        anneeService.getAll().then(data => {
            setListeAnnee(data);  
            
        }).catch(error => {
            notify.echec(error.response.data.message)
        })
        
        const matricule = token.matricule
        //console.log(matricule)
            dashboardService.getEnseignant(matricule).then(dataEns => {
                setEnseignant(dataEns)
        })

        semestreService.getAll().then(data => {
            setListeSetSemestre(data)
        })

    }, []);

    const onCohorteChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setCohorte(val)
        const matricule = token.matricule
            cohorteServices.getListeEcCohorteEns(val.idCohorte, annee.idAnneeAcademique, matricule).then(data => {
                setListeEc(data)
        })
        
    }
    
    const onDepartementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setDepartement(val)
        cohorteServices.getListeCohorteDepartement(val.idDepartementEtab).then(data => {
            setListeCohorte(data)
            //console.log(data)
        })
    }

    const onEtablissementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setEtablissement(val)
        cohorteServices.getListeDepartementEtab(val.idEtablissement).then(data => {
            setDepartements(data.departementEtabDtos)
            //console.log(data)
        })
    }

    const onAnneeChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setAnnee(val)
    }

    const onSemestreChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setSemestre(val)
    }

    const onIesChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setIes(val)

        //console.log(token.matricule)
        const matricule = token.matricule

        cohorteServices.getListeCohorteIesEns(val.idIesr, annee.idAnneeAcademique, matricule).then(data => {
            setListeCohorte(data);
            //console.log(data)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
    }

    const onEcChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setEc(val)
    }

    const listeActivite = () => {
        //console.log(annee)
        //console.log(token.matricule)
        const matricule = token.matricule
        dashboardService.getInfoVhEns(matricule, annee.idAnneeAcademique).then(dataVh => {
            setInfoVhEns(dataVh)
            //console.log(dataVh)
        })
        dashboardService.getActivites(annee.idAnneeAcademique, matricule).then(data => {

            //console.log(data.elementConstitutifTbDtoList)
            //console.log('Ok')

            
            const elements = data.elementConstitutifTbDtoList
            let nbHeureRestant = null
            let listeElement = []
            let element = null
            elements.map(el => {
                nbHeureRestant = el.nombreHeureConf - el.nombreHeure
                element = {
                    dateSeanceEff: el.dateSeanceEff,
                    departement: el.departement,
                    idElementConstitutif: el.idElementConstitutif,
                    idIes: el.idIes,
                    idTypeActivite: el.idTypeActivite,
                    libelleElementConstitutif: el.libelleElementConstitutif,
                    libelleIes: el.libelleIes,
                    libelleTypeActivite: el.libelleTypeActivite,
                    libelleUniteEnseignement: el.libelleUniteEnseignement,
                    nombreHeure: el.nombreHeure,
                    nombreHeureConf: el.nombreHeureConf,
                    nombreHeureRestant : nbHeureRestant
                }
                listeElement.push(element)

            })

            //console.log(listeElement)
            setListeElementsEffectues(listeElement)
        })
    }
    const ficheSuivi = () => {
        semestreService.getAll().then(data => {
            setListeSetSemestre(data)
        })

        SetFicheSuiviDialog(true)
    }

    const ficheSuiviEc = () => {

        iesrService.getAll().then(data => {
            setListeIes(data)
            //console.log(data)
        })

        /*
        anneeService.getAll().then(data => {
            setListeAnnee(data)
        })
        */

        setFicheSuiviEcDialog(true)
    }

    const ficheSuiviPdf = () => {
        const matricule = token.matricule
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_SEANCE_EFFECTUEE",
            annee: parseInt(annee.idAnneeAcademique),
            matriculeEnseignant: matricule,
            idSemestre : semestre.idSemestre
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuivi(fiche).then(res => {
            const matricule = token.matricule
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${matricule}`;
            link.download = fileName;
            link.click();
                  

        }).catch(error => {
            console.log(error)

            console.log(error.response)
            notify.echec(error.response.data.message)

        })
    }

    const ficheSuiviEcPdf = () => {
        const matricule = token.matricule
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_VACATION",
            annee: parseInt(annee.idAnneeAcademique),
            matriculeEnseignant: matricule,
            idCohorte : cohorte.idCohorte,
            idElementConstitutif : ec.idElementConstitutif
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuiviEc(fiche).then(res => {
            const matricule = token.matricule
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${matricule}`;
            link.download = fileName;
            link.click();
                  

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
    }

    const ficheSuiviDepartement = () => {
        
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_SUIVI_DEPART",
            idDepartement : token.code_departement,
            annee: parseInt(annee.idAnneeAcademique),
            idCohorte : parseInt(cohorte.idCohorte),
            idSemestre : parseInt(semestre.idSemestre)
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuiviDepartement(fiche).then(res => {

            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${token.code_departement}`;
            link.download = fileName;
            link.click();         

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
    }

    const hideFicheSuiviDialog = () => {
        SetFicheSuiviDialog(false)
    }

    const hideFicheSuiviEcDialog = () => {
        setFicheSuiviEcDialog(false)
    }

    // const handleCohorteSearch = cohorte=>{
    //     let result =[] ;
    //     programmationFilter.forEach(programmation=>{
    //         programmation.cohorte.filter(programmation=>programmation.idCohorte === cohorte.idCohorte)
    //         for(let element of programmation.cohorte){
    //             if(element.idCohorte === cohorte.idCohorte){
    //                 result.push(programmation);
    //             }
    //         }
    //     });
    //     // programmationGet(result);
    // }

    const ficheSuiviDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideFicheSuiviDialog} />
            <Button label="Affichier la fiche" icon="pi pi-times" className="p-button-text" onClick={ficheSuiviPdf} />
        </React.Fragment>
    );

    const ficheSuiviEcDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideFicheSuiviEcDialog} />
            <Button label="Affichier la fiche" icon="pi pi-times" className="p-button-text" onClick={ficheSuiviEcPdf} />
        </React.Fragment>
    );

    return(
        <React.Fragment>

            <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
            />
            <div className="card">
            {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                
                <div className="p-col-12 p-grid">
                    <div className="p-col-12" style={{marginTop : 10}}>
                        <Dropdown   optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee} placeholder="Choisir une année"
                        onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus/>
                        {/*<Dropdown style={{marginLeft : 10}} optionLabel="" optionValue=""  placeholder="Choisir un semestre"/>*/}
                        <Button style={{marginLeft : 10}} onClick={listeActivite} label="Lister" disabled={!annee.idAnneeAcademique}/>
                    </div>
                    <div className="p-col-12 p-grid" style={{marginTop : 10}}>
                        <Panel header={"Mes informations"} className="p-col-5 card">
                        {enseignant ?
                        <table>
                        
                                <thead>
                                    <tr>
                                        <th style={{width : 300}}>Nom</th>
                                        <th>{enseignant.nomEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Prénom(s)</th>
                                        <th>{enseignant.prenomEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <th>{enseignant.emailEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Grade</th>
                                        <th>{enseignant.gradeEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <th>{enseignant.statutEnseignant}</th>
                                    </tr>
                                </thead>
                                
                            </table>
                            :
                            ""
                            }
                        </Panel>
                        <div className="p-col-2"></div>
                        <Panel header={"Synthèse horaire"} className="p-col-5">
                        {infoVhEns ?
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{width : 300}}>Désignation</th>
                                        <th>Volume horaire</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    
                                    <tr>
                                        <td>Volume Horaire CM</td>
                                        <td>{infoVhEns.volumeHoraireCmConfier}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TD</td>
                                        <td>{infoVhEns.volumeHoraireTdConfier}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TP</td>
                                        <td>{infoVhEns.volumeHoraireTpConfier}   </td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire CM eff.</td>
                                        <td>{infoVhEns.volumeHoraireCmEff}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TD eff.</td>
                                        <td>{infoVhEns.volumeHoraireTdEff}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TP eff.</td>
                                        <td>{infoVhEns.volumeHoraireTpEff}</td>
                                    </tr>
                                   
                                </tbody>
                                
                            </table>
                            :
                            ""
                            }
                        </Panel>
                    </div>
                    <div className="p-col-12 p-grid">
                            <div className="p-grid" style={{marginTop : 10}}>
                                <Button style={{marginLeft : 10, marginBottom : 10}} onClick={ficheSuivi} label="Fiche de suivi/Pédagogique" disabled={!annee.idAnneeAcademique}/>
                                <Button style={{marginLeft : 10, marginBottom : 10}} onClick={ficheSuiviEc} label="Fiche de suivi/EC" disabled={!annee.idAnneeAcademique}/>
                                <DataTable value={listeElementsEffectues}   header="Etat d'execution des cours" paginator={true} rows={5}
                                    filterMatchMode="contains" responsive={true}  >
                                    
                                    <Column field="libelleIes" header="Universtié"/>
                                    <Column field="departement" header="Département" />
                                    <Column field="libelleElementConstitutif" header="ECUE" />
                                    <Column field="libelleTypeActivite" header="Activité" />
                                    <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure effectué" />
                                    <Column field="nombreHeureConf" header="Nb.heure confié" />
                                    <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                                </DataTable>
                            </div>
                    </div>
                    <Dialog visible={ficheSuiviDialog} style={{ width: '450px' }} header="Informations fiche suivi" modal className="p-fluid" footer={ficheSuiviDialogFooter} onHide={hideFicheSuiviDialog}>
                        <div className="p-field">
                            <label htmlFor="semestre">Semestre</label>
                            <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir une semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        </div>
                    </Dialog>
                    <Dialog visible={ficheSuiviEcDialog} style={{ width: '900px' }} header="Informations fiche suivi/EC" modal className="p-fluid" footer={ficheSuiviEcDialogFooter} onHide={hideFicheSuiviEcDialog}>
                        <div className="p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="libelleIesr">Universtié</label>
                                <Dropdown   style={{marginLeft : 10}} optionLabel="libelleIesr" optionValue={ies} value={ies} options={listeIes} placeholder="Choisir une université" onChange={(e) => {onIesChange(e, "ies")}} required autoFocus/>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="libelleCohorte">Cohortes</label>
                                <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="libelleElementConstitutif">Element Constitutif</label>
                                <Dropdown   optionLabel="libelleElementConstitutif" optionValue={ec} value={ec} options={listeEc} placeholder="Choisir un EC" onChange={(e) => {onEcChange(e, "ec")}} required autoFocus/>
                            </div>
                        </div>
                        
                </Dialog>
                </div>
            }
            </div>
            
        </React.Fragment>
    )
}