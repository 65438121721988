import React, { useState, useEffect, useRef } from "react";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";

import { AnneeService } from "../../lmd/service/AnneeService";
import { DashboardService } from "../service/DashboardService";
import { IesrService } from "../service/IesrService";
import { ParcoursFiliereService } from "../../lmd/service/ParcoursFiliereService";
import { Spinner } from "../../components/Spinner";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { Notify } from "../../components/Notify";

const Dashboard = () => {
    let emptyStatDelib = {
        idDepartement: null,
        idAnnee: null,
        idSemestre: null,
        listeParcours: [
            {
                idParcours: null,
                libelleParcours: "",
                statutDelibearition: true,
            },
        ],
    };

    let items = ["Effectif total", "Masculin", "Feminin"];
    let colorData = ["#008000", "#3366ff", "#ffcc99"];
    let donnees = [157, 72, 85];
    const chartDataPie = {
        labels: items,
        datasets: [
            {
                data: donnees,
                backgroundColor: colorData,
                /*hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]*/
            },
        ],
    };
    const lightOptionsPie = {
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
    };

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y1: {
                    type: "linear",
                    display: true,
                    position: "right",
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: "#ebedef",
                    },
                },
            },
        };

        return {
            basicOptions,
            multiAxisOptions,
        };
    };

    const { basicOptions, multiAxisOptions } = getLightTheme();

    const goToSession = () => {
        //props.history.pushState("/lmd/session");
        window.location.hash("/lmd/session");
    };

    const tokenDecoded = localStorage.getItem("tokenDecoded");
    const token = JSON.parse(tokenDecoded);
    const campusDGESup = localStorage.getItem("campusDGESup");
    const campusVpEip = localStorage.getItem("campusVpEip");

    const isDGESup = parseInt(JSON.parse(campusDGESup));
    const isVp = parseInt(JSON.parse(campusVpEip));

    const [curentAnnee, setCurentAnnee] = useState({});
    const [loadingEtablissement, setLoadingEtablissement] = useState(false);
    const [loadingIesr, setLoadingIesr] = useState(false);
    const [loadingDepartement, setLoadingDepartement] = useState(false);
    const [loadingParcours, setLoadingParcours] = useState(false);

    const [listeAnnee, setListeAnnee] = useState([]);
    const [listeIesr, setListeIesr] = useState([]);
    const [listeEtablissement, setListeEtablissement] = useState([]);
    const [listeUniversite, setListeUniversite] = useState([]);
    const [effectifParGenre, setEffectifParGenre] = useState({});

    const [iesr, setIesr] = useState();
    const [etab, setEtab] = useState();
    const [listeDept, setListeDept] = useState([]);
    const [dept, setDept] = useState();
    const [listeParcoursDepartement, setListeParcoursDepartement] = useState(
        []
    );
    const [parcours, setParcours] = useState();

    const [listeEtudiantParcoursNiveau, setListeEtudiantParcoursNiveau] =
        useState([]);

    const [loadingUniv, setLoadingUniv] = useState(false);
    const [dialogParcours, setDialogParcours] = useState(false);

    const anneeService = new AnneeService();
    const iesrService = new IesrService();
    const cohorteServices = new CohorteServices();
    const parcourService = new ParcoursFiliereService();
    const dashboardService = new DashboardService();

    const spinner = new Spinner();

    const onChampsChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === 'iesr'){
            setIesr(val)
            setLoadingEtablissement(true)
            dashboardService
                    .getListeEtudiantEtab(val.idIesr, curentAnnee.idAnneeAcademique)
                    .then((data) => {
                        setListeEtablissement(data);
                        setListeDept([]);
                        setLoadingEtablissement(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoadingEtablissement(false);
                    });
        }
        if (field === "etab") {
            setEtab(val);

            setLoadingDepartement(true);
            dashboardService
                .getListeEtudiantDept(
                    val.idEtablissement,
                    curentAnnee.idAnneeAcademique
                )
                .then((data) => {
                    //console.log(data)
                    setListeDept(data);
                    setLoadingDepartement(false);

                    setLoadingParcours(true);
                    parcourService
                        .getListeDepartementEtablissement(val.idEtablissement)
                        .then((data) => {
                            setListeParcoursDepartement(data);
                            setLoadingParcours(false);

                            //console.log(data)
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoadingParcours(false);
                        });
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingDepartement(false);
                });
        } else if (field === "parcours") {
            setParcours(val);

            //console.log(val)

            const idAnnee = curentAnnee.idAnneeAcademique;
            const idDepartement = dept.idDepartementEtab;
            const idParcours = val.idParcours;

            setLoadingParcours(true);
            dashboardService
                .getListeEtudiantParcours(idDepartement, idParcours, idAnnee)
                .then((data) => {
                    listeEtudiantParcoursNiveau(data);
                    setLoadingParcours(false);
                })
                .catch((error) => {
                    console.log(error);
                });

            /*
            let niveau = []
            semestreService.get(val.idParcours).then(data => {
                let semestre = data
                setListeSemestre(data)
                niveauService.getAll().then(response=>{
                    response.map(niv => {
                        semestre.map(se => {
                            if(niv.idNiveau === se.idNiveau)
                                niveau.push(niv)
                        })
                    })
                    let collectionIdNiveau = []
                    niveau.map(nive => {
                        collectionIdNiveau.push(nive.idNiveau)
                    })
                    let listeNiveauParcours = []
                    niveau.map( (nivo, index) => {
                        for(let i=index+1;i<collectionIdNiveau.length; i++){
                            if(nivo.idNiveau === collectionIdNiveau[i]){
                                listeNiveauParcours.push(nivo)
                            }   
                        }
                        
                    })
                    setListeNiveau(listeNiveauParcours)
                })
            })
            */
        } else if (field === "departement") {
            setDept(val);
            parcourService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    setListeParcoursDepartement(data);

                    //console.log(data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        anneeService
            .getAll()
            .then((data) => {
                setListeAnnee(data);

                let nbAnnee = data.length;

                let anneeAcademique = data[nbAnnee - 1];
                setCurentAnnee(anneeAcademique);

                //setLoadingEtablissement(true);

                const idAnnee = anneeAcademique.idAnneeAcademique;

                dashboardService
                    .getStatistiqueGenre(idAnnee)
                    .then((data) => {
                        //console.log(data)

                        setEffectifParGenre(data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                /*setLoadingEtablissement(true);
                dashboardService
                    .getListeEtudiantEtab(token.code_iesr, idAnnee)
                    .then((data) => {
                        setListeEtablissement(data);
                        setListeDept([]);
                        setLoadingEtablissement(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoadingEtablissement(false);
                    });*/

                //////////////////////////////////////////////////
                /////////////////////////////////////////////////
            })
            .catch((error) => console.log(error));

        
            iesrService
                .getAll()
                .then((data) => {
                    console.log(data)
                    setListeIesr(data);
                })
                .catch((error) => console.log(error));
        
    }, []);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
            },
        },
    });

    const onCurentAnneeChange = (event) => {
        setCurentAnnee(event.target.value);
        setLoadingUniv(true);

        dashboardService
            .getStatistiqueGenre(event.target.value.idAnneeAcademique)
            .then((data) => {
                //console.log(data)
            })
            .catch((error) => {
                console.log(error);
            });

        iesrService
            .getStatistiqueNombreEtudiants(event.target.value.idAnneeAcademique)
            .then((data) => {
                setListeUniversite(data.iesDtoList);
                setLoadingUniv(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingUniv(false);
            });

        if (iesr) {
            const idAnnee = event.target.value.idAnneeAcademique;
            setLoadingEtablissement(true);
            dashboardService
                .getListeEtudiantEtab(iesr.idIesr, idAnnee)
                .then((data) => {
                    setListeEtablissement(data);
                    setLoadingEtablissement(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingEtablissement(false);
                });
        }
    };

    const listeParcours = (depart) => {};

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-eye"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Liste des parcours"
                    onClick={() => listeParcours(rowData)}
                />
            </React.Fragment>
        );
    };

    const listeParcoursDialogFooter = (
        <React.Fragment>
            <Button
                label="Quitter"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setDialogParcours(false);
        setListeEtudiantParcoursNiveau([]);
        setParcours({});
    };

    return (
        <div className="card">
            <div className="p-grid">
                <div className="p-col-12">
                    <hr />
                    <h5>Année académique </h5>
                    <Dropdown
                        optionLabel="libelleAnnee"
                        optionValue={curentAnnee.libelleAnnee}
                        value={curentAnnee}
                        onChange={(e) => {
                            onCurentAnneeChange(e);
                        }}
                        options={listeAnnee}
                        placeholder="Choisir une année académique"
                        className="p-col-12"
                    />
                    <hr />
                </div>
            </div>

            <div className="p-grid">
                <div className="p-col-6">
                    <h5>Buttons d'action</h5>
                    <div className="p-grid">
                        <div>
                            <Button
                                onClick={(e) => (window.location.hash = "#")}
                                label="Statistiques inscription"
                            />
                            <Button
                                onClick={(e) => (window.location.hash = "#")}
                                style={{ marginLeft: 10 }}
                                label="Statistiques déliberation"
                            />
                            <Button
                                onClick={(e) => (window.location.hash = "#")}
                                style={{ marginLeft: 10 }}
                                label="Statistiques offres de formation"
                            />
                        </div>
                    </div>

                    <div className="p-grid" style={{ marginTop: 10 }}>
                        <div className="p-grid">
                            <Button
                                onClick={(e) =>
                                    (window.location.hash =
                                        "/lmd/gestion-surveillants")
                                }
                                style={{ marginLeft: 10 }}
                                label="Surveillants"
                            />
                            <Button
                                onClick={(e) =>
                                    (window.location.hash = "/lmd/deliberation")
                                }
                                style={{ marginLeft: 10 }}
                                label="Déliberations"
                            />
                            <Button
                                onClick={(e) =>
                                    (window.location.hash =
                                        "/lmd/pv-deliberation")
                                }
                                style={{ marginLeft: 10 }}
                                label="PV de déliberation"
                            />
                        </div>

                        <div
                            className="p-grid p-col-11"
                            style={{ marginTop: 20, marginRight: 10 }}
                        >
                            {/*<h5>Liste des Departements</h5>*/}
                            <br />
                            <div className="p-grid p-col-12" style={{ marginTop: 10 }}>
                                <div className="p-col-6">
                                    <Dropdown
                                        optionLabel="libelleIesr"
                                        value={iesr}
                                        options={listeIesr}
                                        onChange={(e) => {
                                            onChampsChange(e, "iesr");
                                        }}
                                        placeholder="Choisir un IESR"
                                    />
                                </div>
                                <div className="p-col-6">
                                    <Dropdown
                                        optionLabel="nomEtablissement"
                                        optionValue={etab}
                                        value={etab}
                                        options={listeEtablissement}
                                        onChange={(e) => {
                                            onChampsChange(e, "etab");
                                        }}
                                        placeholder="Choisir un établissement"
                                    />
                                </div>
                            </div>
                          {loadingDepartement ? (
                                <div className="p-grid">
                                    <div className="p-col-6"></div>
                                    <div className="p-col-6">
                                        {spinner.loadingSpinner(
                                            "spin",
                                            "black"
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="p-grid"
                                    style={{ marginTop: 10 }}
                                >
                                    <DataTable
                                        value={listeDept}
                                        header="Nombre d'etudiants par département"
                                        paginator={true}
                                        rows={5}
                                        filterMatchMode="contains"
                                        responsive={true}
                                    >
                                        <Column
                                            field="lbDepartementEtab"
                                            header="Département"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Departement"
                                        />
                                        <Column
                                            field="effectifEtudiant"
                                            header="Nombre d'etudiants"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Nombre d'étudiants"
                                        />
                                        <Column
                                            field="effectifGarcon"
                                            header="Nombre de garçons"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Nombre de garçons"
                                        />
                                        <Column
                                            field="effectifFille"
                                            header="Nombre de filles"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Nombre de filles"
                                        />
                                    </DataTable>
                                </div>
                            )} 
                            <br/>
                             <div className="p-grid p-col-12" style={{ marginTop: 10 }}>
                                <div className="p-col-6">
                                    <Dropdown
                                        optionLabel="libelleIesr"
                                        value={iesr}
                                        options={listeIesr}
                                        onChange={(e) => {
                                            onChampsChange(e, "iesr");
                                        }}
                                        placeholder="Choisir un IESR"
                                    />
                                </div>
                            </div>
                            {loadingEtablissement ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                        ) : (
                            <div className="p-grid" style={{ marginTop: 10 }}>
                                <DataTable
                                    value={listeEtablissement}
                                    header="Nombre d'etudiants par établissement"
                                    paginator={true}
                                    rows={5}
                                    filterMatchMode="contains"
                                    responsive={true}
                                >
                                    <Column
                                        field="nomEtablissement"
                                        header="Etablissement"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Etablissement"
                                    />
                                    <Column
                                        field="effectifEtudiant"
                                        header="Nombre d'etudiants"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre étudiants"
                                    />
                                    <Column
                                        field="effectifGarcon"
                                        header="Nombre de garçon"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre de garçon"
                                    />
                                    <Column
                                        field="effectifFille"
                                        header="Nombre de fille"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre de fille"
                                    />
                                </DataTable>
                            </div>
                            
                        )}
                             
                            
                        </div>

                        <div className="p-grid" style={{ marginTop: 20 }}>
                            <h5>Proportion des etudiants par genre </h5>
                            <br />
                            {
                                <Chart
                                    className="p-col-12"
                                    type="pie"
                                    data={chartDataPie}
                                    options={lightOptionsPie}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="p-col-6">
                    <h5>Liste des IESR</h5>

                    <div className="p-grid" style={{ marginTop: 10 }}>
                        {loadingIesr ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                        ) : (
                            <div className="p-grid" style={{ marginTop: 10 }}>
                                <DataTable
                                    value={listeIesr}
                                    header="Nombre d'etudiants par IESR"
                                    paginator={true}
                                    rows={5}
                                    filterMatchMode="contains"
                                    responsive={true}
                                >
                                    <Column
                                        field="libelleIesr"
                                        header="IESR"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="IESR"
                                    />
                                    <Column
                                        field="sigleIesr"
                                        header="Sigle"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="IESR"
                                    />
                                    <Column
                                        field="effectifEtudiant"
                                        header="Nombre d'etudiants"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre étudiants"
                                    />
                                    <Column
                                        field="effectifGarcon"
                                        header="Nombre de garçon"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre de garçon"
                                    />
                                    <Column
                                        field="effectifFille"
                                        header="Nombre de fille"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre de fille"
                                    />
                                </DataTable>
                            </div>
                        )}
                    </div>

                    <div className="p-grid" style={{ marginTop: 20 }}>
                        <h5>Liste des parcours</h5>
                        <hr />
                        <div className="p-grid" style={{ marginTop: 30 }}>
                            <Dropdown
                                optionLabel="lbDepartementEtab"
                                optionValue={parcours}
                                value={parcours}
                                options={listeParcoursDepartement}
                                onChange={(e) => {
                                    onChampsChange(e, "departement");
                                }}
                                placeholder="Choisir un departement"
                            />
                        </div>

                        {loadingParcours ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                        ) : (
                            <div className="p-grid" style={{ marginTop: 10 }}>
                                <DataTable
                                    value={listeEtudiantParcoursNiveau}
                                    header="Nombre d'etudiants par parcours et par niveau"
                                    paginator={true}
                                    rows={5}
                                    filterMatchMode="contains"
                                    responsive={true}
                                >
                                    <Column
                                        field="libelleNiveau"
                                        header="Niveau"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre d'étudiants"
                                    />
                                    <Column
                                        field="effectifEtudiant"
                                        header="Nombre d'etudiants"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre d'étudiants"
                                    />
                                    <Column
                                        field="effectifGarcon"
                                        header="Nombre de garçons"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre de garçons"
                                    />
                                    <Column
                                        field="effectifFille"
                                        header="Nombre de filles"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nombre de filles"
                                    />
                                </DataTable>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Dialog
                visible={dialogParcours}
                style={{ width: "75%" }}
                header="Statistique par parcours"
                modal
                className="p-fluid"
                footer={listeParcoursDialogFooter}
                onHide={hideDialog}
            >
                <div className="p-grid">
                    <div className="p-col-12">
                        <hr />
                        Choisir le parcours
                        <Dropdown
                            optionLabel="libelleParcours"
                            optionValue={parcours}
                            value={parcours}
                            options={listeParcoursDepartement}
                            onChange={(e) => {
                                onChampsChange(e, "parcours");
                            }}
                            placeholder="Choisir un parcours"
                        />
                    </div>
                </div>

                {loadingParcours ? (
                    <div className="p-grid">
                        <div className="p-col-6"></div>
                        <div className="p-col-6">
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                ) : (
                    <div className="p-grid" style={{ marginTop: 10 }}></div>
                )}
            </Dialog>
        </div>
    );
};

export default Dashboard;
