import React , {useState,useEffect} from 'react';
import {FullCalendar} from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import fr from '@fullcalendar/core/locales/fr';
import { Button } from 'primereact/button';
import { ToastContainer } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Spinner } from '../../components/Spinner';
import { Controller,useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Editor} from 'primereact/editor'
import { Calendar } from 'primereact/calendar';
import ProgrammationService from '../service/ProgrammationService';
import {Notify} from '../../components/Notify';
import EnseignantService from '../service/EnseignantService';
import SeanceEffectuerService from '../service/SeanceEffectuerService';
import {AnneeService} from '../../lmd/service/AnneeService'
import { Sidebar } from 'primereact/sidebar';



import './parametres/index.css';
import Notification from './Notification';
import EmploiDuTempsService from '../service/EmploiDuTempsService';

export default function EmploiDuTempsEnseignant(props){
    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);
    
    const defaultValues = {
        elementConstitutif:'',
        parcours:'',
        anneeAcademique:'',
        niveau:'',
        semestre:'',
        contenuCours:'',
        heureFinSeanceEff:'',
        heureDebutSeanceEff:'',
        dateExecution:'',
        enseignant:'',
        typeActivite:'',
        heureFinSeanceProg:'',
        heureDebutSeanceProg:'',
        dateProgrammation:'',
        infrastructure:'',
        idSeanceProg:'',
        cohorte:"",
        valideChef:"",
        statutSeanceEff:"",
    }


    let defaultDialog = {
        add:false,
        modify:false,
        delete:false,
        detail:false,
        nonValider:false
    }

    const addLocale ={
        firstDayOfWeek: 1,
        dayNames: ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
        dayNamesShort: ['dim','lun','mar','mer','jeu','ven','sam'],
        dayNamesMin: ['D','L','M','Me','J','V','S'],
        monthNames: ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'],
        monthNamesShort: "",
        today: "aujourd'hui",
        clear: "effacer",
    }

    const evenementsTemplates = {
        
        id:"",
        title:"",
        start:"",
        end:"",
        data:{}
    }


    const spinner = new Spinner();
    const notify = new Notify();
    const programmationService = new ProgrammationService();
    const enseignantService = new EnseignantService();
    const seanceEffectuerService = new SeanceEffectuerService();
    const anneeService = new AnneeService();

    const [loading,setLoading] = useState(false);
    const [spin,setSpin] = useState(false);
    const [dialog,setDialog] = useState(defaultDialog);
    const {control,handleSubmit, reset,getValues,setValue,formState:{errors}} = useForm({defaultValues});
    const {control:controlValidation,handleSubmit:handleSubmitValidation, reset:resetValidation,setValue:setValueValidation} = useForm({contenuCours:"",idSeanceProg:""});
    const [evenements,setEvenements] = useState([]);
    const [listProgrammation,setListProgrammation] = useState([]);
    const [listSeanceEnregistres,setListSeanceEnregistres] = useState([]);
    const [listOfEnseignant,setListOfEnseignant] = useState([]);
    const [listCohortesAfficher,setListCohortesAfficher] = useState([]);
    const [listInfrastructureAfficher,setListInfrastructureAfficher] = useState([]);
    const [listAnnee,setListAnnee] = useState([]);
   const [notificationModal, setnotificationModal] = useState(false);
    const [valider,setValider] = useState({enseignant:false,delegue:false,sp:false});
    const [visible, setvisible] = useState(false);
    const [motif, setmotif] = useState({modal:false,content:""});
    
    

    useEffect(() => {
        let matricule = token.matricule;
        let donnees = []
        let current = {}
        let id = ""
        let title = ""
        let start = ""
        let end = ""

        /*const donnees = [
            {
                id : "",
                title : "Université Joseph Ky Zerbo",
                start : "2022-05-09T10:00:00",
                end : "2022-05-09T12:00:00",
                data : {
                    nomIesr : "Université Norbert Zongo",
                    nomDepartement : "Math-PC",
                    libelleParcours : "Mathématiques appliquées",
                    libelleCohorte : "Cohorte-1-Math/PC",
                    libelleElementConstitutif : "Mécaniques des fluides",
                    libelleTypeActivite : "Cours magistral",
                    libelleNiveau : "Licence 3",
                    libelleSemestre : "Semestre 5"
                }
            }
        ]
        setEvenements(donnees)*/
        const emploiDuTempsService = new EmploiDuTempsService()
        emploiDuTempsService.getEmploiDuTempsEnseignant(matricule).then(data => {
            data.map(d => {
                
                current = {
                    id : d.idSeanceProg,
                    title : d.libelleElementConstitutif,
                    start : d.dateProgrammation+"T"+d.heureDebutSeanceProg,
                    end : d.dateProgrammation+"T"+d.heureFinSeanceProg,
                    data : {
                        nomEnseignant : d.nomEnseignant,
                        prenomEnseignant : d.prenomEnseignant,
                        nomIes : d.nomIes,
                        nomDepartement : d.libelleDepartementEtab,
                        libelleEc : d.libelleElementConstitutif,
                        libelleParcours : d.libelleParcours,
                        libelleCohorte : d.cohorte[0].libelleCohorte,
                        libelleNiveau : d.libelleNiveau,
                        libelleInfrastructure : d.libelleInfrastructure,
                        libelleSemestre: d.libelleSemestre,
                    }
                }
                donnees.push(current)
                
            })
            //console.log(data)
            //console.log(donnees)
            setEvenements(donnees)
            
        })
        .catch(error => {
            console.log(error)
        })

    }, []);

    const options = {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialDate:new Date(),
        initialView:'timeGridWeek',
        locales:[fr],
        headerToolbar:{ 
            left: 'prev,next', 
            center:'title', 
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },

        slotMinTime:"06:00:00",
        slotMaxTime:"23:00:00",
        eventContent: function(arg) {
            let {data} = arg.event.extendedProps;
            let division = document.createElement('div')
            /*let cohorteJsx ="";
             data.cohorte.forEach(cor=>{
                cohorteJsx +=`<span>${cor.libelleCohorte.substring(0,9)}</span>,`
                
             })*/
            const jsx = `
            <div>
                <span>${arg.timeText}</span><br />
                <span>${data.nomIes}</span><br />
                <span>${"Parcours "+ data.libelleParcours}</span><br />
                <span>${data.libelleEc}</span> <br/>
                <span>${data.libelleCohorte} - ${data.libelleInfrastructure}</span><br />
                
                
            </div>`
            division.innerHTML = jsx;
            let arrayOfDomNodes = [ division ]
            return { domNodes: arrayOfDomNodes }
          },
          editable: true,
    };
     
    return(
        <div>
           
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            
            <div className="card">
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                
                   <div>
        
                        <div>
                            <FullCalendar 
                                options={options} events={evenements} 
                                
                            />
                        </div>
                   </div>
                }
            </div>
        </div>
    )
}