import React, {useState, useEffect, useRef} from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { ToastContainer} from 'react-toastify';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { TypeCritereService } from '../../service/TypeCritereService';
import { SessionService } from '../../service/SessionService'


const TypeCritere = () => {

    const empptyTypeCritere = {

        idTypeCritere : null,
        libelleTypeCritere : "",
        coefficient : null,
        obligatoire : false,
        justificatif :false,
        statusItem : "",
        observation : "",
        typeItem : {},
        session : {}
        
    }
    const [typeCriteres, setTypeCriteres] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [typeItems, setTypeItems] = useState([]);
    const [typeCritere, setTypeCritere] = useState(empptyTypeCritere);
    const [typeCritereDialog, setTypeCritereDialog] = useState(null);
    const [deleteTypeCritereDialog, setDeleteTypeCritereDialog] = useState(null);
    const [selectedTypeCriteres, setSelectedTypeCriteres] = useState(null);
    const [structure, setStructure] = useState({});
    const [statusItems, setStatusItems] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);

    const notify = new Notify();
    const spinner = new Spinner();

    const typeCritereService = new TypeCritereService();
    const sessionService = new SessionService();

    useEffect( () => {

        let today = new Date();
        let year = today.getFullYear();
        const structure = localStorage.getItem('structure')
        const struct = JSON.parse(structure)
        setStructure(struct);
        sessionService.get(year, 385, 'NIVEAU_III',"OUVERT","").then(data => {
            setSessions(data);
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })

        const typeItems = [
            {libelleTypeItem : "INPUT"},
            {libelleTypeItem : "DROP DOWN"},
            {libelleTypeItem : "CHECKBOX"},
            {libelleTypeItem : "BUTTON RADIO"},
            {libelleTypeItem : "TEXTAREA"},
            {libelleTypeItem : "BUTTON"}
        ]
        setTypeItems(typeItems);

        const statusItems = [
            {libelleStatusItem : "invisible"},
            {libelleStatusItem : "modifiable"}
        ]

        setStatusItems(statusItems);
        
    }, []);

    const openNew = () => {
        setTypeCritere(empptyTypeCritere);
        setSubmitted(false);
        setTypeCritereDialog(true);
        
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTypeCritereDialog(false);
    }

    const hideDeleteTypeCritereDialog = () => {
        setDeleteTypeCritereDialog(false);
    }

    const saveTypeCritere = () => {
        setSubmitted(true);
        
        if(typeCritere.session.idSession && typeCritere.typeItem.libelleTypeItem && typeCritere.statusItem.libelleStatusItem && typeCritere.libelleTypeCritere.trim()){

            const newTypeCritere = {
                idTypeCritere : typeCritere.idTypeCritere,
                libelleTypeCritere : typeCritere.libelleTypeCritere,
                coefficient : parseInt(typeCritere.coefficient),
                obligatoire : typeCritere.obligatoire,
                justificatif : typeCritere.justificatif,
                libelleTypeItem : typeCritere.typeItem.libelleTypeItem,
                typeItem : typeCritere.typeItem.libelleTypeItem,
                statusItem : typeCritere.statusItem.libelleStatusItem,
                observation : typeCritere.observation,
                idSession : typeCritere.session.idSession,
            }
            console.log(newTypeCritere);
            if(typeCritere.idTypeCritere){
                typeCritereService.update(typeCritere.idTypeCritere, newTypeCritere).then(data => {
                    console.log(data);
                    typeCritereService.get(typeCritere.session.idSession).then(donnee => {
                        setTypeCriteres(donnee);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        //notify.echec();
                    })
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                typeCritereService.create(newTypeCritere).then(data => {
                    console.log(data);
                    typeCritereService.get(typeCritere.session.idSession).then(donnee => {
        
                        setTypeCriteres(donnee);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        //notify.echec();
                    })
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
        }
        setTypeCritere(empptyTypeCritere);
        setTypeCritereDialog(false);

    }

    const editTypeCritere = (typeCritere) => {
        
        setTypeCritere({...typeCritere});
        setTypeCritereDialog(true);
    }

    const confirmDeleteTypeCritere = (typeCritere) => {
        setTypeCritere(typeCritere);
        setDeleteTypeCritereDialog(true);

    }

    const deleteTypeCritere = () => {
        typeCritereService.delete(typeCritere.idTypeCritere).then(data => {
            console.log(data);
            
            typeCritereService.get(typeCritere.session.idSession).then(donnee => {
        
                setTypeCriteres(donnee);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                //notify.echec();
            })
            setDeleteTypeCritereDialog(false);
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setTypeCritereDialog(false);
    }

    const onChampsChange = (e, name) => {
        let val = (e.target && e.target.value) || "";
        let _typeCritere = {...typeCritere}
        _typeCritere[`${name}`] = val;

        setTypeCritere(_typeCritere);

    }

    const onSessionChange = (e, session) => {
        const val = (e.target && e.target.value) || '';
        let _typeCritere = {...typeCritere};
        _typeCritere[`${session}`] = val;
        setTypeCritere(_typeCritere);
        setLoading(true);
        typeCritereService.get(val.idSession).then(data => {
            console.log(data);
            setTypeCriteres(data);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
            setLoading(false);
        })
    }

    const actionObligatoire = (rowData) => {
        return (
            <React.Fragment>
                <InputSwitch checked={rowData.obligatoire} />
            </React.Fragment>
        );
    }

    const actionJustificatif = (rowData) => {
        return (
            <React.Fragment>
                <InputSwitch checked={rowData.justificatif} />
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editTypeCritere(rowData)}  />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteTypeCritere(rowData)} style={{marginLeft : 5}}/>
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );
    const typeCritereDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveTypeCritere} />
        </React.Fragment>
    );
    const deleteTypeCritereDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTypeCritereDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteTypeCritere} />
        </React.Fragment>
    );
    const actionHeader = <Button type="button" icon="pi pi-cog"/>;


    return (
            <div className="p-grid">
                <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                />
                <ToastContainer />
                <div className="p-col-12">
    
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid p-col-12">
                            <div className="p-col-5" ></div>
                            <div className="p-col-6 p-field">
                                <label htmlFor="libelleSession">Session : </label>
                                <Dropdown className="p-col-4" style={{marginLeft : 10}} optionLabel="libelleSession" optionValue="idSession" value={typeCritere.session} options={sessions} onChange={(e) => {onSessionChange(e, 'session')}} required autoFocus  />
                            </div>
                        </div>
                        {header}
                        {loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={dt} value={typeCriteres} selection={selectedTypeCriteres} onSelectionChange={(e) => setSelectedTypeCriteres(e.value)}
                        dataKey="id" paginator rows={10} 
                        globalFilter={globalFilter}
                        header="Liste des types de critères">
                            <Column field="libelleSession" header="Session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleTypeCritere" header="Type critère"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="obligatoire" body={actionObligatoire} header="Obligatoire" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="justificatif" body={actionJustificatif} header="Justificatif" filterMatchMode="contains" sortable={true} filter={true}></Column>
                            <Column field="typeItem" header="Type item"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="statusItem" header="Statut item"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                

                <Dialog visible={typeCritereDialog} style={{ width: '450px' }} header="Informations type critère" modal className="p-fluid" footer={typeCritereDialogFooter} onHide={hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleSession">Session</label>
                        <Dropdown optionLabel="libelleSession" optionValue="idSession" value={typeCritere.session} options={sessions} onChange={(e) => onChampsChange(e, 'session')} required autoFocus className={classNames({ 'p-invalid': submitted && !typeCritere.session.idSession })} />
                        {submitted && !typeCritere.session.idSession && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleTypeCritere">Libelle type critère </label>
                        <InputText id="libelleTypeCritere" value={typeCritere.libelleTypeCritere}  onChange={(e) => onChampsChange(e, 'libelleTypeCritere')} required autoFocus className={classNames({ 'p-invalid': submitted && !typeCritere.libelleTypeCritere })}/>
                        {submitted && !typeCritere.libelleTypeCritere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="coefficient">coefficient </label>
                        <InputText type="int" id="coefficient" value={typeCritere.coefficient}  onChange={(e) => onChampsChange(e, 'coefficient')} required autoFocus />
                    </div>
                    <div className="p-field">
                        <label htmlFor="typeItem">Type item</label>
                        <Dropdown optionLabel="libelleTypeItem" optionValue="libelleTypeItem" value={typeCritere.typeItem} options={typeItems} onChange={(e) => onChampsChange(e, 'typeItem')} required autoFocus className={classNames({ 'p-invalid': submitted && !typeCritere.typeItem.libelleTypeItem })} />
                        {submitted && !typeCritere.typeItem.libelleTypeItem && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="typeItem">Statut item</label>
                        <Dropdown optionLabel="libelleStatusItem" optionValue="libelleStatusItem" value={typeCritere.statusItem} options={statusItems} onChange={(e) => onChampsChange(e, 'statusItem')} required autoFocus className={classNames({ 'p-invalid': submitted && !typeCritere.typeItem.libelleStatusItem })} />
                        {submitted && !typeCritere.typeItem.libelleStatusItem && <small className="p-error">Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="obligatoire">Obligatoire</label>
                        <InputSwitch style={{marginLeft : 5}} checked={typeCritere.obligatoire} onChange={(e) => onChampsChange(e, 'obligatoire') } />
                        <label style={{marginLeft : 35}} htmlFor="justificatif">Justificatif</label>
                        <InputSwitch style={{marginLeft : 5}} checked={typeCritere.justificatif} onChange={(e) => onChampsChange(e, 'justificatif') } />
                    </div>
                    <div className="p-field">
                        <label htmlFor="observation">Observation</label>
                        <InputTextarea id="observation" value={typeCritere.observation}  onChange={(e) => onChampsChange(e, 'observation')} required autoFocus />
                    </div>
                </Dialog>
                <Dialog visible={deleteTypeCritereDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeCritereDialogFooter} onHide={hideDeleteTypeCritereDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {typeCritere && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
                </div>
            </div> 
    )
}

export default TypeCritere
