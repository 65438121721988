import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class IesrService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/iesr"
       return axios.get(url).then(res => res.data) 
   }

   getStatistiqueNombreEtudiants(idAnneeAcademique){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/iesr-statistique?idAnnee="
        return axios.get(url+idAnneeAcademique).then(res => res.data) 
   }

}