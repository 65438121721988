import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeFormationService {
     constructor(){
         this.adresse = new AdresseIP()
     }
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/types-formations"
        return axios.get(url).then(res => res.data) 
    }
    
    create(newTypeFormation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/types-formations"
        return axios.post(url,newTypeFormation)
                    .then(res => res.data);
    }
    update(idTypeFormation ,newTypeFormation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/types-formations/"
        return axios.put(url+idTypeFormation, newTypeFormation)
                    .then(res => res.data);
    }
    delete(idTypeFormation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/types-formations/"
        return axios.patch(url+idTypeFormation)
                    .then(res => res.data)
    }

}