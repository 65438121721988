import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class AnneeSemestreService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/annees-semestres"
       return axios.get(url).then(res => res.data) 
   }
   
   create(newAnneeSemestre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/annees-semestres"
       return axios.post(url,newAnneeSemestre)
                   .then(res => res.data);
   }
   
   delete(idAnneeSemestre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/annees-semestres/"
       return axios.patch(url+idAnneeSemestre)
                   .then(res => res.data)
   }

}