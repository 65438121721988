import React , {useState,useEffect} from 'react';
import {FullCalendar} from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import fr from '@fullcalendar/core/locales/fr';
import { Button } from 'primereact/button';
import { ToastContainer } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Spinner } from '../../components/Spinner';
import { Controller,useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Editor} from 'primereact/editor'
import { Calendar } from 'primereact/calendar';
import ProgrammationService from '../service/ProgrammationService';


import './parametres/index.css';
import Notification from './Notification';
import { Dropdown } from 'primereact/dropdown';
import { CohorteServices } from '../service/CohorteServices';
import { SemestreService } from '../../lmd/service/SemestreService';

export default function SeanceEffectuerDA(props){
    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);

    const defaultValues = {
        elementConstitutif:'',
        parcours:'',
        anneeAcademique:'',
        niveau:'',
        semestre:'',
        contenuCours:'',
        heureFinSeanceEff:'',
        heureDebutSeanceEff:'',
        dateExecution:'',
        enseignant:'',
        typeActivite:'',
        heureFinSeanceProg:'',
        heureDebutSeanceProg:'',
        dateProgrammation:'',
        infrastructure:'',
        idSeanceProg:'',
        cohorte:"",
        valideChef:"",
        statutSeanceEff:"",
    }


    let defaultDialog = {
        add:false,
        modify:false,
        delete:false,
        detail:false,
        nonValider:false
    }

    const addLocale ={
        firstDayOfWeek: 1,
        dayNames: ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
        dayNamesShort: ['dim','lun','mar','mer','jeu','ven','sam'],
        dayNamesMin: ['D','L','M','Me','J','V','S'],
        monthNames: ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'],
        monthNamesShort: "",
        today: "aujourd'hui",
        clear: "effacer",
    }

    const evenementsTemplates = {
        id:"",
        title:"",
        start:"",
        end:"",
        data:{}
    }


    const spinner = new Spinner();
    const programmationService = new ProgrammationService();
    const cohorteServices = new CohorteServices();
    const semestreService = new SemestreService();


    const defaultSearch = {
        parcours:"",
        semestre:"",
        cohorte:"",
        departement:'',
    }
    const [loading,setLoading] = useState(false);
    const [dialog,setDialog] = useState(defaultDialog);
    const {control, reset,getValues,setValue,formState:{errors}} = useForm({defaultValues});
    const {control:controlValidation} = useForm({contenuCours:"",idSeanceProg:""});
    const {control:searchControl, setValue:searchSetValue, getValues:searchGetValue} = useForm({defaultValues:defaultSearch});
    const [evenements,setEvenements] = useState([evenementsTemplates]);
    const [listCohortesAfficher,setListCohortesAfficher] = useState([]);
    const [listInfrastructureAfficher,setListInfrastructureAfficher] = useState([]);
    const [notificationModal, setnotificationModal] = useState(false);
    const [valider,setValider] = useState({enseignant:false,delegue:false,sp:false});
    const [motif, setmotif] = useState({modal:false,content:""});
    const [listParcours, setListParcours] = useState([]);
    const [listSemestreSearch, setListSemestreSearch] = useState([]);
    const [listCohorteSearch, setListCohorteSearch] = useState([]);
    const [listProgrammationByParcours, setListProgrammationByParcours] = useState([]);
    const [programmationFilter, setProgrammationFilter] = useState([]);
    const [listDepartement, setListDepartement] = useState([]);

    const campusVpEip = localStorage.getItem('campusVpEip')
    const campusDirecteur = localStorage.getItem('campusDirecteur')
    const campusDa = localStorage.getItem('campusDa')
    const isVp = parseInt(JSON.parse(campusVpEip))
    const isDirecteur = parseInt(JSON.parse(campusDirecteur))
    const isDA = parseInt(JSON.parse(campusDa))
    
    let code_departement;
    
    
    let code_etablissement = token.code_etablissement;
    

    useEffect(() => {
        setLoading(true);
        cohorteServices.getListeDepartementEtab(code_etablissement).then(data => {
            setListDepartement(data.departementEtabDtos)   
        })
        .catch(error => {
            console.log(error);
        })
        .finally(()=>setLoading(false));
           
    }, []);


    const programmationGet=(response=[])=>{
        let events = response.map(programme=>{
            let heureDebut = programme.heureDebutSeanceEff? programme.heureDebutSeanceEff.split(":"): programme.heureDebutSeanceProg.split(":");
            let heureDebutSeanceProg=new Date(programme.dateProgrammation);
            heureDebutSeanceProg.setHours(+heureDebut[0],+heureDebut[1],heureDebut[2]);

            let heureFin = programme.heureFinSeanceEff? programme.heureFinSeanceEff.split(":") : programme.heureFinSeanceProg.split(":");
            let heureFinSeanceProg=new Date(programme.dateProgrammation);
            heureFinSeanceProg.setHours(+heureFin[0],+heureFin[1],heureFin[2]);
                return {
                "title":`${programme.libelleElementConstitutif}`,
                "id":programme.idSeanceProg,
                "start":heureDebutSeanceProg,
                "end":heureFinSeanceProg,
                "data":programme,
            }
            
        });

        setEvenements(events);
    }


    const handleQuitter = ()=>{
        setDialog({...dialog,add:false});
        reset();
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">*{errors[name].message}</small>
    };


    const saveSeanceFooter =(
        <React.Fragment>
            <div style={{display:"flex"}}>
                <div className="mt-3">
                    <Button type="button" label="Quitter" className="p-button-danger" icon="pi pi-times" 
                        onClick={handleQuitter} style={{width:"130px"}} 
                    />
                </div>
                <div className="mt-3">
                    {
                        motif.content && !valider.sp(
                            <Button type="button" label="Motif" disabled={getValues("statutSeanceEff")} className="p-button-info" icon="pi pi-check-square" 
                                onClick={()=>setmotif({...motif,modal:true})}
                                style={{marginRight:"15px",width:"130px"}} 
                            />
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )


    const options = {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialDate:new Date(),
        initialView:'timeGridWeek',
        locales:[fr],
        headerToolbar:{ 
            left: 'prev,next', 
            center:'title', 
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },

        slotMinTime:"06:00:00",
        slotMaxTime:"23:00:00",
        eventContent: function(arg) {
            let {data} = arg.event.extendedProps;
            let division = document.createElement('div')
            let cohorteJsx ="";
             data.cohorte.forEach(cor=>{
                cohorteJsx +=`<span>${cor.libelleCohorte.substring(0,9)}</span>,`
                
             })
            const jsx = `
            <div>
                <span>${arg.timeText}</span><br />
                <span>${data.libelleElementConstitutif}</span>&nbsp;&nbsp; / <span>${data.libelleTypeActivite}</span><br />
                <p>${cohorteJsx} </p>
            </div>`
            arg.backgroundColor=data.valideSP?"#8000FF":data.valideEnseignant?"#00FF80":data.valideChef?"#ff8000":"";
            division.innerHTML = jsx;
            let arrayOfDomNodes = [ division ]
            return { domNodes: arrayOfDomNodes }
          },

        editable: true,
        eventClick:e=>{
            const content = e.event.extendedProps.data;
            setValue("dateProgrammation",new Date(content.dateProgrammation));
            setValue("heureDebutSeanceProg",content.heureDebutSeanceProg);
            setValue("heureFinSeanceProg",content.heureFinSeanceProg);
            setValue("elementConstitutif",content.libelleElementConstitutif);     
            setValue("enseignant",`${content.matriculeEnseignant} ${content.nomEnseignant} ${content.prenomEnseignant}`);
            setValue("typeActivite",content.libelleTypeActivite);
            setValue("parcours",content.libelleParcours);
            setValue("niveau",content.libelleNiveau);
            setValue("anneeAcademique",`${content.anneeDebut}:${content.anneeFin}`);
            setValue("semestre",content.libelleSemestre);
            setValue("infrastructure",content.libelleInfrastructure);
            setValue("idSeanceProg",content.idSeanceProg);
            setValue("infrastructure",content.infrastructure.libelleInfrastructure);
            setValue("valideChef",content.valideChef);
            setValue("statutSeanceEff",content.statutSeanceEff);
            setValider({enseignant:content.valideEnseignant,delegue:content.valideChef,sp:content.valideSP});
            setValue("heureDebutSeanceEff",content.heureDebutSeanceEff);
            setValue("heureFinSeanceEff",content.heureFinSeanceEff);


            let contenueHTML=""
            if(content.valideChef && content.valideEnseignant && content.contenuEnseignant && content.contenuChef  && props.role==="campus-secretaire-principal"){
                contenueHTML = `<h3 class="ql-align-center" id="delegue"><b>Enregistrer par le Délégué</b></h3><br /> ${content.contenuChef}<br /><h3 class="ql-align-center" id="enseignant"><b>Enregistrer par l'enseignant</b></h3>${content.contenuEnseignant}`
            }else if(content.valideChef && content.valideEnseignant){
                contenueHTML = content.contenuEnseignant?content.contenuEnseignant:content.contenuChef;
            }else if(content.valideChef || !content.valideEnseignant){
                contenueHTML = content.contenuChef;
            }
            
            setValue("contenuCours",contenueHTML);
            
            let cohortes = content.cohorte.map(cohor=>{
                return cohor.libelleCohorte.substring(0,9)
            });

            setValue("cohorte",cohortes.join(","));
            setDialog({...dialog,add:true});

            if(content.justificationRejet){

                setmotif({...motif,content:content.justificationRejet})

               
                    setTimeout(()=>setmotif({...motif,content:content.justificationRejet}),1500)
            }
        }
    };

   const handleParcoursSearch = (parcours)=>{
        if(isVp || isDA || isDirecteur){
            let departement = searchGetValue("departement");
            code_departement = departement.idDepartementEtab;
        }

        let semestre = searchGetValue("semestre");
        let cohorte = searchGetValue("cohorte");
        setLoading(true);
        searchSetValue("niveau","");
        setListSemestreSearch([]);
        setListCohorteSearch([]);
        programmationService.getProgrammationByParcours(code_departement,parcours.idParcours)
        .then(response=>{
            setListProgrammationByParcours(response);
            programmationGet(response);
            if(cohorte) handleCohorteSearch(cohorte);
            if(semestre) handleSemestreSearch(semestre);
        })
        .finally(()=>setLoading(false));

        semestreService.get(parcours.idParcours).then(response=>{
            setListSemestreSearch(response);
        })
    }


    const handleSemestreSearch=semestre=>{
        let result = listProgrammationByParcours.filter(programmation=>programmation.idSemestre === semestre.idSemestre);
        setProgrammationFilter(result);
        programmationGet(result);
        let newListCohorte = [];
        let listCohorte =[];
        result.forEach(prog=>{
            prog.cohorte.forEach(cohor=>{
                listCohorte.push(cohor);
            })
        })
        let cohortesID=new Set();
        for(let element of listCohorte){
            cohortesID.add(element.idCohorte);
        }
        for(let index of cohortesID ){
            newListCohorte.push(listCohorte.find(cohorte=>cohorte.idCohorte === index));
        }
        let cohorte = searchGetValue("cohorte");
        if(cohorte) handleCohorteSearch(cohorte)
        setListCohorteSearch([...newListCohorte]);
    }
    // errors.response.data.message


    const handleCohorteSearch = cohorte=>{
        let result =[] ;
        programmationFilter.forEach(programmation=>{
            programmation.cohorte.filter(programmation=>programmation.idCohorte === cohorte.idCohorte)
            for(let element of programmation.cohorte){
                if(element.idCohorte === cohorte.idCohorte){
                    result.push(programmation);
                }
            }
        });
        programmationGet(result);
    }


    const handleDepartementSearch = departement=>{
        setLoading(true);
        cohorteServices.getListeParcoursDepartement(departement.idDepartementEtab)
        .then(response=>setListParcours(response))
        .catch(errors=>console.log(errors))
        .finally(()=>setLoading(false));
    }


    const header =(
            <React.Fragment>
            <div>
                <div>
                    <div className='all-validation-items'>
                        <div className='validation-container'>
                            <div>
                                <span>valider par Délégué</span>
                            </div>
                            <div className='validation' style={{backgroundColor:"#ff8000"}}></div>
                        </div>

                        <div className='validation-container'>
                            <div>
                                <span>valider par SP</span>
                            </div>
                            <div className='validation' style={{backgroundColor:"#8000FF"}}></div>
                        </div>
                        <div className='validation-container'>
                            <div>
                                <span>valider par Enseignant</span>
                            </div>
                            <div className='validation' style={{backgroundColor:"#00FF80"}}></div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div style={{backgroundColor:"Background",padding:10}} className="table-header p-grid justify-content-center mb-2 mt-3">
                <div className="p-input-icon-left">
                    <div className='p-grid justify-content-center'>
                        {
                            isVp || isDA || isDirecteur ?(
                                <div className="p-field p-col">
                                    <Controller 
                                        name='departement' control={searchControl}
                                        render={({field})=>(
                                            <Dropdown 
                                                filter
                                                name={field.name} id={field.name} value={field.value}
                                                options={listDepartement} optionLabel='lbDepartementEtab'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner un département"
                                                {...field}
                                                onChange={(e)=>{field.onChange(e.value);handleDepartementSearch(e.value)}}
                                            />
                                        )}

                                    />
                                </div>
                            ):<span></span>
                        }
                        
                        <div className="p-field p-col">
                            <Controller 
                                name='parcours' control={searchControl}
                                render={({field})=>(
                                    <Dropdown 
                                        filter
                                        name={field.name} id={field.name} value={field.value}
                                        options={listParcours} optionLabel='libelleParcours'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner un parcours"
                                        {...field}
                                        onChange={(e)=>{field.onChange(e.value);handleParcoursSearch(e.value)}}
                                    />
                                )}

                            />
                        </div>
                        <div className="p-field p-col">
                            <Controller 
                                name='semestre' control={searchControl}
                                render={({field})=>(
                                    <Dropdown 
                                        name={field.name} id={field.name} value={field.value}
                                        options={listSemestreSearch} optionLabel='libelleSemestre'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} placeholder="Selectionner un semestre"
                                        {...field}
                                        onChange={(e)=>{field.onChange(e.value);handleSemestreSearch(e.value)}}
                                    />
                                )}

                            />
                        </div>
                        <div className="p-field p-col">
                            <Controller 
                                name='cohorte' control={searchControl}
                                render={({field})=>(
                                    <Dropdown 
                                        name={field.name} id={field.name} value={field.value}
                                        options={listCohorteSearch} optionLabel='libelleCohorte'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner une cohorte"
                                        {...field}
                                        onChange={e=>{field.onChange(e.value);handleCohorteSearch(e.value)}}
                                    />
                                )}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )


    return(
        <div>
           
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            
            <div className="card">
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                
                   <div>
                        <div>
                            {header}
                        </div>
                        <div>
                            <FullCalendar 
                                options={options} events={evenements} 
                                editable selectable selectMirror dayMaxEvents 
                            />
                        </div>
                   </div>
                }
            </div>


            {/*seance Renseigner Bloc */}
                <Dialog className="card" visible={dialog.add} style={{width:"100vw",height:"100VH",maxHeight:"100vh"}} 
                    onHide={()=>setDialog({...dialog,add:false})} >
                    <div className="p-d-flex p-jc-center mr-2">
                        <h5 className="text-header">Vous pouvez Enregistrer cette séance</h5>
                        <form className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                                    <div>
                                        <div className="p-field" >
                                            <label htmlFor="anneeAcademique" >Année Académique</label>
                                            <Controller
                                                name="anneeAcademique" control={control}
                                                render={({field}) => (
                                                    <InputText
                                                    readOnly
                                                        name={field.name} value={field.value} id={field.name}
                                                        {...field}
                                                    />
                                                )} 

                                            />
                                        </div>
                                        
                                        <div className="p-grid">
                                            <div className="p-col-7 p-field" >
                                                <label htmlFor="parcours" >Parcours</label>
                                                <Controller
                                                    name="parcours" control={control}
                                                    render={({field}) => (
                                                        <InputText
                                                            readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                )} 

                                            />
                                            </div>
                                            <div className="p-col-5 p-field" >
                                                <label htmlFor="niveau" >Niveau</label>
                                                <Controller
                                                    name="niveau" control={control}
                                                    render={({field}) => (
                                                        <InputText
                                                            readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                                /> 
                                            </div>
                                        </div>
                                        <div className="p-field" >
                                            <label htmlFor="semestre" >Semestre</label>
                                            <Controller
                                                name="semestre" control={control}
                                                render={({field}) => (
                                                    <InputText
                                                    readOnly
                                                        name={field.name} value={field.value} id={field.name}
                                                        {...field}
                                                    />
                                                )} 
                                        />
                                        </div>
                                        <div className="p-field" >
                                                <label htmlFor="enseignant" >Enseignant</label>
                                                <Controller
                                                    name="enseignant" control={control}
                                                render={({field}) => (
                                                    <InputText
                                                        readOnly
                                                        name={field.name} value={field.value} id={field.name}
                                                        {...field}
                                                    />
                                                )} />
                                            </div>
                                            <div className="p-grid">
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="elementConstitutif" >Elément Constitutif</label>
                                                    <Controller
                                                        name="elementConstitutif" control={control}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="typeActivite" >Type Activité</label>
                                                    <Controller
                                                        name="typeActivite" control={control}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-col-6 p-field" >
                                                <label htmlFor="infrastructure">Infrastructure</label>
                                            <Controller 
                                                name="infrastructure" control={control}
                                                render={({field}) => (
                                                    <InputText readOnly id={field.id} name={field.name} value={field.value} {...field}/>
                                                )} />
                                            </div> 
                                            <div className="p-col-6 p-field" >
                                                <label htmlFor="cohorte">Cohortes
                                                </label>
                                            <Controller 
                                                name="cohorte" control={control}
                                                render={({field}) => (
                                                    <InputText readOnly id={field.id} name={field.name} value={field.value} {...field}/>
                                                )} />
                                            </div> 
                                            </div>
                                        <hr/>
                                        <div>
                                            <div className="p-field" >
                                                <label htmlFor="dateProgrammation" >Date de programmation</label>
                                                <Controller
                                                    name="dateProgrammation" control={control}
                                                    render={({field}) => {
                                                        return (
                                                            <Calendar 
                                                                locale={addLocale} dateFormat="dd/mm/yy" minDate={new Date()} showOnFocus={false}
                                                                id={field.name} name={field.name} value={field.value} showIcon={false} readOnlyInput
                                                                {...field}
                                                            />
                                                        )
                                                    }} />
                                            </div>
                                                    
                                            <div className="p-grid">
                                                <div className="p-col-6 p-field" >
                                                    <label htmlFor="heureDebutSeanceProg" >heure début programmé {getFormErrorMessage('heureDebutSeanceProg')}</label>
                                                    <Controller 
                                                        name="heureDebutSeanceProg" control={control}
                                                        render={({field}) =>{
                                                            return  (
                                                                <InputText readOnly  type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value)}} />
                                                            )
                                                    }} />
                                                </div>

                                                <div className="p-col-6 p-field" >
                                                    <label htmlFor="heureFinSeanceProg">Heure fin programmé {getFormErrorMessage('heureFinSeanceProg')}</label>
                                                    <Controller 
                                                        name="heureFinSeanceProg" control={control}
                                                        render={({field}) => {
                                                            return  (
                                                                    <InputText readOnly  type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value)}} />
                                                                )
                                                        }} />
                                                </div> 
                                            </div>
                                            {/* <hr />
                                            {
                                                getValues("heureDebutSeanceEff") !==getValues("heureDebutSeanceProg") && getValues("heureFinSeanceEff") !==getValues("heureDebutSeanceProg") && 
                                                    <div className="p-grid">
                                                    <div className="p-col-6 p-field" >
                                                        <label htmlFor="heureDebutSeanceEff" >heure début effectuer {getFormErrorMessage('heureDebutSeanceEff')}</label>
                                                        <Controller 
                                                            name="heureDebutSeanceEff" control={control}
                                                            render={({field}) =>{
                                                                return  (
                                                                    props.role==="campus-delegue" || (props.role==="campus-enseignant" && !getValues("valideChef"))?
                                                                    <InputText  type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value)}} />
                                                                    :
                                                                    <InputText readOnly  type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value)}} />
                                                                )
                                                        }} />
                                                    </div>

                                                    <div className="p-col-6 p-field" >
                                                        <label htmlFor="heureFinSeanceEff">Heure fin effectuer {getFormErrorMessage('heureFinSeanceEff')}</label>
                                                        <Controller 
                                                            name="heureFinSeanceEff" control={control}
                                                            render={({field}) => {
                                                                return  (
                                                                        props.role==="campus-delegue" || (props.role==="campus-enseignant" && !getValues("valideChef"))?
                                                                        <InputText  type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value)}} />
                                                                        :
                                                                        <InputText readOnly  type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value)}} />
                                                                    )
                                                            }} />
                                                    </div> 
                                                </div>
                                            } */}
                                            
                                    </div>
                                    
                                </div>

                                <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                                    <Controller 
                                        control={control}
                                        name='contenuCours'
                                        render={({field})=>{
                                            return(
                                            <Editor id={field.name} value={field.value} onTextChange={e=>field.onChange(e.htmlValue)} style={{height:400}} />
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                {saveSeanceFooter}
                            </div>
                        </form>
                    </div>
                </Dialog>
            {/* seance Renseigner EndBloc */}

            {/* detail programmation Bloc */}
                <Dialog className="card" visible={dialog.detail} style={{width:"100vw",height:"100VH",maxHeight:"100vh"}} onHide={()=>setDialog({...dialog,detail:false})}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center mr-2">
                                <h5 className="text-header">Détail sur l'activité Académique </h5>
                                <form className="p-fluid">
                                    <div>
                                        <div>
                                            <div className="p-field" >
                                                <label htmlFor="anneeAcademique" >Année Académique</label>
                                                <Controller
                                                    name="anneeAcademique" control={control}
                                                    render={({field}) => (
                                                        <InputText
                                                        readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                                />
                                            </div>
                                            
                                            <div className="p-grid">
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="parcours" >Parcours</label>
                                                    <Controller
                                                        name="parcours" control={control}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} 

                                                />
                                                </div>
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="niveau" >Niveau</label>
                                                    <Controller
                                                        name="niveau" control={control}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                        )} 

                                                    /> 
                                                </div>
                                            </div>
                                            <div className="p-field" >
                                                <label htmlFor="semestre" >Semestre</label>
                                                <Controller
                                                    name="semestre" control={control}
                                                    render={({field}) => (
                                                        <InputText
                                                        readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                            />
                                            </div>
                                            <div className="p-field" >
                                                    <label htmlFor="enseignant" >Enseignant</label>
                                                    <Controller
                                                        name="enseignant" control={control}
                                                    render={({field}) => (
                                                        <InputText
                                                            readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} />
                                                </div>
                                                <div className="p-grid">
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="elementConstitutif" >Elément Constitutif</label>
                                                    <Controller
                                                        name="elementConstitutif" control={control}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="typeActivite" >Type Activité</label>
                                                    <Controller
                                                        name="typeActivite" control={control}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <hr/>
                                        <div>
                                            <div className="p-grid">
                                                <div className="p-grid">
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="dateProgrammation" >Date de début</label>
                                                    <Controller
                                                        name="dateProgrammation" control={control}
                                                        render={({field}) => {
                                                            return (
                                                                <Calendar
                                                                    locale={addLocale}
                                                                    minDate={new Date()} dateFormat="dd/mm/yy"
                                                                    id={field.name} name={field.name} value={field.value} showIcon
                                                                    {...field}
                                                                    onChange={(e)=>{field.onChange(e.value)}}
                                                                />
                                                            )
                                                        }} />
                                                </div>
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="heureDebutSeanceProg" >heure début</label>
                                                    <Controller 
                                                        name="heureDebutSeanceProg" control={control}
                                                        render={({field}) =>{
                                                            return (
                                                                <InputText type='time' name={field.name} id={field.name} value={field.value} {...field}/>
                                                            )
                                                    }} />
                                                </div>
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="heureFinSeanceProg">Heure fin</label>
                                                    <Controller 
                                                    name="heureFinSeanceProg" control={control}
                                                    render={({field}) =>{
                                                        return (
                                                                <InputText type='time' name={field.name} id={field.name} value={field.value} {...field}/>
                                                            )
                                                    }} />
                                                </div> 
                                            </div>
                                            </div> 
                                            
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-grid p-dir-col p-justify-between" style={{height:"465px"}}>
                                <div>
                                    <DataTable 
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                        selectionMode="single" 
                                        filterMatchMode="contains"
                                        paginator={false} 
                                        responsive={true}
                                        value={listInfrastructureAfficher}
                                        header="Les informations sur l'infrastructure"
                                    >
                                        <Column field="sigleInfrastructure" header="Sigle" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleInfrastructure" header="libellé" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="localite" header="localité" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleEtatInfrastructure" header="etat" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleTypeInfrastructure" header="type" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleTypeStructureRattachement" header="structure d'appartenance" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="nombrePlace" header="nombre de place" headerStyle={{textTransform:"capitalize"}}/>
                                    </DataTable>
                                </div>
                                <div>
                                    <DataTable 
                                            selectionMode="single" filterMatchMode="contains"
                                             responsive={true}
                                            value={listCohortesAfficher}
                                            header={`Panel des cohortes de l'année académique `}
                                        >
                                            <Column field="libelleParcours" header="parcours" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="libelleNiveau" header="Niveau" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="libelleCohorte" header="Libelle" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="nbEtudiantCohorte" header="nombre d'étudiants" headerStyle={{textTransform:"capitalize"}}/>
                                            
                                        </DataTable>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='mt-2'>
                        <Button label='Activité réaliser' className='p-button-success' onClick={()=>setDialog({...dialog,detail:false,add:true})} />
                    </div>
                </Dialog>
                

                <Dialog className="card" visible={motif.modal} onHide={()=>setmotif({...motif,modal:false})}>
                    
                    <div>
                        {/* <h3 style={{textAlign:"center",marginBottom:5}}>Renseigner le motif de rejet.</h3> */}
                        <form className="p-fluid">
                            <div>
                                <Controller 
                                    control={controlValidation}
                                    defaultValue={motif.content}
                                    name='contenuCours'
                                    render={({field})=>{
                                        return(
                                            <Editor id={field.name} value={field.value} onTextChange={e=>field.onChange(e.htmlValue)} style={{height:400}} />
                                        )
                                    }}
                                />
                            </div>
                            <Button type='button' className='p-button-danger' label='Quitter' onClick={()=>setmotif({...motif,modal:false})} style={{width:100,marginTop:5}} />
                        </form>
                    </div>
                </Dialog>

                <Dialog className="card" visible={notificationModal} onHide={()=>setnotificationModal(false)}>
                    <Notification />
                </Dialog>
            {/* modification programmation EndBloc */}
        </div>
    )
}