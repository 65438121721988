import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class EnseignantJuryService {

    constructor(){
        this.adresse = new AdresseIP()
    }

   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant-jury"
       return axios.get(url).then(res => res.data)
   }
   
   create(newJury) {

       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant-jury"
       return axios.post(url,newJury)
                   .then(res => res.data);
   }

   delete(idJury) {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant-jury/"
       return axios.patch(url+idJury)
                   .then(res => res.data)
   }

   getMembreJury(idJury){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/enseignant-jury?idJury="+idJury
        return axios.get(url).then(res => res.data)
   }

}