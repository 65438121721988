import React,{useState,useEffect} from "react";
import { Button } from "primereact/button";
import { ToastContainer } from "react-toastify";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Spinner } from "../../components/Spinner";
import { Dropdown } from "primereact/dropdown";
import "./parametres/index.css";
import { useForm,Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { Notify } from "../../components/Notify";
import NominationService from "../service/NominationService";
import ResponsabiliteService from '../service/ResponsabiliteService';
import EnseignantService from '../service/EnseignantService';
import { InputText } from "primereact/inputtext";

export default function NominationEnseignant(){
    // definie le statut des enseignant selon que le statuts est en cours ou est revolue(depasse)

    const statutEnseignant = [
                {
                    name:"Encours",
                    value:true
                },
                {
                    name:"Revolue",
                    value:false
                }
            ];
        
        const defaultValues = {
            dateDebutNomination:"",
            dateFinNomination:"",
            responsabilite:"",
            responsabilitePriseEnCompte:false,
            enseignant:"",
            statutEnseignantResponsabilite:"",

    }

    const indiceTauxRabbatement = [
        {
            name:"heure",
            value:"heure"
        },
        {
            name:"pourcentage",
            value:"pourcentage"
        }
    ]

    const defaultResponsabiliteValues = {
        libelleResponsabilite: '',
        tauxRabattement:'',
        indiceRabattement:'',
        idResponsabilite:''
    };

    const addLocale ={
        firstDayOfWeek: 1,
        dayNames: ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
        dayNamesShort: ['dim','lun','mar','mer','jeu','ven','sam'],
        dayNamesMin: ['D','L','M','Me','J','V','S'],
        monthNames: ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'],
        monthNamesShort: "",
        today: "aujourd'hui",
        clear: "effacer",
    }
        
    const gererErreurResponsabiliteData = {localCheck:"",nomEnseignant:"",nouvelleResponsabilite:"",ancienResponsabilite:"",response:false,dialog:false};

    const [loading,setLoading] = useState(false);
    const [listOfEnseignant,setListOfEnseignant] = useState([]) // declaration de la variable d'etat permettant de renseigner la list des enseignant pour le dropdown du formulaire
    const [listOfResponsabilite,setListOfResponsabilite] = useState() // declaration de la variable d'etat permettant de renseigner la liste des responsabilite
    const [saveList,setSaveList]= useState([]) // declaration de la variable d'etat qui contient la liste des enseignants à enregistrer dans la base de donnée
    const [dialog,setDialog]=useState({add:false,modify:false}); // pour l'ouverture de la boite de dialog pour effectuer ou modifier une nominaiton
    const [cancelAndReset,setCancelAndReset] = useState(false);
    const [enseignantResponsabilite,setEnseignantResponsabilite] = useState([]); //  declaration de la variable d'etat pour la liste des responsabilite et des enseignants
    const [modificationNomination,setModificationNomination] =useState(defaultValues) // declaration de la variable d'etat qui contiendra les données de la nomination à modifier
    const [spin,setSpin] = useState(false);
    const [responsabiliteDiscipline,setResponsabiliteDiscipline] = useState(gererErreurResponsabiliteData); // cette variable d'etat declache la boite de dialog en cas de duplicatant de responsabilite ou ...
    const { control, formState: { errors }, handleSubmit, reset,setValue,getValues } = useForm({defaultValues});
    const {reset:resetResponsabilite,control:controlResponsabilite,handleSubmit:handleSubmitResponsabilite,formState:{errors:errorsResponsabilite}} = useForm({defaultValues:defaultResponsabiliteValues});
    const [statutToUpdate,setStatutToUpdate]=useState();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [responsabilite,setResponsabilite] = useState(false);
    const [listSpecialite,setListSpecialite] = useState([]);
    const [filterDropdownEnseignant, setFilterDropdownEnseignant] = useState(null);
    
    const spinner = new Spinner();
    const notify = new Notify();
    const nominationService = new NominationService();
    const responsabiliteService = new ResponsabiliteService();
    const enseignantService = new EnseignantService();
    

    /**
     * 
     * @param {*} dataToModify recupere depuis hook-form
     * la methode setvalue de hook from initialise les champs du formulaire 
     * la valeur des champs definie sont recurpere avec la methode getvalues de hook-form
     * 
     */
    const handleModification = dataToModify=>{

        // recuperation des informations complete de l'enseignant
        let enseignant = listOfEnseignant.filter(d=>d.idEnseignant === dataToModify.idEnseignant)[0];

        //recuperation des informations de la responsabilite de l'enseignant
        let responsabilite = listOfResponsabilite.filter(d=>d.idResponsabilite === dataToModify.idResponsabilite)[0];
        dataToModify.statutEnseignantResponsabilite = dataToModify.statutEnseignantResponsabilite?statutEnseignant[0]:statutEnseignant[1];

        // initialisation des champs du formulaire gerer par hook-form

        setValue("dateDebutNomination",new Date(dataToModify.dateDebutNomination));
        setValue("dateFinNomination",new Date(dataToModify.dateFinNomination));
        setValue("enseignant",enseignant);
        setValue("responsabilite",responsabilite);
        setValue("statutEnseignantResponsabilite",dataToModify.statutEnseignantResponsabilite);
        setValue("responsabilitePriseEnCompte",dataToModify.responsabilitePriseEnCompte);
        setValue("idEnseignantResponsabilite",dataToModify.idEnseignantResponsabilite);

        setModificationNomination({...dataToModify,enseignant,responsabilite});
        setDialog({modify:true})

    }


   

    // cette function supprimer un item dans le saveList qui contient la liste des données a envoie au serveur base de donnée

    const handleNominationDeletion = (dataToDelete={})=>{
        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
                setSaveList([]); // reinitialisation de la liste contenant les responsabilites a enregistrer
                setCancelAndReset(false);
                setDialog({add:false,modify:false,delete:false});
                setSpin(false);
            },2000);
        }else if(dialog.delete){
            modificationNomination.statutEnseignantResponsabilite = !modificationNomination.statutEnseignantResponsabilite;
            setSpin(true);
            nominationService.update(modificationNomination)
            .then(()=>{
                setLoading(true);
                notify.success();
                nominationService.getAll().then(response=>{setEnseignantResponsabilite(response.filter(nomination=>nomination.statutEnseignantResponsabilite))});
                setDialog({...dialog,delete:false});
            })
            .catch((errors)=>{
                notify.echec(errors.response.data.message);
                console.log(errors);
            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })
        }else{
            setDialog({...dialog,delete:true});
            setModificationNomination(dataToDelete);
        }
    }

    const handleNominationSubmit = dataForm=>{

        setSaveList([...saveList,dataForm]); 
        reset(); // reinitialisation des champs du formulaire
           
    }

    const onHide = ()=>{
        if(responsabiliteDiscipline.response){
            nominationService.getAll().then(response=>{setEnseignantResponsabilite(response.filter(nomination=>nomination.statutEnseignantResponsabilite))});        
        }
        setDialog(false);
        reset(); // reinitialisation des champs du formulaire
        setSaveList([]); // reinitialisation de la liste de nomination a sauvegarde
    }
    // la function qui envoie une requete de sauvegarde ou de modification à la base de donnée

    const handleSave = (dataForm=null)=>{

        // reconstitution du DTO
       
        setSpin(true);
        if(dialog.add){
            let finalDTO = saveList.map(d=>({
                dateDebutNomination:d.dateDebutNomination,
                dateFinNomination:d.dateFinNomination,
                idEnseignant:d.enseignant.idEnseignant,
                idResponsabilite:d.responsabilite.idResponsabilite,
                statutEnseignantResponsabilite:d.statutEnseignantResponsabilite.value,
                responsabilitePriseEnCompte:d.responsabilitePriseEnCompte
            }))

            // envoie du DTO final au serveur base de donnée
            if(!statutToUpdate){
                nominationService.create(finalDTO)
                    .then(()=>{
                        setLoading(true);
                        nominationService.getAll().then(response=>{setEnseignantResponsabilite(response.filter(nomination=>nomination.statutEnseignantResponsabilite))});
                        setDialog(false);
                        setSaveList([]); // reinitialisation de la liste de nomination a sauvegarde
                        notify.success(); // notification de success du traitement
                    })
                    .catch((error)=>{
                        notify.echec(error.response.data.message); // notification d'echec du traitement
                    })
                    .finally(()=>{
                        setLoading(false);
                        setSpin(false);
                    })
            }else{
                nominationService.update(statutToUpdate)
                .then(()=>{
                    nominationService.create(finalDTO)
                    .then(()=>{
                        setLoading(true);
                        nominationService.getAll().then(response=>{setEnseignantResponsabilite(response.filter(nomination=>nomination.statutEnseignantResponsabilite))});
                        setDialog(false);
                        setSaveList([]); // reinitialisation de la liste de nomination a sauvegarde
                        notify.success(); // notification de success du traitement
                    })
                    .catch((error)=>{
                        notify.echec(error.response.data.message); // notification d'echec du traitement
                    })
                    .finally(()=>{
                        setLoading(false);
                        setSpin(false);
                    })
                })
            }
            
        }
        else if(dialog.modify){
            let finalDTO = {
                idEnseignantResponsabilite:modificationNomination.idEnseignantResponsabilite,
                dateDebutNomination:dataForm.dateDebutNomination,
                dateFinNomination:dataForm.dateFinNomination,
                idEnseignant:dataForm.enseignant.idEnseignant,
                idResponsabilite:dataForm.responsabilite.idResponsabilite,
                statutEnseignantResponsabilite:dataForm.statutEnseignantResponsabilite.value,
                responsabilitePriseEnCompte:dataForm.responsabilitePriseEnCompte
            }
            console.log(finalDTO)
            nominationService.update(finalDTO)
            .then(()=>{
                notify.success(); // notification de success du traitement
                nominationService.getAll().then(response=>{setEnseignantResponsabilite(response.filter(nomination=>nomination.statutEnseignantResponsabilite))});        
                setLoading(true)
                setDialog(false);
                reset(); // reinitialisation des champs du formulaire
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec du traitement
            })
            .finally(()=>{
                    setSpin(false);
                    setLoading(false);
            })
            }
    }
    // la fonction de gestion du cycle de vie

    useEffect(() => {
        
        responsabiliteService.getAll().then(response=>{setListOfResponsabilite(response)}); // recuperation de la liste des responsabilites pour le dropdown
        enseignantService.getAll().then(response=>{setListOfEnseignant(response)});         // recuperation de la liste des enseignant pour les dropdown du formulaire
       
        // recuperation de la liste des enseignants pour juste l'affichage et eventuellement la modification
        nominationService.getAll()
        .then(response=>{
            setEnseignantResponsabilite(response.filter(nomination=>nomination.statutEnseignantResponsabilite));
        })
        .catch(errors=>{
            console.log(errors);
            notify.echec(errors.response.data.message);
        })
        .finally(()=>{
            setLoading(false);
        });       
           
        
    }, []);

    const ResponsabiliteFooter=(
        <React.Fragment>
                <div className="p-grid p-justify-end" style={{width:"100%"}}>
                <Button type="submit" label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className="p-button-success" disabled={dialog.add && (spin || saveList.length===0)?true:false} style={{marginRight:"15px"}} onClick={handleSave} />
                <Button type="button" label="Quitter" disabled={dialog.add && spin?true:false} className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
            </div>
        </React.Fragment>
    )

    // les fonctions JSX
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const getFormErrorMessageResponsabilite = (name) => {
        return errorsResponsabilite[name] && <small className="p-error">{errorsResponsabilite[name].message}</small>
    };

    /**
     * le template qui fusion le nom le matricule et le prenom des enseignants
     * 
     * plusieurs enseignants peuvent avoir le meme nom et eventuellement le même prenom
     * 
     * ce template permet de selection plus precisement un enseignant en combinant les trois attributs cité plus haut
     */

    const optionsTemplate = rowData=>{
        if(listOfEnseignant.length !==0){
            let enseignant = listOfEnseignant.find(element=>element.idEnseignant === rowData.idEnseignant);
                if(enseignant && rowData.statutEnseignantResponsabilite){
                    return (
                        <div key={enseignant.matriculeEnseignant}>
                            <span >{enseignant.matriculeEnseignant} </span>
                            <span >{enseignant.nomEnseignant} </span>
                            <span >{enseignant.prenomEnseignant} </span>
                        </div>
                    );
                }
            // }
        }
    }
    const itemEnseignantTemplate = rowData=>{
       
        if(listOfEnseignant.length !==0){
                let enseignant = listOfEnseignant.filter(ense=>ense.idEnseignant)
                .find((enseignant)=>enseignant.idEnseignant === rowData.idEnseignant);
        
            return (
                <div key={enseignant.matriculeEnseignant}>
                    <span>{enseignant.matriculeEnseignant} </span>
                    <span>{enseignant.nomEnseignant} </span>
                    <span>{enseignant.prenomEnseignant} </span>
                </div>
            );
        }
    }

    const debutNominationTemplate = rowData=>{
       
        const debutNominaiton = new Date(rowData.dateDebutNomination).toLocaleDateString()
        if(rowData.statutEnseignantResponsabilite){
            return (
                <span>{debutNominaiton}</span>
                )
        }
    }

    const finNominationTemplate = rowData=>{
       if(rowData.dateFinNomination && rowData.statutEnseignantResponsabilite){
            const fintNominaiton = new Date(rowData.dateFinNomination).toLocaleDateString()
            return (
            <span>{fintNominaiton}</span>
        )
       }
       return <span>-</span>
    } 


    const libelleResponsabiliteTemplate = rowData=>{
        if(rowData.statutEnseignantResponsabilite){
            return (
                <span>{rowData.libelleResponsabilite}</span>
            )
        }
           
       
    }


    const actionHeader = <Button type="button" icon="pi pi-cog"/>;
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em'}} onClick={()=>handleModification(rowData)}/>
            </React.Fragment>
        );
    }

    
    const nominationFooter=(
        <React.Fragment>
             <div className="p-grid p-justify-end" style={{width:"100%"}}>
                <Button type="submit" label="Enregistrer" disabled={dialog.add && (saveList.length===0 || spin) } icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} onClick={handleSave} />
                <Button type="button" label="Quitter" className="p-button-danger" icon="pi pi-times" 
                onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}}
                 />
            </div>
        </React.Fragment>
    )

    const cancelResetFooter =(

        <React.Fragment>
            <Button label="NON" type="button" icon="pi pi-times" className="p-button-text" onClick={()=>setCancelAndReset(false)} />
            <Button label="OUI" type="button" icon="pi pi-check" className="p-button-danger" onClick={handleNominationDeletion} />
        </React.Fragment>
    )
  

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]);
        reset(); // reinitialisation des champs du formulaire
    }
    const monthNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) =>{console.log(event);return  e.onChange(event.originalEvent, event.value)}} style={{ lineHeight: 1 }} />;
    }

    const yearNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setDialog({...dialog,add:true})} />
                {/* <i className="pi pi-search" /> */}
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );


    const checkboxTemplate =(rowData)=>{
        return <Checkbox checked={rowData.responsabilitePriseEnCompte && rowData.statutEnseignantResponsabilite}/>
    }

    const handleResponsabilite=(dataForm)=>{
       
        dataForm.indiceRabattement=dataForm.indiceRabattement.value;
        responsabiliteService.create([dataForm])
        .then(()=>{
            responsabiliteService.getAll().then(response=>setListOfResponsabilite(response))
            resetResponsabilite();
            setResponsabilite(false);
        })
        .catch(errors=>{
           console.log(errors);
        });
    }
    return(
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="card">
                {header}
                {
                    loading?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable 
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        
                        rows={5} selectionMode="single" filterMatchMode="contains" 
                        paginator={true} responsive={true}
                        value={enseignantResponsabilite} globalFilter={globalFilter}
                        header="Liste des nominations en cours des enseignants"
                    >
                        <Column body={checkboxTemplate} headerStyle={{textTransform:"capitalize",width:"60px"}} style={{width:"60px"}}/>
                        <Column body={optionsTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}}/>
                        <Column body={libelleResponsabiliteTemplate} header="responsabilité" headerStyle={{textTransform:"capitalize"}}/>
                        <Column body={debutNominationTemplate} header="Date de Début"  headerStyle={{textTransform:"capitalize"}}/>
                        <Column body={finNominationTemplate} header="date de fin"  headerStyle={{textTransform:"capitalize"}}/>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{width:"120px"}} />
                       
                    </DataTable>
                    
                }
            </div>


            {/* Add Nomination Bloc */}
            <Dialog visible={dialog.add}
                footer={nominationFooter}
                style={{width:"85vw",height:"90vh"}}
                onHide={onHide}
            >

                <div className="p-grid">
                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                        <div className="p-d-flex p-jc-center">
                            <h5 className="text-header">Effectuer une nomination</h5>
                            <form className="p-fluid form-container" onSubmit={handleSubmit(handleNominationSubmit)}>
                                <div>
                                    <div className="p-field">
                                        <span>
                                            <label htmlFor="enseignant">Enseignant</label> {getFormErrorMessage("enseignant")} 
                                                <Controller name="enseignant" control={control} 
                                                    rules={{ required:"champ obligatoire"}}
                                                    render={({ field}) =>{
                                                        return  (
                                                            <Dropdown 
                                                                autoFocus
                                                                style={{height:"43px"}}
                                                                id={field.name} name={field.name} value={field.value} 
                                                                options={listOfEnseignant}
                                                                filter filterBy="nomPrenomEnseignant"
                                                                {...field}
                                                                onChange={(e)=> {field.onChange(e.value);}}
                                                                itemTemplate={itemEnseignantTemplate}
                                                                optionLabel="nomPrenomEnseignant"
                                                               
                                                            />
                                                        )
                                                    }} 
                                                />
                                        </span>
                                    </div>

                                    <div className="p-float" >
                                        <div>
                                            <label htmlFor="responsabilite">Responsabilité</label> {getFormErrorMessage("responsabilite")} 
                                            <Controller name="responsabilite" control={control} 
                                                rules={{ required:"champ obligatoire"}}
                                                render={({ field}) => (
                                                    <Dropdown 
                                                        autoFocus
                                                        style={{height:"45px"}}
                                                        id={field.name} name={field.name} value={field.value} 
                                                        onChange={(e)=>field.onChange(e.value)}
                                                        {...field}
                                                        options={listOfResponsabilite}
                                                        optionLabel="libelleResponsabilite"
                                                        // {...field}
                                                        // onChange={(e)=>field.onChange(e.value)}
                                                    
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div  style={{marginLeft:10,marginBottom:8}}>
                                            <Button  type="button" icon="pi pi-plus" className="p-button-info" onClick={()=>setResponsabilite(!responsabilite)} />
                                        </div>
                                </div>
                                    <div className="p-field" >
                                        <span>
                                            <label htmlFor="statut">Statut</label> {getFormErrorMessage("statutEnseignantResponsabilite")}
                                                <Controller name="statutEnseignantResponsabilite" control={control} 
                                                    defaultValue={getValues("statutEnseignantResponsabilite")}
                                                    rules={{ required:"champ obligatoire"}}
                                                    render={({ field}) => (
                                                        <Dropdown 
                                                            autoFocus 
                                                            style={{height:"45px"}}
                                                            id={field.name} name={field.name} value={field.value} 
                                                            onChange={(e)=>field.onChange(e.value)}
                                                            {...field}
                                                            options={statutEnseignant}
                                                            optionLabel="name" 
                                                        />
                                                    )} 
                                                />
                                        </span>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-field p-col-6">
                                            <label htmlFor="dateDebutNomination"> Date du début</label> {getFormErrorMessage("dateDebutNomination")} 
                                            <Controller 
                                                rules={{ required:"champ obligatoire"}}
                                                name="dateDebutNomination" control={control}
                                                render={({field})=> (
                                                <Calendar 
                                                    showIcon locale={addLocale} id={field.name}  name={field.name} 
                                                    monthNavigator yearNavigator yearRange= {`1960:${new Date().getFullYear()}`}
                                                    monthNavigatorTemplate={monthNavigatorTemplate} yearNavigatorTemplate={yearNavigatorTemplate} 
                                                    {...field}
                                                    onChange={(e)=>field.onChange(e.value)}
                                                />)}
                                            />
                                        </div> 
                                        <div className="p-field p-col-6">
                                            <label htmlFor="dateFinNomination"> Date de fin</label>
                                            <Controller 
                                                name="dateFinNomination" control={control}
                                                render={({field})=> (
                                                <Calendar showIcon locale={addLocale}  id={field.name} name={field.name}
                                                    monthNavigator yearNavigator yearRange= {`1960:${new Date().getFullYear()}`}
                                                    monthNavigatorTemplate={monthNavigatorTemplate} yearNavigatorTemplate={yearNavigatorTemplate} 
                                                    {...field}
                                                    onChange={(e)=>field.onChange(e.value)}
                                                />)}
                                            />
                                        </div>
                                    </div>                              
                                    
                                    <div className="p-field" >
                                        <div className="card" >
                                            <span style={{color:"red",marginBottom:"10px"}}>
                                                Cette nouvelle responsabilité devrait-elle être utilisé pour le calcul du volume horaire
                                                apprès Rabattement ? 
                                            </span>
                                            <Controller
                                                defaultValue={false}
                                                control={control}
                                                name="responsabilitePriseEnCompte"
                                                render={({field})=>(
                                                    <Checkbox name={field.name} id={field.name} value={true} {...field} checked={field.value} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <Button  type="submit" className="p-button-info" label="ajouter" icon="pi pi-plus" style={{width:"100px",marginRight:"15px"}} />
                                </div>
                                
                            </form>
                        </div>
                    </div>

                    <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                        <h5 className="text-header">Nouvellement ajouté </h5>

                        <div className=" p-fieldset" >
                            <table className="table" style={{minHeight:"300px"}}>
                                <thead>
                                    <tr className="p-grid">
                                        <th className="p-col-3">Enseignant</th>
                                        <th className="p-col-3">Responsabilité</th>
                                        <th className="p-col-2">Statut</th>
                                        <th className="p-col-2">Taux</th>
                                        <th className="p-col-2" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        saveList.map((res,key) =>
                                            <tr className="p-grid" key={key +1}>
                                                <td className="p-col-3">{res.enseignant.nomEnseignant} {res.enseignant.prenomEnseignant}</td>
                                                <td className="p-col-3">{res.responsabilite.libelleResponsabilite}</td>
                                                <td className="p-col-2">{res.statutEnseignantResponsabilite.name}</td>
                                                {
                                                    res.responsabilite.indiceRabattement==="heure"?<td className="p-col-2">{res.responsabilite.tauxRabattement}h</td>
                                                    :<td className="p-col-2">{res.responsabilite.tauxRabattement}%</td>
                                                }
                                                <td><Button icon="pi pi-trash" className="p-button-danger p-col-2"onClick={() => {removeItem(key)}}/> 
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>      
                            </table>
                        </div>
                    </div>
                </div>
            </Dialog>
            {/* Add Nomination EndBloc */}


            {/* modify nomination Bloc */}
            <Dialog visible={dialog.modify} style={{width:"40vw",height:"80vh"}}  onHide={onHide}>
                <div className="p-d-flex p-jc-center"  style={{height:"60vh"}}>
                    <h5 className="text-header">Modification</h5>
                    <form className="p-fluid" onSubmit={handleSubmit(handleSave)}>
                        <div style={{height:"60vh"}}>
                            <div className="p-field">
                                <span>
                                    <label htmlFor="enseignant">Enseignant</label> {getFormErrorMessage("enseignant")}
                                        <Controller name="enseignant" control={control} defaultValue={getValues("enseignant")} 
                                            rules={{ required:"champ obligatoire"}}
                                        render={({field})=>(
                                            <Dropdown 
                                                name={field.name} value={field.value} id={field.name}
                                                options={listOfEnseignant} optionLabel="nomPrenomEnseignant"
                                                onChange={(e)=>field.onChange(e.value)}
                                            />
                                        )}
                                        />
                                </span>
                            </div>
                            <div className="p-field" >
                                <span>
                                    <label htmlFor="responsabilite">Responsabilité</label> {getFormErrorMessage("responsabilite")}
                                        <Controller 
                                            name="responsabilite"  control={control} defaultValue={getValues("responsabilite")}
                                            rules={{ required:"champ obligatoire"}}
                                            render={({ field}) =>(
                                                <Dropdown
                                                    name={field.name} value={field.value} id={field.name}
                                                    options={listOfResponsabilite} optionLabel="libelleResponsabilite"
                                                    onChange={(e)=>field.onChange(e.value)}
                                                />
                                            )}
                                        />
                                </span>
                            </div> 

                            <div className="p-field" >
                                <span>
                                    <label htmlFor="statut">Statut</label> {getFormErrorMessage("statutEnseignantResponsabilite")}
                                        <Controller name="statutEnseignantResponsabilite" control={control} defaultValue={getValues("statutEnseignantResponsabilite")}
                                            rules={{ required:"champ obligatoire"}}
                                            render={({ field}) =>  { 
                                                    return <Dropdown 
                                                        autoFocus style={{height:"45px"}} id={field.name} value={field.value} 
                                                        onChange={(e)=>field.onChange(e.value)} {...field}
                                                        options={statutEnseignant} optionLabel="name" 
                                                    />
                                            }} 
                                        />
                                </span>
                            </div>
                            
                            <div className="p-grid">
                                <div className="p-field p-col-6">
                                    <label htmlFor="dateDebutNomination"> Date du debut</label> {getFormErrorMessage("dateDebutNomination")}
                                    <Controller 
                                        name="dateDebutNomination" control={control} defaultValue={new Date(getValues("dateDebutNomination"))}
                                        rules={{ required:"champ obligatoire"}}
                                        render={({field})=> {
                                            return <Calendar showIcon locale={addLocale} id={field.name}  name={field.name} value={field.value}
                                                monthNavigator yearNavigator yearRange= {`1960:2021}`} onChange={(e)=>field.onChange(e.value)}
                                                monthNavigatorTemplate={monthNavigatorTemplate} yearNavigatorTemplate={yearNavigatorTemplate} 
                                                {...field}
                                            />}
                                        }
                                    />
                                    
                                </div> 
                                <div className="p-field p-col-6">
                                    <label htmlFor="dateFinNomination"> Date de fin</label> {getFormErrorMessage("dateFinNomination")}
                                    <Controller 
                                        name="dateFinNomination" control={control} defaultValue={new Date(getValues("dateFinNomination"))}
                                        render={({field})=> {
                                            return <Calendar
                                                showIcon  id={field.name} name={field.name} locale={addLocale}
                                                monthNavigator yearNavigator yearRange= {`1960:2021}`} value={field.value}
                                                monthNavigatorTemplate={monthNavigatorTemplate} yearNavigatorTemplate={yearNavigatorTemplate} 
                                                {...field}
                                            />}}
                                    />
                                </div> 
                            </div>
                            <div className="p-field" >
                                <div className="card" >
                                    <span style={{color:"red",marginBottom:"10px"}}>
                                        Cette nouvelle responsabilité devrait-elle être utilisé pour le calcul du volume horaire
                                        apprès Rabattement ? 
                                    </span>
                                    <Controller
                                        defaultValue={getValues("responsabilitePriseEnCompte")}
                                        control={control}
                                        name="responsabilitePriseEnCompte"
                                        render={({field})=>(
                                            <Checkbox name={field.name} id={field.name} value={true} {...field} checked={field.value} />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex"}}>
                            <Button type="submit" disabled={spin?true:false} label="Enregistrer" className="p-button-success" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}}/>
                            <Button type="button" disabled={spin?true:false} label="Quitter" className="p-button-danger" icon="pi pi-times" onClick={onHide}/>
                        </div>

                    </form>
                </div>
            </Dialog>
            {/* modify nomination EndBloc */}


{/* 
            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteNominationFooter} onHide={()=>setDialog({...dialog,delete:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de supprimer cet élément ?</span> */}
                    {/* <span>L'enseignant {modificationNomination.enseignant.nomPrenomEnseignant} risque de perdre ça responsabilité {modificationNomination.libelleResponsabilite} </span> */}
                {/* </div>
            </Dialog> */}


            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelResetFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>


         {/* Add Responsabilite Bloc */}
         <Dialog className="card" visible={responsabilite} style={{width:"35vw",height:"60vh"}} onHide={()=>setResponsabilite(false)}>

            <div className="p-d-flex p-jc-center" style={{height:"30vh"}}>
                <h5 className="text-header">Ajouter une  responsabilité enseignant</h5>
                <form onSubmit={handleSubmitResponsabilite(handleResponsabilite)} style={{height:"300px"}} className="p-fluid">
                    <div style={{height:"230px"}}>
                        <div className="p-field">
                            <div >
                                <label htmlFor="libelleResponsabilite" style={{marginRight:"15px"}}>Libelle responsabilite</label>{getFormErrorMessageResponsabilite('libelleResponsabilite')}
                                <Controller name="libelleResponsabilite" control={controlResponsabilite} rules={{ required: 'Champ obligatoire.'}} render={({ field, fieldState }) => (
                                    <InputText type="text" id={field.name} {...field} autoFocus />
                                )} />
                            </div>
                            
                        </div>
                        <div className="p-field" >
                            <div>
                                <label htmlFor="tauxRabattement" style={{marginRight:"15px"}}>Taux Rabattement</label>  {getFormErrorMessageResponsabilite('tauxRabattement')}
                                <Controller name="tauxRabattement" control={controlResponsabilite} rules={{ required: 'Champ obligatoire.' }} render={({ field, fieldState }) => (
                                    <InputText keyfilter="num" type="text" id={field.name} {...field} autoFocus />
                                )} />
                                
                            </div>
                        
                        </div>

                        <div className="p-field">
                            <label htmlFor="indiceRabattement">indice du taux de rabattement</label> {getFormErrorMessageResponsabilite('indiceRabattement')}

                            <Controller name="indiceRabattement" control={controlResponsabilite} rules={{ required: 'Champ obligatoire.' }} render={({ field, fieldState }) => {
                                return  <Dropdown 
                                options={indiceTauxRabbatement} 
                                value={field.value}
                                onChange={(e)=>field.onChange(e.value)}
                                name={field.name} optionLabel="name"
                                {...field}
                            />  
                            }} />
                        </div>
                    </div>

                    <div style={{width:"100px"}}>
                        <Button type="submit" className="p-button-info" label="Ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                    </div>
                    
                </form>
            </div>

        </Dialog>
        {/* Add Responsabilite EndBloc */}


{/* 

        <Dialog visible={responsabiliteDiscipline.dialog} style={{ width: '450px' }} header="Confirmez" modal footer={responsabiliteDisciplineFooter} onHide={()=>setResponsabiliteDiscipline({...responsabiliteDiscipline,dialog:false})}>
            <div className="confirmation-content" >
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                <span >L'enseignant <span style={{fontWeight:"bold",fontStyle:"italic"}}>{responsabiliteDiscipline.nomEnseignant}</span> dispose déjà de la responsabilite <span style={{fontWeight:"bold",fontStyle:"italic"}}>{responsabiliteDiscipline.ancienResponsabilite}</span> qui est prise en compte dans le calcul des taux de rabattement</span><br /><br />
                <span> Désiriez vous le remplacer <span style={{fontWeight:"bold",fontStyle:"italic"}}>{responsabiliteDiscipline.ancienResponsabilite}</span> par <span style={{fontWeight:"bold",fontStyle:"italic"}}>{responsabiliteDiscipline.nouvelleResponsabilite}</span> ?</span>
            </div>
        </Dialog>
       
       */}
       
        </div>
    )
}
