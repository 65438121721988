import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { InfrastructureServices } from '../../service/InfrastructureServices';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';

const UtiliteInfrastructure = () => {
    
    let emptyEntite = {
        idUtiliteInfrastructure: null,
        libelleUtiliteInfrastructure: ''
    };

    const [entites, setEntites] = useState(null);
    const [entiteDialog, setEntiteDialog] = useState(false);
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false);
    const [entite, setEntite] = useState(emptyEntite);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const utiliteInfrastructureService = new InfrastructureServices();
    const notify = new Notify();
    const spinner = new Spinner();


    useEffect(() => {
        
            setLoading(true)
            utiliteInfrastructureService.getListeUtiliteInfrastructure().then(data => {
                setEntites(data)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(()=>setLoading(false));
        }, []
    );

    const openNew = () => {
        setEntite(emptyEntite);
        setSubmitted(false);
        setEntiteDialog(true);
    }
   
    const hideDialog = () => {
        setSubmitted(false);
        setEntiteDialog(false);
    }
    
    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false);
    }
    
    const saveEntite = () => {

        setSubmitted(true);
        if (entite.libelleUtiliteInfrastructure.trim()) {

            let _entite = {...entite};

            if (_entite.idUtiliteInfrastructure) {
                utiliteInfrastructureService.update(entite.idUtiliteInfrastructure, _entite).then(data => {
                    utiliteInfrastructureService.getListeUtiliteInfrastructure().then(data => setEntites(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
            }
            else {
                utiliteInfrastructureService.create(_entite).then(data => {
                    utiliteInfrastructureService.getListeUtiliteInfrastructure().then(data => setEntites(data));
                    notify.success();
                })
                .catch(error => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                })
                
            }
            
            setEntiteDialog(false);
            setEntite(emptyEntite);
        }
    }
    


    
    const editEntite = (entite) => {
        setEntite({...entite});
        setEntiteDialog(true);
    }
    


    
    const confirmDeleteEntite = (entite) => {
        setEntite(entite);
        setDeleteEntiteDialog(true);
    }
    

    
    const deleteEntite = () => {

        utiliteInfrastructureService.delete(entite.idUtiliteInfrastructure).then(data => {
            setEntite(emptyEntite);
            utiliteInfrastructureService.getListeUtiliteInfrastructure().then(data => setEntites(data));
            notify.success();
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message);
        })
        setDeleteEntiteDialog(false);
        
    }
    

    
    const onChampsChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _entite = {...entite};
        _entite[`${name}`] = val;

        setEntite(_entite);
    }
    

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={() => editEntite(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteEntite(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                {/* <i className="pi pi-search" /> */}
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );

    const entiteDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-success" onClick={saveEntite} />
        </React.Fragment>
    );

    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-success" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={deleteEntite} />
        </React.Fragment>
    );

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable ref={dt} value={entites} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des utilités">

                    <Column field="libelleUtiliteInfrastructure" header="Utilité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
                </DataTable>
                }
            </div>



            {/* Ajout d'une utilité  */}
            <Dialog visible={entiteDialog} style={{ width: '450px' }} header="Utilités infrastructure" modal className="p-fluid" footer={entiteDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="libelleUtiliteInfrasctructure">Utilité</label>
                    <InputText id="libelleUtiliteInfrasctructure" value={entite.libelleUtiliteInfrasctructure} onChange={(e) => onChampsChange(e, 'libelleUtiliteInfrastructure')} required autoFocus className={classNames({ 'p-invalid': submitted && !entite.libelleUtiliteInfrasctructure })} />
                    {submitted && !entite.libelleUtiliteInfrasctructure && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </Dialog>



            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {entite && <span>Etes vous sûr de supprimer cet élément ? <b>{entite.libelleUtiliteInfrasctructure}</b>?</span>}
                </div>
            </Dialog>

        </div>
    )

}
export default UtiliteInfrastructure