import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ECService {

    constructor(){
        this.adresse = new AdresseIP()
    }

   getAll() {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif"
       return axios.get(url).then(res => res.data)
   }
   
   create(newEC) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif"
       return axios.post(url,newEC)
                   .then(res => res.data);
   }
   
   update(idEC ,newEC) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif/"
       return axios.put(url+idEC, newEC)
                   .then(res => res.data);
   }
   
   delete(idEC) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif/"
       return axios.patch(url+idEC)
                   .then(res => res.data)
   }

   getListeECUniteEnseignement(idUniteEnseignement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/element-constitutif?idUniteEnseignement="+idUniteEnseignement
        return axios.get(url)
                    .then(res => res.data)
   }

   
   getListeEcTypeActivite(idParcoursFiliere, idTypeActivite, idAnneeAcademique){
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/elements-constitutifs-VH-TD?idParcoursFiliere="+idParcoursFiliere+"&idTypeActivite="+idTypeActivite+"&idAnneeAcademique="+idAnneeAcademique
    return axios.get(url)
                .then(res => res.data)
   }

   getListeTypeActiviteEc(idEc) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-activite-ec?idElementConstitutif="+idEc
    return axios.get(url)
                .then(res => res.data)
   }

}