import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
} from "react-router-dom";
import Deconnexion from "../components/Deconnexion";
import Hebergement from "../components/Hebergement";
import DepotEnLigne from "../components/DepotEnLigne";
import Ine from "../components/Ine";
import Lmd from "../components/Lmd";
import MenuLmd from "../lmd/components/MenuLmd";
import logoCampusFaso from "../images/campusfaso_logo_wb.png";
import logoOrientation from "../images/icons/svg/080-folder.svg";
import logoLmd from "../images/icons/svg/016-presentation.svg";
import logoHebergement from "../images/icons/svg/086-home.svg";
import logoOrdinateur from "../images/icons/svg/034-laptop.svg";
import logoReinscription from "../images/icons/svg/012-edit.svg";
import logoActe from "../images/icons/svg/013-layers.svg";
import logoRestauration from "../images/icons/svg/075-cutlery.svg";
import logoCatalogue from "../images/icons/svg/072-inbox.svg";
import logoFoner from "../images/icons/svg/003-money.svg";
import logoCiospb from "../images/icons/svg/084-bar chart.svg";
import logoAutorisation from "../images/icons/svg/031-book.svg";
import logoIpes from "../images/icons/svg/015-folder.svg";
import "../css/style.css";
import '../css/style_nouveau.css'
import "../css/themify-icons.css";
import { StructureService } from "../depotEnLigne/service/StructureService";
import VolumeHoraire from "../components/VolumeHoraire";
import Connexion from "../components/Connexion";
import { DepartementService } from "../service/DepartementService";
import { EtablissementService } from "../service/EtablissementService";
import { IesrService } from "../service/IesrService";

export class MenuHome extends Component {
    emptyDepartement = [
        {
            idDepartementEtab: null,
            lbDepartementEtab: "",
            idEtablissement: null,
            nomEtablissement: "",
            idIes: null,
            nomIes: "",
            ineFiliereEtablissementList: {
                idFiliere: null,
                lbFiliere: "",
                idDepartementEtab: null,
                lbDepartementEtab: "",
                nomEtablissement: "",
                nomIes: "",
            },
        },
    ];
    
    constructor(props) {
        const tailleEcran = typeof window === "object";
        const largeurEcran = tailleEcran ? window.innerWidth : undefined
        //const hauteurEcran= tailleEcran ? window.innerHeight : undefined
        let ecran = null
        if(largeurEcran < 768) ecran = 1   
        else  ecran = 0;


        super(props);
        this.state = {
            ecran : ecran,
            booleenLmd: false,
            booleenHebergement: false,
            booleenDepotEnLigne: false,
            booleenVolumeHoraire: false,
            token: null,
            structure: {},
            departement: this.emptyDepartement,
            keycloak: null,
            verife: false,
            boolenAcceuil: false,
            nomStructure: "",
        };

        /*this.departementService = new DepartementService()
        this.etablissementService = new EtablissementService()
        this.iesrService = new IesrService()*/

        this.structureService = new StructureService();
        this.departementService = new DepartementService();
        this.etablissementService = new EtablissementService();
        this.iesrService = new IesrService();
    }

    componentDidMount = () => {
        //console.log(this.props.keycloak)

        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        console.log(token);
        this.setState({ token: token });
        if (token.code_departement) {
            this.departementService
                .get(token.code_departement)
                .then((data) => {
                    if (token.code_departement === 385) {
                        this.setState({ verife: true });
                    }
                    console.log(data)
                    const dept = data[0];
                    console.log(dept)
                    this.setState({ nomStructure: dept.lbDepartementEtab });
                    localStorage.setItem("structure", JSON.stringify(dept));
                    localStorage.setItem(
                        "typeStructure",
                        JSON.stringify("departement")
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
            this.structureService
                .get(token.code_departement)
                .then((data) => {
                    this.setState({ structure: data });
                    console.log(data);
                    localStorage.setItem("structures", JSON.stringify(data));
                    localStorage.setItem("departement", JSON.stringify(data)); // pour permettre les parties que zida a geré
                    localStorage.setItem(
                        "typeStructures",
                        JSON.stringify("departement")
                    );
                })
                .catch((error) => {
                    localStorage.setItem("structures", JSON.stringify(null));
                    localStorage.setItem(
                        "typeStructures",
                        JSON.stringify("departement")
                    );
                    console.log(error);
                });
        } else if (token.code_etablissement) {
            this.etablissementService
                .get(token.code_etablissement)
                .then((data) => {
                    //console.log(data)
                })
                .catch((error) => {
                    console.log(error);
                });
            /*this.structureService.get(token.code_etablissement).then(data => {
                this.setState({structure: data})
                localStorage.setItem('structure', JSON.stringify(data))
                localStorage.setItem('typeStructure', JSON.stringify("etablissement"))
            })
            .catch(error => {
                localStorage.setItem('structure', JSON.stringify(null))
                localStorage.setItem('typeStructure', JSON.stringify("etablissement"))
                console.log(error)
            })*/
        } else if (token.code_iesr) {
            this.iesrService
                .get(token.code_iesr)
                .then((data) => {
                    //console.log(data)
                })
                .catch((error) => {
                    console.log(error);
                });
            this.structureService.get(token.code_iesr).then(data => {
                this.setState({structure: data})
                console.log(data)
                localStorage.setItem('structure', JSON.stringify(data))
                localStorage.setItem('typeStructure', JSON.stringify("iesr"))
            })
            .catch(error => {
                localStorage.setItem('structure', JSON.stringify(null))
                localStorage.setItem('typeStructure', JSON.stringify("iesr"))
                console.log(error)
            })
        }
        else if (token.code_ministere){
            this.iesrService
                .get(token.code_ministere)
                .then((data) => {
                    console.log(data)
                    localStorage.setItem('typeStructure', JSON.stringify("ministere"))
                })
                .catch((error) => {
                    localStorage.setItem('typeStructure', JSON.stringify("ministere"))
                    console.log(error);
                });
        }
        
        else {
            /*else if(){

        }*/
            //le client DGESup se connecte
            // code_departement DGESup = 385
            this.setState({ verife: true });
            this.structureService
                .get(385)
                .then((data) => {
                    this.setState({ structure: data });
                    console.log(data);
                    localStorage.setItem("structure", JSON.stringify(data));
                    localStorage.setItem("departement", JSON.stringify(data)); // pour permettre les parties que zida a geré
                    localStorage.setItem(
                        "typeStructure",
                        JSON.stringify("departement")
                    );
                })
                .catch((error) => {
                    localStorage.setItem("structure", JSON.stringify(null));
                    localStorage.setItem(
                        "typeStructure",
                        JSON.stringify("departement")
                    );
                    console.log(error);
                });
        }
    };

    loadAcceuilPage = () => {
        this.setState({ boolenAcceuil: true });
    };
    loadLmdPage = () => {
        this.setState({ booleenLmd: true });
    };
    loadHebergementPage = () => {
        this.setState({ booleenHebergement: true });
    };
    loadDepotEnLignePage = () => {
        this.setState({ booleenDepotEnLigne: true });
    };
    loadVolumeHorairePage = () => {
        this.setState({ booleenVolumeHoraire: true });
    };

    render() {
        const menuHome = (
            <div className="html">
                <div className="body">
                    <div className="grid-container">
                        <header className="header" style={{"backgroundColor" : "#c3e7d1"}}>
                            <div className="p-col-3 logo"
                                /*className="logo"
                                style={{ marginTop: -30, marginLeft: -300 }}*/
                            >
                                <a
                                    className="navbar-brand link-menu scroll-to-target current"
                                    href="/"
                                >
                                
                                    {<img 
                                        style={{
                                            //marginLeft: -700,
                                            //marginTop: 20,
                                            //width: 200

                                        }}
                                        className="logoimg"
                                        src={this.state.ecran ? "/assets/layout/images/cf_bullet.svg" : "/assets/layout/images/CampusFaso_Logo.png"}
                                        alt="CampusFaso"
                                    />}
                                </a>
                            </div>
                            <div className="titlePi p-col-5">
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        //marginLeft: -600,
                                    }}
                                >
                                    Plateforme integrée de l'Enseignement
                                    Supérieur CAMPUSFASO
                                </span>*
                            </div>
                            {this.state.token ? (
                                <div className="header-text ">
                                    <i className="ti-user"></i>
                                    Bienvenue{" "}
                                    <span style={{ color: "green" }}>
                                        {this.state.token.name}
                                    </span>
                                    {this.state.nomStructure ? " ( " + this.state.nomStructure + " ) " : ""} |
                                    <a
                                        href="#"
                                        onClick={() => {
                                            localStorage.clear();
                                            {
                                                /*this.props.clickService  ?  this.props.keycloak.keycloak.logout() :*/ this.props.keycloak.logout();
                                            }
                                        }}
                                    >
                                        <i className="ti-close"></i>
                                        Déconnexion
                                    </a>{" "}
                                    |
                                    <div style={{"display": "none"}}
                                        href="#"
                                        className="btn"
                                        onClick={this.loadAcceuilPage}
                                    >
                                        <i className="ti-home"></i>
                                        Accueil
                                    </div>
                                </div>
                            ) : (
                                <div>Cet utilisateur n'a pas de rôle</div>
                            )}
                        </header>
                        <aside className="sidenav">
                            <div className="logo">
                                <img src={logoCampusFaso} alt="" />
                                <div className="txts">
                                    Plateforme intégrée de <br />
                                    l’enseignement supérieur <br />
                                    et de la recherche
                                </div>
                            </div>
                        </aside>
                        <main className="main">
                            <div className="title">
                                Mes{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    services
                                </span>
                            </div>
                            <div className="apps">
                                {this.state.verife ? (
                                    <>
                                        <div
                                            className="appcard"
                                            onClick={() => {
                                                this.loadDepotEnLignePage();
                                            }}
                                        >
                                            <div className="icon">
                                                <img
                                                    src={logoAutorisation}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="desc">
                                                <h3>
                                                    Autorisation
                                                    d'enseigner/Équivalence de
                                                    diplôme
                                                </h3>
                                                <p style={{ color: "black" }}>
                                                    dépôt des demandes
                                                    d'autorisation d'enseigner
                                                    au supérieur, équivalence et
                                                    reconnaissance de diplômes
                                                </p>
                                            </div>
                                        </div>

                                        <a href="#">
                                            <div className="appcard">
                                                <div className="icon">
                                                    <img
                                                        src={logoIpes}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="desc">
                                                    <h3>
                                                        Demande de création des
                                                        Institutions Privées
                                                        d'Enseignement Supérieur
                                                    </h3>
                                                    <p
                                                        style={{
                                                            color: "black",
                                                        }}
                                                    >
                                                        dépôt et demande de
                                                        création d'IPES
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        {/*<a href="#" >
                                <div className="appcard">
                                    <div className="icon"><img src={logoOrientation} alt=""/></div>
                                    <div className="desc">
                                        <h3>Orientations</h3>
                                        <p style={{color : "black"}}>
                                            Plateforme d'orientation et d'inscription des étudiants
                                        </p>
                                    </div>
                                </div>
                            </a>*/}

                                        <div
                                            className="appcard"
                                            onClick={() => {
                                                this.loadLmdPage();
                                            }}
                                        >
                                            <div className="icon">
                                                <img src={logoLmd} alt="" />
                                            </div>
                                            <div className="desc">
                                                <h3>LMD</h3>
                                                <p style={{ color: "black" }}>
                                                    Gestion du système Licence
                                                    Master Doctorat
                                                </p>
                                            </div>
                                        </div>

                                        {/*<div className="appcard" onClick={()=>{this.loadHebergementPage()}}>
                                    <div className="icon"><img src={logoHebergement} alt=""/></div>
                                    <div className="desc">
                                        <h3>Hébergement</h3>
                                        <p style={{color : "black"}}>
                                            Suivi des demandes d'hébergement en cité
                                        </p>
                                    </div>
                                </div>*/}

                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoOrdinateur} alt=""/></div>
                                    <div className="desc">
                                        <h3>1 Etudiant 1 Ordinateur</h3>
                                        <p style={{color : "black"}}>
                                           Gestion du programme présidentiel un étudiant un ordinateur
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoReinscription} alt=""/></div>
                                    <div className="desc">
                                        <h3>Réinscription</h3>
                                        <p style={{color : "black"}}>
                                           Plateforme de réinscription administrative et pédagogique
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoActe} alt=""/></div>
                                    <div className="desc">
                                        <h3>Actes académique et pédagogique</h3>
                                        <p style={{color : "black"}}>
                                           Plateforme de réinscription administrative et pédagogique
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoRestauration} alt=""/></div>
                                    <div className="desc">
                                        <h3>Restauration</h3>
                                        <p style={{color : "black"}}>
                                           Plateforme de digitalisation du processus de la restauration universitaire
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoCatalogue} alt=""/></div>
                                    <div className="desc">
                                        <h3>Catalogue de formation</h3>
                                        <p style={{color : "black"}}>
                                           Plateforme de présentation des differentes offres de formations pour le supérieur
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoFoner} alt=""/></div>
                                    <div className="desc">
                                        <h3>FONER</h3>
                                        <p style={{color : "black"}}>
                                           Plateforme de digitalisation du processus d'octroie du FONER
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<a href="#">
                                <div className="appcard">
                                    <div className="icon"><img src={logoCiospb} alt=""/></div>
                                    <div className="desc">
                                        <h3>CIOSPB</h3>
                                        <p style={{color : "black"}}>
                                           Plateforme de digitalisation du processus d'octroie de la bourse
                                        </p>
                                    </div>
                                </div>
                            </a>*/}
                                        {/*<div className="appcard" onClick={()=>{this.loadDepotEnLignePage()}}>
                                <div className="icon"><img src={logoAutorisation} alt=""/></div>
                                <div className="desc">
                                    <h3>Autorisation d'enseigner/Équivalence de diplôme</h3>
                                    <p style={{color : "black"}}>
                                       dépôt des demandes d'autorisation d'enseigner au supérieur, équivalence et reconnaissance de diplômes
                                    </p>
                                </div>
                            </div>*/}

                                        {/*<a href="#">
                            <div className="appcard">
                                <div className="icon"><img src={logoIpes} alt=""/></div>
                                <div className="desc">
                                    <h3>Demande de création des Institutions Privées d'Enseignement Supérieur</h3>
                                    <p style={{color : "black"}}>
                                       dépôt et demande de création d'IPES
                                    </p>
                                </div>
                            </div>
                        </a>*/}
                                        <div
                                            className="appcard"
                                            onClick={() => {
                                                this.loadVolumeHorairePage();
                                            }}
                                        >
                                            <div className="icon">
                                                <img
                                                    src={logoAutorisation}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="desc">
                                                <h3>Volume horaire</h3>
                                                <p style={{ color: "black" }}>
                                                    Gestion du volume horaire
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </main>
                        <div className="footer">
                            <ul className="liens">
                                <li>
                                    <a href="">CGU</a>
                                </li>
                                <li>
                                    <a href="">Politique de confidentialité</a>
                                </li>
                                <li>
                                    <a href="">FAQ</a>
                                </li>
                            </ul>
                            <p>
                                &copy; 2021 &middot; MESRSI &middot;Tous droits
                                réservés
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <>
                {this.state.boolenAcceuil ? (
                    <Connexion keycloakCon={this.props.keycloak} />
                ) : this.state.booleenLmd ? (
                    <Lmd keycloak={this.props.keycloak} />
                ) : this.state.booleenHebergement ? (
                    <Hebergement keycloak={this.props.keycloak} />
                ) : this.state.booleenDepotEnLigne ? (
                    <DepotEnLigne keycloak={this.props.keycloak} />
                ) : this.state.booleenVolumeHoraire ? (
                    <VolumeHoraire keycloak={this.props.keycloak} />
                ) : (
                    menuHome
                )}
            </>
        );
    }
}

export default MenuHome;
