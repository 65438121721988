import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class EntiteService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/entites"
       return axios.get(url).then(res => res.data)
   }
   

   create(newEntite) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/entites"
       return axios.post(url,newEntite)
                   .then(res => res.data);
   }
   
   update(idEntite ,newEntite) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/entites/"
       return axios.put(url+idEntite, newEntite)
                   .then(res => res.data);
   }

   delete(idEntite) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/entites/"
       return axios.patch(url+idEntite)
                   .then(res => res.data)
   }


}