import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { SessionService } from '../../service/SessionService'
import { TypeDemandeService } from '../../service/TypeDemandeService'
import { TypeSessionService } from '../../service/TypeSessionService'
import { StructureService } from '../../service/StructureService';
import { CategorieDemandeService } from '../../service/CategorieDemandeService';

export class Session extends Component {
    emptySession = {
        idSession: null,
        libelleSession : "",
        anneeSession : null,
        statutSession: "OUVERT",
        dateDebutSession:"",
        dateFinSession : "",
        typeSession: {},
        typeDemande: {},
        
    }

    constructor() {
        super();
        this.state = {
            listeSession : [],
            listeTypeSession : [],
            listeTypeDemande : [],
            listeAnnee : [],
            categorieDemande : {},
            listeCategorieDemande : [],
            structure : {},
            listeStatutSession : [{idSatutSession: 1, statutSession : "OUVERT"}, {idSatutSession: 2, statutSession : "FERME"}],
            sessionDialog: false,
            deleteSessionDialog: false,
            session: this.emptySession,
            submitted: false,
            loading : false,
            globalFilter: null
        }
        this.sessionService = new SessionService();
        this.typeSessionService = new TypeSessionService();
        this.typeDemandeService = new TypeDemandeService();
        this.structureService = new StructureService();
        this.categorieDemandeService = new CategorieDemandeService();
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveSession = this.saveSession.bind(this);
        this.editSession = this.editSession.bind(this);
        this.confirmDeleteSession = this.confirmDeleteSession.bind(this);
        this.deleteSession = this.deleteSession.bind(this);
        this.onStatutSessionChange = this.onStatutSessionChange.bind(this);
        this.onLibelleSessionChange = this.onLibelleSessionChange.bind(this);
        this.onAnneeChange = this.onAnneeChange.bind(this);
        this.onIdCategorieDemandeChange = this.onIdCategorieDemandeChange.bind(this);
        this.hideDeleteSessionDialog = this.hideDeleteSessionDialog.bind(this);


    }


    componentDidMount() {

        const structure = localStorage.getItem('structure')


        console.log(localStorage)
        const struct = JSON.parse(structure)
        console.log(struct)
        this.setState({structure : struct});
        let today = new Date();
        let year = today.getFullYear();
        let listeAnnee = [];
        for(let i = 0; i < 100; i++){
            listeAnnee.push({idAnnee : i+1, anneeSession : 2021+i })
        }
        this.setState({listeAnnee});
        this.typeSessionService.getAll().then(data => {
            this.setState({listeTypeSession: data})
        })
        .catch(error => {this.notify.echec(error.response.data.message) ; console.log(error)})

        this.setState({loading : true});

       this.sessionService.get(year, struct.idDepartementEtab, 'NIVEAU_III',"OUVERT","").then(donnee => {
            console.log(donnee);
            this.setState({listeSession : donnee, loading : false});
        })
        .catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message);
        })

        this.categorieDemandeService.get(struct.idDepartementEtab, 'NIVEAU_III').then(data => 
            this.setState({listeCategorieDemande: data})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
        
    }


    openNew() {
        this.setState({
            session: this.emptySession,
            submitted: false,
            sessionDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            sessionDialog: false
        });
    }
    hideDeleteSessionDialog() {
        this.setState({ deleteSessionDialog: false });
    }
    saveSession() {
        let state = { submitted: true };
        let today = new Date();
        let year = today.getFullYear();
        console.log(this.state.session)
        //if (this.state.session.statusSession.trim() && this.state.session.statusSession) {
    if (this.state.session.typeSession.idTypeSession && this.state.session.typeDemande.idTypeDemande && this.state.session.statutSession && this.state.session.dateDebutSession && this.state.session.dateFinSession) {
            const newSession = {
                idSession: parseInt(this.state.session.idSession) ,
                libelleSession : this.state.session.libelleSession,
                idTypeSession: parseInt(this.state.session.typeSession.idTypeSession),
                idTypeDemande: parseInt(this.state.session.typeDemande.idTypeDemande),
                statutSession : this.state.session.statutSession.statutSession,
                dateDebutSession :this.state.session.dateDebutSession,
                dateFinSession :this.state.session.dateFinSession,
                idStructure : this.state.structure.idDepartementEtab,
                libelleStructure : this.state.structure.libDepartementEtab,
                niveau : 'NIVEAU_III',
                //niveau : this.state.structure.niveau, 
               // typeSession : this.state.session.typeSession,
                //typeDemande : this.state.session.typeDemande,
                //anneeSession : parseInt(this.state.session.anneeSession),
                //structure : this.state.structure
            }
            console.log(newSession)
            if (this.state.session.idSession) {
                this.sessionService.update(parseInt(this.state.session.idSession), newSession)
                                .then(data => {
                                    this.notify.success()
                
                                    this.setState({loading : true})
                                    this.sessionService.get(year, this.state.structure.idDepartementEtab, 'NIVEAU_III', "OUVERT","").then(data => 
                                        this.setState({listeSession: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)  
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.sessionService.create(newSession).then(data => {
                    this.notify.success()
                    this.setState({loading : true})
                    this.sessionService.get(year, this.state.structure.idDepartementEtab, 'NIVEAU_III', "OUVERT", "").then(donnee => {
                        console.log(donnee);
                        this.setState({listeSession : donnee, loading : false});
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({loading : false});
                    })
            
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    this.setState({loading : false})
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                sessionDialog: false,
                session: this.emptySession
            };
        }

        this.setState(state);
    }
    editSession(session) {
        console.log(session);
        this.setState({
            session,
            sessionDialog: true
        });
    }
    confirmDeleteSession(session) {
        this.setState({
            session,
            deleteSessionDialog: true
        });
    }
    deleteSession(idSession) {
        //console.log(idSession)
        let today = new Date();
        let year = today.getFullYear();
        this.sessionService.delete(parseInt(idSession)).then(data => {
            this.notify.success()
            this.setState({
                deleteSessionDialog: false, 
                session: this.emptySession,
            });
            this.setState({loading : true})
            this.sessionService.get(year, this.state.structure.idDepartementEtab, 'NIVEAU_III', "OUVERT","").then(donnee => {
                console.log(donnee);
                this.setState({listeSession : donnee, loading : false});
            })
            .catch(error => {
                console.log(error);
                this.setState({loading : false});
            })
            
        })
    }
    onLibelleSessionChange(e, libelleSession){
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${libelleSession}`] = val;

        this.setState({session });
    }
    onStatutSessionChange(e, statutSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${statutSession}`] = val;

        this.setState({session });
    }
    onAnneeChange(e, anneeSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${anneeSession}`] = val;

        this.setState({session });
        this.setState({loading : true})
        this.sessionService.get(val.anneeSession, this.state.structure.idDepartementEtab, 'NIVEAU_III', "OUVERT","").then(data => {
            console.log(data);
            this.setState({listeSession : data, loading : false});
        })
        .catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message);
        })
    }
    onAnneeChange2(e, anneeSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${anneeSession}`] = val;

        this.setState({session });
    }
    onIdTypeSessionChange(e, typeSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${typeSession}`] = val;

        this.setState({ session });
    }
    onIdTypeDemandeChange(e, typeDemande) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${typeDemande}`] = val;

        this.setState({ session });
    }

    onDateDebutSessionChange(e, dateDebutSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${dateDebutSession}`] = val;

        this.setState({ session });
    }

    onDateFinSessionChange(e, dateFinSession) {
        const val = (e.target && e.target.value) || '';
        let session = this.state.session;
        session[`${dateFinSession}`] = val;

        this.setState({ session });
    }

    onIdCategorieDemandeChange(e, categorieDemande) {
        console.log(categorieDemande);
        const val = (e.target && e.target.value) || '';
        this.setState({ categorieDemande : val });
        this.typeDemandeService.get(val.idCategorieDemande).then(data => {
            this.setState({listeTypeDemande : data});
        })
        .catch(error => {
            console.log(error);
            this.notify.echec(error.response.data.message);
        })
    }
   
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                {/*<Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editSession(rowData)} style={{marginRight: '.5em'}}/>*/}
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteSession(rowData)} />
            </React.Fragment>
        );
    }
    render() {
       
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const sessionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSession} />
            </React.Fragment>
        );
        const deleteSessionDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSessionDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteSession(this.state.session.idSession) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        <div className="p-grid p-col-12">
                            <div className="p-col-5" ></div>
                            <div className="p-col-6 p-field">
                                <label htmlFor="anneeSession">Année : </label>
                                <Dropdown style={{marginLeft : 10}} optionLabel="anneeSession" optionValue="anneeSession" value={this.state.session.anneeSession} options={this.state.listeAnnee} onChange={(e) => {this.onAnneeChange(e, 'anneeSession')}} required autoFocus  />
                            </div>
                        </div>
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSession} selectionMode="single" header="Liste de session" paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleSession" header="Session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="anneeSession" header="Annee"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleTypeSession" header="Type session" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher type session"/>
                            <Column field="libelleTypeDemande" header="Type demande" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher type demande"/>
                            <Column field="statutSession" header="Status session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="dateDebutSession" header="Date debut"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="dateFinSession" header="Daate fin"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.sessionDialog} style={{ width: '450px' }} header="Informations session" modal className="p-fluid" footer={sessionDialogFooter} onHide={this.hideDialog}>
                     
                     {/*<div className="p-field">
                        <label htmlFor="anneeSession">Année *</label>
                        <InputText id="anneeSession" value={this.state.session.anneeSession}  onChange={(e) => this.onAnneeChange2(e, 'anneeSession')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.session.anneeSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>*/}
                     <div className="p-field">
                        <label htmlFor="libelleTypeSession">Type session</label>
                        <Dropdown optionLabel="libelleTypeSession" optionValue="idTypeSession" value={this.state.session.typeSession} options={this.state.listeTypeSession} onChange={(e) => {this.onIdTypeSessionChange(e, 'typeSession')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.session.typeSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleCategorieDemande">Categorie de de demande</label>
                        <Dropdown optionLabel="libelleCategorieDemande" optionValue="idCategorieDemande" value={this.state.categorieDemande} options={this.state.listeCategorieDemande} onChange={(e) => {this.onIdCategorieDemandeChange(e, 'categorieDemande')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.categorieDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleTypeDemande">Type demande</label>
                        <Dropdown optionLabel="libelleTypeDemande" optionValue="idTypeDemande" value={this.state.session.typeDemande} options={this.state.listeTypeDemande} onChange={(e) => {this.onIdTypeDemandeChange(e, 'typeDemande')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.session.typeDemande && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="libelleSession">Libelle session</label>
                        <InputText id="libelleSession" value={this.state.session.libelleSession}  onChange={(e) => this.onLibelleSessionChange(e, 'libelleSession')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                        {this.state.submitted && !this.state.session.libelleSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="statutSession">Status session</label>
                        <Dropdown optionLabel="statutSession" optionValue="statutSession"  value={this.state.session.statutSession} options={this.state.listeStatutSession} onChange={(e) => {this.onStatutSessionChange(e, 'statutSession')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}/>
                        {this.state.submitted && !this.state.session.statutSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="dateDebutSession">Date debut</label>
                        {<Calendar dateFormat="dd/mm/yy" id="dateDebutSession" value={this.state.session.dateDebutSession} onChange={(e) => this.onDateFinSessionChange(e,'dateDebutSession')} showIcon />}
                        {this.state.submitted && !this.state.session.dateDebutSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="dateFinSession">Date Fin</label>
                        {<Calendar dateFormat="dd/mm/yy" id="dateFinSession" value={this.state.session.dateFinSession} onChange={(e) => this.onDateFinSessionChange(e,'dateFinSession')} showIcon />}
                        {this.state.submitted && !this.state.session.dateFinSession && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    
                </Dialog>
                <Dialog visible={this.state.deleteSessionDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSessionDialogFooter} onHide={this.hideDeleteSessionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.session && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Session
