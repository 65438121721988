import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ExamenService {

     constructor(){
         this.adresse = new AdresseIP()
     }
     
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen"
        return axios.get(url).then(res => res.data)
    }
    
    create(newExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen"
        return axios.post(url,newExamen)
                    .then(res => res.data);
    }

    getListeExamenByEc(idSession, idElementConstitutif){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen?idSession="+idSession+"&idEc="+idElementConstitutif
        return axios.get(url)
                    .then(res => res.data);
    }

    createSoutenance(newSoutenance) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/soutenances"
        return axios.post(url,newSoutenance)
                    .then(res => res.data);
    }

    update(idExamen ,newExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen/"
        return axios.put(url+idExamen, newExamen)
                    .then(res => res.data);
    }

    delete(idExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen/"
        return axios.patch(url+idExamen)
                    .then(res => res.data)
    }

    examenBySession(idSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen?idSession="+idSession
        return axios.get(url)
                    .then(res => res.data)
    }

    soutenanceBySession(idSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/soutenances?idSession="+idSession
        return axios.get(url)
                    .then(res => res.data)
    }

    examenByEc(idEc, idAnnee) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen?idEc="+idEc+"&idAnnee="+idAnnee
        return axios.get(url)
                    .then(res => res.data)
    }

    getListCandidat(idExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/information-examen/"+idExamen
        return axios.get(url)
                    .then(res => res.data)
    }

    getListNotation(idExamen) {

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/information-examen?idExamen="+idExamen
        return axios.get(url)
                    .then(res => res.data)
    }

    getListeEtudiantSoutenance(idExamen){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant-soutenance?idExamen="+idExamen
        return axios.get(url)
                    .then(res => res.data)
    }

    saveFileSoutenance(idDepartement, idExamen, importFichier){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/upload-rapport/"+idExamen+"/"+idDepartement
        return axios.post(url, importFichier)
                    .then(res => res.data)
    }

    getFileSoutenance(idDepartement, idExamen){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/upload-rapport/"+idExamen+"/"+idDepartement
        return axios.get(url)
                    .then(res => res.data)
    }

    getListeSurveillantExamen(idExamen){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen-surveillant?idExamen="+idExamen
        return axios.get(url)
                    .then(res => res.data)
    }


    getSaveSurveillantExamen(listeSurveillant){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen-surveillant"
        return axios.post(url, listeSurveillant)
                    .then(res => res.data)
    }


    getListeInfrastructure(idDepartement, dateExamen, heureExamen, heureFinExamen){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-ies/"+idDepartement+"?date="+dateExamen+"&heureDeb="+heureExamen+"&heureFin="+heureFinExamen
        
        //console.log(url)
        return axios.get(url)
                    .then(res => res.data)
    }

}