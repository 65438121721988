import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SpecialiteService {

     constructor(){
         this.adresse = new AdresseIP()
     }


    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/specialites"
        return axios.get(url).then(res => res.data) 
    }


    getAllSansObjet() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/specialite-sans-objet"
        return axios.get(url).then(res => res.data) 
    }
    

    
    create(newSpecialite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/specialites"
        return axios.post(url,newSpecialite)
                    .then(res => res.data);
    }

    
    update(idSpecialite ,newSpecialite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/specialites/"
        return axios.put(url+idSpecialite, newSpecialite)
                    .then(res => res.data);
    }

    
    
    delete(idSpecialite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/specialites/"
        return axios.patch(url+idSpecialite)
                    .then(res => res.data)
    }
    
}