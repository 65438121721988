import React, {useEffect, useState } from 'react'
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Spinner } from '../../components/Spinner';

function Dashbord() {

  const [loading, setloading] = useState(false);
  const [annee, setAnnee] = useState(null);
  const [listeAnnee, setListeAnnee] = useState([]);
  const [listeDemande, setListeDemande] = useState([]);
  const [listeDemandeRe, setListeDemandeRe] = useState([])
  const [pieData, setPieData] = useState(null);
  const [chartData, setCharData] = useState({});
  const [lineData, setLineData] = useState({});
  const [isAffiche, setIsAffiche] = useState(false);

  const spinner = new Spinner()

  const onAnneeChange = (e, field) => {
    const val = (e.target && e.target.value) || '';
    setAnnee(val)
  }

  const chartDataCombo = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [{
        type: 'line',
        label: 'Dataset 1',
        borderColor: '#42A5F5',
        borderWidth: 2,
        fill: false,
        tension: .4,
        data: [
            50,
            25,
            12,
            48,
            56,
            76,
            42
        ]
    }, {
        type: 'bar',
        label: 'Dataset 2',
        backgroundColor: '#66BB6A',
        data: [
            21,
            84,
            24,
            75,
            37,
            65,
            34
        ],
        borderColor: 'white',
        borderWidth: 2
    }, {
        type: 'bar',
        label: 'Dataset 3',
        backgroundColor: '#FFA726',
        data: [
            41,
            52,
            24,
            74,
            23,
            21,
            32
        ]
    }]
};

const lightOptionsCombo = {
    maintainAspectRatio: false,
    aspectRatio: .6,
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef'
            }
        }
    }
};

let items = ['Hommes', 'Femmes']
let colorData = ["#66BB6A", "#FFA726"]
let donnees = [3, 2]
let colorDataRd = ["#66BB6A", "#FFA726"]
let donneesRd = [2, 1]
const chartDataPie = {
    labels: items,
    datasets: [
        {
            data: donnees,
            backgroundColor: colorData
        }
    ]
};

const chartDataPieRd = {
    labels: items,
    datasets: [
        {
            data: donneesRd,
            backgroundColor: colorDataRd
        }
    ]
}

const lightOptionsPie = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
};

const getLightTheme = () => {
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    let multiAxisOptions = {
        stacked: false,
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    color: '#495057'
                },
                grid: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                }
            }
        }
    };

    return {
        basicOptions,
        multiAxisOptions
    }
}

const { basicOptions, multiAxisOptions } = getLightTheme();

  

const [lightOptions] = useState({
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
});

useEffect(() => {
    /*setloading(true);
    programmationService.getProgrammationDepartement(code_departement).then((response=[])=>{
        let nbProgrammer = response.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
        let nbRealiser = response.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
        setprogrammation({...programmation,nbProgrammer,nbRealiser});
    })
    .finally(()=>setloading(false))*/

    //cohorteServices.getListeParcoursDepartement(code_departement).then(response=>{console.log(response);setlistParcours(response)})
    
    /*anneeService.getAll().then(data => {
        setListeAnnee(data);  
        
    })*/
    /*const listeAnnee = [
        {idAnnee : 1, libelleAnnee : "2022-2023"}
    ]
    setListeAnnee(listeAnnee)

    const listDemande =[
        {idDemande : 1, nomPrenom : "OUEDRAOGO Saidou", dateDeNaissance :  "12-12-2002", lieuDeNaissance : "Ouagadougou", disciplinesEnseignement : "Informatique"},
        {idDemande : 2, nomPrenom : "ZIDA Christian", dateDeNaissance :  "21-10-2005", lieuDeNaissance : "Koudougou", disciplinesEnseignement : "Mathématique"},
        {idDemande : 3, nomPrenom : "SOME Narcisse", dateDeNaissance :  "31-12-2008", lieuDeNaissance : "Dano", disciplinesEnseignement : "Physique"},
        {idDemande : 4, nomPrenom : "KABORE Juliette", dateDeNaissance :  "13-09-2000", lieuDeNaissance : "Ouagadougou", disciplinesEnseignement : "Philosophie"},
        {idDemande : 5, nomPrenom : "BAYALA Georgette", dateDeNaissance :  "05-06-2001", lieuDeNaissance : "Ouahigouya", disciplinesEnseignement : "Lettres modernes"}
    ]
    setListeDemande(listDemande)

    const listeDemandeRecon =[
        {idDemande : 1, nomPrenom : "KONATE Brahima", dateDeNaissance :  "22-11-2003", lieuDeNaissance : "Kuini", disciplinesEnseignement : "Sociologie"},
        {idDemande : 2, nomPrenom : "WANGRE Mathias", dateDeNaissance :  "03-10-2003", lieuDeNaissance : "Ouagadougou", disciplinesEnseignement : "Anthropologie"},
        {idDemande : 3, nomPrenom : "ZONGO Simone", dateDeNaissance :  "04-04-1998", lieuDeNaissance : "Bobo-Dioulasso", disciplinesEnseignement : "Chimie"}
    ]
    setListeDemandeRe(listeDemandeRecon)*/


}, []);

const diagramCircle = () => {
    let homme = 0
    let femme = 0

    let pieData = {
        labels: ['Hommes', 'Femmes'],
        datasets: [
            {
                data: [homme, femme],
                backgroundColor: [
                    "#66BB6A",
                    "#FFA726",
                    "red",
                    
                
                ],
                hoverBackgroundColor: [
                    "#81C784",
                    "#FFB74D",
                    "red",
                
                    
                ]
            }
        ]
    }
    setCharData(pieData)
}

const diagramLine = () => {
    let donneeSeanceEff = [];
    let donneeSeanceProg = [];
    let mois = [];

    const chartLineCombo = {
        labels: mois,
        datasets: [
            {
                type: 'line',
                label: 'séances programmées',
                borderColor: '#42A5F5',
                borderWidth: 2,
                fill: false,
                tension: .4,
                data: donneeSeanceProg  
            }
            ,
            {
            type: 'line',
            label: 'séances effectuées',
            borderColor: '#66BB6A',
            borderWidth: 2,
            fill: false,
            tension: .4,
            data: donneeSeanceEff
        }
        
    ]
    };
    setLineData(chartLineCombo)
    const lightOptionsCombo = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
}

  return (
    <div className="card">              
        <div className="p-col-12 p-grid">
                  
                 {/*<div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={""} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeDemande} header="Liste des demandeurs autrisation d'enseigner"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="nomPrenom" header="Nom et prénom"/>
                            <Column field="dateDeNaissance" header="Date de naissance" />
                            <Column field="lieuDeNaissance" header="Lieu de naissance" />
                            <Column field="disciplinesEnseignement" header="Discipline" />
                            
                        </DataTable>
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeDemandeRe} header="Liste des demandeurs reconnaissance de diplômes"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="nomPrenom" header="Nom et prénom"/>
                            <Column field="dateDeNaissance" header="Date de naissance" />
                            <Column field="lieuDeNaissance" header="Lieu de naissance" />
                            <Column field="disciplinesEnseignement" header="Discipline" />
                            
                        </DataTable>
                    </div>
                 </div>*/}
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5 flex justify-content-center">
                        
                        <>
                        <h5>Nombre de dépôt par genre autorisation d'enseigner</h5>
                        <Chart type="pie" data={chartDataPie} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                        </>
                </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        
                            <>
                            <h5>Nombre de dépôt par genre reconnaissance de diplômes</h5>
                            <Chart type="pie" data={chartDataPieRd} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
            
                        
                    </div>
                 </div>
                    
            
            </div>
            
    </div>
  )
}

export default Dashbord