import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP"


export default class EnseignantService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/enseignants"
    }
    

    getAll(){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }

    getEnseignantProgrammeByAnneeDepartement(idDepartement, idAnnee){
        let url = this.addresseIP.getAdresseVolumeHoraire() + "/service/api/campusfaso-volume-horaire/campus-faso/affectation-enseignant-dep?idDepartement=" + idDepartement +"&idAnneeAcademique=" + idAnnee
        return axios.get(url).then(response=>response.data);
    }

    create(listEnseignant){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,listEnseignant).then(response=>response.data)
    }
    
    
    update(enseignant){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${enseignant.idEnseignant}`,enseignant).then(response=>response.status);
    }
    
    
    delete(idEnseignant){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${idEnseignant}`).then(response=>response.status);
    }
    

    getListeEnseignantIesrInvitation(idIesr){
        const url = this.addresseIP.getAdresseVolumeHoraire()+"/service/api/campusfaso-volume-horaire/campus-faso/enseignants-hors-iesr?idIesr="+idIesr
        return axios.get(url).then(response=>response.data);
    }

    getListeEnseignantDepartementInvitation(idDepartement){
        const url = this.addresseIP.getAdresseVolumeHoraire()+"/service/api/campusfaso-volume-horaire/campus-faso/enseignants-hors-iesr?idDepartement="+idDepartement
        return axios.get(url).then(response=>response.data);
    }


    getListeEnseignantIesr(idIesr){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/enseignants-ies?idIes=${idIesr}`).then(res => res.data)
    }

}



