import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class MentionService {
     constructor(){
         this.adresse = new AdresseIP()
     }


    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mentions"
        return axios.get(url).then(res => res.data) 
    }
    

    
    create(newMention) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mentions"
        return axios.post(url,newMention)
                    .then(res => res.data);
    }

    
    update(idMention ,newMention) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mentions/"
        return axios.put(url+idMention, newMention)
                    .then(res => res.data);
    }

    
    delete(idDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mentions/"
        return axios.patch(url+idDomaine)
                    .then(res => res.data)
    }

    getAllSansObjet() {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/mention-sans-objet"
        return axios.get(url).then(res => res.data) 
    }
    
}