import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { ExamenService } from '../../service/ExamenService'
import { NotationService } from '../../service/NotationService'

export class Notation extends Component {

    constructor(props){

        super(props);
        this.notify = new Notify()
        this.spinner = new Spinner()

        this.ModelExamen = new ExamenService()
        this.ModelNotation = new NotationService()

        this.state = {
            curentExamen: {},
            notationList: [],
            loading : false,
            globalFilter: null,
            viewCreateNotationDialog: false,
            allowSave: false,
            allowValide: false
        }

        //On recupère l'id de l'examen pour la recuperation de tout le necessaire
        const idExamen =  props.match.params.idExamen
        this.ModelNotation.getListNotation(parseInt(idExamen)).then(dataNotation => {

            let i = 0
            dataNotation.map(notation => {
                //Ajout d'un index pour la modification de la note
                notation['index'] = i
                i++
            })

            let curentExamen = dataNotation[0].examen
            this.setState(
                {
                    notationList: dataNotation,
                    curentExamen: curentExamen
                }
            )

            let firstNotation = dataNotation[1]

            //Si la première note est saisie, cela signifie que la notation a été faite, donc on peut auto la validation
            if(!isNaN(firstNotation.note) && firstNotation.fgValide){
                this.setState({
                    allowValide: true
                })
            }

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })

    }

    componentDidMount(){
        //Component didMount
    }

    
    //Gestion de l'enregistrement de la notation
    saveNotation = (validation) => {

        //On parcours toutes les notes pour voir si y'a des notes qui n'ont pas été omis
        let curentListeNote = this.state.notationList
        let existeAbsent = false
        let curentExamen = this.state.curentExamen

        curentListeNote.map(note => {
            if(validation){
                note.fgValide = true
            }

            if(note.absentEvaluation){
                existeAbsent = true
                note.note = 0
            }
        })

        let valider = true
        if(existeAbsent){
            if(!window.confirm("Il y'a des notes non saisies, si vous validez ainsi, des étudiants seront considérés comme absent à l'évaluation.\n\n\t\t\t\t\t\t\t\t Voulez vous valider?")){
                valider = false
            }
        }

        if(valider){

            this.ModelNotation.create(curentListeNote).then(() => {
                //On peut maintenant autoriser la validation
                this.setState(
                    {
                        allowValide: true
                    }
                )
                this.notify.success()
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })

        }

    }


    onNoteChange = (value, count) => {

        let curentNotations = this.state.notationList

        if((!isNaN(value) && parseFloat(value)>=0 && parseFloat(value)<=20)){
            curentNotations[count].note = parseFloat(value)
        }else if(value === ""){
            curentNotations[count].note = 0
        }
        curentNotations[count].absentEvaluation = false

        this.setState(
            {
                notationList: curentNotations,
            }
        )
    }

    onObservationChange = (value, count) => {

        let curentNotations = this.state.notationList
        curentNotations[count].observationNotation = value

        this.setState(
            {
                notationList: curentNotations
            }
        )
    }
    

    inputNoteEditor = (props) => {
        return <input type='number' value={props.rowData.note} onChange={(e)  => this.onNoteChange(e.target.value, props.rowData.index)} placeholder="Note sur 20" className='p-inputtext p-component' step='0.01' max='20' min='0'/>;
    }

    editNote = (props) => {
        return this.inputNoteEditor(props);
    }

    inputObservationEditor = (props) => {
        return <InputText value={props.rowData.observationNotation} onChange={(e)  => this.onObservationChange(e.target.value, props.rowData.index)} placeholder="Observation" className='p-inputtext p-component'/>;
    }

    editObservation = (props) => {

        return this.inputObservationEditor(props);
    }
   


    render() {

        if(this.state.redirect) {
            window.location.reload();
        }

        let allowValide = this.state.allowValide? ( 
            <div className="p-col-2" style={{textAlign: 'right'}} >
                <Button label="Valider" icon="pi pi-check" className="p-button-success p-mr-2 pull-right" onClick={() => this.saveNotation(true)} />
            </div>):(<div className="p-col-2" ></div>)

        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        let titreDatatable = this.state.curentExamen ? `Liste des notations de l'examen ${this.state.curentExamen.libelleExamen}`: ''
   
        const confirmFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideConfirmationDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.confirmDeleteExamen(this.state.deletionId) } />
            </React.Fragment>
        );

        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-2">
                                <Button label="Rafraichir" icon="pi pi-refresh" className="" onClick={()=>window.location.reload()} />
                            </div>
                        </div>
        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <React.Fragment>

                            <div className="p-grid">
                                <div className="p-col-12" >

                                    <DataTable  ref={(el) => this.dt = el} value={this.state.notationList} selectionMode="single" header={titreDatatable} paginator={true} rows={10000}
                                        globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                        <Column field="etudiant.ine" header="INE Etudiant"  sortable={true} filter={true} filterPlaceholder="INE Etudiant"/>
                                        <Column field="etudiant.nom" header="Nom étudiant"  sortable={true} filter={true} filterPlaceholder="Nom étudiant"/>
                                        <Column field="etudiant.prenom" header="Prénom(s) étudiant"  sortable={true} filter={true} filterPlaceholder="Prénom(s) étudiant"/>
                                        <Column field="note" header="Note obtenue"  sortable={true} filter={true} filterPlaceholder="Note obtenue"  editor={(props) => this.editNote(props)}/>
                                        <Column field="observationNotation" header="Observation note"  sortable={true} filter={true} filterPlaceholder="observation note" editor={(props) => this.editObservation(props)}/>
                                    </DataTable>

                                </div>
                                <div className="p-col-8"></div>

                                { allowValide }

                                <div className="p-col-2" style={{textAlign: 'right'}} >
                                    <Button label="Enregistre" icon={this.state.spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-success p-mr-2 pull-right" onClick={() => this.saveNotation(false)} disabled={this.state.spin?true:false} />
                                </div>
                            </div>

                        </React.Fragment>

                        }
                        </div>

                        <Dialog visible={this.state.viewConfirmAbsence} style={{ width: '450px' }} header="Confirmez" modal footer={confirmFooter} onHide={this.hideDeleteExamenDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                                {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                            </div>
                        </Dialog>
                    </div>
            </div> 
        )

    }

}

export default Notation