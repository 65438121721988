import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';
import { DepartementService } from '../../../lmd/service/DepartementService';
//import { set } from 'core-js/core/dict';

export default function ParametrerSignataire(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    const [selectedEntites, setSelectedEntites] = useState(null)
    const [listeSignataire, setListeSignataire] = useState([])

    const [signataireDialog, setSignataireDialog] = useState(false)

    const [signataire, setSignataire] = useState("")

    const [idIesr, setIdIesr] = useState(token.code_iesr)

    const [curentSignataire, setCurentSignataire] = useState({})

    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState(false)
    

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()

    useEffect(() => {

        setLoading(true)
        invitationService.getSignataireIesr(idIesr).then(data => {
            setListeSignataire([data])
            setLoading(false)
            //console.log(data)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setLoading(false)

        })
        }, [] 
    );



    const editerSignataire = (informations) => {
        setSignataire(informations.userSignataire)
        setCurentSignataire(informations)
        setSignataireDialog(true)
    }

    const hideDialog = () => {
        setSignataireDialog(false)
        setSignataire("")
        setSubmitted(false)
        setCurentSignataire({})
    }



    const creerSignataire = () => {
        setSignataireDialog(true)
        setCurentSignataire({})
    }


    const onSignataireChange = (event) => {
        setSignataire(event.target.value)
    }


    const saveSignataire = () => {
        
        setSubmitted(true)
        if(signataire){

            let userSignataire = {
                idIesr: idIesr,
                userSignataire: signataire
            }

            if(curentSignataire.idSignataire){
                console.log("Update")
                userSignataire['idSignataire'] = curentSignataire.idSignataire

                invitationService.updateSignataire(curentSignataire.idSignataire, userSignataire).then(data => {
                    notify.success()
                    setLoading(true)
                    invitationService.getSignataireIesr(idIesr).then(data => {
                        setListeSignataire([data])
                        setLoading(false)
                        console.log(data)
                    }).catch(error => {
                        console.log(error)
                        notify.echec(error.response.data.message)
                        setLoading(false)

                    })
                    hideDialog()

                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                })


            }else{
                console.log("Create")

                invitationService.saveSignataire(userSignataire).then(data => {
                    notify.success()
                    setLoading(true)
                    invitationService.getSignataireIesr(idIesr).then(data => {
                        setListeSignataire([data])
                        setLoading(false)
                        console.log(data)
                    }).catch(error => {
                        console.log(error)
                        notify.echec(error.response.data.message)
                        setLoading(false)
                    })
                    hideDialog()

                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                })



            }

        }
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Editer signataire" style={{marginRight: '.5em'}} onClick={() => editerSignataire(rowData)} />
            </React.Fragment>
        );
    }


    const header = (listeSignataire.length<=0 && !loading)? 
        (
            <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>creerSignataire()} />
            </span>
        </div>
        ):""
        ;
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeSignataire} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    header="Signataire">
                    <Column field="userSignataire" header="Signataire" filterMatchMode="contains" sortable={false} filter={false}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>


 
            <Dialog visible={signataireDialog} style={{ width: '75%' }} header="Enregistrer signataire" modal className="p-fluid" onHide={hideDialog}>
                <hr/>
                <div className="p-grid">
                    <div className="p-col-12" >
                        <div className="p-field">
                            <label htmlFor="signataire">Nom d'utilisateur du signataire</label>
                            <InputText id="signataire" value={signataire}  onChange={(e) => {onSignataireChange(e)}} required autoFocus placeholder="Nom utilisateur acteur" className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !signataire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}            
                        </div>
                    </div>
                </div>

                <hr/>
                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideDialog}  disabled={spin?true:false} />
                    <Button label='Enregistrer' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={saveSignataire}  disabled={spin?true:false} />
                </div>

            </Dialog>

        </div>
    )
}


