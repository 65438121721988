import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../parametres/index.css';


import React, { useState, useEffect} from 'react';
import {FullCalendar} from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import fr from '@fullcalendar/core/locales/fr';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm,Controller } from 'react-hook-form'; 
import { Dropdown } from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import { ToastContainer } from 'react-toastify';

import { InfrastructureServices } from '../../service/InfrastructureServices';
import {TypeActiviteService} from '../../../lmd/service/TypeActiviteService';
import {MultiSelect} from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import {Accordion,AccordionTab } from 'primereact/accordion';
import { NiveauService } from '../../../lmd/service/NiveauService';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { AnneeService } from '../../../lmd/service/AnneeService';
import { CohorteServices } from '../../service/CohorteServices';
import { SemestreService } from '../../../lmd/service/SemestreService';
import AffectationService from '../../service/AffectationService';
import EnseignantService from '../../service/EnseignantService';
import EmploiDuTempsService from '../../service/EmploiDuTempsService';
import ProgrammationService from '../../service/ProgrammationService';
import { DepartementService } from '../../../lmd/service/DepartementService';


export default function Programmation() {


    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);

    const defaultValues = {
        enseignant:'',
        parcours:'',
        departement:'',
        niveau:'',
        anneeAcademique:'',
        semestre:'',
        typeActivite:'',
        dateProgrammation:'',
        heureDebutSeanceProg:'',
        heureFinSeanceProg:'',
        infrastructure:'',
        cohorte:[],
        idSeanceProg:'',
        fixe:false,
        elementConstitutif:'',
        dateDebutSemaine:'',
        dateFinSemaine:'', 
        title:""
    }

    const defaultMessage={
        messageDialog:false,
        messageInfrastructure:false,
        dataForm:{},
        indexList:'',
        cohorte:{libelleCohorte:'',indexCohorte:'',idCohorte:''},
        infrastructure:{},
        toSave:{}
    }

    const evenementsTemplates = {
        id:"",
        title:"",
        start:"",
        end:"",
        data:{}
    }

    const defaultDialog = {
        add:false,
        modification:false,
        delete:false
    }

    const detailDefaul = {
        detail:false
    }


    const [loading,setLoading] = useState(false);
    const [dialog,setDialog] = useState(defaultDialog);
    const [saveList,setSaveList] = useState([]);
    const [evenements,setEvenements] = useState([evenementsTemplates]);
    const [listParcours,setListParcours] = useState([]);
    const [listAnneeAcademique,setListAnneeAcademique] = useState([]);
    const [listSemestre,setListSemestre] = useState([]);
    const [listNiveau,setListNiveau] = useState([]);
    const [listCohorteNiveau,setListCohorteNiveau] = useState([]);
    const [listElementConstitutif,setListElementConstitutif] = useState([]) // la variables d'état contenant la liste des élements constitutifs
    const [listEnseignant,setListEnseignant] = useState([]) // la variables d'état contenant la liste des enseignants
    const [listTypeActivite,setListTypeActivite] = useState([]) // la variables d'état contenant la listes des type d'activité
    const [spin,setSpin] = useState(false);
    const [cancelAndReset,setCancelAndReset] = useState(false);
    const [listProgrammation,setListProgrammation] = useState([]);
    let [listEnseignantFilter,setListEnseignantFilter] = useState([]);
    const [message,setMessage] = useState(defaultMessage);
    let [listNiveauFilter,setListNiveauFilter] = useState([]);
    const [listInfrastructure,setListInfrastructure] = useState([]);
    const [listInfrastructureByDepartement,setListInfrastructureDepartement] = useState([]);
    const [detailDialog,setDetailDialog] = useState(detailDefaul);
    const [infrastructure,setInfrastructure] = useState([])
    const [programmationDelete,setProgrammationDelete] = useState();
    const [cohortes,setCohortes] = useState([]);
    const [erreurs,setErreurs] = useState(false);
    const [listSemestreSearch,setListSemestreSearch] = useState([]);
    const [next,setNext] = useState({loading:false,move:false});
    const [listUtiliteInfrastructure,setListUtiliteInfrastructure] = useState([]);
    const {control,handleSubmit,reset,setValue,getValues,formState:{errors}} = useForm({defaultValues});
    const {control:searchControl,setValue:searchSetValue} = useForm({defaultValues:{semestre:'',parcours:'',cohorte:''}});
    const {control:affectationControl,reset:affectationReset,setValue:affectationSetValue, getValues:affectationGetValues} = useForm({defaultValues:{semestre:'',cohorte:''}});
    const [selectedInfrastructure, setSelectedInfrastructure] = useState({});
    const [dateErrors,setDateErrors] = useState(false);
    const [checkInfrastructure,setCheckInfrastructure] = useState({check:false,popup:false});
    const [listProgrammationByParcours,setListProgrammationByParcours] = useState([]);
    const [listCohorteSearch,setListCohorteSearch] = useState([]);
    const [programmationFilter,setProgrammationFilter] = useState([]);
    const [listDepartementByIesr,setListDepartementByIesr] = useState([]);
    const [iesr,setIesr] = useState({});
    const [libelleDepartementSelect,setLibelleDepartementSelect] = useState("");
    const [infrastructureReserver,setInfrastructureReserver] = useState([]);
    const [refresh,setRefresh] = useState(false);
    const [limitHeure,setLimiteHeure] = useState({element:{},popup:false,sup:0});
    const [listAffectation, setListAffectation] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [selectionAffectation, setSelectionAffectation] = useState({});
    const [listCohortes, setListCohortes] = useState([]);
    const [spinInfras, setSpinInfras] = useState(false);

    
    const addLocale ={
        firstDayOfWeek: 1,
        dayNames: ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
        dayNamesShort: ['dim','lun','mar','mer','jeu','ven','sam'],
        dayNamesMin: ['D','L','M','Me','J','V','S'],
        monthNames: ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'],
        monthNamesShort: "",
        today: "aujourd'hui",
        clear: "effacer",
    }

    
    const notify = new Notify();
    const spinner = new Spinner();
    const programmationService = new ProgrammationService();
    const niveauService = new NiveauService();
    const anneeService = new AnneeService();
    const cohorteServices = new CohorteServices();
    const semestreService = new SemestreService();
    const affectationService = new AffectationService();
    const infrastructureService = new InfrastructureServices();
    const enseignantService = new EnseignantService();
    const typeActiviteService = new TypeActiviteService();
    const departementService = new DepartementService();
    const employeDuTempsService = new EmploiDuTempsService();
   

    // variables declaration En DialogdBloc
      
    let code_departement = token.code_departement || 3;


    useEffect(() => {
        
        programmationService.getProgrammationDepartement(code_departement)
        .then(response=>{
            setLoading(true);
            if(response){
                setListProgrammation(response);
                programmationGet(response);
            }
            
        })
        .catch(errors=>{
            notify.echec(errors.response.data.message);
            console.log(errors)
        })
        .finally(()=>{
            setLoading(false);
        }); 

        cohorteServices.getListeParcoursDepartement(code_departement).then(response=>{setListParcours(response)});
        anneeService.getAll().then(response=>{setListAnneeAcademique (response)});
        niveauService.getAll().then(response=>{setListNiveau(response)});
       
        enseignantService.getAll().then(response=>setListEnseignant(response));
        typeActiviteService.getAll().then(response=>setListTypeActivite(response));
        infrastructureService.getListeUtiliteInfrastructure().then(response=>{setListUtiliteInfrastructure(response)});
        programmationService.getIersByIdDepartement(code_departement).then(response=>{setIesr(response)}).catch(errors=>console.log(errors))
    }, []);

    


    const messageHandler = ()=>{
        let listCohorte = saveList[message.indexList].cohorte;
        saveList[message.indexList].cohorte = listCohorte.filter((cohr=>cohr.idCohorte !== message.idCohorte));
        setSaveList([...saveList,message.dataForm]);
        reset();
        setMessage({...message,messageDialog:false});
    } 
    const messageInfrastructureHandler = ()=>{
        saveList.splice(message.indexList,1);
        setSaveList([...saveList,message.dataForm]);
        reset();
        setMessage({...message,messageInfrastructure:false});
    } 

    const onHide = ()=>{
       
        if(dialog.add){
            setDialog(defaultDialog);
            setListElementConstitutif([]);
            setListEnseignantFilter([]);
            setListNiveauFilter([]);
            setListSemestre([]);
            setSaveList([]);
            setNext({...next,move:false});
            reset();
        } else if(dialog.modification){
            setDialog(defaultDialog);
            setListElementConstitutif([]);
            setListEnseignantFilter([]);
            setListNiveauFilter([]);
            setListSemestre([]);
            // modifyReset();
        }
    }

    const programmationGet=(response=[])=>{
        let events = response.map(programme=>{
            let heureDebut = programme.heureDebutSeanceProg.split(":");
            let heureDebutSeanceProg=new Date(programme.dateProgrammation);
            heureDebutSeanceProg.setHours(+heureDebut[0],+heureDebut[1],heureDebut[2]);

            let heureFin = programme.heureFinSeanceProg.split(":");
            let heureFinSeanceProg=new Date(programme.dateProgrammation);
            heureFinSeanceProg.setHours(+heureFin[0],+heureFin[1],heureFin[2]);
            let libelleCohorte = "";
            programme.cohorte.forEach(cohort=>{
                libelleCohorte +=cohort.libelleCohorte.substring(0,9)+",";;
            })

            return {
                "title":`${programme.libelleElementConstitutif}(${programme.libelleTypeActivite}) ${libelleCohorte}`,
                "id":programme.idSeanceProg,
                "start":heureDebutSeanceProg,
                "end":heureFinSeanceProg,
                "data":programme
            }
        });
        setEvenements(events)
    }

    const detailFooter=(
        <React.Fragment>
             <div className="p-grid w-full">
                <Button type="button" label="Supprimer" icon="pi pi-trash" disabled={spin}  className="p-button-warning p-mr-2" 
                    style={{marginRight:"15px",color:"white"}}
                    onClick={()=>handleProgrammationDeletion(getValues("idSeanceProg"))}
                />
               
                <Button type="button" label="Quitter" disabled={spin} className="p-button-danger" icon="pi pi-times" 
                    onClick={()=>setDetailDialog({...detailDialog,detail:false})}
                />
            </div>
        </React.Fragment>
    )

    const handleProgrammationDeletion = (dataToDelete=null)=>{

        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
                setSaveList([]); // reinitialisation de la liste des grades 
                setCancelAndReset(false);
                setDialog(false);
                setSpin(false);
                reset();
                setSelectedInfrastructure([]);
                setCheckInfrastructure({check:false,popup:false})
                setNext({loading:false,move:false})
            }
            ,2000)
        }else if(dialog.delete){
            setSpin(true);
            programmationService.delete(programmationDelete)
                .then(()=>{
                    programmationService.getProgrammationDepartement(code_departement).then(response=>{setListProgrammation(response);programmationGet(response)});
                    setDetailDialog({...detailDialog,detail:false});
                    notify.success();
                    setDialog({...dialog,delete:false});
                })
                .catch((errors)=>{
                    notify.echec(errors.response.data.message);
                    console.log(errors);
                })
                .finally(()=>{
                    setSpin(false);
                })
        }else{
            setProgrammationDelete(dataToDelete);
            setDialog({delete:true});
        }
    }

    const cancelAndResetFooter =(
        <React.Fragment>
            <Button label="NON" type="button" disabled={spin} icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
            <Button label="OUI" type="button" disabled={spin} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleProgrammationDeletion} />
        </React.Fragment>
    )

    const handleParcoursSearch = (parcours)=>{
        setLoading(true);
        searchSetValue("niveau","");
        setListSemestreSearch([]);
        setListCohorteSearch([]);
        semestreService.get(parcours.idParcours).then(response=>{
            setListSemestreSearch(response);
        })
        programmationService.getProgrammationByParcours(code_departement,parcours.idParcours)
        .then(response=>{
            setListProgrammationByParcours(response)
            programmationGet(response);
        })
        .finally(()=>setLoading(false));
    }


    const handleSemestreSearch=semestre=>{
        let result = listProgrammationByParcours.filter(programmation=>programmation.idSemestre === semestre.idSemestre);
        setProgrammationFilter(result);
        programmationGet(result);
        let newListCohorte = [];
        let listCohorte =[];
        result.forEach(prog=>{
            prog.cohorte.forEach(cohor=>{
                listCohorte.push(cohor);
            })
        })
        let cohortesID=new Set();
        for(let element of listCohorte){
            cohortesID.add(element.idCohorte);
        }
        for(let index of cohortesID ){
            newListCohorte.push(listCohorte.find(cohorte=>cohorte.idCohorte === index));
        }
        setListCohorteSearch([...newListCohorte]);
        
    }


    const handleCohorteSearch = cohorte=>{
        let result =[] ;
        programmationFilter.forEach(programmation=>{
            programmation.cohorte.filter(programmation=>programmation.idCohorte === cohorte.idCohorte)
            for(let element of programmation.cohorte){
                if(element.idCohorte === cohorte.idCohorte){
                    result.push(programmation);
                }
            }
        });
        programmationGet(result);
    }



    const options = {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialDate:new Date(),
        initialView:'timeGridWeek',
        locales:[fr],
        headerToolbar:{ 
            left: 'prev,next', 
            center:'title', 
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        slotMinTime:"06:00:00",
        slotMaxTime:"23:00:00",
        eventContent: function(arg) {
            let {data} = arg.event.extendedProps;
            let division = document.createElement('div')
            let cohorteJsx = "";
             data.cohorte.forEach(cor=>{
                cohorteJsx +=`<span>${cor.libelleCohorte.substring(0,9)}</span>,`
             })
            const jsx = `
            <div>
                <span>${arg.timeText}</span><br />
                <span>${data.libelleElementConstitutif}</span>&nbsp;&nbsp; / <span>${data.libelleTypeActivite}</span><br />
                <p>${cohorteJsx} </p>
            </div>`
            division.innerHTML = jsx;
            let arrayOfDomNodes = [ division ]
            return { domNodes: arrayOfDomNodes }
          },    
        editable: true,
        dateClick:(e)=>{
        let today = new Date();
        today.setHours(6,0,0);
        const date = new Date(e.date);
        if(date.valueOf() >= today.valueOf()){
            cohorteServices.getListeCohorteDepartement(code_departement)
            .then(response=>{
                setListCohortes(response);
                setDialog({...dialog,add:true});
            })
            .catch(errors=>console.log(errors));  

            let regexp = /\d+/g;
            let date_array = new Date(e.view.currentEnd).toLocaleDateString().match(regexp);
            date_array[0] = `${+date_array[0]-1}`;
            let currentDate = new Date(e.date).toLocaleDateString().match(regexp).reverse().join("-");
            let currentEnd = date_array.reverse().join("-");
            let currentStart = new Date(e.view.currentStart).toLocaleDateString().match(regexp).reverse().join("-");
            let title = e.view.title;
            setValue("dateDebutSemaine",currentStart);
            setValue("dateFinSemaine",currentEnd);
            setValue("dateProgrammation",currentDate);
        }else{
            notify.info("La date choisie est invalide , Réessayer !");
        }
        },
        eventClick:e=>{
            const content = e.event.extendedProps.data;
            setValue("anneeAcademique",`${content.anneeDebut}-${content.anneeFin}`);
            let {cohorte} = content;
            cohorte.forEach(element=>{
                element.libelleNiveau = content.libelleNiveau;
                element.libelleAnnee = `${content.anneeDebut}-${content.anneeFin}`;
                element.libelleParcours = content.libelleParcours;
                element.libelleTypeActivite = content.libelleTypeActivite;
            });
            setValue("heureDebutSeanceProg",content.heureDebutSeanceProg);
            setValue("heureFinSeanceProg",content.heureFinSeanceProg);
            setCohortes(content.cohorte)
            setValue("dateProgrammation",new Date(content.dateProgrammation));
            setInfrastructure([content.infrastructure]);
            setValue("niveau",content.libelleNiveau);
            setValue("typeActivite",content.libelleTypeActivite);
            setValue("elementConstitutif",content.libelleElementConstitutif);
            setValue("semestre",content.libelleSemestre);
            setValue("parcours",content.libelleParcours);
            setValue("enseignant",`${content.matriculeEnseignant} ${content.nomEnseignant} ${content.prenomEnseignant}`);
            setValue("idSeanceProg",content.idSeanceProg);
            setDetailDialog({...detailDialog,detail:true})
        }
    };

    const itemEnseignantTemplate = rowData=>{
        if(listEnseignant.length!==0){
            return listEnseignant.map(enseignant=>{
                if(enseignant.idEnseignant === rowData.idEnseignant){
                    return (
                        <div key={enseignant.matriculeEnseignant}>
                            <span>{enseignant.matriculeEnseignant} </span>
                            <span>{enseignant.nomEnseignant} </span>
                            <span>{enseignant.prenomEnseignant} </span>
                        </div>
                    );
                }
                return null
            })
        }
    }

    const cohorteBody = rowData=>{
        return(
            <span title={rowData.libelleCohorte}>{rowData.libelleCohorte.substring(0,9)} ...</span>
        )
    }

    const handleCohorteSelect =(cohorte)=>{
        employeDuTempsService.getSemestre(cohorte.idNiveau)
        .then(response=>{
            setListSemestre(response.semestreDtoList);
        })
        .catch(errors=>{
            console.log(errors);
        });
        let semestre = affectationGetValues("semestre");
        if(semestre){
            affectationService.getAffectationCohorteSemestre(code_departement, semestre.idSemestre, cohorte.idCohorte)
            .then(response=>{
                setListAffectation(response);
            })
            .catch(errors=>console.log(errors));
        }
    }

    const handleSemestreSelect = (semestre)=>{
        let cohorte = affectationGetValues("cohorte");
        affectationService.getAffectationCohorteSemestre(code_departement, semestre.idSemestre, cohorte.idCohorte)
        .then(response=>{
            setListAffectation(response);
        })
        .catch(errors=>console.log(errors));
    }

    const header = (
        <div className="table-header p-col-12">
            <form className="p-fluid" style={{margin:"0px auto", width:"40%"}}>
                <div className="p-grid" style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>                                            
                    <div className="p-field w-50">
                        <label htmlFor="cohorte" >Cohorte/Classe</label>
                        <Controller 
                            name="cohorte" control={affectationControl}
                            render={({field}) =>{
                                return  (
                                    <Dropdown
                                        options={listCohortes} optionLabel="libelleCohorte"
                                        name={field.name} id={field.name} value={field.value}
                                        {...field}
                                        onChange={(e) => {field.onChange(e.value);handleCohorteSelect(e.value)}}
                                    />
                                )
                        }} />
                    </div>
                    <div className="p-field" style={{width:"45%"}}>
                        <label htmlFor="semestre">Semestre</label>
                        <Controller 
                            name="semestre" control={affectationControl}
                            render={({field}) => (
                                <Dropdown
                                    options={listSemestre} optionLabel="libelleSemestre"
                                    name={field.name} id={field.name} value={field.value}
                                    {...field}
                                    onChange={(e) => {field.onChange(e.value); handleSemestreSelect(e.value)}}
                                />
                            )} />                            
                    </div> 
                </div>
                
            </form>
        </div>
    );

    const messageFooter = (
        <React.Fragment>
            <Button label="Anuller" icon="pi pi-times" className="p-button-text" onClick={()=>setMessage({...message,messageDialog:false})} />
            <Button label="Continuer" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={messageHandler} />
        </React.Fragment>
    )


    const messageInfrastructureFooter = (
        <React.Fragment>
            <Button label="Anuller" icon="pi pi-times" className="p-button-text" onClick={()=>setMessage({...message,messageInfrastructure:false})} />
            <Button label="Continuer" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={messageInfrastructureHandler} />
        </React.Fragment>
    )


    const deleteProgrammationFooter =(

        <React.Fragment>
            <Button label="NON" disabled={spin?true:false} icon="pi pi-times" className="p-button-success" onClick={onHide} />
            <Button label="OUI" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleProgrammationDeletion} />
        </React.Fragment>

    )

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">*{errors[name].message}</small>
    };
   

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]);
        
    }

    
    const handleInfrastructure = ()=>{
        setSpinInfras(true);
        let dateProgrammation=getValues("dateProgrammation");
        let heureDebut = getValues("heureDebutSeanceProg");
        let heureFin = getValues("heureFinSeanceProg");
        infrastructureService.getInfrastructureLibre(code_departement,dateProgrammation,heureDebut,heureFin)
        .then(response=>{
            setListInfrastructure(response);
        })
        .catch(error=>console.log(error))
        .finally(()=>setSpinInfras(false));
    }
   

    const handleSelectionInfrastructure = infrastructure=>{
        if(checkInfrastructure.popup){
            setListInfrastructure(infrastructure);
        }
        if(infrastructure.length===0){
            setSelectedInfrastructure({});
        }else{
            setSelectedInfrastructure(infrastructure);
        }
        setValue("infrastructure",infrastructure);
    }


    const handleAjouter = ()=>{
        let dateProgrammation = getValues("dateProgrammation");
        let heureDebutSeanceProg = getValues("heureDebutSeanceProg");
        let heureFinSeanceProg = getValues("heureFinSeanceProg");
        setSaveList([...saveList, {heureFinSeanceProg,heureDebutSeanceProg,dateProgrammation,selectionAffectation, selectedInfrastructure}]);
            
    }

    const handleSave = ()=>{

        let programmationSeanceDtos = saveList.map(data=>{
            return {
                    heureDebutSeanceProg : data.heureDebutSeanceProg,
                    heureFinSeanceProg : data.heureFinSeanceProg,
                    dateProgrammation : data.dateProgrammation,
                    idAnneeAcademique : data.selectionAffectation.idAnneeAcademique,
                    idElementConstitutif : data.selectionAffectation.idElementConstitutif,
                    idEnseignant:data.selectionAffectation.idEnseignant,
                    idParcours : data.selectionAffectation.idParcours,
                    idSemestre : data.selectionAffectation.idSemestre,
                    idTypeActivite : data.selectionAffectation.idTypeActivite,
                    idDepartementEtab : code_departement,
                    idInfrastructure : data.selectedInfrastructure.idInfrastructure
                };
        })


        let semaineProgrammationDto = {
            dateDebutSemaine:getValues("dateDebutSemaine"),
            dateFinSemaine:getValues("dateFinSemaine")
        }
        setSpin(true);
        programmationService.create(code_departement,{programmationSeanceDtos,semaineProgrammationDto})
        .then(()=>{
            setLoading(true);
            programmationService.getProgrammationDepartement(code_departement).then(response=>{setListProgrammation(response);programmationGet(response)});
            reset();
            affectationReset();
            setSelectedInfrastructure({});
            setSelectionAffectation({});
            setListInfrastructure([]);
            setDialog({...dialog,add:false});
            setSaveList([]);
            setListAffectation([]);
            notify.success();
        })
        .catch((errors)=>{
            notify.echec(errors.response.data.message);
            console.log(errors);
        })
        .finally(()=>{
            setSpin(false);
            setLoading(false);
        });
    }

    const handleReservation = ()=>{
        
        departementService.getListeDepartement(iesr.idIes).then(response=>{
            setListDepartementByIesr(response.filter(departement=>departement.idDepartementEtab !== code_departement));
            setCheckInfrastructure({...checkInfrastructure,popup:true})
            setInfrastructureReserver([])
            setSelectedInfrastructure([]);
        })
        .catch(errors=>console.log(errors));
    }

    const handleCohorteChange=(cohorte)=>{
       setValue("heureDebutSeanceProg","");
       setValue("heureFinSeanceProg","");
       setSelectedInfrastructure([]);
       setListInfrastructure([])
    }


    const handleSelectionReservation = infrastructure=>{
        
        setSelectedInfrastructure([infrastructure])
        setInfrastructureReserver([infrastructure])
        setListInfrastructure([...listInfrastructure,infrastructure])
        
        setValue("infrastructure",infrastructure);
        setCheckInfrastructure({...checkInfrastructure,popup:false})
    }


    const handleRowSelected = rowData=>{
        
        setSelectionAffectation(rowData.data);
      
    }
    const handleRowUnselected = rowData=>{
        
        // let newData = selectionAffectation.filter(affect=>affect.idElementConstitutifHoraire !== rowData.data.idElementConstitutifHoraire);
        setSelectionAffectation({});
    }

    const handleDateDebut=()=>{
        setErreurs(false);
        setSelectedInfrastructure([])
        let dateProgrammation = getValues("dateProgrammation");
        let heureDebutSeanceProg = `${dateProgrammation} ${getValues("heureDebutSeanceProg")}`
        let heureFinSeanceProg = `${dateProgrammation} ${getValues("heureFinSeanceProg")}`
        
        if(heureDebutSeanceProg > heureFinSeanceProg && `${getValues("heureFinSeanceProg")}`){
            setErreurs(true);
        }

    }   


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                
                   <div>
                        <div style={{backgroundColor:"Background",padding:10}} className="table-header p-grid justify-content-center mb-2 mt-3">
                            <span className="p-input-icon-left">
                                <div className='p-grid justify-content-center'>
                                    <div className="p-field p-col">
                                        <Controller 
                                            name='parcours' control={searchControl}
                                            render={({field})=>(
                                                <Dropdown 
                                                    name={field.name} id={field.name} value={field.value}
                                                    options={listParcours} optionLabel='libelleParcours'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner un parcours"
                                                    {...field}
                                                    onChange={(e)=>{field.onChange(e.value);handleParcoursSearch(e.value)}}
                                                />
                                            )}

                                        />
                                    </div>
                                    <div className="p-field p-col">
                                        <Controller 
                                            name='semestre' control={searchControl}
                                            render={({field})=>(
                                                <Dropdown 
                                                    name={field.name} id={field.name} value={field.value}
                                                    options={listSemestreSearch} optionLabel='libelleSemestre'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} placeholder="Selectionner un semestre"
                                                    {...field}
                                                    onChange={(e)=>{field.onChange(e.value);handleSemestreSearch(e.value)}}
                                                />
                                            )}

                                        />
                                    </div>
                                    <div className="p-field p-col">
                                        <Controller 
                                            name='cohorte' control={searchControl}
                                            render={({field})=>(
                                                <Dropdown 
                                                    name={field.name} id={field.name} value={field.value}
                                                    options={listCohorteSearch} optionLabel='libelleCohorte'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner une cohorte"
                                                    {...field}
                                                    onChange={e=>{field.onChange(e.value);handleCohorteSearch(e.value)}}
                                                />
                                            )}

                                        />
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div>
                            <FullCalendar 
                                options={options} events={evenements} 
                                editable selectable selectMirror dayMaxEvents 
                            />
                        </div>
                   </div>
                }
            </div>
            
            
            {/* Add programmation Bloc */}
            <Dialog className="card" visible={dialog.add} style={{width:"100%"}} onHide={onHide}>
                <div id='data-table'>
                    {/* {header} */}
                    <DataTable 
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        rows={5} 
                        onRowSelect={handleRowSelected}
                        onRowUnselect={handleRowUnselected}
                        selection={[selectionAffectation]}
                        selectionMode="single" filterMatchMode="contains"
                        paginator={true} responsive={true}
                        value={listAffectation}
                        globalFilter={globalFilter}
                        header="Liste des ECUE Affectées Par Département"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '5em' }} />
                        <Column field="libelleSemestre" header="Semestre" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleParcours" header="Parcours" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column body={itemEnseignantTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column body={cohorteBody} header="Cohorte" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleElementConstitutif" header="EC" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleTypeActivite" header="Type Activité" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="nbHeure" header="Nombre d'heures" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                    </DataTable>
                </div>
                <div className="p-grid" id='programmation' >
                    <div className='card' style={{width:"20vw", marginRight:10}}>
                        <h2 style={{textAlign:"center"}}>Horaire de programmation</h2>
                        <hr />
                        <form className="p-fluid">
                            <div className="p-field" >
                                <div>
                                    <label htmlFor="dateProgrammation" >Date de debut  {getFormErrorMessage('dateProgrammation')}</label>
                                    <Controller 
                                        name="dateProgrammation" control={control}
                                        rules={{ required: ' champ obligatoire.'}}
                                        render={({field}) =>{
                                            return  (
                                                <InputText type="date" id={field.name} name={field.name} value={field.value} {...field} 
                                                // onChange={(e)=>{field.onChange(e.target.value);handleDateProgrammation(e.target.value)}} 

                                                />
                                            )
                                    }} />
                                </div>
                                {dateErrors && <p style={{backgroundColor:"red",color:"white", fontSize:18,marginTop:8,textAlign:"center"}}>Hors de la semaine</p>}
                                    
                            </div>
                            <div className="p-grid ">                                            
                                <div className="p-field p-col-6" >
                                    <label htmlFor="heureDebutSeanceProg" >heure debut  {getFormErrorMessage('heureDebutSeanceProg')}</label>
                                    <Controller 
                                        name="heureDebutSeanceProg" control={control}
                                        rules={{ required: ' champ obligatoire.'}}
                                        render={({field}) =>{
                                            return  (
                                                <InputText type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value);handleDateDebut()}} />
                                            )
                                    }} />
                                </div>
                                <div className="p-col-6 p-field" >
                                    <label htmlFor="heureFinSeanceProg">Heure fin {getFormErrorMessage('heureFinSeanceProg')}</label>
                                    <Controller 
                                        name="heureFinSeanceProg" control={control}
                                        rules={{ required: ' champ obligatoire.'}}
                                        render={({field}) => (
                                            <InputText type="time" id={field.id} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value);handleDateDebut()}}/>
                                        )} />
                                        {erreurs && <span style={{color:"red"}}>L'heure saisie est incorrectre !</span>}
                                    
                                </div> 
                            </div>
                            <div style={{width:200, marginTop:15}}>
                                <Button type="button" label="Choisir Infrastructure" 
                                    disabled={spin?true:false} className="p-button-info" 
                                    iconPos='right' icon={spinInfras?"pi pi-spin pi-spinner":"pi pi-arrow-right"} 
                                    onClick={handleInfrastructure} 
                                />
                            </div>
                        </form>
                    </div>
                    
                    <div className='card ' style={{width:"30vw", marginRight:10,display:"flex", flexDirection:"column", justifyContent:"space-between"}} >
                        
                        <div>
                            <h2 style={{textAlign:"center"}}>Infrastructure</h2>
                            <hr />
                            <DataTable 
                                    value={listInfrastructure} 
                                    selection={[selectedInfrastructure]} onSelectionChange={(e)=>handleSelectionInfrastructure(e.value)}
                                    selectionMode="single"
                                    paginator rows={5} 
                                    // header={`Liste des infrastructures ${listInfrastructure[0].libelleTypeStructureRattachement}`}
                            >
                                <Column field="libelleInfrastructure" header="Libellé" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column field="nombrePlace" header="Capacité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                <Column selectionMode="simple" headerStyle={{ width: '5em' }} />
                            </DataTable>
                            <div style={{width:"100px",display:"flex",marginTop:15}}>
                                <Button type="button"
                                    // disabled={selectedInfrastructure.length===0?true:false} 
                                    className="p-button-success" label="Ajouter" 
                                    icon={refresh?"pi pi-spinner pi-spin":"pi pi-plus"} 
                                    onClick={handleAjouter}
                                    style={{marginRight:"15px"}} />
                                <Button type='button' onClick={handleReservation} className="p-button-info" label='Réservation' />
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{width:"40vw"}} >
                        <h2 style={{textAlign:"center"}}>Nouvellement ajouté </h2>
                        <hr />
                        <div className=" p-fieldset">
                            <table className="table" style={{minHeight:"250px"}}>
                                <thead>
                                    <tr className="p-grid">
                                        <th className="p-col">EC</th>
                                        <th className="p-col">Type Actvité</th>
                                        <th className="p-col">Infrastructure</th>
                                        <th className="p-col" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        saveList.map((spec,index) =>
                                            <tr className="p-grid" key={index+1}>
                                                <td className="p-col">{spec.selectionAffectation.libelleElementConstitutif}</td>
                                                <td className="p-col">{spec.selectionAffectation.libelleTypeActivite}</td>
                                                <td className="p-col">{spec.selectedInfrastructure.libelleInfrastructure}</td>
                                                <td><Button icon="pi pi-trash" className=" p-button-danger p-col-4" 
                                                onClick={() => {removeItem(index)}} 
                                                /></td>
                                            </tr>
                                        )
                                    }
                                </tbody>      
                            </table>
                            <Button label='Enregistrer' className='pi p-button-success' icon={spin?"pi pi-spin pi-spinner":'pi pi-save'} onClick={handleSave} />
                        </div>
                    </div>
                </div>
            </Dialog>

            {/* Add programmation EndBloc */}
          

            {/* detail programmation Bloc */}
                <Dialog className="card" showHeader={false} visible={detailDialog.detail} style={{width:"100vw",height:"100VH",maxHeight:"100vh"}} footer={detailFooter} onHide={()=>setDetailDialog({...detailDialog,detail:false})}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center mr-2">
                                <h5 className="text-header">Détail sur l'activité Académique </h5>
                                <form className="p-fluid">
                                    <div>
                                        <div>
                                            <div className="p-field" >
                                                <label htmlFor="anneeAcademique" >Année Académique</label>
                                                <Controller
                                                    name="anneeAcademique" control={control}
                                                    defaultValue={getValues("anneeAcademique")}
                                                    render={({field}) => (
                                                        <InputText
                                                        readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                                />
                                            </div>
                                            
                                            <div className="p-grid">
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="parcours" >Parcours</label>
                                                    <Controller
                                                        name="parcours" control={control}
                                                        defaultValue={getValues("parcours")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} 

                                                />
                                                </div>
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="niveau" >Niveau</label>
                                                    <Controller
                                                        name="niveau" control={control}
                                                        defaultValue={getValues("niveau")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                        )} 

                                                    />
                                                </div>
                                            </div>
                                            <div className="p-field" >
                                                <label htmlFor="semestre" >Semestre</label>
                                                <Controller
                                                    name="semestre" control={control}
                                                    defaultValue={getValues("semestre")}
                                                    render={({field}) => (
                                                        <InputText
                                                        readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                            />
                                            </div>
                                            <div className="p-field" >
                                                <label htmlFor="enseignant" >Enseignant</label>
                                                <Controller
                                                    name="enseignant" control={control}
                                                    defaultValue={getValues("enseignant")}
                                                    render={({field}) => (
                                                    <InputText
                                                        readOnly
                                                        name={field.name} value={field.value} id={field.name}
                                                        {...field}
                                                    />
                                                )} /> 
                                                   
                                                </div>
                                                <div className="p-grid">
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="elementConstitutif" >Elément Constitutif</label>
                                                    <Controller
                                                        name="elementConstitutif" control={control}
                                                        defaultValue={getValues("elementConstitutif")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="typeActivite" >Type Activité</label>
                                                    <Controller
                                                        name="typeActivite" control={control}
                                                        defaultValue={getValues("typeActivite")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <hr/>
                                        <div>
                                            <div className="p-grid">
                                                <div className="p-grid">
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="dateProgrammation" >Date de début</label>
                                                    <Controller
                                                        name="dateProgrammation" control={control}
                                                        defaultValue={getValues("dateProgrammation")}
                                                        render={({field}) => {
                                                            return (
                                                                <Calendar 
                                                                    locale={addLocale} dateFormat="dd/mm/yy" showOnFocus={false} 
                                                                    id={field.name} name={field.name} value={field.value}
                                                                    {...field}
                                                                />
                                                            )
                                                        }} />
                                                </div>
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="heureDebutSeanceProg" >heure début</label>
                                                    <Controller 
                                                        defaultValue={getValues("heureDebutSeanceProg")}
                                                        name="heureDebutSeanceProg" control={control}
                                                        render={({field}) =>{
                                                            return (
                                                                    <InputText readOnly name={field.name} id={field.name} value={field.value} {...field}/>
                                                            )
                                                    }} />
                                                </div>
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="heureFinSeanceProg">Heure fin</label>
                                                    <Controller 
                                                    defaultValue={getValues("heureFinSeanceProg")} name="heureFinSeanceProg" control={control}
                                                    render={({field}) => (
                                                 
                                                        <InputText readOnly name={field.name} id={field.name} value={field.value} {...field}/>)
                                                    } />
                                                </div> 
                                            </div>
                                            </div> 
                                            
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-grid p-dir-col p-justify-between" style={{height:"465px"}}>
                                <div>
                                    <DataTable 
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                        selectionMode="single" 
                                        filterMatchMode="contains"
                                        paginator={false} 
                                        responsive={true}
                                        value={infrastructure}
                                        header="Les informations sur l'infrastructure"
                                    >
                                        <Column field="sigleInfrastructure" header="Sigle" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleInfrastructure" header="libellé" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="localite" header="localité" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleEtatInfrastructure" header="etat" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleTypeInfrastructure" header="type" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleDepartement" header="departement" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="nombrePlace" header="nombre de place" headerStyle={{textTransform:"capitalize"}}/>
                                    </DataTable>
                                </div>
                                <div>
                                    <DataTable 
                                            selectionMode="single" filterMatchMode="contains"
                                             responsive={true}
                                            value={cohortes}
                                            header={`Panel des cohortes de l'année académique `}
                                        >
                                            <Column field="libelleParcours" header="parcours" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="libelleNiveau" header="Niveau" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="libelleCohorte" header="Libelle" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="nbEtudiantCohorte" header="nombre d'étudiants" headerStyle={{textTransform:"capitalize"}}/>
                                            
                                        </DataTable>
                                </div>
                            </div>

                        </div>
                       
                    </div>
                </Dialog>
            {/* detail programmation EndBloc */}



            <Dialog  visible={checkInfrastructure.popup} style={{width:"60vw"}} onHide={()=>setCheckInfrastructure({...checkInfrastructure,popup:false})}>
                <div className='p-grid justify-content-center' >
                    <div className="p-field mb-3 mt-2" >
                        <label htmlFor="departement"></label>
                        
                        <Controller name="libelleStatutEnseignant" control={control} 
                            render={({ field, fieldState }) => (
                                <Dropdown name={field.name} id={field.name} value={field.value}
                                    {...field}
                                    options={listDepartementByIesr} optionLabel="lbDepartementEtab"
                                    // onChange={(e)=>{field.onChange(e.value);handleDepartement(e.value)}}
                                    placeholder="selection un departement"
                                />
                            )} 
                        />
                    </div>
                </div>

                <DataTable 
                    value={listInfrastructureByDepartement} 
                    selection={infrastructureReserver} onSelectionChange={(e)=>handleSelectionReservation(e.value)}
                    selectionMode="single"
                    paginator rows={5} 
                    header={`Liste des infrastructures du departement ${libelleDepartementSelect}`}
                >
                    <Column field="libelleInfrastructure" header="Libellé" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nombrePlace" header="Capacité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column selectionMode="simple" headerStyle={{ width: '5em' }} />
                </DataTable>
                
            </Dialog>

            <Dialog visible={message.messageDialog} style={{ width: '500px' }} header="Confirmez" modal footer={messageFooter} onHide={()=>setMessage({...message,messageDialog:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>La cohorte 
                    {/* <strong>{message.cohorte.libelleCohorte}</strong>  */}
                    a été déjà programmée pour une autre séance d'activité dans cette plage horaire </span>
                    <span>En continuant seule la nouvelle programmation sera prise en compte !</span>
                </div>
            </Dialog>
            <Dialog visible={message.messageInfrastructure} style={{ width: '500px' }} header="Confirmez" modal footer={messageInfrastructureFooter} onHide={()=>setMessage({...message,messageInfrastructure:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>L'infrastructure <strong>{message.infrastructure.libelleInfrastructure}</strong> a été déjà programmée pour une autre séance d'activité dans cette plage horaire </span>
                    <span>En continuant seule la nouvelle programmation sera prise en compte !</span>
                </div>
            </Dialog>

            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelAndResetFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>

            <Dialog visible={limitHeure.popup} style={{ width: '450px' }} header="Confirmez" modal onHide={()=>setLimiteHeure({...limitHeure,popup:false})}>
                <div className="confirmation-content">
                    <span>La programmation de la cohorte {limitHeure.element.libelleCohorte} ne doit pas excéder le nombre d'heure attribuer à l'enseignant durant l'affectation</span>
                    <span> Vous devez reduire les heures de {limitHeure.sup}</span>
                </div>
            </Dialog>

            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteProgrammationFooter} onHide={()=>setDialog({...dialog,delete:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>
          
        </>
    );
}