import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class TypeDemandeService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-demandes?tout=no"
       return axios.get(url).then(res => res.data) 
   }

   get(idCategorieDemande) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-demandes?idCategorieDemande="
    return axios.get(url+idCategorieDemande).then(res => res.data) 
   }
   
   getListeTypeDemandeStructure(idStructure, niveau) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-demandes?idStructure="+idStructure+"&niveau="+niveau
    return axios.get(url).then(res => res.data) 
   }

   create(newTypeDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-demandes"
       return axios.post(url,newTypeDemande)
                   .then(res => res.data);
   }
   
   update(idTypeDemande ,newTypeDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-demandes/"
       return axios.put(url+idTypeDemande, newTypeDemande)
                   .then(res => res.data);
   }

   delete(idTypeDemande) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/type-demandes/"
       return axios.patch(url+idTypeDemande)
                   .then(res => res.data)
   }


}