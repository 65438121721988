import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ParametreService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres"
       return axios.get(url).then(res => res.data) 
   }
   

   create(newParametre) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres"
       return axios.post(url,newParametre)
                   .then(res => res.data);
   }
   
   update(idParametre ,newParametre) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres/"
       return axios.put(url+idParametre, newParametre)
                   .then(res => res.data);
   }

   delete(idParametre) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres/"
       return axios.patch(url+idParametre)
                   .then(res => res.data)
   }


}