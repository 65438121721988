import React, { Component } from 'react'
import { Menubar } from 'primereact/menubar';
import { Route } from 'react-router-dom';
import GestionNote from '../lmd/components/GestionNote';
import ImportNote from '../lmd/components/ImportNote';

    export class MenuEnseignant extends Component {
        constructor(props){
            super(props)
            this.state = {
                token : 1,
                structure : {},
                keycloak : null,
                verife : false,
                boolenAcceuil : false
            }

            //this.structureService = new StructureService();
            
        }
        componentDidMount = () => {
            const tokenDecoded = localStorage.getItem('tokenDecoded')
            const token = JSON.parse(tokenDecoded)
            this.setState({token : token}) 
        }
    
  render() {
    const itemsEnseignant = [
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs'
        },
        {
            label: 'Emploi du temps',
            icon: 'pi pi-fw pi-power-offs',
            command:()=>{window.location.hash = "/volume-horaire/emploi-temps"}
        },
        {
            
            label: 'Gestion des examens',
            icon: 'pi pi-fw pi-files',
            items: [
                {
                    label: 'Saisie de notes',
                    icon: 'pi pi-fw pi-trashs',
                    command:()=>{window.location.hash = "/lmd/saisie-note"}
                },
                {
                    separator: true
                },
                {
                    label: 'Import de notes',
                    icon: 'pi pi-fw pi-internal-link',
                    command:()=>{window.location.hash = "/lmd/import-note"}
                }
            
            ]
        
        }        
        
    ];
    return (
      <div>
          <div className="html">
        <div className="body">
            <div className="grid-container">
                <header className="header">
                    <div className="logo" >
                        <a className="navbar-brand link-menu scroll-to-target current"href="/">
                            <img style={{marginLeft : -700}} className="logo" src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                        </a>
                    </div>
                    <div className="" >
                        <text style={{fontSize : 16, fontWeight : "bold", marginLeft : -600 }}>Plateforme integrée de l'Enseignement Supérieur CAMPUSFASO</text>
                    </div>
                    {this.state.token ?
                    <div className="header-text">
                        <i className="ti-user"></i>
                        Bienvenue <span style={{color : "green"}}>{this.state.token.name }</span>{" ( "+this.state.structure.libelleStructure+" ) " } | 
                        
                            <a href="#" onClick={()=> {localStorage.clear(); {/*this.props.clickService  ?  this.props.keycloak.keycloak.logout() :*/  this.props.keycloak.logout()}}} >
                        
                            <i className="ti-close"></i>
                            Déconnexion
                        </a> |
                        <div href="#" className="btn" onClick={this.loadAcceuilPage}>
                            <i className="ti-home"></i>
                            Accueil
                        </div>
                    </div>
                    :
                    ""
                    }
                </header>
                
                <main className="main">
                    <div className="cards titles" > 
                        <Menubar model={itemsEnseignant} />
                    </div>
                    <div>
                        <Route path="/lmd/saisie-note" component={GestionNote}/>
                        <Route path="/lmd/import-note" component={ImportNote}/>
                    </div>  
                    {/*<div className="title">Mes <span style={{fontWeight : "bold"}}>services</span></div>*/}
                        
                        
                </main>
                <div className="footer">
                        <ul className="liens">
                            <li><a href="">CGU</a></li>
                            <li><a href="">Politique de confidentialité</a></li>
                            <li><a href="">FAQ</a></li>
                        </ul>
                        <p>
                            &copy; 2021 &middot; 
                            MESRSI
                            &middot;Tous droits réservés
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default MenuEnseignant