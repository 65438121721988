import axios from 'axios';
import { AdresseIP } from '../components/AdresseIP';


export class DepartementService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd"
       return axios.get(url).then(res => res.data)
   }

   get(idDepartement) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd?idDepartement="+idDepartement
    return axios.get(url)
                .then(res => res.data)
    }

}