import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class EntiteParametreService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-lmd/campus-faso/parametres-entites"
       return axios.get(url).then(res => res.data)
   }
   

   create(newParametreEntite) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres-entites"
       return axios.post(url,newParametreEntite)
                   .then(res => res.data);
   }
   
   update(listeParametreEntite) {
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres-entites/"
       return axios.put(url, listeParametreEntite)
                   .then(res => res.data);
   }

   delete(listeParametreEntite) {
       let urlmin;
       let url1 = "?ids=";
       let url2 = "&ids=";
       let val;
    
       listeParametreEntite.map((item, index) => {
            val = item.idParametreEntite;
            if(index == 0) urlmin = url1+val;
            else urlmin = urlmin+url2+val;
            
       })
       console.log(urlmin);
       const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres-entites/"+urlmin
       return axios.patch(url)
                   .then(res => res.data)
   }

   getListeParametreEntites(idEntite) {
    const url = this.adresse.getAdresseIPDepotEnLigne() +"/service/api/campusfaso-procedure/campus-faso/parametres-entites?idEntite="
    return axios.get(url+idEntite)
                .then(res => res.data)
   }


}