import { Button } from 'primereact/button'
import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../components/Notify'
import { Spinner } from '../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { JuryService } from '../service/JuryService'
import { TypeMembreJuryService} from '../service/TypeMembreJuryService'
import { SessionExamenService } from '../service/SessionExamenService'
import { EnseignantService } from '../service/EnseignantService'
import { EnseignantJuryService } from '../service/EnseignantJuryService'
import { Dropdown } from 'primereact/dropdown'
import { PickList } from 'primereact/picklist';

export class Jury extends Component {

    constructor() {
        
        super();

        this.state = {
            sessionList : [],
            juryList: [],
            juryDialog: false,
            deleteJuryDialog: false,
            curentJury: {},
            curentSession: {},
            submitted: false,
            loading : false,
            globalFilter: null,
            selectSessionDialog: false,
            curentPresidentJury: {},
            curentSecretaireJury: {},
            typePresident: {},
            typeSecretaire: {},
            typeMembre: {},
            typePresidentList: [],
            typeSecretaireList: [],
            typeMembreList: [],
            enseignantList: [],
            listeRecherche : [],
            membreEligible: [],
            membreChoisi: [],
            isValidable: false,
            deletionId: null, 
            loadingSession: true,
            patience : false,
            idDepartement: null,
            viewSelectMembreJuryDialog: false,
            listeSelectionEnseignantDisponible: [],
            listeSelectionEnseignantMembre: [],
            typeOperation: ""
        }

        this.juryService = new JuryService()
        this.sessionService = new SessionExamenService()
        this.TypeMembreJuryService = new TypeMembreJuryService()
        this.enseignantService = new EnseignantService()
        this.enseignantJuryService = new EnseignantJuryService()

        this.spinner = new Spinner()
        this.notify = new Notify()

    }

    componentDidMount() {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)
       
        this.setState({
            loading: true, 
            token : token,
            idDepartement: token.code_departement
        })


        this.juryService.getAllByDepartement(token.code_departement).then(data => {
            this.setState({juryList: data, loading : false})
            //console.log(data)
        }
           
            
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

        //Prise en compte des sessions du departement
        this.sessionService.getSessionDepartement(token.code_departement).then(data => {
            //On filtre uniquement les sessions qui sont ouverts
            let listeSessionOuvert = data.data.filter(curentSession => {
                return curentSession.etatSession === "OUVERT"
            })
            this.setState({
                sessionList : listeSessionOuvert,
                loadingSession: false
            })
        }).catch(error =>{
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({loading:false})
        })


        /*    
        this.sessionService.getAll().then(data => {
            this.setState(
                {
                    sessionList: data,
                    loadingSession:false
                }
            )
        }).catch(error => {
            console.log(error)
        })
        */

        this.TypeMembreJuryService.getAll().then(data => {

            let typePresident = {}
            let typePresidentList = {}

            let typeSecretaire = {}
            let typeSecretaireList = []

            let typeMembre = {}
            let typeMembreList = []
            
            data.map((typeMembreJury) => {
                if(typeMembreJury.libelleTypeMembre==='PRESIDENT'){
                    typePresident = typeMembreJury
                    typePresidentList = [
                        typeMembreJury
                    ]
                }else if(typeMembreJury.libelleTypeMembre==='SECRETAIRE'){
                    typeSecretaire = typeMembreJury
                    typeSecretaireList = [
                        typeMembreJury
                    ]
                }else if(typeMembreJury.libelleTypeMembre==='MEMBRE'){
                    typeMembre = typeMembreJury
                    typeMembreList = [
                        typeMembreJury
                    ]
                }
            })

            this.setState({
                typePresident,
                typePresidentList,
                typeSecretaire,
                typeSecretaireList,
                typeMembre,
                typeMembreList
            })


        }).catch(error => {
            console.log(error)
        })
    }


    openNew = () => {
        this.setState({
            curentJury: {
                idJury: null,
                libelleJury: null,
                session: null
            },
            submitted: false,
            selectSessionDialog: true
        })
    }


    hideDialog = () => {
        let curentJury = {}
        let curentPresidentJury = {}
        let curentSecretaireJury = {}
        let membreChoisi = []
        this.setState({
            juryDialog: false,
            curentJury,
            curentPresidentJury,
            curentSecretaireJury,
            membreChoisi
        });
    }


    hideDeleteJuryDialog = () => {
        this.setState({ 
            deleteJuryDialog: false,
            deletionId: null
         });
    }


    saveJury = () => {

        let membre = []
        let i = 0

        membre[i] = {
            idEnseignant: this.state.curentPresidentJury.idEnseignant,
            idTypeMembre: this.state.typePresident.idTypeMembre
        }
        i++
        membre[i] = {
            idEnseignant: this.state.curentSecretaireJury.idEnseignant,
            idTypeMembre: this.state.typeSecretaire.idTypeMembre
        }
        i++

        let membresJury = this.state.membreChoisi

        membresJury.map(membreJury => {
            membre[i] = {
                idEnseignant: membreJury.idEnseignant,
                idTypeMembre: this.state.typeMembre.idTypeMembre
            }
            i++
        })


        let juryData = {
            jury: {
                idJury: this.state.curentJury.idJury,
                libelleJury: this.state.curentJury.libelleJury,
                idSession: this.state.curentSession.idSession
            },
            'enseignantJury': membre
        }

        //console.log(juryData)

        
        this.enseignantJuryService.create(juryData).then(data => {

            this.juryService.getAllByDepartement(this.state.idDepartement).then(data => 
                this.setState({
                    juryList: data,
                }))
                .catch(error => {
                    console.log(error)
                })

            this.setState({
                curentJury: {
                    idJury: null,
                    libelleJury: null,
                    session: null
                },
                juryDialog: false
            })
            this.notify.success()

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })

    }


    editJury = (rowData) => {

        let idSession = rowData.idSession
        let idDepartementFiliere = rowData.session.parcoursFiliere.idDepartementFiliere
        let idParcours = rowData.session.parcoursFiliere.idParcours
        let idJury = rowData.idJury
        let membreChoisi = []
        let membreEligible = []
        let president = []
        let secretaire = []

        let idTypeMembre = this.state.typeMembre.idTypeMembre
        let idTypeSecretaire = this.state.typeSecretaire.idTypeMembre
        let idTypePresident = this.state.typePresident.idTypeMembre
        let listIdMembresJury = []

        //this.enseignantService.getListeEnseignant(parseInt(idSession), parseInt(idDepartementFiliere), parseInt(idParcours)).then(dataEnseignant => {


            this.enseignantService.getAll().then(dataEnseignant => {
            this.enseignantJuryService.getMembreJury(parseInt(idJury)).then(dataMembre => {

                president = dataMembre.filter(membre => {
                    return (membre.idTypeMembre===idTypePresident)
                })
                this.setState({
                    curentPresidentJury: president[0].enseignant,
                })
                listIdMembresJury.push(president[0].enseignant.idEnseignant)

                secretaire = dataMembre.filter(membre => {
                    return (membre.idTypeMembre===idTypeSecretaire)
                })
                listIdMembresJury.push(secretaire[0].enseignant.idEnseignant)
                this.setState({
                    curentSecretaireJury: secretaire[0].enseignant,
                })
                
                dataMembre.map(membre => {
                    if(membre.idTypeMembre==idTypeMembre){
                        membreChoisi.push(membre.enseignant)
                        listIdMembresJury.push(membre.enseignant.idEnseignant)
                    }
                })
                this.setState({
                    membreChoisi: membreChoisi
                })
                

                membreEligible = dataEnseignant.filter(enseignant => {
                    return (listIdMembresJury.indexOf(enseignant.idEnseignant)===-1)
                })
                this.setState({
                    membreEligible: membreEligible,
                })

                this.setState({
                    enseignantList: dataEnseignant,
                    membreChoisi: membreChoisi,
                    curentSession: rowData.session,
                    curentJury: rowData,
                    juryDialog: true,
                    isValidable: this.testValide(rowData, president[0].enseignant, secretaire[0].enseignant, membreChoisi)
                })

            }).catch(error => {
                console.log(error)
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)

            this.setState({
                juryDialog: false,
            })
        })

    }

    confirmDeleteJury = (rowData) => {
        this.setState({
            deletionId: rowData.idJury,
            deleteJuryDialog: true
        });
    }

    deleteJury = (idJury) => {

        this.juryService.delete(parseInt(idJury)).then(() => {
            this.setState({
                deleteJuryDialog: false,
                deletionId: null
            });

            this.juryService.getAllByDepartement(this.state.idDepartement).then(data => 
                this.setState({juryList: data, loading : false})
                )
                .catch(error => {
                    console.log(error)
                })

                this.notify.success();

        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
        })

    }

    hideSelectSessionDialog = () => {
        this.setState({
            selectSessionDialog: false
        })
    }

    selectSession = (rowData) => {

        let idSession = rowData.idSession
        let idDepartementFiliere = rowData.parcoursFiliere.idDepartementFiliere
        let idParcours = rowData.parcoursFiliere.parcours.idParcours

        //console.log(idDepartementFiliere)

        this.enseignantService.getAll().then(data => {
            this.setState({
                enseignantList: data,
                membreEligible: data,
                curentSession: rowData,
                selectSessionDialog: false,
                juryDialog: true
            })
        })
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)

            this.setState({
                selectSessionDialog: false,
            })
        } )

        /*
        this.enseignantService.getListeEnseignant(parseInt(idSession), parseInt(idDepartementFiliere), parseInt(idParcours)).then(data => {

            this.setState({
                enseignantList: data,
                membreEligible: data,
                curentSession: rowData,
                selectSessionDialog: false,
                juryDialog: true
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)

            this.setState({
                selectSessionDialog: false,
            })
        })
        */

    }


    onLibelleJuryChange(e, field) {
        
        let curentJury = this.state.curentJury
        curentJury[field] = e.target.value

        this.setState({
            curentJury,
            isValidable: this.testValide(curentJury, this.state.curentPresidentJury, this.state.curentSecretaireJury, this.state.membreChoisi)
        })

    }

    onPresidentChange = (e) => {

        let membreEligible = []
        let membreChoisi = []
        let enseignantList = this.state.enseignantList

        //On va parcourir la liste des enseignants pour retirer ceux qui ont été choisie comme president et secretaire
        membreEligible = enseignantList.filter(enseignant => {
            return ( enseignant !== e.target.value && enseignant !== this.state.curentSecretaireJury )
        })

        this.setState({
            curentPresidentJury: e.target.value,
            membreEligible,
            membreChoisi,
            isValidable: this.testValide(this.state.curentJury, e.target.value, this.state.curentSecretaireJury, membreChoisi)
        })
    }

    onSecretaireChance = (e) => {

        let membreEligible = []
        let membreChoisi = []
        let enseignantList = this.state.enseignantList

        //On va parcourir la liste des enseignants pour retirer ceux qui ont été choisie comme president et secretaire
        membreEligible = enseignantList.filter(enseignant => {
            return ( enseignant !== e.target.value && enseignant !== this.state.curentPresidentJury )
        })

        this.setState({
            curentSecretaireJury: e.target.value,
            membreEligible,
            membreChoisi,
            isValidable: this.testValide(this.state.curentJury, this.state.curentPresidentJury, e.target.value, membreChoisi)
        })
    }


    onMembreJurySelect = (e) => {

        let listeEnseignantEligible = this.state.membreEligible
        let listeMembreJury = this.state.membreChoisi
        let listeSelectionActuel = this.state.listeSelectionEnseignantDisponible
        let idEnseignantChoisis = []

    

        //On parcours la selection pour ajouter les enseignants selectionnes
        listeSelectionActuel.map(enseignant => {
            listeMembreJury.push(enseignant)
            idEnseignantChoisis.push(parseInt(enseignant.idEnseignant))
        })

        //On retire les selectionnees de la liste des enseignants disponible
        let listeEnseignantRestants = listeEnseignantEligible.filter( enseignant => {
            return idEnseignantChoisis.indexOf(enseignant.idEnseignant) === -1
        })

        this.setState({
            membreEligible: listeEnseignantRestants,
            membreChoisi: listeMembreJury,
            listeSelectionEnseignantDisponible: [],
            isValidable: this.testValide(this.state.curentJury, this.state.curentPresidentJury, this.state.curentSecretaireJury, listeMembreJury)
        })

    }


    onMembreJuryRemove = (e) => {
        let membreEligible = this.state.membreEligible
        let membreChoisi = this.state.membreChoisi
        let listeSelectionEnseignantMembre = this.state.listeSelectionEnseignantMembre
        let idEnseignantSelection = []

        //On parcours la selection pour ajouter les enseignants selectionnes 
        listeSelectionEnseignantMembre.map(enseignant => {
            membreEligible.push(enseignant)
            idEnseignantSelection.push(parseInt(enseignant.idEnseignant))
        })

        //On retir la selection des membres du jury
        let newMembreChoisi = membreChoisi.filter(enseignant => {
            return idEnseignantSelection.indexOf(parseInt(enseignant.idEnseignant)) === -1
        })

        this.setState({
            membreChoisi: newMembreChoisi,
            membreEligible: membreEligible,
            listeSelectionEnseignantMembre: [],
            isValidable: this.testValide(this.state.curentJury, this.state.curentPresidentJury, this.state.curentSecretaireJury, newMembreChoisi)
        })

    }


    testValide = (jury, president, secretaire, membre) => {

        return (
                    jury.libelleJury
                    &&
                    president.idEnseignant
                    &&
                    secretaire.idEnseignant
                    &&
                    president.idEnseignant !== secretaire.idEnseignant
                    &&
                    membre.length>0
                )? true: false

    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editJury(rowData)} style={{marginRight: '.5em'}}/>
                {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteJury(rowData)} />*/}
            </React.Fragment>
        );
    }


    actionSelectSessionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check" className="p-button-success" onClick={() => this.selectSession(rowData)} style={{marginRight: '.5em'}} title="Selectioner la session"/>
            </React.Fragment>
        );
    }


    itemTemplate = (item) => {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.nomPrenom}</h6>
                </div>
            </div>
        );
    }


    onListeMembrePick = (event) => {
        
        this.setState({
            membreEligible: event.source,
            membreChoisi: event.target,
            isValidable: this.testValide(this.state.curentJury, this.state.curentPresidentJury, this.state.curentSecretaireJury, event.target)
        })

    }


    hideSelectMembreJuryDialog = () => {
        this.setState({
            viewSelectMembreJuryDialog: false,
            typeOperation: "",
            patience:false,
            listeRecherche: []
        })
    }


    selectMembrePresident = () => {
        this.setState({patience : true})
        this.enseignantService.getAll().then(data => {
            //console.log(data)
            this.setState({
                patience : false,
                listeRecherche : data,
            }) 
        })
        .catch(error => {
            this.setState({patience : false})
            console.log(error)
        } )
        this.setState({
            typeOperation: "president",
            viewSelectMembreJuryDialog: true
        })
    }


    selectMembreSecretaire = () => {
        this.setState({patience : true})

        //console.log('Ok')
        this.enseignantService.getAll().then(data => {
            //console.log(data)
            this.setState({
                patience : false,
                listeRecherche : data,
            }) 
        })
        .catch(error => {
            console.log(error)
            this.setState({patience : false})
        })
        this.setState({
            typeOperation: "secretaire",
            viewSelectMembreJuryDialog: true
        })
    }


    selectionMembreJury = (enseignantChoix) => {
        
        if(this.state.typeOperation){

            if(this.state.typeOperation ==="president"){

                let membreEligible = []
                let membreChoisi = []
                let enseignantList = this.state.enseignantList
                enseignantList.push(enseignantChoix)

                //On va parcourir la liste des enseignants pour retirer ceux qui ont été choisie comme president et secretaire
                membreEligible = enseignantList.filter(enseignant => {
                    return ( enseignant !== enseignantChoix && enseignant !== this.state.curentSecretaireJury )
                })
                this.setState({
                    curentPresidentJury: enseignantChoix,
                    membreEligible,
                    membreChoisi,
                    enseignantList,
                    isValidable: this.testValide(this.state.curentJury, enseignantChoix, this.state.curentSecretaireJury, membreChoisi),
                    viewSelectMembreJuryDialog: false
                })
   
            }else{

                let membreEligible = []
                let membreChoisi = []
                let enseignantList = this.state.enseignantList
                enseignantList.push(enseignantChoix)

                //On va parcourir la liste des enseignants pour retirer ceux qui ont été choisie comme president et secretaire
                membreEligible = enseignantList.filter(enseignant => {
                    return ( enseignant !== enseignantChoix && enseignant !== this.state.curentPresidentJury )
                })
                this.setState({
                    curentSecretaireJury: enseignantChoix,
                    membreEligible,
                    membreChoisi,
                    enseignantList,
                    isValidable: this.testValide(this.state.curentJury, this.state.curentPresidentJury, enseignantChoix, membreChoisi),
                    viewSelectMembreJuryDialog: false
                })
            }
        }

    }




    actionSelectMembreJuryBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check-circle" className="p-button-success" onClick={() => this.selectionMembreJury(rowData)} title="Sélectionner l'enseignant"/>
            </React.Fragment>
        );
    }


    
 
    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        let titreJury = this.state.curentSession? `Création d'un jury de délibération ${this.state.curentSession.libelleSession}`:""

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );


        const selectMembreJuryDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectMembreJuryDialog} />
            </React.Fragment>
        )

        
        const selectSessionFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideSelectSessionDialog} />
            </React.Fragment>
        );

        let validable = (this.state.isValidable)?(<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveJury} />):(<Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled />)

        const juryDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                {validable}
            </React.Fragment>
        );

        const deleteJuryDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteJuryDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteJury(this.state.deletionId) } />
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2" style={{marginLeft : 20}}>
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.juryList} selectionMode="single" header={`Liste des jury (${this.state.juryList.length})`} paginator={true} rows={5} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleJury" header="Jury" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par jury"/>
                            <Column field="session.libelleSession" header="Session concernée" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="recherche par session"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>


                <Dialog visible={this.state.selectSessionDialog} style={{ width: '95%' }} header="Sélectionner la session concernée" modal className="p-fluid" footer={selectSessionFooter} onHide={this.hideSelectSessionDialog}>
                {this.state.loadingSession ? 
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {this.spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable  ref={(el) => this.dt = el} value={this.state.sessionList} selectionMode="single" header={`Session disponible (${this.state.sessionList.length})`} paginator={true} rows={5} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                        <Column field="parcoursFiliere.parcours.libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher par parcours"/>
                        <Column field="anneeSemestre.libelleSemestre" header="Semestre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="recherche par semestre"/>
                        <Column field="anneeSemestre.libelleAnneeAcademique" header="Année académique" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="recherche par année académique"/>
                        <Column field="typeSession.libelleTypeSession" header="Type session" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="recherche par type session"/>
                        <Column header={actionHeader} body={this.actionSelectSessionTemplate} style={{textAlign:'center', width: '8em'}}/>
                    </DataTable>
                }
                </Dialog>

                <Dialog visible={this.state.juryDialog} style={{ width: '80%' }} header={`Création d'un jury de délibération ${this.state.curentSession.libelleSession}`} modal className="p-fluid" footer={juryDialogFooter} onHide={this.hideDialog}>
                   
                    <div className="p-grid">

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                {/*<label htmlFor="libelleJury">Libellé jury</label>*/}
                                <InputText id="libelleJury" value={this.state.curentJury.libelleJury}  onChange={(e) => this.onLibelleJuryChange(e, 'libelleJury')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Libellé du jury"/>
                                { !this.state.curentJury.libelleJury && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>

                    </div> 

                    <div className="p-grid">

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                {/*<label htmlFor="typePresidentJury">Type membre jury</label>*/}
                                <div className="p-grid p-lg-12">
                                    <div className="p-field p-col-12">
                                        <Dropdown optionLabel="libelleTypeMembre" optionValue={this.state.typePresident.idTypeJury} value={this.state.typePresident}  options={this.state.typePresidentList} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Type membre jury"/>
                                        {!this.state.typePresident && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                {/*<label htmlFor="presidentJury">Membre de jury</label>*/}
                                <div className="p-grid p-lg-12">
                                    <div className="p-field p-col-11">
                                        <Dropdown optionLabel="nomPrenom" optionValue={this.state.curentPresidentJury.idEnseignant} value={this.state.curentPresidentJury}  options={this.state.enseignantList} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le membre" onChange={(e) => {this.onPresidentChange(e)} }/>
                                        {!this.state.curentPresidentJury.emailEnseignant && <small className="p-invalid" style={{color : "red"}}>Selectionner le membre.</small>}
                                    </div>

                                    <div className="p-field p-col-1">
                                        <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.selectMembrePresident} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                    </div> 
                    
                    <div className="p-grid">
                        
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                {/*<label htmlFor="typeSecretaireJury">Type membre jury</label>*/}
                                <div className="p-grid p-lg-12">
                                    <div className="p-field p-col-12">
                                        <Dropdown optionLabel="libelleTypeMembre" optionValue={this.state.typeSecretaire.idTypeJury} value={this.state.typeSecretaire}  options={this.state.typeSecretaireList} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Type membre jury"/>
                                        {!this.state.typeSecretaire && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                {/*<label htmlFor="secretaireJury">Membre de jury</label>*/}
                                <div className="p-grid p-lg-12">
                                    <div className="p-field p-col-11">
                                        <Dropdown optionLabel="nomPrenom" optionValue={this.state.curentSecretaireJury.idEnseignant} value={this.state.curentSecretaireJury}  options={this.state.enseignantList} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Selectionner le membre" onChange={(e) => {this.onSecretaireChance(e)} }/>
                                        {(!this.state.curentSecretaireJury.emailEnseignant || this.state.curentSecretaireJury===this.state.curentPresidentJury) && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et ne doit être identique à la précedante !!!.</small>}
                                    </div>

                                    <div className="p-field p-col-1">
                                        <Button type="button" icon="pi pi-search" className="p-button-info" onClick={this.selectMembreSecretaire} />
                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    </div> 

                    <div className="p-grid">

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                {/*<label htmlFor="typeMembreJury">Type membre jury</label>*/}
                                <Dropdown optionLabel="libelleTypeMembre" optionValue={this.state.typeMembre.idTypeJury} value={this.state.typeMembre}  options={this.state.typeMembreList} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Type membre jury"/>
                                {!this.state.typeMembre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>


<br/>


                    <div className="p-grid">



                        <div className='p-field p-col-5'>

                            <DataTable  ref={(el) => this.dt = el} value={this.state.membreEligible} header="Liste des enseignants" paginator={true} rows={5}
                                filterMatchMode="contains" responsive={true} selection={this.state.listeSelectionEnseignantDisponible} onSelectionChange={event => this.setState({listeSelectionEnseignantDisponible: event.value})}>
                                <Column field="nomPrenom" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher un enseignant"/>
                                <Column selectionMode="multiple" headerStyle={{ width: '5em' }}></Column>
                            </DataTable>
                            <Button label="Choisir la sélection" icon="pi pi-check" className="p-button-text" onClick={(e) => this.onMembreJurySelect(e)} />

                        </div>

                        <div className="p-field p-col-2">
                            <Button label="Choisir la sélection ==>" icon="pi pi-check" className="btn btn-success" onClick={(e) => this.onMembreJurySelect(e)}  style={{marginBottom: '5px'}}/>
                            <Button label="<== Retirer la sélection" icon="pi pi-trash" className="btn btn-danger" onClick={(e) => this.onMembreJuryRemove(e)} />
                        </div>

                        <div className='p-field p-col-5'>
                            <DataTable  ref={(el) => this.dt = el} value={this.state.membreChoisi} header="Liste des membres du jury" paginator={true} rows={5}
                                filterMatchMode="contains" responsive={true} selection={this.state.listeSelectionEnseignantMembre} onSelectionChange={event => this.setState({listeSelectionEnseignantMembre: event.value})}>
                                <Column field="nomPrenom" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher un enseignant"/>
                                <Column selectionMode="multiple" headerStyle={{ width: '5em' }}></Column>
                            </DataTable>
                            <Button label="Retirer la sélection" icon="pi pi-trash" className="p-button-text" onClick={(e) => this.onMembreJuryRemove(e)} />

                        </div>
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            {this.state.membreChoisi.length===0 && <small className="p-invalid" style={{color : "red"}}>Vous devez choisir au moins un membre !.</small>}
                        </div>

                    </div> 

                </Dialog>

                <Dialog visible={this.state.deleteJuryDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteJuryDialogFooter} onHide={this.hideDeleteJuryDialog}>
                    
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.deletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>

                </Dialog>




                <Dialog visible={this.state.viewSelectMembreJuryDialog} style={{ width: '75%', height: 'auto' }} header="Sélection du membre jury" modal className="p-fluid" footer={selectMembreJuryDialogFooter} onHide={this.hideSelectMembreJuryDialog}>
                    {this.state.patience ?

                    <div className="p-col-6" >
                        {this.spinner.loadingSpinner("spin", "black")}
                    </div>
                    :
                    <DataTable ref={(el) => this.dt = el} value={this.state.listeRecherche} header="Liste des enseignants" paginator={true} rows={10} filterMatchMode="contains" responsive={true}>
                        <Column field="nomPrenom" header="Enseignant" sortable={true} filter={true} filterMatchMode="contains" filterPlaceholder="rechercher un enseignant"/>
                        <Column header={actionHeader} body={this.actionSelectMembreJuryBodyTemplate} style={{textAlign:'center', width: '150px'}}/>
                    </DataTable>
                    }
                </Dialog>









            </div> 
        )
    }

}

export default Jury
