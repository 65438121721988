import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SurveillantService {
    constructor(){
        this.adresse = new AdresseIP()
    }
    
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/surveillant"
        return axios.get(url).then(res => res.data) 
    }

    getAllDepartement(idDepartement) {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/surveillant?idDepartement="+idDepartement
        return axios.get(url).then(res => res.data) 
    }

    getAllEtablissement(idEtablissement) {

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/surveillant?idEtablissement="+idEtablissement
        return axios.get(url).then(res => res.data) 
    }
    
    create(newSurveillant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/surveillant"
        return axios.post(url,newSurveillant)
                    .then(res => res.data);
    }

    update(idSurveillant ,newSurveillant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/surveillant/"
        return axios.put(url+idSurveillant, newSurveillant)
                    .then(res => res.data);
    }

    delete(idSurveillant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/surveillant/"
        return axios.patch(url+idSurveillant)
                    .then(res => res.data)
    }

}