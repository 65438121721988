import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { SessionExamenService } from "../service/SessionExamenService";
import { RadioButton } from "primereact/radiobutton";
import { Notify } from "../../components/Notify";
import { Spinner } from "../../components/Spinner";
import { ToastContainer } from "react-toastify";
import { DeliberationService } from "../service/DeliberationService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";

import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import { jsPDF } from "jspdf";

export class PvDeliberation extends Component {
    state = {
        listeSession: [],
        session: {},
        listeDepartement: [],
        departement: null,
        pvDialog: false,
        affichePvDialog: false,
        choix: 1,
        listePV: [],
        structureEntete: {
            nb_ec: 0,
            liste_ue: [],
        },
        listeSecretaire: [],
        listeMembrePV: [],
        presidentJury: null,
        loadingPV: false,
        verifeDept: false,
        verifeEtab: false,
        loading: false,

        isDepartement: false,
        isDA: false,
        isDirecteur: false,
        isVp: false,

        table: null,
        telecharger: false,
        pvPDF: null,
        idDepartement: null
    };

    notify = new Notify();
    spinner = new Spinner();
    sessionService = new SessionExamenService();
    deliberationService = new DeliberationService();
    cohorteServices = new CohorteServices();
    //exportPdf = this.exportPdf.bind(this);
    //exportExcel = this.exportExcel.bind(this);
    cols = [
        { field: "ine", header: "INE" },
        { field: "nom", header: "Nom" },
        { field: "prenom", header: "Prénom(s)" },
        { field: "sexe", header: "Sexe" },
        { field: "dateNaiss", header: "Date de naiss." },
        { field: "lieuNaissance", header: "Lieu de naiss." },
        { field: "moyenne", header: "Moyenne" },
    ];

    exportColumns = this.cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        const structure = localStorage.getItem("structure");
        const struct = JSON.parse(structure);

        const campusDGESup = localStorage.getItem("campusDGESup");
        const campusVpEip = localStorage.getItem("campusVpEip");
        const campusEnseignant = localStorage.getItem("campusEnseignant");
        const campusEtudiant = localStorage.getItem("campusEtudiant");
        const campusDirecteur = localStorage.getItem("campusDirecteur");
        const campusDa = localStorage.getItem("campusDa");
        const campusDepartement = localStorage.getItem("campusDepartement");
        const isDGESup = parseInt(JSON.parse(campusDGESup));
        const isVp = parseInt(JSON.parse(campusVpEip));
        const isEnseignant = parseInt(JSON.parse(campusEnseignant));
        const isEtudiant = parseInt(JSON.parse(campusEtudiant));
        const isDepartement = parseInt(JSON.parse(campusDepartement));
        const isDirecteur = parseInt(JSON.parse(campusDirecteur));
        const isDA = parseInt(JSON.parse(campusDa));

        this.setState({
            structure: struct,
            loading: true,
            token: token,
            isDepartement: isDepartement,
            isDA: isDA,
            isDirecteur: isDirecteur,
            isVp: isVp,
        });

        if (isDA || isDirecteur) {
            this.setState({ verifeEtab: true });
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    const depts = data.departementEtabDtos;
                    this.setState({ listeDepartement: depts, loading: false });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);
                    this.setState({
                        selectSessionDialog: false,
                        loading: false,
                    });
                });
        } else if (isDepartement) {
            this.setState({ verifeDept: true });
            this.sessionService
                .getSessionDepartement(token.code_departement)
                .then((data) => {
                    this.setState({
                        listeSession: data.data,
                        loading: false,
                        idDepartement: token.code_departement
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({ loading: false });
                });
        } else if (token.code_enseignant) {
            this.anneeService.getAll().then((data) => {
                this.setState({ listeAnnee: data });
            });
            this.semestreService.getAll().then((data) => {
                this.setState({ listeSemestre: data });
            });
            this.iesrService.getAll().then((data) => {
                this.setState({ listeIesr: data });
            });
            this.setState({ loading: false, verifeEnseignant: true });
        } else {
            //this.setState({loading: false, verifeEnseignant : true})
        }
    };

    donneePV = (session) => {
        this.setState({ session: session, pvDialog: true });
    };

    listePV = () => {
        let title = `PV de délibération: ${this.state.session.libelleSession}`;
        this.setState({
            loadingPV: true,
            affichePvDialog: true,
            listeTitle: title,
        });

        this.deliberationService.getPVPDFFromBackend(this.state.session.idSession, this.state.idDepartement, this.state.choix).then(data => {
            this.setState({
                pvPDF: data.data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })


        //On recupère les informations necessaires a construire l'entête du PV
        if (this.state.choix === 1) {
            this.deliberationService
                .getPVDeliberation(this.state.session.idSession, 1)
                .then((data) => {
                    //console.log('Structure globale')
                    //console.log(data)

                    //On recupère le premier élement vue que l'ensemble est ordonnées coté backend suivant le meme principe
                    let etudiantPV = data.listeEtudiantPv[0];

                    let listeMembrePV = data.listeMembre;
                    let listeSecretaire = data.listeSecretaire;
                    let presidentJury = data.presidentJury;

                    this.setState({
                        listeMembrePV: listeMembrePV,
                        listeSecretaire: listeSecretaire,
                        presidentJury: presidentJury,
                    });

                    //On defini une entête pour recuillir les informations de la structure de l'entete
                    let struct_ent = {};

                    struct_ent["nom"] = "Nom";
                    struct_ent["prenom"] = "Prenom(s)";
                    struct_ent["dateNaissance"] = "Date de naissance";
                    struct_ent["lieuNaissance"] = "Lieu de naissance";
                    struct_ent["matricule"] = "Matricule";

                    //Element permettant de definir l'arborescence de UEs et ECs
                    let nb_ue = 0;
                    let listeEntUE = [];

                    etudiantPV.listeUe.map((ue) => {
                        let structureEnteteUE = {};

                        structureEnteteUE["code_ue"] =
                            ue.libelleUniteEnseignement +
                            " (" +
                            ue.codeUniteEnseignement +
                            ")";
                        structureEnteteUE["id_ue"] = ue.idUniteEnseignement;
                        //structureEnteteUE['credit_ue'] = ue.creditUniteEnseignement
                        structureEnteteUE["credit_ue"] =
                            "Moyenne UE (crédit:" +
                            ue.creditUniteEnseignement +
                            ")";
                        structureEnteteUE["moyenne_ue"] = ue.moyenneUe;
                        structureEnteteUE["moyenne_pondere"] =
                            "Moyenne Pondéré";
                        structureEnteteUE["nb_ec"] = ue.listeEc.length;

                        let listeECEnt = [];
                        if (ue.listeEc.length !== 0) {
                            let nb_ec = 0;
                            ue.listeEc.map((ec) => {
                                let structureEntEC = {};
                                structureEntEC["id_ec"] =
                                    ec.idElementConstitutif;
                                structureEntEC["code_ec"] =
                                    ec.codeElementConstitutif;
                                structureEntEC["libelle_ec"] =
                                    ec.libelleElementConstitutif;
                                listeECEnt[nb_ec] = structureEntEC;
                                nb_ec++;
                            });
                        }
                        structureEnteteUE["liste_ec"] = listeECEnt;
                        listeEntUE[nb_ue] = structureEnteteUE;
                        nb_ue++;
                    });
                    struct_ent["liste_ue"] = listeEntUE;

                    //Structuration des données pour être en conformité avec l'entête
                    let donnees = [];
                    let nb_data = 0;
                    data.listeEtudiantPv.map((etudiantPV) => {
                        let struct_data = {};

                        struct_data["nom"] = etudiantPV.nom;
                        struct_data["prenom"] = etudiantPV.prenom;
                        struct_data["dateNaissance"] = etudiantPV.dateNaissance;
                        struct_data["lieuNaissance"] = etudiantPV.lieuNaissance;
                        struct_data["matricule"] = etudiantPV.ine;

                        //Compteur des moyennes ajoutés dans la structure de données
                        let nb_moyenne = 0;

                        //Tableau devant contenir la liste des moyennes
                        let listeMoyenne = [];
                        etudiantPV.listeUe.map((ue) => {
                            //Le traitement n'est fait que quand il y'a des EC dans l'UE
                            if (ue.listeEc.length !== 0) {
                                //On place les moyennes des EC dans l'ordre dans lequel ils sont definis au niveau de l'entête
                                ue.listeEc.map((ec) => {
                                    listeMoyenne[nb_moyenne] = {
                                        moyenne: ec.moyenneEc,
                                    };
                                    nb_moyenne++;
                                });

                                //A la suite des EC, on fait preceder directement la moyenne de l'UE
                                listeMoyenne[nb_moyenne] = {
                                    moyenne: ue.moyenneUe,
                                };
                                nb_moyenne++;
                                listeMoyenne[nb_moyenne] = {
                                    moyenne: ue.pondereUe,
                                };
                                nb_moyenne++;
                            }
                        });

                        //A la fin de tous, on fait passer le total pondere
                        listeMoyenne[nb_moyenne] = {
                            moyenne: etudiantPV.cursusEtudiant.pondereTotal,
                        };
                        nb_moyenne++;
                        //On fait passer la moyenne obtenue par l'étudiant
                        listeMoyenne[nb_moyenne] = {
                            moyenne: etudiantPV.cursusEtudiant.moyenne,
                        };
                        nb_moyenne++;

                        //On fait passer le total des credits acquis si on veut l'afficher
                        listeMoyenne[nb_moyenne] = {
                            moyenne:
                                etudiantPV.cursusEtudiant.creditTotalAcquis,
                        };
                        nb_moyenne++;

                        listeMoyenne[nb_moyenne] = {
                            moyenne: etudiantPV.cursusEtudiant.cote,
                        };
                        //La decision Admis ou Ajourné, pour une harmonisation il s'intitulera moyenne aussi
                        listeMoyenne[nb_moyenne + 1] = {
                            moyenne:
                                etudiantPV.cursusEtudiant.resultat === 1
                                    ? "Validé"
                                    : "Non validé",
                        };

                        //On ajoute les moyennes à la structure de données contenant déjà les autres informations (nom, prenom etc)
                        struct_data["listeMoyenne"] = listeMoyenne;

                        //On insert le tous dans la structure de données
                        donnees[nb_data] = struct_data;
                        nb_data++;
                    });

                    this.setState({
                        listePV: donnees,
                        structureEntete: struct_ent,
                        loadingPV: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);

                    /*this.setState({
                //pvDialog: false,
                //loadingPV: false
            })*/
                });
        } else if (this.state.choix === 2) {
            this.deliberationService
                .getPVDeliberation(this.state.session.idSession, 2)
                .then((data) => {
                    //console.log('Structure globale')
                    //console.log(data)

                    //On recupère le premier élement vue que l'ensemble est ordonnées coté backend suivant le meme principe
                    let etudiantPV = data.listeEtudiantPv[0];

                    let listeMembrePV = data.listeMembre;
                    let listeSecretaire = data.listeSecretaire;
                    let presidentJury = data.presidentJury;

                    this.setState({
                        listeMembrePV: listeMembrePV,
                        listeSecretaire: listeSecretaire,
                        presidentJury: presidentJury,
                    });

                    //On defini une entête pour recuillir les informations de la structure de l'entete
                    let struct_ent = {};

                    struct_ent["nom"] = "Nom";
                    struct_ent["prenom"] = "Prenom(s)";
                    struct_ent["dateNaissance"] = "Date de naissance";
                    struct_ent["lieuNaissance"] = "Lieu de naissance";
                    struct_ent["matricule"] = "Matricule";

                    //Element permettant de definir l'arborescence de UEs et ECs
                    let nb_ue = 0;
                    let listeEntUE = [];

                    etudiantPV.listeUe.map((ue) => {
                        let structureEnteteUE = {};

                        structureEnteteUE["code_ue"] =
                            ue.libelleUniteEnseignement +
                            " (" +
                            ue.codeUniteEnseignement +
                            ")";
                        structureEnteteUE["id_ue"] = ue.idUniteEnseignement;
                        //structureEnteteUE['credit_ue'] = ue.creditUniteEnseignement
                        structureEnteteUE["credit_ue"] =
                            "Moyenne UE (crédit:" +
                            ue.creditUniteEnseignement +
                            ")";
                        structureEnteteUE["moyenne_ue"] = ue.moyenneUe;
                        structureEnteteUE["moyenne_pondere"] =
                            "Moyenne Pondéré";
                        structureEnteteUE["nb_ec"] = ue.listeEc.length;

                        let listeECEnt = [];
                        if (ue.listeEc.length !== 0) {
                            let nb_ec = 0;
                            ue.listeEc.map((ec) => {
                                let structureEntEC = {};
                                structureEntEC["id_ec"] =
                                    ec.idElementConstitutif;
                                structureEntEC["code_ec"] =
                                    ec.codeElementConstitutif;
                                structureEntEC["libelle_ec"] =
                                    ec.libelleElementConstitutif;
                                listeECEnt[nb_ec] = structureEntEC;
                                nb_ec++;
                            });
                        }
                        structureEnteteUE["liste_ec"] = listeECEnt;
                        listeEntUE[nb_ue] = structureEnteteUE;
                        nb_ue++;
                    });
                    struct_ent["liste_ue"] = listeEntUE;

                    //Structuration des données pour être en conformité avec l'entête
                    let donnees = [];
                    let nb_data = 0;
                    data.listeEtudiantPv.map((etudiantPV) => {
                        let struct_data = {};

                        struct_data["nom"] = etudiantPV.nom;
                        struct_data["prenom"] = etudiantPV.prenom;
                        struct_data["dateNaissance"] = etudiantPV.dateNaissance;
                        struct_data["lieuNaissance"] = etudiantPV.lieuNaissance;
                        struct_data["matricule"] = etudiantPV.ine;

                        //Compteur des moyennes ajoutés dans la structure de données
                        let nb_moyenne = 0;

                        //Tableau devant contenir la liste des moyennes
                        let listeMoyenne = [];
                        etudiantPV.listeUe.map((ue) => {
                            //Le traitement n'est fait que quand il y'a des EC dans l'UE
                            if (ue.listeEc.length !== 0) {
                                //On place les moyennes des EC dans l'ordre dans lequel ils sont definis au niveau de l'entête
                                ue.listeEc.map((ec) => {
                                    listeMoyenne[nb_moyenne] = {
                                        moyenne: ec.moyenneEc,
                                    };
                                    nb_moyenne++;
                                });

                                //A la suite des EC, on fait preceder directement le pondere et la moyenne de l'UE
                                listeMoyenne[nb_moyenne] = {
                                    moyenne: ue.moyenneUe,
                                };
                                nb_moyenne++;
                                listeMoyenne[nb_moyenne] = {
                                    moyenne: ue.pondereUe,
                                };
                                nb_moyenne++;
                            }
                        });

                        //A la fin de tous, on fait passer le total pondere
                        listeMoyenne[nb_moyenne] = {
                            moyenne: etudiantPV.cursusEtudiant.pondereTotal,
                        };
                        nb_moyenne++;
                        //On fait passer la moyenne obtenue par l'étudiant
                        listeMoyenne[nb_moyenne] = {
                            moyenne: etudiantPV.cursusEtudiant.moyenne,
                        };
                        nb_moyenne++;

                        //On fait passer le total des credits acqui si nous voulons afficher ces derniers
                        listeMoyenne[nb_moyenne] = {
                            moyenne:
                                etudiantPV.cursusEtudiant.creditTotalAcquis,
                        };
                        nb_moyenne++;

                        listeMoyenne[nb_moyenne] = {
                            moyenne: etudiantPV.cursusEtudiant.cote,
                        };
                        //La decision Admis ou Ajourné, pour une harmonisation il s'intitulera moyenne aussi
                        listeMoyenne[nb_moyenne + 1] = {
                            moyenne:
                                etudiantPV.cursusEtudiant.resultat === 1
                                    ? "Validé"
                                    : "Non validé",
                        };

                        //On ajoute les moyennes à la structure de données contenant déjà les autres informations (nom, prenom etc)
                        struct_data["listeMoyenne"] = listeMoyenne;

                        //On insert le tous dans la structure de données
                        donnees[nb_data] = struct_data;
                        nb_data++;
                    });

                    this.setState({
                        listePV: donnees,
                        structureEntete: struct_ent,
                        loadingPV: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({
                        affichePvDialog: false,
                        loadingPV: false,
                    });
                });
        }
    };

    hidePvDialog = () => {
        this.setState({
            pvDialog: false,
            pvPDF: null
        });
    };
    paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
                "" + String(i) + "/" + String(pageCount),
                290,
                200,
                null,
                null,
                "right"
            );
        }
    };
    genererPdf = () => {
        /*
        const doc = new jsPDF("l", "pt", [6000, 3370.39]);
        let titre = this.state.listeTitle;
        doc.html(document.querySelector("#pvpdf"), {
            callback: function (pdf) {
                pdf.save(titre + ".pdf");
            },
        });*/

        var blob = new Blob([this.state.pvPDF], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = this.state.listeTitle;
        link.download = fileName;
        link.click();
    };

    /*
    exportPdf() {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default("l");

                /*doc.text("Ministère de l'Enseignement Supérieur,", 10, 10)
                doc.text("de la Recherche et de l'Innovation", 10, 20)
                doc.text("----------------------------------", 10, 30)
                doc.text("Secrétariat Général", 10, 40)
                doc.text("----------------------------------", 10, 50)
                doc.text(this.state.departement.nomIes, 10, 60)
                doc.text("----------------------------------", 10, 70)
                doc.text(this.state.departement.nomEtablissement, 10, 80)
                doc.text("----------------------------------", 10, 90)
                doc.text(this.state.departement.lbDepartementEtab, 10, 100)*/
                /*
                doc.text(this.state.listeTitle, 10, 10);

                doc.autoTable(
                    this.exportColumns,
                    this.state.listeEnConsultation,
                    {
                        startY: doc.autoTable() + 70,
                        margin: { horizontal: 10 },
                        styles: { overflow: "linebreak" },
                        bodyStyles: { valign: "top" },
                        columnStyles: { email: { columnWidth: "wrap" } },
                        theme: "striped",
                        //showHead: "everyPage",
                        didDrawPage: function (data) {
                            // Header
                            doc.setFontSize(10);
                            doc.setTextColor(40);
                            //doc.text("Report", data.settings.margin.left, 22);

                            // Footer
                            const str =
                                "Page " + doc.internal.getNumberOfPages();

                            doc.setFontSize(10);

                            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                            const pageSize = doc.internal.pageSize;
                            const pageHeight = pageSize.height
                                ? pageSize.height
                                : pageSize.getHeight();
                            doc.text(
                                str,
                                data.settings.margin.left,
                                pageHeight - 10
                            );
                        },
                    }
                );
                //this.paginationPage(doc)
                doc.save(this.state.listeTitle + ".pdf");
            });
        });
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(
                this.state.listeDonneeExcel
            );
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, this.state.listeTitle);
        });
    }*/


    saveAsExcelFile(buffer, fileName) {
        import("file-saver").then((module) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            module.default.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    }
    dowloadListe = () => {
        this.setState({
            table: document.querySelector("#sheetjs"),
            telecharger: true,
        });
    };
    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="PV de la session"
                    onClick={() => this.donneePV(rowData)}
                />
            </React.Fragment>
        );
    };
    onDepartementChange = (e) => {
        const val = (e.target && e.target.value) || "";
        this.setState({ 
            departement: val,
            idDepartement: val.idDepartementEtab
         });
        this.setState({ loading: true });
        this.sessionService
            .getSessionDepartement(val.idDepartementEtab)
            .then((data) => {
                //On filtre uniquement les sessions qui sont ouvert
                let listeSessionOuvert = data.data.filter((curentSession) => {
                    return curentSession.etatSession === "OUVERT";
                });
                this.setState({
                    listeSession: listeSessionOuvert,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({ loading: false });
            });
    };
    hideListPvDialog = () => {
        this.setState({ affichePvDialog: false });
    };

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog" />;
        const pvDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hidePvDialog}
                />
            </React.Fragment>
        );
        const listPvFooter = (
            <React.Fragment>
                <hr />
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListPvDialog}
                />
            </React.Fragment>
        );
        const exportEcel = (
            <div id="pvpdf">
                <React.Fragment>
                    <table
                        id="sheetjs"
                        style={{ fontSize: 10 }}
                        className="table"
                    >
                        <thead>
                            <tr>
                                <th colSpan={12}>
                                    Ministère de l'Enseignement Supérieur de la
                                    Recherche et de l'Innovation (MESRI)
                                </th>
                            </tr>

                            <tr>
                                <th colSpan={12}>Secrétariat Général</th>
                            </tr>

                            <tr>
                                <th colSpan={12}>
                                    {this.state.structure &&
                                        this.state.structure.nomIes}
                                </th>
                            </tr>

                            <tr>
                                <th colSpan={12}>
                                    {this.state.structure &&
                                        this.state.structure.nomEtablissement}
                                </th>
                            </tr>

                            <tr>
                                <th colSpan={12}>
                                    {this.state.structure &&
                                        this.state.structure.lbDepartementEtab}
                                </th>
                            </tr>

                            <tr>
                                <th colSpan={12}>
                                    {this.state.session.libelleSession}
                                </th>
                            </tr>

                            <th colSpan={12}>
                                {this.state.structure ? (
                                    <img
                                        style={{ marginLeft: 200 }}
                                        src={
                                            "/assets/layout/images/logos/" +
                                            this.state.structure.logoIes
                                        }
                                        width={100}
                                        height={100}
                                        alt="logo iesr"
                                    />
                                ) : (
                                    ""
                                )}
                            </th>

                            <tr style={{ border: "1px solid black" }}>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    {this.state.structureEntete.nom}
                                </th>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    {this.state.structureEntete.prenom}
                                </th>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    {this.state.structureEntete.dateNaissance}
                                </th>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    {this.state.structureEntete.lieuNaissance}
                                </th>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    {this.state.structureEntete.matricule}
                                </th>
                                {this.state.structureEntete.liste_ue.map(
                                    (ue) => {
                                        return (
                                            <React.Fragment>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    colSpan={ue.nb_ec + 2}
                                                >
                                                    {ue.code_ue}
                                                </th>
                                            </React.Fragment>
                                        );
                                    }
                                )}
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    Total pondéré
                                </th>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    Moyenne
                                </th>
                                {
                                    <th
                                        style={{ border: "1px solid black" }}
                                        rowSpan="2"
                                    >
                                        Total crédits acquis
                                    </th>
                                }
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    Mention
                                </th>
                                <th
                                    style={{ border: "1px solid black" }}
                                    rowSpan="2"
                                >
                                    Résultat
                                </th>
                            </tr>

                            <tr style={{ border: "1px solid black" }}>
                                {this.state.structureEntete.liste_ue.map(
                                    function (ue) {
                                        let listeElementConstitutif =
                                            ue.liste_ec.map((ec) => {
                                                return (
                                                    <th
                                                        style={{
                                                            border: "1px solid black" /*writingMode: "vertical-rl", textOrientation : "mixed"*/,
                                                        }}
                                                    >
                                                        {ec.libelle_ec}
                                                    </th>
                                                );
                                            });

                                        return (
                                            <React.Fragment>
                                                {listeElementConstitutif}
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {ue.credit_ue}
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {ue.moyenne_pondere}
                                                </th>
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.listePV.map((data) => {
                                let listeMoyenneEtudiant =
                                    data.listeMoyenne.map((moyenne) => {
                                        return (
                                            <td
                                                style={{
                                                    border: "1px solid black",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {moyenne.moyenne}
                                            </td>
                                        );
                                    });

                                return (
                                    <tr style={{ border: "1px solid black" }}>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            {data.nom}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            {data.prenom}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            {data.dateNaissance}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            {data.lieuNaissance}
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid black",
                                            }}
                                        >
                                            {data.matricule}
                                        </td>
                                        {listeMoyenneEtudiant}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {/*
                            <h2>Membres du jury</h2>

                            <br/>   
s
                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'>

                                {(this.state.presidentJury) && <>
                                    
                                        <hr/>
                                        Président
                                        <hr/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        {(this.state.presidentJury.enseignant.grade && this.state.presidentJury.enseignant.grade.sigleGrade)? this.state.presidentJury.enseignant.grade.sigleGrade : (this.state.presidentJury.enseignant.sexeEnseignant === 'M')? "Mr":"Mme"} {(this.state.presidentJury && this.state.presidentJury.enseignant ) ? this.state.presidentJury.enseignant.prenomEnseignant : ""} { this.state.presidentJury ? this.state.presidentJury.enseignant.nomEnseignant : ""}
                                        {(this.state.presidentJury.enseignant.grade && this.state.presidentJury.enseignant.grade.libelleGrade && this.state.presidentJury.enseignant.grade.sigleGrade) && <><br/> {this.state.presidentJury.enseignant.grade.libelleGrade} </>}
                                   
                                    
                                    </>}
                                    

                                </div>
                            </div>

                          
                            <br/>
                                ***
                            <br/>

                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'>
                                    <hr/>
                                    Sécretaire(s)
                                    <hr/>
                                </div>
        
                                {this.state.listeSecretaire.map(secretaire => {
                                return(
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            {(secretaire.enseignant.grade && secretaire.enseignant.grade.sigleGrade)? secretaire.enseignant.grade.sigleGrade : (secretaire.enseignant.sexeEnseignant === 'M')? "Mr":"Mme"} {secretaire.enseignant.prenomEnseignant} {secretaire.enseignant.nomEnseignant}
                                            {(secretaire.enseignant.grade && secretaire.enseignant.grade.libelleGrade && secretaire.enseignant.grade.sigleGrade) && <> <br/>{secretaire.enseignant.grade.libelleGrade}</> }
                                    </div>
                                    )
                                })}
                                
                                
                            </div>

                            <br/>
                                ***
                            <br/>

                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'>
                                    <hr/>
                                    Membres
                                    <hr/>
                                </div>
                        
                                {this.state.listeMembrePV.map(membre => {
                                    return(

                                        
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            {(membre.enseignant.grade && membre.enseignant.grade.sigleGrade)? membre.enseignant.grade.sigleGrade : (membre.enseignant.sexeEnseignant === 'M')? "Mr":"Mme"} {membre.enseignant.prenomEnseignant} {membre.enseignant.nomEnseignant}
                                            {(membre.enseignant.grade && membre.enseignant.grade.libelleGrade && membre.enseignant.grade.sigleGrade) && <> <br/>{membre.enseignant.grade.libelleGrade} </> }
                                        </div>
                                    )
                                })}
                            </div>
                            */}
                </React.Fragment>
            </div>
        );
        return (
            <div>
                <div
                    className="card p-lg-10"
                    style={{ marginLeft: "10%", marginTop: "5%" }}
                >
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                    {this.state.loading ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : this.state.verifeDept ? (
                        <div className="p-lg-12" style={{ marginTop: 10 }}>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeSession}
                                selectionMode="single"
                                header="Liste des sessions"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilter}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="parcoursFiliere.libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un parcours"
                                />
                                <Column
                                    field="anneeSemestre.libelleAnneeAcademique"
                                    header="Année accadémique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une année"
                                />
                                <Column
                                    field="anneeSemestre.libelleSemestre"
                                    header="Semestre"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher un semestre"
                                />
                                <Column
                                    field="typeSession.libelleTypeSession"
                                    header="Session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher une session"
                                />
                                <Column
                                    field="etatSession"
                                    header="Etat de la session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un état"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateSession}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </div>
                    ) : this.state.verifeEtab ? (
                        <div className="p-lg-12" style={{ marginTop: 10 }}>
                            <div className="form-grid">
                                <div className="p-col-3">
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={this.state.departement}
                                        options={this.state.listeDepartement}
                                        onChange={(e) => {
                                            this.onDepartementChange(e);
                                        }}
                                        required
                                        autoFocus
                                        placeholder="Selectionner un departement"
                                    />
                                </div>
                            </div>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeSession}
                                selectionMode="single"
                                header="Liste des sessions"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilter}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="parcoursFiliere.libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un parcours"
                                />
                                <Column
                                    field="anneeSemestre.libelleAnneeAcademique"
                                    header="Année accadémique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une année"
                                />
                                <Column
                                    field="anneeSemestre.libelleSemestre"
                                    header="Semestre"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher un semestre"
                                />
                                <Column
                                    field="typeSession.libelleTypeSession"
                                    header="Session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher une session"
                                />
                                <Column
                                    field="etatSession"
                                    header="Etat de la session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un état"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateSession}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <Dialog
                    visible={this.state.pvDialog}
                    style={{ width: "80%" }}
                    header="Affichage du PV par ordre alphabétique ou par ordre de mérite"
                    modal
                    className="p-fluid"
                    footer={pvDialogFooter}
                    onHide={this.hidePvDialog}
                >
                    <>
                        <div className="field-radiobutton">
                            <div className="p-lg-12">
                                <div className="p-col-4">
                                    <RadioButton
                                        inputId="choix1"
                                        name="choix"
                                        value={1}
                                        onChange={(e) =>
                                            this.setState({ choix: e.value })
                                        }
                                        checked={this.state.choix === 1}
                                    />
                                    <label htmlFor="city1">
                                        Ordre alphabétique
                                    </label>
                                </div>
                                <div className="p-col-4">
                                    <RadioButton
                                        inputId="choix2"
                                        name="choix"
                                        value={2}
                                        onChange={(e) =>
                                            this.setState({ choix: e.value })
                                        }
                                        checked={this.state.choix === 2}
                                    />
                                    <label htmlFor="choix2">
                                        Ordre de merite
                                    </label>
                                </div>
                                <div className="p-col-3">
                                    <Button
                                        label="Afficher PV"
                                        icon="pi pi-times"
                                        className="p-button-text"
                                        onClick={this.listePV}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </Dialog>

                <Dialog
                    visible={this.state.affichePvDialog}
                    style={{ width: "98%" }}
                    modal
                    header={this.state.listeTitle}
                    className="p-fluid"
                    footer={listPvFooter}
                    onHide={this.hideListPvDialog}
                >
                    {this.state.loadingPV ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <hr />

                            {this.state.telecharger ? (
                                <>
                                    <ExportSheet
                                        dataType="Table-Node-Element"
                                        fileName={`pv`}
                                        tableElement={this.state.table}
                                        xlsx={XLSX}
                                    >
                                        <div className="p-col-2">
                                            <Button
                                                label="Télécharger le PV"
                                                icon="pi pi-file-excel"
                                                className="p-button-success"
                                            />
                                        </div>
                                    </ExportSheet>
                                </>
                            ) : (
                                <div className="p-grid p-col-4">
                                    <div className="p-col-5">
                                        <Button
                                            label="Export pdf"
                                            title="expoter en pdf"
                                            className="p-button-warning"
                                            type="button"
                                            icon="pi pi-file-pdf"
                                            onClick={this.genererPdf}
                                            data-pr-tooltip="PDF"
                                        />
                                    </div>
                                    <div className="p-col-2"></div>
                                    <div className="p-col-5">
                                        <Button
                                            label="Export excel"
                                            title="exporter en excel"
                                            className="p-button-success"
                                            data-pr-tooltip="EXCEL"
                                            icon="pi pi-file-excel"
                                            onClick={() => {
                                                this.dowloadListe();
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {exportEcel}
                        </React.Fragment>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default PvDeliberation;
