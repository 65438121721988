import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InfrastructureServices } from '../../service/InfrastructureServices';

export default function ResponsableInfrastructure(){

    const emptyResponsable = {
        idResponsableInfrastructure: '',
        nomResponsable: '',
        prenomResponsable: '',
        telephoneResponsable: '',
        emailResponsable: '',
        idDepartement: ''
    }

    const [idDepartement, setIdDepartement] = useState(null);
    const [responsableInfrastructure, setResponsableInfrastructure] = useState(null);
    const [responsableDialog, setResponsableDialog] = useState(false);
    const [selectedEntites, setSelectedEntites] = useState(null);
    const [responsable, setResponsable] = useState(emptyResponsable);
    const [allowCreate, setAllowCreate] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);

    const notify = new Notify();
    const spinner = new Spinner();
    const infrastructureServices = new InfrastructureServices();

    useEffect(() => {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
		const token = JSON.parse(tokenDecoded)
        
        setIdDepartement(token.code_departement)
        setLoading(true)
        infrastructureServices.getResponsableInfrastructure(token.code_departement).then(data => {
           
            if(data){

                setResponsableInfrastructure([data])
                setAllowCreate(false)
            }else{
                setAllowCreate(true)
            }
            setLoading(false)
                
        }).catch(error => {
                console.log(error);
                setLoading(false)
        })

    }, []
    );

    const hideResponsableDialog = () => {
        setResponsableDialog(false)
        setResponsable(emptyResponsable)
        setSubmitted(false)
    }

    const creerResponsable = () => {

        setResponsableDialog(true)

    }

    const editionResponsable = (responsable) => {
        setResponsable(responsable)
        setResponsableDialog(true)
    }



    const saveResponsable = () => {

        let curentResponbalite = {...responsable};

        curentResponbalite['idDepartement'] = idDepartement
        setSubmitted(true)
        if(curentResponbalite.nomResponsable && curentResponbalite.prenomResponsable && curentResponbalite.telephoneResponsable && curentResponbalite.emailResponsable){
            if(curentResponbalite.idResponsableInfrastructure){

                infrastructureServices.editResponsableInfrastructure(curentResponbalite.idResponsableInfrastructure, curentResponbalite).then(data => {
                    notify.success()
                    setResponsable(emptyResponsable)
                    setResponsableDialog(false)
                    setLoading(true)
                    setSubmitted(false)
                    infrastructureServices.getResponsableInfrastructure(idDepartement).then(data => {
                        if(data){
                            setResponsableInfrastructure([data])
                            setAllowCreate(false)
                        }else{
                            setAllowCreate(true)
                        }
                            setLoading(false)
                        }).catch(error => {
                            console.log(error);
                            setLoading(false)
                        })

                    }).catch(error => {
                        console.log(error)
                        notify.echec(error.response.data.message)
                    })

            }else{

                infrastructureServices.saveResponsableInfrastructure(curentResponbalite).then(data => {
                    notify.success()
                    setResponsable(emptyResponsable)
                    setResponsableDialog(false)
                    setSubmitted(false)
                    setLoading(true)
                    infrastructureServices.getResponsableInfrastructure(idDepartement).then(data => {
                        if(data){
                            setResponsableInfrastructure([data])
                            setAllowCreate(false)
                        }else{
                            setAllowCreate(true)
                        }
                            setLoading(false)
                        }).catch(error => {
                            console.log(error);
                            setLoading(false)
                        })
                    }).catch(error => {
                        console.log(error)
                        notify.echec(error.response.data.message)
                    })
            }
        }
    }

    const onInputChange = (e, fieldName) => {
        let curentResponbalite = {...responsable};
        curentResponbalite[`${fieldName}`] = e.target.value
        setResponsable(curentResponbalite)
    }



    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={creerResponsable} />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Edition" style={{marginRight: '.5em'}} onClick={() => editionResponsable(rowData)} />
            </React.Fragment>
        );
    }

    const responsableDialogFooter = (
        <React.Fragment>
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveResponsable} />
            <Button label="Quitter" icon="pi pi-times" className="p-button-danger" onClick={hideResponsableDialog} />
        </React.Fragment>
    );

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
            <div className="datatable-crud-demo">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="card">
                    {allowCreate && header}
                    {loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable value={responsableInfrastructure} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                        dataKey="id" paginator rows={10} 
                        globalFilter={globalFilter}
                        header="Responsable infrastructure departement">
                        <Column field="nomResponsable" header="Nom du responsable" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="prenomResponsable" header="Prenom du responsable" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="telephoneResponsable" header="Telephone du responsable" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column field="emailResponsable" header="Email responsable" filterMatchMode="contains" sortable={true} filter={true}></Column>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                    </DataTable>
                    }
                </div>


                <Dialog visible={responsableDialog} style={{ width: '70%' }} header="Informations du responsable des infrastructures" modal className="p-fluid" footer={responsableDialogFooter} onHide={hideResponsableDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nomResponsable">Nom</label>
                                <InputText id="nomResponsable" value={responsable.nomResponsable}  onChange={(e) => onInputChange(e, 'nomResponsable')} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                {submitted && !responsable.nomResponsable && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="prenomResponsable">Prenom</label>
                                <InputText id="prenomResponsable" value={responsable.prenomResponsable}  onChange={(e) => onInputChange(e, 'prenomResponsable')} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                {submitted && !responsable.prenomResponsable && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="telephoneResponsable">Telephone</label>
                                <input type='tel' id="telephoneResponsable" value={responsable.telephoneResponsable}  onChange={(e) => onInputChange(e, 'telephoneResponsable')} required autoFocus className="p-inputtext p-component" />
                                {submitted && !responsable.telephoneResponsable && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="emailResponsable">Email</label>
                                <input type='email' id="emailResponsable" value={responsable.emailResponsable}  onChange={(e) => onInputChange(e, 'emailResponsable')} required autoFocus className="p-inputtext p-component" />
                                {submitted && !responsable.emailResponsable && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <hr/>
                </Dialog>
            </div>
        )
    }