import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class CurriculaService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/curriculat"
       return axios.get(url).then(res => res.data) 
   }
   
   create(newCurricula) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/curriculat"
       return axios.post(url,newCurricula)
                   .then(res => res.data);
   }

   update(idCurricula ,newCurricula) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/curriculat/"
       return axios.put(url+idCurricula, newCurricula)
                   .then(res => res.data);
   }

   delete(idCurricula) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/curriculat/"
       return axios.patch(url+idCurricula)
                   .then(res => res.data)
   }

}