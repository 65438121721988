import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { TypeMembreJuryService } from '../../service/TypeMembreJuryService';

export class TypeMembreJury extends Component {

    emptyTypeMembre = {
        idTypeMembre: null,
        libelleTypeMembre: ''
    }

    constructor() {
        super();
        this.state = {
            listeTypeMembreJury : [],
            typeMembreJuryDialog: false,
            deleteTypeMembreJuryDialog: false,
            curentTypeMembreJury: this.emptyTypeMembre,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null,
            idDeletion: null
        }

        this.ModelTypeMembreJury = new TypeMembreJuryService();
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNewTypeMembreJury = this.openNewTypeMembreJury.bind(this);
        this.hideTypeMembreJuryDialog = this.hideTypeMembreJuryDialog.bind(this);
        this.saveTypeMembreJury = this.saveTypeMembreJury.bind(this);
        this.editTypeMembreJury = this.editTypeMembreJury.bind(this);
        this.confirmDeleteTypeMembreJury = this.confirmDeleteTypeMembreJury.bind(this);
        this.deleteTypeMembreJury = this.deleteTypeMembreJury.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.hideDeleteTypeMembreJuryDialog = this.hideDeleteTypeMembreJuryDialog.bind(this);
    }


    componentDidMount() {

        this.setState({loading : true})
        this.ModelTypeMembreJury.getAll().then(data => 
            this.setState({listeTypeMembreJury: data, loading : false})
            )
            .catch(error => {
                this.notify.echec(error.response.data.message)   
                console.log(error)
                this.setState({loading : false})
            })
    }
    
    openNewTypeMembreJury() {
        this.setState({
            curentTypeMembreJury: this.emptyTypeMembre,
            submitted: false,
            typeMembreJuryDialog: true
        });
    }


    hideTypeMembreJuryDialog() {
        this.setState({
            submitted: false,
            typeMembreJuryDialog: false,
            curentTypeMembreJury: this.emptyTypeMembre
        });
    }

    hideDeleteTypeMembreJuryDialog() {
        this.setState({ deleteTypeMembreJury: false });
    }


    saveTypeMembreJury() {

        let state = { submitted: true };

        if (this.state.curentTypeMembreJury.libelleTypeMembre && this.state.curentTypeMembreJury.libelleTypeMembre!=='' && (this.state.curentTypeMembreJury.libelleTypeMembre.trim()).length>=3) {

            const newTypeMembreJury = {
                idTypeMembre : parseInt(this.state.curentTypeMembreJury.idTypeMembre) ,
                libelleTypeMembre : this.state.curentTypeMembreJury.libelleTypeMembre
            }

            //console.log(newTypeMembreJury)
            if (this.state.curentTypeMembreJury.idTypeMembre) {
                this.ModelTypeMembreJury.update(parseInt(this.state.curentTypeMembreJury.idTypeMembre), newTypeMembreJury)
                                .then(data => {
                                    this.notify.success()
                                    this.setState({redirect : true})
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.ModelTypeMembreJury.create(newTypeMembreJury).then(data => {
                    this.notify.success()
                    this.setState({redirect : true})
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
            }

            state = {
                ...state,
                typeMembreJuryDialog: false,
                curentTypeMembreJury: this.emptyTypeMembre
            };

            this.setState(state);
        }

        
    }


    editTypeMembreJury(rowData) {

        let curentTypeMembre = rowData

        if(!curentTypeMembre.libelleTypeMembre){
            curentTypeMembre.libelleTypeMembre=''
        }

        this.setState({
            curentTypeMembreJury: curentTypeMembre,
            typeMembreJuryDialog: true
        });
    }

    confirmDeleteTypeMembreJury(rowData) {

        this.setState({
            idDeletion: rowData.idTypeMembre,
            deleteTypeMembreJuryDialog: true
        });
    }

    deleteTypeMembreJury(idDeletion) {
        this.ModelTypeMembreJury.delete(parseInt(idDeletion)).then(data => {
            this.notify.success()
            this.setState({
                deleteTypeMembreJuryDialog: false,
                curentTypeMembreJury: this.emptyTypeMembre,
                redirect : true
            });
        }).catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({
                deleteTypeMembreJuryDialog: false,
            });
        })
    }
    
    onInputChange(e, libelleInput) {
        const val = (e.target && e.target.value) || '';
        let curentTypeMembreJury = this.state.curentTypeMembreJury;
        curentTypeMembreJury[`${libelleInput}`] = val;

        this.setState({curentTypeMembreJury });
    }
    
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNewTypeMembreJury} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editTypeMembreJury(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteTypeMembreJury(rowData)} />
            </React.Fragment>
        );
    }
    
    render() {
        //console.log(this.state.listeTypeMembreJury)
        if(this.state.redirect) {
            //window.location.reload();
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const typeMembreJuryDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideTypeMembreJuryDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveTypeMembreJury} />
            </React.Fragment>
        );
        const deleteTypeMembreJuryDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteTypeMembreJuryDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteTypeMembreJury(this.state.idDeletion) } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNewTypeMembreJury} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeTypeMembreJury} selectionMode="single" header="Liste des type membres jury " paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                
                            <Column field="libelleTypeMembre" header="Type membre" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="type membre"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.typeMembreJuryDialog} style={{ width: '450px' }} header="Informations type membre jury" modal className="p-fluid" footer={typeMembreJuryDialogFooter} onHide={this.hideTypeMembreJuryDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleTypeMembre">Type membre jury</label>
                        <InputText id="libelleTypeMembre" value={this.state.curentTypeMembreJury.libelleTypeMembre}  onChange={(e) => this.onInputChange(e, 'libelleTypeMembre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}   />
                        { this.state.submitted && !this.state.curentTypeMembreJury.libelleTypeMembre || (this.state.curentTypeMembreJury.libelleTypeMembre.trim()).length<3 && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et doit contenir au moins 3 caractères.</small>}
                    </div>
                </Dialog>


                <Dialog visible={this.state.deleteTypeMembreJuryDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeMembreJuryDialogFooter} onHide={this.hideDeleteTypeMembreJuryDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.idDeletion && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default TypeMembreJury
